import flatten from 'lodash/flatten';
import admin from '@/store/entityDefinitions/admin';
import { get, post } from '@/utils/api';
import { setItems, setOneItem } from '@/utils/store';
import { uuidValidate as isUUID } from '@/utils/tools';

const modules = {
	admin
};

const state = {
	items: [],
	loading: false,
	conceptTypes: [],
	blockStatistics: {}
};

const mutations = {
	SET_ENTITY_DEFINITIONS(state, defs)
	{
		setItems(state, 'items', state.items, defs);
	},
	SET_ENTITY_DEFINITION(state, entity)
	{
		setOneItem(state, 'items', entity);
	},
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	},
	CLEAR_CONCEPT_TYPES(state)
	{
		state.conceptTypes = [];
	},
	SET_CONCEPT_TYPES(state, typeData)
	{
		setOneItem(state, 'conceptTypes', typeData);
	},
	SET_BLOCK_STATISTICS(state, { statTargetId, stat, data })
	{
		if(!state.blockStatistics[statTargetId])
		{
			state.blockStatistics[statTargetId] = {};
		}

		state.blockStatistics[statTargetId][stat] = data;
	}
};

const actions = {
	async loadAll({ dispatch, getters }, { force = false } = {})
	{
		// Only load if we're either forcing, or have none
		if(force || !getters.total)
		{
			dispatch('setLoading', true);
			try
			{
				const { data } = await get('entityDefinitions');

				dispatch('app/handleServerResponse', data, { root: true });
			}
			catch(e)
			{
				console.error('There was an error', e.message);
			}
			finally
			{
				dispatch('setLoading', false);
			}
		}
	},
	async load({ dispatch }, { id, force = false } = {})
	{
		dispatch('setLoading', true);
		try
		{
			const { data } = await get(`entityDefinitions/${id}`);

			await dispatch('app/handleServerResponse', data, { root: true });
		}
		catch(e)
		{
			console.error('There was an error', e.message);
		}
		finally
		{
			dispatch('setLoading', false);
		}
	},
	async loadMultiple({ dispatch, getters }, { ids, force = false })
	{
		let validIds = (Array.isArray(ids) ? ids : [ids])
			.filter((id) => id && isUUID(id));

		if(!force)
		{
			validIds = validIds.filter((id) => !getters.byId(id));
		}

		if(!validIds.length)
		{
			return;
		}

		dispatch('setLoading', true);

		try
		{
			const { data } = await post('/entityDefinitions/get', { ids: validIds });

			await dispatch('app/handleServerResponse', data, { root: true });
		}
		catch(e)
		{
			console.error('There was an error', e.message);
		}
		finally
		{
			dispatch('setLoading', false);
		}
	},
	async search({ dispatch }, { page = 1, limit = 10, filters = {}, sortBy = null, descending = false })
	{
		const { data } = await get('/entityDefinitions/search', { params: { page, limit, filters, sortBy, descending } });

		const itemData = data.items.map((item) => item.data);

		dispatch('setEntityDefinitions', itemData);

		return data;
	},
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	},
	setEntityDefinitions({ commit }, defs)
	{
		if(Array.isArray(defs))
		{
			// defs.forEach((def) => commit('SET_ENTITY_DEFINITION', def));
			commit('SET_ENTITY_DEFINITIONS', defs);
		}
	},
	async clearConceptTypes({ commit })
	{
		commit('CLEAR_CONCEPT_TYPES');
	},
	async getConceptTypes({ commit }, definitionId)
	{
		const { defId } = definitionId;
		let { data } = await get(`/entityDefinitions/conceptTypes/${defId}`);

		data = flatten(data);
		if(Array.isArray(data))
		{
			data.forEach((conceptType) => commit('SET_CONCEPT_TYPES', conceptType));
		}
	},
	async gatherBlockStatistics({ commit }, { statTargetId, stat })
	{
		const { data } = await get(`entityDefinitions/${statTargetId}/blockStatistics/${stat}`);

		commit('SET_BLOCK_STATISTICS', { statTargetId, stat, data });
	}
};

const getters = {
	all: (state) => state.items,
	total: (state, getters) => getters.all.length,
	loading: (state) => state.loading,
	byId: (state, getters) => (id) => getters.all.find(
		(concept) => concept.id === id
	),
	conceptTypes: (state) => state.conceptTypes,
	getBlockStatistics: (state) => (statTargetId, stat) => (state.blockStatistics[statTargetId] ? state.blockStatistics[statTargetId][stat] : null)
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
	modules
};
