<template>
	<div>
		<Container>
			<div class="row q-col-gutter-x-md">
				<div
					v-for="(col, colIndex) in columns"
					:key="colIndex"
					:class="`col-sm-${col}`"
					class="col col-xs-12"
				>
					<slot v-if="colIndex === 0" />
					<slot :name="`col-${colIndex}`" />

					<AddBlockButton
						:parent="blockId"
						:colIndex="colIndex"
						:index="getIndex(colIndex)"
					/>
				</div>
			</div>
		</Container>
	</div>
</template>

<script>
	import Container from '@/components/layout/Container';
	import AddBlockButton from '@/components/admin/generic/AddBlockButton';

	export default {
		components: {
			Container,
			AddBlockButton
		},
		props: {
			columns: {
				type: Array,
				default: () => [12],
				validator: (value) =>
				{
					if(value.length > 12) throw new Error('Too many columns');

					const totalCols = value.reduce((agg, col) =>
					{
						agg += col;

						return agg;
					}, 0);

					if(totalCols !== 12)
					{
						throw new Error('All cols widths combined should equal 12');
					}

					return true;
				}
			},
			blockId: {
				type: String,
				required: true
			},
			blockIdsInCols: {
				type: Object,
				default: () => ({})
			}
		},
		methods: {
			getIndex(index)
			{
				return this.blockIdsInCols?.[`col-${index}`]?.length || 0;
			}
		}
	};
</script>
