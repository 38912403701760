<template>
	<div v-show="shouldShow" v-hotkey.prevent.stop="keymap">
		<slot />
	</div>
</template>

<script>
	export default {
		data()
		{
			return {
				NODE_ENV: process.env.NODE_ENV,
				show: false
			};
		},
		computed: {
			shouldShow()
			{
				return this.NODE_ENV === 'development' && this.show;
			},
			keymap()
			{
				return {
					'alt+shift+d': this.toggle
				};
			}
		},
		methods: {
			toggle(e)
			{
				this.show = !this.show;
			}
		}
	};
</script>
