<template>
	<o-menubar-btn
		icon="fad fa-camera"
		:tooltip="$t('form.elements.wysiwyg.imageUploader.title')"
		class="o-photo-btn"
	>
		<q-menu
			ref="photoPopover"
			anchor="bottom middle"
			self="top middle"
			class="shadow-5"
			persistent
			:autoClose="false"
		>
			<div style="float: left" class="q-pa-md">
				<FileUploader
					:field="{
						name: 'post-image',
						multipleFiles: false,
						hideUploadedFiles: true
					}"
					class="q-mb-sm"
					emitResponse
					:maxFileSize="cMaxImageSizeInUploaders"
					limitToType="image"
					showUploader
					@upload="uploadComplete"
				/>
				<AdminI18N
					id="form.elements.wysiwyg.imageUploader.maxFileSize"
					class="text-caption"
					:vars="{maxFileSize: displayBytesInMb(cMaxImageSizeInUploaders)}"
				/>
			</div>
			<SimpleButton
				v-close-popup
				class="close"
				round
				icon="far fa-times"
				flat
				:aria-label="$t('modal.close')"
				color="primary"
				@click="closeMenu"
			/>
		</q-menu>
	</o-menubar-btn>
</template>

<script>
	import OMenubarBtn from 'quasar-tiptap/src/components/buttons/OMenubarBtn';
	// import OMetaInput from 'quasar-tiptap/src/components/common/OMetaInput';
	import FileUploader from '@/components/form/elements/Files';
	import { displayBytesInMb } from '@/utils/tools';
	import { cMaxImageSizeInUploaders } from '@/configs/constants';

	export default {
		name: 'ImageUploaderBtn',
		components: {
			OMenubarBtn,
			// OMetaInput,
			FileUploader
		},
		props: {
			commands: {
				type: Object,
				required: true
			},
			isActive: {
				type: Object,
				default: () => ({})
			}
		},
		data()
		{
			return {
				displayBytesInMb,
				cMaxImageSizeInUploaders
			};
		},
		inject: {
			maxFileSize: {
				type: Number,
				default: undefined
			}
		},
		methods: {
			insertImage(command, id)
			{
				if(id)
				{
					command({ 'data-id': id });
				}

				this.closeMenu();
			},
			uploadComplete(res)
			{
				// Get the file id and insert the image
				const fileIds = res?.xhr?.response ? JSON.parse(res.xhr.response).fileIds : null;

				const id = fileIds?.[0];

				if(id) this.insertImage(this.commands.imageUploader, id);
			},
			closeMenu()
			{
				this.$refs.photoPopover.hide();
			}
		}
	};
</script>
