import Vue from 'vue';
import { pick } from 'dot-object';
import cloneDeep from 'lodash/cloneDeep';
import {
	powerUps,
	cBlockPanelExtensions,
	defaultUserPostDisplayMode
} from '@/configs/constants';
import {
	userPostMediaTypes,
	userPostThumbnailAspectRatios
} from '@/configs/mediaConstants';
import BasicForm from '@/components/blocks/types/BasicForm';
import Handlebars from '@/components/blocks/types/Handlebars';
import RecentFooter from '@/components/blocks/types/RecentFooter';
import Parent from '@/components/blocks/types/Parent';
import Card from '@/components/blocks/types/Card';
import Tabs from '@/components/blocks/types/Tabs';
import Columns from '@/components/blocks/types/layout/Columns';
import Container from '@/components/blocks/types/layout/Container';
import Modal from '@/components/blocks/types/Modal';
import DataDisplay from '@/components/blocks/DataDisplay';
import Error from './Error';
import Loading from './Loading';

// don't forget to add a translation for your block - it won't show up in the block selector otherwise
const definitions = {};

const delay = 100;
const timeout = 30000;

const categoryPrefix = 'admin.panel.newBlock.categories';
const blockCategories = {
	user: `${categoryPrefix}.user`,
	form: `${categoryPrefix}.form`,
	display: `${categoryPrefix}.display`,
	// search: `${categoryPrefix}.search`,
	entity: `${categoryPrefix}.entity`,
	structure: `${categoryPrefix}.structure`,
	interaction: `${categoryPrefix}.interaction`,
	headlessSearch: `${categoryPrefix}.headlessSearch`,
	events: `${categoryPrefix}.events`,
	jobs: `${categoryPrefix}.jobs`,
	dashboard: `${categoryPrefix}.dashboard`,
	tickets: `${categoryPrefix}.tickets`
};

const LoadingComp = (width, height = '1000px', loaderType = 'facebook') => Vue.extend({
	name: 'LoadingComp',
	components: { Loading },
	render(createElement)
	{
		return createElement(
			Loading,
			{ props: { width, height, type: loaderType }, attrs: { style: `${width}; height: ${height};` } }
		);
	}
});

const RemovedComponent = Vue.extend({
	name: 'RemovedComponent',
	render(h)
	{
		return h('p', null, 'This component has been removed.');
	}
});

/*
WHEN ADDING A BLOCK DEFINITION WITH DEFAULTS, THE DEFAULTS *MUST* BE IN THE SAME FORMAT AS THEY WOULD ACTUALLY BE STORED ON THE BLOCK.
THIS IS BECAUSE THEY *WILL* BE AUTOMATICALLY SAVED ONTO THE BLOCK WHEN IT'S CREATED.
FOR EXAMPLE, IF YOU WANT YOUR BLOCK TO HAVE THE DEFAULT VALUE `{ minWidth: '200px' }` THEN YOU MUST ADD IT AS `{ defaults: { minWidth: 200 } }`
*/

definitions.inviteToPlatform = {
	component: () => ({
		component: import('@/components/blocks/types/InviteToPlatform'),
		loading: LoadingComp(null, '300px'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-user-plus'
};

definitions.application = {
	component: () => ({
		component: import('@/components/blocks/types/Application'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.form',
	icon: 'fal fa-clipboard-list'
};

definitions.basicForm = {
	component: BasicForm,
	category: 'admin.panel.newBlock.categories.form',
	icon: 'fal fa-align-justify'
};

definitions.plainText = {
	component: () => ({
		component: import('@/components/blocks/types/PlainText'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.display',
	icon: 'fal fa-align-left'
};

definitions.htmlBlock = {
	component: () => ({
		component: import('@/components/blocks/types/HtmlBlock'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	// category: 'admin.panel.newBlock.categories.display',
	icon: 'fal fa-code',
	panels: [
		'attachToEntity'
	]
};

definitions.handlebars = {
	component: Handlebars,
	category: 'admin.panel.newBlock.categories.display',
	icon: 'fal fa-brackets-curly',
	panels: [
		'attachToEntity',
		'enableAutoLinking'
	],
	defaults: {
		options: {
			autoLink: true
		}
	}
};

definitions.iFrame = {
	component: () => ({
		component: import('@/components/blocks/types/Iframe'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.display',
	icon: 'fal fa-brackets-curly'
};

definitions.ticketingWidget = {
	component: () => ({
		component: import(/* webpackChunkName: "blocks-ticketingWidget" */'@/components/blocks/types/TicketingWidget'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.ticketing',
	icon: 'fal fa-ticket-alt',
	addingDependency: ['ticketingEnabled']
};

definitions.orderList = {
	component: () => ({
		component: import(/* webpackChunkName: "blocks-orderList" */'@/components/blocks/types/OrderList'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.ticketing',
	icon: 'fal fa-shopping-cart',
	defaults: {
		orderListSpec: {
			limitToPaymentProvider: 'ticketTailor' // Temporary hard coded limit until this block gets extended
		}
	},
	addingDependency: ['ticketingEnabled']
};

definitions.userAssetList = {
	component: () => ({
		component: import(/* webpackChunkName: "blocks-userAssetList" */'@/components/blocks/types/UserAssetList'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.ticketing',
	icon: 'fal fa-boxes',
	defaults: {
		userAssetListSpec: {
			limitTo: '05b9a227-cba2-4825-9525-3dc8f97885c5' // Temporary hard coded limit until this block gets extended
		}
	},
	addingDependency: ['ticketingEnabled']
};

definitions.customScript = {
	component: () => ({
		component: import('@/components/blocks/types/CustomScript'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.display',
	icon: 'fal fa-code',
	panels: [
		'attachToEntity'
	]
};

definitions.userSearch = {
	component: () => ({
		component: import('@/components/blocks/types/search/UserSearch'),
		loading: LoadingComp(null, '500px', 'list'),
		error: Error,
		delay,
		timeout
	}),
	// category: 'admin.panel.newBlock.categories.search',
	panels: [
		'profileLink'
	],
	events: {
		afterAdd: [
			'triggerEdit'
		]
	},
	icon: 'fal fa-search'
};

definitions.programmeSearch = {
	component: RemovedComponent,
	category: '',
	events: {
		afterAdd: [
			'triggerEdit'
		]
	},
	icon: 'fal fa-search'
};

definitions.entitySearch = {
	component: () => ({
		component: import('@/components/blocks/types/search/EntitySearch'),
		loading: LoadingComp(null, '500px', 'list'),
		error: Error,
		delay,
		timeout
	}),
	// category: 'admin.panel.newBlock.categories.search',
	icon: 'fal fa-search'
};

definitions.userSearchResult = {
	component: () => ({
		component: import('@/components/blocks/types/search/UserSearchResult'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	})
};

definitions.programmeSearchResult = {
	component: RemovedComponent
};

definitions.entitySearchResult = {
	component: () => ({
		component: import('@/components/blocks/types/search/EntitySearchResult'),
		loading: LoadingComp(null, '300px'),
		error: Error,
		delay,
		timeout
	})
};

definitions.currentUserOverview = {
	component: () => ({
		component: import('@/components/blocks/types/CurrentUserOverview'),
		loading: LoadingComp(null, '300px', 'image'),
		error: Error,
		delay,
		timeout
	}),
	panels: [
		'socialMediaLinks'
	],
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-search'
};

definitions.relationships = {
	component: () => ({
		component: import('@/components/blocks/types/ProfileRelationships'),
		loading: LoadingComp(null, '300px', 'list'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-user-friends'
};

definitions.userListIntersections = {
	component: () => ({
		component: import('@/components/blocks/types/UserListIntersections'),
		loading: LoadingComp(null, '300px', 'list'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fas fa-chart-network'
};

definitions.profileHero = {
	component: () => ({
		component: import('@/components/blocks/types/ProfileHero'),
		loading: LoadingComp(null, '500px', 'image'),
		error: Error,
		delay,
		timeout
	}),
	// category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-user-circle',
	panels: [
		'heroSettings'
	]

};

definitions.socialMediaLinks = {
	component: () => ({
		component: import('@/components/blocks/types/SocialMediaLinks'),
		loading: LoadingComp(null, '30px', 'bulletList'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-user-circle'
};

definitions.userSocialMedia = {
	component: () => ({
		component: import('@/components/blocks/types/UserCards/UserSocialMedia'),
		loading: LoadingComp(null, '30px', 'bulletList'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fas fa-chart-network'
};

definitions.recentFooter = {
	component: RecentFooter,
	panels: [
		'profileLink'
	]
};

definitions.programmeList = {
	component: RemovedComponent,
	category: '',
	panels: [
		'attachToProgramme'
	],
	icon: 'fal fa-list'

};

definitions.myProgrammes = {
	component: () => ({
		component: import('@/components/blocks/types/programmes/myProgrammes/'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: '',
	icon: 'fal fa-list-alt'
};

definitions.programmeHero = {
	component: RemovedComponent,
	category: '',
	panels: [
		'attachToProgramme'
	],
	icon: 'fal fa-camera'
};

definitions.entityHero = {
	component: () => ({
		component: import('@/components/blocks/types/entities/Hero'),
		loading: LoadingComp(null, '500px'),
		error: Error,
		delay,
		timeout
	}),
	// category: 'admin.panel.newBlock.categories.entity',
	optionalPowerUps: [powerUps.durations, powerUps.contact],
	panels: [
		'attachToEntity',
		'heroSettings'
	],
	icon: 'fal fa-camera'
};

definitions.programmeOverview = {
	component: RemovedComponent,
	category: '',
	panels: [
		'attachToProgramme'
	],
	icon: 'fal fa-th-large'
};

definitions.entityOverview = {
	component: () => ({
		component: import('@/components/blocks/types/entities/Overview'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	optionalPowerUps: [powerUps.contact, powerUps.connections, powerUps.membership],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.programmeDetailsForm = {
	component: RemovedComponent,
	category: '',
	panels: [
		'attachToProgramme'
	],
	icon: 'fal fa-database'
};

definitions.programmeJoinBoxes = {
	component: RemovedComponent,
	category: '',
	panels: [
		'attachToProgramme'
	],
	icon: 'fal fa-coins'
};

definitions.entityJoinBoxes = {
	component: () => ({
		component: import('@/components/blocks/types/entities/JoinBoxes'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	optionalPowerUps: [powerUps.durations],
	requiredPowerUps: [powerUps.membership],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-coins'
};

/* DEPRECATED */
definitions.entityJoin = {
	component: () => ({
		component: import('@/components/blocks/types/entities/Join'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	requiredPowerUps: [powerUps.join],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-coins'
};

definitions.entityApplication = {
	component: () => ({
		component: import('@/components/blocks/types/entities/Application'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	panels: [
		'attachToEntity'
	],
	icon: 'far fa-clipboard-list'
};

definitions.programmeMap = {
	component: RemovedComponent,
	category: '',
	panels: [
		'attachToProgramme'
	],
	icon: 'fal fa-map'
};

definitions.entityMap = {
	component: () => ({
		component: import('@/components/blocks/types/entities/Map'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	panels: [
		'mapPanel',
		'attachToEntity'
	],
	icon: 'fal fa-map'
};

definitions.userMap = {
	component: () => ({
		component: import('@/components/blocks/types/profile/Map'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	panels: [
		'mapPanel'
	],
	icon: 'fal fa-map'
};

definitions.programmeRecentlyJoined = {
	component: RemovedComponent,
	category: '',
	panels: [
		'attachToProgramme',
		'profileLink'
	],
	icon: 'fal fa-clock'
};

definitions.parent = {
	component: Parent,
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-square',
	canContainBlocks: true
};

definitions.card = {
	component: Card,
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-square',
	canContainBlocks: true
};

definitions.tabs = {
	component: Tabs,
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-columns',
	canContainBlocks: true
};

definitions.stepper = {
	component: () => ({
		component: import('@/components/blocks/types/Stepper'),
		loading: null,
		error: Error,
		delay: 100,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-step-forward',
	panels: [
		'autoClose',
		'deleteConfirmation'
	]
};

definitions.carousel = {
	component: () => ({
		component: import('@/components/blocks/types/Carousel'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-presentation',
	canContainBlocks: true
};

/*
	DELETE ME WHEN I AM NO LONGER NEEDED, I AM A HACK
*/
definitions.programmeTabs = {
	component: RemovedComponent,
	category: '',
	icon: 'fal fa-columns'
};

definitions.myConnections = {
	component: RemovedComponent,
	category: '',
	icon: 'fal fa-users'
};

definitions.entityMyConnections = {
	component: () => ({
		component: import('@/components/blocks/types/entities/MyConnections'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-users'
};

definitions.connectionHistory = {
	component: () => ({
		component: import('@/components/blocks/types/entities/ConnectionHistory'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-users',
	panels: [
		'attachToEntity'
	]
};

definitions.applyToMe = {
	component: () => ({
		component: import('@/components/blocks/types/entities/ApplyToMe'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-users',
	requiredPowerUps: [powerUps.connections, powerUps.membership]
};

definitions.programmeHeaderBar = {
	component: RemovedComponent,
	category: '',
	panels: [
		'attachToProgramme'
	],
	icon: 'fal fa-heading'
};

definitions.columns = {
	component: Columns,
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-container-storage',
	canContainBlocks: true
};

definitions.portal = {
	component: () => ({
		component: import('@/components/blocks/types/layout/Portal'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-arrow-alt-to-right'
};

definitions.container = {
	component: Container,
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-square',
	canContainBlocks: true
};

definitions.masonry = {
	component: () => ({
		component: import('@/components/blocks/types/layout/Masonry'),
		loading: LoadingComp(),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-th',
	canContainBlocks: true
};

definitions.entityTitle = {
	component: () => ({
		component: import('@/components/blocks/types/entities/Title'),
		loading: LoadingComp(null, '50px'),
		delay,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-search',
	panels: [
		'attachToEntity'
	]
};

definitions.entityPicture = {
	component: () => ({
		component: import('@/components/blocks/types/entities/Picture'),
		loading: LoadingComp(null, '50px'),
		delay,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-image',
	panels: [
		'attachToEntity'
	]
};

definitions.entityText = {
	component: () => ({
		component: import('@/components/blocks/types/entities/Text'),
		loading: LoadingComp(),
		delay,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-font',
	panels: [
		'attachToEntity'
	]
};

definitions.uploadFile = {
	component: () => ({
		component: import('@/components/blocks/types/Upload'),
		loading: LoadingComp(),
		delay,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.interaction',
	icon: 'fal fa-file',
	panels: [
		'blockDataScope',
		'deleteConfirmation',
		'attachToEntity'
	],
	defaults: {
		scope: 'targetId'
	}
};

definitions.downloadFile = {
	component: () => ({
		component: import('@/components/blocks/types/Download'),
		loading: LoadingComp(),
		delay,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.interaction',
	icon: 'fal fa-file',
	panels: [
		'blockDataScope',
		'attachToEntity'
	],
	defaults: {
		scope: 'targetId'
	}
};

definitions.viewFiles = {
	component: () => ({
		component: import('@/components/blocks/types/ViewFiles'),
		loading: LoadingComp(),
		delay,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.interaction',
	icon: 'fal fa-file',
	panels: [
		'attachToEntity'
	]
};

definitions.modal = {
	component: Modal,
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-window-maximize',
	canContainBlocks: true,
	defaults: {
		modalProps: {
			width: '700',
			fullHeight: false,
			maximized: false,
			position: 'standard',
			seamless: false,
			persistent: true
		},
		noAutoOpen: false,
		showOnce: true,
		requiresAgreeing: false
	}
};

definitions.modalChain = {
	component: () => ({
		component: import('@/components/blocks/types/ModalChain'),
		loading: LoadingComp(),
		delay,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-link'
};

definitions.cardWall = {
	component: () => ({
		component: import('@/components/blocks/types/CardWall'),
		loading: LoadingComp(),
		delay,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.interaction',
	icon: 'fal fa-window-maximize',
	panels: [
		'blockDataScope'
	],
	defaults: {
		canCreateComments: 'admin',
		allowReplies: false,
		maxTitleLength: 100,
		maxContentLength: 500,
		allowEdit: true,
		onlyEditOwn: true,
		displayStyle: 'masonry',
		scope: 'targetId'
	}
};

definitions.discussionThreads = {
	component: () => ({
		component: import('@/components/blocks/types/DiscussionThreads'),
		loading: LoadingComp(),
		delay,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.interaction',
	icon: 'fal fa-comments-alt',
	panels: [
		'blockDataScope'
	],
	defaults: {
		enableSortByNewest: true,
		scope: 'targetId'
	}
};

definitions.headlessUserSearch = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/User'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-search',
	canContainBlocks: true,
	panels: [
		'searchFilters',
		'profileLink',
		'attachToEntity'
	]
};

definitions.headlessEntitySearch = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/Entity'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-search',
	canContainBlocks: true,
	panels: [
		'searchFilters'
	]
};

definitions.headlessUserContentSearch = {
	component: () => ({
		component: import('@/components/blocks/types/HeadlessSearch/UserContent'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-search',
	defaults: {
		options: {
			scope: true,
			enableComments: false,
			shouldHaveTitle: false,
			shouldHaveThumbnail: false,
			allowOneDriveViewer: true,
			defaultUserPostMediaTypes: [
				userPostMediaTypes.videos,
				userPostMediaTypes.images,
				userPostMediaTypes.audio,
				userPostMediaTypes.documents,
				userPostMediaTypes.links
			],
			maxMediaPerPost: 6,
			minMediaPerPost: 0,
			maxTitleLength: 400
		}
	}
};

definitions.createUserPost = {
	component: () => ({
		component: import('@/components/blocks/types/CreateUserPost'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-pencil'
};

definitions.userPost = {
	component: () => ({
		component: import('@/components/blocks/types/UserPost/UserPost.vue'),
		loading: null,
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-pencil',
	defaults: {
		thumbnailAspectRatio: userPostThumbnailAspectRatios['3/2'],
		postDisplayMode: defaultUserPostDisplayMode
	}
};

definitions.headlessSearchDebug = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/Debug'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-bug'
};

definitions.headlessSearchGrid = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/Grid'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-th',
	panels: [
		'searchResultsBlocks',
		cBlockPanelExtensions?.fadeOutIfInPast
	]
};

definitions.headlessSearchHorizontalResults = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/HorizontalResults'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-th',
	panels: [
		'searchResultsBlocks',
		cBlockPanelExtensions?.fadeOutIfInPast
	]
};

definitions.headlessSearchPagination = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/Pagination'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-arrows-h'
};

definitions.headlessSearchShowMore = {
	component: () => ({
		component: import('@/components/blocks/types/HeadlessSearch/ShowMore'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-arrows-h'
};

definitions.headlessSearchInput = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/SearchInput'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-keyboard'
};

definitions.headlessSearchFilters = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/Filters'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-filter'
};

definitions.headlessSearchReset = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/Reset'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-undo'
};

definitions.headlessSearchMap = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/Map'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-map',
	panels: [
		'searchResultsBlocks',
		'profileLink'
	],
	defaults: {
		popup: {
			minWidth: 100
		}
	}
};

definitions.headlessSearchCarousel = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/Carousel'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-presentation',
	panels: [
		'searchResultsBlocks',
		'carousel',
		cBlockPanelExtensions?.fadeOutIfInPast
	]
};

definitions.headlessSearchFilterGraph = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/FilterGraph'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-chart-bar'
};

definitions.headlessSearchNoResults = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/NoResults'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-empty-set'
};

definitions.headlessSearchNumberOfResults = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/NumberOfResults'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-poll-h'
};

definitions.headlessSearchOneFilter = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/OneFilter'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-filter'
};

definitions.headlessSearchMemberships = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/Memberships'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-filter'
};

definitions.headlessSearchPreparationContainer = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/PreparationContainer'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-poll-h'
};

definitions.headlessSearchFilterUserConnectivity = {
	component: () => ({
		component: import(/* webpackChunkName: "search-blocks" */ '@/components/blocks/types/HeadlessSearch/FilterUserConnectivity'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-filter'
};

definitions.userCardEducation = {
	component: () => ({
		component: import('@/components/blocks/types/UserCards/Education'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-chart-bar',
	panels: [
		'socialMediaLinks'
	]
};

definitions.syncWithLinkedIn = {
	component: () => ({
		component: import('@/components/blocks/types/SyncWithLinkedIn'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fab fa-linkedin-in',
	panels: [
		'profileSyncConfirmationBlock'
	]
};

definitions.debugUpdateUser = {
	component: () => ({
		component: import('@/components/blocks/types/debug/UpdateUser'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	})
};

definitions.entityHeroOverview = {
	component: () => ({
		component: import('@/components/blocks/types/entities/HeroOverviews/HeroOverview'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		optionalPowerUps: [powerUps.socialShare],
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	optionalPowerUps: [powerUps.addToFavorites, powerUps.contact, powerUps.connections, powerUps.membership],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.userCardDefault = {
	component: () => ({
		component: import('@/components/blocks/types/UserCards/Default'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	optionalPowerUps: [powerUps.addToFavorites, powerUps.socialShare],
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-font'
};

definitions.userCardProfessional = {
	component: () => ({
		component: import('@/components/blocks/types/UserCards/Professional'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-chart-bar',
	panels: [
		'socialMediaLinks'
	]
};

definitions.entityCardDefault = {
	component: () => ({
		component: import('@/components/blocks/types/entityCards/Default'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	optionalPowerUps: [powerUps.addToFavorites, powerUps.connections, powerUps.membership],
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-font',
	panels: [
		'attachToEntity',
		'entityCardImageSizing'
	]
};

definitions.entityCardEvent = {
	component: () => ({
		component: import('@/components/blocks/types/entityCards/Event'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.event',
	optionalPowerUps: [powerUps.addToFavorites, powerUps.socialShare],
	icon: 'fal fa-chart-bar',
	panels: [
		'entityCardDisplayStyle',
		'attachToEntity',
		'entityCardImageSizing'
	]
};

definitions.eventHeroOverview = {
	component: () => ({
		component: import('@/components/blocks/types/entities/HeroOverviews/EventHeroOverview'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.event',
	optionalPowerUps: [powerUps.addToFavorites],
	icon: 'fal fa-chart-bar',
	panels: [
		'attachToEntity'
	]
};

definitions.eventOverviewDetails = {
	component: () => ({
		component: import('@/components/blocks/types/entities/event/EventOverviewDetails'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.event',
	optionalPowerUps: [powerUps.addToFavorites, powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.eventOverviewParticipants = {
	component: () => ({
		component: import('@/components/blocks/types/entities/event/EventOverviewParticipants'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.event',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.userOverviewSmall = {
	component: () => ({
		component: import('@/components/blocks/types/entityCards/UserOverviewSmall'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	panels: [
		'profileLink'
	],
	icon: 'fal fa-th-large'
};

definitions.eventOverviewContacts = {
	component: () => ({
		component: import('@/components/blocks/types/entities/event/EventOverviewContacts'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.event',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.eventContentDescription = {
	component: () => ({
		component: import('@/components/blocks/types/entities/event/EventContentDescription'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.event',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity',
		'enableAutoLinking'
	],
	icon: 'fal fa-th-large',
	defaults: {
		options: {
			autoLink: true
		}
	}
};

definitions.eventContentDate = {
	component: () => ({
		component: import('@/components/blocks/types/entities/event/EventContentDate'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.event',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.eventContentGoing = {
	component: () => ({
		component: import('@/components/blocks/types/entities/event/EventContentGoing'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.event',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity',
		'actionButton'
	],
	icon: 'fal fa-th-large'
};

definitions.eventContentLocation = {
	component: () => ({
		component: import('@/components/blocks/types/entities/event/EventContentLocation'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.event',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.entityCardJob = {
	component: () => ({
		component: import('@/components/blocks/types/entityCards/Job'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.job',
	icon: 'fal fa-chart-bar',
	optionalPowerUps: [powerUps.addToFavorites, powerUps.socialShare],
	panels: [
		'entityCardDisplayStyle',
		'attachToEntity',
		'entityGenericJobConfig',
		'entityCardImageSizing'
	]
};

definitions.jobHeroOverview = {
	component: () => ({
		component: import('@/components/blocks/types/entities/HeroOverviews/JobHeroOverview'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.job',
	icon: 'fal fa-chart-bar',
	optionalPowerUps: [powerUps.addToFavorites],
	panels: [
		'attachToEntity',
		'entityGenericJobConfig'
	]
};

definitions.jobOverviewDetails = {
	component: () => ({
		component: import('@/components/blocks/types/entities/job/JobOverviewDetails'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		optionalPowerUps: [powerUps.socialShare],
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.job',
	optionalPowerUps: [powerUps.addToFavorites, powerUps.contact],
	panels: [
		'attachToEntity',
		'entityGenericJobConfig'
	],
	icon: 'fal fa-th-large'
};

definitions.jobOverviewContacts = {
	component: () => ({
		component: import('@/components/blocks/types/entities/job/JobOverviewContacts'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.job',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.jobContentDescription = {
	component: () => ({
		component: import('@/components/blocks/types/entities/job/JobContentDescription'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.job',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity',
		'enableAutoLinking'
	],
	icon: 'fal fa-th-large',
	defaults: {
		options: {
			autoLink: true
		}
	}
};

definitions.jobContentSkills = {
	component: () => ({
		component: import('@/components/blocks/types/entities/job/JobContentSkills'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.job',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.jobContentApplication = {
	component: () => ({
		component: import('@/components/blocks/types/entities/job/JobContentApplication'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.job',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.jobContentApply = {
	component: () => ({
		component: import('@/components/blocks/types/entities/job/JobContentApply'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.job',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.jobLocation = {
	component: () => ({
		component: import('@/components/blocks/types/entities/job/JobLocation'),
		loading: LoadingComp(null, '500px', 'instagram'),
		error: Error,
		delay,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.job',
	optionalPowerUps: [powerUps.contact],
	panels: [
		'attachToEntity'
	],
	icon: 'fal fa-th-large'
};

definitions.userCardEducation = {
	component: () => ({
		component: import('@/components/blocks/types/UserCards/Education'),
		loading: null,
		delay: 100,
		error: Error,
		timeout

	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-chart-bar',
	panels: [
		'socialMediaLinks'
	]
};

definitions.miniUsers = {
	component: () => ({
		component: import('@/components/blocks/types/dashboard/MiniUsers'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.dashboard',
	icon: 'fal fa-chart-bar',
	panels: [
		'profileLink'
	]
};

definitions.messages = {
	component: () => ({
		component: import('@/components/blocks/types/dashboard/Messages'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: '',
	icon: 'fal fa-chart-bar',
	panels: [
		'profileLink'
	]
};

definitions.miniProfileOverview = {
	component: () => ({
		component: import('@/components/blocks/types/dashboard/MiniProfileOverview'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.dashboard',
	icon: 'fal fa-chart-bar',
	panels: [
		'profileLink'
	]
};

definitions.entityStatsContent = {
	component: () => ({
		component: import('@/components/blocks/types/stats/EntityStatsContent'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-chart-line',
	panels: [
		'attachToEntity'
	]
};

definitions.userStatsContent = {
	component: () => ({
		component: import('@/components/blocks/types/stats/UserStatsContent'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-file-chart-line'
};

definitions.userHeroOverview = {
	component: () => ({
		component: import('@/components/blocks/types/dashboard/UserHeroOverview'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.dashboard',
	icon: 'fal fa-chart-bar',
	panels: [
		'generalStatsConfig',
		'profileLink'
	]
};

definitions.profileIntro = {
	component: () => ({
		component: import('@/components/blocks/types/profile/ProfileIntro'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-chart-bar',
	panels: [
		'profileLink'
	]
};

definitions.digitalResource = {
	component: () => ({
		component: import('@/components/blocks/types/entityCards/DigitalResource'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-chart-bar',
	panels: [
		'attachToEntity'
	]
};

definitions.socialAuthAccountLinks = {
	component: () => ({
		component: import('@/components/blocks/types/profile/SocialAuthAccountLinks'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-plug'
};

definitions.simpleEntityCard = {
	component: () => ({
		component: import('@/components/blocks/types/entityCards/SimpleEntityCard'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-chart-bar',
	panels: [
		'attachToEntity'
	]
};

definitions.myConnectionSettings = {
	component: () => ({
		component: import('@/components/blocks/types/entities/MyConnectionSettings'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-cog',
	panels: [
		'attachToEntity'
	]
};

definitions.connectionCapacities = {
	component: () => ({
		component: import('@/components/blocks/types/entities/ConnectionCapacities'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-warehouse-alt',
	panels: [
		'attachToEntity'
	]
};

definitions.rssFeedViewer = {
	component: () => ({
		component: import('@/components/blocks/types/rss/RSSFeedViewer'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.display',
	icon: 'fal fa-rss'
};

definitions.createEntityFromTemplate = {
	component: () => ({
		component: import('@/components/blocks/types/entities/CreateEntityFromTemplate'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-heart-circle'
};

definitions.favouritePosts = {
	component: () => ({
		component: import('@/components/blocks/types/FavouritePosts'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.interaction',
	icon: 'fal fa-comments-alt',
	panels: [
		'blockDataScope'
	],
	defaults: {
		scope: 'targetId'
	}
};

definitions.favouriteEntities = {
	component: () => ({
		component: import('@/components/blocks/types/entities/Favourites'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-heart'
};

definitions.favouriteButton = {
	component: () => ({
		component: import('@/components/blocks/types/entities/FavouriteButton'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-heart-circle'
};

definitions.editEntityFromTemplate = {
	component: () => ({
		component: import('@/components/blocks/types/entities/EditEntityFromTemplate'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-edit'
};

definitions.dismissible = {
	component: () => ({
		component: import('@/components/blocks/types/Dismissible'),
		loading: LoadingComp(),
		delay,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: blockCategories.structure,
	icon: 'fal fa-window-close',
	defaults: {
		icon: {
			type: 'fal fa-times',
			color: getComputedStyle(document.documentElement)?.getPropertyValue('--colors-white').trim(),
			btnColor: getComputedStyle(document.documentElement)?.getPropertyValue('--q-color-primary').trim(),
			position: {
				vertical: 'top',
				horizontal: 'right'
			}
		}
	}
};

definitions.dataDisplay = {
	component: DataDisplay,
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-presentation'
};

definitions.actionButton = {
	component: () => ({
		component: import('@/components/blocks/types/ActionButton'),
		loading: null,
		delay,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.structure',
	icon: 'fal fa-circle',
	panels: [
		'attachToEntity'
	]
};

definitions.clickable = {
	component: () => ({
		component: import('@/components/blocks/types/Clickable'),
		loading: LoadingComp(),
		delay,
		error: Error,
		timeout
	}),
	category: blockCategories.structure,
	canContainBlocks: true,
	icon: 'fal fa-circle'
};

definitions.entityLeaderMemberships = {
	component: () => ({
		component: import('@/components/blocks/types/entities/leader/Memberships'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-edit',
	panels: [
		'blockDataScope'
	],
	defaults: {
		scope: 'targetId'
	}
};

definitions.sortSearch = {
	component: () => ({
		component: import('@/components/blocks/types/search/SortSearch'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-sort-circle-up'
};

definitions.filterSearchBoolean = {
	component: () => ({
		component: import('@/components/blocks/types/HeadlessSearch/FilterSearchBoolean'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.headlessSearch',
	icon: 'fal fa-filter'
};

definitions.generalStats = {
	component: () => ({
		component: import('@/components/blocks/types/stats/GeneralStats'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-chart-line',
	panels: [
		'generalStatsConfig'
	]
};

definitions.entitySendNotification = {
	component: () => ({
		component: import(/* webpackChunkName: "blocks-entity-send-notification" */'@/components/blocks/types/entities/SendNotification.vue'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-paper-plane'
};

definitions.profileHeroV2 = {
	component: () => ({
		component: import('@/components/blocks/types/HeroV2'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fal fa-image-polaroid',
	panels: [
		'heroV2Settings'
	]
};

definitions.entityHeroV2 = {
	component: () => ({
		component: import('@/components/blocks/types/HeroV2'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-image-polaroid',
	panels: [
		'attachToEntity',
		'heroV2Settings'
	]
};

definitions.genericHeroV2 = {
	component: () => ({
		component: import('@/components/blocks/types/HeroV2'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	canContainBlocks: true,
	category: 'admin.panel.newBlock.categories.display',
	icon: 'fal fa-image-polaroid',
	panels: [
		'heroV2Settings'
	]
};

// TODO Add these back once suggestion/issue ticket for these new blocks have been resolved
definitions.entitySubscriptions = {
	component: () => ({
		component: import('@/components/blocks/types/notifications/entitySubscriptions'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.entity',
	icon: 'fal fa-bell'
};

definitions.qrCode = {
	component: () => ({
		component: import('@/components/blocks/types/QRCode.vue'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.display',
	icon: 'fas fa-qrcode'
};

definitions.notificationCenter = {
	component: () => ({
		component: import('@/components/blocks/types/NotificationCenter.vue'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.interaction',
	icon: 'fas fa-bell'
};

definitions.linkedInAutoFill = {
	component: () => ({
		component: import('@/components/blocks/types/LinkedInAutoFill.vue'),
		loading: null,
		delay: 100,
		error: Error,
		timeout
	}),
	category: 'admin.panel.newBlock.categories.user',
	icon: 'fab fa-linkedin-in',
	defaults: () =>
	{
		// field name on form mapped to default schema id
		const fieldList = {
			firstName: '197363ad-e047-4634-8f4a-c86433acd8e4',
			lastName: '901553c0-26ae-45bc-98a0-881fd2f1cdc5',
			title: '4892d6f0-9338-422c-8c15-4d2b912082f0',
			company: 'f223937b-351f-4c0e-a89a-5b7472857435',
			email: '22b91870-a017-4562-9bfe-1e00f46a5c08',
			phone: '96eabffc-0702-4376-9e49-378e11baab26',
			city: 'f442866b-8e81-4ca0-a356-6bbac3aabfd1',
			zip: '72c54a2b-dda6-4fec-8882-fb319ccf3ee2',
			state: 'b7b299e7-9934-4927-8515-d9daeab73b8f',
			country: '6ae0da69-403f-4b11-b258-dbe46e483d9e'
		};

		return {
			fieldOptions: Object.entries(fieldList).map(([alias, schemaId]) =>
			{
				return {
					alias,
					schemaId,
					show: true
				};
			}),
			showBlockTitle: true
		};
	}
};

// definitions.followingItems = {
// 	component: () => ({
// 		component: import('@/components/blocks/types/notifications/followingItems'),
// 		loading: null,
// 		delay: 100,
// 		error: Error,
// 		timeout
// 	}),
// 	category: 'admin.panel.newBlock.categories.entity',
// 	icon: 'fal fa-bell'
// };

const getComponents = () =>
{
	return Object.entries(definitions).reduce((components, [key, def]) =>
	{
		components[key] = def.component;

		return components;
	}, {});
};

const getDefinition = (name) =>
{
	if(name in definitions)
	{
		return definitions[name];
	}

	return undefined;
};

/**
 * Get the defined block default value at the specified path.
 * @param name string
 * @param path string
 * @returns
 */
const getBlockDefault = (name, path = undefined) =>
{
	const def = getDefinition(name);

	if(!def || !def.defaults)
	{
		return undefined;
	}

	let defaults;

	if(typeof def.defaults === 'function')
	{
		defaults = def.defaults();
	}
	else
	{
		defaults = def.defaults;
	}

	// No path, return everything
	if(!path)
	{
		return cloneDeep(defaults);
	}

	return pick(path, cloneDeep(defaults));
};

export {
	definitions,
	getComponents,
	getDefinition,
	getBlockDefault
};
