import { pick } from 'dot-object';
import { powerUps } from '@/configs/constants';
import { NodeTypes } from '@/configs/constants/ruleSets';
import { DateTime } from 'luxon';

export default {
	name: 'RuleParser',
	data()
	{
		const LogicalOperator = Object.freeze({
			all: 'all',
			any: 'any'
		});

		const RuleType = Object.freeze({
			profileField: 'field', // left as "field" since there are already existing rules with this rule id, and we don't want to start changing them... even with a script or a query
			profileList: 'list', // left as "list" intentionally
			profileEntityPowerUp: 'profileEntityPowerUp',
			userField: 'userField',
			userList: 'userList',
			userEntityPowerUp: 'userEntityPowerUp',
			entityField: 'entityField',
			emailBuilderField: 'emailBuilderField',
			// TODO: This is meant to be 'knownValue'?
			knownValueField: 'knownValueField',
			formField: 'formField' // quick addition for specific requirement - does not appear in rule builder, and only Form.vue is implemented
		});

		return {
			localRepeaterFieldsPath: '',
			userAccountId: null,
			profileAccountId: null,
			internalEntityId: null,
			userData: {},
			profileData: {},
			entityData: {},
			formData: {},
			LogicalOperator,
			RuleType,
			inverseOperators: ['does not equal', 'does not contain', 'number is empty', 'has not been uploaded', 'is empty', '<>', 'is not', 'is not toggled', 'not checked', 'does not begin with', 'does not end with', 'not between']
			// yes 'number is empty' and 'is empty' instead of 'number is not empty' or 'is not empty' - we're actually checking if the field does not exist
		};
	},
	computed: {
		memberships()
		{
			return this.$store.getters['entities/getPowerUpData'](this.internalEntityId, powerUps.membership);
		},
		membershipTypes()
		{
			return this.memberships?.membershipType || [];
		}
	},
	methods: {
		areConditionsMet(
			rules,
			userAccountId,
			profileAccountId,
			entityId,
			userData,
			profileData,
			entityData,
			formData,
			localRepeaterFieldsPath = this.repeaterFieldsPath,
			knownValues = {},
			ignoreCheck = false
		)
		{
			if(!rules?.children?.length || (!ignoreCheck && !userAccountId && !profileAccountId)) return false;

			this.userAccountId = userAccountId;
			this.profileAccountId = profileAccountId;
			this.internalEntityId = entityId;
			this.userData = userData;
			this.profileData = profileData;
			this.entityData = entityData;
			this.formData = formData;
			this.localRepeaterFieldsPath = localRepeaterFieldsPath;
			this.knownValues = knownValues;

			return this.traverseChildren(rules.children, rules.logicalOperator);
		},
		/**
		 * This series of methods does the frontend evaluation of the rules. While the backend rules traverser builds a bunch of arrays for the ElasticSearch to build a query, the frontend methods evaluate the rules on the fly with each method returning a boolean value of the processed rule
		 */
		traverseChildren(children, logicalOperator)
		{
			const results = [];

			children.forEach((child) =>
			{
				if(child.type === NodeTypes.group && child.query.children.length > 0)
				{
					const subResult = this.traverseChildren(child.query.children, child.query.logicalOperator);

					results.push(subResult);
				}
				else if(child.type === NodeTypes.rule)
				{
					results.push(this.parseChild(child.query));
				}
				// else skip
			});

			// final evaluation of the parsed rules and groups
			if(logicalOperator === this.LogicalOperator.any)
			{
				return results.some((result) => (typeof result !== 'undefined' ? result : false)); // unknown values should default to "false" to avoid false positives
			}

			return results.every((result) => (typeof result !== 'undefined' ? result : true)); // unknown values should default to "true" to avoid false negatives
		},
		parseChild(child)
		{
			if(child.rule === this.RuleType.profileField)
			{
				return this.parseFieldRule(child.value, this.profileData);
			}
			else if(child.rule === this.RuleType.userField)
			{
				return this.parseFieldRule(child.value, this.userData);
			}
			else if(child.rule === this.RuleType.profileList)
			{
				return this.parseListRule(child.value, this.profileAccountId);
			}
			else if(child.rule === this.RuleType.userList)
			{
				return this.parseListRule(child.value, this.userAccountId);
			}
			else if(child.rule === this.RuleType.profileEntityPowerUp)
			{
				return this.parsePowerUpRule(child.value, this.profileAccountId);
			}
			else if(child.rule === this.RuleType.userEntityPowerUp)
			{
				return this.parsePowerUpRule(child.value, this.userAccountId);
			}
			else if(child.rule === this.RuleType.entityField)
			{
				return this.parseFieldRule(child.value, this.entityData);
			}
			else if(child.rule === this.RuleType.formField)
			{
				return this.parseFieldRule(child.value, this.formData);
			}
			else if(child.rule === this.RuleType.emailBuilderField)
			{
				if(child.value.ruleType === 'field')
				{
					if(child.value.fieldSchema.profileTarget === 'receiverProfile')
					{
						return this.parseFieldRule(child.value, { ...this.userData, ...this.entityData });
					}

					return this.parseFieldRule(child.value, { ...this.profileData, ...this.entityData });
				}

				return this.parseKnownValueRule(child.value, this.knownValues);
			}

			console.warn('Unknown rule type', child.rule);

			return undefined;
		},
		dateOrString(value)
		{
			if(!value)
			{
				return value;
			}

			const dateToBeChecked = DateTime.fromSQL(value);

			if(dateToBeChecked.isValid)
			{
				return dateToBeChecked.startOf('day').ts.toString();
			}

			return value;
		},
		fieldValueCompare(value, fieldValue)
		{
			let result,
				fieldValueComp = fieldValue,
				valueComp = value.fieldValue;

			if(this.compareLowerCaseValuesInRules)
			{
				if(typeof fieldValue === 'string')
				{
					fieldValueComp = fieldValue.toLowerCase();
				}

				if(typeof value.fieldValue === 'string')
				{
					valueComp = value.fieldValue.toLowerCase();
				}
			}

			fieldValueComp = this.dateOrString(fieldValueComp);
			valueComp = this.dateOrString(valueComp);

			// inverse check
			const inverse = this.inverseOperators.includes(value.operator);

			switch(value.operator)
			{
				case 'is true':
					result = !!fieldValueComp;
					break;
				case 'is false':
					result = !fieldValueComp;
					break;
				case 'is neither true nor false':
					result = (typeof fieldValueComp !== 'boolean');
					break;
				case 'contains':
				case 'checked':
				case 'does not contain':
					result = fieldValueComp.includes(valueComp);
					break;
				case 'begins with':
				case 'does not begin with':
					result = fieldValueComp.startsWith(valueComp);
					break;
				case 'ends with':
				case 'does not end with':
					result = fieldValueComp.endsWith(valueComp);
					break;
				case 'is empty':
				case 'is not empty':
					// as stated above, we're checking if the value exists and is set and then reversing it if we need to check if it's empty
					result = fieldValueComp && fieldValue !== ''; // field exists and has a value - "is not empty"
					break;
				case 'number is empty':
				case 'number is not empty':
				case 'has been uploaded':
				case 'has not been uploaded':
					result = fieldValueComp && parseFloat(fieldValue) > 0;
					break;
				case 'is toggled':
				case 'is not toggled': // easier to check like this, might not even exist, not just be false
					result = fieldValueComp === true;
					break;
				case '<':
					result = parseFloat(fieldValueComp) < parseFloat(valueComp);
					break;
				case '>':
					result = parseFloat(fieldValueComp) > parseFloat(valueComp);
					break;
				case '<=':
					result = parseFloat(fieldValueComp) <= parseFloat(valueComp);
					break;
				case '>=':
					result = parseFloat(fieldValueComp) >= parseFloat(valueComp);
					break;
				case 'between':
				case 'not between':
					result = fieldValueComp >= this.dateOrString(value?.fieldValueObject?.gte) &&
					fieldValueComp <= this.dateOrString(value?.fieldValueObject?.lte);

					break;
				default:
					if(Array.isArray(fieldValueComp))
					{
						result = fieldValueComp.includes(valueComp); // e.g. checkbox fields
					}
					else
					{
						// treat default as a value equalling something
						result = valueComp === `${fieldValueComp}`; // always compare strings!
					}

					break;
			}

			return inverse ? !result : result;
		},
		parseKnownValueRule(value, data = {})
		{
			if(value.operator === '' || !value.fieldSchema?.id) // these should always be set
			{
				console.warn('Operator or fieldSchema id missing', value.operator, value.fieldSchema?.id);

				return undefined;
			}

			const fieldValue = data?.[value.fieldSchema.id];

			// inverse check
			// yes 'number is empty' and 'is empty' instead of 'number is not empty' or 'is not empty' - we're actually checking if the field does not exist
			const inverse = this.inverseOperators.includes(value.operator);

			if(typeof fieldValue === 'undefined')
			{
				// console.warn('Field value is undefined');

				return inverse;
			}

			return this.fieldValueCompare(value, fieldValue);
		},
		parseFieldRule(value, data = {})
		{
			if(value.operator === '' || !value.fieldSchema?.id) // these should always be set
			{
				console.warn('Operator or fieldSchema id missing', value.operator, value.fieldSchema?.id);

				return undefined;
			}

			let key;

			// check that the schema still exists
			const schema = this.$store.getters['dataSchemas/getDataSchema'](value.fieldSchema.id);

			if(!schema)
			{
				console.warn('Schema not found', schema);

				return undefined; // schema doesn't exist, so we don't know what to do with this
			}

			if(this.localRepeaterFieldsPath !== '' && schema.key.indexOf('.') > 0)
			{
				const fieldKey = schema.key.split('.').pop(); // the key for the current field without the repeater key

				key = `${this.localRepeaterFieldsPath}${fieldKey}`;
			}
			else
			{
				key = schema.key;
			}

			if(schema.source === 'entity')
			{
				// custom fields on entities are placed in `context` key in the data
				key = `context.${key}`;
			}

			const fieldValue = this.getDataValueByPath(data, key);

			return this.fieldValueCompare(value, fieldValue);
		},
		parseListRule(value, accountId)
		{
			if(!value?.list || !accountId)
			{
				return undefined;
			}

			let result;

			/*
			 'in list (all selected)'       => agg && isUserInList      initial agg = true
			 'not in list (any selected)'   => agg && !isUserInList     initial agg = true
			 'in list (any selected)'       => agg || isUserInList      initial agg = false
			 'not in list (some selected)'  => agg || !isUserInList     initial agg = false
			 */

			// here we can quit searching through the lists early if the result won't change once we've come to a conclusion midway - for that we set the agg to be the first variable
			if(value.operator === 'in list (all selected)' || value.operator === 'not in list (any selected)')
			{
				// needs to search through ALL checked lists and make sure the user is in ALL of them or isn't in ANY of them
				result = Object.keys(value.list || {}).reduce((agg, listId) =>
				{
					if(value.list[listId] === true && agg === true) // quit early if we already have our result
					{
						const subResult = this.$store.getters['profiles/isProfileAcceptedInList'](accountId, listId) || false; // the getter can return `undefined` which in our use case means that the user is NOT in the list

						return agg && (value.operator === 'not in list (any selected)' ? !subResult : subResult);
					}
					// else it's been unchecked and doesn't matter anymore

					return agg;
				}, true);
			}
			else
			{
				// check if the user is in at least one list
				result = Object.keys(value.list || {}).reduce((agg, listId) =>
				{
					if(value.list[listId] === true && agg === false) // quit early if we already have our result
					{
						const subResult = this.$store.getters['profiles/isProfileAcceptedInList'](accountId, listId) || false;

						return agg || (value.operator === 'not in list (some selected)' ? !subResult : subResult);
					}
					// else it's been checked and doesn't matter anymore

					return agg;
				}, false);
			}

			return result;
		},
		parsePowerUpRule(value, accountId)
		{
			if(!accountId) return undefined;

			let listMembershipStatesMap = null;

			switch(value.operator)
			{
				case 'is member':
					// just check if the user is in the entity (on any membership)
					return this.membershipTypes.some((membershipType) =>
					{
						// find the membership the rule states
						return this.$store.getters['profiles/isProfileAcceptedInList'](
							accountId,
							membershipType.primaryUserList.value
						);
					});
				case 'has never had a membership':
					listMembershipStatesMap = this.$store.getters['userLists/getListMembershipStatesMap'](accountId);

					if(listMembershipStatesMap)
					{
						// check if user has NOT interacted with ANY of the entity's memberships
						return this.membershipTypes.every((membershipType) => (
							typeof listMembershipStatesMap[
								membershipType.primaryUserList.value
							] === 'undefined'
						));
					}

					return false;
				case 'is member of tag':
					// find the membership(s) with the stated tag(s) and check if the user is a member of any of them
					return this.membershipTypes.some((membershipType) =>
					{
						// does this membership have any of the specified tags
						if(value.tags.some((tag) => membershipType?.tags?.value.includes(tag)))
						{
							// is the user in this membership type's primaryUserList
							return this.$store.getters['profiles/isProfileAcceptedInList'](
								accountId,
								membershipType.primaryUserList.value
							);
						}

						return false;
					});
				case 'is in state of tag':
					listMembershipStatesMap = this.$store.getters['userLists/getListMembershipStatesMap'](accountId);

					if(!listMembershipStatesMap)
					{
						return false;
					}

					if(!value.listMembershipStatesToInclude?.length)
					{
						value.listMembershipStatesToInclude = ['accepted'];
					}

					// find the membership(s) with the stated tag(s) and check
					// if the user is in the given states of any of them
					return this.membershipTypes.some((membershipType) =>
					{
						if(
							// there is a user list to match to
							membershipType?.primaryUserList?.value &&
							// the membership has any tags
							membershipType.tags?.value?.length
						)
						{
							// find any tag for which...
							return value.tags.some((tag) =>
							{
								const mapValue = listMembershipStatesMap[
									membershipType.primaryUserList.value
								];

								if(typeof mapValue === 'undefined')
								{
									return false;
								}

								if(
									// the membership has this tag
									membershipType.tags.value.includes(tag)
								)
								{
									if(value.inverse)
									{
										// user's current list state is NOT in the rejected states
										return !value.listMembershipStatesToInclude.includes(mapValue);
									}

									// user's current list state IS one of the required states
									return value.listMembershipStatesToInclude.includes(mapValue);
								}

								return false;
							});
						}

						return false;
					});
				default:
					console.warn(`Unknown power-up rule operator: ${value.operator}`);
			}

			return false;
		},
		getDataValueByPath(profile, path)
		{
			if(!profile || !path)
			{
				return '';
			}

			// repeaters can be a bit of a pain with their additional `.value` locations - this gets the correct value in case of an updated repeater value
			const parts = path.split('.');
			let current = profile;

			parts.every((part) =>
			{
				current = pick(part, current);

				// just check if there's an additional `value` in the mix. If there is, just select the value from there.
				if(typeof current?.value !== 'undefined')
				{
					current = current.value;
				}

				return current !== undefined; // break out of loop when there's no value
			});

			if(!current || !path)
			{
				return '';
			}

			return current;
		}
	}
};
