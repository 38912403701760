import { getFocalPointRaw, getFocalPointForFile } from '@/utils/focalPointTools';

export default {
	computed: {
		focusedImageStyle()
		{
			if(!this.imageId) return {}; // when there's no id, there won't be an image

			return getFocalPointForFile(this.imageId, true, true, false);
		},
		focusedThumbStyle()
		{
			if(!this.imageId) return {}; // when there's no id, there won't be an image

			return getFocalPointForFile(this.imageId, true, true, true, false, undefined, this.thumbSize);
		},
		focusedImageOrEmpty()
		{
			return this.imageId ? this.focusedThumbOrImage : {};
		},
		focusedThumbOrImage()
		{
			return this.thumbSize ? this.focusedThumbStyle : this.focusedImageStyle;
		},
		imageFile()
		{
			return this.$store.getters['files/byId'](this.imageId);
		},
		focalPoint()
		{
			return getFocalPointRaw(this.imageId);
		},
		imageMetadataProps()
		{
			return {
				role: 'img',
				alt: this.imageFile?.data?.title,
				'aria-label': this.imageFile?.data?.description,
				style: this.focusedImageStyle
			};
		},
		thumbMetadataProps()
		{
			return {
				role: 'img',
				alt: this.imageFile?.data?.title,
				'aria-label': this.imageFile?.data?.description,
				style: this.focusedThumbStyle
			};
		},
		imgProps()
		{
			return {
				title: this.imageFile?.data?.title,
				alt: this.imageFile?.data?.description,
				src: this.imgSrc,
				style: { ...this.focusedImageStyle, 'background-image': null }
			};
		},
		imgThumbProps()
		{
			return {
				title: this.imageFile?.data?.title,
				alt: this.imageFile?.data?.description,
				src: this.imgThumbSrc,
				style: { ...this.focusedThumbStyle, 'background-image': null }
			};
		},
		// let's try to limit the times these values are recalculated
		imgSrc()
		{
			return this.$imagePath(this.imageId);
		},
		imgThumbSrc()
		{
			return this.$thumbPath(this.imageId, this.thumbSize);
		}
	},
	mounted()
	{
		if(!this.imageFile && this.imageId) this.$store.dispatch('files/loadFiles', { fileIds: [this.imageId] });
	}
};
