import admin from '@/store/applications/membershipRequests/admin';
// import { post } from '@/utils/api';

const modules = {
	admin
};

const state = { };

const mutations = { };

const actions = { };

const getters = { };

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
