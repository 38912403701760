<template>
	<div>
		<SimpleTabsOverflow
			:useSlots="isAdminEditMode"
			:noArrows="!isAdminEditMode"
			:tabs="modules"
			i18nBasePath="custom.modules"
			:customTabsBarClasses="['modules']"
			:customOverflowMenuClasses="customOverflowMenuClasses"
			:customTitleValue="titleValue"
			dark
			:currentTab="currentModule"
			:routeLink="routeLink"
			class="q-mx-md"
		>
			<template #customTabs>
				<div class="row no-wrap">
					<draggable
						v-if="isAdminEditMode"
						v-model="modules"
						:disabled="!(isAdminEditMode && canMoveModules)"
						style="display: inherit"
					>
						<span
							v-for="module in modules"
							:key="module.id"
							class="droppable"
						>
							<q-route-tab
								:ripple="false"
								:to="routeLink(module)"
							>
								<div>
									<I18N :id="`custom.modules.${module.id}`" />
									<SimpleButton
										v-if="isAdminEditMode && userCanSpecific(module.id)"
										:icon="module.id === currentlyEditingItemId ? 'fas fa-cog' : 'fad fa-cog'"
										:color="module.id === currentlyEditingItemId ? 'teal' : 'neutral'"
										size="sm"
										class="cog"
										round
										flat
										:aria-label="$t('blocks.controls.header.edit')"
										@click.prevent="edit(module.id)"
									/>
								</div>
							</q-route-tab>
							<div
								v-if="dropzonesActive"
								class="dropzone"
								@drop.prevent="confirmDropToModule(module)"
								@dragenter="showDropTarget"
								@dragleave="hideDropTarget"
							/>
						</span>
					</draggable>
					<transition
						enterActiveClass="animated fadeIn"
						leaveActiveClass="animated fadeOut"
					>
						<q-route-tab
							v-if="isCurrentModuleAbstract"
							:ripple="false"
							:to="routeLink(currentModule)"
							class="abstractModule"
						>
							<div class="inline-content">
								<I18N
									v-if="!titleValue"
									:id="`custom.modules.${currentModule.id}`"
								/>
								<span v-else>
									{{ titleValue }}
								</span>
								<SimpleButton
									v-if="isAdminEditMode && userCanSpecific(currentModule.id)"
									:icon="currentModule.id === currentlyEditingItemId ? 'fas fa-cog' : 'fad fa-cog'"
									:color="currentModule.id === currentlyEditingItemId ? 'teal' : 'neutral'"
									class="cog"
									size="sm"
									round
									flat
									@click.prevent="edit(currentModule.id)"
								/>
							</div>
						</q-route-tab>
					</transition>
					<q-route-tab
						v-for="page in staticPages"
						:key="page.path"
						:ripple="false"
						:to="page.path"
					>
						<I18N :id="`layout.header.staticModules.${page.label}`" />
					</q-route-tab>
					<q-tab
						v-if="isAdminEditMode && canAddModules"
						:ripple="false"
						@click="newModule"
					>
						<SimpleButton
							icon="far fa-plus"
							size="sm"
							color="text-white"
							class="plus"
							round
							flat
							:aria-label="$t('blocks.controls.header.addModule')"
						/>
					</q-tab>
				</div>
			</template>
		</SimpleTabsOverflow>
		<Modal
			ref="modal"
			size="small"
			persistent
			:title="$t('navigation.moveActions.titles.modal')"
			class="modal"
		>
			<div class="text-center">
				<I18N id="navigation.moveActions.confirmText" />
			</div>
			<div class="q-mt-md q-mb-lg text-center">
				<div class="q-mb-xl">
					<q-chip
						color="primary"
						dark
					>
						<span class="ellipsis">
							{{ heldPage.name }}
						</span>
					</q-chip>
				</div>
				<div>
					<div class="moveActionConfirmModule">
						<I18N id="navigation.moveActions.titles.from" />
					</div>
					<q-chip>
						{{ heldPageCurrentModule.name }}
					</q-chip>
				</div>
				<q-icon
					name="fal fa-arrow-down"
					class="q-my-sm"
				/>
				<div>
					<div class="moveActionConfirmModule">
						<I18N id="navigation.moveActions.titles.to" />
					</div>
					<q-chip
						color="positive"
						dark
					>
						{{ heldPageNewModule.name }}
					</q-chip>
				</div>
			</div>
			<template #controls>
				<SimpleButton
					color="primary"
					@click="dropToModule(pageDropNewModule)"
				>
					<I18N id="navigation.moveActions.confirm" />
				</SimpleButton>
				<SimpleButton
					color="negative"
					@click="cancelPageMove"
				>
					<I18N id="navigation.moveActions.cancel" />
				</SimpleButton>
			</template>
		</Modal>
	</div>
</template>

<script>
	import { routeConfig } from '@/router';
	import EventBus from '@/components/admin/generic/EventBus';
	import { userCan, userCanAnyOf } from '@/plugins/Permissions';
	import SimpleTabsOverflow from '@/components/layout/navigation/NavItemsTabsOverflow.vue';
	import { cAdminPanelTypes } from '@/configs/adminPanelTypes';

	export default {
		components: {
			draggable: () => import('vuedraggable'),
			Modal: () => import('@/components/Modal'),
			SimpleTabsOverflow
		},
		props: {
			gradientFallback: {
				default: false,
				type: Boolean
			}
		},
		data()
		{
			return {
				pageDropNewModule: null,
				parentStyleUpdate: false,
				dropzonesActive: false,
				modulesOrPages: 'modules'
			};
		},
		computed: {
			isAdminEditMode()
			{
				return this.$store.getters['admin/isEditMode'] && userCan('manageEditMode', 'administration');
			},
			currentlyEditingItemId()
			{
				return this.$store.getters['admin/panel/activeStack']?.id;
			},
			canAddModules()
			{
				return userCan('addModules', 'items.modules');
			},
			canMoveModules()
			{
				return userCan('move', 'items.modules');
			},
			moduleOrder()
			{
				return this.$store.getters['app/settings/getModuleOrder'];
			},
			orderedModules()
			{
				const modules = this.$store.getters['structure/modules/getNavigationModules'];

				if(!(Array.isArray(modules) && modules.length))
				{
					return [];
				}

				if(!(Array.isArray(this.moduleOrder) && this.moduleOrder.length))
				{
					return modules;
				}

				return this.moduleOrder
					.map((moduleId) => (
						modules.find((module) => module.id === moduleId)
					))
					.filter((module) => !!module);
			},
			modules: {
				get()
				{
					if(!this.isCurrentModuleAbstract || this.isAdminEditMode)
					{
						return this.orderedModules;
					}

					return [...this.orderedModules, {
						...this.currentModule,
						customI18NKey: `custom.modules.${this.currentModule.id}`,
						titleValue: this.titleValue
					}];
				},
				set(modules)
				{
					this.$store.dispatch('structure/modules/admin/changeOrder', modules);
				}
			},
			currentModuleSlug()
			{
				return this.$store.state.route.params.moduleSlug;
			},
			currentModule()
			{
				return this.$store.getters['structure/modules/getModuleBySlug'](this.currentModuleSlug) ?? {};
			},
			isCurrentModuleAbstract()
			{
				return this.currentModule && !!this.currentModule.isAbstract;
			},
			currentPage()
			{
				return this.$store.getters['structure/pages/getPageBySlug'](this.$store.state.route.params.moduleSlug, this.$store.state.route.params.pageSlug);
			},
			showTargetValueAsTitle()
			{
				return this.currentModule && this.currentModule.showTargetValueAsTitle;
			},
			entity()
			{
				return this.$route.params.targetId && this.$store.getters['entities/byId'](this.$route.params.targetId);
			},
			titleValue()
			{
				if(this.showTargetValueAsTitle && this.entity)
				{
					return this.$store.getters['entities/getFlatValueByPath'](this.entity.id, this.showTargetValueAsTitle);
				}

				return null;
			},
			staticPages()
			{
				return routeConfig.filter((r) => r.label && r.displayInMenu);
			},
			adminMode()
			{
				return this.isAdminEditMode ? 'adminMode' : '';
			},
			heldPage()
			{
				const heldPage = this.$store.getters['structure/pages/admin/getHeld'];

				if(heldPage)
				{
					return heldPage.page;
				}

				return false;
			},
			heldPageCurrentModule()
			{
				if(this.heldPage)
				{
					const { moduleId } = this.$store.getters['structure/pages/admin/getHeld'];

					return this.$store.getters['structure/modules/getModuleById'](moduleId);
				}

				return false;
			},
			heldPageNewModule()
			{
				if(this.pageDropNewModule)
				{
					return this.$store.getters['structure/modules/getModuleById'](this.pageDropNewModule.id);
				}

				return false;
			},
			customOverflowMenuClasses()
			{
				const customOverflowMenuClasses = ['modules'];

				if(this.gradientFallback)
				{
					customOverflowMenuClasses.push('fallback-background-color');
				}

				return customOverflowMenuClasses;
			}
		},
		created()
		{
			EventBus.$on('navigation:modules:dropzones:activate', this.activateDropzones);
			EventBus.$on('navigation:modules:dropzones:deactivate', this.deactivateDropzones);
		},
		destroyed()
		{
			EventBus.$off('navigation:modules:dropzones:activate');
			EventBus.$off('navigation:modules:dropzones:deactivate');
		},
		methods: {
			newModule()
			{
				this.$store.dispatch('structure/modules/admin/openPanel');
			},
			edit(id)
			{
				this.$store.dispatch('admin/panel/new', { type: cAdminPanelTypes.editModule, id, key: id, moduleId: this.currentModule.id }, { root: true });
			},
			routeLink(module)
			{
				if(!module)
				{
					return { name: 'page', params: { moduleSlug: '404' } };
				}

				if(module.isAbstract)
				{
					return { name: 'space', params: { moduleSlug: module.slug } };
				}

				return { name: 'module', params: { moduleSlug: module.slug } };
			},
			confirmDropToModule(newModule)
			{
				if(newModule.id !== this.heldPageCurrentModule.id)
				{
					this.pageDropNewModule = newModule;
					this.$refs.modal.open();
				}
			},
			async dropToModule(newModule)
			{
				if(this.isAdminEditMode)
				{
					this.deactivateDropzones();

					const pageToMove = await this.$store.getters['structure/pages/admin/getHeld'];

					if(pageToMove !== null && typeof pageToMove !== 'undefined')
					{
						const oldModuleId = pageToMove.moduleId;
						const activePage = await this.$store.getters['structure/pages/getActivePage'];
						const { page } = pageToMove;
						const redirectUserToNewPage = (typeof activePage !== 'undefined' ? activePage.id === page.id : true);

						await this.$store.dispatch('structure/modules/admin/moveChild', { origin: oldModuleId, target: newModule.id, child: page.id });
						this.$store.dispatch('structure/pages/admin/setHeld', { moduleId: this.currentModule.id, page: {} });

						// redirect user to new page
						if(redirectUserToNewPage && typeof this.currentModule.childIds !== 'undefined')
						{
							const currentModuleChildren = this.currentModule.childIds;

							if(typeof currentModuleChildren !== 'undefined' && currentModuleChildren.length > 0)
							{
								const firstChild = currentModuleChildren[0]; // eslint-disable-line prefer-destructuring
								const { slug } = await this.$store.getters['structure/pages/getPageById'](firstChild);

								this.$router.replace({ name: 'page', params: { moduleSlug: this.currentModuleSlug, pageSlug: slug } });
							}
							else
							{
								// If there are no children left, we still stay in the module
								this.$router.replace({ name: 'module', params: { moduleSlug: this.currentModuleSlug } });
							}
						}
					}

					if(this.$refs.modal)
					{
						this.$refs.modal.close();
					}
				}
			},
			showDropTarget(event)
			{
				if(this.isAdminEditMode)
				{
					const { classList } = event.target;

					if(classList.contains('dropzone'))
					{
						classList.add('targeted');
					}
				}
			},
			hideDropTarget(event)
			{
				if(this.isAdminEditMode)
				{
					const { classList } = event.target;

					if(classList.contains('dropzone'))
					{
						classList.remove('targeted');
					}
				}
			},
			cancelPageMove()
			{
				if(this.$refs.modal)
				{
					this.$refs.modal.close();
				}

				this.deactivateDropzones();
				this.$store.dispatch('structure/pages/admin/setHeld', { moduleId: this.currentModule.id, page: {} });
			},
			activateDropzones()
			{
				this.dropzonesActive = true;
			},
			deactivateDropzones()
			{
				this.dropzonesActive = false;
			},
			userCanSpecific(id)
			{
				return userCanAnyOf([{
					actions: [
						'managePermissions',
						'manageSettings',
						'manageVisibility'
					],
					subject: 'items.modules',
					subjectId: id
				}]);
			}
		}
	};
</script>

<style scoped lang="postcss">
	.inline-content {
		display: inline-block;
	}

	.modules-tabs {
		display: inherit;
	}

	.cog {
		pointer-events: auto;
		vertical-align: inherit;

		&:hover,
		&:focus {
			color: #fff;
			opacity: 1;
		}
	}

	.abstractModule {
		animation-duration: 100ms;
	}

	.droppable {
		position: relative;
	}

	.targeted {
		border: 2px dotted white;
	}

	.dropzone {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	.modal {
		position: relative;
	}

	.moveActionConfirmModules {
		margin: 30px 0;
	}

	.moveActionConfirmModule {
		text-align: center;
	}
</style>
