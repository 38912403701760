import { I18N } from 'acb-lib';

/**
 * Get the language name in the language and the English name of the language.
 * For instance, `Français (French)`, or `English`.
 */
export function languageAdminNameForIso(iso)
{
	const label = I18N.cLanguages[iso];

	const labelInEnglish = I18N.cLanguagesEnglish[iso];

	if(!label || !labelInEnglish)
	{
		// when all there is to show is an iso, show it
		// it's better than a blank
		return iso;
	}

	return label === labelInEnglish ? label : `${label} (${labelInEnglish})`;
}
