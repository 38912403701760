// @ts-check
import { inject, provide } from 'vue';

/**
 * @typedef {import(
 * 	'@/types/composables/ui/quasarProps').QuasarProps
 * } QuasarProps
 */

const cDefaultQuasarPropsKey = 'defaultQuasarProps';

/**
 * @param {QuasarProps} props
 * @returns {QuasarProps}
 */
function getDefaultPropValues(props)
{
	return Object.entries(
		props
	).reduce((defaults, [key, value]) =>
	{
		// Convert empty strings to true for
		// <MyComponent myBooleanProp> prop usages.
		defaults[/** @type {keyof defaults} */(
			key
		)] = value === '' ?
			true :
			/** @type {*} */(value);

		return defaults;
	}, /** @type {QuasarProps} */({}));
}

/**
 * Used to provide props to all child components.
 * Global <QuasarProps> component available.
 *
 * @param {QuasarProps} props
 */
export function useQuasarPropsProvide(props)
{
	provide(
		cDefaultQuasarPropsKey,
		getDefaultPropValues(props)
	);
}

/**
 * @callback factory
 * @returns {QuasarProps}
 *
 * @param {(QuasarProps | null)} [defaultProps]
 * @returns {(factory | null)}
 */
function getDefaultPropsFactory(defaultProps)
{
	if(defaultProps === undefined)
	{
		return () => ({});
	}
	else if(defaultProps === null)
	{
		return null;
	}

	defaultProps = getDefaultPropValues(defaultProps);

	return () => ({ ...defaultProps });
}

/**
 * @type {import(
 * 	'@/types/composables/ui/quasarProps'
 * ).useQuasarPropsInject}
 */
export const useQuasarPropsInject = (
	defaultProps
) =>
{
	// This can be null. Cast is due to comment types' handling of overloads.
	// The `| null` return type gets lost here, despite working when used.
	// Apparently, TS 5 does support an @overload to declare doc type overloads.
	// It's not in the docs, just mentioned in the release notes,
	// but it works in their sandbox.
	// https://devblogs.microsoft.com/typescript/announcing-typescript-5-0-beta/#overload-support-in-jsdoc
	const defaultQuasarProps = /** @type {QuasarProps} */(inject(
		cDefaultQuasarPropsKey,
		getDefaultPropsFactory(defaultProps),
		// Have to cast since inject's definitions don't support boolean,
		// just true/false. Casting to true types defaultQuasarProps properly.
		/** @type {true} */(defaultProps !== null)
	));

	return {
		defaultQuasarProps
	};
};
