// @ts-check
import Vue from 'vue';
import { get, put, destroy } from '../../../../utils/api';

const state = {
	reservations: {}
};

const mutations = {
	SET_CONNECTION_TYPE_RESERVATIONS(state, { connectionTypeId, reservations })
	{
		if(connectionTypeId in state.reservations)
		{
			const connectionTypeReservations = state.reservations[connectionTypeId];

			if(!Array.isArray(reservations))
			{
				reservations = Object.values(reservations);
			}

			if(!connectionTypeReservations.reservations)
			{
				Vue.set(connectionTypeReservations, 'reservations', {});
			}

			reservations.forEach((reservation) =>
			{
				if(!connectionTypeReservations.reservations[reservation.reservationId]?.isDeleted)
				{
					if(!reservation.dataRequestedAt)
					{
						reservation.dataRequestedAt = Date.now();
					}

					Vue.set(
						connectionTypeReservations.reservations,
						reservation.reservationId,
						reservation
					);
				}
			});
		}
		else
		{
			if(Array.isArray(reservations))
			{
				reservations = reservations.reduce((index, reservation) =>
				{
					if(!reservation.dataRequestedAt)
					{
						reservation.dataRequestedAt = Date.now();
					}

					index[reservation.reservationId] = reservation;

					return index;
				}, {});
			}

			Vue.set(state.reservations, connectionTypeId, { reservations });
		}
	},
	SET_CONNECTION_TYPE_RESERVATIONS_COUNT(state, { connectionTypeId, count })
	{
		if(connectionTypeId in state.reservations)
		{
			Vue.set(
				state.reservations[connectionTypeId],
				'reservationsCount',
				count
			);
		}
		else
		{
			Vue.set(
				state.reservations,
				connectionTypeId,
				{ reservationsCount: count }
			);
		}
	},
	SET_RESERVATION(state, reservation)
	{
		const { connectionTypeId, reservationId } = reservation;

		if(!reservation.dataRequestedAt)
		{
			reservation.dataRequestedAt = Date.now();
		}

		if(connectionTypeId in state.reservations)
		{
			const connectionTypeReservations = state.reservations[connectionTypeId];

			if(connectionTypeReservations.reservations)
			{
				if(!(
					reservationId in connectionTypeReservations.reservations &&
					(
						connectionTypeReservations.reservations[reservationId].isDeleted ||
						reservation.dataRequestedAt <= connectionTypeReservations.reservations[reservationId].dataRequestedAt
					)
				))
				{
					Vue.set(
						connectionTypeReservations.reservations,
						reservationId,
						reservation
					);
				}
			}
			else
			{
				Vue.set(
					connectionTypeReservations,
					'reservations',
					{ [reservationId]: reservation }
				);
			}
		}
		else
		{
			Vue.set(
				state.reservations,
				connectionTypeId,
				{ reservations: { [reservationId]: reservation } }
			);
		}
	},
	CLEAR_RESERVATION(state, { connectionTypeId, reservationId })
	{
		if(connectionTypeId in state.reservations)
		{
			if(state.reservations[connectionTypeId].reservations && reservationId in state.reservations[connectionTypeId].reservations)
			{
				Vue.delete(
					state.reservations[connectionTypeId].reservations,
					reservationId
				);
			}
		}
	}
};

const actions = {
	async loadReservationsByConnectionTypeId({ commit }, {
		entityId,
		connectionTypeId,
		offset,
		limit
	})
	{
		try
		{
			const { data } = await get(
				`/applications/${entityId}/connections/${connectionTypeId}/reservations`,
				{ params: { offset, limit } }
			);

			commit('SET_CONNECTION_TYPE_RESERVATIONS', { connectionTypeId, reservations: data.items });

			return data;
		}
		catch(e)
		{
			console.error(e);

			return {
				items: [],
				total: 0,
				more: false
			};
		}
	},
	async createReservation({ dispatch }, reservation)
	{
		try
		{
			const { data: reservationId } = await put(
				`/applications/${reservation.entityId}/connections/${reservation.connectionTypeId}/reservation`,
				reservation
			);

			// Don't need to wait for these.
			dispatch(
				'profiles/getConnectionStats',
				{ accountId: reservation.followerAccountId, forceLoad: true },
				{ root: true }
			);

			dispatch(
				'profiles/getConnectionStats',
				{ accountId: reservation.leaderAccountId, forceLoad: true },
				{ root: true }
			);

			return {
				...reservation,
				reservationId,
				dataRequestedAt: Date.now()
			};
		}
		catch(e)
		{
			console.error(e.response?.data?.message || e);

			return e.response?.data?.message || false;
		}
	},
	async deleteReservation({ dispatch }, reservation)
	{
		try
		{
			await destroy(`/applications/connections/${reservation.connectionTypeId}/reservations/${reservation.reservationId}`);

			// Don't need to wait for these.
			dispatch(
				'profiles/getConnectionStats',
				{ accountId: reservation.followerAccountId, forceLoad: true },
				{ root: true }
			);

			dispatch(
				'profiles/getConnectionStats',
				{ accountId: reservation.leaderAccountId, forceLoad: true },
				{ root: true }
			);

			return {
				...reservation,
				dataRequestedAt: Date.now()
			};
		}
		catch(e)
		{
			console.error(e.response?.data?.message || e);

			return e.response?.data?.message || false;
		}
	},
	clearReservationsById({ commit }, { connectionTypeId, reservationIds })
	{
		if(Array.isArray(reservationIds))
		{
			reservationIds.forEach((reservationId) =>
			{
				commit('CLEAR_RESERVATION', { connectionTypeId, reservationId });
			});
		}
		else
		{
			commit('CLEAR_RESERVATION', { connectionTypeId, reservationId: reservationIds });
		}
	},
	async createConnection({ dispatch }, reservation)
	{
		try
		{
			const result = await dispatch(
				'applications/admin/createArrangedConnection',
				reservation,
				{ root: true }
			);

			if(result === true)
			{
				return {
					...reservation,
					dataRequestedAt: Date.now()
				};
			}

			return result;
		}
		catch(e)
		{
			console.error(e.response?.data?.message || e);

			return e.response?.data?.message || false;
		}
	},
	async addReservation({ commit, dispatch, getters, rootGetters }, reservation)
	{
		const { connectionTypeId, reservationId } = reservation;
		const storedReservation = getters.getOneById(connectionTypeId, reservationId);

		// It's either already been added, or it's marked as deleted.
		if(storedReservation) return false;

		commit('SET_RESERVATION', reservation);
		commit(
			'SET_CONNECTION_TYPE_RESERVATIONS_COUNT',
			{
				connectionTypeId,
				count: getters.getConnectionTypeReservationsCount(connectionTypeId) + 1
			}
		);

		let match = rootGetters['entities/connections/matches/get'](reservation);

		if(match)
		{
			match = {
				...match,
				reservationId
			};

			// Don't have to wait for the match.
			dispatch(
				'entities/connections/matches/setMatch',
				match,
				{ root: true }
			);
		}

		return true;
	},
	async removeReservation({ commit, dispatch, getters, rootGetters }, reservation)
	{
		const { connectionTypeId, reservationId } = reservation;
		const storedReservation = getters.getOneById(connectionTypeId, reservationId);

		if(storedReservation?.isDeleted) return false;

		commit('SET_RESERVATION', { ...reservation, isDeleted: true });
		commit(
			'SET_CONNECTION_TYPE_RESERVATIONS_COUNT',
			{
				connectionTypeId,
				count: getters.getConnectionTypeReservationsCount(connectionTypeId) - 1
			}
		);

		let match = rootGetters['entities/connections/matches/get'](reservation);

		if(match?.reservationId)
		{
			match = { ...match };
			delete match.reservationId;
			/**
			 * The `MatchListItem.vue` adds `isRematch`.
			 * Remove it too, in case it made it into the store.
			 */
			delete match.isRematch;

			// Don't have to wait for the match.
			dispatch(
				'entities/connections/matches/setMatch',
				match,
				{ root: true }
			);
		}

		return true;
	},
	async updateReservation({ commit, getters }, reservation)
	{
		const { connectionTypeId, reservationId } = reservation;
		const storedReservation = getters.getOneById(connectionTypeId, reservationId);

		if(
			storedReservation?.isDeleted ||
			reservation.dataRequestedAt < storedReservation.dataRequestedAt
		) return false;

		commit('SET_RESERVATION', reservation);

		return true;
	},
	setConnectionTypeReservationsCount({ commit }, connectionTypeReservationsCount)
	{
		commit('SET_CONNECTION_TYPE_RESERVATIONS_COUNT', connectionTypeReservationsCount);
	}
};

const getters = {
	/**
	 * @returns - The ID indexed object of reservations for the specified connection type.
	 */
	getByConnectionTypeId: (state) => (connectionTypeId) => (
		connectionTypeId ?
			state.reservations[connectionTypeId]?.reservations || {} :
			{}
	),
	getById: (state, getters) => (connectionTypeId, reservationIds) =>
	{
		const reservations = getters.getByConnectionTypeId(connectionTypeId);

		return reservationIds.reduce((agg, reservationId) =>
		{
			if(
				reservations[reservationId] &&
				!reservations[reservationId].isDeleted
			)
			{
				agg.push(reservations[reservationId]);
			}

			return agg;
		}, []);
	},
	getOneById: (state, getters) => (connectionTypeId, reservationId) => getters.getByConnectionTypeId(connectionTypeId)[reservationId],
	getParticipantAccountIdsByReservationId: (state, getters) => (connectionTypeId, reservationIds) =>
	{
		const reservations = getters.getByConnectionTypeId(connectionTypeId);
		const participantIds = {};

		if(Array.isArray(reservationIds))
		{
			reservationIds.forEach((reservationId) =>
			{
				if(reservations[reservationId])
				{
					const { followerAccountId, leaderAccountId } = reservations[reservationId];

					participantIds[followerAccountId] = true;
					participantIds[leaderAccountId] = true;
				}
			});
		}
		else if(reservations[reservationIds])
		{
			const { followerAccountId, leaderAccountId } = reservations[reservationIds];

			participantIds[followerAccountId] = true;
			participantIds[leaderAccountId] = true;
		}

		return Object.keys(participantIds);
	},
	getConnectionTypeReservationsCount: (state) => (connectionTypeId) => state.reservations[connectionTypeId]?.reservationsCount
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
