const KeyAlias = {};
let store;

export function getKeyForAlias(alias)
{
	return store.getters['app/settings/mappedKeyForAlias'](alias);
}

KeyAlias.install = function install(Vue, options)
{
	store = options.store;
	Vue.prototype.$alias = function returnKey(alias)
	{
		return getKeyForAlias(alias);
	};
};

export default KeyAlias;
