import Vue from 'vue';
import uuid from 'uuid/v4';

export default new Vue({
	data()
	{
		return {
			registeredForms: {},
			validateForms: {}
		};
	},
	methods: {
		registerForm(id, registrationContent, formSaverId = null)
		{
			if(!this.registeredForms)
			{
				this.registeredForms = {};
			}

			if(formSaverId && !this.registeredForms[formSaverId])
			{
				this.registeredForms[formSaverId] = {};
			}

			if(formSaverId)
			{
				this.registeredForms[formSaverId][id] = registrationContent;
			}
			else
			{
				this.registeredForms[id] = registrationContent;
			}

			return () =>
			{
				if(formSaverId)
				{
					delete this.registeredForms[formSaverId][id];
				}
				else
				{
					delete this.registeredForms[id];
				}
			};
		},
		registerAnonymousForm(registrationContent, formSaverId = null)
		{
			const id = uuid();

			return this.registerForm(id, registrationContent, formSaverId);
		},
		unRegisterForm(disposer)
		{
			if(disposer && typeof disposer === 'function')
			{
				disposer();
			}
		}
	}
});
