import Vue from 'vue';
import { get } from '@/utils/api';
import { setItems } from '@/utils/store';

const state = {
	powerUps: [],
	loadedWholePowerUp: false,
	custom: [],
	serviceRequestCounts: {}
};

const mutations = {
	SET_POWER_UPS(state, powerUps)
	{
		if(!Array.isArray(powerUps)) powerUps = [powerUps];

		setItems(state, 'powerUps', state.powerUps, powerUps, 'id', { freeze: true });
	},
	SET_SERVICE_REQUEST_COUNTS(state, { accountId, connectionId, counts })
	{
		Vue.set(state.serviceRequestCounts, accountId, { [connectionId]: counts });
	},
	RESET(state)
	{
		Vue.set(state, 'powerUps', []);
	},
	EVERYTHING_STORED(state)
	{
		state.loadedWholePowerUp = true;
	}
};

const actions = {
	setPowerUps({ commit }, powerUps)
	{
		if(!powerUps) return;

		commit('SET_POWER_UPS', powerUps);
	},
	reset({ commit })
	{
		commit('RESET');
	},
	async loadWholePowerUpFile({ dispatch, commit, state })
	{
		if(!state.loadedWholePowerUp)
		{
			const { data } = await get('structure/powerUp');

			await dispatch('app/handleServerResponse', data, { root: true });
			commit('EVERYTHING_STORED');
		}
	},
	async getServiceRequestCounts({ commit, getters }, { accountId, connectionId, forceLoad })
	{
		if(!forceLoad && getters.getServiceRequestCounts(accountId, connectionId)) return;

		const { data } = await get(`entities/getServiceRequestCounts/${connectionId}/${accountId}`);

		commit('SET_SERVICE_REQUEST_COUNTS', { accountId, connectionId, counts: data.counts });
	}
};

const getters = {
	getPowerUps: (state) => state.powerUps,
	loadedWholePowerUp: (state) => state.loadedWholePowerUp,
	byId: (state, getters) => (id) => getters.getPowerUps.find((powerUp) =>	powerUp.id === id),
	getFieldsForPowerUp: (state, getters, rootState, rootGetters) => (powerUpId) =>
	{
		const powerUp = getters.byId(powerUpId);
		const { fields } = powerUp;

		return fields.map((fieldId) => rootGetters['structure/fields/getField'](fieldId));
	},
	getServiceRequestCounts: (state) => (accountId, connectionId) => state.serviceRequestCounts[accountId]?.[connectionId]
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
