var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:{ 'display-inline-block': _vm.inline }},[_c('div',[(!_vm.hideActivatorWhenOpen || (_vm.hideActivatorWhenOpen && !_vm.modelValue))?_vm._t("activator",null,null,_vm.slotProps):_vm._e()],2),_c('q-dialog',_vm._b({staticStyle:{"z-index":"9999"},attrs:{"noEscDismiss":_vm.hasInnerModal,"maximized":_vm.lightroom || _vm.maximized,"contentStyle":_vm.contentStyle},on:{"hide":_vm.modalClosed,"shake":_vm.onShake,"show":_vm.show},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},'q-dialog',_vm.qProps,false),[_c('q-card',{class:[
				_vm.cardClasses,
				_vm.blockData ? `item-id-${_vm.blockData.id}` : undefined
			],style:(_vm.modalStyle),attrs:{"dark":_vm.dark || _vm.lightroom}},[(_vm.editBlockMode)?_c('q-bar',[_c('BlockToolbar',{attrs:{"data":_vm.blockData,"showOnlyControls":""}})],1):_vm._e(),(!_vm.hideToolbar)?_c('q-toolbar',{class:{
					'defaultDarkModalTool': _vm.dark,
					'uniform-modal-toolbar': _vm.uniformDesign,
					'dark-mode': _vm.darkMode,
					'bordered': !_vm.uniformDesignBorderless,
					'more-padding': _vm.morePadding,
					'lightroom-toolbar': _vm.lightroom
				}},[(_vm.icon)?_c('span',{staticClass:"title-icon"},[_c('q-icon',{attrs:{"name":_vm.icon,"color":"grey-9","size":"24px"}})],1):_vm._e(),(_vm.title)?_c('span',{staticClass:"title-content text-h6"},[_vm._v(_vm._s(_vm.title))]):_vm._t("title",null,null,_vm.slotProps),_c('q-space'),_vm._t("toolbar-controls",null,null,_vm.slotProps),(!_vm.hideCloseButton || (_vm.editMode && _vm.userCanBuild))?_c('SimpleButton',{directives:[{name:"close-popup",rawName:"v-close-popup",value:(!_vm.preventClosing),expression:"!preventClosing"}],staticClass:"close",attrs:{"round":"","flat":"","size":_vm.uniformDesign ? '10px' : '14px',"icon":_vm.uniformDesign ? 'fal fa-times' : 'far fa-times',"aria-label":_vm.$t('modal.close'),"color":(_vm.dark || _vm.lightroom || _vm.darkMode) ? 'grey-1' : _vm.uniformDesign ? 'grey-9' : 'primary'},on:{"click":_vm.closeModal}}):_vm._e()],2):_vm._e(),_c('q-card-section',{class:_vm.mainContentClasses},[_vm._t("default",null,null,_vm.slotProps)],2),_c('q-card-actions',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowControls),expression:"shouldShowControls"}],class:{
					'uniform-modal-actions': _vm.uniformDesign,
					'dark-mode': _vm.darkMode,
					'bordered': !_vm.uniformDesignBorderless
				}},[_c('div',{staticClass:"items-center rjbrv full-width"},[_c('div',{staticClass:"ric"},[_vm._t("controls",null,null,_vm.slotProps)],2),_vm._t("controlsLeft",null,null,_vm.slotProps)],2)]),_c('q-inner-loading',{attrs:{"showing":_vm.isLoading}},[_c('q-spinner')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }