import Vue from 'vue';
import { pick } from 'dot-object';
import admin from '@/store/applications/admin';
import membershipRequests from '@/store/applications/membershipRequests';
import connectionRequests from '@/store/applications/connectionRequests';
import { get, put, post } from '@/utils/api';
import { entityRoute } from '@/utils/routerLinkGenerator';
import { powerUps } from '@/configs/constants';

const modules = {
	admin,
	membershipRequests,
	connectionRequests
};

const state = {
	applications: [],
	states: {},
	loading: false,
	possibleStates: null
};

const mutations = {
	SET_APPLICATIONS(state, applications)
	{
		Vue.set(state, 'applications', applications);
	},
	SET_APPLICATION(state, application)
	{
		const currentIndex = state.applications.findIndex((app) => app.id === application.id);

		if(currentIndex > -1)
		{
			Vue.set(state.applications, currentIndex, application);
		}
		else
		{
			state.applications.push(application);
		}
	},
	SET_STATE(state, { applicationId, stateName })
	{
		Vue.set(state.states, applicationId, stateName);
	},
	CLEAR(state)
	{
		Vue.set(state, 'applications', []);
	},
	SET_APPLICATION_PROPERTY(state, { applicationId, property, value })
	{
		const currentIndex = state.applications.findIndex((application) => application.id === applicationId);
		const newApplication = { ...state.applications[currentIndex], [property]: value };

		Vue.set(state.applications, currentIndex, newApplication);
	},
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	},
	SET_POSSIBLE_STATES(state, possibleStates)
	{
		Vue.set(state, 'possibleStates', possibleStates);
	},
	REMOVE_APPLICATION(state, applicationId)
	{
		state.applications = state.applications.filter((app) => app.id !== applicationId);
	}
};

const actions = {
	async loadApplication({ dispatch, getters }, applicationId)
	{
		if(!getters.getApplication(applicationId) && applicationId)
		{
			dispatch('setLoading', true);

			const { data } = await get(`applications/${applicationId}`);

			dispatch('app/handleServerResponse', data, { root: true });
			dispatch('setLoading', false);
		}
	},
	loadApplications({ dispatch }, applicationIds)
	{
		if(!Array.isArray(applicationIds)) applicationIds = [applicationIds];

		applicationIds.forEach((id) => dispatch('loadApplication', id));
	},
	async loadStates({ dispatch })
	{
		dispatch('setLoading', true);

		const { data } = await get('applications/user');

		dispatch('setStates', data);

		dispatch('setLoading', false);
	},
	async loadState({ dispatch }, applicationId)
	{
		if(applicationId)
		{
			dispatch('setLoading', true);

			const { data } = await get(`applications/user/${applicationId}`);

			dispatch('setState', { applicationId, ...data });

			dispatch('setLoading', false);
		}
	},
	async createApplication({ dispatch, rootGetters }, {
		entityId,
		applicationId,
		membershipId,
		formData
	})
	{
		const accountId = rootGetters['user/accountId'];

		dispatch('setLoading', true);

		try
		{
			await dispatch('profiles/saveProfileData', {
				accountId,
				formData
			}, { root: true });

			const { data } = await put(
				`applications/${applicationId}/request/${accountId}/${entityId}`,
				{
					membershipId
				}
			);

			dispatch('setState', { applicationId, state: data.state });
			dispatch('user/upsertListMembership', data, { root: true });

			if(data.state === 'accepted')
			{
				dispatch('profiles/addToAcceptedList', { accountId, listId: data.userListId }, { root: true });

				// go to the landing page if toggled on the membership
				await dispatch(
					'entities/load',
					{
						id: entityId,
						force: true
					},
					{
						root: true
					}
				);

				const entity = rootGetters['entities/byId'](entityId);
				const memberships = entity?.powerUpData?.[powerUps.membership]?.membershipType;

				if(memberships?.length)
				{
					const membership = memberships.find((m) => (m.id === membershipId));

					if(membership?.redirectToLandingPage?.value === true)
					{
						await entityRoute(entity, {
							pageId: rootGetters['entities/getLandingPageForMembership'](entityId, membershipId),
							push: true
						});
					}
				}
			}
		}
		catch(e)
		{
			console.error('Failed to change state', e);
		}
		finally
		{
			dispatch('setLoading', false);
		}
	},
	async changeApplicationState({ dispatch, rootGetters }, { applicationId, state, entityId })
	{
		dispatch('setLoading', true);

		try
		{
			const { data } = await post(`applications/${applicationId}/request/me/${state}/${entityId}`);

			if(!data.success)
			{
				throw new Error('Something went wrong when saving');
			}

			dispatch('setState', { applicationId, state: data.application.state });

			const accountId = rootGetters['user/accountId'];

			if(data.application.state === 'accepted')
			{
				dispatch('user/upsertListMembership', data.application, { root: true });
				dispatch('profiles/addToAcceptedList', { accountId, listId: data.application.userListId }, { root: true });
			}
			else
			{
				dispatch('user/removeListMembership', data.application.id, { root: true });
				dispatch('profiles/removeFromAcceptedList', { accountId, listId: data.application.userListId }, { root: true });
			}
		}
		catch(e)
		{
			console.error('Failed to change state', e);
		}
		finally
		{
			dispatch('setLoading', false);
		}
	},
	setApplications({ dispatch }, applications)
	{
		if(!applications || !applications.length) return;

		applications.forEach((application) => dispatch('setApplication', application));
	},
	setApplication({ commit }, application)
	{
		commit('SET_APPLICATION', application);
	},
	setApplicationProperty({ commit }, payload)
	{
		commit('SET_APPLICATION_PROPERTY', payload);
	},
	setStates({ dispatch }, states)
	{
		Object.entries(states).forEach(([applicationId, state]) => dispatch('setState', { applicationId, state }));
	},
	setState({ commit }, { applicationId, state })
	{
		commit('SET_STATE', { applicationId, stateName: state });
	},
	clear({ commit })
	{
		commit('CLEAR');
	},
	reset({ dispatch })
	{
		dispatch('clear');
	},
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	},
	async loadPossibleStates({ commit, state })
	{
		if(state.possibleStates) return;

		const { data } = await get('applications/states');

		commit('SET_POSSIBLE_STATES', data);
	},
	removeApplication({ commit }, { applicationId })
	{
		commit('REMOVE_APPLICATION', applicationId);
	}
};

const getters = {
	getApplications: (state) => state.applications,
	getApplication: (state, getters) => (applicationId) => getters.getApplications.find((application) => application.id === applicationId),
	loading: (state) => state.loading,
	getApplicationState: (state) => (applicationId) => state.states[applicationId],
	possibleStates: (state) => state.possibleStates,
	getValueByPath: (state, getters) => (id, path) =>
	{
		const application = getters.getApplication(id);

		if(!application)
		{
			return undefined;
		}

		return pick(path, application);
	}
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
