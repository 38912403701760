import { pick } from 'dot-object';
import sanitizeHtml, { defaults } from 'sanitize-html';
import Handlebars from 'handlebars';

const slugify = (value) =>
{
	// A quick and easy way to slug a value, on the backend we'll use the `url-slug` package which has unidecode
	return value.toString().toLowerCase().trim()
		.replace(/&/g, '-and-')
		.replace(/[\s\W-]+/g, '-');
};

const formatFileSize = (size) =>
{
	const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const threshold = 1024;

	size = Number(size) * threshold;

	const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold));

	const float = parseFloat((size / (threshold ** i)).toFixed(2));

	return `${float * 1} ${units[i]}`;
};

// Based on discussion around inclusion of a function like this in the uuid module, also saves installing an entire module just for this
// https://github.com/uuidjs/uuid/pull/72/files/6f2b55d9c632ed7c97dec8451d41cdba28638933#r6502478
const uuidValidate = (str) => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i.test(str);

/**
 * This will check for an EXISTING value from the ones provided - if one is found, it will be returned and the function exits.
 */
const returnFirstExistingValue = (...values) =>
{
	for(let i = 0; i < values.length; i += 1)
	{
		if(typeof values[i] !== 'undefined' && values[i] !== null)
		{
			return values[i];
		}
	}

	return undefined;
};

/**
 * Strip matching XML tags from a string
 *
 * @param {String} string - The string to check for matching XML tags
 * @param {String|String[]} tags - A string or array of tag names to match against
 */
const stripHtmlTags = (string, tags = []) =>
{
	if(!string || typeof string !== 'string')
	{
		return string;
	}

	if(!Array.isArray(tags))
	{
		tags = [tags];
	}

	// eslint-disable-next-line no-useless-escape
	const expString = `<\/?(${tags.join('|')})[^>]*>\n?`;
	const exp = new RegExp(expString, 'g');

	return string.replace(exp, '');
};
/**
 * finds and returns last nested object in nested object
 * will continue to loop until last nested object is found within the passed object
 * @param {object} nestedObj a object with nested objects
 * @return {object} returns only the last nested object
 */
const getNestedObj = (nestedObj) => // NOTE:: expansion for first || last || index
{
	const [objectKeyName] = Object.keys(nestedObj);

	if(objectKeyName)
	{
		const searchKey = getNestedObj(nestedObj[objectKeyName]);

		if(searchKey)
		{
			return searchKey;
		}

		return nestedObj;
	}

	return null;
};
/**
 * Sending string with prefix, returns an nested array via prefix.
 * @param {object} [obj=[]] - a empty obbject is passed (or object you want to append to)
 * @param {string} flatString - a string that will be used to convert into nested objects
 * @param {string} [prefix=.] - the prefix used to split by into objects
 * @param {number} [illiteration=0] - illiteration is used for recursive, no need to pass a param here.
 * @return {object[]} - an object with all nested objects into original object passed
 */
const multiNestFromString = (obj = [], flatString, prefix = '.', illiteration = 0) =>
{
	const arrFlatString = flatString.split(prefix);
	const newKeyName = arrFlatString[illiteration];
	const newObjToAppend = {
		[newKeyName]: {}
	};

	if(illiteration >= 1)
	{
		const lastKey = getNestedObj(obj);

		Object.assign(lastKey[Object.keys(lastKey)[0]], newObjToAppend);
	}
	else
	{
		Object.assign(obj, newObjToAppend);
	}

	if(illiteration >= arrFlatString.length - 1)
	{
		return obj;
	}

	return multiNestFromString(obj, flatString, prefix, illiteration + 1);
};

/**
 * converts a short hex value to a long hex value
 * @param {string} shortHex: Short hex eg. #fff
 * @return {string} returns long hex value or null if a short hex is not provided correctly
 **/
const convertShortHexToLongHex = (shortHex) =>
{
	if(shortHex.length === 4)
	{
		return `#${shortHex[1]}${shortHex[1]}${shortHex[2]}${shortHex[2]}${shortHex[3]}${shortHex[3]}`;
	}

	return null;
};

/**
 * converts long hex to rgb
 * @param {string} hex: long hex required eg. #ffffff
 * @return {string} returns rgb value or null if long hex is not provided
 **/
const convertHexToRgb = (hex) =>
{
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

	return result ? [
		parseInt(result[1], 16),
		parseInt(result[2], 16),
		parseInt(result[3], 16)
	] : null;
};

/**
 * return a legible colour, depending on the colour given.
 * @param {string} colorValue: color given either in hex or rgb (hex will be converted to rgb)
 * @return {string} returns either white or black hex value, depending on colour given
 **/
const legibleColorContrast = (colorValue) =>
{
	const colorThreshold = 186;
	let rgbValue;

	if(!colorValue) return colorValue;

	if(colorValue.includes('#'))
	{
		if(colorValue.length === 4)
		{
			rgbValue = convertShortHexToLongHex(colorValue);
			rgbValue = convertHexToRgb(rgbValue);
		}
		else
		{
			rgbValue = convertHexToRgb(colorValue);
		}
	}
	else
	{
		rgbValue = colorValue.replace(/[^\d,]/g, '').split(',');
	}

	if((rgbValue[0] * 0.299 + rgbValue[1] * 0.587 + rgbValue[2] * 0.114) > colorThreshold)
	{
		return '#18191a';
	}

	return '#ffffff';
};

/**
 * Initialises the desired variable from a path, or a default value
 */
const optionalValue = (path, object, defaultValue = null) =>
{
	return pick(path, object) || defaultValue;
};

/**
 * Wraps around `sanitize-value` to provide a quick way to, either:
 * * remove all HTML
 * * allow only a select few tags and attributes (but still removing,
 * among other things, event attributes (e.g. `onmouseover`)).
 * @param {string} valueToSanitize
 * @param {boolean} allowHtml `false` by default. When true, HTML
 * won't be stripped, but only certain tags and attributes are allowed.
 * @returns The sanitised value.
 */
const sanitizeValue = (valueToSanitize, allowHtml = false) =>
{
	// jest... grrrr
	if(!sanitizeHtml || !defaults)
	{
		return '';
	}

	// no html should be rendered outside of HTML / script block.
	const stripItAllOptions = { allowedTags: [] };

	const sanitize = (value, options) => sanitizeHtml(value, options).replace(/&amp;/, '&');

	if(!allowHtml)
	{
		// sanitizeHtml doesn't let us ignore `&` so we have to reconvert it...
		return sanitize(valueToSanitize, stripItAllOptions);
	}

	// For when redering inside of an HTML block.
	const keepSomeOfItOptions = {
		allowedSchemes: defaults.allowedSchemes,
		allowedSchemesByTag: defaults.allowedSchemesByTag,
		allowedSchemesAppliedToAttributes: defaults.allowedSchemesAppliedToAttributes,
		allowProtocolRelative: defaults.allowProtocolRelative,
		enforceHtmlBoundary: defaults.enforceHtmlBoundary,
		allowVulnerableTags: true,
		allowedTags: defaults.allowedTags.concat([
			'img',
			'iframe',
			'embed',
			'style'
		]),
		allowedAttributes: {
			...defaults.allowedAttributes,
			// see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes
			'*': [
				'autofocus',
				'contextmenu',
				'data-*',
				'style',
				'inert',
				'inputmode',
				'itemid',
				'itemprop',
				'itemref',
				'itemscope',
				'itemtype',
				'lang',
				'nonce',
				'part',
				'popver',
				'slot',
				'spellcheck',
				'translate',
				'virtualkeyboardpolicy',
				'class',
				'hidden',
				'title',
				'dir',
				'id',
				'role',
				'tabindex',
				'type',
				'aria-*'
			],
			// See https://developer.mozilla.org/en-US/docs/Web/HTML/Element
			a: [
				'href',
				'name',
				'download',
				'rel',
				'target'
			],
			embed: [
				'height',
				'src',
				'type',
				'width'
			],
			iframe: [
				'src',
				'srcdoc',
				'allow',
				'width',
				'height',
				'title',
				'allowfullscreen',
				'csp',
				'name',
				'sandbox',
				'loading',
				'referrerpolicy',
				'frameborder'
			],
			img: [
				'caption',
				'ref',
				'width',
				'height',
				'data-id',
				'alt',
				'crossorigin',
				'fetchpriority',
				'loading',
				'referrerpolicy',
				'sizes',
				'srcset',
				'src'
			],
			p: [
				'data-text-align'
			],
			blockquote: [
				'cite'
			],
			li: [
				'value'
			],
			data: [
				'value'
			],
			q: [
				'cite'
			],
			datetime: [
				'cite'
			],
			col: [
				'span'
			],
			colgroup: [
				'span'
			],
			ol: [
				'reversed',
				'start',
				'type'
			],
			td: [
				'colspan',
				'headers',
				'rowspan'
			],
			th: [
				'abbr',
				'colspan',
				'headers',
				'rowspan',
				'scope'
			]
		},
		selfClosing: [
			'img',
			'br',
			'hr'
		]
	};

	return sanitize(valueToSanitize, keepSomeOfItOptions);
};

// 1MB = 1024^2 bytes
const displayBytesInMb = (bytes) => `${(bytes / (1024 ** 2)).toFixed(1)}MB`;
/**
 * Compiles a Handlebars string with provided data.
 *
 * @param {string} data - The handlebars string to be compiled.
 * @param {Object} templateContext - The context object containing the data.
 * @param {Object} [templateContext.entity] - The entity data used in the template.
 * @param {Object} [templateContext.entity.context] - Additional context data for the entity.
 * @param {Object} [templateContext.profile] - The profile data.
 * @param {Object} [templateContext.user] - The user data.
 * @returns {string} - The rendered template as a string.
 */
const handlebarsCompile = (data, templateContext = {}) =>
{
	try
	{
		const modifiedData = data.replace(/{{entity\.(\w+)}}/g, (match, key) =>
		{
			// first look at root, if not found, try context
			if(
				!templateContext.entity?.[key] &&
				templateContext.entity?.context?.[key]
			)
			{
				return `{{entity.context.${key}}}`;
			}

			return match;
		});

		const template = Handlebars.compile(modifiedData);
		const renderedTemplate = template(templateContext);

		return renderedTemplate.toString();
	}
	catch(e)
	{
		console.warn(e);

		return data;
	}
};

const getObjectFromUrl = (url) =>
{
	const processedUrl = new URL(url);

	if(!processedUrl.hostname || !processedUrl.host)
	{
		throw new Error('Invalid URL: missing hostname or host.');
	}

	return processedUrl;
};

/**
 * Determines whether an array includes a given
 *  array of values to test.
 * @param testedArray
 * @param valuesToTest
 */
const arrayIncludesAnyOf = (testedArray, valuesToTest) =>
{
	return testedArray.some((valueInTestedArray) => valuesToTest.includes(valueInTestedArray));
};

/**
 * @returns Determines whether an array includes other values
 * than the given value.
 */
const arrayIncludesOtherThan = (testedArray, ignoredValue) =>
{
	return testedArray.some((valueInTestedArray) => valueInTestedArray !== ignoredValue);
};

const cExternalLinkProtocols = [
	'http',
	'https',
	'ftp',
	'ftps',
	'sftp',
	'tftp',
	'oftp',
	'aftp',
	'scp',
	'mailto',
	'smtp'
];

const getIsExternalLink = (link) =>
{
	if(!link) return false;

	const pattern = new RegExp(`^${cExternalLinkProtocols.join('|^')}`);

	return pattern.test(link);
};

/**
 * Given an ambiguous URL (e.g. 'google.com'), add 'https://'
 * if necessary. This is used to fix links entered by users.
 * @param {string} link - The URL to interpret
 * @returns {string} - The URL as a usable link
 */
const interpretUrlOrRoute = (link) =>
{
	if(!link || typeof link !== 'string')
	{
		return '';
	}

	link = link.replace(/http:\/\//gi, 'https://');

	if(
		link.slice(0, 6) === 'mailto' || // is email
		link.slice(0, 1) === '/' || // is internal
		link.indexOf('://') > -1 // has protocol
	)
	{
		return link;
	}

	if(link.indexOf('.') < 0) // definitely probably internal
	{
		return `/${link}`;
	}

	if(link.indexOf('https://') < 0)
	{
		return `https://${link}`;
	}

	return link;
};

/**
 * Get the attributes of an element tag. This supports any element.
 * It is being used here to extract the img tag's data-id, load
 * the image to show in the preview, and replace the tag with other
 * attributes intact.
 * @param {string} elAsString - The tag to get attrs from
 * @return {{[key: string]: string|number}}
 */
const getAttributesOfElement = (elAsString) =>
{
	const tempElement = document.createElement('div');

	tempElement.innerHTML = elAsString;

	const [element] = tempElement.childNodes;
	const { attributes } = element;

	const attrs = {};

	attributes.forEach((attribute) =>
	{
		attrs[attribute.name] = attribute.value;
	});

	tempElement.remove();

	return attrs;
};

export {
	slugify,
	formatFileSize,
	uuidValidate,
	returnFirstExistingValue,
	stripHtmlTags,
	getNestedObj,
	multiNestFromString,
	convertShortHexToLongHex,
	convertHexToRgb,
	legibleColorContrast,
	getObjectFromUrl,
	optionalValue,
	sanitizeValue,
	displayBytesInMb,
	handlebarsCompile,
	getIsExternalLink,
	interpretUrlOrRoute,
	arrayIncludesOtherThan,
	arrayIncludesAnyOf,
	getAttributesOfElement
};
