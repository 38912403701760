import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';
import uuid from 'uuid/v4';
import bus from '@/components/form/elements/utils/bus';
import { userCan } from '@/plugins/Permissions';
import { Utils } from 'acb-lib';
import { cFormInputKeyCodes } from '@/configs/constants';

export default {
	inject: {
		targetId: {
			default: null
		},
		defaultQuasarProps: {
			default: {}
		},
		i18nPath: {
			default: null
		},
		formInstance: {
			default: () => () => ({})
		},
		$alwaysEdit: {
			default: false
		},
		$keyPressHandlingOverride: {
			default: null
		}
	},
	components: {
		ErrorMessages: () => import('@/components/form/elements/utils/ErrorMessages'),
		EditButton: () => import('@/components/form/elements/utils/EditButton'),
		SubmitButtons: () => import('@/components/form/elements/utils/SubmitButtons'),
		InputLabel: () => import('@/components/form/elements/utils/InputLabel')
	},
	props: {
		blockId: {
			type: [String, null],
			default: null
		},
		field: {
			type: Object,
			required: true
		},
		value: {
			type: [String, Boolean, Object, Array, Number],
			default: null
		},
		repeatersKey: {
			type: Number,
			default: undefined
		},
		fieldInt: {
			type: Number,
			default: undefined
		},
		formId: {
			type: String,
			default: undefined
		},
		disabled: {
			type: Boolean,
			default: false
		},
		alwaysEdit: {
			type: Boolean,
			default: false
		},
		editable: {
			type: Boolean,
			default: false
		},
		quasarProps: {
			type: Object,
			default: () => ({})
		},
		inputTabIndex: {
			type: Number,
			default: null
		},
		restrictValueRegex: {
			type: RegExp,
			default: undefined
		},
		autosave: {
			type: Boolean,
			default: false
		},
		showLabel: {
			type: Boolean,
			default: true
		}
	},
	data()
	{
		const valueNoReference = JSON.parse(JSON.stringify(this.value));

		// We have to throttle this, otherwise we can re-run this code before the 'error bag' has updated, and therefore we pick up old error messages
		this.getErrorMessages = throttle(this.getErrorMessages, 50, { leading: false, trailing: true });

		return {
			debounceEmitChanges: debounce((field) => this.emitChanges(field), 250),
			uuid: uuid(),
			inputVal: valueNoReference,
			lastClickTime: null,
			initialInputValue: valueNoReference,
			lastKeyPress: null,
			validationRules: {},
			errorMessages: []
		};
	},
	watch: {
		inputVal: {
			handler(newVal, oldVal)
			{
				if(isEqual(newVal, this.initialInputValue)) // it's not dirty if it's the same as the initial value
				{
					bus.$emit('cleanField', this.formId, this.field.id);
				}
				else
				{
					bus.$emit('dirtyField', this.formId, this.field.id);
				}

				// Allows users of ElementMixin to override the default behaviour.
				if(typeof this.onInputValUpdateOverride === 'function')
				{
					this.onInputValUpdateOverride(newVal, oldVal);

					return;
				}

				if(!isEqual(newVal, oldVal))
				{
					this.fieldUpdated(true);
				}
			},
			deep: true
		},
		value: {
			handler(newVal, oldVal)
			{
				if(this.noValueWatch)
				{
					return;
				}

				// If the `value` prop is updated, and actually changed, we need to change `inputVal`
				if(!isEqual(newVal, oldVal))
				{
					this.inputVal = JSON.parse(JSON.stringify(this.value));

					if(this.$store.getters['user/isAdmin'])
					{
						this.$set(this, 'validationRules', this.createValidationRules()); // if it's an admin, they may have changed the rules so make sure the rules are up-to-date
					}

					this.getErrorMessages(); // trigger manually, so it only runs for the field that's updated
				}
			},
			deep: true
		}
	},
	computed: {
		I18NPath()
		{
			if(this.i18nPath) return `${this.i18nPath}.${this.field.id}`;

			return this.field.i18nPath || `custom.fields.${this.field.id}`;
		},
		I18NPathBlock()
		{
			if(this.i18nPath) return `${this.i18nPath}`;

			return `custom.blocks.${this.blockId}`;
		},
		// old way of doing it - unfortunately it triggers several times for each field when any field on the form is updated
		/* validationRules()
		{
			if(!this.field.validation) return {};

			// If somehow we get some props that aren't supported, they break things. This is the list of things we allow
			const acceptedRules = this.$store.getters['structure/fields/getAcceptedValidation'](this.field.type);
			const rules = {};

			Object.keys(this.field.validation).forEach((rule) =>
			{
				if(!acceptedRules.includes(rule)) return;

				if(this.field?.validation?.[rule] !== null)
				{
					rules[rule] = this.field.validation[rule];
				}
				else
				{
					rules[rule] = false;
				}
			});

			return rules;
		},
		// old way of doing it - unfortunately it triggers several times for each field when any field on the form is updated
		errorMessages()
		{
			// this.$set(this, 'errorMessages', this.errors.collect(this.field.name)); // Even though this is the correct way to do it from the documentation this isn't reactive with child components :/ I.e. when you press Save on a form and there are errors, it doesn't show the errors on the field, only at the end of the form

			// console.log('errorMessages', this.errorMessages);

			const errors = [];

			if(!this.errors)
			{
				return errors;
			}

			this.errors.items.forEach((error) =>
			{
				let formId,
					fieldId,
					repeatersKey;

				// In various places, we set this field value to ${formId}|${fieldId}
				// On occasion, error.field can return only a single ID, which is always the fieldId
				// The original assignment (see if statement below) would obviously set this up wrong and cause validation to break
				// Ideally we would find the root cause of the problem, but it's been 3 days and this isn't what I was supposed to be doing
				// If you find the bad assignment (bearing in mind it doesn't always happen), please update it
				//
				// TODO Find the missing ${formId}|${field.id} assignment and add it
				// This may well have something to do with where and when it is set after being requested by getFields() in FormMixin
				// Only the field ID is returned of course, and each field has no concept of it's parent form
				if(error?.field?.includes('|'))
				{
					[formId, fieldId, repeatersKey] = error.field.split('|');
				}
				else
				{
					fieldId = error.field;
				}

				// See above comments for why we might pass over this check in the event that there is no formId accepted
				if(formId !== this.formId && (!this.field.id || !fieldId))
				{
					return;
				}

				// check if the error belongs to this field in the repeater item
				if(fieldId === this.field.id && repeatersKey === `${this.repeatersKey}`)
				{
					errors.push(error.msg);
				}
			});

			return errors;
		}, */
		errorsFound()
		{
			return this.errorMessages.length !== 0;
		},
		fieldSource()
		{
			return this.field.source;
		},
		fieldKey()
		{
			return this.field.key;
		},
		isAdmin()
		{
			return (this.$store.getters['admin/isEditMode'] || this.alwaysEditable) && this.editable && userCan('manageEditMode', 'administration');
		},
		isEditing()
		{
			return process.env.NODE_ENV === 'development' && this.editable && (this.$store.getters['i18n/admin/isEditingModeEnabled'] || this.alwaysEditable);
		},
		opts()
		{
			return this.field.opts || {};
		},
		displayBlock()
		{
			if(this.field.relatedBlocks && this.field.relatedBlocks.display)
			{
				return this.$store.getters['structure/blocks/getBlock'](this.field.relatedBlocks.display);
			}

			return null;
		},
		repeaterIsDeleted()
		{
			const instance = this.getFormInstance();

			return instance?.repeaterIsDeleted;
		}
	},
	created()
	{
		this.field.id = this.field.id || this.field.key || this.field.name;
		this.field.name = this.field.name || this.field.id || this.field.key;
		this.field.key = this.field.key || this.field.id || this.field.name;

		if(!this.field.id)
		{
			console.error('ElementMixin field setup error:', this.field);
			throw new Error('You must provide either an [\'id\', \'key\', \'name\'] for your field.');
		}
	},
	mounted()
	{
		this.$set(this, 'validationRules', this.createValidationRules());
		bus.$on('submitForm', this.updateInitialInputValue);

		// formId not guaranteed
		// reacting to unspecified reset requests can erroneously reset fields
		if(this.formId)
		{
			// TODO: add handling to propagate reset events with composite formIds - will allow main form reset, as well as reset of individual, nested forms, e.g. repeaters
			// check for the main form's id
			// otherwise, repeaters won't reset
			bus.$on(`resetFields:${this.formId.split('#')[0]}`, this.reset);
		}

		bus.$on(`${this.formId}|${this.field.id}|${this.repeatersKey}`, this.getErrorMessages);

		// await intentionally omitted
		this.setDefaultValue();
	},
	beforeDestroy()
	{
		bus.$off(`${this.formId}|${this.field.id}|${this.repeatersKey}`, this.getErrorMessages);
	},
	destroyed()
	{
		bus.$off('submitForm', this.updateInitialInputValue);

		if(this.formId)
		{
			bus.$off(`resetFields:${this.formId.split('#')[0]}`, this.reset);
		}
	},
	methods: {
		/**
		 * Go through the listener/watcher route to update the value to the default value,
		 * so Form side and the store data cache would also be aware of the change and conditional fields would work
		 */
		async setDefaultValue()
		{
			await Utils.sleep(300); // either the listeners aren't ready or the initial value hasn't been set up yet... either way, we need to wait to set the default value

			if(!this.value && this.field.default)
			{
				this.inputVal = JSON.parse(JSON.stringify(this.field.default));
			}
		},
		// TODO: some inputs might not react correctly, if at all, to programmatic changes, e.g. pretty sure WYSIWYG doesn't, repeaters don't handle array length changes that well, etc.
		// if using this, be sure to test inputs and fix as needed
		reset()
		{
			this.inputVal = this.initialInputValue;
			// ICE - potential patch, so repeaters (maybe any input heavy forms) reset correctly. Still has at least one quirk for repeaters, where added items might not get cleared, if the new array length > initial array length
			// this.fieldUpdated();
		},
		updateInitialInputValue(formId)
		{
			if(formId === this.formId)
			{
				// note: don't use the data provided by the event, keep using value prop
				// otherwise, things, like Form.vue's submitForm(), can invoke this, update initialInputValue with the submitted form data, causing a second reset to go wrong, since it no longer has the real initial data any more
				this.initialInputValue = JSON.parse(JSON.stringify(this.value));
			}
		},
		getFormInstance()
		{
			return this.formInstance();
		},
		getFormFieldData(fieldId)
		{
			const formInstance = this.getFormInstance();

			if(formInstance)
			{
				return formInstance.getInputValue(fieldId);
			}

			return undefined;
		},
		getI18N(key, path = false, force = true)
		{
			const variables = this.field?.i18nVariables;

			if(this.isEditing || path)
			{
				if(!path && this.field[key])
				{
					return this.field[key];
				}

				return `${this.I18NPath}.${key}`;
			}

			if(this.field[key])
			{
				return this.$t(this.field[key], undefined, variables);
			}

			return (this.$te(`${this.I18NPath}.${key}`) || this.$store.getters['i18n/admin/forceOutput']) && force ?
				this.$t(`${this.I18NPath}.${key}`, undefined, variables) :
				'';
		},
		/**
		 * Gets language values for options
		 * @param {*} option
		 * @param {*} path
		 * @param {*} isSharedOption
		 */
		getOptionI18N(option, path, isSharedOption = false)
		{
			// If it's a shared option, we look elsewhere
			if(isSharedOption)
			{
				if(this.$te(`sharedOptions.${path}.${option}`))
				{
					return this.$t(`sharedOptions.${path}.${option}`);
				}
			}

			// otherwise, we return the normal one
			return this.$t(`${this.I18NPath}.options.${option}`);
		},
		submitButtonClicked()
		{
			this.saveField();
		},
		fieldElementClicked()
		{
			const now = Math.floor(new Date().getTime() / 1000);

			if(now - this.lastClickTime <= 0)
			{
				this.toggleFieldEdit();
			}

			this.lastClickTime = now;
		},
		inputKeyPress(e)
		{
			if(this.errorsFound) return;

			this.lastKeyPress = e.key;

			if(this.restrictValueRegex && !this.restrictValueRegex.test(
				String.fromCharCode(e.charCode)
			))
			{
				e.preventDefault();
			}

			// Run override function if form has its own custom validation
			if(typeof this.$keyPressHandlingOverride === 'function')
			{
				this.$keyPressHandlingOverride(e, this.field.type);

				return;
			}

			// Run override function if it's a text input
			if(typeof this.inputKeyPressOverride === 'function')
			{
				this.inputKeyPressOverride(e);

				return;
			}

			// Default behaviour
			if(
				this.lastKeyPress === cFormInputKeyCodes.ENTER ||
				this.lastKeyPress === cFormInputKeyCodes.UNIDENTIFIABLE
			)
			{
				e.preventDefault();
				this.saveField();
			}
		},
		saveField()
		{
			if(this.errorsFound) return;

			const key = this.field.name || this.field.id;

			if(this.field.type === 'number')
			{
				// Number should be numbers, not strings
				this.inputVal = parseInt(this.inputVal, 10);
			}

			this.field.value = this.inputVal;

			if(typeof this.repeatersKey === 'number')
			{
				this.toggleFieldEdit();
				bus.$emit(
					'fieldSave',
					this.formId,
					this.fieldInt,
					this.repeatersKey,
					this.field
				);

				return;
			}

			this.fieldUpdated();

			bus.$emit(
				'submitField',
				this.formId,
				{ [key]: { value: this.field.value } }
			);
		},
		toggleFieldEdit()
		{
			if(!this.editable) return;

			this.field.editing = !this.field.editing;
			this.field.fieldEdit = this.field.editing;
			this.field.focus = this.field.editing;
			// bus.$emit('dirtyField', this.formId, this.field.id); // doesn't look like it's needed

			this.fieldUpdated();
		},
		fieldUpdated(debounce = false)
		{
			// Stops empty fields storing field as ''
			this.field.inputValue = this.field.strictEmpty ? this.inputVal || null : this.inputVal;

			if(debounce)
			{
				bus.$emit('transmittingData', this.formId, this.fieldInt, this.repeatersKey, this.uuid);
				this.debounceEmitChanges(this.field);
			}
			else
			{
				this.emitChanges(this.field);
			}
		},
		emitChanges(field)
		{
			// 2023/10/19
			// Removed as if:
			// - you create an entity
			// - you upload an image
			// - you edit another field
			// , the image will be lost - not sure how it gets saved in the first place.
			// Another fix required so that images can be deleted...
			//
			// /** Explicit check if type is imageUploader.
			// * When value is null and type is imageUploader, value wants to revert back to
			// * original image value, which causes second emit.
			// * Forcing a bypass here allows us to submit a null to the api for images.
			// * Desired outcome is to delete the image
			// * https://gitlab.angdev.com/aluminati/aluminate-vue/-/wikis/FormFields/InlineImageUploader#issue-with-deleting-images
			// */
			// if(field.type !== 'imageUploader')

			bus.$emit('fieldUpdated', this.formId, this.fieldInt, this.repeatersKey, this.uuid, field);
			this.$emit('input', this.field.inputValue);
		},
		checkDesign(qProps)
		{
			// if we don't have any design opts set in the props then use 'outlined' by default else use what we've set in the props
			// check if we've passed through any design props for the options component
			return ['outlined', 'standout', 'borderless', 'filled'].some((prop) => !!qProps[prop]);
		},
		createValidationRules()
		{
			if(!this.field.validation) return {};

			// If somehow we get some props that aren't supported, they break things. This is the list of things we allow
			const acceptedRules = this.$store.getters['structure/fields/getAcceptedValidation'](this.field.type);
			const rules = {};

			Object.keys(this.field.validation).forEach((rule) =>
			{
				if(!acceptedRules.includes(rule)) return;

				if(this.field.validation[rule] !== null)
				{
					rules[rule] = this.field.validation[rule];
				}
				else
				{
					rules[rule] = false;
				}
			});

			return rules;
		},
		getErrorMessages()
		{
			// Vue.set(this, 'errorMessages', this.errors.collect(this.field.name)); // Even though this is the correct way to do it from the documentation this isn't reactive with child components :/ I.e. when you press Save on a form and there are errors, it doesn't show the errors on the field, only at the end of the form

			// console.log('errorMessages', this.errorMessages);

			const errors = [];

			if(!this.errors)
			{
				this.$set(this, 'errorMessages', errors);

				return;
			}

			this.errors.items.forEach((error) =>
			{
				let formId,
					fieldId,
					repeatersKey;

				// In various places, we set this field value to ${formId}|${fieldId}
				// On occasion, error.field can return only a single ID, which is always the fieldId
				// The original assignment (see if statement below) would obviously set this up wrong and cause validation to break
				// Ideally we would find the root cause of the problem, but it's been 3 days and this isn't what I was supposed to be doing
				// If you find the bad assignment (bearing in mind it doesn't always happen), please update it
				//
				// TODO Find the missing ${formId}|${field.id} assignment and add it
				// This may well have something to do with where and when it is set after being requested by getFields() in FormMixin
				// Only the field ID is returned of course, and each field has no concept of it's parent form
				if(error.field.includes('|'))
				{
					[formId, fieldId, repeatersKey] = error.field.split('|');
				}
				else
				{
					fieldId = error.field;
				}

				// See above comments for why we might pass over this check in the event that there is no formId accepted
				if(formId !== this.formId && (!this.field.id || !fieldId))
				{
					return;
				}

				/*
				Check to see if this field is:
				- the same field as this component
				- has the same repeater key
				  - the repeater key of both fields is `undefined` OR are the same (that includes a string of 'undefined' - a form error can send the string back!)
				*/
				if(fieldId === this.field.id && ((typeof repeatersKey === 'undefined' && typeof this.repeatersKey === 'undefined') || String(repeatersKey) === String(this.repeatersKey)))
				{
					errors.push(error.msg);
				}
			});

			this.$set(this, 'errorMessages', errors);
		}
	}
};
