import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { destroy, get, post, put } from '@/utils/api';
import { cAdminPanelTypes } from '@/configs/adminPanelTypes';

const state = {
	pages: null,
	held: null,
	pagePermissions: []
};

const mutations = {
	SET_PAGES(state, { pages })
	{
		state.pages = pages;
	},
	SET_HELD(state, page)
	{
		state.held = page;
	},
	SET_PAGE_PERMISSIONS(state, items)
	{
		state.pagePermissions = items;
	},
	UPDATE_PAGE_PERMISSIONS(state, { pageName, permission, newObject })
	{
		const index = state.pagePermissions.findIndex((item) => item.title === pageName);

		if(index > -1)
		{
			// state.pagePermissions.items[index][permission] = newValue;
			Vue.set(state.pagePermissions, index, newObject);
		}
	}
};

const actions = {
	async search({ dispatch }, { page = 1, limit = 10, filters = {}, sortBy = null, descending = false, abstract = false, moduleId = null })
	{
		filters.moduleId = moduleId;
		const { data } = await get('/structure/page/search', { params: { page, limit, filters, sortBy, descending, abstract } });

		/** @type {import('acb-lib').Items.ItemData} */
		const itemData = data.items.map((item) => item.data);

		dispatch('structure/pages/setPages', itemData, { root: true });

		return data;
	},
	changeOrder({ dispatch, rootGetters }, pages)
	{
		const originalModule = cloneDeep(rootGetters['structure/modules/getActiveModule']);
		const module = cloneDeep(rootGetters['structure/modules/getActiveModule']);

		module.childIds = [];
		pages.forEach((page) => module.childIds.push(page.id));

		dispatch('structure/modules/setModule', module, { root: true });

		return post('structure/page/order', { moduleId: module.id, order: module.childIds })
			.then((res) =>
			{
				if(!res.data)
				{
					dispatch('structure/modules/setModule', originalModule, { root: true });
				}
			});
	},
	openPanel({ dispatch })
	{
		dispatch('admin/panel/new', { type: cAdminPanelTypes.newPage }, { root: true });
	},
	async upsert({ dispatch, rootGetters }, page)
	{
		let module,
			data;

		if(page._moduleId)
		{
			module = rootGetters['structure/modules/getModuleById'](page._moduleId);
			delete page._moduleId;
		}
		else
		{
			module = rootGetters['structure/modules/getActiveModule'] ||
				rootGetters['structure/pages/getModuleParentOfPage'](page.id);
		}

		if(!module)
		{
			console.error('No module!');
		}

		if(page.id)
		{
			({ data } = await post(`structure/page/${page.id}`, { moduleId: module.id, page }));
		}
		else
		{
			({ data } = await put('structure/page', { moduleId: module.id, page }));
		}

		dispatch('app/handleServerResponse', data.changes, { root: true });

		return data;
	},
	async delete({ dispatch }, { pageId, moduleId })
	{
		const { data } = await destroy(`structure/page/${moduleId}/${pageId}`);

		dispatch('structure/admin/handleServerChanges', data, { root: true });

		return data;
	},
	load({ state, commit })
	{
		if(state.pages === null)
		{
			return get('structure/page/adminView').then((res) =>
			{
				commit('SET_PAGES', res.data);
			});
		}

		return Promise.resolve(true);
	},
	async updateProperty({ dispatch }, { pageId, key, value, doCommit = true })
	{
		if(!pageId) throw new Error('You must provide a page ID');

		try
		{
			const { data } = await post(`structure/page/${pageId}/property`, { key, value });

			if(doCommit)
			{
				dispatch('structure/admin/handleServerChanges', data, { root: true });
			}
		}
		catch(e)
		{
			console.warn(e);
		}
	},
	setHeld({ state, commit }, page)
	{
		if(typeof page !== 'undefined')
		{
			commit('SET_HELD', page);
		}
	}
	// async initialisePagePermissions({ commit }, { items })
	// {
	// 	commit('SET_PAGE_PERMISSIONS', items);
	// },
	// async setPagePermissions({ commit }, { items, entityId, doCommit = true })
	// {
	// 	if(entityId)
	// 	{
	// 		const { data } = await post('structure/page/permissions', { items, entityId });

	// 		if(doCommit) commit('SET_PAGE_PERMISSIONS', data.permissions);
	// 	}
	// 	else
	// 	{
	// 		console.warn('Entity id missing, can\'t set page permissions');
	// 	}
	// }
	// async changePagePermission({ commit }, { pageName, permission, newObject, entityId, userListId, doCommit = true })
	// {
	// 	const { data } = await post('structure/page/updatePermissions', { pageName, permission, newObject, entityId, userListId });

	// 	if(doCommit) commit('SET_PAGE_PERMISSIONS', data.permissions);
	// }
};

const getters = {
	getPages: (state) => state.pages,
	getHeld: (state) => state.held,
	getPagePermissions: (state) => state.pagePermissions
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
