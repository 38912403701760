<template>
	<SimpleButton
		flat
		dense
		round
		:ripple="false"
		:aria-label="$t('navigation.userProfile.settings')"
	>
		<Avatar
			:accountId="accountId"
			:editable="false"
			height="38px"
			width="38px"
			round
			shouldShowOnlineIndicator
		/>
	</SimpleButton>
</template>

<script>
	import Avatar from '@/components/profile/Avatar';

	export default {
		components: {
			Avatar
		},
		computed: {
			accountId()
			{
				return this.$store.getters['user/accountId'];
			}
		}
	};
</script>

<style scoped>
	.avatar-shell {
		overflow: hidden;
	}

	.user-profile >>> .avatar {
		border-radius: 1000px;
	}

	.router-link-exact-active >>> .avatar {
		box-shadow: 0 0 0 3px #fff;
	}
</style>
