<template>
	<Modal
		ref="modal"
		size="medium"
		uniformDesignBorderless
	>
		<template #title>
			<I18N
				id="capturedBlocks.modal.title"
				class="bold"
			/>
		</template>
		<template>
			<div class="q-mb-md">
				<I18N id="capturedBlocks.modal.description" />
			</div>

			<q-item
				v-for="(block, index) in prettyCapturedBlocks"
				:key="`${index}-${block.target.id}`"
				class="items-center q-pb-md"
			>
				<q-item-section avatar>
					<q-avatar
						color="light-blue-2"
						size="38px"
						textColor="light-blue-9"
						icon="fal fa-copy"
					/>
				</q-item-section>
				<q-item-section>
					<q-item-label class="item-label">
						{{ getLabel(block.target) }}
					</q-item-label>
					<q-item-label>
						<q-badge
							outline
							color="light-blue-9"
							align="middle"
						>
							<AdminI18N
								:id="`blockDefinitions.${block.target.type}.label`"
								:fallback="block.target.type"
							/>
						</q-badge>
						<!-- <I18N id="capturedBlocks.modal.page">
							<template #page>
								{{ getLabel(block.sourcePage) }}
							</template>
						</I18N> -->
					</q-item-label>
					<q-item-label v-if="block.target.children && block.target.children.length">
						<span class="text-subtitle2 text-grey-8">
							<I18N id="capturedBlocks.modal.hasChildren" />
						</span>
					</q-item-label>
				</q-item-section>
				<q-item-section class="q-ma-none">
					<q-icon
						name="far fa-long-arrow-right"
						color="grey-8"
					/>
				</q-item-section>
				<q-item-section avatar class="q-pr-md q-pl-none items-start">
					<q-avatar
						color="green-2"
						size="38px"
						textColor="green-9"
						icon="fal fa-copy"
						class="q-pr-md q-pl-none"
					/>
				</q-item-section>
				<q-item-section>
					<q-item-label class="item-label">
						{{ getLabel(sendToBlock.parent) }}
					</q-item-label>
					<q-item-label v-if="sendToBlock.type === 'blocks'">
						<q-badge
							outline
							color="green-9"
							align="middle"
						>
							<AdminI18N
								:id="`blockDefinitions.${sendToBlock.parent.type}.label`"
								:fallback="sendToBlock.parent.type"
							/>
						</q-badge>
					</q-item-label>
					<q-item-label v-else>
						<q-badge
							outline
							color="green-9"
							align="middle"
						>
							<AdminI18N id="capturedBlocks.modal.page" />
						</q-badge>
					</q-item-label>
					<!-- <q-item-label
						v-if="sendTo && currentPage.id !== sendToBlock.id"
					>
						<I18N id="capturedBlocks.modal.parent">
							<template #parent>
								{{ getLabel(sendToBlock) }}
							</template>
						</I18N>
					</q-item-label> -->
				</q-item-section>
			</q-item>
		</template>
		<template #controls>
			<div class="q-pb-md">
				<SimpleButton
					color="negative"
					class="q-mr-sm"
					:disabled="$store.getters['structure/blocks/admin/isPasting']"
					@click="close"
				>
					<I18N id="capturedBlocks.modal.cancel.title" />
					<q-tooltip>
						<I18N id="capturedBlocks.modal.cancel.tooltip" class="readable-q-tooltip" />
					</q-tooltip>
				</SimpleButton>
				<SimpleButton
					class="q-mr-sm"
					color="secondary"
					:loading="actionTaken === 'move' && $store.getters['structure/blocks/admin/isPasting']"
					@click="move"
				>
					<q-icon
						size="16px"
						name="fal fa-cut"
						class="q-mr-sm"
					/>
					<I18N id="capturedBlocks.modal.move.title" />
					<q-tooltip>
						<I18N id="capturedBlocks.modal.move.tooltip" class="readable-q-tooltip" />
					</q-tooltip>
				</SimpleButton>
				<SimpleButton
					class="q-mr-sm"
					:loading="actionTaken === 'clone' && $store.getters['structure/blocks/admin/isPasting']"
					@click="clone"
				>
					<q-icon
						size="16px"
						name="fal fa-copy"
						class="q-mr-sm"
					/>

					<I18N id="capturedBlocks.modal.clone.title" />
					<q-tooltip>
						<I18N id="capturedBlocks.modal.clone.tooltip" class="readable-q-tooltip" />
					</q-tooltip>
				</SimpleButton>
				<SimpleButton
					class="q-mr-sm"
					color="deep-purple"
					:loading="actionTaken === 'copy' && $store.getters['structure/blocks/admin/isPasting']"
					@click="copy"
				>
					<q-icon
						size="16px"
						name="fal fa-copy"
						class="q-mr-sm"
					/>

					<I18N id="capturedBlocks.modal.copy.title" />
					<q-tooltip>
						<I18N id="capturedBlocks.modal.copy.tooltip" class="readable-q-tooltip" />
					</q-tooltip>
				</SimpleButton>
			</div>
		</template>
	</Modal>
</template>

<script>
	import Modal from '@/components/Modal';
	import BlockMoveMixin from '@/components/mixins/BlockMoveMixin';
	import { addError } from '@/utils/notifications';

	// TODO Move BlockMoveModal out from ElementControls and AddBlockButton - it's a component that should only be calculated once, not per block

	export default {
		components: { Modal },
		mixins: [BlockMoveMixin],
		data()
		{
			return {
				sendTo: null,
				colIndex: undefined,
				index: undefined,
				type: 'block',
				actionTaken: null
			};
		},
		computed: {
			sendToBlock()
			{
				if(this.sendTo)
				{
					return this.getParent(this.sendTo);
				}

				return {};
			}
		},
		methods: {
			/**
			 * @param action 'move' | 'clone' | 'copy'
			 */
			async pasteSelected(action)
			{
				this.actionTaken = action;

				const to = {
					parentMeta: {
						id: this.sendTo
					},
					colIndex: this.colIndex,
					index: this.index
				};
				const { parent, type } = this.getParent(this.sendTo);

				if(parent)
				{
					try
					{
						to.parentMeta.type = type;
						await this.$store.dispatch('structure/blocks/admin/pasteCaptured', { to, action });
					}
					catch(e)
					{
						addError(
							this.$t('capturedBlocks.modal.blockCloneError')
						);
					}
				}
				else
				{
					addError(
						this.$t('capturedBlocks.modal.noParentFound')
					);
				}

				this.actionTaken = null;

				this.close();
			},
			open(payload)
			{
				this.sendTo = payload.id;
				this.colIndex = payload.colIndex;
				this.index = payload.index;
				this.$refs.modal.open();
			},
			close()
			{
				this.$refs.modal.close();
			},
			move()
			{
				this.pasteSelected('move');
			},
			clone()
			{
				this.pasteSelected('clone');
			},
			copy()
			{
				this.pasteSelected('copy');
			},
			getParent(id)
			{
				let parent = this.$store.getters['structure/blocks/getBlock'](id),
					type = 'blocks';

				/**
				 * Fail-safe for a strange bug where the page is ending up in the list of blocks in the store.
				 * If we send the id of a page saying it's a block to the API, the API won't like it.
				 */
				const parentIsActuallyAPage = this.$store.getters['structure/pages/getPageById'](id);

				if(!parent || parentIsActuallyAPage)
				{
					parent = parentIsActuallyAPage;

					if(parent)
					{
						type = 'pages';
					}
				}

				return {
					parent,
					type
				};
			}
		}
	};
</script>
<style lang="postcss" scoped>
	.item-label {
		font-weight: 600;
	}

	.q-item {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}

	>>> .q-item__section--main ~ .q-item__section--side {
		align-items: self-start;
		padding-right: 0;
		padding-left: 0;
	}
</style>
