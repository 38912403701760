import Vue from 'vue';
import { get, post } from '@/utils/api';

const state = {
	templates: {},
	itemDetails: [],
	latestRevertToken: null,
	permissions: {}
};

const mutations = {
	SET_PERMISSIONS(state, permissions)
	{
		state.permissions = permissions;
	},
	SET_PERMISSION(state, { itemId, permissions })
	{
		Vue.set(state.permissions, itemId, permissions);
	},
	SET_TEMPLATES(state, templates)
	{
		state.templates = templates;
	},
	SET_ITEM_DETAILS(state, details = [])
	{
		details.forEach((detail) =>
		{
			const currentIndex = state.itemDetails.findIndex((d) => d.id === detail.id);

			if(currentIndex > -1)
			{
				Vue.set(state.itemDetails, currentIndex, detail);
			}
			else
			{
				state.itemDetails.push(detail);
			}
		});
	},
	SET_REVERT_TOKEN(state, token)
	{
		const data = window.atob(token);

		Vue.set(state, 'latestRevertToken', { expires: data.expires, token });
	},
	CLEAR_REVERT_TOKEN(state)
	{
		Vue.set(state, 'latestRevertToken', null);
	}
};

const actions = {
	setRevertToken({ commit }, token)
	{
		commit('SET_REVERT_TOKEN', token);
	},
	clearRevertToken({ commit })
	{
		commit('CLEAR_REVERT_TOKEN');
	},
	loadTemplates({ state, commit })
	{
		if(Object.keys(state.templates).length === 0)
		{
			return get('structure/templates/modules').then((res) =>
			{
				commit('SET_TEMPLATES', res.data);
			});
		}

		return Promise.resolve(true);
	},
	nuke({ dispatch })
	{
		dispatch('structure/blocks/reset', undefined, { root: true });
		dispatch('structure/fields/reset', undefined, { root: true });
	},
	async handleServerChanges({ dispatch, commit }, { success, changes, admin })
	{
		if(success)
		{
			await dispatch('app/handleServerResponse', changes, { root: true });

			if(admin)
			{
				Object.values(admin).forEach((type) =>
				{
					commit('SET_ITEM_DETAILS', type);
				});
			}
		}
	},
	async executeRevert({ dispatch, getters })
	{
		const { token } = getters.revertToken;
		const { data } = await post('/structure/revert', { token });

		data.forEach((items) =>
		{
			dispatch('handleServerChanges', items);
		});

		dispatch('clearRevertToken');
	},
	async loadAllPermissions({ commit, rootGetters })
	{
		const isAdmin = rootGetters['user/isElevated'];

		if(!isAdmin)
		{
			return false;
		}

		const { data } = await get('structure/permissions');

		commit('SET_PERMISSIONS', data);

		return data;
	},
	async loadItemPermissions({ rootGetters, getters, commit }, {
		itemId,
		force = false
	})
	{
		// leaving isAdmin here as this is for the old admin visibility permissions
		const isAdmin = rootGetters['user/isElevated'];

		if(!isAdmin)
		{
			return false;
		}

		if(!force && getters.getItemPermission(itemId))
		{
			return getters.getItemPermission(itemId);
		}

		const result = await get(`structure/permissions/${itemId}`);

		commit('SET_PERMISSION', { itemId, permissions: result?.data });

		return result?.data;
	},
	async loadListPermissions({ rootGetters, getters }, {
		userListId,
		force = false
	})
	{
		if(!rootGetters['user/isElevated'])
		{
			return false;
		}

		// Understand this is somewhat mixed messaging;
		// needs to be cleared up
		if(!force && getters.getItemPermission(userListId))
		{
			return getters.getItemPermission(userListId);
		}

		// Not currently cached; this will come later.
		const result = await get(`userLists/permissions/byList/${userListId}`);

		return result?.data;
	},
	async loadPermissions({ commit, rootGetters, dispatch }, {
		id,
		permissionType = 'item',
		force = false
	})
	{
		// leaving isAdmin here as this is for the old admin visibility permissions
		const isAdmin = rootGetters['user/isElevated'];

		if(!isAdmin)
		{
			return false;
		}

		let permissions = false;

		switch(permissionType)
		{
			case 'item':
			{
				try
				{
					permissions = await dispatch('loadItemPermissions', {
						itemId: id,
						force
					});
				}
				catch(e)
				{
					console.error(e);
				}

				break;
			}
			case 'userList':
			{
				try
				{
					permissions = await dispatch('loadListPermissions', {
						userListId: id,
						force
					});
				}
				catch(e)
				{
					console.error(e);
				}

				break;
			}
			default:
				return false;
		}

		if(permissions)
		{
			commit('SET_PERMISSION', { itemId: id, permissions });
		}

		return permissions;
	},
	async editItemPermissions({ commit }, { itemId, permissions })
	{
		const { data } = await post(`structure/permissions/${itemId}`, { permissions });

		commit('SET_PERMISSION', { itemId, permissions: data });

		return data;
	}
};

const getters = {
	getTemplates: (state) => state.templates,
	detail: (state) => (itemId) => state.itemDetails.find((item) => item.id === itemId),
	revertToken: (state) => state.latestRevertToken,
	getItemPermissions: (state) => (id) => state?.permissions?.[id]
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
