import Vue from 'vue';
import { get, put, post, destroy } from '@/utils/api';
import { addError, addWarn } from '@/utils/notifications';

const state = {
	defaults: {},
	custom: [],
	broadcastCount: 0,
	loading: false
};

const mutations = {
	SET_CUSTOM(state, custom)
	{
		state.custom = custom;
	},
	SET_DEFAULTS(state, defaults)
	{
		state.defaults = defaults;
	},
	ADD_USER_LIST(state, userList)
	{
		state.custom.push(userList);
	},
	REMOVE_USER_LIST(state, id)
	{
		const index = state.custom.findIndex((list) => list.id === id);

		Vue.delete(state.custom, index);
	},
	UPDATE_USER_GROUP(state, userList)
	{
		const currentIndex = state.custom.findIndex((g) => g.id === userList.id);

		if(currentIndex > -1)
		{
			state.custom.splice(currentIndex, 1, userList);
		}
	},
	ADD_USERS_TO_USER_LIST(state, { listId, users })
	{
		const listIndex = state.custom.findIndex((l) => l.id === listId);

		// state.custom[listIndex] = { ...state.custom[listIndex], users: [...users] };
		const currentUsers = state.custom[listIndex]?.users || [];
		const allUsers = new Set(currentUsers.concat(users));

		Vue.set(state.custom, listIndex, { ...state.custom[listIndex], users: [...allUsers] });// concatenate old and additional arrays and then make the resulting array unique through Set
	},
	REMOVE_USER_FROM_LIST(state, { listId, accountId })
	{
		const list = state.custom.find((l) => l.id === listId);

		if(list)
		{
			list.users = list.users.filter((id) => parseInt(id, 10) !== parseInt(accountId, 10));
		}
	},
	UPDATE_CUSTOM_LISTS(state, lists)
	{
		lists.forEach((list) =>
		{
			const index = state.custom.findIndex((l) => l.id === list.id);

			if(index > -1)
			{
				Vue.set(state.custom, index, list);
			}
			else
			{
				state.custom.push(list);
			}
		});
	},
	UPDATE_BROADCAST_LIST_COUNT(state, count)
	{
		state.broadcastCount = count;
	},
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	}
};

const actions = {
	async load({ commit, state, getters, rootGetters, dispatch }, { force = false } = {})
	{
		if(!getters.loading)
		{
			if((!force && state.custom && state.custom.length) || !rootGetters['user/isElevated']) return;

			dispatch('setLoading', true);
			const { data } = await get('userLists');

			commit('SET_CUSTOM', data.custom);
			commit('SET_DEFAULTS', data.defaults);
			dispatch('setLoading', false);
		}
	},

	// async removeItemPermission({ commit }, { itemId, userListId })
	// {
	// 	const { data } = await destroy(`userLists/${userListId}/permissions/${itemId}`);

	// 	commit('SET_PERMISSION', { itemId, permissions: data });
	// },
	async save({ commit }, userList)
	{
		try
		{
			const { data } = await put('userLists', { userList });

			commit('ADD_USER_LIST', data);

			return data;
		}
		catch(e)
		{
			addError('Failed to save userList: Something went wrong, please try again');
			throw e;
		}
	},
	async createNew({ commit, dispatch }, { label, type })
	{
		try
		{
			const payload = { _i18n: { label }, type };
			const { data } = await put('userLists', payload);

			const { list, i18n } = data;

			commit('ADD_USER_LIST', list);

			dispatch('i18n/updateKeys', i18n, { root: true });

			return data;
		}
		catch(e)
		{
			addError('Failed to save userList: Something went wrong, please try again');
			throw e;
		}
	},
	async update({ commit }, { userListId, changes })
	{
		// NOTE: the changes must be a changed result of the user-list's `data` field, or you will be removing all other values except your `changes` from it!
		try
		{
			const { data } = await post(`/userLists/${userListId}`, { changes });

			if(data.success)
			{
				commit('UPDATE_USER_GROUP', data.changes);

				return data.changes;
			}

			return false;
		}
		catch(e)
		{
			addError('Failed to save userList: Something went wrong, please try again');
			throw e;
		}
	},
	async delete({ commit, rootGetters }, id)
	{
		try
		{
			await destroy(`userlists/${id}`);

			commit('REMOVE_USER_LIST', id);
		}
		catch(e)
		{
			if(e?.response?.status === 400)
			{
				addError(e.response.data.message);
			}
			else
			{
				const errorTitle = rootGetters['i18n/get']('admin.dash.userLists.deleteFailed.title');
				const errorContent = rootGetters['i18n/get']('admin.dash.userLists.deleteFailed.content');

				addError(`${errorTitle}: ${errorContent}`);
			}
		}
	},
	async doLoopCheck({ commit }, { userListId, changes })
	{
		try
		{
			await post(`/userLists/doLoopCheck/${userListId}`, { changes });
		}
		catch(e)
		{
			addError('Failed to save userList: Something went wrong, please try again');
			throw e;
		}
	},
	async loadMembers({ commit, dispatch }, listId)
	{
		try
		{
			const { data: accountIds } = await get(`userLists/${listId}/users`);

			await dispatch('profiles/loadProfiles', accountIds, { root: true });
			commit('ADD_USERS_TO_USER_LIST', { users: accountIds, listId });

			return accountIds;
		}
		catch(e)
		{
			console.warn(e);
			addError('Could not load members: We could not load members for this list. Please try again');

			return [];
		}
	},
	async addUsers(ctx, { listId, accountIds })
	{
		const list = ctx.rootGetters['userLists/admin/getUserList'](listId);

		if(!list)
		{
			return;
		}

		if(list.dynamic || list.system)
		{
			addWarn(ctx.rootGetters['i18n/get']('errors.userLists.cannotAddToProtectedLists'));

			return;
		}

		try
		{
			await post(`userLists/${listId}/users`, {
				accounts: accountIds
			});

			ctx.commit('ADD_USERS_TO_USER_LIST', { users: accountIds, listId });
		}
		catch(e)
		{
			console.warn('something went wrong', e);
		}
	},
	async removeUser(ctx, { listId, accountId })
	{
		try
		{
			await destroy(`userLists/${listId}/${accountId}`);
			ctx.commit('REMOVE_USER_FROM_LIST', { listId, accountId });
		}
		catch(e)
		{
			console.warn('something went wrong', e);
		}
	},
	updateCustomLists({ commit }, lists)
	{
		commit('UPDATE_CUSTOM_LISTS', lists);
	},
	async search({ dispatch }, payload)
	{
		const { data } = await get('/userLists/search', { params: payload });

		dispatch('updateCustomLists', data.items);

		return data;
	},
	async countUsersInLists({ commit }, { listIds, states })
	{
		const { data } = await post('/userLists/admin/countUsers', { listIds, states });

		commit('UPDATE_BROADCAST_LIST_COUNT', { count: data });
	},
	async setAdminMode({ dispatch }, { userListId, mode })
	{
		const { data } = await post(`/userLists/admin/${userListId}/isAdmin/${mode}`);

		dispatch('updateCustomLists', [data.changes]);

		return data;
	},
	async setAllowPermissions({ dispatch }, { userListId, mode })
	{
		const { data } = await post(`/userLists/admin/${userListId}/allowPermissions/${mode}`);

		dispatch('updateCustomLists', [data.changes]);

		return data;
	},
	async changeAdminPermission({ dispatch }, { userListId, permission, mode })
	{
		const { data } = await post(`/userLists/admin/${userListId}/${permission}/${mode}`);

		dispatch('updateCustomLists', data.changes);
	},
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	}
};

const getters = {
	loading: (state) => state.loading,
	custom: (state) => state.custom.map((userList) => ({ ...userList })),
	getUserList: (state, getters) => (userListId) => ({ ...getters.custom.find((userList) => userList.id === userListId) }),
	defaults: (state) => ({ ...state.defaults }),
	isUserInList: (state, getters) => (userListId, accountId) =>
	{
		const userList = getters.getUserList(userListId);

		return userList.users && userList.users.includes(accountId);
	},
	getBroadcastCount: (state) => (state.broadcastCount)
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
