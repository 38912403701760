import Vue from 'vue';
import 'quasar/dist/quasar.ie.polyfills';
import iconSet from 'quasar/icon-set/fontawesome-v5-pro.js';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@/styles/quasar.styl';
import {
	Quasar,
	QLayout,
	QHeader,
	QDrawer,
	QPageContainer,
	QPage,
	QToolbar,
	QToolbarTitle,
	QBreadcrumbs,
	QBreadcrumbsEl,
	QBtn,
	QIcon,
	QList,
	QItem,
	QItemSection,
	QItemLabel,
	QExpansionItem,
	QPopupProxy,
	ClosePopup,
	QCard,
	QCardSection,
	QColor,
	// QCardActions,
	QInput,
	QSlider,
	QKnob,
	QRadio,
	QOptionGroup,
	Ripple,
	QCardActions,
	Notify,
	QDialog,
	QBadge,
	QSpinner,
	LoadingBar,
	QResizeObserver,
	QCheckbox,
	QToggle,
	QChatMessage,
	QPageSticky,
	QBtnGroup,
	QSelect,
	QTabs,
	QTab,
	QTabPanel,
	QTabPanels,
	QSpace,
	QField,
	QFile,
	QUploader,
	QTooltip,
	QDate,
	QRange,
	QTime,
	QSeparator,
	QAvatar,
	QBanner,
	QInfiniteScroll,
	QScrollArea,
	QRouteTab,
	QFooter,
	QBtnToggle,
	QTree,
	QTable,
	QTr,
	QTd,
	QPopupEdit,
	Meta,
	QCarousel,
	QCarouselControl,
	QCarouselSlide,
	QChip,
	QBar,
	QCircularProgress,
	QPagination,
	QVirtualScroll,
	QSplitter,
	QInnerLoading,
	QForm,
	QTh,
	Intersection,
	QSpinnerDots,
	QSpinnerBars,
	QMarkupTable,
	QStepper,
	QStep,
	QStepperNavigation,
	QImg,
	QScrollObserver,
	Dialog,
	QBtnDropdown,
	QLinearProgress,
	QMenu,
	QEditor,
	QFab,
	QFabAction,
	QTimeline,
	QTimelineEntry,
	QSkeleton
} from 'quasar';

Vue.use(Quasar, {
	config: {},
	components: {
		QLayout,
		QHeader,
		QDrawer,
		QPageContainer,
		QPage,
		QToolbar,
		QToolbarTitle,
		QBreadcrumbs,
		QBreadcrumbsEl,
		QBtn,
		QIcon,
		QList,
		QItem,
		QItemSection,
		QItemLabel,
		QExpansionItem,
		QPopupProxy,
		QCard,
		QCardSection,
		QColor,
		QInput,
		QSlider,
		QKnob,
		QRadio,
		QOptionGroup,
		QCardActions,
		QDialog,
		QBadge,
		QSpinner,
		QResizeObserver,
		QCheckbox,
		QToggle,
		QChatMessage,
		QPageSticky,
		QBtnGroup,
		QSelect,
		QTabs,
		QTab,
		QTabPanel,
		QTabPanels,
		QSpace,
		QField,
		QFile,
		QUploader,
		QTooltip,
		QDate,
		QRange,
		QTime,
		QSeparator,
		QAvatar,
		QBanner,
		QInfiniteScroll,
		QScrollArea,
		QRouteTab,
		QFooter,
		QBtnToggle,
		QTree,
		QTable,
		QTr,
		QTd,
		QPopupEdit,
		QChip,
		QCircularProgress,
		QBar,
		QCarousel,
		QCarouselControl,
		QCarouselSlide,
		QPagination,
		QVirtualScroll,
		QSplitter,
		QInnerLoading,
		QForm,
		QTh,
		QSpinnerDots,
		QSpinnerBars,
		QMarkupTable,
		QStepper,
		QStep,
		QStepperNavigation,
		QScrollObserver,
		QImg,
		QBtnDropdown,
		QLinearProgress,
		QMenu,
		QEditor,
		QFab,
		QFabAction,
		QTimeline,
		QTimelineEntry,
		QSkeleton
	},
	directives: {
		ClosePopup,
		Ripple,
		Intersection
	},
	plugins: {
		Notify,
		LoadingBar,
		Meta,
		Dialog
	},
	iconSet
});
