import Vue from 'vue';
import { post, get } from '@/utils/api';
import { addSuccess, addError } from '@/utils/notifications';

const state = {
	templates: [],
	masterTemplates: undefined,
	emailLegalFooters: undefined,
	areMasterTemplatesLoaded: false,
	currentViewingTemplate: null,
	currentViewingSubject: null,
	currentViewingParameterList: null
};

const mutations = {
	ADD_TEMPLATE(state, templateDetails)
	{
		const indexOfEle = state.templates.findIndex((template) => (
			template.templateId === templateDetails.templateId
		));

		if(indexOfEle > -1)
		{
			Vue.set(state.templates, indexOfEle, templateDetails);
		}
		else
		{
			Vue.set(state.templates, state.templates.length, templateDetails);
		}
	},
	SET_TEMPLATES(state, templates)
	{
		state.templates = templates;
	},
	SET_MASTER_TEMPLATES(state, masterTemplates)
	{
		state.masterTemplates = masterTemplates;
	},
	SET_MASTER_TEMPLATES_LOADED(state)
	{
		state.areMasterTemplatesLoaded = true;
	},
	SET_LEGAL_FOOTERS(state, legalFooters)
	{
		state.emailLegalFooters = legalFooters;
	},
	DISPLAY_TEMPLATE(state, { templateHtml, templateSubject, templateParameterList })
	{
		state.currentViewingTemplate = templateHtml;
		state.currentViewingSubject = templateSubject;
		state.currentViewingParameterList = templateParameterList;
	},
	DELETE_TEMPLATE(state, templateDetails)
	{
		const indexOfEle = state.templates.findIndex((template) => (
			template.templateId === templateDetails.templateId
		));

		if(indexOfEle > -1)
		{
			state.templates.splice(indexOfEle, 1);
		}
	}
};

const actions = {
	async loadTemplates({ commit, state }, force = false)
	{
		if(state.templates.length && !force)
		{
			return;
		}

		const { data } = await get('service/coreAPI/getServiceEmailTemplates');
		const allServiceTemplates = data.response?.responseData || [];
		const templates = allServiceTemplates.map((template) =>
		{
			template.templateId = template.templateId.substring(template.templateId.indexOf('_') + 1);
			if(typeof template.parameterList === 'string')
			{
				template.parameterList = template.parameterList.split(',');
			}

			return template;
		});

		commit('SET_TEMPLATES', templates);

		commit('DISPLAY_TEMPLATE', { templateHtml: state.templates[0]?.html || '', templateSubject: state.templates[0]?.subject || '', templateParameterList: state.templates[0]?.parameterList || [] });
	},
	async loadMasterTemplates({ commit }, force = false)
	{
		if(!force && state.areMasterTemplatesLoaded)
		{
			return;
		}

		try
		{
			const { data } = await get('emailTemplates/getEmailMasterTemplates');

			commit('SET_MASTER_TEMPLATES', data || undefined);
		}
		catch(e)
		{
			console.error(e);
		}
		finally
		{
			commit('SET_MASTER_TEMPLATES_LOADED');
		}
	},
	async loadEmailLegalFooters({ commit, rootGetters }, force = false)
	{
		if(!force && state.emailLegalFooters)
		{
			return;
		}

		try
		{
			// const language = rootGetters['i18n/localeActive'];
			const language = null;

			const { data } = await get(
				'emailTemplates/getEmailLegalFooters',
				{ params: { language } }
			);

			commit('SET_LEGAL_FOOTERS', data || undefined);
		}
		catch(e)
		{
			console.error(e);
		}
	},
	changeTemplateViewing({ commit }, templateHtml)
	{
		commit('DISPLAY_TEMPLATE', { templateHtml: templateHtml.html, templateSubject: templateHtml.subject, templateParameterList: templateHtml.parameterList });
	},
	async addTemplate({ commit, rootGetters }, templateDetails)
	{
		commit('ADD_TEMPLATE', templateDetails);

		try
		{
			const { data } = await post(
				'service/coreAPI/addEmailTemplate',
				{ templateDetails }
			);

			if(data.response.responseCode !== 100)
			{
				throw new Error(`Error: "${data.response.responseDescription}"`);
			}

			addSuccess(rootGetters['i18n/get'](
				'admin.dash.emailTemplateBuilder.successAdding'
			));
		}
		catch(e)
		{
			addError(e.message);
		}
	},
	async deleteTemplate({ commit, rootGetters }, templateDetails)
	{
		let response;

		await post('service/coreAPI/deleteEmailTemplate', { templateDetails })
			.then((res) =>
			{
				commit('DELETE_TEMPLATE', templateDetails);
				addSuccess(rootGetters['i18n/get']('admin.dash.emailTemplateBuilder.successDeleting'));

				response = res.data.response;
			})
			.catch((e) =>
			{
				addError(e);

				response = { responseDescription: e?.message };
			});

		return response;
	}
};

const getters = {
	currentTemplateHtml: (state) =>
	{
		return { subject: state.currentViewingSubject, html: state.currentViewingTemplate, parameterList: state.currentViewingParameterList };
	},
	templates: (state) => state.templates,
	masterTemplates: (state) => state.masterTemplates,
	emailLegalFooters: (state) => state.emailLegalFooters,
	byId: (state, getters) => (id) => getters.templates.find((template) => template.templateId === id)
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

