import Vue from 'vue';
import dot from 'dot-object';
import { uniq } from 'lodash';
import { get, setDirtySWR } from '@/utils/api';
import admin from '@/store/i18n/admin';
import i18n from '@/plugins/vue-i18n';
import { setItems } from '@/utils/store';

const modules = {
	admin
};

const state = {
	localeDefault: 'en', // Default language (we could make this dynamic by storing a default service language)
	localeActive: null, // The active language - This is replaced with the user's language if they have one, or it will use `state.localeDefault`
	languages: {}, // This stores each language that is requested, so we only request each language once from the server
	updating: true, // Are we in the middle of updating languages -> Default to yes we're updating as we don't have a language in memory to begin with,
	updatedCounter: 0 // Number of times updated -> Used for App watcher because the `state.updating` can do it too quickly for it to know it's changed
};

const mutations = {
	SAVE_LANGUAGE(state, languageData) // Save the language from the server in memory
	{
		Vue.set(state.languages, state.localeActive, languageData);
	},
	SET_LANGUAGE(state) // Sets the language to be updated using the App.watcher
	{
		state.updating = false; // We finished handling getting the new language, we update this to finished so the store.watcher can update the App frontend language
		state.updatedCounter += 1; // Count how many times we've updated to the App.watcher can use this
	},
	CHANGE_LOCALE(state, locale) // Store which language is active
	{
		state.updating = true; // We're in the middle of updating the language
		state.localeActive = locale;
	},
	UPDATE_KEY(state, { iso, key, value })
	{
		if(!state.languages[iso] || iso !== state.localeActive)
		{
			return;
		}

		const tmpState = state.languages[iso];

		dot.del(key, tmpState);
		dot.str(key, value, tmpState);
		state.languages[iso] = { ...tmpState };
	},
	UPDATE_KEYS(state, { iso, changes })
	{
		setItems(state.languages, iso, state.languages[iso], dot.object(changes), 'id', { freeze: true });
	}
};

const actions = {
	async load({ dispatch, state }, force) // Load a language
	{
		await dispatch('i18n/initialiseLocale', null, { root: true });

		if(!state.languages[state.localeActive] || force) // If the language does not exist in memory (ie we've never got it before)
		{
			return get(`i18n/${state.localeActive}`) // Make a request to the server to get the language
				.then((res) =>
				{
					return res.data;
				})
				.then((language) =>
				{
					dispatch('saveUserLanguage', language);
					dispatch('setAppLanguage');
					dispatch('app/languageLoaded', {}, { root: true }); // This is used for when the user first loads Aluminate, it tells the App we've loaded a language
				});
		}

		// Else
		dispatch('setAppLanguage');

		return Promise.resolve(true);
	},
	initialiseLocale({ commit, state, rootGetters })
	{
		if(state.localeActive === null)
		{
			commit('CHANGE_LOCALE', rootGetters['user/metaValue']('language') || rootGetters['app/settings/get']('languages.default'));
		}
	},
	saveUserLanguage({ commit }, languageData) // Save the language to memory
	{
		commit('SAVE_LANGUAGE', languageData);
	},
	setAppLanguage({ commit }) // Set the language to be active on the frontend
	{
		commit('SET_LANGUAGE');

		i18n.setLocaleMessage(state.localeActive, state.languages[state.localeActive]);
		i18n.locale = state.localeActive;
	},
	/**
	 * Change the user's locale.
	 */
	async changeLocale({ state, commit, dispatch, rootState, rootGetters }, locale)
	{
		commit('CHANGE_LOCALE', locale);

		await dispatch('profiles/saveProfileMetaData', {
			path: 'language',
			data: state.localeActive
		}, { root: true });

		await dispatch('load', true);

		// Not the best way to deal with SWR, but it works.
		// This will force `/init` to be called instead of relying on the cache.
		// This ensures that once a locale has been set, the right one is loaded
		// the next time the page is loaded, and prevents gettings all kinds of
		// weird bugs.
		// The correct way would be to update the cache when updating any value
		// in which it's stored, but this is outside the scope of this ticket
		// (PT_184279365).
		await setDirtySWR(true);

		return true;
	},
	updateKey({ commit, rootGetters }, payload)
	{
		if(!payload.iso)
		{
			payload.iso = rootGetters['app/settings/get']('languages.default');
		}

		commit('UPDATE_KEY', payload);
		commit('SET_LANGUAGE');
	},
	updateKeys({ commit, dispatch }, data)
	{
		if(!data || !data.iso || !data.changes || (typeof data.changes === 'object' && !Object.keys(data.changes).length)) return;

		commit('UPDATE_KEYS', { iso: data.iso, changes: data.changes });
		dispatch('i18n/admin/updateKeys', data, { root: true });
	}
};

const getters = {
	updatedCounter: (state) => // Returns if the language is ready for use
	{
		return state.updatedCounter;
	},
	/**
	 * For now, the only way to set available languages is
	 * to manually edit `settingsPublished` - we're not
	 * allowing admins to make the change YET.
	 */
	enabledLanguages: (state, getters, rootState, rootGetters) =>
	{
		return rootGetters['app/settings/get']('languages')?.enabled ?
			uniq(rootGetters['app/settings/get']('languages').enabled) :
			[state.localeDefault];
	},
	/**
	 * Available languages need to be manually published
	 * by admins to be visible to users.
	 */
	publishedLanguages: (state, getters, rootState, rootGetters) =>
	{
		const publishedLanguages = [...rootGetters['app/settings/get']('languages')?.published ?? []];

		if(!publishedLanguages.includes(state.localeDefault))
		{
			publishedLanguages.push(state.localeDefault);
		}

		/** If a language was published but then disabled, it should not be visible to users. */
		return publishedLanguages.filter((language) => getters.enabledLanguages.includes(language));
	},
	get: (state) => (key) =>
	{
		if(!key) return null;

		return dot.pick(key, state.languages[state.localeActive]);
	},
	exists: (state, getters) => (key) => typeof getters.get(key) !== 'undefined',
	// ↓ this is probably not needed - use `(import i18n from '@/plugins/vue-i18n').t` instead
	dynamicValue: (state, getters) => (key, values, count = undefined) =>
	{
		const i18nString = count ?
			getters.countValue(key, count) :
			getters.get(key) || '';

		// {(.+)} use this regex to replace the dynamic variables
		const regex = /\{([a-zA-Z]+)\}/g;

		return i18nString.replace(regex, (match, id) => values[id]);
	},
	/**
	 * Locale set by the user. If the locale doesn't exist
	 * in the list of published languages, use default instead.
	 */
	localeActive: (state, getters) => (
		getters.publishedLanguages.includes(state.localeActive) ?
			state.localeActive : state.localeDefault
	),
	localeDefault: (state) => state.localeDefault,
	language: (state) => (iso) => state.languages[iso]
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
