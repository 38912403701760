export default {
	computed: {
		capturedBlocks: {
			get()
			{
				return this.$store.getters['structure/blocks/admin/capturedBlocks'];
			}
		},
		numberOfCapturedBlocks: {
			get()
			{
				return this.capturedBlocks.length < 100 ? this.capturedBlocks.length : '99+';
			}
		},
		numberOfCapturedBlocksLong: {
			get()
			{
				return this.$t('capturedBlocks.list.numberSelected', { number: this.numberOfCapturedBlocks });
			}
		},
		prettyCapturedBlocks: {
			get()
			{
				return this.capturedBlocks?.map((captured) =>
				{
					let from;

					switch(captured.from.parentMeta.type)
					{
						case 'blocks':
							from = this.$store.getters['structure/blocks/getBlock'](captured.from.parentMeta.id);

							break;
						case 'pages':
							from = this.$store.getters['structure/pages/getPageById'](captured.from.parentMeta.id);

							break;
						default:
							break;
					}

					return {
						target: this.$store.getters['structure/blocks/getBlock'](captured.target.blockId),
						from,
						sourcePage: this.$store.getters['structure/pages/getPageById'](captured.meta.sourcePageId)
					};
				});
			}
		},
		currentPage: {
			get()
			{
				return (this.$store.getters['app/isAdminDashboard'] && this.$route.params.pageId && this.$store.getters['structure/pages/getPageById'](this.$route.params.pageId)) || this.$store.getters['structure/pages/getActivePage'] || {};
			}
		}
	},
	methods: {
		getLabel(item)
		{
			if(item)
			{
				if(item?.name)
				{
					return item.name;
				}
				else if(this.$te(`custom.blocks.${item?.id}.title`))
				{
					return this.$t(`custom.blocks.${item.id}.title`);
				}
				else if(item?.slug && this.$t(`custom.pages.${item.id}`))
				{
					// probably a page
					return this.$t(`custom.pages.${item.id}`);
				}
				else if(item?.type)
				{
					return this.$t(`blockDefinitions.${item.type}.label`);
				}
			}

			return item || '';
		}
	}
};
