import { get } from '@/utils/api';

const state = {
	contentCache: {}
};

const mutations = {
	ADD_CONTENT(state, { id, content })
	{
		state.contentCache[id] = content;
	}
};

const actions = {
	async loadContent({ commit }, { id, contentType, url })
	{
		await get(`external/content/${contentType}/url/${encodeURIComponent(url)}`)
			.then((res) =>
			{
				commit('ADD_CONTENT', { id, content: res.data });
			});
	}
};

const getters = {
	getContent: (state) => (contentId) =>
	{
		return state.contentCache[contentId];
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
