// @ts-check
/**
 * @typedef {'query-builder-group'} GroupNode
 */
/**
 * @typedef {'query-builder-rule'} RuleNode
 */
/**
 * @enum {(GroupNode | RuleNode )}
 */
export const NodeTypes = Object.freeze({
	/** @type {GroupNode} */
	group: 'query-builder-group',
	/** @type {RuleNode} */
	rule: 'query-builder-rule'
});

/**
 * @typedef {'source'} QueryContextSource
 */
/**
 * @typedef {'target'} QueryContextTarget
 */
/**
 * Established context data swim lanes.
 * @enum {( QueryContextSource | QueryContextTarget )}
 */
export const QueryContexts = Object.freeze({
	/**
	 * The "left-hand side" of the query.
	 * @type {QueryContextSource}
	 */
	source: 'source',
	/**
	 * The "right-hand side" of the query.
	 * @type {QueryContextTarget}
	 */
	target: 'target'
});
