import Vue from 'vue';
import { pick } from 'dot-object';
import { get, post } from '@/utils/api';
import admin from '@/store/entities/admin';
import connections from '@/store/entities/connections';
import { setItems } from '@/utils/store';
import { powerUps } from '@/configs/constants';
import { addSuccess } from '@/utils/notifications';

const modules = {
	admin,
	connections
};

const state = {
	items: [],
	loading: false,
	blockStatistics: {},
	entityPermissionType: {},
	viewablePages: {},
	landingPageNoMembership: {}
};

const mutations = {
	SET_ENTITIES(state, entities)
	{
		setItems(state, 'items', state.items, entities);
	},
	SET_ENTITY(state, entity)
	{
		const currentIndex = state.items.findIndex((item) => item.id === entity.id);

		if(currentIndex > -1)
		{
			Vue.set(state.items, currentIndex, entity);
		}
		else
		{
			state.items.push(entity);
		}
	},
	REMOVE_ENTITY(state, entityId)
	{
		const index = state.items.findIndex((item) => item.id === entityId);

		if(index > -1)
		{
			Vue.delete(state.items, index);
		}
	},
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	},
	SET_BLOCK_STATISTICS(state, { statTargetId, stat, data })
	{
		if(!state.blockStatistics[statTargetId])
		{
			state.blockStatistics[statTargetId] = {};
		}

		state.blockStatistics[statTargetId][stat] = data;
	},
	SET_ENTITY_PERMISSION_TYPE(state, { permission, entityId })
	{
		state.entityPermissionType[entityId] = permission;
	},
	SET_VIEWABLE_PAGES(state, { viewablePages, entityId })
	{
		Vue.delete(state.viewablePages, entityId);
		Vue.set(state.viewablePages, entityId, viewablePages);
	},
	SET_LANDING_PAGE_NO_MEMBERSHIPS(state, { landingPage, entityId })
	{
		state.landingPageNoMembership[entityId] = landingPage;
	}
};

const actions = {
	async load({ dispatch, getters, rootGetters, commit }, { id, force = false })
	{
		// make sure the id is not an account ID (a number)
		// eslint-disable-next-line no-restricted-globals
		if(isNaN(id))
		{
			if(typeof id === 'undefined' || id === null)
			{
				// console.error('id is not set in store/entities/index.js::load()'); // not really needed
			}
			else if(force || !getters.byId(id))// Only load if we're either forcing, or it doesn't exist
			{
				dispatch('setLoading', true);

				try
				{
					const { data } = await get(`entities/${id}`);

					await dispatch('app/handleServerResponse', data, { root: true });
				}
				catch(e)
				{
					console.error('There was an error', e.message);
				}
				finally
				{
					dispatch('setLoading', false);
				}
			}
		}
	},
	async loadMultiple({ dispatch }, { ids, force = false })
	{
		let idsToUse = ids;

		if(!Array.isArray(ids))
		{
			idsToUse = [ids];
		}

		const naNIds = idsToUse
			.filter((id) => !!id)
			// eslint-disable-next-line no-restricted-globals
			.filter((id) => isNaN(id));

		if(!naNIds.length)
		{
			return;
		}

		const { data } = await post('/entities/get', { ids: naNIds });

		await dispatch('app/handleServerResponse', data, { root: true });
	},
	async loadForDefinition({ dispatch }, definitionId)
	{
		dispatch('setLoading', true);

		try
		{
			const { data } = await get(`entities/definition/${definitionId}`);

			dispatch('app/handleServerResponse', data, { root: true });
		}
		catch(e)
		{
			console.error('There was an error', e.message);
		}
		finally
		{
			dispatch('setLoading', false);
		}
	},
	removeItem({ commit }, entityId)
	{
		commit('REMOVE_ENTITY', entityId);
	},
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	},
	setEntities({ commit }, entities)
	{
		if(Array.isArray(entities))
		{
			// entities.forEach((entity) => commit('SET_ENTITY', entity));
			commit('SET_ENTITIES', entities);
		}
	},
	async gatherBlockStatistics({ commit }, { statTargetId, stat })
	{
		const { data } = await get(`entities/${statTargetId}/blockStatistics/${stat}`);

		commit('SET_BLOCK_STATISTICS', { statTargetId, stat, data });
	},
	async gatherViewablePages({ dispatch, rootGetters, commit }, { entityId })
	{
		let entity = rootGetters['entities/byId'](entityId);

		if(!entity)
		{
			await dispatch('load', { id: entityId });
			entity = rootGetters['entities/byId'](entityId);
		}

		if(entity?.id)
		{
			const idToStore = entity.id; // in case slug was used; normalise to id

			const permissions = entity?.pagePermissions;

			if(permissions)
			{
				const viewablePages = [];
				const currentUser = rootGetters['user/accountId'];
				const currentlyViewingAs = rootGetters['admin/viewingAs'];
				const memberships = rootGetters['entities/getPowerUpData'](entityId, rootGetters['entities/getPowerUpId']('membership'))?.membershipType || [];
				// get memberships on the entity that the user belongs to & map to their names (pagePermissions uses the name as the key)
				let acceptedMemberships = memberships
					.filter((m) => (
						m?.primaryUserList?.value &&
						(
							rootGetters['profiles/isProfileAcceptedInList'](currentUser, m.primaryUserList.value) ||
							// Remember to always check the user isn't using viewAs
							currentlyViewingAs.includes(m.primaryUserList.value)
						)))
					.map((m) => m.primaryListWord?.value);

				if(!acceptedMemberships?.length)
				{
					// not in any memberships? find a default page
					// note (NOT handled here): if user is in a membership, this page could be closed to them, which shouldn't apply if they have no memberships
					acceptedMemberships = ['defaultDisplay'];
				}

				permissions.forEach((permission) =>
				{
					Object.entries(permission).forEach(([key, value]) =>
					{
						if(!['title', 'landing', 'visibility', 'pageId'].includes(key))
						{
							// if the user IS in this membership and this membership IS allowed to view the page
							// memberships for which the permission is not set will not be allowed to view!
							if(value === true && acceptedMemberships.includes(key))
							{
								viewablePages.push(permission.pageId);

								if(permission.landing)
								{
									commit('SET_LANDING_PAGE_NO_MEMBERSHIPS', {
										landingPage: permission.pageId,
										entityId: idToStore
									});
								}
							}
						}
					});
				});

				commit('SET_VIEWABLE_PAGES', {
					viewablePages: Array.from(new Set(viewablePages)),
					entityId: idToStore
				});
			}
		}
	},
	async setPermissionType({ dispatch, commit, rootGetters }, { entityId, newPermission, doCommit = true })
	{
		const { data } = await post(`entities/${entityId}/permissionTypes`, { newPermission });

		dispatch('app/handleServerResponse', data, { root: true });

		if(doCommit)
		{
			const { permission } = data;

			commit('SET_ENTITY_PERMISSION_TYPE', { permission, entityId });

			await dispatch('load', { id: entityId, force: true });

			addSuccess(rootGetters['i18n/dynamicValue']('entities.permissionTypeUpdated', {
				permission,
				// for some reason, this doesn't work with a capital letter in the variable name
				entity: rootGetters['entities/byId'](entityId)?.title
			}));
		}

		return data;
	}
};

const getters = {
	all: (state) => state.items,
	loading: (state) => state.loading,
	convertSlugToId: (state, getters) => (idOrSlug) =>
	{
		if(!idOrSlug) return null;

		const entity = getters.byId(idOrSlug);

		if(entity)
		{
			return entity.id;
		}

		return null;
	},
	byId: (state, getters) => (idOrSlug) =>
	{
		if(!idOrSlug) return null;

		return getters.all.find((entity) => entity.id === idOrSlug || entity.slug === idOrSlug);
	},
	byDefinition: (state, getters) => (defId) => getters.all.filter((entity) => entity.definition === defId),
	isPowerUpEnabled: (state, getters) => (id, powerUpId) =>
	{
		const entity = getters.byId(id);

		if(entity)
		{
			return entity.powerUps.includes(powerUpId);
		}

		return false;
	},
	getPowerUpId: () => (name) =>
	{
		return powerUps[name];
	},
	getPowerUpData: (state, getters) => (id, powerUpId) =>
	{
		const isPowerUpEnabled = getters.isPowerUpEnabled(id, powerUpId);

		if(!isPowerUpEnabled)
		{
			return undefined;
		}

		const entity = getters.byId(id);

		return pick(`powerUpData.${powerUpId}`, entity);
	},
	getAllMembershipLabels: (state, getters) => (entityId) =>
	{
		const memberships = getters.getPowerUpData(entityId, powerUps.membership)?.membershipType;

		return memberships?.map((membership) => ({
			label: membership.primaryListWord?.value,
			id: membership.id
		})) || [];
	},
	getAllMembershipLandingPages: (state, getters) => (entityId) =>
	{
		const membershipLabels = getters.getAllMembershipLabels(entityId);
		const permissions = getters.byId(entityId)?.pagePermissions;
		const results = [];

		if(permissions?.length)
		{
			permissions.filter((p) => p).forEach((page) =>
			{
				membershipLabels.forEach((membership) =>
				{
					if(page[`landing-${membership.label}`] === true)
					{
						results.push({
							pageId: page.pageId,
							membershipId: membership.id,
							membershipLabel: membership.label
						});
					}
				});
			});
		}

		return results;
	},
	getLandingPageForMembership: (state, getters) => (entityId, membershipId) =>
	{
		const landingPages = getters.getAllMembershipLandingPages(entityId);
		const foundMembership = landingPages.find((page) => (page.membershipId === membershipId));

		return foundMembership?.pageId;
	},
	getViewablePages: (state) => (entityId) => state.viewablePages[entityId],
	getLandingPageNoMemberships: (state) => (entityId) => state.landingPageNoMembership[entityId],
	getValueByPath: (state, getters) => (id, path) =>
	{
		const entity = getters.byId(id);

		if(!entity)
		{
			return undefined;
		}

		return pick(path, entity);
	},
	getContextValueByPath: (state, getters) => (id, path) =>
	{
		const prefix = 'context';

		return getters.getValueByPath(id, `${prefix}.${path}`);
	},
	getFlatData: (state, getters) => (id) =>
	{
		const entity = getters.byId(id);

		if(typeof entity === 'undefined' || entity === null)
		{
			return {};
		}

		return { ...entity, context: undefined, ...entity.context };
	},
	// Sometimes we don't care where a property is stored (context) or not we just know it's name and want to get it
	getFlatValueByPath: (state, getters) => (id, path) =>
	{
		const data = getters.getFlatData(id);

		if(!data || !path)
		{
			return undefined;
		}

		return pick(path, data);
	},
	dynamicWord: (state, getters, rootState, rootGetters) => (entityId, type = 'primaryList') =>
	{
		try
		{
			const id = getters.convertSlugToId(entityId);
			const customWord = rootGetters['i18n/get'](`custom.entities.${id}.${type}Word`);

			if(customWord)
			{
				return customWord;
			}

			throw new Error('No custom word found');
		}
		catch(e)
		{
			return rootGetters['i18n/get'](`entities.${type}`);
		}
	},
	leaderWord: (state, getters, rootState, rootGetters) => (entityId) =>
	{
		const customWord = getters.dynamicWord(entityId, 'primaryList');

		if(customWord)
		{
			return customWord;
		}

		return rootGetters['i18n/get']('entities.primaryListWord');
	},
	followerWord: (state, getters, rootState, rootGetters) => (entityId) =>
	{
		const customWord = getters.dynamicWord(entityId, 'secondaryList');

		if(customWord)
		{
			return customWord;
		}

		return rootGetters['i18n/get']('entities.secondaryListWord');
	},
	getBlockStatistics: (state) => (statTargetId, stat) => (state.blockStatistics[statTargetId] ? state.blockStatistics[statTargetId][stat] : null),
	getLabelValueForSelectedOptionForAlias: (state, getters, rootState, rootGetters) => (targetId, alias) =>
	{
		const path = rootGetters['app/settings/mappedKeyForAlias'](alias);
		const value = rootGetters['entities/getFlatValueByPath'](targetId, path);
		const schemaId = rootGetters['app/settings/keyForAlias'](alias);
		const field = rootGetters['structure/fields/getFieldBySchemaId'](schemaId);

		if(!field || !value || !path || !schemaId)
		{
			return null;
		}

		return rootGetters['i18n/get'](`custom.fields.${field.id}.options.${value}`);
	},
	getPermissionType: (state) => (entityId) => state.entityPermissionType[entityId]
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
	modules
};
