import cloneDeep from 'lodash/cloneDeep';
import { get } from '@/utils/api';
import EventBus from '@/components/admin/generic/EventBus';
import { addError, addWarn } from '@/utils/notifications';

export default {
	computed: {
		initialValue()
		{
			if(this.initial && (this.initial.length || this.initial instanceof HTMLImageElement)) return this.initial;

			if(typeof this.value === 'string') return this.value;

			return null;
		},
		/**
		 * Grab the source for the embedded image
		 * The image from the current model is needed after the first user-initiated image upload
		 * Otherwise, the built-in refresh() command will load our previous initialImage
		 */
		newSource()
		{
			return this.model.img?.src || '';
		},
		/**
		 * Use vue-croppa' built-in autoSizing setting (don't use this with matchAspect, or it won't work properly)
		 */
		setAutoSizing()
		{
			if(typeof this.autoSizing !== 'undefined') return this.autoSizing;

			if(typeof this.opts?.autoSizing !== 'undefined') return this.opts?.autoSizing;

			return false;
		},
		/**
		 * Allow this component to decide its height based on the natural dimensions and displayWidth
		 */
		setMatchAspectRatio()
		{
			return this.matchAspect || this.opts?.matchAspect;
		},
		indicatorPosX()
		{
			return `calc(${this.focalPointPercentage.x}% - 11px)`;
		},
		indicatorPosY()
		{
			return `calc(${this.focalPointPercentage.y}% - 11px)`;
		},
		file()
		{
			return this.validFileId ? this.$store.getters['files/byId'](this.fileId) : '';
		},
		validFileId()
		{
			return this.fileId && Number.isInteger(this.fileId);
		},
		maxHeight()
		{
			return this.$q.screen.lt.md ? '50vh' : '60vh';
		},
		previewOptions()
		{
			const options = [
				{
					value: '5-4',
					default: true
				},
				{
					value: '4-3',
					default: true
				},
				{
					value: '16-9',
					default: true
				},
				{
					value: '21-9',
					default: true
				},
				{
					value: '1-1',
					default: false
				},
				{
					value: '3-2',
					default: false
				},
				{
					value: '16-10',
					default: false
				},
				{
					value: '9-16',
					default: false
				}
			];

			// add new values above, but add labels to imageUploader.preview.options in I18N
			return [
				...options.map((opt) =>
				{
					const path = `imageUploader.preview.options.${opt.value}.label`;

					return {
						...opt,
						...(this.$te(path) && { label: this.$t(path) })
					};
				}),
				...this.customPreviewOptions
			];
		},
		previewOptionsPretty()
		{
			return cloneDeep(this.previewOptions)
				.map((opt) =>
				{
					const ratio = opt.value.replace('-', ':');

					opt.label = opt.label ? `${opt.label} (${ratio})` : `${ratio}`;

					return opt;
				});
		},
		previewEnabled()
		{
			return this.preview || this.opts?.preview || false;
		},
		activePreviewsPreserveOrder()
		{
			const opts = cloneDeep(this.previewOptions)
				.map((opt) => opt.value)
				.filter((val) => this.activePreviews.includes(val));

			opts.sort((a, b) =>
			{
				const [aW, aH] = a.split('-');
				const [bW, bH] = b.split('-');

				const aRatio = aW / aH;
				const bRatio = bW / bH;

				if(aRatio < bRatio)
				{
					return -1;
				}

				if(bRatio < aRatio)
				{
					return 1;
				}

				return 0;
			});

			return opts;
		},
		maximisedPreviewRatioCalc()
		{
			const [w, h] = this.maximisedPreviewRatio.split('-');

			return h / w;
		},
		maximisedPreviewMaxWidth()
		{
			// 900px is the default modal width
			return (1 / this.maximisedPreviewRatioCalc) * 900;
		},
		maximisedPreviewRatioPercentage()
		{
			return this.maximisedPreviewRatioCalc * 100;
		},
		validImage()
		{
			return this.image && this.image !== 'image:';
		},
		maximisedPreviewRatioOption: {
			get()
			{
				return this.previewOptionsPretty
					.find((opt) => opt.value === this.maximisedPreviewRatio);
			},
			set(selection)
			{
				this.maximisedPreviewRatio = selection.value;
			}
		},
		previewSizeClass()
		{
			return `previewSize-${this.previewsPerRow}`;
		}
	},
	watch: {
		file: {
			handler(newVal, oldVal)
			{
				if((newVal?.data?.title !== oldVal?.data?.title) || (newVal?.data?.description !== oldVal?.data?.description))
				{
					this.loadMetadataFromFile();
				}
			},
			deep: true
		},
		async fileId(newVal, oldVal)
		{
			if(newVal !== oldVal)
			{
				if(this.formSave)
				{
					await this.saveFocalPoint();
					await this.saveMetadata();
					this.formSave = false;
				}

				this.focalPointSetup();
			}
		},
		image(newVal, oldVal)
		{
			if(newVal !== oldVal)
			{
				this.imageChanged = true;
			}
		},
		'activePreviews.length': {
			handler(newVal)
			{
				let value = newVal;

				if(value < 2)
				{
					value = 2;
				}

				if(value > 5)
				{
					value = 5;
				}

				this.calculatePreviewsPerRow(value);
			}
		}
	},
	async mounted()
	{
		if(this.validFileId)
		{
			await this.$store.dispatch('files/loadFiles', { fileIds: [this.fileId], force: true });

			if(!this.disableMetadataEdit)
			{
				this.loadMetadataFromFile();
			}
		}

		await this.focalPointSetup();

		if(this.scrollIntoView)
		{
			const rect = this.$refs?.uploaderControls?.getBoundingClientRect() || null;

			if(rect)
			{
				const offset = Math.round(rect.top + rect.height) - this.$q.screen.height + 16;

				if(offset > 0)
				{
					window.scrollBy(0, offset);
				}
			}
		}

		this.calculatePreviewsPerRow(this.previewsPerRow);
	},
	methods: {
		async doCreatedActions()
		{
			if(this.opts.initial === 'url')
			{
				const { data } = await get(this.url);

				this.initialValue = data.image;
				this.image = data.image;
			}

			const formId = this.id?.split('|')[0];

			if(formId && !this.disableFocalSelect)
			{
				EventBus.$on(`admin:form:${formId}:save`, this.prepareForSave);
			}

			// set up the default active previews
			this.activePreviews = this.previewOptions
				.filter((opt) => opt.default)
				.map((opt) => opt.value);
		},
		doBeforeDestroyActions()
		{
			const formId = this.id?.split('|')[0];

			if(formId && !this.disableFocalSelect)
			{
				EventBus.$off(`admin:form:${formId}:save`);
			}
		},
		/**
		 * Generate new cropped image data, value to save
		 */
		newImage()
		{
			try
			{
				this.image = this.model.generateDataUrl(`image/${this.type}`);
				this.$emit('input', this.image);
			}
			catch(e)
			{
				// just ignore as it's likely that the croppa's `this.canvas` wasn't ready when it was used
				console.warn('Croppa error caught', e);
			}
		},
		/**
		 * Set viewport dimensions based on uploaded image aspect ratio
		 */
		updateViewport()
		{
			let { naturalHeight, naturalWidth } = this.model;

			// in case someone uploads a weird picture
			if(naturalHeight === 0 || naturalWidth === 0) return;

			const naturalAspectRatio = this.getRatio(naturalWidth, naturalHeight);

			if(this.setMatchAspectRatio)
			{
				// limit the real dimensions of the image to a max size, keep the natural ratio
				// limit by larger dimension, to fit the whole image into the max dimensions
				// in here, the image will not be cropped
				if(naturalHeight > this.maxDimensions.height)
				{
					({
						width: naturalWidth,
						height: naturalHeight
					} = this.getNewDimensionsByHeightAndRatio(this.maxDimensions.height, naturalAspectRatio));
				}
				else if(naturalWidth > this.maxDimensions.width)
				{
					({
						width: naturalWidth,
						height: naturalHeight
					} = this.getNewDimensionsByWidthAndRatio(this.maxDimensions.width, naturalAspectRatio));
				}

				if(naturalWidth > this.width || naturalHeight > this.height)
				{
					// just use the original image
					// the size the image is displayed as
					// (might be in another container though and not in full size)
					if(!this.strictImageSize)
					{
						this.width = naturalWidth;
						this.height = naturalHeight;
					}

					if(this.opts)
					{
						this.opts.width = naturalWidth;
						this.opts.height = naturalHeight;
						// the size the image is saved at
						this.opts.displayHeight = `${naturalHeight}px`;
						this.opts.displayWidth = `${naturalWidth}px`;
					}
				}
				else
				{
					const displayWidth = parseInt(this.displayWidth, 10);
					const rawQuality = +(naturalWidth / displayWidth).toPrecision(4);
					const displayHeight = +(naturalHeight / rawQuality).toFixed(0);

					if(!this.strictImageSize)
					{
						this.width = displayWidth;
						this.height = displayHeight;
					}

					if(this.opts)
					{
						this.opts.displayWidth = `${displayWidth}px`;
						this.opts.displayHeight = `${displayHeight}px`;
					}
				}
			}
			else
			{
				// limit the real dimensions of the image to a max size, keep the natural ratio
				// limit by smaller dimension, since the other dimension is going to be limited by the viewport
				if(naturalHeight < naturalWidth && naturalHeight > this.maxDimensions.height)
				{
					({
						width: naturalWidth,
						height: naturalHeight
					} = this.getNewDimensionsByHeightAndRatio(this.maxDimensions.height, naturalAspectRatio));
				}
				else if(naturalWidth > this.maxDimensions.width)
				{
					({
						width: naturalWidth,
						height: naturalHeight
					} = this.getNewDimensionsByWidthAndRatio(this.maxDimensions.width, naturalAspectRatio));
				}

				if(!this.strictImageSize)
				{
					// Let's try to keep the original image quality and fill the original dimensions.
					// Calculate the aspect ratios to compare the natural and required dimensions.
					// Depending on if the image needs to fit or fill, the calculations for image resize are flipped
					const requiredAspectRatio = this.getRatio(this.width, this.height);
					let viewportHeight,
						viewportWidth;

					// if the new image is wider rather than higher than the required image dimensions
					if(naturalAspectRatio > requiredAspectRatio)
					{
						({
							width: viewportWidth,
							height: viewportHeight
						} = this.getNewDimensionsByHeightAndRatio(naturalHeight, requiredAspectRatio));
					}
					else
					{
						({
							width: viewportWidth,
							height: viewportHeight
						} = this.getNewDimensionsByWidthAndRatio(naturalWidth, requiredAspectRatio));
					}

					this.width = viewportWidth;
					this.height = viewportHeight;
				}

				if(this.opts)
				{
					this.opts.width = naturalWidth;
					this.opts.height = naturalHeight;
					this.opts.displayWidth = `${naturalWidth}px`;
					this.opts.displayHeight = `${naturalHeight}px`;
				}
			}

			this.model.refresh();
		},
		getRatio(width, height)
		{
			return +(width / height).toFixed(4);
		},
		getNewDimensionsByWidthAndRatio(width, ratio)
		{
			// divided by ratio because `ratio = width / height`
			// then to get height again `newWidth * (1 / ratio) -> newWidth / ratio`
			return {
				height: +(width / ratio).toFixed(0),
				width
			};
		},
		getNewDimensionsByHeightAndRatio(height, ratio)
		{
			return {
				height,
				width: +(height * ratio).toFixed(0)
			};
		},
		rotateImage()
		{
			this.model.rotate(1);
		},
		flipVertically()
		{
			this.model.flipY();
		},
		flipHorizontally()
		{
			this.model.flipX();
		},
		deleteImage()
		{
			if(this.inLightroomEditor)
			{
				// lightroom is full-screen and needs large dimensions
				// calculate ratio
				const ratio = Number.parseFloat(this.height / this.width).toPrecision(4);

				// set a "good" width for the placeholder image, so it doesn't look blurry
				this.width = window?.innerWidth ||
					document.documentElement?.clientWidth ||
					document.body?.clientWidth;

				// make sure the image ratio stays, so the placeholder doesn't look stretched
				this.height = this.width * ratio;
			}
			else
			{
				// small in-page editor needs original dimensions.
				// The lightroom dimension image will not fit in the page
				this.width = this.requestedWidth;
				this.height = this.requestedHeight;
			}

			setTimeout(() =>
			{
				this.$refs.uploader.remove();
				this.focalPointEdit = false;
			}, 100);
		},
		async focalPointSetup()
		{
			if(!this.disableFocalSelect && this.validFileId)
			{
				await this.$store.dispatch('files/loadFiles', { fileIds: [this.fileId] });

				const { data } = this.file || {};

				if(data)
				{
					this.setFocalPointRelative(data.focalPos?.x || 50, data.focalPos?.y || 50);
				}
				else
				{
					this.setFocalPointRelative(50, 50);
				}
			}
		},
		setFocalPointRelative(x, y)
		{
			this.focalPointPercentage = { x, y };
		},
		async setFocalPointClick(e)
		{
			if(this.focalPointEdit !== true) return;

			const rect = e.target.getBoundingClientRect();
			const x = Number.parseFloat(((e.clientX - rect.left) / rect.width) * 100).toPrecision(4);
			const y = Number.parseFloat(((e.clientY - rect.top) / rect.height) * 100).toPrecision(4);

			this.setFocalPointRelative(x, y);
		},
		async saveFocalPointAndMetadata()
		{
			const { focalPos, title, description } = this.file?.data || {};

			if(focalPos && (focalPos.x !== this.focalPointPercentage.x || focalPos.y !== this.focalPointPercentage.y))
			{
				await this.saveFocalPoint();
			}

			if(description !== this.metadata.description || title !== this.metadata.title)
			{
				await this.saveMetadata();
			}
		},
		prepareForSave()
		{
			if(this.imageChanged)
			{
				// wait for the fileId to change, then save the other data with the new id
				this.formSave = true;
			}
			else
			{
				// the fileId should not change; save the other data now
				this.saveFocalPointAndMetadata();
				this.formSave = false;
			}

			this.imageChanged = false;
		},
		getFocalPoint()
		{
			const { x, y } = this.focalPointPercentage;

			return { x, y };
		},
		async saveFileData(fileId = this.fileId, changes)
		{
			return this.$store.dispatch('files/setData', { fileId, changes });
		},
		async saveFocalPoint(fileId = this.fileId, posX, posY)
		{
			if(this.disableFocalSelect)
			{
				return null;
			}

			const focalPointBefore = this.focalPointPercentage;
			let x = posX,
				y = posY;

			if(!x || !y)
			{
				x = this.focalPointPercentage.x;
				y = this.focalPointPercentage.y;
			}

			try
			{
				return this.saveFileData(fileId, { focalPos: { x, y } });
			}
			catch(err)
			{
				if(this.formSave)
				{
					this.formSave = false;
				}

				throw new Error(err);
			}
			finally
			{
				if(this.formSave)
				{
					this.formSave = false;
				}

				this.focalPointPercentage = { ...focalPointBefore };
			}
		},
		async saveMetadata(data = this.metadata)
		{
			if(this.disableMetadataEdit)
			{
				return null;
			}

			let { title, description } = data;

			if(!Object.keys(data || {}).length)
			{
				addWarn(this.$t('assetManager.warnings.noData'));

				return null;
			}

			if(!this.validFileId)
			{
				this.metadata.title = data.title;
				this.metadata.description = data.description;
				this.formSave = true; // the changes should be saved when the fileId changes (i.e. after the file is uploaded)

				return null;
			}

			if(typeof title === 'undefined')
			{
				title = this.metadata.title;
			}

			if(typeof description === 'undefined')
			{
				description = this.metadata.description;
			}

			if(typeof title === 'undefined' && typeof description === 'undefined')
			{
				// no data to save; so don't
				return null;
			}

			try
			{
				return this.saveFileData(this.fileId, { title, description });
			}
			catch(err)
			{
				if(this.formSave)
				{
					this.formSave = false;
				}

				throw new Error(err);
			}
			finally
			{
				if(this.formSave)
				{
					this.formSave = false;
				}

				this.metadata.title = title;
				this.metadata.description = description;
			}
		},
		loadMetadataFromFile()
		{
			if(!this.disableMetadataEdit && this.file?.data)
			{
				this.metadata.title = this.file.data.title;
				this.metadata.description = this.file.data.description;
			}
		},
		showControl(key)
		{
			return !this.controls || !Array.isArray(this.controls) || this.controls.includes(key) ||
				(this.opts !== null && Object.prototype.hasOwnProperty.call(this.opts, key));
		},
		calcPreviewHeight(ratio)
		{
			const [x, y] = ratio.split('-');

			if(x && y)
			{
				const height = this.previewWidth * (Number(y) / Number(x));

				if(height > this.maximumPreviewHeightInline)
				{
					return this.maximumPreviewHeightInline;
				}

				return height;
			}

			return this.previewWidth;
		},
		calcPreviewWidth(ratio)
		{
			const height = this.calcPreviewHeight(ratio);

			if(height === this.maximumPreviewHeightInline)
			{
				const [x, y] = ratio.split('-');

				if(x && y)
				{
					return height * (Number(x) / Number(y));
				}
			}

			return this.previewWidth;
		},
		maximisePreview(ratio)
		{
			this.maximisedPreviewRatio = ratio;

			this.$refs.previewModal && this.$refs.previewModal.open();
		},
		calculatePreviewsPerRow(numPerRow)
		{
			// get displayWidth and remove units for the value
			const { displayWidth } = this;
			const [width] = displayWidth.split(/[^0-9]+$/);

			// remove gaps from available space
			const spacing = this.previewHorizontalSpacing * numPerRow;
			const availableSpace = Number(width) - spacing;

			// divide up available space evenly
			this.previewWidth = availableSpace / numPerRow;
			this.previewsPerRow = numPerRow;
		},
		addRatio()
		{
			const ratio = this.customRatio.x / this.customRatio.y;

			if(!this.customRatio.x || !this.customRatio.y)
			{
				addError(this.$t('imageUploader.preview.errors.customRatio.missingDimension'));

				return;
			}

			// block unrealistic display ratios
			// these can mess with the display of the inline preview (and ofc are not particularly helpful)
			if(ratio > 4 || ratio < 0.25)
			{
				addWarn(this.$t('imageUploader.preview.warnings.customRatio.unrealistic'));

				return;
			}

			const newRatio = `${this.customRatio.x}-${this.customRatio.y}`;
			const existing = this.previewOptions
				.find((opt) => opt.value === newRatio);

			if(existing)
			{
				addWarn(this.$t('imageUploader.preview.warnings.customRatio.duplicate'));

				return;
			}

			this.customPreviewOptions.push({
				value: newRatio,
				default: false,
				custom: true
			});

			this.activePreviews.push(newRatio);
		},
		getPreviewOption(value)
		{
			return this.previewOptions
				.find((opt) => opt.value === value);
		},
		removeRatio(value)
		{
			const activePreviewIndex = this.activePreviews
				.findIndex((option) => option === value);
			const customPreviewOptionIndex = this.customPreviewOptions
				.findIndex((option) => option.value === value);

			if(activePreviewIndex > -1)
			{
				this.activePreviews.splice(activePreviewIndex, 1);
			}

			if(customPreviewOptionIndex > -1)
			{
				this.customPreviewOptions.splice(customPreviewOptionIndex, 1);
			}
		},
		swapCustomRatioValues()
		{
			const { x, y } = this.customRatio;

			this.customRatio.x = y || 1;
			this.customRatio.y = x || 1;
		}
	},
	errorCaptured(err, vm, info)
	{
		console.warn('Croppa component error caught in ImageUploader', err);

		return !err.stack.includes('vue-croppa');
	}
};
