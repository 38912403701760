<template>
	<o-menubar-btn
		icon="fal fa-smile"
		round
		:tooltip="$t('form.elements.wysiwyg.emojiSelector.title')"
		class="o-emoji-selector emojiBtn"
	>
		<q-menu
			ref="emojiHiddenPopover"
			style="visibility: false"
			:autoClose="false"
			@show="onShow"
			@hide="onHide"
		/>
		<Modal
			ref="emojiModal"
			light
			width="360px"
			height="520px"
			noScroll
			@closed="closeMenu"
		>
			<EmojiPicker
				@select="selectEmoji"
			/>
		</Modal>
	</o-menubar-btn>
</template>

<script>
	import OMenubarBtn from 'quasar-tiptap/src/components/buttons/OMenubarBtn';
	import EmojiPicker from '@/components/ui/EmojiPicker';

	export default {
		name: 'EmojiSelector',
		components: {
			OMenubarBtn,
			EmojiPicker,
			Modal: () => import('@/components/Modal')
		},
		props: {
			editor: {
				type: Object,
				required: false,
				default: () => ({})
			},
			isActive: {
				type: Object,
				default: () => ({})
			},
			justEmit: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
			};
		},
		computed: {
		},
		methods: {
			selectEmoji(res)
			{
				if(res?.data)
				{
					if(this.justEmit)
					{
						this.$emit('select', res.data);
					}
					else
					{
						const transaction = this.editor.state.tr.insertText(res.data);

						this.editor.view.dispatch(transaction);
					}
				}
			},
			closeMenu()
			{
				this.$refs.emojiHiddenPopover.hide();
			},
			onShow()
			{
				this.$refs.emojiModal.open();
			},
			onHide()
			{
				this.$refs.emojiModal.close();
			}
		}
	};
</script>
