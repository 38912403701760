import { get, put, destroy, post } from '@/utils/api';

const state = {
	loading: false,
	listJoinRequests: {},
	membershipRequests: [],
	states: [],
	loadedAll: false
};

const mutations = {
	SET_ALL_LOADED(state, mode)
	{
		state.loadedAll = mode;
	}
};

const actions = {
	async loadApplications({ commit, dispatch, state })
	{
		if(!state.loadedAll)
		{
			commit('SET_ALL_LOADED', true);
			const { data } = await get('applications');

			await dispatch('app/handleServerResponse', data, { root: true });
		}
	},
	async createApplication({ dispatch }, { label, listId })
	{
		const { data } = await put('applications', { label, listId });

		data.changes.applications.forEach((application) =>
		{
			dispatch('applications/setApplication', application, { root: true });
		});

		dispatch('i18n/updateKeys', data.changes.i18n, { root: true });

		return data;
	},
	async addField({ dispatch }, { applicationId, fieldId })
	{
		const { data } = await put(`applications/${applicationId}/field/${fieldId}`);

		data.forEach((change) => dispatch('applications/setApplication', change, { root: true }));
	},
	async removeField({ dispatch }, { applicationId, fieldId })
	{
		const { data } = await destroy(`applications/${applicationId}/field/${fieldId}`);

		data.forEach((change) => dispatch('applications/setApplication', change, { root: true }));
	},
	async update({ dispatch }, { applicationId, changes })
	{
		const { data } = await put(`applications/${applicationId}`, { changes });

		data.forEach((change) => dispatch('applications/setApplication', change, { root: true }));
	},
	async changeConnectionState({ dispatch }, { connectionId, state })
	{
		const { data } = await post(`/applications/connections/${connectionId}/state/${state}`, {
			initiatedByAdmin: true
		});

		dispatch('applications/connectionRequests/setConnectionRequests', [data], { root: true });
	},
	async deleteApplication({ dispatch }, { applicationId })
	{
		try
		{
			await destroy(`/applications/${applicationId}`);
			dispatch('applications/removeApplication', applicationId, { root: true });
		}
		catch(e)
		{
			// TODO?
		}
	},
	/**
	 * TODO: This should probably live at 'entities/connections/admin'.
	 */
	async createArrangedConnection(
		{ dispatch, rootGetters },
		{
			reservationId,
			entityId,
			connectionTypeId,
			followerAccountId,
			leaderAccountId,
			followerEmailNotification,
			leaderEmailNotification,
			services
		}
	)
	{
		try
		{
			const connectionType = rootGetters['entities/connections/getOneById'](
				entityId,
				connectionTypeId
			);

			if(!connectionType) return false;

			const { data } = await put(
				`applications/${entityId}/connections/${connectionTypeId}/arrange`,
				{
					reservationId,
					followerAccountId,
					leaderAccountId,
					followerEmailNotification,
					leaderEmailNotification,
					services,
					applicationId: connectionType.connectionRequestApplication.value
				}
			);

			await dispatch('applications/connectionRequests/setConnectionRequest', data, { root: true });

			// Refresh some things - fire and forget.
			if(services && Object.values(services).some((value) => value))
			{
				dispatch('powerUps/getServiceRequestCounts', {
					accountId: leaderAccountId,
					connectionId: connectionTypeId,
					forceLoad: true
				}, { root: true });
			}

			dispatch(
				'profiles/getConnectionStats',
				{ accountId: followerAccountId, forceLoad: true },
				{ root: true }
			);

			dispatch(
				'profiles/getConnectionStats',
				{ accountId: leaderAccountId, forceLoad: true },
				{ root: true }
			);

			return true;
		}
		catch(e)
		{
			console.error(e.response?.data?.message || e);

			return e.response?.data?.message || false;
		}
	}
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
