import { get, post } from '@/utils/api';

const state = {
	logTypes: {},
	items: []
};

const mutations = {
	ADD_AUDIT_LOG_ENTRY(state, logEntry)
	{
		state.items.push(logEntry);
	},
	CLEAR_LOG(state)
	{
		state.items = [];
	},
	SET_LOG_TYPES(state, logTypes)
	{
		state.logTypes = logTypes;
	}
};

const actions = {
	async getLogTypes({ commit })
	{
		const logTypes = await get('audit/getLogTypes');

		commit('SET_LOG_TYPES', logTypes);
	},
	log({ commit }, { logType, subject, extra = undefined })
	{
		const logEntry = {
			logType,
			subject,
			actionTime: Date.now(),
			extra
		};

		commit('ADD_AUDIT_LOG_ENTRY', logEntry);
	},
	// For when you absolutely want to register an event right away
	async logImmediate({ dispatch }, payload)
	{
		dispatch('log', payload);
		await dispatch('save');
	},
	async save({ commit, getters, rootGetters })
	{
		if(!rootGetters['user/loggedIn'] || !getters.all.length)
		{
			return;
		}

		const logs = [...getters.all];

		commit('CLEAR_LOG');

		// no need for loading bars
		await post('audit/save', logs, {}, { quiet: true });
	}
};

const getters = {
	all: (state) => state.items,
	allLogTypes: (state) => state.logTypes,
	logTypeById: (state, getters) => (id) => getters.allLogTypes[id]
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
