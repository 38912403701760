<template>
	<SimpleTabsOverflow
		:useSlots="isAdminEditMode || onlyShowCurrentPage"
		:noArrows="!isAdminEditMode && !isSmallScreen"
		:customTabsBarClasses="['pages']"
		:customOverflowMenuClasses="customOverflowMenuClasses"
		:tabs="pages"
		i18nBasePath="custom.pages"
		:tabIdsToShow="pageIdsToShow"
		:currentTab="currentPage"
		:routeLink="routeLink"
	>
		<template #customTabs>
			<draggable
				v-if="!onlyShowCurrentPage && isAdminEditMode"
				v-model="pages"
				:disabled="!(isAdminEditMode && canMovePagesInModule)"
				style="display: inherit"
			>
				<span
					v-for="page in pages"
					:key="`${page.id}-${renderIndex}`"
					@dragstart="setHeldPage(page)"
					@dragend="deactivateDropzones"
				>
					<router-link
						v-slot="{ route, isActive }"
						:to="routeLink(page)"
					>
						<q-route-tab
							v-if="isActive || pageIdsToShow.includes(page.id)"
							:to="route"
						>
							<div class="inline-content">
								<I18N :id="`custom.pages.${page.id}`" />
								<SimpleButton
									v-if="isAdminEditMode && userCanSpecific(page.id)"
									size="sm"
									:icon="page.id === currentlyEditingItemId ? 'fas fa-cog' : 'fad fa-cog'"
									:color="page.id === currentlyEditingItemId ? 'primary' : 'neutral'"
									class="cog"
									round
									flat
									:aria-label="$t('blocks.controls.header.edit')"
									@click.stop.prevent="edit(page.id)"
								/>
							</div>
						</q-route-tab>
					</router-link>
				</span>
			</draggable>
			<q-route-tab
				v-else-if="onlyShowCurrentPage"
				:to="routeLink(currentPage)"
			>
				<div class="inline-content">
					<I18N :id="`custom.pages.${currentPage.id}`" style="float: left;" />
					<SimpleButton
						v-if="isAdminEditMode && userCanSpecific(currentPage.id)"
						:icon="currentPage.id === currentlyEditingItemId ? 'fas fa-cog' : 'fad fa-cog'"
						:color="currentPage.id === currentlyEditingItemId ? 'primary' : 'neutral'"
						size="sm"
						class="cog"
						round
						flat
						@click.prevent="edit(currentPage.id)"
					/>
				</div>
			</q-route-tab>
			<q-tab v-if="isAdminEditMode && canAddPagesToModule" @click="newPage">
				<SimpleButton
					color="neutral"
					icon="far fa-plus"
					size="sm"
					class="plus"
					round
					flat
					:aria-label="$t('blocks.controls.header.addPage')"
				/>
			</q-tab>
		</template>
	</SimpleTabsOverflow>
</template>

<script>
	import Entity from '@/components/mixins/Entity';
	import EventBus from '@/components/admin/generic/EventBus';
	import { userCan, userCanAnyOf } from '@/plugins/Permissions';
	import SimpleTabsOverflow from '@/components/layout/navigation/NavItemsTabsOverflow.vue';
	import { cAdminPanelTypes } from '@/configs/adminPanelTypes';

	export default {
		components: {
			draggable: () => import('vuedraggable'),
			SimpleTabsOverflow
		},
		mixins: [Entity],
		props: {
			gradientFallback: {
				default: false,
				type: Boolean
			}
		},
		data()
		{
			return {
				renderIndex: 1
			};
		},
		computed: {
			isSmallScreen()
			{
				return this.$q.screen.lt.md;
			},
			isAdminEditMode()
			{
				return this.$store.getters['admin/isEditMode'] &&
					userCan('manageEditMode', 'administration');
			},
			canAddPagesToModule()
			{
				return userCan('addChildren', 'items.modules', this.currentModule.id);
			},
			canMovePagesInModule()
			{
				return userCan('moveChildren', 'items.modules', this.currentModule.id);
			},
			currentlyEditingItemId()
			{
				return this.$store.getters['admin/panel/activeStack']?.id;
			},
			pages: {
				get()
				{
					const activePages = this.$store.getters['structure/pages/getModulePages'](this.currentModule.id);

					// If this person is in admin edit mode, show everything
					if(this.isAdminEditMode)
					{
						// while the backend should handle figuring out what pages anyone can see,
						// let's filter here too to avoid any errors
						return activePages.filter((page) => page);
					}

					return activePages.filter((page) => page && page.hideFromNav !== true);
				},
				set(pages)
				{
					this.$store.dispatch('structure/pages/admin/changeOrder', pages);
				}
			},
			adminMode()
			{
				return this.isAdminEditMode ? 'adminMode' : '';
			},
			currentModule()
			{
				return this.$store.getters['structure/modules/getModuleBySlug'](
					this.$store.state.route.params.moduleSlug
				);
			},
			// If the current module is abstract, then we want to show all abstract pages;
			// if it's not, then we only show abstract pages when they're active
			isCurrentModuleAbstract()
			{
				return this.currentModule?.isAbstract;
			},
			currentPage()
			{
				return this.$store.getters['structure/pages/getPageBySlug'](
					this.$store.state.route.params.moduleSlug,
					this.$store.state.route.params.pageSlug
				) ?? {};
			},
			onlyShowCurrentPage()
			{
				return this.currentPage?.showOnlyThis || false;
			},
			entityId()
			{
				/*
				 This try catch is to stop a race condition where `convertSlugToId` does not exist when loading the
				 page sometimes. This particularly seems to cause an issue with the monitoring script.

				 It's possible that when `unsplit-main` is merged (which undoes the lazy loading of the stores too)
				 that this will be resolved, but for now, this is a workaround.
				*/
				try
				{
					return this.$store.getters['entities/convertSlugToId'](this.$store.state.route.params?.targetId);
				}
				catch(e)
				{
					return null;
				}
			},
			/**
			 * When the user joins/leaves a list, their viewable pages are likely to change
			 * @returns {Array<UUID>}
			 */
			listMemberships()
			{
				return this.$store.getters['profiles/get'](this.$store.getters['user/accountId'])?.acceptedUserListIds;
			},
			/**
			 * @returns {Array<UUID>} - Ids of pages which may be visited by this user
			 */
			pageIdsToShow()
			{
				return (this.pages || [])
					.filter((page) => this.shouldShowPage(page))
					.map((page) => page.id);
			},
			customOverflowMenuClasses()
			{
				const customOverflowMenuClasses = ['pages'];

				if(this.gradientFallback)
				{
					customOverflowMenuClasses.push('fallback-background-color');
				}

				return customOverflowMenuClasses;
			}
		},
		watch: {
			/** `viewablePages` is (computed) in the Entity mixin */
			viewablePages: {
				async handler(val)
				{
					this.renderIndex += 1;
				},
				deep: true
			},
			listMemberships: {
				async handler(val)
				{
					this.renderIndex += 1;
				},
				deep: true
			},
			entityId: {
				async handler(val)
				{
					// this can't be loaded in created/mounted() as this.entityId will not have been calculated by then
					if(this.entityId)
					{
						await this.$store.dispatch('user/getListMemberships');
					}
				}
			}
		},
		methods: {
			newPage()
			{
				this.$store.dispatch('structure/pages/admin/openPanel');
			},
			edit(id)
			{
				this.$store.dispatch('admin/panel/new', {
					type: cAdminPanelTypes.editPage, id, key: id, moduleId: this.currentModule.id
				}, { root: true });
			},
			isRouteActive(routeLink)
			{

			},
			routeLink(page)
			{
				if(page.isAbstract && this.currentModule.isAbstract)
				{
					return { name: 'space', params: { moduleSlug: this.currentModule.slug, pageSlug: page.slug } };
				}

				return { name: 'page', params: { moduleSlug: this.currentModule.slug, pageSlug: page.slug } };
			},
			shouldShowPage(page)
			{
				// let admins view all pages, unless viewing as something else
				if(
					this.isAdminEditMode &&
					!this.$store.getters['admin/isViewingAs']
				)
				{
					return true;
				}

				if(page.id === this.currentPage.id)
				{
					// always show the current page
					return true;
				}
				// No longer checking if the entity has any memberships or has the power-up enabled;
				// all entity pages should use membership permissions regardless
				else if(this.entity?.permissionType === 'memberships')
				{
					return this.canUserViewEntityPage(page.id);
				}
				else if(page.isAbstract)
				{
					// if the page is abstract but it's in a non-abstract module,
					// the page should not be considered abstract
					return !this.currentModule.isAbstract;
				}

				return !page.isAbstract;
			},
			setHeldPage(page)
			{
				if(this.isAdminEditMode) // check if we receive a page or an event
				{
					if(typeof page.id !== 'undefined')
					{
						this.$store.dispatch('structure/pages/admin/setHeld', {
							moduleId: this.currentModule.id,
							page
						});

						EventBus.$emit('navigation:modules:dropzones:activate');
					}
					else
					{
						this.$store.dispatch('structure/pages/admin/setHeld', {
							moduleId: this.currentModule.id,
							page: {}
						});

						EventBus.$emit('navigation:modules:dropzones:deactivate');
					}
				}
			},
			deactivateDropzones()
			{
				EventBus.$emit('navigation:modules:dropzones:deactivate');
			},
			userCanSpecific(id)
			{
				return userCanAnyOf([{
					actions: [
						'managePermissions',
						'manageSettings',
						'manageVisibility'
					],
					subject: 'items.pages',
					subjectId: id
				}]);
			}
		}
	};
</script>

<style lang="postcss" scoped>
	.page-navigation {
		max-width: 80rem;
	}

	.pages-tabs {
		display: inherit;
	}

	.inline-content {
		display: inline-block;
	}

	.cog {
		pointer-events: auto;
		vertical-align: inherit;

		&:hover,
		&:focus {
			color: #333;
			opacity: 1;
		}
	}

	>>> .q-tabs__arrow--left {
		left: -20px;
		font-size: 20px;
	}

	>>> .q-tabs__arrow--right {
		right: -20px;
		font-size: 20px;
	}

</style>
