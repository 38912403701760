<template>
	<div class="error">
		Component failed to load
	</div>
</template>

<script>
	export default {
		components: { }
	};
</script>

<style scoped>
	.loader {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
</style>
