import admin from '@/store/statistics/admin';

const state = {};
const mutations = {};
const actions = {};
const getters = {};
const modules = {
	admin
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
