// import deepmerge from 'deepmerge';
// import dot from 'dot-object';
import Vue from 'vue';

function cacheId(params = [])
{
	return params.join('-');
}

const state = {
	cache: {},
	updating: []
};

const mutations = {
	SET_CACHE(state, { data = {}, params = [] })
	{
		Vue.set(state.cache, cacheId(params), data);
	},
	SET_UPDATING(state, { id, mode })
	{
		if(mode)
		{
			state.updating.push(id);
		}
		else
		{
			state.updating = state.updating.filter((updatingId) => id !== updatingId);
		}
	}
};

const actions = {
	setCache({ commit }, payload)
	{
		commit('SET_CACHE', payload);
	},
	setUpdating({ commit }, { params = [], mode = false })
	{
		commit('SET_UPDATING', { id: cacheId(params), mode });
	}
};

const getters = {
	cacheValue: (state) => (params = []) =>
	{
		return state.cache?.[cacheId(params)];
	},
	hasCache: (state) => (params = []) =>
	{
		return !!state.cache?.[cacheId(params)];
	},
	isUpdating: (state) => (params) => state.updating.includes(cacheId(params))
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
