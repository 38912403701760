<template>
	<q-list
		style="min-width: 300px; max-width: 500px"
	>
		<q-item-label header>
			<div class="flex items-center">
				<h3 class="notifMessHeading">
					<I18N :id="label" />
				</h3>
				<q-space />
				<SimpleButton
					v-if="labelLink"
					:to="labelLink"
					size="md"
					unelevated
				>
					<I18N id="navigation.notifications.allMessagesBtn" />
				</SimpleButton>
			</div>
			<UserSelector
				v-if="showUserSearch"
				ref="searchUser"
				v-model="quickAdd"
				:searchRules="ruleKey"
				:behaviour="`default`"
				class="q-mt-md"
			/>
		</q-item-label>
		<div
			v-for="(notification, i) in sortedNotifications"
			ref="items"
			:key="i"
		>
			<q-item
				v-if="!!itemType(notification)"
				v-ripple
				clickable
				:class="{ unread: !notification.read }"
				class="q-pa-lg"
			>
				<component
					:is="itemType(notification)"
					ref="item"
					class="notificationContent"
					:notification="notification"
				/>
				<div
					v-if="!notification.read"
					class="dot-indicator"
				/>
			</q-item>
			<q-separator v-if="notifications.length - 1 !== i" />
		</div>
	</q-list>
</template>

<script>
	import moment from 'moment';
	import { notificationTypes } from '@/configs/constants';

	export default {
		components: {
			EntityApplication: () => import('@/components/ui/notificationsMenu/item/EntityApplication'),
			AdminNotification: () => import('@/components/ui/notificationsMenu/item/AdminNotification'),
			EntityNotification: () => import('@/components/ui/notificationsMenu/item/EntityNotification'),
			EntityNotificationStatus: () => import('@/components/ui/notificationsMenu/item/EntityNotificationStatus'),
			Chat: () => import('@/components/ui/notificationsMenu/item/Chat'),
			UserSelector: () => import('@/components/profile/UserSelector'),
			Mention: () => import('@/components/ui/notificationsMenu/item/Mention'),
			NewDiscussion: () => import('@/components/ui/notificationsMenu/item/NewDiscussion'),
			NewCommentReply: () => import('@/components/ui/notificationsMenu/item/NewCommentReply'),
			Connection: () => import('@/components/ui/notificationsMenu/item/Connection'),
			ReportAbuse: () => import('@/components/ui/notificationsMenu/item/ReportAbuse'),
			UserCommunicationAction: () => import('@/components/ui/notificationsMenu/item/UserCommunicationAction'),
			Order: () => import('@/components/ui/notificationsMenu/item/Order'),
			UserAsset: () => import('@/components/ui/notificationsMenu/item/UserAsset')
		},
		props: {
			label: {
				type: String,
				required: true
			},
			notifications: {
				type: Array,
				required: true
			},
			labelLink: {
				type: String,
				default: null
			},
			showUserSearch: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				quickAdd: [],
				/** @type {import('acb-lib').Search.SearchRuleType} */
				ruleKey: 'chat-search'
			};
		},
		computed: {
			sortedNotifications: {
				get()
				{
					const notifications = [...this.notifications];

					notifications.sort((a, b) =>
					{
						if(a.date && b.date)
						{
							const bIsAfterA = moment(b.date).isAfter(a.date);

							// If the second element was sent after the first, sort the second before the first (return 1)
							// Else, retain the same order (return -1)
							return bIsAfterA ? 1 : -1;
						}

						// If one of our elements doesn't have a date prop, retain the same order
						return -1;
					});

					return notifications;
				}
			}
		},
		watch: {
			async quickAdd(newVal)
			{
				if(newVal)
				{
					const chat = await this.$store.dispatch('chats/createChat', { toAccountId: newVal.accountId });

					this.$router.push({ name: 'chat', params: { targetId: chat.id } });
				}
			}
		},
		methods: {
			itemType(notification)
			{
				if(notification.targetId)
				{
					return 'Chat';
				}

				// If notification typeId is found in the notificationTypes constant
				if(notificationTypes[notification.typeId]) return notificationTypes[notification.typeId?.toString()];

				// Notification typeId was not found
				// throwing an error causes the notifications dropdown to break, preventing any from being viewed - log error and return undefined, so individual, unknown types can be ignored
				// throw new Error(`Unknown typeId: ${notification.typeId}`);
				console.error('Unknown typeId:', notification.typeId);

				return undefined;
			}
		}
	};
</script>

<style scoped lang="postcss">
	.notifMessHeading {
		font-weight: 600;
	}

	.q-item {
		>>> .avatar-shell {
			.avatar-img {
				border-radius: 100%;
			}
		}

		>>> .q-item__section--main ~ .q-item__section--side {
			padding-left: 0;
			font-size: 0.8rem;
		}

		>>> .q-item__section--avatar {
			float: left;
		}

		.notificationContent {
			width: 100%;
		}
	}

	.unread {
		background: #fef7ea;
		position: relative;

		.dot-indicator {
			position: absolute;
			top: 20px;
			left: 16px;
			background: var(--q-color-primary);
			width: 16px;
			border-color: #fef7ea;
		}
	}

	.q-list--dark {
		.unread {
			/* stylelint-disable-next-line a11y/content-property-no-static-value */
			> .dot-indicator {
				background: #f57c00;
				border-color: #36383e;
			}
		}
	}

	.body--dark {
		.unread {
			background: #36383e;
		}
	}

	.label-link {
		color: inherit;
	}
</style>
