/** Regex to test if string is valid email address */
export const cRegexValidEmailAddress =
/^"?\w+([.-]?\w+)*"?\s*@\s*\w+([.-]?\w+)*(\.\w{2,})+$/;

/** Check if domain address from an email address is a valid domain address
 *  Ignores surrounding whitespace and optional angle brackets
 */
export const cRegexValidDomainAddress =
/\S*?<?(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b)>?/i;

/** Test if the string is an email address with name field.
* e.g. `"Name" <email@domain.tld>`
*/
export const cRegexValidEmailAddressWithNameField =
/^"([^"]+)" <\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,})>$/i;

/** Test the 'email address with name field' for trailing parentheses,
* before and after the email address field. `< and >`
*/
export const cRegexValidEmailAddressWithNameParentheses =
/^(?!.*<.*>$)|^(?=.*<)(?=.*>$)/;

/** Vee-validate allows the use of a single double quote at the begin of
 * a string/email, which should be flagged as invalidated
 */
export const cRegexVeeValidateEmailExtension = /^("([^"]+)"|[^"]+)(@[\w.-]+)$/;

/** `imageWidth` for image handlebar helper, accepts integer value with `px` as units or without units
 *
 * valid = no units or with 'px' (e.g. `'500' | '500px'`, no other valid units)
 */
export const cRegexValidImageWidth = /^([0-9]+)(px)?$/;
