<template functional>
	<div
		:style="{ width, height }"
		class="loader"
	>
		<Spinner color="primary" />
		<!-- <ContentLoader
			:width="600"
			:height="600"
			primaryColor="#ff0000"
			secondaryColor="#0000ff"
		> -->
		<!--
		<content-loader
			v-if="loaderType === 'image'"
			:speed="2"
			:animate="true"
			:primaryColor="'#f9f9f9'"
			:secondaryColor="'#c1c1c1'"
		>
		-->
		<!-- eslint-disable-next-line --><!--
			<path style="height: 150px; width: 150px;" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm16 336c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V112c0-8.822 7.178-16 16-16h416c8.822 0 16 7.178 16 16v288zM112 232c30.928 0 56-25.072 56-56s-25.072-56-56-56-56 25.072-56 56 25.072 56 56 56zm0-80c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm207.029 23.029L224 270.059l-31.029-31.029c-9.373-9.373-24.569-9.373-33.941 0l-88 88A23.998 23.998 0 0 0 64 344v28c0 6.627 5.373 12 12 12h360c6.627 0 12-5.373 12-12v-92c0-6.365-2.529-12.47-7.029-16.971l-88-88c-9.373-9.372-24.569-9.372-33.942 0zM416 352H96v-4.686l80-80 48 48 112-112 80 80V352z" />
		</content-loader>
		-->

		<!-- <BulletListLoader
			v-if="loaderType === 'image'"
			style="width: 100%; height: 100%;"
			:speed="2"
			:animate="true"
			:primaryColor="'#f9f9f9'"
			:secondaryColor="'#c1c1c1'"
		/>
		<component
			:is="loaderType"
			v-else
			style="width: 100%; height: 100%;"
			:speed="2"
			:animate="true"
			:primaryColor="'#f9f9f9'"
			:secondaryColor="'#c1c1c1'"
		/> -->
		<!-- </ContentLoader> -->
	</div>
</template>

<script>

	export default {
		props: {
			height: {
				default: '300px',
				type: String
			},
			width: {
				default: '100%',
				type: String
			},
			type: {
				type: String,
				default: 'facebook',
				validator: (value) =>
				{
					return ['facebook', 'code', 'bulletList', 'instagram', 'list', 'image'].includes(value);
				}
			}
		}
	};
</script>

<style scoped>
	.loader {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		text-align: center;
	}
</style>
