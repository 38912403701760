<template>
	<section>
		<q-toggle
			v-model="isConnectivityPrivate"
			:label="i18n.t('privacySettings.connectivity')"
			@input="onIsConnectivityPrivate"
		/>
	</section>
</template>

<script setup>
	// @ts-check
	import { ref } from 'vue';
	import store from '@/store';
	// eslint-disable-next-line no-unused-vars
	import i18n from '@/plugins/vue-i18n';
	import { addServerResponseNotification } from '@/utils/notifications';
	import { cConnectivityPrivacyPath } from '@/configs/constants/settings';
	import { post } from '@/utils/api';

	/**
	 * @template T
	 * @typedef {import('vue').Ref<T>} Ref<T>
	 */

	/** @type {Ref<boolean>} */
	// eslint-disable-next-line no-unused-vars
	const isConnectivityPrivate = ref(
		!!store.getters['user/metaValue'](
			cConnectivityPrivacyPath
		)
	);

	/** @param {boolean} isConnectivityPrivate */
	// eslint-disable-next-line no-unused-vars
	async function onIsConnectivityPrivate(isConnectivityPrivate)
	{
		let result = false;

		try
		{
			await post('profile/meta/privacy/connectivity', {
				isConnectivityPrivate
			});

			await store.dispatch('profiles/updateProfileData', {
				accountId: store.getters['user/accountId'],
				data: {
					meta: {
						settings: {
							privacy: {
								isConnectivityPrivate
							}
						}
					}
				}
			});

			result = true;
		}
		catch(/** @type {*} */e)
		{
			console.error('Failed to update connectivity privacy', e);
		}

		addServerResponseNotification(
			result,
			i18n.t('admin.dash.settings.successfullySaved')
		);
	}
</script>
