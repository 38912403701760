// @ts-check
import Vue from 'vue';
import { get, post } from '@/utils/api';
import { addSuccess, addError } from '@/utils/notifications';
import { processType as allProcessTypes } from '@/configs/constants';

/**
 * @typedef {import('../../types/process').ProcessStore.State} State
 * @typedef {import('../../types/process').ProcessStore.Mutations} Mutations
 * @typedef {import('../../types/process').ProcessStore.Actions} Actions
 * @typedef {import('../../types/process').ProcessStore.Getters} Getters
 */

/** @type {State} */
const state = {
	registeredProcesses: {}
};

/** @type {Mutations} */
const mutations = {
	SET_REGISTERED_PROCESSES(state, processes)
	{
		Vue.set(state, 'registeredProcesses', processes);
	},
	REGISTER_NEW_PROCESS(state, { processType, newProcess })
	{
		// Only register processes with a valid type
		if(!(processType in allProcessTypes))
		{
			console.error(
				'You must register your process type in configs/constants before use!'
			);

			return;
		}

		if(
			!state.registeredProcesses[processType]?.find(
				(appProcess) => appProcess.processId === newProcess.processId
			) && (
				state.registeredProcesses[processType] &&
				Array.isArray(state.registeredProcesses[processType])
			)
		)
		{
			Vue.set(
				state.registeredProcesses,
				processType,
				[...state.registeredProcesses[processType], newProcess]
			);
		}
		else
		{
			Vue.set(
				state.registeredProcesses,
				processType,
				[newProcess]
			);
		}
	},
	DEREGISTER_PROCESS(state, { processType, processId })
	{
		const processIndex = state.registeredProcesses[processType]?.findIndex(
			(appProcess) => appProcess.processId === processId
		);

		if(
			typeof processIndex !== 'undefined' &&
			processIndex > -1
		)
		{
			const processArray = [...state.registeredProcesses[processType]];

			processArray.splice(processId, 1);

			Vue.set(
				state.registeredProcesses,
				processType,
				processArray
			);
		}
	},
	SET_PROCESS_COMPLETED(state, { processType, processId, completed })
	{
		const processIndex = state.registeredProcesses[processType]?.findIndex(
			(appProcess) => appProcess.processId === processId
		);

		if(
			typeof processIndex !== 'undefined' &&
			processIndex > -1
		)
		{
			const processArray = [...state.registeredProcesses[processType]];

			processArray[processIndex].completed = completed;

			Vue.set(
				state.registeredProcesses,
				processType,
				processArray
			);
		}
	},
	SET_PROCESS_REPORT(state, { processId, processType, report })
	{
		const processIndex = state.registeredProcesses[processType]?.findIndex(
			(appProcess) => appProcess.processId === processId
		);

		if(
			typeof processIndex !== 'undefined' &&
			processIndex > -1
		)
		{
			const processArray = [...state.registeredProcesses[processType]];

			processArray[processIndex].report = report;

			Vue.set(
				state.registeredProcesses,
				processType,
				processArray
			);
		}
	},
	SET_PROCESS_REPORT_READ_STATE(state, { processType, processId, readState })
	{
		const processIndex = state.registeredProcesses[processType]?.findIndex(
			(appProcess) => appProcess.processId === processId
		);

		if(
			typeof processIndex !== 'undefined' &&
			processIndex > -1
		)
		{
			const processArray = [...state.registeredProcesses[processType]];

			processArray[processIndex].reportRead = readState;

			Vue.set(
				state.registeredProcesses,
				processType,
				processArray
			);
		}
	}
};

/** @type {Actions} */
const actions = {
	async loadExistingProcesses({ commit })
	{
		const { data } = await get('/admin/process/allProcesses');

		if(
			data &&
			typeof data === 'object' &&
			Object.keys(data)?.length
		)
		{
			commit('SET_REGISTERED_PROCESSES', data);
		}
	},
	async startProcess({ commit, rootGetters }, { processId, processType, payload })
	{
		const { data } = await post(
			`/admin/process/${processType}/start`,
			{
				processId,
				payload
			}
		);

		if(data?.started)
		{
			addSuccess(
				rootGetters['i18n/get'](`admin.${processType}.startup.success`)
			);

			const newProcess = {
				processId,
				stepCompletion: {},
				completed: false,
				errorsList: [],
				report: null
			};

			commit('REGISTER_NEW_PROCESS', { processType, newProcess });

			return true;
		}

		addError(
			data?.message ?
				data.message :
				rootGetters['i18n/get'](`admin.${processType}.startup.failure`)
		);

		return false;
	},
	completeProcess({ commit }, { processId, processType, report })
	{
		commit(
			'SET_PROCESS_COMPLETED', {
				processId,
				processType,
				completed: true
			}
		);

		if(report)
		{
			commit(
				'SET_PROCESS_REPORT',
				{
					processId,
					processType,
					report
				}
			);
		}
	},
	setProcessReportReadState({ commit }, payload)
	{
		commit('SET_PROCESS_REPORT_READ_STATE', payload);
	}
};

/** @type {Getters} */
const getters = {
	registeredProcesses: (state) => state.registeredProcesses,

	registeredProcessesByType: (_, getters) =>
		(processType) => getters.registeredProcesses[processType] || [],

	registeredProcessByTypeAndId: (_, getters) =>
		(processType, processId) =>
			getters.registeredProcessesByType(processType).find(
				(appProcess) => appProcess.processId === processId
			),

	runningProcesses: (_, getters) =>
		Object.entries(getters.registeredProcesses).reduce(
			(processesByType, [processType, processArray]) =>
			{
				processesByType[processType] = processArray.filter(
					(appProcess) => !appProcess.completed
				);

				return processesByType;
			},
			{}
		),

	runningProcessesByType: (_, getters) =>
		(processType) => getters.runningProcesses[processType] || [],

	completedProcesses: (_, getters) =>
		Object.entries(getters.registeredProcesses).reduce(
			(processesByType, [processType, processArray]) =>
			{
				processesByType[processType] = processArray.filter(
					(appProcess) => appProcess.completed
				);

				return processesByType;
			},
			{}
		),

	completedProcessesByType: (_, getters) =>
		(processType) => getters.completedProcesses[processType] || []
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
