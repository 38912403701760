import { exportFile } from 'quasar';
import * as deepmerge from 'deepmerge';
import uniq from 'lodash/uniq';
import { get, post, destroy, getSWR } from '@/utils/api';
import { setItems } from '@/utils/store';
import { addError } from '@/utils/notifications';

const state = {
	files: [],
	filesToLoad: {},
	timers: {}
};

const mutations = {
	ADD_FILES(state, files)
	{
		setItems(state, 'files', state.files, files);
	},
	INCREMENT_DOWNLOAD_COUNT(state, id)
	{
		const currentIndex = state.files.findIndex((file) => file.id === id);
		const file = state.files[currentIndex];

		file.data.downloadCount += 1;

		state.files[currentIndex] = file;
	},
	REMOVE_FILE(state, id)
	{
		state.files = state.files.filter((file) => file.id !== id);
	},
	UPDATE_FILE_DATA(state, { fileId, changes })
	{
		state.files.forEach((file, i) =>
		{
			if(file.id === fileId)
			{
				state.files[i].data = deepmerge(state.files[i].data, changes);
			}
		});
	}
};

const actions = {
	async deleteFile({ commit }, id)
	{
		await destroy(`files/${id}`);
		commit('REMOVE_FILE', id);
	},
	async download({ commit, dispatch }, { id, fileName, mime })
	{
		const { request } = await get(`files/download/${id}`, { responseType: 'blob' });

		dispatch('audit/log', {
			logType: 'fileDownload',
			subject: id,
			extra: {
				filename: fileName
			}
		}, { root: true });

		commit('INCREMENT_DOWNLOAD_COUNT', id);

		exportFile(fileName, request.response, mime);
	},
	/**
	 * Gather together all the files that are requested for in a short span and get them all in one request.
	 * Almost like a `debounce`, but since all requested files are needed, it gathers the ids together, and then requests them.
	 */
	async loadFiles({ state, getters, dispatch }, { fileIds, scope, force, timeout = 10 })
	{
		const filesToLoad = fileIds?.filter((fileId) => fileId && (force || !getters.byId(fileId)));

		if(fileIds?.length === 0) return;

		clearTimeout(state.timers[scope]);

		if(!state.filesToLoad[scope]) state.filesToLoad[scope] = [];

		state.filesToLoad[scope].push(...filesToLoad);
		state.timers[scope] = setTimeout(() => dispatch('fetch', { fileIds: uniq(state.filesToLoad[scope]), scope, force }), timeout);
	},
	/**
	 * Don't access this function directly - use `loadFiles` instead as that gathers any frequent requests together
	 */
	async fetch({ state, getters, dispatch }, { fileIds, scope, force })
	{
		// double-checking if files are already loaded
		const filesToLoad = fileIds?.filter((fileId) => fileId && (force || !getters.byId(fileId)));

		if(filesToLoad?.length === 0) return;

		// remove the ids from the list
		state.filesToLoad[scope] = state.filesToLoad[scope].filter((fileId) => !fileIds.includes(fileId));

		await getSWR(
			'/files/getFiles',
			{
				scope,
				fileIds: filesToLoad
			},
			({ fileList }) =>
			{
				dispatch('addFiles', fileList);
			},
			'post'
		);
	},
	addFiles({ commit }, files)
	{
		commit('ADD_FILES', files);
	},
	async setData({ commit, rootGetters }, { fileId, changes })
	{
		if(fileId)
		{
			const { data } = await post(`/files/${fileId}/data`, { changes });

			if(!data.success)
			{
				addError(rootGetters['i18n/get']('errors.files.setData.apiNoSave'));
			}
			else
			{
				commit('UPDATE_FILE_DATA', { fileId, changes });
			}

			return data.success;
		}

		// This was saying `No file ID given, cannot save data, or image removed successfully`
		// - in my case, image removed successfully is _not_ an error, and otherwise, this shouldn't
		// be a user facing error, if any error at all.
		// addError(rootGetters['i18n/get']('errors.files.setData.noFileId'));

		console.warn('No file ID given, cannot save data, or image removed successfully');

		return false;
	},
	async uploadImageForDataId({ getters, dispatch }, { image, scope })
	{
		const formData = new FormData();

		formData.append(image.name, image);
		formData.append('scope', scope);
		formData.append('accept', '*/*');
		formData.append('enctype', 'multipart/form-data');
		formData.append('contextId', 'post-image');

		const { data: { fileIds } } = await post('/files/uploadImage', formData);

		const [imageId] = fileIds;

		dispatch('loadFiles', { fileIds, scope, force: true });

		return imageId;
	},
	async uploadAsset({ dispatch }, assetData)
	{
		const { data: { id: fileId } } = await post('/files/assetUpload', { ...assetData, isAsset: true });

		await dispatch('loadFiles', { fileIds: [fileId] });

		return fileId;
	},
	async loadAssets({ commit }, fileIds)
	{
		let data;

		if(fileIds && fileIds.length)
		{
			data = (await post('/files/getAssets', { fileIds })).data;
		}
		else
		{
			data = (await post('/files/getAssets')).data;
		}

		commit('ADD_FILES', data.assets);
	},
	async updateAsset({ dispatch }, assetData)
	{
		let belowMinimumToSave = true;

		Object.entries(assetData).forEach(([key, value]) =>
		{
			if(!['type', 'fileId'].includes(key) && value)
			{
				belowMinimumToSave = false;
			}
		});

		if(!belowMinimumToSave)
		{
			const { data: { fileId } } = await post('/files/updateAsset', assetData);

			if(fileId)
			{
				await dispatch('loadFiles', { fileIds: [fileId], force: true });

				return true;
			}
		}

		return false;
	}
};

const getters = {
	allFiles: (state) => state.files,
	byId: (state, getters) => (id) => getters.allFiles.find((file) => parseInt(file.id, 10) === parseInt(id, 10)),
	allAssets: (state, getters) => getters.allFiles.filter((file) => file.isAsset),
	assetById: (state, getters) => (id) => getters.allAssets.find((file) => parseInt(file.id, 10) === parseInt(id, 10))
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
