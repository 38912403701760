<template>
	<div v-if="loading" class="globalSpinner">
		<Spinner
			:size="size"
			color="white"
		/>
	</div>
</template>

<script>
	export default {
		computed: {
			loading()
			{
				return this.$store.getters['app/loading'];
			},
			size()
			{
				return '20px';
			}
		}
	};
</script>

<style scoped>
	.globalSpinner {
		position: absolute;
		top: 10px;
		right: 10px;
	}
</style>
