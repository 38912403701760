import { get } from '@/utils/api';

const state = {
	hasLoadedAll: false,
	isLoading: false,
	definitions: []
};

const mutations = {
	SET_HAS_LOADED_ALL(state, hasLoadedAll)
	{
		state.hasLoadedAll = !!hasLoadedAll;
	},
	SET_IS_LOADING(state, isLoading)
	{
		state.isLoading = !!isLoading;
	},
	SET_DEFINITIONS(state, definitions)
	{
		state.definitions = definitions || [];
	}
};

const actions = {
	async loadAll({ commit, getters })
	{
		if(getters.hasLoadedAll || getters.isLoading) return;

		commit('SET_IS_LOADING', true);

		try
		{
			const { data } = await get('eventDefinitions/getAllDefinitions');

			commit('SET_DEFINITIONS', data);
			commit('SET_HAS_LOADED_ALL', true);
		}
		catch(e)
		{
			console.error(e);
		}

		commit('SET_IS_LOADING', false);
	}
};

const getters = {
	all: (state) => state.definitions,
	byId: (state) => (id) => (id ? state.definitions.find(({ hook }) => id === hook) : null),
	isLoading: (state) => state.isLoading,
	definitionContextFields: (state, getters) => (id) => getters.byId(id)?.definition?.handlerData?.contextFields || []
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
