
import { formatFileSize } from '@/utils/tools';

export default {
	fieldsBagName: 'fieldsBag',
	dictionary: {
		en: {
			messages: {
				// We define the I18N inside vue-i18n and pass the keys into here, then use vue-i18n to translate the `key`.
				// It sounds silly, since we can pass vue-i18n straight into vee-validate,
				// but we do it this way, so Admin can translate it using the inline-editing tool. Not doing this method means it just static text.
				// We can pass any extra `$vars` as well and some magic will automatically handle this, and pass it into the i18n string with its key name
				_default: (field) => ({ errorType: '_default', field }),
				after: (field, [target, inclusion]) =>
				{
					if(inclusion)
					{
						return ({ errorType: 'afterOrEqual', field, vars: { target } });
					}

					return ({ errorType: 'after', field, vars: { target } });
				},
				alpha: (field) => ({ errorType: 'alpha', field }),
				alpha_dash: (field) => ({ errorType: 'alpha_dash', field }),
				alpha_num: (field) => ({ errorType: 'alpha_num', field }),
				alpha_spaces: (field) => ({ errorType: 'alpha_spaces', field }),
				before: (field, [target, inclusion]) =>
				{
					if(inclusion)
					{
						return ({ errorType: 'beforeOrEqual', field, vars: { target } });
					}

					return ({ errorType: 'before', field, vars: { target } });
				},
				between: (field, [min, max]) => ({ errorType: 'between', field, vars: { min, max } }),
				confirmed: (field) => ({ errorType: 'confirmed', field }),
				credit_card: (field) => ({ errorType: 'credit_card', field }),
				date_between: (field, [min, max]) => ({ errorType: 'date_between', field, vars: { min, max } }),
				date_format: (field, [format]) => ({ errorType: 'date_format', field, vars: { format } }),
				decimal: (field, [decimals = '*'] = []) => ({ errorType: 'decimal', field, vars: { decimals } }),
				digits: (field, [length]) => ({ errorType: 'digits', field, vars: { length } }),
				dimensions: (field, [width, height]) => ({ errorType: 'dimensions', field, vars: { width, height } }),
				email: (field) => ({ errorType: 'email', field }),
				ext: (field) => ({ errorType: 'ext', field }),
				excluded: (field) => ({ errorType: 'excluded', field }),
				image: (field) => ({ errorType: 'image', field }),
				in: (field) => ({ errorType: 'in', field }),
				// integer: (field) => ({ errorType: 'integer', field }),
				ip: (field) => ({ errorType: 'ip', field }),
				//
				max: (field, [length]) => ({ errorType: 'max', field, vars: { length } }),
				max_value: (field, [max]) => ({ errorType: 'max_value', field, vars: { max } }),
				mimes: (field) => ({ errorType: 'mimes', field }),
				min: (field, [length]) => ({ errorType: 'min', field, vars: { length } }),
				min_value: (field, [min]) => ({ errorType: 'min_value', field, vars: { min } }),
				not_in: (field) => ({ errorType: 'not_in', field }),
				numeric: (field) => ({ errorType: 'numeric', field }),
				regex: (field) => ({ errorType: 'regex', field }),
				required: (field) => ({ errorType: 'required', field }),
				size: (field, [size]) => ({ errorType: 'size', field, vars: { size: formatFileSize(size) } }),
				url: (field) => ({ errorType: 'url', field }),
				applicationTargetMatchesList: (field, nil, listName) =>
				{
					return { errorType: 'applicationTargetMatchesList', field, vars: { listName } };
				},
				filterDuplicatesByKey: (field) => ({ errorType: 'filterDuplicatesByKey', field }),
				maxHtml: (field, [length]) =>
				{
					return { errorType: 'maxHtml', field, vars: { length } };
				},
				isLink: (field) =>
				{
					return { errorType: 'isLink', field };
				}
			}
		}
	}
};
