<template>
	<div class="field">
		<InputLabel :field="field" />
		<Uploader
			:showUploader="showUploaderComp"
			:hideUploadedFiles="hideUploadedFiles"
			:scope="targetIdToUse"
			:contextId="field.id"
			:maxFileSize="maxFileSize"
			:acceptedFileTypes="acceptedFileTypes"
			:allowMultipleFiles="allowMultipleFiles"
			:canDelete="editable"
			:limitToType="limitToType"
			:repeatersKey="repeatersKey"
			:fieldKey="field.key"
			:emitResponse="true"
			@upload="postUploadActions"
		/>
		<SubmitButtons
			:blockId="blockId"
			:field="field"
			@submit="submitButtonClicked"
			@toggleFieldEdit="toggleFieldEdit"
		/>
	</div>
</template>

<script>
	import cloneDeep from 'lodash/cloneDeep';
	import ElementMixin from '@/components/form/elements/ElementMixin';
	import debounce from 'lodash/debounce';

	export default {
		components: {
			Uploader: () => import('@/components/files/Uploader')
		},
		inject: {
			contextTargetId: {
				default: null,
				from: 'targetId'
			},
			$formId: {
				default: undefined
			},
			$formOrigin: {
				default: null
			}
		},
		mixins: [ElementMixin],
		props: {
			limitToType: {
				type: String,
				required: false,
				default: null
			},
			emitResponse: {
				type: Boolean,
				default: false
			},
			showUploader: {
				type: Boolean,
				default: undefined
			},
			maxFileSize: {
				type: Number,
				default: undefined
			}
		},
		data()
		{
			return {
				uploadErrors: []
			};
		},
		computed: {
			qProps()
			{
				return {
					...this.quasarProps,
					...this.defaultQuasarProps,
					...this.field.qProps
				};
			},
			bearer()
			{
				const jwt = localStorage.getItem('jwt');

				return `Bearer ${jwt}`;
			},
			formDataCopy()
			{
				return this.$store.getters[
					'profileFormCopy/getItemData'
				](
					`${this.$formId.split('#')[0]}-${this.targetIdToUse}`,
					this.fieldKey,
					this.repeatersKey
				);
			},
			filesInRepeaterItem()
			{
				if(
					typeof this.repeatersKey === 'number' &&
					this.fieldKey?.indexOf('.') > 0 &&
					this.targetIdToUse
				)
				{
					// deliberately return [] to prevent all images in the
					// scope from appearing in the list
					return this.formDataCopy || [];
				}

				return undefined;
			},
			// If the uploader is set to just 1 file, and we have 1, then don't show the uploader unless we have a prop
			showUploaderComp()
			{
				return this.showUploader ?? !(!this.allowMultipleFiles && this.files?.length >= 1);
			},
			acceptedFileTypes()
			{
				switch(this.limitToType)
				{
					case 'image':
						return 'image/*';
					default:
						// eslint-disable-next-line camelcase
						return this.field?.validation?.file_types;
				}
			},
			allowMultipleFiles()
			{
				return this.field?.multipleFiles || false;
			},
			targetIdToUse()
			{
				if(!this.contextTargetId || this.$formOrigin === 'ApplicationForm')
				{
					return this.$store.getters['user/accountId'];
				}

				return this.contextTargetId;
			},
			files()
			{
				const fileList = cloneDeep(
					this.$store.getters['fileLists/byUrl'](
						`files/getFileList/${this.field.id}`,
						this.targetIdToUse
					)?.data
				);

				if(this.filesInRepeaterItem)
				{
					return fileList.filter((file) => (
						this.filesInRepeaterItem.includes(file.id)
					));
				}

				if(this.scopeToUser)
				{
					return fileList.filter((file) => (
						file.accountId === this.$store.getters['user/accountId']
					));
				}

				return fileList;
			},
			hideUploadedFiles()
			{
				return this.field?.hideUploadedFiles || false;
			}
		},
		watch: {
			files()
			{
				this.setFileIds();
			}
		},
		methods: {
			setFileIds: debounce(function setFileIds()
			{
				// Get just the ids of the files
				// The element mixin will check if this is different to our current value
				this.inputVal = this.files?.map((data) => data.id);
			}, 500),
			postUploadActions(res)
			{
				if(this.emitResponse)
				{
					this.$emit('upload', res);
				}
				else
				{
					const fileIds = res?.xhr?.response ?
						JSON.parse(res.xhr.response).fileIds :
						undefined;

					if(fileIds)
					{
						if(
							this.allowMultipleFiles &&
							Array.isArray(this.inputVal)
						)
						{
							this.inputVal = this.inputVal.concat(fileIds);
						}
						else
						{
							this.inputVal = fileIds;
						}
					}
				}
			}
		}
	};
</script>
