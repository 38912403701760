<template>
	<div>
		<Block
			v-for="(blockId, index) in defaultPosition"
			:key="index"
			v-bind="$props"
			:data="blockId"
			colIndex="0"
			:index="index"
		/>
		<AddBlockButton
			:parent="data.id"
			:index="defaultPosition.length"
		/>
	</div>
</template>

<script>
	import BlockMixin from '@/components/blocks/BlockMixin';
	import AddBlockButton from '@/components/admin/generic/AddBlockButton';
	// import Block from '@/components/blocks/Block';

	export default {
		components: { AddBlockButton, Block: () => import('@/components/blocks/Block') },
		mixins: [BlockMixin]
	};
</script>
