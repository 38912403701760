import Vue from 'vue';
import admin from '@/store/userLists/admin';
import { get, post, destroy } from '@/utils/api';
import { addError } from '@/utils/notifications';
import { powerUps } from '@/configs/constants';
import { setOneItem } from '@/utils/store';

const state = {
	system: [],
	custom: [],
	loading: false,
	listMembershipStatesMaps: {},
	loadingListMembershipStatesMap: {}
};

const mutations = {
	SET_SYSTEM(state, system)
	{
		state.system = system;
	},
	SET_CUSTOM(state, custom)
	{
		state.custom = custom;
	},
	ADD_TO_CUSTOM(state, list)
	{
		setOneItem(state, 'custom', list);
	},
	ADD_USERS_TO_USER_LIST(state, { listId, users })
	{
		const listIndex = state.custom.findIndex((l) => l.id === listId);

		// state.custom[listIndex] = { ...state.custom[listIndex], users: [...users] };
		const currentUsers = state.custom[listIndex]?.users || [];
		const allUsers = new Set(currentUsers.concat(users));

		Vue.set(state.custom, listIndex, { ...state.custom[listIndex], users: [...allUsers] });// concatenate old and additional arrays and then make the resulting array unique through Set
	},
	REMOVE_USER_FROM_LIST(state, { listId, accountId })
	{
		const list = state.custom.find((userList) => userList.id === listId);

		if(list)
		{
			list.users = list.users.filter((id) => parseInt(id, 10) !== parseInt(accountId, 10));
		}
	},
	SET_LIST_MEMBERSHIP_STATES_MAP(state, { accountId, data })
	{
		Vue.set(state.listMembershipStatesMaps, accountId, data);
	},
	SET_LOADING_LIST_MEMBERSHIP_STATES_MAP(state, { accountId, loading })
	{
		Vue.set(state.loadingListMembershipStatesMap, accountId, loading);
	}
};

const actions = {
	async loadListMembershipStatesMapForUser({ dispatch, getters }, {
		accountId,
		includeDynamicLists,
		force
	})
	{
		// don't try to fetch a value which we know won't have an entry
		if(!accountId) return null;

		if(!includeDynamicLists) includeDynamicLists = false;

		if(
			force ||
			(
				!getters.getLoadingListMembershipStatesMap(accountId) &&
				!getters.getListMembershipStatesMap(accountId) &&
				// if `false`, it failed to retrieve; don't overload the api with more calls
				getters.getListMembershipStatesMap(accountId) !== false
			)
		)
		{
			dispatch('loadingListMembershipStatesMap', { accountId, loading: true });

			try
			{
				const { data } = await get(
					`profile/listMembershipStates/${accountId}/${includeDynamicLists}`
				);

				dispatch('insertNewListMembershipStatesMap', { accountId, data });
			}
			catch(e)
			{
				if(e && e.response && e.response.data && !e.response.data.permission)
				{
					dispatch('insertNewListMembershipStatesMap', { accountId });
				}

				dispatch('insertNewListMembershipStatesMap', { accountId, data: false });
			}

			dispatch('loadingListMembershipStatesMap', { accountId, loading: false });
		}

		return getters.getListMembershipStatesMap(accountId);
	},
	insertNewListMembershipStatesMap({ commit }, { accountId, data })
	{
		commit('SET_LIST_MEMBERSHIP_STATES_MAP', { accountId, data });
	},
	loadingListMembershipStatesMap({ commit }, { accountId, loading })
	{
		commit('SET_LOADING_LIST_MEMBERSHIP_STATES_MAP', { accountId, loading });
	},
	async loadOne({ commit, getters }, listId)
	{
		if(Object.keys(getters.getUserList(listId))?.length)
		{
			return;
		}

		const { data } = await get(`/userLists/${listId}`);

		commit('ADD_TO_CUSTOM', data.userList);
	},
	async loadSome({ commit, getters }, listIds = [])
	{
		if(!listIds.length) return;

		try
		{
			const { data } = await post('/userLists/getSome', { userListIds: listIds });

			data.forEach((list) =>
			{
				commit('ADD_TO_CUSTOM', list);
			});
		}
		catch(e)
		{
			console.error(e);
		}
	},
	async loadLeaderListsForUser({ commit, state }, { accountId, states = [] })
	{
		if(!state.loading)
		{
			let data;

			if(states && states.length)
			{
				const statesString = states.join();

				data = (await get(`userLists/getLeaderLists/${accountId}/${statesString}`)).data;
			}
			else
			{
				data = (await get(`userLists/getLeaderLists/${accountId}`)).data;
			}

			// the JSON returned from the API does not respect `Infinity`; it is converted to `null`.
			// here we set it back to Infinity so that it can be interpreted correctly by components.
			data.custom = data.custom.map((list) =>
			{
				if(list?.capacity === null)
				{
					list.capacity = Infinity;
				}

				return list;
			});

			commit('SET_SYSTEM', data.custom);
		}
	},
	async loadUserLists({ commit, state, rootGetters }, { force = false } = {})
	{
		if(!force && state.custom && state.custom.length) return;

		const { data } = await get(`structure/powerUp/${powerUps.join}/getAllLists`);

		commit('SET_CUSTOM', data.custom);
	},
	async loadMembers({ commit, dispatch }, listId)
	{
		try
		{
			const { data: accountIds } = await get(`structure/powerUp/${powerUps.join}/${listId}/users`);

			await dispatch('profiles/loadProfiles', accountIds, { root: true });
			commit('ADD_USERS_TO_USER_LIST', { users: accountIds, listId });

			return accountIds;
		}
		catch(e)
		{
			console.warn(e);
			addError('Could not load members: We could not load members for this list. Please try again');

			return [];
		}
	},
	async setCapacity({ commit, state }, { entityId, connectionId, capacity })
	{
		try
		{
			await post('userLists/setCapacity', {
				entityId,
				connectionId,
				capacity
			});
		}
		catch(e)
		{
			console.warn('something went wrong', e);
		}
	},
	async addUsers(ctx, { listId, accountIds })
	{
		try
		{
			await post(`structure/powerUp/${powerUps.join}/${listId}/users`, {
				accounts: accountIds
			});

			ctx.commit('ADD_USERS_TO_USER_LIST', { users: accountIds, listId });
		}
		catch(e)
		{
			console.warn('something went wrong', e);
		}
	},
	async removeUser(ctx, { listId, accountId })
	{
		try
		{
			await destroy(`structure/powerUp/${powerUps.join}/${listId}/${accountId}`);
			ctx.commit('REMOVE_USER_FROM_LIST', { listId, accountId });
		}
		catch(e)
		{
			console.warn('something went wrong', e);
		}
	},
	async membershipToggleHidden({ dispatch, rootGetters }, userListId)
	{
		if(!userListId) return null;

		const { data } = await post(`/applications/membership/${userListId}/toggleHidden`);

		dispatch('user/upsertListMembership', data, { root: true });

		// TODO should pausing a membership hide the user from the related accepted list?
		/* const accountId = rootGetters['user/accountId'];

		if(data.hidden === 1 || data.state !== 'accepted')
		{
			dispatch('profiles/removeFromAcceptedList', { accountId, listId: data.userListId }, { root: true });
		}
		else if(data.state === 'accepted')
		{
			dispatch('profiles/addToAcceptedList', { accountId, listId: data.userListId }, { root: true });
		} */

		return data;
	}
};

const getters = {
	system: (state) => state.system.map((userList) => ({ ...userList })),
	custom: (state) => state.custom.map((userList) => ({ ...userList })),
	getUserList: (state, getters) => (userListId) => ({ ...getters.custom.find((userList) => userList.id === userListId) }),
	isUserInList: (state, getters) => (userListId, accountId) =>
	{
		const userList = getters.getUserList(userListId);

		return userList.users && userList.users.includes(accountId);
	},
	isFull: (state, getters) => (userListId) =>
	{
		const list = getters.system.find((userList) => userList.id === userListId);

		return list && list.isFull;
	},
	getLeaderList: (state, getters, rootState, rootGetters) => (connectionId, entityId) =>
	{
		entityId = rootGetters['entities/convertSlugToId'](entityId) || entityId;

		return {
			...getters.system.find((userList) => (
				userList.connectionId === connectionId &&
				userList.entityId === entityId
			))
		};
	},
	getListMembershipStatesMap: (state) => (accountId) => (state.listMembershipStatesMaps[accountId]),
	getLoadingListMembershipStatesMap: (state) => (accountId) => (state.loadingListMembershipStatesMap[accountId])
};

const modules = {
	admin
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
