<template>
	<div />
</template>

<script>
	import { addInfo } from '@/utils/notifications';
	// import socket from '@/utils/socket';

	export default {
		computed: {
			module()
			{
				const { moduleSlug } = this.$store.state.route.params;

				return this.$store.getters['structure/modules/getModuleBySlug'](moduleSlug);
			}
		},
		created()
		{
			if(!this.module)
			{
				addInfo('That module does not exist, sorry for any inconvenience caused.');

				this.$router.replace('/404');

				return;
			}

			this.$store.dispatch('app/setActiveModuleId', this.module.id);

			const defaultPage = this.$store.getters['structure/pages/getPageById'](this.module.defaultPage);

			this.$router.replace(`/${this.module.slug}/${defaultPage?.slug || '404'}`);
		},
		async mounted()
		{
			if(this.module)
			{
				const socket = await import('@/utils/socket').then((mod) => mod.default);

				socket.emit('module:subscribe', { id: this.module.id });

				socket.on(`module:${this.module.id}:data`, (data) => this.$store.dispatch('structure/modules/setModule', data));
			}
		},
		async destroyed()
		{
			if(this.module)
			{
				const socket = await import('@/utils/socket').then((mod) => mod.default);

				socket.emit('module:unsubscribe', { id: this.module.id });

				socket.removeListener(`module:${this.module.id}:data`);
			}
		}
	};
</script>

<style scoped>
</style>
