import Vue from 'vue';
import admin from '@/store/structure/pages/admin';
// import { normalizePageAndBlocks } from '@/store/structure/utilities';
import { get, getSWR } from '@/utils/api';
import { setItems } from '@/utils/store';

const modules = {
	admin
};

const state = {
	pages: {}
};

const mutations = {
	SET_PAGES(state, pages)
	{
		setItems(state, 'pages', state.pages, pages);
	},
	SET_PAGE(state, page)
	{
		Vue.set(state.pages, page.id, page);
	},
	SET_PAGE_LOADED(state, pageId)
	{
		Vue.set(state.pages[pageId], 'loaded', true);
	},
	RESET(state)
	{
		Vue.set(state, 'pages', {});
	}
};

const actions = {
	setPages({ commit, dispatch }, pages)
	{
		if(!pages) return;

		// if(Array.isArray(pages))
		// {
		// 	pages.forEach((page) => dispatch('setPage', page));
		// }
		// else
		// {
		commit('SET_PAGES', pages);
		// }
	},
	setPage({ commit }, page)
	{
		if(!page) return;

		commit('SET_PAGE', page);
	},
	async loadPageForce({ dispatch }, pageId)
	{
		const { data } = await get(`structure/page/${pageId}`);

		await dispatch('app/handleServerResponse', data, { root: true });

		return data;
	},
	async loadPage({ dispatch, getters }, pageId)
	{
		if(getters.getPageById(pageId))
		{
			return getters.getPageById(pageId);
		}

		return dispatch('loadPageForce', pageId);
	},
	async loadPageContent({ commit, getters, dispatch, rootGetters }, page)
	{
		const pageBlocksAreLoaded = (page?.children || []).every((blockId) =>
		{
			return !!rootGetters['structure/blocks/getBlock'](blockId);
		});

		if(!getters.loaded(page) || !pageBlocksAreLoaded)
		{
			await getSWR(`structure/page/${page.id}`, undefined, ({ blocks, fields, dataSchemas }) =>
			{
				dispatch('structure/blocks/setBlocks', blocks, { root: true });
				dispatch('structure/fields/setFields', fields, { root: true });
				dispatch('dataSchemas/setDataSchemas', dataSchemas, { root: true });
			});

			commit('SET_PAGE_LOADED', page.id);

			return dispatch('structure/routerUpdate', rootGetters['structure/getCurrentRoute'], { root: true });
		}

		return true;
	},
	async forcePageLoad({ commit, getters, dispatch, rootGetters }, page)
	{
		const { data: { blocks, fields, dataSchemas } } = await get(`structure/page/${page.id}`);

		// returns all our blocks currently held in the store
		const loadedBlocks = rootGetters['structure/blocks/getBlocks'];
		// returns all our visible block id's any hidden blocks are not included
		const visibleBlocks = blocks.map((block) => block.id);
		// find any blocks in the store that are not in our visible blocks i.e. needs removing
		const removeBlocks = Object.keys(loadedBlocks).filter((key) => !visibleBlocks.includes(key));

		if(removeBlocks.length)
		{
			await dispatch('structure/blocks/removeBlocks', removeBlocks, { root: true });
		}

		dispatch('structure/blocks/setBlocks', blocks, { root: true });
		dispatch('structure/fields/setFields', fields, { root: true });
		dispatch('dataSchemas/setDataSchemas', dataSchemas, { root: true });
		commit('SET_PAGE_LOADED', page.id);

		return dispatch('structure/routerUpdate', rootGetters['structure/getCurrentRoute'], { root: true });
	},
	reset({ commit })
	{
		commit('RESET');
	}
};

const getters = {
	getPages: (state) => state.pages,
	getPageId: (state, getters, rootState, rootGetters) => (moduleSlug, pageSlug) =>
	{
		if(moduleSlug === '404' || pageSlug === '404')
		{
			return null;
		}

		const module = rootGetters['structure/modules/getModuleBySlug'](moduleSlug);

		if(!module || !module.childIds)
		{
			return null;
		}

		return module.childIds.find((pageId) => state.pages[pageId] && state.pages[pageId].slug === pageSlug);
	},
	getPageBySlug: (state, getters) => (moduleSlug, pageSlug) =>
	{
		return state.pages[getters.getPageId(moduleSlug, pageSlug)];
	},
	getPageById: (state) => (pageId) =>
	{
		return state.pages[pageId];
	},
	getActivePage: (state, getters, rootState, rootGetters) =>
	{
		return state.pages[rootGetters['app/getActivePageId']];
	},
	loaded: () => (page) =>
	{
		return Boolean((
			page &&
			page.loaded
		));
	},
	getModuleParentOfPage: (state, getters, rootState, rootGetters) => (pageId) =>
	{
		return Object.values(rootGetters['structure/modules/getModules']).find((module) => (
			module.childIds.includes(pageId)
		));
	},
	getModulePages: (state, getters, rootState, rootGetters) => (moduleId) =>
	{
		const module = rootGetters['structure/modules/getModuleById'](moduleId);

		if(!module) return [];

		if(!module.childIds)
		{
			return [];
		}

		return module.childIds.map((pageId) => state.pages[pageId]);
	},
	getActiveModulePages: (state, getters, rootState, rootGetters) =>
	{
		const module = rootGetters['structure/modules/getActiveModule'];

		if(!module)
		{
			return false;
		}

		const childIds = module.childIds || [];

		return childIds.map((pageId) => state.pages[pageId]);
	},
	firstPageOfType: (state, getters) => (moduleId, type) =>
	{
		const pages = getters.getModulePages(moduleId);

		return pages.find((page) => page.type === type);
	},
	getSlugForId: (state, getters) => (id) =>
	{
		const page = getters.getPageById(id);

		if(!page || !page.slug)
		{
			return null;
		}

		return page.slug;
	}
	// getModuleForPage: (state, getters, rootState, rootGetters) => (pageId) =>
	// {
	// 	const modules = rootGetters['structure/modules/getModules'];
	// 	Object.entries(modules).forEach(([id, item]) =>
	// 	{
	// 		const pageIndex = item
	// 	});
	// }
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
