// @ts-check
/**
 * @typedef {(
 * 	import('../types/store/entities/connections/matches').EntityConnectionMatches.MatchDetails
 * )} MatchDetails
 */

/**
 * Separator character for match IDs.
 */
export const matchIdSeparator = ':';

/**
 * Just for convenience.
 * Easier to update 1 function, rather than a c/p.
 *
 * @param {MatchDetails} matchData
 * @returns {string} - `${entityId}:${connectionTypeId}:${followerAccountId}:${leaderAccountId}`.
 */
export function getMatchId({ entityId, connectionTypeId, followerAccountId, leaderAccountId })
{
	return [
		entityId,
		connectionTypeId,
		followerAccountId,
		leaderAccountId
	].join(matchIdSeparator);
}

/**
 * @param {String} matchId
 * @returns {MatchDetails}
 */
export function getMatchDetailsFromMatchId(matchId)
{
	const [
		entityId,
		connectionTypeId,
		followerAccountId,
		leaderAccountId
	] = matchId.split(matchIdSeparator);

	return {
		matchId,
		entityId,
		connectionTypeId,
		followerAccountId: parseInt(followerAccountId, 10) || undefined,
		leaderAccountId: parseInt(leaderAccountId, 10) || undefined
	};
}

/**
 * @param {Array<String>} matchIds
 * @returns {Array<MatchDetails>}
 */
export function getMatchDetailsFromMatchIds(matchIds)
{
	return matchIds.map((matchId) =>
	{
		return getMatchDetailsFromMatchId(matchId);
	});
}

/**
 *
 * @param {Array<String>} matchIds
 * @returns {Array<Number>}
 */
export function getLeaderAccountIdsFromMatchIds(matchIds)
{
	const matchDetails = getMatchDetailsFromMatchIds(matchIds);

	return matchDetails.map(({ leaderAccountId }) => leaderAccountId);
}
