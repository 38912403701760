import Vue from 'vue';
import { get, put, post, destroy } from '@/utils/api';

const state = {
	ruleSets: {},
	isLoadingAll: false,
	areAllLoaded: false
};

const mutations = {
	SET_RULE_SETS(state, ruleSets)
	{
		ruleSets.forEach((ruleSet) =>
		{
			if(!ruleSet.id) return;

			if(ruleSet.id in state.ruleSets)
			{
				Vue.set(state.ruleSets[ruleSet.id], 'data', ruleSet);
			}
			else
			{
				Vue.set(state.ruleSets, ruleSet.id, { data: ruleSet });
			}
		});
	},
	REMOVE_RULE_SETS(state, ruleSetIds)
	{
		ruleSetIds.forEach((id) =>
		{
			if(!id) return;

			if(id in state.ruleSets)
			{
				Vue.delete(state.ruleSets, id);
			}
		});
	},
	SET_IS_LOADING_ALL(state, isLoadingAll)
	{
		Vue.set(state, 'isLoadingAll', isLoadingAll);
	},
	SET_ARE_ALL_LOADED(state, areAllLoaded)
	{
		Vue.set(state, 'areAllLoaded', areAllLoaded);
	}
};

const actions = {
	// TODO: might need pagination, depending on how many rule-sets are expected
	async loadAll({ commit, getters })
	{
		if(getters.isLoadingAll || getters.areAllLoaded) return;

		commit('SET_IS_LOADING_ALL', true);

		try
		{
			const { data } = await get('ruleSet');

			commit('SET_RULE_SETS', data);
			commit('SET_ARE_ALL_LOADED', true);
		}
		catch(e)
		{
			console.error('Failed to load rule-sets', e);
		}

		commit('SET_IS_LOADING_ALL', false);
	},
	async create({ commit }, ruleSet)
	{
		try
		{
			const { data } = await put('ruleSet', ruleSet);

			commit('SET_RULE_SETS', [data]);
		}
		catch(e)
		{
			console.error('Failed to create rule-set', e.response?.data?.message || e);
		}
	},
	async update({ commit }, ruleSet)
	{
		try
		{
			await post(`ruleSet/${ruleSet.id}`, ruleSet);

			commit('SET_RULE_SETS', [ruleSet]);
		}
		catch(e)
		{
			console.error('Failed to update rule-set', e.response?.data?.message || e);
		}
	},
	async delete({ commit }, ruleSetId)
	{
		try
		{
			await destroy(`ruleSet/${ruleSetId}`);

			commit('REMOVE_RULE_SETS', [ruleSetId]);
		}
		catch(e)
		{
			console.error('Failed to delete rule-set', e.response?.data?.message || e);
		}
	}
};

const getters = {
	isLoadingAll: (state) => state.isLoadingAll,
	areAllLoaded: (state) => state.areAllLoaded,
	getAll: (state) => (state.ruleSets ? Object.values(state.ruleSets).map(({ data }) => data) : []),
	getById: (state) => (ids) =>
	{
		if(!ids) return [];

		return ids.reduce((ruleSets, id) =>
		{
			if(id in state.ruleSets)
			{
				ruleSets.push(state.ruleSets[id].data);
			}

			return ruleSets;
		}, []);
	},
	getOneById: (state, getters) => (id) =>
	{
		const ruleSets = getters.getById([id]);

		return ruleSets.length ?
			ruleSets[0] :
			undefined;
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
