import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import app from '@/store/app';
import user from '@/store/user';
import structure from '@/store/structure';
import statistics from '@/store/statistics';
import insights2 from '@/store/insights2';
import profiles from '@/store/profiles';
import userLists from '@/store/userLists';
import i18n from '@/store/i18n';
import admin from '@/store/admin';
import audit from '@/store/audit';
import files from '@/store/files';
import fileLists from '@/store/fileLists';
import dataSchemas from '@/store/dataSchemas';
import powerUps from '@/store/powerUps';
import applications from '@/store/applications';
import entities from '@/store/entities';
import entityDefinitions from '@/store/entityDefinitions';
import chats from '@/store/chats';
import communications from '@/store/userCommunications';
import emailTemplates from '@/store/emailTemplates';
import external from '@/store/external';
import dataMappings from '@/store/dataMappings';
import handlebars from '@/store/handlebars';
import profileFormCopy from '@/store/profileFormCopy';
import items from '@/store/items';
import reportAbuse from '@/store/reportAbuse';
import ruleSet from '@/store/ruleSet';
import eventDefinitions from '@/store/eventDefinitions';
import appProcess from '@/store/process';

const modules = {
	app,
	user,
	structure,
	statistics,
	insights2,
	profiles,
	userLists,
	i18n,
	admin,
	audit,
	files,
	fileLists,
	dataSchemas,
	powerUps,
	applications,
	entities,
	entityDefinitions,
	chats,
	communications,
	emailTemplates,
	external,
	dataMappings,
	handlebars,
	profileFormCopy,
	items,
	reportAbuse,
	ruleSet,
	eventDefinitions,
	appProcess
};

Vue.use(Vuex);

const state = {};
const mutations = {};
const actions = {};

const store = new Store({
	strict: process.env.NODE_ENV !== 'production',
	state,
	mutations,
	actions,
	modules
});

// (async () =>
// {
// 	Promise.all([
// 	import('@/store/app').then((mod) => mod.default && store.registerModule('app', mod.default)),
// 	import('@/store/user').then((mod) => mod.default && store.registerModule('user', mod.default)),
// 	import('@/store/structure').then((mod) => mod.default && store.registerModule('structure', mod.default)),
// 	import('@/store/statistics').then((mod) => mod.default && store.registerModule('statistics', mod.default)),
// 	import('@/store/profiles').then((mod) => mod.default && store.registerModule('profiles', mod.default)),
// 	import('@/store/profileFormCopy').then((mod) => mod.default && store.registerModule('profileFormCopy', mod.default)),
// 	import('@/store/userLists').then((mod) => mod.default && store.registerModule('userLists', mod.default)),
// 	import('@/store/i18n').then((mod) => mod.default && store.registerModule('i18n', mod.default)),
// 	import('@/store/items').then((mod) => mod.default && store.registerModule('items', mod.default)),
// 	import('@/store/admin').then((mod) => mod.default && store.registerModule('admin', mod.default)),
// 	import('@/store/audit').then((mod) => mod.default && store.registerModule('audit', mod.default)),
// 	import('@/store/files').then((mod) => mod.default && store.registerModule('files', mod.default)),
// 	import('@/store/fileLists').then((mod) => mod.default && store.registerModule('fileLists', mod.default)),
// 	import('@/store/dataSchemas').then((mod) => mod.default && store.registerModule('dataSchemas', mod.default)),
// 	import('@/store/powerUps').then((mod) => mod.default && store.registerModule('powerUps', mod.default)),
// 	import('@/store/applications').then((mod) => mod.default && store.registerModule('applications', mod.default)),
// 	import('@/store/entities').then((mod) => mod.default && store.registerModule('entities', mod.default)),
// 	import('@/store/entityDefinitions').then((mod) => mod.default && store.registerModule('entityDefinitions', mod.default)),
// 	import('@/store/chats').then((mod) => mod.default && store.registerModule('chats', mod.default)),
// 	import('@/store/userCommunications').then((mod) => mod.default && store.registerModule('communications', mod.default)),
// 	import('@/store/emailTemplates').then((mod) => mod.default && store.registerModule('emailTemplates', mod.default)),
// 	import('@/store/external').then((mod) => mod.default && store.registerModule('external', mod.default)),
// 	import('@/store/dataMappings').then((mod) => mod.default && store.registerModule('dataMappings', mod.default)),
// 	import('@/store/reportAbuse').then((mod) => mod.default && store.registerModule('reportAbuse', mod.default)),
// 	import('@/store/handlebars').then((mod) => mod.default && store.registerModule('handlebars', mod.default))
// 	]);
// })();

export default store;
