import { generateImagePath } from '@/plugins/imagePath';
import { get } from '@/utils/api';
import { setThemeContent } from '@/utils/initialLoad';
import { getFocalPointString } from '@/utils/focalPointTools';

export const processors = {
	backgroundImage: (fileId, opts = []) => `url(${generateImagePath(parseInt(fileId, 10), false, undefined, opts.includes('breakCache'))});${getFocalPointString(parseInt(fileId, 10), false, false)}`
};

export function splitIntermediateValue(data)
{
	const [, intermediate] = data.split(/##/m);
	let key,
		value;

	if(intermediate)
	{
		[key, value] = intermediate.split('|');
	}

	return { key, value };
}

// opts should be an object, with option names as keys, and arrays of intermediate values as values
export function processStyles(data, opts = {})
{
	const dataArray = data.split(/##/m);

	return dataArray.reduce((agg, string) =>
	{
		if(string.split('|').length !== 2)
		{
			agg += string;
		}
		else
		{
			const [type, value] = string.split('|');
			const options = Object.entries(opts).reduce((agg, [option, applyToValues]) =>
			{
				if(applyToValues.includes(value))
				{
					agg.push(option);
				}

				return agg;
			}, []);

			if(!processors[type])
			{
				console.error(`No processor exists for type ${type}`);
			}
			else
			{
				const updated = processors[type](value, options);

				agg += updated;
			}
		}

		return agg;
	}, '');
}

export function getIntermediateValues(data, types)
{
	const dataArray = data.split(/##/m);

	return dataArray.reduce((agg, string) =>
	{
		if(string.split('|'.length === 2))
		{
			const [type, value] = string.split('|');

			if(types.includes(type))
			{
				agg.push({ type, value });
			}
		}

		return agg;
	}, []);
}

export async function reloadStyles(opts)
{
	const { data } = await get('service/theme.css');
	const processedTheme = processStyles(data, opts);

	setThemeContent(processedTheme);
}
