import { get, post, put, destroy } from '@/utils/api';
import { setItems, setOneItem } from '@/utils/store';

const state = {
	items: [],
	loading: false
};

const mutations = {
	SET_DATA_MAPPINGS(state, mappings)
	{
		setItems(state, 'items', state.items, mappings);
	},
	SET_DATA_MAPPING(state, mapping)
	{
		setOneItem(state, 'items', mapping);
	},
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	},
	REMOVE_DATA_MAPPING(state, id)
	{
		state.items = state.items.filter((m) => m.id !== id);
	}
};

const actions = {
	async loadAll({ dispatch })
	{
		dispatch('setLoading', true);

		try
		{
			const { data } = await get('dataMappings');

			dispatch('app/handleServerResponse', data, { root: true });
		}
		catch(e)
		{
			console.error('There was an error', e.message);
		}
		finally
		{
			dispatch('setLoading', false);
		}
	},
	async load({ dispatch, getters }, { force = false, id } = {})
	{
		if(!getters.byId(id) || force === true)
		{
			dispatch('setLoading', true);

			try
			{
				const { data } = await get(`dataMappings/${id}`);

				dispatch('app/handleServerResponse', data, { root: true });
			}
			catch(e)
			{
				console.error('There was an error', e.message);
			}
			finally
			{
				dispatch('setLoading', false);
			}
		}
	},
	async search({ dispatch }, { page = 1, limit = 10, filters = {}, sortBy = null, descending = false })
	{
		const { data } = await get('/dataMappings/search', { params: { page, limit, filters, sortBy, descending } });

		const itemData = data.items.map((item) => item.data);

		dispatch('setDataMappings', itemData);

		return data;
	},
	async create({ dispatch }, { title })
	{
		const { data } = await put('/dataMappings', { title, data: {} });

		if(data?.changes)
		{
			dispatch('app/handleServerResponse', data.changes, { root: true });

			return data.changes?.dataMapping?.[0];
		}

		return null;
	},
	async update({ dispatch }, payload)
	{
		if(!payload?.id)
		{
			return;
		}

		const { data } = await post(`/dataMappings/${payload.id}`, payload);

		if(data?.changes)
		{
			dispatch('app/handleServerResponse', data.changes, { root: true });
		}
	},
	async destroy({ commit }, id)
	{
		try
		{
			await destroy(`/dataMappings/${id}`);
			commit('REMOVE_DATA_MAPPING', id);
		}
		catch(e)
		{
			console.error('Something went wrong when deleting mapping', e);
		}
	},
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	},
	setDataMappings({ commit }, mappings)
	{
		if(Array.isArray(mappings))
		{
			commit('SET_DATA_MAPPINGS', mappings);
		}
	}
};

const getters = {
	all: (state) => state.items,
	total: (state, getters) => getters.all.length,
	loading: (state) => state.loading,
	byId: (state, getters) => (id) => getters.all.find((mapping) => mapping.id === id)
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
