<template>
	<div>
		<I18N id="form.confirmationCheck" />
		<q-table
			v-if="displayRows.length"
			:data="displayRows"
			:columns="changeReportColumns"
			:rowsPerPageOptions="[0]"
			:pagination="{ rowsPerPage: 0 }"
			rowKey="name"
		>
			<template
				#body-cell-inputValue="props"
			>
				<q-td
					:key="cellKey('inputValue', 'other')"
					class="color-transition tableBody"
				>
					<component
						:is="fieldElement(props.row)"
						:value="props.row.value"
						:blockId="blockId"
						:entityId="entityId"
						:accountToUse="accountToUse"
						:editable="false"
						:disabled="true"
						:field="props.row"
						:formId="mainFormId"
						:collapseRepeaters="false"
					/>
				</q-td>
			</template>
		</q-table>
		<div v-else>
			<strong>
				<I18N id="form.noChanges" />
			</strong>
		</div>
		<div v-if="showAgreement" class="float-right q-pt-md">
			<SimpleButton
				class="outline q-mr-md"
				size="md"
				outline
				@click="cancelAction"
			>
				<I18N id="blocks.modal.agreementButtons.cancel" />
			</SimpleButton>
			<SimpleButton
				color="primary"
				class="submit"
				@click.stop="confirm"
			>
				<I18N id="blocks.modal.agreementButtons.agree" />
			</SimpleButton>
		</div>
		<div class="float-clear" />
	</div>
</template>

<script>
	import cloneDeep from 'lodash/cloneDeep';

	export default {
		name: 'FormConfirmation',
		components: {
			DisplayValue: () => import('@/components/form/elements/DisplayValue'),
			DisplayValueOptions: () => import('@/components/form/elements/DisplayValueOptions'),
			RepeaterDisplayValue: () => import('@/components/form/elements/repeaters/DisplayValue'),
			Heading: () => import('@/components/form/elements/Heading'),
			Paragraph: () => import('@/components/form/elements/Paragraph'),
			Toggle: () => import('@/components/form/elements/Toggle')
		},
		props: {
			changeReportRows: {
				type: Array,
				default: () => ([])
			},
			confirm: {
				type: Function,
				required: false,
				default: null
			},
			cancelAction: {
				type: Function,
				required: false,
				default: null
			},
			showAgreement: {
				type: Boolean,
				default: true
			},
			mainFormId: { // so we get the same data as updated in the profileFormCopy, used for conditional fields
				type: String,
				required: true
			},
			blockId: {
				type: [String],
				default: ''
			},
			entityId: {
				type: String,
				default: ''
			},
			accountToUse: {
				type: [Number, String, undefined],
				default: undefined
			}
		},
		data()
		{
			return {
				changeReportColumns: [
					{
						name: 'inputValue',
						label: this.$t('form.changedValues'),
						field: 'inputValue',
						sortable: false,
						align: 'left'
					}
				],
				rowExpansion: {}
			};
		},
		computed: {
			displayRows()
			{
				return this.recursiveDataMapper(cloneDeep(this.changeReportRows)); // clone so Vue doesn't complain
			}
		},
		methods: {
			recursiveDataMapper(data)
			{
				return data.map((field) =>
				{
					field.value = field.inputValue; // because all the display components show the `value` property

					if(Array.isArray(field.value))
					{
						this.markDeletedItems(field.value); // mark the deleted items in `value` because that's what the repeater's DisplayValue shows
					}

					return field;
				});
			},
			markDeletedItems(data)
			{
				data.forEach((item) =>
				{
					if(item._meta?.deleted === true)
					{
						item._meta.confirmationView = true; // make it show as a deleted item in the confirmation view

						return;
					}

					// else check if there are sub-repeaters
					Object.keys(item).forEach((key) =>
					{
						if(key !== '_meta') // pass all remaining meta
						{
							if(Array.isArray(item[key].value))
							{
								this.markDeletedItems(item[key].value);
							}
						}
					});
				});
			},
			isDateType(field)
			{
				return field?.type === 'date';
			},
			isOptionsType(field)
			{
				return field?.type === 'options';
			},
			isRepeaterField(field)
			{
				return field?.type === 'repeater';
			},
			repeaterMaxResults(values)
			{
				return values.length || 0;
			},
			headerSlot(field)
			{
				return `header-cell-${field.key}`;
			},
			cellKey(name, i)
			{
				return `body-cell-${name}-${i}`;
			},
			formatRepeaterValues(values)
			{
				return values?.map((value, i) => ([i + 1, value])) || [];
			},
			getRowValue(value, field)
			{
				if(field.type === 'checkbox' && value) return value?.join(', ') || '';

				if(field.repeaterSettings && value) return value?.[0] || '';

				return value;
			},
			repeaterValuesLength(values)
			{
				return values?.filter((val) => val)?.length || 0;
			},
			isOverflowing(field)
			{
				const element = this.$refs[field]?.[0];

				return element ? (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) : false;
			},
			getExpansionWrap(rowIndex)
			{
				return this.rowExpansion[rowIndex] ? 'wrap-normal' : 'wrap-nowrap';
			},
			fieldElement(field)
			{
				if(['heading', 'paragraph'].includes(field.type)) // likely not needed here, but best have them in case a dev create a hacky way of updating them
				{
					return field.type;
				}

				switch(field.type)
				{
					case 'checkbox': return 'DisplayValueOptions';
					case 'repeater': return 'RepeaterDisplayValue';
					case 'toggle': return 'Toggle';
					default: return 'DisplayValue';
				}
			}
		}
	};
</script>
