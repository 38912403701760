<template>
	<div>
		<q-card>
			<q-item>
				<q-item-section>
					<q-item-label>
						<I18N id="accessibility.noCarouselAutoPlay.title" />
					</q-item-label>
				</q-item-section>
				<q-item-section class="content-center" side>
					<q-toggle :value="noCarouselAutoPlay" @input="setMetaValue('accessibility.noCarouselAutoPlay', $event)">
						<q-tooltip>{{ $t('accessibility.noCarouselAutoPlay.tooltip') }}</q-tooltip>
					</q-toggle>
				</q-item-section>
			</q-item>
		</q-card>
	</div>
</template>

<script>
	export default {
		computed: {
			noCarouselAutoPlay()
			{
				return this.$store.getters['user/getDataValueByPath']('accessibility.noCarouselAutoPlay', 'meta') || false;
			}
		},
		methods: {
			setMetaValue(path, data)
			{
				this.$store.dispatch('profiles/saveProfileMetaData', { path, data });
			}
		}
	};
</script>
