import store from '@/store';
import router from '@/router';

/** @typedef {import('@/types/app').VueRoute} VueRoute */

const _selectIdentifier = (...rest) =>
{
	let id;

	rest.forEach((param) =>
	{
		if(typeof param === 'undefined') return;

		if(typeof param === 'object' && param.id)
		{
			id = param.id;
		}

		if(typeof param === 'string')
		{
			id = param;
		}
	});

	if(id)
	{
		return id;
	}

	throw new Error('You must provide an ID');
};

const getModuleAndPageSlugs = async ({ moduleId, pageId }) =>
{
	// If we're going to another page, we need to make sure it's loaded. This is specifically important for
	// layouts/abstract pages as they are not loaded by default.
	await Promise.all([
		moduleId ? store.dispatch('structure/modules/loadModule', moduleId) : null,
		pageId ? store.dispatch('structure/pages/loadPage', pageId) : null
	]);

	const pageSlug = store.getters['structure/pages/getSlugForId'](pageId);
	const moduleSlug = store.getters['structure/modules/getSlugForId'](moduleId);

	return { moduleSlug, pageSlug };
};

const make = async ({ module, moduleId, page, pageId, tab }, targetId = null, push = false) =>
{
	const path = {
		name: 'page'
	};

	const moduleIdentifier = _selectIdentifier(module, moduleId);
	const pageIdentifier = _selectIdentifier(page, pageId);

	const { moduleSlug, pageSlug } = await getModuleAndPageSlugs({ moduleId: moduleIdentifier, pageId: pageIdentifier });

	const params = {
		moduleSlug,
		pageSlug
	};

	if(tab)
	{
		path.query = {
			tab: [tab]
		};
	}

	path.params = params;

	if(targetId)
	{
		path.params.targetId = targetId;
	}

	return push ? pushRoute(path) : path;
};

const getRouteTargetId = ({ pageSlug, targetId, postId }) =>
{
	let newTargetId = null;

	if(
		pageSlug === 'discussions' &&
		targetId.length === 0 &&
		postId
	)
	{
		const communication = store.getters['communications/byId'](
			postId
		);
		const [slug] = communication.meta.linkToComment.match(
			/space\/[^/]*/g
		);

		newTargetId = slug.replace('space/', '');
	}

	return newTargetId;
};

const generateRouteFromModuleAndPageIds = async (data) =>
{
	const {
		moduleId,
		pageId,
		targetId,
		params,
		routerPageName
	} = data || {};

	if(!(moduleId && pageId))
	{
		return undefined;
	}

	const { moduleSlug, pageSlug } = await getModuleAndPageSlugs({ moduleId, pageId });
	const route = {};

	route.params = {
		...(params || {}),
		moduleSlug,
		pageSlug,
		targetId
	};

	if(routerPageName)
	{
		route.name = routerPageName;
	}

	return route;
};

/**
 * Generate a route to your destination!
 * @param {*} data
 * @param {*} push
 * @param	{object} data		All the data you want to give. Pick from:
 * blockId, entityId, user, accountId, module, moduleId, page, pageId, tab, postId, pageNumber, location, routerPageName, followingEntryLink
 * @param	{boolean} push		(Optional) Whether you want to push the route immediately
 * @return	{object}			Your route as an object e.g. { name, params: {} }
 */
const generate = async (data, push = false) =>
{
	if(!Object.keys(data || {}).length) return {};

	let { entity, pageNumber, blockId, postId } = data;

	const route = { name: 'page', params: {} };

	const {
		entityId,
		user, accountId,
		module, moduleId,
		page, pageId,
		tab,
		location,
		routerPageName,
		followingEntryLink
	} = data;

	if(user?.accountId || accountId)
	{
		// go to profile
		const defaultPage = store.getters['app/settings/defaultProfilePage']() || {};

		route.params = {
			...defaultPage.params,
			targetId: user?.accountId || accountId
		};
	}

	route.name = routerPageName || (entity?.id || entityId ? 'space' : 'page');

	if(module && moduleId && page && pageId)
	{
		return make({ module, moduleId, page, pageId, tab }, blockId, push);
	}

	if(moduleId && pageId && !followingEntryLink && !location)
	{
		const generatedRoute = await generateRouteFromModuleAndPageIds(data);

		if(generatedRoute)
		{
			return generatedRoute;
		}
	}

	if(followingEntryLink)
	{
		const { moduleId, pageId } = followingEntryLink;

		const { moduleSlug, pageSlug } = await getModuleAndPageSlugs({ moduleId, pageId });

		route.params = {
			...followingEntryLink.params,
			moduleSlug,
			pageSlug,
			// If we can, we need to get the entity ID.
			// Since sometimes we have the ID, and sometimes we get the slug from the URL, we need to try both.
			targetId: entity?.slug || entityId
		};

		if(followingEntryLink.routerPageName)
		{
			route.name = followingEntryLink.routerPageName;
		}
	}

	if(entityId && !entity?.id)
	{
		// get the entity if not already extant
		entity = store.getters['entities/byId'](entityId);

		if(!entity)
		{
			await store.dispatch('entities/load', { id: entityId });
			entity = store.getters['entities/byId'](entityId);
		}

		route.params.targetId = entity?.slug || entity?.id || entityId;
	}

	// try this before entity.location
	if(location && Object.keys(location).length)
	{
		if(!pageNumber) pageNumber = location.page;

		// if there is a link, try to get the entity slug
		if(location.linkToComment)
		{
			const [slugFromLink] = location.linkToComment.match(/space\/[^/]*/g) || [''];

			route.params.targetId = slugFromLink.replace('space/', '');
		}

		if(location.moduleSlug && location.pageSlug)
		{
			// pre-load the module & page
			const module = await store.dispatch('structure/modules/loadModuleBySlug', location.moduleSlug);
			const page = module?.pages?.find((page) => (page?.slug === location.pageSlug));

			await store.dispatch('structure/pages/loadPage', page?.id);

			route.params.moduleSlug = location.moduleSlug;
			route.params.pageSlug = location.pageSlug;

			// If :targetId param is still empty here for discussion posts,
			// get communication by id then get targetId from linkToComment slug.
			const targetId = await getRouteTargetId({
				pageSlug: location.pageSlug,
				targetId: route.params.targetId,
				postId
			});

			if(targetId)
			{
				route.params.targetId = targetId;
			}
		}

		blockId = location.targetId || location.itemId || blockId;
		postId = location.parentId || location.commId || postId; // going to the actual commId shows only that comment, so show the parent (i.e. whole thread) instead

		route.name = location.routerPageName || route.name;
	}
	else if(entity?.location)
	{
		const { moduleSlug, pageSlug } = await getModuleAndPageSlugs({ moduleId: entity.location.module, pageId: entity.location.page });

		route.params.pageSlug = pageSlug;
		route.params.moduleSlug = moduleSlug;
		route.name = entity.location.routerPageName || route.name;
	}

	if(blockId) // postId may be undefined, but we still want to go to the block
	{
		const payload = {
			post: postId,
			page: pageNumber || 1
		};

		route.query = { [`block:${blockId}`]: JSON.stringify(payload) };
	}

	// if pageId isn't used by this point & is the desired target, add it in
	if(!!pageId && route.name === 'page')
	{
		route.params.targetId = pageId;
	}

	if(tab)
	{
		if(route.query)
		{
			route.query.tab = [tab];
		}
		else
		{
			route.query = { tab: [tab] };
		}
	}

	return push ? pushRoute(route) : route;
};

/**
 * generate an entity route
 * @param entity: The entity's data
 * @param force: data to force including push (to push the route), pageId, pageSlug, moduleId, moduleSlug
 **/
const entityRoute = async (entity, force = {}) =>
{
	const route = {
		name: 'space',
		params: {
			targetId: entity.slug || entity.id || entity.targetId
		}
	};
	const { moduleSlug, pageSlug } = await getModuleAndPageSlugs({
		moduleId: force.moduleId || entity.location.module,
		pageId: force.pageId || entity.location.page
	});

	route.params.pageSlug = force.pageSlug || pageSlug;
	route.params.moduleSlug = force.moduleSlug || moduleSlug;
	route.name = force.routerPageName || entity.location.routerPageName || route.name;

	if(entity.location?.tab?.hash)
	{
		route.query = { tab: entity.location.tab.hash };
	}

	return force.push ? pushRoute(route) : route;
};

/**
 * Push or replace the current route with the given route. Duplicate route errors are swallowed.
 * @param {VueRoute} route The route to navigate to.
 * @param {Boolean} replace Whether to replace the route.
 * @returns {Promise<void>}
 */
const pushRoute = async (route, replace = false) =>
{
	if(!route?.params) return;

	try
	{
		if(replace)
		{
			await router.replace(route);
		}
		else
		{
			await router.push(route);
		}
	}
	catch(e)
	{
		if(e?.name === 'NavigationDuplicated')
		{
			//
		}
		else
		{
			console.error(e);
		}
	}
};

const profileLink = ({ user, accountId }) =>
{
	const { params } = store.getters['app/settings/defaultProfilePage']() || {};

	const route = { name: params?.routerPageName || 'page', params };

	if(user?.accountId || accountId)
	{
		route.params.targetId = user?.accountId || accountId;
	}

	return route;
};

const profileLinkFromBlockData = (data, params = {}, fallbackToDefault = true) =>
{
	const link = { name: 'page', params: {}, query: {} };

	if(data.profileLink && Object.keys(data.profileLink).length)
	{
		// If we only have one key, and it's 'path', then we've been given a direct URL, and we can just go there
		if(Object.keys(data.profileLink).length === 1 && ('path' in data.profileLink))
		{
			return data.profileLink;
		}

		// If we're only given  a module then we only need to go there
		if(Object.keys(data.profileLink).length === 1 && ('module' in data.profileLink || 'moduleId' in data.profileLink))
		{
			const moduleSlug = store.getters['structure/modules/getSlugForId'](data.profileLink.module || data.profileLink.moduleId);

			return { name: 'module', params: { moduleSlug } };
		}

		const moduleSlug = store.getters['structure/modules/getSlugForId'](data.profileLink.module || data.profileLink.moduleId);
		const pageSlug = store.getters['structure/pages/getSlugForId'](data.profileLink.page || data.profileLink.pageId);

		link.params = { ...link.params, moduleSlug, pageSlug, ...params };
		if(data.profileLink.routerPageName)
		{
			link.name = data.profileLink.routerPageName;
		}

		if(data.profileLink?.tab)
		{
			link.query.tab = data.profileLink.tab.hash;
		}
	}
	else if(fallbackToDefault)
	{
		const { params } = store.getters['app/settings/defaultProfilePage']() || {};

		link.params = params || {};
		link.name = params?.routerPageName || link.name;
	}

	return link;
};

const internalLinkFromData = (data, params = {}) =>
{
	return profileLinkFromBlockData({ profileLink: data }, params);
};

export {
	make,
	generate,
	pushRoute,
	_selectIdentifier,
	entityRoute,
	profileLink,
	profileLinkFromBlockData,
	internalLinkFromData
};
