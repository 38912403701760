<template>
	<DynamicColumns
		:columns="columns"
		:blockId="data.id"
		:blockIdsInCols="blockIdsInCols"
	>
		<template v-for="(column, colIndex) in columns" v-slot:[`col-${colIndex}`]>
			<div :key="colIndex">
				<Block
					v-for="(blockId, index) in blockIdsInCols[`col-${colIndex}`]"
					:key="index"
					:data="blockId"
					v-bind="$props"
					:data-child-index="index"
					:colIndex="colIndex"
					:index="index"
				/>
			</div>
		</template>
	</DynamicColumns>
</template>

<script>
	import BlockMixin from '@/components/blocks/BlockMixin';
	import DynamicColumns from '@/components/layout/DynamicColumns';

	export default {
		components: {
			DynamicColumns,
			Block: () => import('@/components/blocks/Block')
		},
		mixins: [BlockMixin],
		computed: {
			columns()
			{
				return this.data.columns;
			},
			positions()
			{
				return this.data.positions;
			}
			// orphanedChildren()
			// {
			// 	const { children } = this.data;
			// 	const usedChildren = Object.values(this.blockIdsInCols)
			// 		.reduce((agg, blockIds) =>
			// 		{
			// 			blockIds.forEach((id) => agg.push(id));

			// 			return agg;
			// 		}, []);

			// 	return children.filter((id) => !usedChildren.includes(id)).map((blockId) => this.$store.getters['structure/blocks/getBlock'](blockId));
			// }
		}
	};
</script>

<style scoped>
</style>
