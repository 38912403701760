<template>
	<div>
		<portal :to="portalTarget" :disabled="disableLightroomEditor">
			<div
				ref="lightroomContainer"
				class="inline-editor-container"
				:class="{hidden: (alwaysEditing && isLoading) || !isUploaderVisible}"
			>
				<div
					:id="`inline-uploader-${id}`"
					class="inline-uploader-anchor"
					style="position: absolute; top: -60px;"
				/>
				<!-- Exits lightroom mode when pressing Escape -->
				<!-- This won't trigger if a preview is in fullscreen -->
				<GlobalEvents v-if="!isShowingPreviews" @keydown.27="disableEditing" />
				<!-- IN INLINE MODE ONLY -->
				<!-- Image / Preview tabs and Delete button -->
				<!-- Hidden if preview and deleting are disabled -->
				<div v-if="topControlsDisplayCondition" class="inline-uploader-top-controls">
					<div v-if="previewEnabled" class="controls-group">
						<span
							:class="[
								'inline-uploader-button',
								'switch',
								{
									active: !isShowingPreviews
								}
							]"
							@click="isShowingPreviews = false"
						>
							<I18N id="imageUploader.image" />
						</span>
						<span
							:class="[
								'inline-uploader-button',
								'switch',
								{
									active: isShowingPreviews
								}
							]"
							@click="showPreviews"
						>
							<I18N id="imageUploader.previews" />
						</span>
					</div>
					<div v-else />
					<!-- Delete button -->
					<!-- Hidden if deleting is disabled -->
					<div v-if="!disableDelete" class="controls-group">
						<span
							class="inline-uploader-button delete"
							@click.prevent="deleteImage"
						>
							<q-icon
								size="18px"
								class="control-icon"
								name="far fa-trash-alt"
							/>
						</span>
					</div>
				</div>
				<!-- isLoaded handles opening animation -->
				<div
					class="inline-uploader"
					:class="[editStateClass, {isLoaded: !isLoading}]"
				>
					<!-- ONLY IN LIGHTROOM EDITOR -->
					<!-- Lightroom Toolbar -->
					<div
						v-if="!focalPointEdit && inLightroomEditor"
						class="lightroom-toolbar"
						:class="{'mobile': isPortraitScreen}"
					>
						<div class="lightroom-toolbar-container">
							<!-- Buttons are displayed differently whether in desktop or mobile view -->
							<div class="title-delete-container" :class="{'reverse': !isPortraitScreen}">
								<SimpleButton
									v-if="!disableDelete"
									class="delete-button"
									:class="{'mobile': isPortraitScreen, 'transparent': image === ''}"
									round
									padding="xs"
									icon="far fa-trash"
									flat
									size="10px"
									:aria-label="$t('imageUploader.delete')"
									color="grey-1"
									@click.prevent="deleteImage"
								/>
								<header v-if="title !== ''" class="text-h6 lightroom-title">
									{{ title }}
								</header>
								<SimpleButton
									v-if="isPortraitScreen"
									class="close-button"
									round
									padding="xs"
									icon="far fa-times"
									flat
									size="15px"
									:aria-label="$t('modal.close')"
									color="grey-1"
									@click="disableEditing"
								/>
							</div>
							<SimpleButton
								v-if="!isPortraitScreen"
								class="close-button"
								round
								padding="xs"
								icon="far fa-times"
								flat
								size="15px"
								:aria-label="$t('modal.close')"
								color="grey-5"
								@click="disableEditing"
							/>
						</div>
					</div>
					<!-- Click to edit overlay -->
					<div
						v-if="!isEditing && !isShowingPreviews && image !== ''"
						class="inline-uploader-border"
						@click="enableEditing"
					>
						<span class="hover-label"><I18N id="imageUploader.editLabel" /></span>
					</div>
					<!-- IMAGE TAB for inline editor -->
					<div class="image" :class="{hidden: isShowingPreviews}">
						<div class="uploader">
							<croppa
								ref="uploader"
								v-model="model"
								accept="image/*"
								:style="[(inLightroomEditor) ? fullScreenImageStyle : '', { background: backgroundColor }]"
								:class="[
									{ 'crosshair': focalPointEdit === true },
									{ 'hoverable': !validImage },
									{ 'transparent-background': transparentBackground },
									{ 'strict-image-size': strictImageSize && !inLightroomEditor },
									{ 'small-canvas': smallCanvas },
									editStateClass
								]"
								:width="width"
								:height="height"
								:zoomSpeed="2"
								:quality="quality"
								:placeholder="setPlaceholder"
								:autoSizing="setAutoSizing"
								:showRemoveButton="false"
								:placeholderColor="getPlaceholderColor"
								:initialImage="newImageAdded ? newSource : initialValue"
								:initialSize="initialSize"
								:disableDragToMove="!isEditing || focalPointEdit === true"
								:disableScrollToZoom="!isEditing || zoom.disabled || focalPointEdit === true"
								:preventWhiteSpace="preventWhiteSpace"
								:fileSizeLimit="maxFileSize"
								@initial-image-loaded="findZoomSliderRange"
								@draw="drawNewImage"
								@file-size-exceed="fileSizeExceeded"
								@new-image-drawn="updateImage"
								@image-remove="updateImage"
								@new-image="newFile"
								@click="setFocalPointClick"
								@zoom="updateSliderZoom"
							>
								<div
									v-if="focalPointEdit && !disableFocalSelect && validFileId"
									:class="['focal-point-indicator', { 'show': Object.keys(focalPointPercentage).length > 0 }]"
									:style="{ left: indicatorPosX, top: indicatorPosY }"
								/>
							</croppa>
						</div>
						<!-- Editing controls and submit button -->
						<div
							ref="uploaderControls"
							class="uploader-bottom-controls"
							:class="[
								{ 'mobile': isPortraitScreen },
								editStateClass,
								{ hidden: !(isEditing && image !== '' && !(focalPointEdit && inLightroomEditor)) }
							]"
						>
							<div class="controls q-gutter-xs">
								<SimpleButton
									v-if="showControl('rotate')"
									size="10px"
									icon="far fa-sync-alt"
									color="grey-4"
									:textColor="buttonTextColor"
									:aria-label="$t('imageUploader.rotate')"
									stack
									padding="5px 5px 2px"
									flat
									unelevated
									class="q-ml-xs"
									@click.prevent="rotateImage"
								>
									<I18N id="imageUploader.rotate" />
								</SimpleButton>
								<SimpleButton
									v-if="showControl('flipVertical')"
									size="10px"
									stack
									flat
									icon="far fa-arrows-v"
									color="grey-4"
									padding="5px 5px 2px"
									:textColor="buttonTextColor"
									unelevated
									class="q-ml-xs"
									@click.prevent="flipVertically"
								>
									<I18N id="imageUploader.flipVertically" />
								</SimpleButton>
								<SimpleButton
									v-if="showControl('flipHorizontal')"
									size="10px"
									icon="far fa-arrows-h"
									color="grey-4"
									stack
									padding="5px 5px 2px"
									flat
									:textColor="buttonTextColor"
									unelevated
									class="q-ml-xs"
									@click.prevent="flipHorizontally"
								>
									<I18N id="imageUploader.flipHorizontally" />
								</SimpleButton>
								<SimpleButton
									v-if="transparentBackgroundOption"
									size="10px"
									icon="fa fa-images"
									:color="transparentBackground ? 'positive' : 'grey-4'"
									:textColor="transparentBackground ? 'white' : buttonTextColor "
									stack
									padding="5px 5px 2px"
									:flat="!transparentBackground"
									unelevated
									class="q-ml-xs"
									@click="transparentBackground = !transparentBackground"
								>
									<I18N id="imageUploader.transparent" />
								</SimpleButton>
								<q-btn-dropdown
									v-if="!disableMetadataEdit"
									:label="$t('imageUploader.metadata')"
									color="grey-9"
									stack
									flat
									:textColor="buttonTextColor"
									icon="far fa-book"
									class="no-arrow-dropdown"
									size="10px"
									padding="5px 5px 2px"
									unelevated
								>
									<QuasarProps :dark="false">
										<Form
											alwaysEdit
											overrideControlHiding
											class="q-pa-md metadataForm"
											:fields="[
												{
													name: 'title',
													type: 'text',
													value: metadata.title,
													i18nPath: 'imageUploader.fields.title'
												},
												{
													name: 'description',
													type: 'textarea',
													value: metadata.description,
													i18nPath: 'imageUploader.fields.description'
												}
											]"
											:onSubmit="saveMetadata"
										/>
									</QuasarProps>
								</q-btn-dropdown>
								<SimpleButton
									v-if="showControl('preventWhiteSpaceOption') || preventWhiteSpaceOption"
									size="10px"
									:color="preventWhiteSpace ? 'positive' : 'grey-4'"
									:textColor="preventWhiteSpace ? 'white' : buttonTextColor"
									unelevated
									class="q-ml-xs"
									stack
									:flat="!preventWhiteSpace"
									icon="far fa-square"
									padding="5px 5px 2px"
									@click.prevent="setWhiteSpace"
								>
									<I18N id="imageUploader.fillArea" />
								</SimpleButton>
								<SimpleButton
									v-if="showControl('preventWhiteSpaceOption') || preventWhiteSpaceOption"
									size="10px"
									color="grey-4"
									:textColor="buttonTextColor"
									unelevated
									class="q-ml-xs"
									stack
									flat
									icon="far fa-arrows"
									padding="5px 5px 2px"
									@click.prevent="centreImage"
								>
									<I18N id="imageUploader.centreImage" />
								</SimpleButton>
								<SimpleButton
									v-if="showControl('focalSelect') && !disableFocalSelect && fileId && !isPortraitScreen"
									size="10px"
									:color="focalPointEdit ? 'positive' : 'grey-4'"
									:textColor="focalPointEdit ? 'white' : buttonTextColor"
									unelevated
									class="q-ml-xs"
									stack
									:flat="!focalPointEdit"
									icon="far fa-camera"
									padding="5px 5px 2px"
									@click.prevent="focalPointEdit = !focalPointEdit"
								>
									<I18N id="imageUploader.focal.edit" />
								</SimpleButton>
								<SimpleButton
									v-if="!disableFocalSelect && fileId && focalPointEdit"
									color="grey-4"
									:textColor="buttonTextColor"
									unelevated
									flat
									icon="far fa-bullseye"
									size="10px"
									padding="5px 5px 2px"
									stack
									:disable="!focalPointEdit"
									class="q-ml-xs"
									@click="setFocalPointRelative(50, 50)"
								>
									<I18N id="imageUploader.focal.center" />
								</SimpleButton>
							</div>
							<!-- Zoom slider will move around depending on image format if in lightroom mode -->
							<div
								class="zoom-slider"
								:class="[{ vertical: isSliderVertical, 'mobile-slider': isPortraitScreen, 'hidden': focalPointEdit }, editStateClass]"
							>
								<SimpleButton
									size="9px"
									round
									flat
									icon="fal fa-minus"
									:textColor="buttonTextColor"
									unelevated
									@click.prevent="zoomOut"
								/>
								<q-slider
									v-model="model.scaleRatio"
									:trackColor="buttonTextColor"
									:dark="inLightroomEditor"
									thumbSize="8px"
									dense
									class="slider-track"
									:step="0.01"
									:color="buttonTextColor"
									:vertical="isSliderVertical"
									:reverse="isSliderVertical"
									:min="zoomSliderMin"
									:max="zoomSliderMax"
								/>
								<SimpleButton
									size="9px"
									flat
									icon="far fa-plus"
									round
									:textColor="buttonTextColor"
									unelevated
									@click.prevent="zoomIn"
								/>
							</div>
							<!-- ONLY IN INLINE MODE -->
							<!-- Delete is hidden if disableDelete (i.e. ImageAssetUploader) -->
							<!-- Submit button is Done if !alwaysEditing. It just exits the editing mode. (i.e. Entities) -->
							<!-- Submit button is Save if alwaysEditing. -->
							<!-- No submit button if save is external and is always editing (i.e. ImageAssetUploader)-->
							<div v-if="!disableDelete || !externalSave">
								<SimpleButton
									v-if="alwaysEditing && !absoluteSubmitButtonCondition && !disableDelete"
									class="q-ml-xs ImageUploaderSaveButton"
									unelevated
									size="12px"
									color="red"
									@click.prevent="deleteImage"
								>
									<I18N id="imageUploader.delete" />
								</SimpleButton>
								<SimpleButton
									v-if="!absoluteSubmitButtonCondition && !externalSave"
									class="q-ml-xs ImageUploaderSaveButton"
									size="12px"
									color="primary"
									padding="2px 4px"
									unelevated
									@click="submit"
								>
									<I18N v-if="!alwaysEditing" id="imageUploader.done" />
									<I18N v-if="alwaysEditing && !isSaving" id="imageUploader.save" />
									<q-spinner v-if="alwaysEditing & isSaving" />
								</SimpleButton>
							</div>
						</div>
						<!-- Save button for Lightroom editor.  -->
						<!-- Disappears when editing focal point.  -->
						<SimpleButton
							v-if="absoluteSubmitButtonCondition && !externalSave"
							class="q-ml-xs save-button ImageUploaderSaveButton"
							size="12px"
							color="primary"
							unelevated
							@click="submit"
						>
							<I18N v-if="!isSaving" id="imageUploader.save" />
							<q-spinner v-else />
						</SimpleButton>
						<!-- Focal point controls for lightroom editor -->
						<!-- If they're shown, all the other controls disappear. -->
						<div v-if="focalPointEdit && inLightroomEditor && fileId" class="focal-edit-controls">
							<SimpleButton
								size="12px"
								:disable="!focalPointEdit"
								class="q-ml-xs"
								stack
								flat
								icon="far fa-bullseye"
								color="grey-9"
								textColor="grey-4"
								unelevated
								padding="5px"
								@click="setFocalPointRelative(50, 50)"
							>
								<I18N id="imageUploader.focal.reset" />
							</SimpleButton>
							<SimpleButton
								size="12px"
								:disable="!focalPointEdit"
								class="q-ml-xs"
								stack
								flat
								icon="far fa-check"
								color="grey-9"
								textColor="grey-4"
								unelevated
								padding="5px"
								@click="focalPointEdit = false"
							>
								<I18N id="imageUploader.done" />
							</SimpleButton>
						</div>
					</div>
					<!-- PREVIEWS TAB for inline editor -->
					<div :class="{ hidden: !isShowingPreviews }">
						<div class="choose-previews-button-container">
							<q-btn-dropdown
								v-if="previewEnabled"
								color="grey-4"
								textColor="grey-10"
								unelevated
								size="12px"
								:label="$t('imageUploader.preview.toggle')"
							>
								<div class="q-ma-md">
									<I18N id="imageUploader.preview.settings.ratioSelect.header" wrap="h5" />
									<div class="row q-mb-md" style="max-width: 650px">
										<div
											v-for="option in previewOptionsPretty"
											:key="`${id}-option-${option.value}`"
											class="row previewOptionHover q-pa-xs justify-between items-center"
											style="width: 50%"
										>
											<q-checkbox
												v-model="activePreviews"
												:val="option.value"
												:label="option.label"
												style="flex: 1 1 auto"
												class="q-mr-sm"
											/>
											<SimpleButton
												v-if="option.custom"
												icon="fal fa-times"
												color="negative"
												flat
												dense
												round
												style="flex: 0 0 34px"
												@click="removeRatio(option.value)"
											/>
										</div>
									</div>
									<I18N id="imageUploader.preview.settings.customRatio.header" wrap="h5" />
									<div class="row items-center">
										<q-input
											v-model.number="customRatio.x"
											:label="$t('imageUploader.preview.settings.customRatio.opts.width')"
											class="q-mr-sm"
											outlined
											dense
											type="number"
											@keydown.enter="addRatio"
										/>
										<SimpleButton
											flat
											round
											size="sm"
											icon="far fa-exchange"
											class="q-mr-sm"
											@click="swapCustomRatioValues"
										>
											<I18N id="imageUploader.preview.settings.customRatio.valueSwap" wrap="q-tooltip" />
										</SimpleButton>
										<q-input
											v-model.number="customRatio.y"
											:label="$t('imageUploader.preview.settings.customRatio.opts.height')"
											class="q-mr-sm"
											outlined
											dense
											type="number"
											@keydown.enter="addRatio"
										/>
										<SimpleButton
											flat
											icon="far fa-plus"
											@click="addRatio"
										>
											<I18N id="imageUploader.preview.settings.customRatio.addNew" wrap="q-tooltip" />
										</SimpleButton>
									</div>
								</div>
							</q-btn-dropdown>
						</div>
						<div
							v-if="validImage && activePreviews.length"
							:class="[previewSizeClass, 'previews-container']"
						>
							<div
								v-for="activePreview in activePreviewsPreserveOrder"
								:key="`preview-${activePreview}`"
								class="preview-cell"
								:style="{
									width: `${calcPreviewWidth(activePreview)}px`,
									marginRight: `${previewHorizontalSpacing}px`
								}"
							>
								<div
									class="imagePreview"
									:style="[
										currentImagePreview,
										{
											width: `${calcPreviewWidth(activePreview)}px`,
											height: `${calcPreviewHeight(activePreview)}px`
										}
									]"
									@click="maximisePreview(activePreview)"
								>
									<div class="imagePreview-hover">
										<q-icon
											name="fal fa-expand-arrows"
											style="color: #fff; font-size: 1.6em"
										/>
									</div>
								</div>
								<div class="q-ma-xs">
									<h5>{{ activePreview.replace('-', ':') }}</h5>
									<h6>{{ getPreviewOption(activePreview).label }}</h6>
								</div>
							</div>
						</div>
						<BasicNote
							v-else-if="activePreviews.length"
							i18nKey="imageUploader.preview.warnings.imageMissing"
							color="warning"
							icon="fad fa-image"
							class="q-mb-sm"
						/>
						<BasicNote
							v-else
							i18nKey="imageUploader.preview.warnings.noSelection"
							color="warning"
							icon="fad fa-ballot-check"
							class="q-mb-sm"
						/>
					</div>
					<Modal
						ref="previewModal"
						lightroom
					>
						<div class="preview-modal-content">
							<div class="preview-image-maximized" :style="[currentImagePreview]" />
							<q-select
								v-model="maximisedPreviewRatioOption"
								dark
								dense
								outlined
								bgColor="grey-10"
								class="preview-select"
								:options="previewOptionsPretty"
							/>
						</div>
					</Modal>
				</div>
			</div>
		</portal>
	</div>
</template>

<script>
	import debounce from 'lodash/debounce';
	import GlobalEvents from 'vue-global-events';
	import { generateFocusedImage } from '@/utils/focalPointTools';
	import { addError } from '@/utils/notifications';
	import Form from '@/components/form/Form';
	import { inlineImageUploaderPortals } from '@/configs/constants';
	import ImageUploadMixin from '@/components/mixins/imageUploadMixin';
	import { displayBytesInMb } from '@/utils/tools';

	export default {
		components: {
			GlobalEvents,
			Form,
			Modal: () => import('@/components/Modal'),
			BasicNote: () => import('@/components/ui/BasicNote')
		},
		mixins: [ImageUploadMixin],
		props: {
			id: {
				type: String,
				default: ''
			},
			value: {
				type: String,
				default: ''
			},
			opts: {
				type: Object,
				default()
				{
					return { width: 500, height: 500, quality: 1 };
				}
			},
			initial: {
				type: [String, HTMLImageElement],
				default: ''
			},
			/**
			 * Allows croppa to resize itself when image ratio should respect file sent by user
			*/
			autoSizing: {
				type: Boolean,
				default: null
			},
			/**
			 * Same as autosizing
			*/
			matchAspect: {
				type: Boolean,
				default: false
			},
			fileId: {
				type: [Number, String],
				default: null
			},
			disableFocalSelect: {
				type: Boolean,
				default: false
			},
			disableMetadataEdit: {
				type: Boolean,
				default: false
			},
			controls: {
				type: Array,
				default: () => (['focalSelect', 'delete', 'rotate', 'flipVertical', 'flipHorizontal', 'zoom'])
			},
			/**
			 * If `true`, the scroll position of the window will be set just above the Uploader.
			 * As the scroll wheel is used to control the zoom level when the Uploader is in `edit` mode
			 * and when the mouse is over the Uploader, this reduces the risk the user will encounter
			 * annoying behaviour in which they have to use the scroll wheel for scrolling.
			 */
			scrollIntoView: {
				type: Boolean,
				default: false
			},
			/**
			 * Unused by any component using InlineImageUploader
			 */
			disableZoomUntilClick: {
				type: Boolean,
				default: false
			},
			preview: {
				type: Boolean,
				default: false
			},
			/**
			 * Allows changing croppa-container background colour
			 * Doesn't affect the image uploaded
			*/
			backgroundColor: {
				type: String,
				default: ''
			},
			/**
			 * Optional placeholder when there's no image.
			*/
			placeholder: {
				type: String,
				default: null
			},
			/**
			 * Should be set if backgroundColor is changed to ensure readability
			*/
			placeholderColor: {
				type: String,
				default: null
			},
			/**
			 * If set to false there's an overlay to toggle editing
			 * Should be true when using lightroom editor
			 * If using with disableLightroomEditor, inline editor will always be in editing mode
			*/
			alwaysEditing: {
				type: Boolean,
				default: true
			},
			/**
			 * Displays inline editor only
			*/
			disableLightroomEditor: {
				type: Boolean,
				default: false
			},
			/**
			 * Save method when save needs to happen when pressing Save button
			 */
			save: {
				type: Function,
				default: () =>
				{}
			},
			/**
			 * Close method if using lightroom editor
			*/
			close: {
				type: Function,
				default: () =>
				{}
			},
			/**
			 * Optional title that will display either inline or in lightroom
			*/
			title: {
				type: String,
				default: ''
			},
			/**
			 * By default, when in Lightroom editor, images will take up the whole screen.
			 * If strictImageSize is set to true, the display will respect what's set in opts.
			 * This property doesn't affect the image saved.
			 */
			strictImageSize: {
				type: Boolean,
				default: false
			},
			/**
			 * When set to false, will allow users to move image outside of borders (i.e. in Community Branding)
			 * Also will set min zoom to 0.5
			 * This can be toggled via the "Fill area" button, which toggles the `preventWhiteSpace`
			 */
			preventWhiteSpaceOption: {
				type: Boolean,
				default: false
			},
			/**
			 * Allows users to toggle transparency (used in Community Branding)
			 */
			transparentBackgroundOption: {
				type: Boolean,
				default: false
			},
			/**
			 * When true, hides the save button, in case the editor should always be open and the saving happens outside of this component.
			 * Useful for ImageAssetUploader where the editor is always on but the save is handled by ImageAssetUploader.
			 */
			externalSave: {
				type: Boolean,
				default: false
			},
			/**
			 * Sets the max-height of canvas to 50vh
			 */
			smallCanvas: {
				type: Boolean,
				default: false
			},
			/**
			 * If set to false won't display any delete button
			 */
			disableDelete: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			// if image doesn't have a size then set it to 100% to fit modal
			const displayHeight = this.opts?.displayHeight || '100%';
			const displayWidth = this.opts?.displayWidth || '100%';
			let url = null;

			if(this.opts?.initial && this.opts?.initial === 'url')
			{
				this.initialValue = '';
				url = this.value;
			}

			let transparentBg = false;

			// if transparent bg option is not given, then set bg as transparent
			// if option type is png.
			if(!this.transparentBackgroundOption)
			{
				if(this.opts?.type && this.opts?.type === 'png')
				{
					transparentBg = true;
				}
			}

			return {
				preventWhiteSpace: !(this.opts?.preventWhiteSpaceOption || this.preventWhiteSpaceOption),
				isUploaderVisible: this.disableLightroomEditor,
				editingState: false,
				displayHeight,
				displayWidth,
				url,
				model: {},
				image: '',
				width: this.opts?.width || 500,
				height: this.opts?.height || 500,
				requestedWidth: this.opts?.width || 500,
				requestedHeight: this.opts?.height || 500,
				// let's limit the max image size to 1.5x 1920 width and 1.5x 1080 height
				// that way we can still get a good quality download when zoomed in
				// and a png upload doesn't get too big in MB-s
				maxDimensions: {
					width: 2880,
					height: 1620
				},
				previousNaturalWidth: 0,
				previousNaturalHeight: 0,
				quality: this.opts?.quality || 1,
				type: this.opts?.type || 'jpeg', // avoid using 'png' as the image will get 10x larger!
				newImageAdded: false,
				drawNewImage: debounce(() =>
				{
					const { naturalHeight, naturalWidth } = this.model;

					this.newImage();

					// always update the viewport when the image dimensions change
					if(naturalWidth !== this.previousNaturalWidth || naturalHeight !== this.previousNaturalHeight)
					{
						this.updateViewport();
					}

					this.previousNaturalWidth = naturalWidth;
					this.previousNaturalHeight = naturalHeight;
				}, 100),
				focalPointEdit: false,
				focalPointPercentage: {
					x: 50,
					y: 50
				},
				formSave: false,
				zoom: { disabled: this.disableZoomUntilClick },
				activePreviews: [],
				previewWidth: 0,
				isShowingPreviews: false,
				maximisedPreviewRatio: '4-3',
				previewsPerRow: 4,
				previewHorizontalSpacing: 8,
				customRatio: {
					x: 1,
					y: 1
				},
				customPreviewOptions: [],
				maximumPreviewHeightInline: 300,
				metadata: {
					title: null,
					description: null
				},
				imageChanged: false,
				zoomSliderMin: undefined,
				zoomSliderMax: undefined,
				originalScaleRatio: 1,
				windowInnerWidth: window.innerWidth,
				windowInnerHeight: window.innerHeight,
				isSaving: false, // Set to true if alwaysEdit = true and once user submits image
				isLoading: true,
				transparentBackground: transparentBg,
				initialSize: this.opts?.initialSize || 'cover'

			};
		},
		computed: {
			portalTarget()
			{
				return inlineImageUploaderPortals.lightroom;
			},
			/**
			 * Note: when setting a limit, remember that Windows and Mac don't treat a MB the same.
			 * For MacOS, 	1MB = 1,000,000 bytes.
			 * For Windows,	1MB = 2 ^ 20 = 1,048,576.
			 * Therefore, if saying to the user that the max file is, for instance, 1MB, you probably
			 * shouldn't set the limit as 1,000,000 but instead 1,048,576, so Windows users don't feel
			 * left out.
			 * @returns {(number | null)} The maximum file size in bytes if it was defined in
			 * `opts`, or `null` otherwise.
			 */
			maxFileSize()
			{
				return this.opts?.maxFileSize ?? null;
			},
			/**
			 * Styling for the maximised image preview when viewed in the modal.
			 * Returns background-image and background-position depending on focal point
			 * Also figures out the size based on image ratio and window ratio
			 */
			currentImagePreview()
			{
				const currentImagePreviewStyle = generateFocusedImage(`url(${this.image})`, this.focalPointPercentage?.x, this.focalPointPercentage?.y);
				let previewDisplayWidth,
					previewDisplayHeight;

				const screenRatio = window.innerWidth / window.innerHeight;

				/**
				 * By default, image sticks to the left and right of the window
				 */

				previewDisplayWidth = window.innerWidth;
				previewDisplayHeight = previewDisplayWidth * this.maximisedPreviewRatioCalc;

				/**
				 * If image is narrower than window however it should stick to top and bottom
				 */

				if(screenRatio > (1 / this.maximisedPreviewRatioCalc))
				{
					previewDisplayHeight = window.innerHeight;
					previewDisplayWidth = previewDisplayHeight / this.maximisedPreviewRatioCalc;
				}

				return { ...currentImagePreviewStyle, width: `${previewDisplayWidth}px`, height: `${previewDisplayHeight}px` };
			},
			/**
			 * Figures out size of image in lightroom mode.
			 */
			fullScreenImageStyle()
			{
				if(this.strictImageSize) return { height: this.opts?.displayHeight, width: this.opts?.displayWidth };

				const windowRatio = this.windowInnerWidth / this.windowInnerHeight;
				const imageRatio = this.width / this.height;
				let height,
					width;

				/**
				 * If a custom size has been set we don't want the image to take up the whole screen.
				 * For instance, in CommunityBranding.vue, the logo and favicon are set to only take up a small portion of the screen.
				 */
				// if(this.customSize && (this.opts?.height <= window.innerHeight && this.opts?.width <= window.innerWidth))
				// {
				// 	height = this.opts?.height;
				// 	width = this.opts?.width;
				// }

				/**
				 * If the window is wider than the image then the image should stick to the top and bottom of the window.
				 */
				// else if(imageRatio <= windowRatio)
				if(imageRatio <= windowRatio)
				{
					height = window.innerHeight;
					width = height * imageRatio;
				}

				/**
				 * If the window is narrower than the image then the image should stick to the left and right of the window.
				 */
				else
				{
					width = window.innerWidth;
					height = window.innerWidth / imageRatio;
				}

				return { width: `${width}px`, height: `${height}px` };
			},
			buttonTextColor()
			{
				return this.inLightroomEditor ? 'white' : 'grey-10';
			},
			inLightroomEditor()
			{
				// if((this.alwaysEditing && !this.disableLightroomEditor) || (this.editingState && !this.disableLightroomEditor))
				if(!this.disableLightroomEditor && (this.alwaysEditing || this.editingState))
				{
					this.$emit('open');

					return true;
				}

				return false;
			},
			inInlineEditor()
			{
				if(this.alwaysEditing && this.disableLightroomEditor) return true;

				if(this.editingState && this.disableLightroomEditor) return true;

				return false;
			},
			isEditing()
			{
				return (this.alwaysEditing || this.editingState);
			},
			topControlsDisplayCondition()
			{
				return (this.image !== '' && !(this.alwaysEditing && this.inLightroomEditor) && !(this.disableDelete && !this.previewEnabled));
			},
			absoluteSubmitButtonCondition()
			{
				return this.alwaysEditing && this.inLightroomEditor && !this.focalPointEdit;
			},
			editStateClass()
			{
				if(this.inLightroomEditor) return 'edit-mode fullscreen-edit';

				if(this.inInlineEditor) return 'edit-mode';

				return '';
			},
			isPortraitScreen()
			{
				const controlsGutterWidth = 250;
				const sliderWidth = 600;

				return this.windowInnerWidth < (sliderWidth + controlsGutterWidth * 2);
			},
			/**
			 * Determines whether the zoom slider is vertical or horizontal depending on screen and image.
			*/
			isSliderVertical()
			{
				if(!this.inLightroomEditor) return false;

				const windowRatio = this.windowInnerWidth / this.windowInnerHeight;
				const imageRatio = this.width / this.height;

				if(imageRatio <= windowRatio && !this.isPortraitScreen) return true;

				return false;
			},
			setPlaceholder()
			{
				return this.placeholder ?? this.opts?.placeholder ?? undefined;
			},
			getPlaceholderColor()
			{
				if(this.placeholderColor !== null) return this.placeholderColor;

				if(this.inLightroomEditor) return 'white';

				return 'black';
			}
		},
		watch: {
			opts: {
				handler(newVal)
				{
					if(newVal.displayWidth)
					{
						this.displayWidth = newVal.displayWidth;
					}

					if(newVal.displayHeight)
					{
						this.displayHeight = newVal.displayHeight;
					}

					if(this.setAutoSizing)
					{
						if(newVal.width)
						{
							this.width = newVal.width;

							this.calculatePreviewsPerRow(this.previewsPerRow);
						}

						if(newVal.height)
						{
							this.height = newVal.height;
						}
					}
				},
				deep: true
			},
			inLightroomEditor(newLightroomState, oldLightroomState)
			{
				this.$root.$emit('hasInnerModalAsset', newLightroomState);
				if(oldLightroomState && !newLightroomState) this.$emit('closed');
			}
		},
		async created()
		{
			/**
			 * Waits for everything to be rendered before showing the contents
			 */
			if(this.alwaysEditing)
			{
				setTimeout(() =>
				{
					this.isLoading = false;
				}, 500);
			}

			/**
			 * Needed for responsive fullscreen image size
			 */
			window.addEventListener('resize', this.onWindowResize);

			await this.doCreatedActions();
		},
		beforeDestroy()
		{
			this.doBeforeDestroyActions();

			window.removeEventListener('resize', this.onWindowResize);
		},
		methods: {
			/**
			 * Throws an error when a user tries to upload a file over the allowed file size.
			 * @param {File} file Data about the file that was rejected.
			 */
			fileSizeExceeded(file)
			{
				addError(
					this.$t('imageUploader.maxFileSizeError',
						{
							maxFileSize: displayBytesInMb(this.maxFileSize),
							fileSize: displayBytesInMb(file.size)
						})
				);
			},
			showUploader(timeout = 0)
			{
				setTimeout(() =>
				{
					this.isUploaderVisible = true;
				}, timeout);
			},
			hideUploader()
			{
				this.isUploaderVisible = false;
			},
			/**
			 * Allow a viewport update, toggle to use new image's src as initialImage (see croppa component)
			 */
			newFile()
			{
				this.editingState = true;
				this.findZoomSliderRange();
				this.newImageAdded = true;
				this.focalPointEdit = false;
			},
			updateImage: debounce(async function updateImage()
			{
				this.newImage();
				this.preventWhiteSpace = true;
			}, 300),
			findZoomSliderRange()
			{
				setTimeout(() =>
				{
					this.originalScaleRatio = this.model.scaleRatio;
					this.zoomSliderMin = this.model.scaleRatio;
					this.zoomSliderMax = this.model.scaleRatio * 10;
					if(!this.preventWhiteSpace) this.zoomSliderMin = this.model.scaleRatio / 2;
				}, 200);
			},
			/**
			 * For listener to adjust size of image when in lightroom
			 */
			onWindowResize()
			{
				this.windowInnerWidth = window.innerWidth;
				this.windowInnerHeight = window.innerHeight;
			},

			/**
			 * @typedef {Object} DataModelCanvas
			 * @property {number} height Canvas height of croppa tool
			 * @property {number} width Canvas width of croppa tool
			 */

			/**
			 * @typedef {Object} DataModel
			 * @property {number} naturalWidth Width of the original image
			 * @property {number} naturalHeight Height of the original image
			 * @property {DataModelCanvas} canvas
			 */
			/** Centre Image in Canvas of croppa plugin */
			centreImage()
			{
				/** @type {DataModel} */
				const {
					naturalWidth,
					naturalHeight,
					scaleRatio,
					canvas: {
						width: canvasWidth,
						height: canvasHeight
					}
				} = this.model;
				let startX = 0,
					startY = 0;

				const croppaImageWidth = naturalWidth * scaleRatio;
				const croppaImageHeight = naturalHeight * scaleRatio;

				startX = canvasWidth / 2 - croppaImageWidth / 2;
				startY = canvasHeight / 2 - croppaImageHeight / 2;

				this.$refs.uploader.applyMetadata({ startX, startY });
			},
			/** Setting whiteSpace for image. Creates new scaleRatio from image
			 * for zoom sliders and resets position to bring image into view */
			setWhiteSpace()
			{
				this.preventWhiteSpace = !this.preventWhiteSpace;

				/** @type {DataModel} */
				const {
					naturalWidth,
					naturalHeight,
					canvas: {
						height: canvasHeight,
						width: canvasWidth
					}
				} = this.model;
				let newScaleRatio = 0;

				/** Find out which scale to use, depending on height vs width */
				if(naturalWidth > naturalHeight)
				{
					newScaleRatio = canvasWidth / naturalWidth;
				}
				else
				{
					newScaleRatio = canvasHeight / naturalHeight;
				}

				/** If preventWhiteSpace is false, create new max and min point for zoom slider.
				 * Due to delay of croppa, custom scale is required (hence why we cannot add this function in the findZoomSliderRange)
				 */
				if(!this.preventWhiteSpace)
				{
					this.model.scaleRatio = newScaleRatio;
					this.originalScaleRatio = newScaleRatio;
					this.zoomSliderMin = newScaleRatio / 2;
					this.zoomSliderMax = newScaleRatio * 10;
					this.centreImage();
				}
				else
				{
					this.findZoomSliderRange();
				}

				this.updateSliderZoom();
			},
			/**
			 * This is so you won't be able to scroll past what the max allowed by the slider
			 */
			updateSliderZoom()
			{
				if(this.model.scaleRatio > this.zoomSliderMax) this.model.scaleRatio = this.zoomSliderMax;

				if(this.model.scaleRatio < this.zoomSliderMin) this.model.scaleRatio = this.zoomSliderMin;
			},
			zoomOut()
			{
				this.model.zoomOut();
			},
			zoomIn()
			{
				this.model.zoomIn();
			},
			showPreviews()
			{
				this.isShowingPreviews = true;
				this.focalPointEdit = false;
			},
			enableEditing()
			{
				if(this.disableLightroomEditor) this.scrollToEditor();

				this.editingState = true;
				this.isShowingPreviews = false;
				this.focalPointEdit = false;
			},
			disableEditing()
			{
				this.editingState = false;
				this.isShowingPreviews = false;
				this.focalPointEdit = false;
				this.close();
			},
			/**
			 * This is only called when alwaysEdit == true
			 */
			async submit()
			{
				if(this.alwaysEditing)
				{
					this.isSaving = true;
					await this.save(); // save method passed by the parent
					this.isSaving = false;

					return;
				}

				this.disableEditing();
			},
			/**
			 * This is so user doesn't have to scroll when scroll to zoom is enabled
			 */
			scrollToEditor()
			{
				document.getElementById(`inline-uploader-${this.id}`).scrollIntoView({
					behavior: 'smooth'
				});
			}
		},
		errorCaptured(err, vm, info)
		{
			console.warn('Croppa component error caught in InlineImageUplaoder', err);

			return !err.stack.includes('vue-croppa');
		}
	};
</script>

<style lang="postcss" scoped>
	* {
		outline: none;
	}

	.transparent {
		opacity: 0;
	}

	.hidden {
		visibility: hidden;
	}

	.inline-editor-container {
		margin-top: 10px;
	}

	.lightroom-toolbar {
		display: flex;
		align-items: flex-start;
		/* background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 40%) 100%); */
		height: 50px;
		width: 100%;
		left: 0;
		padding: 8px 11px;
		position: fixed;
		z-index: 7001;
		top: 0;

		> .lightroom-toolbar-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			> .title-delete-container {
				display: flex;
				width: 100%;
				justify-content: space-between;

				&.reverse {
					justify-content: space-between;
					flex-direction: row-reverse;
				}
			}
		}

		>>> .lightroom-title {
			color: hsl(0, 100%, 100%, 0.9);
		}

		>>> .delete-button:not(.mobile) {
			margin-right: 10px;
		}

		&.mobile {
			border-bottom: 1px solid hsl(0, 100%, 100%, 0.1);
			backdrop-filter: blur(3px);
			height: auto;
			-webkit-backdrop-filter: blur(3px);
			background: hsl(0, 0%, 0%, 0.1);
			box-shadow: 0 3px 20px 0 hsl(0, 0%, 0%, 0.3);

			>>> .lightroom-title {
				font-weight: 600;
				letter-spacing: -0.03em;
			}
		}
	}

	.choose-previews-button-container {
		display: flex;
		align-content: center;
		justify-content: center;
		padding: 15px 0;
	}

	.previews-container {
		padding: 20px;
		align-items: baseline;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.preview-modal-content {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.preview-cell {
		margin: 10px 0;
	}

	.preview-select {
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 7001;

		>>> .q-field__control::before {
			border-color: hsl(0, 100%, 100%, 0.3);
		}

		>>> .q-field__control {
			border-radius: 8px;
			box-shadow: 0 0 20px 1px hsl(0, 0%, 0%, 0.4);
		}
	}

	.preview-image-maximized {
		max-height: calc(100vh);
		max-width: 100vw;
	}

	.zoom-slider {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		opacity: 0.6;

		> .slider-track {
			transition: none;
			margin: 0 8px;
			width: 100px;

			>>> * {
				transition: none;
			}
		}

		&.fullscreen-edit {
			position: fixed;
			bottom: 20px;
			left: 50%;
			width: 600px;
			z-index: 1000;
			transform: translateX(-50%);
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			opacity: 0.6;

			> .slider-track {
				margin: 0 15px;
				width: 100%;
			}

			&.vertical {
				left: calc(100vw - 40px);
				top: 50%;
				width: 20px;
				height: 700px;
				transform: translateY(-50%);
				flex-direction: column-reverse;

				> .slider-track {
					height: 400px;
					margin: 15px 0;
				}
			}

			&.mobile-slider {
				background: hsl(0, 0%, 0%, 0.8);
				border-radius: 20px;
				padding: 0 5px;
				bottom: 80px;
				width: 70%;
			}

			&.hidden {
				visibility: hidden;
			}
		}
	}

	.inline-uploader-top-controls,
	.controls-group {
		display: flex;
		align-items: center;
	}

	.inline-uploader-top-controls {
		justify-content: space-between;
		height: 35px;

		>>> .inline-uploader-button {
			font-size: 93%;
			padding: 3px 0;
			margin: 0 7px 0 5px;
			cursor: pointer;
			border-bottom: 5px solid transparent;
			color: hsl(0, 0%, 20%);
			display: flex;
			align-items: center;

			&.delete {
				padding: 0 6px;
				margin: 1px 0;
				border: none;
				/* background-color: var(--q-negative); */

				&:hover,
				&:focus {
					opacity: 0.4;
				}

				&:active {
					opacity: 0.7;
				}
			}

			&:active {
				color: hsl(0, 0%, 40%);
			}

			&.active {
				font-weight: 700;
				color: black;
			}

			&.switch.active {
				border-color: var(--q-color-primary);
			}

			> .control-icon {
				margin-right: 3px;
			}
		}
	}

	.inline-uploader {
		box-shadow: inset 0 0 4px 1px hsl(0, 0%, 0%, 0.07);
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		overflow: hidden;
		margin-bottom: 5px;
		background: hsl(0, 0%, 96%);
		position: relative;
		border-radius: 4px;

		>>> .inline-uploader-border {
			border-radius: 4px;
			box-shadow: inset 0 0 4px 1px hsl(0, 0%, 0%, 0.07);
			z-index: 500;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: 50ms;

			> .hover-label {
				opacity: 0;
				font-size: 250%;
				color: white;
				display: block;
				text-shadow: 0 0 5px hsl(0, 0%, 0%, 0.7);
				padding: 0 20px;
				border-radius: 10px;
				transition: 50ms;
			}

			&:hover,
			&:focus {
				background: hsl(0, 0%, 0%, 0.5);
				backdrop-filter: blur(1px);

				> .hover-label {
					opacity: 1;
				}
			}
		}

		&.edit-mode.fullscreen-edit {
			position: fixed;
			width: 100vw;
			/* height value must be in `%` - iOS/Android screen height compatibility issue */
			height: 100%;
			top: 0;
			left: 0;
			background: hsl(0, 0%, 0%, 0.9);
			backdrop-filter: blur(20px);
			-webkit-backdrop-filter: blur(20px);
			border: none;
			z-index: 7000;

			&.isLoaded {
				animation: scaleIn 200ms;
			}
		}

		@keyframes fadeIn {
			from {
				transform: scale(0.9);
				opacity: 0;
			}

			80% {
				transform: scale(0.9);
				opacity: 0;
			}

			to {
				transform: scale(1);
				opacity: 1;
			}
		}

		@keyframes scaleIn {
			from {
				transform: scale(0.9);
				opacity: 0;
			}

			50% {
				opacity: 1;
			}

			to {
				transform: scale(1);
			}
		}

		&:hover,
		&:focus {
			border-color: hsl(0, 0%, 78%);
		}
	}

	.inline-uploader-external-controls {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		> .q-btn {
			margin-left: 5px;
		}
	}

	.save-button {
		position: fixed;
		bottom: 20px;
		right: 20px;
	}

	.button-container {
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 1000;

		>>> .q-btn {
			background: hsl(0, 0%, 0%, 0.5) !important;
			border: 1px solid hsl(0, 0%, 0%, 0);
			margin-left: 10px;
			z-index: 1000;

			&:hover,
			&:focus {
				border-color: hsl(0, 0%, 0%, 0.8);
				background: hsl(0, 0%, 0%, 0.4) !important;
			}
		}
	}

	.edit-controls {
		display: inline;
	}

	.no-arrow-dropdown {
		>>> .q-btn-dropdown__arrow {
			display: none !important;
		}
	}

	.croppa-container {
		background: #eee;
		display: flex;
		align-items: center;
		transition: none;
		justify-content: center;
		box-shadow: inset 0 0 4px 1px hsl(0, 0%, 0%, 0.07);
		overflow: hidden;

		&.strict-image-size {
			>>> canvas {
				border: 2px dashed hsl(0, 100%, 100%, 0.5);
				margin: 10px;
			}
		}

		&:not(.strict-image-size) {
			>>> canvas {
				width: auto !important;
				height: auto !important;
				max-height: 70vh !important;
				max-width: 100% !important;
				transition: none;
			}
		}

		&.small-canvas {
			>>> canvas {
				max-height: 40vh !important;
			}
		}

		&.transparent-background {
			background: url(~@/assets/alpha.png) !important;
			background-repeat: repeat;
		}

		&.crosshair {
			cursor: crosshair;
		}

		&.edit-mode.fullscreen-edit {
			background-color: hsl(0, 0%, 30%);
			position: fixed;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			&.transparent-background {
				background: url(~@/assets/alpha.png);
				background-repeat: repeat;
			}

			>>> canvas {
				background: none;
				transition: none;
				width: 100% !important;
				height: 100% !important;
				max-height: 100% !important;
				max-width: 100% !important;
			}
		}
	}

	.previewSize {
		&-2,
		&-3,
		&-4,
		&-5 {
			h5 {
				font-size: 1em;
				margin-bottom: 0.2em;
			}

			h6 {
				font-size: 0.8em;
			}
		}

		&-2 {
			font-size: 18px;
		}

		&-3 {
			font-size: 17px;
		}

		&-4 {
			font-size: 16px;
		}

		&-5 {
			font-size: 15px;
		}
	}

	/* Restores a hover effect to croppa */
	/* Setting the canvasColor to anything other than the default 'transparent' colours the canvas by default, hiding the hover effect */
	.hoverable {
		&::after {
			background-color: rgba(2, 123, 227, 0.2);
			bottom: 0;
			content: '';
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			right: 0;
			top: 0;
			transition: opacity 0.1s ease-out;
		}

		&:hover,
		&:focus {
			&::after {
				opacity: 1;
				transition: opacity 0.3s ease-out;
			}
		}
	}

	.previewOptionHover {
		border-radius: 4px;
		transition: background-color 0.1s ease-out;

		&:hover,
		&:focus {
			background-color: #f3f3f3;
		}
	}

	.croppa-container:hover,
	.croppa-container:focus {
		opacity: 1;
		background-color: #ddd;
		color: #fff;
	}

	.button {
		span {
			color: #fff;
			margin-right: 5px;
			text-shadow: none;
		}

		svg {
			color: #fff;
			font-size: 1.4em;
		}
	}

	.uploader {
		position: relative;
	}

	.imagePreview {
		border-radius: 4px;
		cursor: pointer;
		overflow: hidden;
		transition: transform 0.1s ease-out;

		&-hover {
			align-items: center;
			background: rgba(0, 0, 0, 0.4);
			bottom: 0;
			display: flex;
			justify-content: center;
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			right: 0;
			top: 0;
			transition: opacity 0.1s ease-out;
		}

		&:hover,
		&:focus {
			transform: scale(1.05);
			transition: transition 0.2s ease-out;

			.imagePreview-hover {
				opacity: 1;
			}
		}
	}

	.maximisedPreview {
		margin: 0 auto;
		position: relative;

		&-image {
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		&-container {
			text-align: center;
		}
	}

	.focal-point-indicator {
		background-color: rgb(255, 77, 255);
		border-radius: 50%;
		border: 3px solid rgb(144, 219, 103);
		clip: rect(0 0 0 0);
		height: 22px;
		pointer-events: none;
		position: absolute;
		transition: left ease-out 0.1s, top ease-out 0.1s;
		width: 22px;

		&.show {
			clip: auto;
		}
	}

	@media (max-width: 1000px) {
		.uploader-bottom-controls:not(.fullscreen-edit) {
			flex-direction: column;
		}

		.zoom-slider:not(.fullscreen-edit) {
			margin-top: 20px;
		}
	}

	.focal-edit-controls {
		position: fixed;
		bottom: 7px;
		right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.uploader-bottom-controls {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px 7px;
		box-shadow: inset 0 1px 0 0 hsl(0, 0%, 100%);
		border-top: 1px solid hsl(0, 0%, 88%);
		flex-wrap: wrap;

		.button {
			span {
				color: #444;
			}

			svg {
				color: #444;
			}
		}

		&.edit-mode.fullscreen-edit {
			box-shadow: none;
			border: none;
			position: fixed;
			bottom: 0;
			padding: 10px 15px;
			width: 100vw;
			align-items: flex-end;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 50%) 100%);
			height: 150px;
			/* animation: fadeIn 1s; */

			&.mobile {
				/* Height need to be fixed here otherwise position: fixed is not picked up in Android */
				height: 60px;
				padding: 5px 15px 5px 10px;
				align-items: center;
				border-top: 1px solid hsl(0, 100%, 100%, 0.15);
				backdrop-filter: blur(3px);
				-webkit-backdrop-filter: blur(3px);
				background: hsl(0, 0%, 0%, 0.1);
				box-shadow: 0 -3px 20px 0 hsl(0, 0%, 0%, 0.3);
			}
		}
	}

	@media (min-width: 1424px) {
		.block-wrapper .profile-intro-wrapper.editMode {
			padding: 20px 20px 10px 20px;
			margin-top: 0;
			border-radius: var(--roundCorners-sm);
		}
	}

	.q-menu {
		z-index: 6000;
	}

	.metadataForm {
		min-width: 300px;
	}
</style>
