<template>
	<div
		ref="container"
		class="container"
		:class="{fluid, 'q-px-xs-sm q-px-sm-md q-px-md-xl q-px-lg-xl': !fluid}"
	>
		<slot />
	</div>
</template>

<script>
	export default {
		name: 'Container',
		provide: {
			inContainer: true
		},
		inject: {
			inContainer: {
				default: false
			}
		},
		data()
		{
			return {
				fluid: false
			};
		},
		created()
		{
			this.shouldBeFluid();
		},
		methods: {
			shouldBeFluid()
			{
				this.fluid = this.inContainer;
			}
		}
	};
</script>

<style scoped>
	.container-wrapper {
		display: flex;
		justify-content: center;
		width: 100%;

		&.collapse {
			justify-content: left;
		}

		.container {
			display: block;
			width: 100%;
			position: relative;
			transition: transform 0.3s ease;

			.fluid {
				width: 100% !important;
			}
		}
	}
</style>
