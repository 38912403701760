<template>
	<div
		v-if="accountId"
		ref="shell"
		:style="{height, width}"
		class="avatar-shell"
		:alt="`${firstName} ${lastName}`"
	>
		<q-tooltip
			v-if="nameTooltip"
		>
			{{ firstName }} {{ lastName }}
		</q-tooltip>
		<div
			v-if="show"
			:style="avatarStyle"
			:class="['avatar-img', 'avatar', { 'default-style': !imageData }, defaultColor, { 'compact-style': compactView }, { 'hover-class': typeof accountId === 'string' }]"
		>
			<span
				v-if="!imageId"
				class="first-initial"
				:style="{ fontSize: `${firstInitialHeight}px` }"
			>
				{{ showInitials }}
			</span>
		</div>
		<!-- Required to display in 1:1 aspect ratio -->
		<div
			v-if="compactView"
			style="padding-top:100%"
		/>
		<InlineImageUploader
			v-if="isCurrentUser && editable && !compactView"
			ref="uploader"
			v-model="imageData"
			:title="$t('avatar.edit')"
			:autoSizing="false"
			disableMetadataEdit
			:initial="$imagePath(imageId, undefined, 'cors')"
			:fileId="imageId"
			:opts="imageUploaderOpts"
			:save="saveNewAvatar"
			:close="closeModal"
		/>
		<ConnectivityIndicator
			v-if="shouldShowOnlineIndicator"
			:accountId="accountId"
		/>
	</div>
</template>

<script>
	import { debounce } from 'quasar';
	import { post } from '@/utils/api';
	import InlineImageUploader from '@/components/form/InlineImageUploader';
	import ImageMixin from '@/components/mixins/ImageMixin';
	import ThumbnailMixin from '@/components/entities/display/ThumbnailMixin';
	import FocalSaverMixin from '@/components/mixins/FocalSaverMixin';
	import ConnectivityIndicator from '@/components/profile/ConnectivityIndicator.vue';
	import { cCurrentUserOverviewDefaults } from '@/configs/constants';

	export default {
		components: {
			InlineImageUploader,
			ConnectivityIndicator
		},
		mixins: [ImageMixin, ThumbnailMixin, FocalSaverMixin],
		props: {
			accountId: {
				type: [String, Number],
				default: null
			},
			width: {
				type: String,
				default: 'auto'
			},
			height: {
				type: String,
				default: 'auto'
			},
			thumbWidth: {
				type: [Number, Function],
				default: undefined
			},
			editable: {
				type: Boolean,
				default: false
			},
			rounded: {
				type: Boolean,
				default: false
			},
			round: {
				type: Boolean,
				default: false
			},
			compactView: {
				type: Boolean,
				default: false
			},
			show: {
				type: Boolean,
				default: true
			},
			nameTooltip: {
				type: Boolean,
				default: false
			},
			editingOn: {
				type: Function,
				default: () =>
				{}
			},
			editingOff: {
				type: Function,
				default: () =>
				{}
			},
			shouldShowOnlineIndicator: {
				type: Boolean,
				default: false
			},
			userInitials: {
				type: String,
				default: 'showFirstInitial'
			}
		},
		data()
		{
			return {
				imageData: '',
				loading: false,
				cancelLoading: null,
				firstInitialHeight: 0,
				uploaderWidth: 0,
				triggerChange: null,
				uploaderRef: 'uploader',
				actionAfterSave: this.closeModal,
				displayImageUploader: false
			};
		},
		computed: {
			imageId()
			{
				return this.$store.getters['profiles/getDataValueByPath'](this.accountId, 'avatar', 'profile');
			},
			lastInitial()
			{
				const lastN = this.lastName;

				return lastN?.substr(0, 1).toUpperCase() || '';
			},
			showInitials()
			{
				if(this.userInitials === cCurrentUserOverviewDefaults.userInitials.showFirstInitial)
				{
					return this.firstInitial;
				}

				return this.$store.getters['i18n/dynamicValue'](
					'avatar.userInitials',
					{
						firstInitial: this.firstInitial,
						lastInitial: this.lastInitial
					}
				);
			},
			firstInitial()
			{
				const firstN = this.firstName;

				return firstN?.substr(0, 1).toUpperCase() || '';
			},
			firstName()
			{
				return this.$store.getters['profiles/getDataValueByPath'](this.accountId, this.$alias('FIRSTNAME'), 'profile');
			},
			lastName()
			{
				return this.$store.getters['profiles/getDataValueByPath'](this.accountId, this.$alias('LASTNAME'), 'profile');
			},
			avatarStyle()
			{
				const radius = (this.rounded && '100px') || (this.round && '1000px') || null;

				return {
					...this.focusedImageOrEmpty,
					'border-radius': radius
				};
			},
			isCurrentUser()
			{
				return parseInt(this.accountId, 10) === parseInt(this.$store.getters['user/accountId'], 10);
			},
			defaultColor()
			{
				return `color${this.accountId % 10}`;
			},
			calculatedUploaderHeight()
			{
				// 1:1 aspect ratio
				const aspectRatio = 1 / 1;

				return this.uploaderWidth * aspectRatio;
			},
			imageUploaderOpts()
			{
				// eslint-disable-next-line no-restricted-globals
				if(!this.uploaderWidth || isNaN(this.uploaderWidth) || !this.calculatedUploaderHeight || isNaN(this.calculatedUploaderHeight)) return undefined;

				return {
					displayWidth: `${this.uploaderWidth}px`,
					displayHeight: `${this.calculatedUploaderHeight}px`
				};
			},
			/**
			 * Used directly by ImageMixin.js
			 */
			thumbSize()
			{
				// if the `this.thumbWidth` function isn't ready and there is no `this.width`, the smallest size image is fetched. There's so much refreshing going on, that once the `this.thumbWidth` _is_ ready, a new refresh will get the correct value from here and the correct image from the api
				return (this.width === 'auto' ? this.getParentSizeAndThenSome : parseInt(this.width, 10)) || this.thumbWidth;
			}
		},
		created()
		{
			if(!this.imageId)
			{
				this.$emit('setContainerShading', false);
			}
			else
			{
				this.$emit('setContainerShading', true);
			}

			this.updateInitialHeight = debounce(this.updateInitialHeight, 300);
			this.updateUploaderWidth = debounce(this.updateUploaderWidth, 300);
		},
		mounted()
		{
			this.updateInitialHeight();
			this.updateUploaderWidth();
		},
		updated()
		{
			if(!this.imageId)
			{
				this.$emit('setContainerShading', false);
			}
			else
			{
				this.$emit('setContainerShading', true);
			}

			this.updateInitialHeight();
			this.updateUploaderWidth();
		},
		methods: {
			openModal()
			{
				this.editingOn();

				if(!this.isCurrentUser || !this.editable)
				{
					return;
				}

				this.triggerChange = Date.now();
				this.$refs?.uploader && this.$refs.uploader.showUploader(500);
			},
			closeModal()
			{
				this.$refs?.uploader && this.$refs.uploader.hideUploader();
				this.editingOff();
			},
			async saveNewAvatar()
			{
				await post('user/image/avatar', { avatar: this.imageData });

				this.$store.dispatch('profiles/reloadProfile', this.accountId);
				this.focalSave = true;
			},
			updateInitialHeight()
			{
				const height = this.$refs.shell?.clientHeight;

				if(height < 100)
				{
					this.firstInitialHeight = height / 1.7;
				}
				else if(height < 320)
				{
					this.firstInitialHeight = height / 3.2;
				}
				else
				{
					this.firstInitialHeight = height / 4;
				}
			},
			updateUploaderWidth()
			{
				this.uploaderWidth = this.$refs?.placeholder?.clientWidth;
			}
		}
	};
</script>

<style scoped lang="postcss">
	img {
		display: block;
	}

	.avatar-shell {
		width: 100%;
		position: relative;
	}

	.compact-style {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	.avatar-img.hover-class {
		&:hover,
		&:focus {
			opacity: 0.7;
		}
	}

	.avatar-img {
		height: 100%;
		width: 100%;
		/*background: url("~@/assets/default/profile-avatar.png") no-repeat top center;*/
		background-size: cover;
		background-position: 50% 50%;

		&.default-style {
			align-items: center;
			display: flex;
			justify-content: center;

			/* Purple */
			&.color1 {
				background-color: var(--avatar-color1);
			}

			/* Red */
			&.color2 {
				background-color: var(--avatar-color2);
			}

			/* Green */
			&.color3 {
				background-color: var(--avatar-color3);
			}

			/* Orange */
			&.color4 {
				background-color: var(--avatar-color4);
			}

			/* Blue */
			&.color5 {
				background-color: var(--avatar-color5);
			}

			/* Pink */
			&.color6 {
				background-color: var(--avatar-color6);
			}

			/* Yellow */
			&.color7 {
				background-color: var(--avatar-color7);
			}

			/* Dark blue */
			&.color8 {
				background-color: var(--avatar-color8);
			}

			/* light purple */
			&.color9 {
				background-color: var(--avatar-color9);
			}

			/* Turquoise */
			&.color0 {
				background-color: var(--avatar-color0);
			}

			& > .first-initial {
				color: #fff;
				font-size: 90px;
			}
		}
	}

	.avatarSidebarImg {
		.avatar-img {
			border-radius: var(--roundCorners-all-md);
		}
	}

	.avatarRoundCorners .avatar-img {
		border-radius: 5px 5px;
	}

	.joiners .avatar-shell {
		width: 75px;
		height: 75px;
	}

	.dot-indicator {
		position: absolute;
		right: 0;
		bottom: 0;
		/* Using a % value to keep consistent ratio. */
		/* This Avatar component has a variety of px widths, */
		/* so a px-based indicator will have issues if generally applied. */
		width: 30%;
	}
</style>
