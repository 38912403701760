import Vue from 'vue';
import dot from 'dot-object';
import deepmerge from 'deepmerge';
import { get, post, destroy } from '@/utils/api';
import { userCan } from '@/plugins/Permissions';
import { addSuccess, addError } from '@/utils/notifications';
import { languageIsoCodesToEnglishDisplayValue } from '@/configs/constants/languages';
import { languageAdminNameForIso } from '@/utils/languages';

/** @typedef {import('../../types/store/i18n/admin').State} State */

/** @type {State} */
const state = {
	default: {},
	custom: {}, // All the languages in their raw format, this is used for editing the I18Ns in admin mode,
	editMode: false,
	languageHistoryIsLoaded: false,
	forceOutput: false, // Used by Storybook
	languagesSettingsHistory: [],
	translateProgressPerLocale: {}
};

const mutations = {
	SET_LANGUAGES(state, languages)
	{
		Object.keys(languages).forEach((iso) =>
		{
			Vue.set(state.custom, iso, languages[iso]);
		});
	},
	SET_DEFAULT(state, defaultLanguage)
	{
		Object.keys(defaultLanguage).forEach((i) =>
		{
			Vue.set(state.default, i, defaultLanguage[i]);
		});
	},
	UPDATE_KEY(state, { iso, key, value })
	{
		const tmpState = state.custom[iso];

		dot.remove(key, tmpState);
		dot.str(key, value, tmpState);
		state.custom[iso] = { ...tmpState };
	},
	UPDATE_KEYS(state, { iso, changes })
	{
		if(!state.custom[iso])
		{
			state.custom[iso] = {};
		}

		state.custom[iso] = deepmerge(state.custom[iso], dot.object(changes));
	},
	REMOVE_KEY(state, { iso, key })
	{
		const tmpState = state.custom[iso];

		dot.delete(key, tmpState);
		state.custom[iso] = { ...tmpState };
	},
	SET_EDIT_MODE(state, mode)
	{
		state.editMode = mode;
	},
	SET_FORCE_OUTPUT(state, mode)
	{
		state.forceOutput = mode;
	},
	SET_LANGUAGES_SETTINGS_HISTORY_IS_LOADED(state)
	{
		state.languageHistoryIsLoaded = true;
	},
	SET_LANGUAGES_SETTINGS_HISTORY(state, historyData)
	{
		state.languagesSettingsHistory = historyData;
	},
	SET_TRANSLATE_PROGRESS_PER_LOCALE(state, historyData)
	{
		state.translateProgressPerLocale = historyData;
	}
};

const actions = {
	async load({ dispatch, state, commit }) // Loads all languages in raw format for Admin mode
	{
		if(Object.keys(state.default).length === 0)
		{
			await dispatch('i18n/initialiseLocale', null, { root: true });

			await get('i18n/all').then((res) =>
			{
				commit('SET_LANGUAGES', res.data.custom);
				commit('SET_DEFAULT', res.data.default);
			});
		}
	},
	async saveKey({ dispatch }, { iso, value, key, successI18N })
	{
		await dispatch('load');

		const { data } = await post('i18n', [{ key, data: value }], { params: { iso } });

		if(data?.success && successI18N)
		{
			addSuccess(successI18N);
		}

		await dispatch('structure/admin/handleServerChanges', data, { root: true });
	},
	removeKey({ state, commit, dispatch, rootGetters }, { iso, key })
	{
		return destroy(`i18n/${key}`, { params: { iso } })
			.then(({ data }) =>
			{
				if(!data.success)
				{
					return;
				}

				const defaultLocale = rootGetters['i18n/localeDefault'];

				const valueToReplaceWith = dot.pick(key, state.default[defaultLocale]) ??
					dot.pick(key, state.custom[defaultLocale]);

				commit('REMOVE_KEY', { iso, key });
				dispatch(
					'i18n/updateKey',
					{
						iso,
						key,
						value: valueToReplaceWith
					},
					{ root: true }
				);
			});
	},
	async addKey({ state, dispatch, getters }, { iso, key })
	{
		const value = getters.getInitialValue(key);

		await dispatch('saveKey', { iso, value, key });
	},
	async updateKey({ dispatch, commit }, { iso, value, key })
	{
		await dispatch('load');
		commit('UPDATE_KEY', { iso, value, key });
	},
	updateKeys({ commit }, data)
	{
		if(!data || !data.iso || !data.changes) return;

		commit('UPDATE_KEYS', { iso: data.iso, changes: data.changes });
	},
	setEditMode({ commit }, mode)
	{
		commit('SET_EDIT_MODE', mode);
	},
	setForceOutput({ commit }, mode)
	{
		commit('SET_FORCE_OUTPUT', mode);
	},
	async loadLanguagesSettingsHistory({ commit, state, rootGetters }, force = false)
	{
		if(state.languageHistoryIsLoaded && !force)
		{
			return;
		}

		try
		{
			const response = await get('/i18n/languagesSettingsHistory');

			commit('SET_LANGUAGES_SETTINGS_HISTORY_IS_LOADED');
			commit('SET_LANGUAGES_SETTINGS_HISTORY', response.data.data);
		}
		catch(e)
		{
			console.error(e);
			addError(rootGetters['i18n/get']('admin.dash.settings.languages.unknownError'));
		}
	},
	setTranslateProgressForLocale({ commit, state }, { ratio, locale })
	{
		const newProgressData = {
			...state.translateProgressPerLocale,
			[locale]: ratio
		};

		commit('SET_TRANSLATE_PROGRESS_PER_LOCALE', newProgressData);
	},
	setTranslateProgressPerLocale({ commit }, progressData)
	{
		commit('SET_TRANSLATE_PROGRESS_PER_LOCALE', progressData);
	},
	async loadTranslateProgressPerLocale({ dispatch })
	{
		try
		{
			const translateProgressPerLocale = await get('/i18n/getTranslateProgressPerLocale');

			dispatch('setTranslateProgressPerLocale', translateProgressPerLocale.data.data);
		}
		catch(e)
		{
			console.error(e);
			addError(e);
		}
	},
	async toggleLanguagePublishedState({ dispatch, rootGetters }, language)
	{
		if(!Object.keys(languageIsoCodesToEnglishDisplayValue).includes(language))
		{
			addError(rootGetters['i18n/dynamicValue'](
				'admin.dash.settings.languages.unsupportedValueError', { language }
			));

			return;
		}

		const publishedLanguages = rootGetters['i18n/publishedLanguages'];

		const isRemoving = publishedLanguages.includes(language);

		try
		{
			const toggleResponse = await post('/i18n/toggleLanguagePublishedState', { language });

			if(toggleResponse.data.success)
			{
				await dispatch('loadLanguagesSettingsHistory', true);

				const i18nKey = isRemoving ? 'unpublishSuccess' : 'publishSuccess';

				addSuccess(rootGetters['i18n/dynamicValue'](`admin.dash.settings.languages.${i18nKey}`, {
					language: languageAdminNameForIso(language)
				}));

				return;
			}
		}

		catch(e)
		{
			const i18nKey = isRemoving ? 'unpublishError' : 'publishError';

			console.error(e);

			addError(rootGetters['i18n/dynamicValue'](`admin.dash.settings.languages.${i18nKey}`, {
				language: languageAdminNameForIso(language)
			}));
		}
	}
};

const getters = {
	getDefault: (state) => state.default,
	defaultLanguageKey: (state) => (key) =>
	{
		return dot.pick(key, state.default.en);
	},
	getInitialValue: (state) => (key) =>
	{
		let value = dot.pick(key, state.default.en);

		if(!value && state.custom.en)
		{
			value = dot.pick(key, state.custom.en);
		}

		return value || '';
	},
	getValues: (state) => (key) =>
	{
		if(key.substr(key.length - 1) === ' ')
		{
			console.warn('DEV! You have a space at the end of your I18N. We\'ve fixed it for now, but you should remove that ASAP');
		}

		key = key.trim();
		const data = {};

		Object.keys(state.custom).forEach((iso) =>
		{
			const value = dot.pick(key, state.custom[iso]);

			data[iso] = typeof value !== 'undefined' ? value : false;
		});

		return data;
	},
	editMode: (state) => state.editMode,
	forceOutput: (state) => state.forceOutput,
	isEditingModeEnabled: (state, getters) => getters.editMode && userCan('manageTextEdit', 'administration'),
	languagesSettingsHistory: (state) => state.languagesSettingsHistory ?? [],
	translateProgressPerLocale: (state) => state.translateProgressPerLocale ?? {}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
