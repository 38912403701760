<template>
	<div>
		<Container>
			<Block
				v-for="(blockId, index) in defaultPosition"
				:key="index"
				:data="blockId"
				v-bind="$props"
				isCurrentlyEditing
				colIndex="0"
				:index="index"
			/>
			<AddBlockButton
				:parent="data.id"
				:index="defaultPosition.length"
			/>
		</Container>
	</div>
</template>

<script>
	import BlockMixin from '@/components/blocks/BlockMixin';
	import Container from '@/components/layout/Container';
	import AddBlockButton from '@/components/admin/generic/AddBlockButton';

	export default {
		components: {
			Container,
			AddBlockButton,
			Block: () => import('@/components/blocks/Block')
		},
		mixins: [BlockMixin]
	};
</script>

<style>
</style>
