import { date, debounce } from 'quasar';
import store from '@/store';
import { uuidValidate } from '@/utils/tools';

/**
 * Sort some repeater data!
 *
 * @param {object} field - The basic field spec. Should include a sort property unless it's included in coreFieldDefaults.
 * @param {array} value - The data you wish to sort.
 * @param {string} source - Simple identifier for different sort methods.
 * @param {boolean} returnMap - Pass true to add a map to the return value. Map will be an array of integers representing the original positions, indexed by new position.
 */
export const sortRepeaterValues = (field, value, source, returnMap = false) =>
{
	// Even though core fields cannot be updated yet, you can still write over them in some capacity
	// If this field has been written over, and should have a sort option, it's possible we won't load it by default
	// Instead, load this explicitly and continue
	if(!field.sort && coreFieldDefaults.includes(field.id))
	{
		// the default field must be manually loaded before this call, otherwise it will do nothing
		field = {
			...store.getters['structure/fields/getDefault'](field.id),
			...field
		};
	}

	if(field.sort && value && Array.isArray(value))
	{
		const { fieldId: sortFieldId } = field.sort;
		let returnValue = [];

		value = [...value];

		if(returnMap)
		{
			value = addMapIndices(value);
		}

		if(uuidValidate(sortFieldId))
		{
			switch(source)
			{
				case 'store':
					returnValue = sortStoreRepeaterValues(field, value);

					break;
				case 'handlebars':
					returnValue = sortHandlebarsRepeaterValues(field, value);

					break;
				default:
					break;
			}
		}

		if(returnMap)
		{
			// Split iteration index away from sorted data
			const map = returnValue.map((iteration) => iteration.tempFieldSortingMapIndex) || [];

			// Delete iteration index from sorted data
			returnValue.forEach((iteration) =>
			{
				delete iteration.tempFieldSortingMapIndex;
			});

			return {
				data: returnValue.length ? returnValue : value,
				map
			};
		}

		return {
			data: returnValue.length ? returnValue : value
		};
	}

	return {
		data: value
	};
};

/**
 * Sort using format generally found in the profiles store.
 *
 * Example input value:
 * [
 *   { course: { _meta: { fieldId: "1234" }, value: "Physics" }, startYear: { _meta: { fieldId: "2341" }, value: "2008" }},
 *   { course: { _meta: { fieldId: "1234" }, value: "Maths" }, startYear: { _meta: { fieldId: "2341" }, value: "2012" }}
 * ]
 */
const sortStoreRepeaterValues = (field, value) =>
{
	const { sortFieldKey, direction, blankPosition } = prepareSortingData(field);

	return value.sort((a, b) => valueSort(a?.[sortFieldKey]?.value, b?.[sortFieldKey]?.value, direction, blankPosition));
};

/**
 * Sort using format generally found in handlebars options.
 *
 * Example input value:
 * [
 *   { course: "Physics", startYear: "2008" },
 *   { course: "Maths", startYear: "2012" }
 * ]
 */
const sortHandlebarsRepeaterValues = (field, value) =>
{
	const { sortFieldKey, direction, blankPosition } = prepareSortingData(field);

	return value.sort((a, b) => valueSort(a?.[sortFieldKey], b?.[sortFieldKey], direction, blankPosition));
};

/**
 * Add an index to every iteration of this array, representing its starting position
 */
const addMapIndices = (arr) => arr.map((iteration, index) => ({ ...iteration, tempFieldSortingMapIndex: index }));

/**
 * Get the key of the field we want to sort
 */
const prepareSortingData = (field) =>
{
	const { fieldId: sortFieldId, direction, blankPosition } = field.sort;
	const sortField = store.getters['structure/fields/byId'](sortFieldId);
	const sortFieldSchema = store.getters['dataSchemas/byId'](sortField.schema);

	return {
		sortFieldKey: sortFieldSchema.key.split('.').pop(),
		direction,
		blankPosition
	};
};

/**
 * Standardised sorting for field values; _all_ values must pass through this function to ensure data is sorted consistently
 * Create a new intermediary sortXValues function to prepare the data for sorting first
 */
const valueSort = (a, b, direction, blankPosition, empties = []) =>
{
	// In case the value is not a string, normalise it quick
	if(a)
	{
		a = `${a}`;
	}

	if(b)
	{
		b = `${b}`;
	}

	if(a && b)
	{
		const aExtracted = date.extractDate(a, 'YYYY-MM-DD');
		const bExtracted = date.extractDate(b, 'YYYY-MM-DD');
		let diff = date.getDateDiff(bExtracted, aExtracted);

		// It's possible the date format is wrong, date is not the only field type we use
		// Non-date fields (year, text) may be holding a year, core fields currently use text.
		// Test against YYYY extraction as a backup, and try again
		// ---
		// To clarify this, admins cannot set text fields to sort against; they can only set date and year fields
		// Text fields are technically supported, but only for the three core fields below which store years in text fields
		// The 'year' field was added long after these fields were specified
		if(diff === 0)
		{
			const aYear = date.extractDate(a, 'YYYY');
			const bYear = date.extractDate(b, 'YYYY');

			diff = date.getDateDiff(bYear, aYear);
		}

		if(direction === 'desc')
		{
			return diff < 0 ? -1 : 1;
		}

		return diff < 0 ? 1 : -1;
	}
	else if(!a && b)
	{
		// if this is basicForm and the values are being edited, make sure the new values appear at the bottom
		if(empties.includes('a'))
		{
			return 1;
		}

		switch(blankPosition)
		{
			case 'first':
				return -1;
			case 'last':
				return 1;
			default:
				return 0;
		}
	}
	else if(a && !b)
	{
		// same as above, make sure that new entries are always appended to the bottom of the repeater
		if(empties.includes('b'))
		{
			return -1;
		}

		switch(blankPosition)
		{
			case 'first':
				return 1;
			case 'last':
				return -1;
			default:
				return 0;
		}
	}

	return 0;
};

export const loadDefaultsForSorting = debounce(async (fieldIds = coreFieldDefaults) =>
{
	const filteredIds = fieldIds.filter((id) => coreFieldDefaults.includes(id));

	store.dispatch('structure/fields/loadDefaults', filteredIds);
}, 100);

export const coreFieldDefaults = [
	'bb899c91-64c3-482e-8442-fe548aea3ef6',
	'5eecab88-0d3b-4602-ab6c-fa2ddb114f6d',
	'4b5a2560-6b7f-4f43-b587-8aa01a44d60e'
];
