export default {
	data()
	{
		return {
			uploaderRef: null,
			focalSave: false
		};
	},
	watch: {
		imageId: {
			async handler(newVal, oldVal)
			{
				if(newVal !== oldVal && this.focalSave)
				{
					await this.$refs[this.uploaderRef].saveFocalPoint(newVal);

					this.focalSave = false;

					if(this.actionAfterSave)
					{
						this.actionAfterSave();
					}
				}
			},
			immediate: true
		}
	}
};
