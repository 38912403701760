import { date } from 'quasar';
import deepmerge from 'deepmerge';
import { get } from '@/utils/api';
import { uuidValidate } from '@/utils/tools';
import store from '@/store';
import {
	email as emailValidateRule,
	url as urlValidateRule
} from 'vee-validate/dist/rules.esm.js';
import {
	cRegexValidEmailAddressWithNameField,
	cRegexValidEmailAddressWithNameParentheses,
	cRegexVeeValidateEmailExtension
} from '@/configs/regex';
import Handlebars from 'handlebars';

export const allValidators = {
	is_unique: 'isUnique',
	slug_valid: 'validateSlug',
	feed_valid: 'validateFeedURL',
	date_before: 'dateBefore',
	date_after: 'dateAfter',
	max_custom: 'maxCustom',
	min_custom: 'minCustom',
	emailWithNameParentheses: 'emailWithNameParentheses',
	email: 'emailExtended',
	handlebars: 'handlebars',
	handlebarsOrURL: 'handlebarsOrURL'
};

export const isUnique = {
	getMessage: (field) => ({ errorType: 'is_unique', field }),
	validate: (value, { comparisonData = [], excludes = [] }) =>
	{
		let unique = true;

		excludes.forEach((exclude) =>
		{
			const pos = comparisonData.indexOf(exclude);

			if(pos !== -1)
			{
				comparisonData.splice(pos, 1);
			}
		});

		if(comparisonData !== null)
		{
			comparisonData.forEach((testCase) =>
			{
				if(value === testCase) unique = false;
			});
		}

		return unique;
	}
};

export const validateSlug = {
	getMessage: (field) => ({ errorType: 'slug_valid', field }),
	validate: async (value, { source, id, contextId = '' }) =>
	{
		// contextId to allow scoped checks for pages/entities
		const url = `/${source}/${(contextId !== '' ? `${contextId}/` : '')}${id}/slug/${value}/validate`;
		const isValid = await get(url);
		const { unique, number } = isValid.data;

		return !!(unique && number);
	}
};

// Request a URL to make sure a usable RSS
// feed is returned;
export const validateFeedURL = {
	getMessage: (field) => ({ errorType: 'feed_valid', field }),
	validate: async (value) =>
	{
		const res = await get(
			`/external/content/rss/url/${encodeURIComponent(value)}`
		);

		return !!res.data.items && !res.data.errno;
	}
};

export const validateNotificationOwnership = {
	getMessage: (field) => ({ errorType: 'notification_ownership', field }),
	validate: async (value, { definitionId }) =>
	{
		const definition = store.getters['entityDefinitions/byId'](definitionId);
		const notificationExists = definition?.settings?.notificationsForEntities?.find((notification) =>
		{
			return notification.id === value.value;
		});

		return !!notificationExists;
	}
};

/**
 * Find target validation dates for the given field for display purposes.
 * We get both here because getting each one individually and assigning to a "target" variable when necessary was tested and wasn't reactive.
 *
 * @param	{string} field		The id of the field we're inputting data to.
 * @return	{object}			Contains either or both before and after target dates if they exist.
 */
function getTargetDates(field)
{
	const formFieldSplit = field.split('|');
	let inputField = field,
		before,
		after;

	if(formFieldSplit.length > 1)
	{
		[, inputField] = formFieldSplit;
	}

	// grab the value we're supposed to be validating against for reference
	const { validation } = deepmerge(
		store.getters['structure/fields/byId'](inputField),
		store.getters['dataSchemas/byFieldId'](inputField)
	);

	before = validation.date_before || '';
	after = validation.date_after || '';

	if(uuidValidate(before))
	{
		const { key, source } = store.getters['dataSchemas/byFieldId'](before);

		before = store.getters['profiles/getDataValue'](store.getters['user/accountId'], key, source);
	}

	if(uuidValidate(after))
	{
		const { key, source } = store.getters['dataSchemas/byFieldId'](after);

		after = store.getters['profiles/getDataValue'](store.getters['user/accountId'], key, source);
	}

	return { before, after };
}

// Replacement for date_between for ValidationPicker
export const dateBefore = {
	getMessage: (field) => ({ errorType: 'date_before', field, vars: getTargetDates(field) }),
	validate: async (value, targetValues) => validateDate(value, targetValues, 'before')
};

export const dateAfter = {
	getMessage: (field) => ({ errorType: 'date_after', field, vars: getTargetDates(field) }),
	validate: async (value, targetValues) => validateDate(value, targetValues, 'after')
};

export const maxCustom = {
	getMessage: (field, [count]) => ({ errorType: 'max_custom', field, vars: { count } }),
	validate: (value, limit) =>
	{
		if(!Number.isNaN(value) && !Array.isArray(value))
		{
			return parseInt(value, 10) <= limit;
		}

		return value?.length <= limit;
	}
};

export const minCustom = {
	getMessage: (field, [count]) => ({ errorType: 'min_custom', field, vars: { count } }),
	validate: (value, limit) =>
	{
		if(!Number.isNaN(value) && !Array.isArray(value))
		{
			return parseInt(value, 10) >= limit;
		}

		return value?.length >= limit;
	}
};

// This function only exists because vee-validate doesn't support date before/after comparisons, only a within range comparison
// If you need a range comparison, this function shouldn't be updated to support it
async function validateDate(value, targetValues, comparison)
{
	// extract value to native JS Date and confirm it's a valid date
	// This value is extracted from the input display format
	// Value (and target later) must be parsed as strings; quasar cannot format a numeric value

	// Removed below as dates are coming in DD-MM-YYYY by default, so this is not needed
	// const formattedValue = date.formatDate(`${value}`, 'YY-MM-DD');
	let extractedValue;

	// if the above formatting doesn't work, it's likely we've accepted a date string in the display format
	// the date extractor doesn't understand the chosen display format, so let's do it explicitly
	// SIDE-NOTE: if the display format changes to something that the date formatter doesn't understand, this validation will break again
	if(typeof formattedValue === 'undefined')
	{
		extractedValue = date.extractDate(`${value}`, store.getters['app/settings/get']('date').dateFormat);
	}
	else
	{
		extractedValue = date.extractDate(value, 'DD-MM-YYYY');
	}

	if(date.isValid(extractedValue))
	{
		// extract comparison target to native JS Date
		let [target] = targetValues;

		// If the target is actually a data reference, get the value first
		if(uuidValidate(target))
		{
			const { key, source } = await store.getters['structure/fields/getSchemaForField'](target);

			target = await store.getters['profiles/getDataValue'](store.getters['user/accountId'], key, source);
		}

		if(target) // can be an empty string
		{
			// We make sure we can accept dates regardless of how they come, i.e. from a numeric field
			const formattedTarget = date.formatDate(`${target}`, 'YYYY-MM-DD');
			const extractedTarget = date.extractDate(formattedTarget, 'YYYY-MM-DD');
			// generate a date boundary for the range which is definitely not equal to the input date
			let boundary;

			if(comparison === 'before') boundary = date.addToDate(extractedValue, { days: -1 });
			else if(comparison === 'after') boundary = date.addToDate(extractedValue, { days: 1 });

			// perform comparison
			// quasar date can only check for a date range, we want to compare each individually
			return date.isBetweenDates(
				extractedValue,
				(comparison === 'before' ? boundary : extractedTarget),
				(comparison === 'after' ? boundary : extractedTarget),
				{ onlyDate: true }
			);
		}

		return true;
	}

	return false;
}

export const emailWithNameParentheses =
	{
		validate: (value) =>
		{
			/** "John Smith" <john.smith@aluminati.net> */
			const validEmailAddressWithNameParentheses =
				cRegexValidEmailAddressWithNameField.test(value) &&
				cRegexValidEmailAddressWithNameParentheses.test(value);

			/** john.smith@aluminati.net */
			const isEmailValid = emailValidateRule.validate(value) &&
				cRegexVeeValidateEmailExtension.test(value);

			return validEmailAddressWithNameParentheses || isEmailValid;
		}
	};

export const emailExtended =
	{
		validate: (value) =>
		{
			return emailValidateRule.validate(value) && (value.lastIndexOf('"') !== 0);
		}
	};

export const handlebars =
	{
		getMessage: (field) => ({ errorType: 'handlebars_valid', field }),
		validate: (value) =>
		{
			const template = Handlebars.compile(value);

			try
			{
				template({});
			}
			catch(e)
			{
				return false;
			}

			return true;
		}
	};

/**
 * Validate as _either_ a URL, or Handlebars. Without the current context, there
 * is no way to know whether the end result will be a valid URL, so we can only
 * validate either one.
 */
export const handlebarsOrURL = {
	getMessage: (field) => ({ errorType: 'handlebarsOrURL', field }),
	validate: (value) =>
	{
		if(!(new RegExp(/^([^{]*){{[A-Za-z0-9._-]*}}([^}]*)$/)).test(value))
		{
			return urlValidateRule.validate(value);
		}

		const template = Handlebars.compile(value);

		try
		{
			template({});
		}
		catch(e)
		{
			return false;
		}

		return true;
	}
};
