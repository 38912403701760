<template>
	<div class="q-pa-md">
		<h3><AdminI18N id="userControls.notificationSettings" /></h3>
		<q-list>
			<q-item>
				<q-item-section side>
					<ToggleSwitch
						:value="notificationsEnabled"
						@click="toggleNotifications"
					/>
				</q-item-section>
				<q-item-section>
					<q-item-label>
						<I18N id="userControls.enableNotifications.title" />
					</q-item-label>
					<q-item-label caption>
						<I18N id="userControls.enableNotifications.desc" />
					</q-item-label>
				</q-item-section>
			</q-item>
		</q-list>
		<!-- ADD BACK ONE PUSH COMPLETED -->
		<!-- <q-list>
							<q-item-section>
								<q-item-label>
									<I18N id="notifications.types.chat.allowPushTitle" />
									<q-toggle
										:value="pushNotificationsEnabled"
										color="green"
										@input="notificationsToggle"
									/>
								</q-item-label>
								<q-item-label caption>
									<I18N id="notifications.types.chat.allowPush" />
								</q-item-label>
							</q-item-section>
						</q-list> -->
	</div>
</template>

<script>
	import ToggleSwitch from '@/components/form/ToggleSwitch';

	export default {
		components: { ToggleSwitch },
		computed: {
			notificationsEnabled()
			{
				return this.$store.getters['user/hasNotifications'];
			}
		},
		methods: {
			toggleNotifications()
			{
				const enableNotifications = !this.$store.getters['user/hasNotifications'];

				this.$store.dispatch('user/setNotifications', enableNotifications);
			}
		}
	};
</script>
