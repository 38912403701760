<template>
	<div>
		<div
			v-for="(template, index) in templates"
			:key="`template-${index}`"
			class="lineTemplate"
		>
			<div
				v-if="getIconForTemplate(template)"
				class="mainIcon"
			>
				<q-icon
					:name="getIconForTemplate(template)"
					color="primary"
				/>
			</div>
			<div>
				<span
					v-for="(item, itemIndex) in template.template"
					:key="`template-${index}-item-${itemIndex}`"
					:class="['templateContent', { 'placeholder': !valueExists(item) }]"
				>
					<!--
						Here, if you adhere to ESLint's style guide, these inline elements will be rendered with a space either side of their content.
						The solution? I'm glad you asked. Stop using ESLint's style guide for these lines, and place each element on one line ONLY.
						Not sure why it works, but it does.
					-->
					<!-- eslint-disable -->
					<q-icon v-if="item.type === 'icon'" :name="item.value" />
					<span v-else-if="item.type === 'field' && !Array.isArray(getDataFromSchema(item))">{{ getDataFromSchema(item) }}</span>
					<q-chip v-for="(option, optionIndex) in getDataFromSchema(item)" v-else-if="item.type === 'field' && Array.isArray(getDataFromSchema(item))" :key="`template-${index}-item-${itemIndex}-option-${optionIndex}`">{{ option }}</q-chip>
					<span v-else>{{ item.value }}</span>
					<!-- eslint-enable -->
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import { date } from 'quasar';
	import deepmerge from 'deepmerge';
	import BlockMixin from '@/components/blocks/BlockMixin';
	import { fieldDefaultIcons } from '@/configs/constants';
	import { getLabelsForOptions } from '@/utils/handlebarsCache';

	export default {
		mixins: [BlockMixin],
		computed: {
			block()
			{
				return this.$store.getters['structure/blocks/getBlock'](this.id);
			},
			templates()
			{
				return this.block?.templates;
			},
			targetProfileData()
			{
				let accountId = this.targetId;

				if(!accountId)
				{
					accountId = this.$store.getters['user/accountId'];
				}

				return this.$store.getters['profiles/getSimpleData'](accountId);
			},
			allFieldsWithData()
			{
				const allFields = this.templates.reduce((agg, template) =>
				{
					const fields = template.template.reduce((agg, item) =>
					{
						if(item.type === 'field' && item.value)
						{
							const { key } = this.$store.getters['dataSchemas/byId'](item.value);

							agg[key] = this.targetProfileData[key];
						}

						return agg;
					}, {});

					return deepmerge(agg, fields);
				}, {});

				return { ...allFields, ...getLabelsForOptions(JSON.stringify(allFields), allFields) };
			}
		},
		methods: {
			getIconForTemplate(template)
			{
				const { show, value } = template.icon;

				if(show)
				{
					if(value)
					{
						return value;
					}

					const [firstField] = template.template.filter((item) => item.type === 'field' && !!item.value);

					if(firstField)
					{
						if(firstField && fieldDefaultIcons[firstField.value])
						{
							return fieldDefaultIcons[firstField.value];
						}

						const { type } = this.$store.getters['dataSchemas/byId'](firstField.value);

						if(fieldDefaultIcons.default[type])
						{
							return fieldDefaultIcons.default[type];
						}
					}

					return fieldDefaultIcons.default.fallback;
				}

				return false;
			},
			getDataFromSchema(item)
			{
				const { showDefault, defaultValue, value } = item;
				const exists = this.valueExists(item);

				if(exists)
				{
					const { key, type } = this.$store.getters['dataSchemas/byId'](value);

					if(type === 'date')
					{
						return this.formatDate(this.allFieldsWithData[key]);
					}

					return this.allFieldsWithData[key];
				}

				if(showDefault)
				{
					return defaultValue || '';
				}

				return '';
			},
			valueExists(item)
			{
				const { value, type } = item;

				if(type !== 'field')
				{
					return true;
				}

				if(value)
				{
					const { key } = this.$store.getters['dataSchemas/byId'](value);

					return !!this.allFieldsWithData[key];
				}

				return false;
			},
			formatDate(dateValue)
			{
				return date.formatDate(dateValue, this.$store.getters['app/settings/get']('date')?.dateFormat);
			}
		}
	};
</script>

<style scoped>
	.lineTemplate {
		display: flex;
		margin-bottom: 5px;
	}

	.lineTemplate:last-of-type {
		margin-bottom: 0;
	}

	.mainIcon {
		min-width: 30px;
	}

	.placeholder {
		color: var(--button-neutral);
		font-style: italic;
	}

	.templateContent {
		display: inline-block;
		white-space: pre-wrap;
	}
</style>
