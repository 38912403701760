import { get } from '@/utils/api';

const state = {
	loading: false,
	states: []
};

const mutations = {};

const actions = {
	async loadBySourcesAndTypes({ dispatch }, { sources, types })
	{
		const { data } = await get('structure/dataSchema/source', { params: { sources, types } });

		dispatch('app/handleServerResponse', data, { root: true });
	}
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
