// @ts-check
import Vue from 'vue';
import { I18NLocations } from '@/configs/constants';

const hasVariableOfType = (content, type) =>
{
	let hasEntity = false;

	if(typeof content !== 'undefined' && content !== '' && typeof type !== 'undefined' && type !== '')
	{
		const regex = new RegExp(`{${type}.[a-z0-9._-]+}`, 'gi');
		const matches = content.match(regex);

		hasEntity = !!(matches && matches.length);
	}

	return hasEntity;
};

const replaceVariables = (store, content, opts) =>
{
	if(typeof content !== 'string' || content === '' || typeof opts === 'undefined')
	{
		return content;
	}

	// get all the parts form the text that we can replace
	// Lint fail. Not escaping [ and ] breaks the build and regex.
	// eslint-disable-next-line no-useless-escape
	const replaceables = content.match(/{{?[\[\]()a-z0-9._-]+}}?/gi);

	if(replaceables !== null)
	{
		replaceables.forEach((replaceMe) =>
		{
			// strip the variable to variable name
			// const variable = replaceMe.substring(1, replaceMe.length - 1).trim();
			const variable = replaceMe.replace(/[{}]/g, '').trim();
			const value = getVariableValue(variable, store, opts);

			content = content.replace(replaceMe, value);
		});
	}

	return content;
};

function getVariableValue(variable, store, opts)
{
	// find out what the content for that variable should be
	const isFunction = variable.match(/([a-z0-9]+)\(([\s\S]+)\)+/i);
	let parts = variable.split('.'),
		whatObject = parts.shift(), // remove the first element - this allows us to use a path
		replacer = '',
		path = parts.join('.'),
		functionName = '',
		functionParam = '';

	opts.targetId = (store.state.route.params && store.state.route.params.targetId) || null;

	// For functions, e.g. {image(user.avatar.value)}
	if(isFunction)
	{
		// Name of the function (`image`)
		functionName = isFunction[1];
		// Function 'param' (user.avatar.value)
		functionParam = isFunction[2];
		parts = functionParam.split('.');
		whatObject = parts.shift();
		path = parts.join('.');
	}
	else if(parts.length > 1)
	{
		path = getRepeaterPath(parts);
	}

	if(whatObject === 'entity' && typeof opts.entityId !== 'undefined' && typeof store !== 'undefined')
	{
		if(path === '')
		{
			replacer = store.getters['entities/byId'](opts.entityId);
		}
		else
		{
			replacer = store.getters['entities/getFlatValueByPath'](opts.entityId, path);
		}
	}
	else if(whatObject === 'user' && typeof store !== 'undefined')
	{
		if(typeof opts.accountId === 'undefined')
		{
			opts.accountId = store.getters['user/accountId'];
		}

		if(path === '')
		{
			replacer = store.getters['profiles/get'](opts.accountId);
		}
		else
		{
			replacer = store.getters['profiles/getDataValueByPath'](opts.accountId, path, 'profile');
		}
	}
	else if(whatObject === 'profile' && typeof store !== 'undefined')
	{
		if(!opts.targetId)
		{
			opts.targetId = store.getters['user/accountId'];
		}

		if(path === '')
		{
			replacer = store.getters['profiles/get'](opts.targetId);
		}
		else
		{
			replacer = store.getters['profiles/getDataValueByPath'](opts.targetId, path, 'profile');
		}
	}
	else if(whatObject === 'i18n' && typeof store !== 'undefined')
	{
		replacer = store.getters['i18n/get'](path);
	}
	else if(whatObject === 'this' || whatObject === 'current')
	{
		if(path === '')
		{
			replacer = opts.current;
		}
		else
		{
			replacer = opts.current[path].value; // NOTE this might not work with repeaters
		}
	}
	// else don't replace

	if(typeof replacer === 'object' && replacer !== null)
	{
		replacer = `<pre>${JSON.stringify(replacer, null, 2)}</pre>`;
	}

	if(replacer !== '' && typeof replacer !== 'undefined' && replacer !== null)
	{
		if(isFunction)
		{
			// If this is a function, call it with the specific param (what we just got out of the data)
			return replacerFunctions[functionName](replacer);
		}

		// replace in the original content
		return replacer;
	}

	return undefined;
}

/**
 * @param {Array<string>} pathParts
 * @param {string} [path]
 * @returns {string}
 */
function getRepeaterPath(pathParts, path = '')
{
	const [repeaterKey, index, ...rest] = pathParts;
	let sanitisedIndex = index;

	// For backwards compatibility.
	if(index === 'value')
	{
		// Technically, having a trailing .value works.
		sanitisedIndex = rest.length > 0 ?
			/** @type {string} */(rest.shift()) :
			'';
	}

	// Check for handlebars style index after the value check.
	if(sanitisedIndex.includes('['))
	{
		// Lint fail. Not escaping [ and ] breaks the build and regex.
		// eslint-disable-next-line no-useless-escape
		sanitisedIndex = sanitisedIndex.replace(/[\[\]]/g, '');
	}

	path = `${
		path || ''
	}${
		path ? '.' : ''
	}${
		repeaterKey
	}.value${
		sanitisedIndex ? '.' : ''
	}${
		sanitisedIndex
	}`;

	// Array value, e.g. checkboxes. Path complete.
	if(rest.length === 0) return path;
	// Repeater field.
	else if(rest.length === 1) return `${path}.${rest[0]}`;

	// Nested repeater.
	return getRepeaterPath(rest, path);
}

const titleLocation = (type, id) =>
{
	// this is of course quite a bad way of doing this because it's annoying to use it everywhere, but I can't think of a better solution atm

	if(!type || !id)
	{
		console.error('type and id needed for titleLocation', type, id);

		return id;
	}

	if(typeof I18NLocations[type] !== 'undefined')
	{
		return `${I18NLocations[type].pre}${id}${I18NLocations[type].post}`;
	}

	console.warn('Unknown type for titleLocation:', type);

	return id;
};

const replacerFunctions = {
	image(imageId)
	{
		return Vue.prototype.$imagePath(imageId);
	}
};

export {
	hasVariableOfType,
	replaceVariables,
	getVariableValue,
	titleLocation
};
