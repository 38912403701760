import { apiInstance, instance } from '@/utils/api';
import { processStyles, getIntermediateValues } from '@/utils/designProcessors';

const getTokenFromUrl = async (store) =>
{
	const { default: URI } = await import('urijs');

	const uri = new URI(window.location.href);
	const { t: secret, s: serviceId } = uri.search(true);

	if(!secret || !serviceId)
	{
		return false;
	}

	const { data } = await instance.post('/auth/tradeSecret', { secret, serviceId });

	const token = data?.jwt;

	if(!token)
	{
		// if(path !== '/' || pathBeforeLogin === '/' || pathBeforeLogin === '/app' || pathBeforeLogin === '/app/')
		// {
		// 	localStorage.setItem('pathBeforeLogin', path);
		// }

		// setPathBeforeLogin(path);

		return false;
	}

	store.dispatch('user/login', token);
	// Preserve the badge in the URL, but remove the token values.
	const newUri = uri.query({ t: undefined, s: undefined, badge: uri.search(true)?.badge });

	window.history.replaceState({}, document.title, newUri);

	return true;
};

/**
 * If the user has already logged in, check for their token in the local storage.
 * If we find it, start the app loading and mark the user logged in. If we don't resolve false.
 * No need to reject, it's not an 'error' if the user is not logged in.
 * @returns {Promise}
 */
const startAppLoading = async (store) =>
{
	const localToken = localStorage.getItem('jwt');

	if(localToken)
	{
		// Set up the API instance to use the correct token
		apiInstance.defaults.headers.Authorization = `Bearer ${localToken}`;

		const { default: URI } = await import('urijs');
		const uri = new URI(window.location);
		const { badge } = uri.search(true);

		// Check if the framework has the same user that we do.
		if(badge)
		{
			try
			{
				await apiInstance.get('/checkFrameworkBadge', { params: { badge } });
			}
			catch
			{
				// Uh oh, different user - log them out!
				window.localStorage.removeItem('jwt');

				// Refresh
				window.location.reload();

				return false;
			}

			const newUri = uri.query('');

			window.history.replaceState({}, document.title, newUri);
		}

		store.dispatch('user/login', localToken);
		// const loadUserAndStructure = await Promise.all([
		// 	store.dispatch('user/login', localToken),
		// 	store.dispatch('user/load'),
		// 	store.dispatch('app/settings/loadSettings'),
		// 	store.dispatch('structure/load')
		// ]);

		const currentPage = store?.state?.route?.params?.pageSlug;

		const settings = await store.dispatch('app/settings/loadSettings');

		addDynamicHead(settings?.advanced?.dynamicHeadInsert?.content);

		const theme = await store.dispatch('app/loadTheme');

		await store.dispatch('app/settings/getThemeStyles');
		// find all relevant assets
		const assets = getIntermediateValues(theme, 'backgroundImage');

		if(assets && assets.length)
		{
			// try to load all relevant assets
			try
			{
				await store.dispatch('files/loadAssets', assets.map((asset) => asset.value));
			}
			catch(e)
			{
				console.error(e.message);
			}
		}

		if(!store.getters['app/isOffline'])
		{
			await store.dispatch('user/getAndSetNewImageAccessToken');
			// If the user loads the page AND we're online, remove the token so that a new one can be set. If the user is OFFLINE, then allow them to continue using the old token.
			// store.dispatch('user/removeImageAccessToken');
		}

		await store.dispatch('app/init', { pageSlug: currentPage });
		// apply theme to ACB
		insertTheme(theme);

		const locale = store.getters['user/language'];

		safeModeInit(store);

		setHtmlLanguageTag(locale);

		return true;
	}

	return false;
};

const safeModeInit = (store) =>
{
	const currentSafeMode = store.getters['app/getCurrentRoute'];

	const safeModeParam = currentSafeMode?.query?.safeMode;

	if(safeModeParam && ['on', '1', 'true'].includes(safeModeParam.toLowerCase()))
	{
		store.dispatch('admin/setSafeMode', true);
	}
};

const initialLoad = async (store) =>
{
	const tokenInUrl = await getTokenFromUrl(store);

	// Is the user already logged in? If so, we start to load the app
	const appLoaded = await startAppLoading(store);

	// If we had a token, or loaded the app, return the result.
	if(tokenInUrl || appLoaded)
	{
		return appLoaded;
	}

	store.dispatch('app/hideFullLoader');

	// Otherwise, the user was not logged in.
	throw new Error('User not logged in');
};

const addDynamicHead = (headContent) =>
{
	try
	{
		if(headContent && document.body)
		{
			const range = document.createRange();
			const fragment = range.createContextualFragment(headContent);

			document.head.appendChild(fragment);
		}
	}
	catch(e)
	{
		console.log(e);
	}
};

const insertTheme = (theme) =>
{
	const style = document.createElement('style');

	style.id = 'custom-style';

	style.setAttribute('type', 'text/css');

	const processedTheme = processStyles(theme);

	if(document.head)
	{
		document.head.insertAdjacentElement('beforeend', style);
		setThemeContent(processedTheme);
	}
};

const setThemeContent = (theme) =>
{
	const styleElement = document.getElementById('custom-style');

	styleElement.innerHTML = theme;
};

const setHtmlLanguageTag = (langCode) =>
{
	const [el] = document.getElementsByTagName('html');

	el.setAttribute('lang', langCode);
};

export default initialLoad;

export {
	setThemeContent,
	setHtmlLanguageTag,
	insertTheme
};
