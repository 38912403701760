/**
 * Checks the function in an interval; when it returns a truthy value we resolve, otherwise we resolve when the max timeout is reached.
 * @param {} fn
 * @param {*} maxWait
 * @returns
 */
export async function waitForTruthyValue(fn, maxWait = 5000)
{
	// Here we can early exit if the function is true immediately
	if(await fn()) return true;

	let interval;

	const startTimeInMillis = Date.now();

	return new Promise((resolve, reject) =>
	{
		interval = setInterval(async () =>
		{
			const result = await fn();

			if(result)
			{
				clearInterval(interval);
				resolve(true);
			}

			const iterationTimeInMillis = Date.now();

			if(iterationTimeInMillis - maxWait >= startTimeInMillis)
			{
				clearInterval(interval);
				resolve(false);
			}
		}, 100);
	});
}
