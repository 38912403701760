<template>
	<div class="row items-center no-wrap full-width">
		<!-- All visible (and invisible) tabs will be rendered here -->
		<div ref="tabsParent" class="full-width">
			<q-tabs
				id="tabs"
				ref="tabsContainer"
				align="left"
				:contentClass="[
					'navigation-overflow-list',
					'full-width',
					...customTabsBarClasses
				].join(' ')"
				:class="[
					$q.dark.isActive ? 'isDark' : 'isLight',
					{
						'no-arrows': noArrows,
						'full-width': overflowTabsToShow.length
					}
				]"
			>
				<slot v-if="useSlots" name="customTabs" />
				<q-route-tab
					v-for="tab in tabsNotOverflowing"
					:id="tab.id"
					:key="tab.id"
					:ripple="false"
					:to="routeLink(tab)"
					:class="{'flex-grow': !isRecalculating && overflowTabsToShow.length}"
				>
					<div>
						<span v-if="tab.titleValue">{{ tab.titleValue }}</span>
						<I18N v-else :id="tabI18N(tab)">
							<template #fallback>
								{{ tab.name }}
							</template>
						</I18N>
					</div>
				</q-route-tab>
			</q-tabs>
		</div>
		<!-- Dropdown to show overflowing tabs (i.e. tabs not visible) -->
		<q-btn-dropdown
			v-if="!isAdminEditMode && overflowTabsToShow.length"
			id="more-nav-items"
			ref="tabDropdown"
			transitionShow="jump-down"
			transitionHide="jump-up"
			:ripple="false"
			contentClass="more-nav-items-menu"
			autoClose
			:class="[
				'q-ml-sm',
				'more-nav-items-button',
				customTabsBarClasses,
				$q.dark.isActive ? 'isDark' : 'isLight'
			]"
			flat
			icon="far fa-ellipsis-h"
		>
			<q-tabs
				id="tabs-dropdown"
				vertical
				bordered
				class="text-left"
				:class="[
					customTabsBarClasses,
					$q.dark.isActive ? 'isDark' : 'isLight'
				]"
				:contentClass="['navigation-overflow-list', ...customOverflowMenuClasses].join(' ')"
			>
				<q-route-tab
					v-for="(tab, index) in overflowTabsToShow"
					:key="tab.id"
					v-close-popup
					:ripple="false"
					class="cursor-pointer justify-initial"
					:to="routeLink(tab)"
				>
					<q-separator v-if="index" class="custom-separator" />
					<div class="q-pl-md">
						<span v-if="tab.titleValue">{{ tab.titleValue }}</span>
						<I18N v-else :id="tabI18N(tab)">
							<template #fallback>
								{{ tab.name }}
							</template>
						</I18N>
					</div>
				</q-route-tab>
			</q-tabs>
		</q-btn-dropdown>
	</div>
</template>

<script>
	import debounce from 'lodash/debounce';

	/**
	 * @typedef {import('../../../types/components/layout/NavItemsTabsOverflow').Props} Props
	 * @typedef {import('../../../types/components/layout/NavItemsTabsOverflow').Data} Data
	 * @typedef {import('../../../types/components/layout/NavItemsTabsOverflow').Computed} Computed
	 * @typedef {import('../../../types/components/layout/NavItemsTabsOverflow').Props} Watch
	 * @typedef {import('../../../types/components/layout/NavItemsTabsOverflow').Methods} Methods
	 */

	export default {
		name: 'NavItemsTabsOverflow',
		/** @type {Props} */
		props: {
			tabs: {
				type: Array,
				required: true,
				default: () => ([])
			},
			customTabsBarClasses: {
				type: Array,
				default: () => ([])
			},
			customOverflowMenuClasses: {
				type: Array,
				default: () => ([])
			},
			currentTab: {
				type: Object,
				required: true,
				default: () => ({})
			},
			/** By default, just give this an array of items. Otherwise, display the tabs how you want. */
			useSlots: {
				type: Boolean,
				default: false
			},
			noArrows: {
				type: Boolean,
				default: false
			},
			i18nBasePath: {
				type: String,
				required: true
			},
			routeLink: {
				type: Function,
				required: true
			},
			tabIdsToShow: {
				type: Array,
				default: undefined
			}
		},
		/** @type {Data} */
		data()
		{
			return {
				tabsEligibleToShow: [],
				countOverflowingTabsDebounce: undefined,
				isRecalculating: false,
				widthMap: {}
			};
		},
		/** @type {Computed} */
		computed: {
			eligibleTabs() // all the tabs not excluded by tabIdsToShow
			{
				if(!this.tabIdsToShow)
				{
					return this.tabs;
				}

				return this.tabs.filter(
					(tab) => tab && this.tabIdsToShow.includes(tab.id)
				);
			},
			tabsNotOverflowing() // the tabs countOverflowingTabs allows
			{
				return this.useSlots ? [] : this.tabsEligibleToShow;
			},
			overflowTabsToShow() // the rest of the tabs to show
			{
				return this.eligibleTabs.filter(({ id: tabId }) => (
					(this.currentTab.id !== tabId) &&
					!this.tabsNotOverflowing.some((tab) => tab.id === tabId)
				));
			},
			isAdminEditMode()
			{
				return this.$store.getters['admin/isEditMode'];
			},
			currentLanguage()
			{
				return this.$store.getters['i18n/localeActive'];
			}
		},
		/** @type {Watch} */
		watch: {
			'$q.screen.width': {
				handler()
				{
					// debounced to avoid recalculating for every pixel resized
					this.countOverflowingTabsDebounce();
				}
			},
			tabs()
			{
				this.countOverflowingTabs();
			},
			currentTab()
			{
				this.countOverflowingTabsDebounce();
			},
			isAdminEditMode()
			{
				this.countOverflowingTabsDebounce();
			},
			tabIdsToShow()
			{
				this.countOverflowingTabsDebounce();
			},
			currentLanguage()
			{
				this.countOverflowingTabsDebounce();
			}
		},
		mounted()
		{
			this.countOverflowingTabsDebounce = debounce(this.countOverflowingTabs, 100);
			this.countOverflowingTabs();
		},
		/** @type {Methods} */
		methods: {
			setWidthMap()
			{
				const widthMap = this.$refs.tabsContainer.$children.reduce(
					(agg, tab) =>
					{
						if(tab?.$attrs?.id)
						{
							if(this.widthMap[tab.$attrs.id])
							{
								agg[tab.$attrs.id] = Math.min(
									tab.$el.offsetWidth,
									this.widthMap[tab.$attrs.id]
								);
							}
							else
							{
								agg[tab.$attrs.id] = tab.$el.offsetWidth;
							}
						}

						return agg;
					},
					{}
				);

				this.widthMap = widthMap;
			},
			async countOverflowingTabs()
			{
				if(!this.$refs.tabsContainer || this.isRecalculating)
				{
					return;
				}

				this.$set(this, 'isRecalculating', true);

				// create a list to use in the template & add all tabs to the list
				// all tabs must be added first, to calculate the flex-wrap offset
				const visibleTabs = this.eligibleTabs;

				// show all the tabs so we can get their widths
				this.tabsEligibleToShow = visibleTabs;

				// if we're on a small screen, show tabs as a continuous list; i.e., we
				// won't put anything in overflow, so no need for further computation
				if(this.$q.screen.lt.md)
				{
					this.$set(this, 'isRecalculating', false);

					return;
				}

				// wait for changes to propagate
				await this.$nextTick();

				// calculate tab widths
				this.setWidthMap();

				// whether there is no more room for tabs
				// (if tab order is to be preserved)
				let isFull = false;

				// whether the current tab has already been added
				let isCurrentTabInList = false;

				// the total width of the container, minus any existing tabs
				let availableWidth = this.$refs.tabsParent.offsetWidth - 70;

				// any tabs that can be shown go into finalTabs
				const finalTabs = [];
				// the current tab's width must be considered on each addition
				const currentTabWidth = this.widthMap[this.currentTab.id] || 0;

				visibleTabs.forEach((tab) =>
				{
					if(!tab || isFull || availableWidth < 1) return;

					const tabWidth = this.widthMap[tab.id] || 0;

					// always let the current tab in
					if(tab.id === this.currentTab.id)
					{
						isCurrentTabInList = true;
						finalTabs.push(tab);
					}
					// always leave room for the current tab
					else if(availableWidth >= (tabWidth + currentTabWidth))
					{
						availableWidth -= tabWidth;
						finalTabs.push(tab);
					}
					// NOTE: disable this if it's okay to not keep the tab order.
					// Not setting `isFull` causes a shorter tab to replace the next
					// tab in line, if the correct tab is too big for the space.
					else if(isCurrentTabInList)
					{
						isFull = true;
					}
				});

				this.tabsEligibleToShow = finalTabs;

				this.$set(this, 'isRecalculating', false);
			},
			tabI18N(tab)
			{
				if(tab?.customI18NKey) return tab.customI18NKey;

				// Can't use ?? in <template> so it must be a separate function, as
				// `tab` is sometimes `undefined`.
				return tab?.id ? `${this.i18nBasePath}.${tab.id}` : undefined;
			}
		}
	};
</script>

<style lang="postcss">
	.navigation-overflow-bar {
		display: inherit;
	}

	.q-tabs#tabs {
		max-width: 100%;
	}

	.q-tabs#tabs.no-arrows {
		overflow: hidden;

		.navigation-overflow-bar,
		.q-tabs__content {
			display: inherit;
			flex-wrap: wrap;
			height: 72.99px;
			overflow: hidden;

			&.pages {
				height: 50px;
			}
		}

		.q-tabs__arrow {
			display: none;
		}
	}

	.q-tabs#tabs-dropdown.isDark,
	.q-tabs#tabs.isDark {
		.q-list.navigation-overflow-list,
		.q-tabs__content.navigation-overflow-list {
			&.pages {
				background: var(--theme-dark-header-color-pages-backgroundColour);
				color: var(--theme-dark-header-color-pages-link);

				&.fallback-background-color {
					background: #18191a;
					color: #616161;
				}
			}

			&.modules {
				background: var(--theme-dark-header-color-background);
				color: var(--theme-dark-header-color-tabs-link);

				&.fallback-background-color {
					background: #242526;
					color: #fff;
				}
			}
		}
	}

	.q-tabs#tabs-dropdown.isLight,
	.q-tabs#tabs.isLight {
		.q-list.navigation-overflow-list,
		.q-tabs__content.navigation-overflow-list {
			&.pages {
				background: var(--header-color-pages-backgroundColour);
				color: var(--header-color-pages-link);

				&.fallback-background-color {
					background: #fff;
					color: #616161;
				}
			}

			&.modules {
				background: var(--header-color-background);
				color: var(--header-color-tabs-link);

				&.fallback-background-color {
					background: #fff;
					color: #616161;
				}
			}
		}
	}
</style>

<style scoped lang="postcss">
	.custom-separator {
		opacity: 0.3;
		background: black;
		filter: contrast(0);
	}

	.more-nav-items-menu[role='menu'] {
		background: none;
	}

	.active {
		opacity: 1;
	}

	/* stylelint-disable a11y/no-display-none */
	.more-nav-items-button {
		&.pages.isLight {
			color: var(--header-color-pages-link);
		}

		&.modules.isLight {
			color: var(--header-color-tabs-link);
		}

		&.pages.isDark {
			color: var(--theme-dark-header-color-pages-link);
		}

		&.modules.isDark {
			color: var(--theme-dark-header-color-tabs-link);
		}

		>>> .q-btn-dropdown__arrow {
			display: none;
		}
	}
	/* stylelint-enable a11y/no-display-none */

	.justify-initial {
		justify-content: initial;
	}
</style>
