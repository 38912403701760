import userLists from '@/store/userLists/admin';
import { get } from '@/utils/api';

const state = {
	active: false,
	loaded: false,
	sections: [],
	previousLocation: null,
	currentSection: null,
	adminNavExpandedOverride: null,
	adminNavExpandedStateBeingSaved: false
};

const mutations = {
	SET_ACTIVE(state, isActive)
	{
		state.active = isActive;
	},
	SET_LOADED(state)
	{
		state.loaded = true;
	},
	SET_SECTIONS(state, sections)
	{
		state.sections = sections;
	},
	PREVIOUS_LOCATION(state, location)
	{
		state.previousLocation = location;
	},
	SET_CURRENT_SECTION(state, current)
	{
		state.currentSection = current;
	},
	TOGGLE_ADMIN_NAV_EXPANDED_BEING_SAVED(state)
	{
		state.adminNavExpandedStateBeingSaved = !state.adminNavExpandedStateBeingSaved;
	},
	SET_ADMIN_NAV_EXPANDED_OVERRIDE(state, newState)
	{
		state.adminNavExpandedOverride = newState;
	}
};

const actions = {
	setActive({ commit }, isActive)
	{
		commit('SET_ACTIVE', isActive);
	},
	async load({ dispatch, commit })
	{
		await Promise.all([dispatch('loadSections')]);
		commit('SET_LOADED');
	},
	async loadSections({ commit })
	{
		const { data } = await get('admin/sections');

		commit('SET_SECTIONS', data);
	},
	previousLocation({ commit }, location)
	{
		commit('PREVIOUS_LOCATION', location);
	},
	setCurrentSection({ commit }, current)
	{
		commit('SET_CURRENT_SECTION', current);
	},
	async toggleAdminNavExpandedState({ rootGetters, dispatch, commit, getters })
	{
		// Don't do anything if a new state is being saved.
		if(getters.isAdminNavStateBeingSaved)
		{
			return true;
		}

		try
		{
			commit('TOGGLE_ADMIN_NAV_EXPANDED_BEING_SAVED');

			const currentSetting = rootGetters['user/getMetaSimple']
				?.settings?.isAdminNavExpanded ?? true;

			// optimistic update
			commit('SET_ADMIN_NAV_EXPANDED_OVERRIDE', !currentSetting);

			await dispatch(
				'profiles/saveProfileMetaData',
				{
					path: 'settings.isAdminNavExpanded',
					data: !currentSetting
				},
				{ root: true }
			);

			commit('TOGGLE_ADMIN_NAV_EXPANDED_BEING_SAVED');

			return true;
		}
		catch(err)
		{
			return false;
		}
	}
};

const getters = {
	isActive: (state) => state.active,
	isLoaded: (state) => state.loaded,
	sections: (state) => state.sections,
	section: (state, getters) => (sectionName) => getters.sections.find((section) => section === sectionName),
	previousLocation: (state) => state.previousLocation,
	currentSection: (state) => state.currentSection,
	adminNavExpandedOverride: (state) => state.adminNavExpandedOverride,
	isAdminNavStateBeingSaved: (state) => state.adminNavExpandedStateBeingSaved,
	isAdminNavExpanded: (state, getters, rootState, rootGetters) =>
	{
		if(state.adminNavExpandedStateBeingSaved)
		{
			return state.adminNavExpandedOverride;
		}

		return rootGetters['user/getMetaSimple']
			?.settings?.isAdminNavExpanded ?? true;
	}
};

const modules = {
	userLists
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
	modules
};
