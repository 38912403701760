<template>
	<div class="login">
		<transition enterActiveClass="animated fadeIn">
			<div class="row">
				<div class="col-12 text-center">
					<div v-if="loading">
						<div class="loadingSpinner">
							<h4>Loading...</h4>
							<q-circular-progress
								indeterminate
								size="50px"
								:thickness="0.22"
								color="green"
								trackColor="green-2"
							/>
						</div>
						<transition enterActiveClass="animated fadeIn">
							<div
								v-if="showHelpText && loading"
								class="loginBtnWrapper"
							>
								<div class="flex">
									<h4 class="loginBtnText">
										Taking longer than expected?
									</h4>
									<SimpleButton
										rounded
										unelevated
										color="primary"
										class="loginBtn"
										@click="login"
									>
										Try again
									</SimpleButton>
								</div>
							</div>
						</transition>
					</div>
					<div v-else>
						<SimpleButton
							rounded
							unelevated
							@click="login"
						>
							<h1>Log in</h1>
						</SimpleButton>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	// import { setPathBeforeLogin } from '@/utils/pathBeforeLogin';
	import URI from 'urijs';

	export default {
		data()
		{
			return {
				loginUrl: `${process.env.API}login`,
				displayTimeout: null,
				loading: false,
				showHelpText: false
			};
		},
		async mounted()
		{
			const loggedOut = this.$store.getters['user/loggedOut'];

			if(loggedOut)
			{
				return;
			}

			const uri = new URI(window.location);
			const { s, t } = uri.search(true);

			// We want to auto redirect the user to log in, unless they already have a token stored, or a token in the URL.
			if(!localStorage.getItem('jwt') && (!s && !t))
			{
				this.loading = true;
				this.displayTimeout = setTimeout(() =>
				{
					this.showHelpText = true;
				}, 10000);

				// window.location.href = this.loginUrl; // throws an error in tests
				// use this instead:
				const loginUrl = new URI(this.loginUrl);

				loginUrl.setQuery({ referrer: window.location.href });

				window.location.assign(loginUrl.toString());
			}
		},
		destroyed()
		{
			clearTimeout(this.displayTimeout);
		},
		methods: {
			login()
			{
				// const currentPath = this.$route.path;

				this.loading = true;
				this.displayTimeout = setTimeout(() =>
				{
					this.showHelpText = true;
				}, 5000);

				// use this instead:
				window.location.assign(this.loginUrl);
			}
		}
	};
</script>

<style scoped>
	h4 {
		font-size: 1.1rem;
		line-height: 1.3rem;
		font-family: "Source Sans Pro", sans-serif;
	}

	.login {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		background: #f1f1f1;

		& >>> .button {
			background-color: lightblue;
			font-size: 30px;
			color: #fff;
			padding: 20px;
			text-decoration: none;
			border-radius: 10px;
			border: 0;
			cursor: pointer;

			&:hover,
			&:focus {
				background-color: darkcyan;
			}
		}

		.loadingSpinner {
			margin-bottom: 30px;
		}

		.loginBtnWrapper {
			padding: 20px;
			background: #fff;
			border-radius: 16px;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.05),
				0 4px 8px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.05),
				0 16px 32px rgba(0, 0, 0, 0.05), 0 32px 64px rgba(0, 0, 0, 0.05);
		}

		.loginBtnText {
			margin-top: 8px;
			margin-right: 10px;
		}

		.loginBtn {
			font-family: "Source Sans Pro", sans-serif;
		}
	}

	body.body--dark {
		.login {
			background: inherit;

			.loginBtnWrapper {
				background: var(--q-color-dark);
			}
		}
	}
</style>
