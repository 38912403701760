import Vue from 'vue';

const state = {
	forms: {}
};

/**
 * @param formId should be `[baseFormId]-[accountId]` - baseFormId is the main form id without any repeater add-ons
 */

const mutations = {
	SET_FORM_DATA(state, { formId, data })
	{
		Vue.set(state.forms, formId, data);
	},
	SET_FORM_FIELD_DATA(state, { formId, fieldKey, data })
	{
		if(!state.forms[formId]) // can happen when adding items to repeaters - e.g. in entityDefinition settings
		{
			Vue.set(state.forms, formId, {});
		}

		const parts = fieldKey.split('.');

		if(parts.length > 1)
		{
			if(parts[0] === 'context')
			{
				// for entity's context fields
				if(!state.forms[formId].context)
				{
					Vue.set(state.forms[formId], 'context', {});
				}

				Vue.set(state.forms[formId].context, parts[1], data);
			}
			// out of luck for everything else... you figure it out

			return;
		}

		Vue.set(state.forms[formId], fieldKey, data);
	},
	REMOVE_FORM_DATA(state, mainFormId)
	{
		Object.keys(state.forms).forEach((key) =>
		{
			if(key.includes(mainFormId))
			{
				Vue.delete(state.forms, key);
			}
		});
	}
};

const actions = {
	setFormData({ commit }, { formId, data })
	{
		commit('SET_FORM_DATA', { formId, data });
	},
	setFormFieldData({ commit }, { formId, fieldKey, data })
	{
		commit('SET_FORM_FIELD_DATA', { formId, fieldKey, data });
	},
	removeFormData({ commit }, mainFormId)
	{
		commit('REMOVE_FORM_DATA', mainFormId);
	}
};

const getters = {
	getFormData: (state) => (formId) => state.forms[formId],
	getItemData: (state, getters) => (
		formId,
		fieldKey,
		repeatersKey = undefined
	) =>
	{
		if(repeatersKey || repeatersKey === 0)
		{
			return getters.getRepeaterItemData(formId, fieldKey, repeatersKey);
		}

		const form = state.forms[formId];

		const item = form?.context?.[fieldKey] || form?.[fieldKey];

		return (item && 'value' in item) ? item.value : item;
	},
	getRepeaterItemData: (state) => (
		formId,
		fieldKey,
		repeatersKey
	) =>
	{
		const form = state.forms[formId];

		const getValue = (form) => (
			form?.[
				fieldKey.split('.')[0]
			]?.[
				repeatersKey
			]?.[
				fieldKey.split('.')[1]
			]
		);

		const item = getValue(form?.context) || getValue(form);

		return (item && 'value' in item) ? item.value : item;
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
