import Vue from 'vue';
import { get, destroy } from '@/utils/api';

/**
 * @typedef {import('../../types/app').UUID} UUID
 * @typedef {import('../../types/scope').ScopeContextData} ScopeContextData
 * @typedef {import('../../types/userContent').UserCommunication} UserCommunication
 *
 * @typedef {Object} FindCommunicationParams
 * @property {UUID} targetId - id of the block the communications belong to, ie. id of the UserContentSearchContainer
 * @property {Number} page - page of results required
 * @property {Number} [limit] - number of results per page required. If not provided, `limit = 10`
 * @property {Number} [parentId] - id of the parent communication, used when loading comments
 * @property {String} [order] - order when a different sorting is required
 * @property {(UUID | Number)} [scope] - id of an entity or a used.
 * @property {ScopeContextData} [scopeContextData] - scope context data.
*/

/**
 * Incomplete for now as will be updated when adding new scope structure
 * @typedef {Object} LoadOneCommunicationParams
 * @property {UUID} targetId Block the communications belong to.
 * @property {Number} communicationId Id the communication required.
 * @property {Boolean} [force] If true, will load the communication even if it's already in the store.
 * @property {Boolean} [gettingFoloowing]
 * @property {(Number | UUID)} [scope] What communications are scoped to.
 */

export function generatePageId(targetId, parentId = null, scope = null)
{
	return `${targetId}${parentId ? `:${parentId}` : ''}${scope ? `:${scope}` : ''}`;
}

const state = {
	communications: [],
	hasMore: [],
	loadedPage: {},
	totalCommunications: {},
	idsOnPage: {}
};

const mutations = {
	UPDATE_MESSAGE(state, communication)
	{
		communication.id = parseInt(communication.id, 10);
		const existing = state.communications.findIndex((m) => parseInt(communication.id, 10) === parseInt(m.id, 10));

		if(existing > -1)
		{
			const mergedData = { ...state.communications[existing], ...communication };

			Vue.set(state.communications, existing, mergedData);
		}
		else
		{
			state.communications.push(communication);
		}
	},
	UPDATE_HAS_MORE(state, { pageId, hasMore })
	{
		const targets = new Set(state.hasMore);

		if(hasMore)
		{
			targets.add(pageId);
		}
		else
		{
			targets.delete(pageId);
		}

		Vue.set(state, 'hasMore', Array.from(targets));
	},
	SET_LOADED_PAGE(state, { pageId, page })
	{
		Vue.set(state.loadedPage, pageId, page);
	},
	SET_TOTAL_COMMUNICATIONS(state, { pageId, total })
	{
		Vue.set(state.totalCommunications, pageId, total);
	},
	REMOVE_COMMUNICATION(state, communicationId)
	{
		const index = state.communications.findIndex((comm) => comm.id === communicationId);

		if(index > -1)
		{
			Vue.delete(state.communications, index);
		}
	},
	UPDATE_SCORE(state, { communicationId, score })
	{
		const index = state.communications.findIndex((comm) => comm.id === communicationId);

		if(index > -1)
		{
			const updatedData = state.communications[index];

			updatedData.meta.score = score;

			Vue.set(state.communications, index, updatedData);
		}
	},
	UPDATE_INDIVIDUAL_REACTION_SCORES(state, { communicationId, scores })
	{
		const index = state.communications.findIndex((comm) => comm.id === communicationId);

		if(index > -1)
		{
			const updatedData = state.communications[index];

			Object.entries(scores).forEach(([reactionId, score]) =>
			{
				updatedData.meta[reactionId] = score;
			});

			Vue.set(state.communications, index, updatedData);
		}
	}
};

const actions = {
	/**
	 * Loads all communications linked to a block
	 * @param {*} storeParams
	 * @param {FindCommunicationParams} options
	 * @returns {Array<UserCommunication>} communications loaded
	 */
	async loadCommunications({ dispatch, commit, getters }, options)
	{
		let {
			targetId,
			page,
			limit = 10,
			parentId = null,
			order = null,
			scope = null,
			scopeContextData = {},
			reportedPostId = null
		} = options;

		/**
		 * UserContentSearchContainer supports multi scoping.
		 * The API can still handle scopes the old way but some scope needs to be
		 * sent (scope=null is fine, scope is overridden if scopeContextData is present).
		 * If loadCommunications is provided with  `scopeContextData` and the relevant
		 * scopeContextData information, it overrides `scope`
		 */
		if(scopeContextData?.scopeContextName === null && scopeContextData?.scopeContextId === null)
		{
			scope = null;
		}

		const nextPage = typeof page === 'number' ?
			page :
			getters.nextPage(targetId, parentId, scope);

		const params = {
			page: nextPage,
			limit,
			parentId,
			order,
			scope,
			scopeContextData,
			reportedPostId
		};

		const { data } = await get(`communications/${targetId}`, { params });

		data.items.forEach((message) =>
		{
			const pageId = generatePageId(targetId, message.id, scope);

			dispatch('updateMessage', message);
			commit('SET_TOTAL_COMMUNICATIONS', { pageId, total: message.children });
		});

		const pageId = generatePageId(targetId, parentId, scope);

		commit('UPDATE_HAS_MORE', { pageId, hasMore: data.more });
		commit('SET_LOADED_PAGE', { pageId, page: nextPage });
		commit('SET_TOTAL_COMMUNICATIONS', { pageId, total: data.total });

		return data;
	},
	/**
	 * Load one communication by ID
	 * @param {*} dispatchParams
	 * @param {LoadOneCommunicationParams} LoadOneCommunicationParams
	 * @returns {(UserCommunication | null)}
	 */
	async loadOne({ dispatch, getters, commit }, { targetId, communicationId, force = false, gettingFollowing = false, scope = null })
	{
		if(force || !getters.byId(communicationId))
		{
			try
			{
				const { data } = await get(`communications/single/${communicationId}`);

				dispatch('updateMessage', data);
				if(gettingFollowing)
				{
					const pageId = generatePageId(targetId, communicationId, scope);

					commit('SET_TOTAL_COMMUNICATIONS', { pageId, total: data.children });
				}

				return data;
			}
			catch(e)
			{
				return null;
			}
		}

		return null;
	},
	async loadMessageMeta({ commit }, { targetId, messageId })
	{
		try
		{
			const { data } = await get(`communications/single/${messageId}`);

			commit('UPDATE_MESSAGE', data);

			return data;
		}
		catch(e)
		{
			//
			return null;
		}
	},
	async updateMessage({ commit, dispatch, rootGetters }, message)
	{
		const currentUserAccountId = rootGetters['user/accountId'];

		message.own = currentUserAccountId === message.from;
		await dispatch('profiles/loadProfiles', [message.from], { root: true });
		commit('UPDATE_MESSAGE', message);
	},
	async remove({ commit }, { targetId, communicationId })
	{
		await destroy(`communications/${targetId}/${communicationId}`);

		commit('REMOVE_COMMUNICATION', communicationId);
	},
	async updatePostScore({ commit }, { communicationId, score })
	{
		commit('UPDATE_SCORE', { communicationId, score });
	},
	updatePostReactionScores({ commit }, { communicationId, scores })
	{
		commit('UPDATE_INDIVIDUAL_REACTION_SCORES', { communicationId, scores });
	},
	setTotalCommunications({ commit }, { pageId, total })
	{
		commit('SET_TOTAL_COMMUNICATIONS', { pageId, total });
	}
};

const getters = {
	all: (state) => state.communications,
	byId: (state, getters) => (id) => getters.all.find((comm) => parseInt(comm.id, 10) === parseInt(id, 10)),
	getScoreById: (state, getters) => (id) => getters.byId(id).meta.score,
	getReactionScore: (state, getters) => (id, reactionId) => getters.byId(id).meta[reactionId],
	byTargetId: (state, getters) => (targetId, includeReplies = false, scope = null) => getters.all.filter((comm) =>
	{
		if(!includeReplies)
		{
			if(comm.parentId)
			{
				return false;
			}
		}

		if(scope)
		{
			if(!comm.scope || comm.scope.toString() !== scope.toString())
			{
				return false;
			}
		}

		return comm.targetId === targetId;
	}),
	// byTargetIdAndScope: (state, getters) => (targetId, includeReplies = false, scope = null) => getters.byTargetId(targetId, includeReplies).filter((comm) => comm.scope === scope),
	currentPage: (state) => (targetId, parentId = null, scope = null) => state.loadedPage[generatePageId(targetId, parentId, scope)],
	nextPage: (state, getters) => (targetId, parentId = null, scope = null) => (
		typeof getters.currentPage(generatePageId(targetId, parentId, scope)) === 'number' ?
			getters.currentPage(generatePageId(targetId, parentId, scope)) + 1 :
			0
	),
	hasMore: (state) => (targetId, parentId = null, scope = null) => state.hasMore.includes(generatePageId(targetId, parentId, scope)),
	totalCommunications: (state) => (targetId, parentId = null, scope = null) => state.totalCommunications[generatePageId(targetId, parentId, scope)],
	replies: (state, getters) => (commId) => getters.all.filter((child) => child.parentId === commId)
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
