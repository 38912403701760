import Vue from 'vue';
import flatten from 'lodash/flatten';
import moment from 'moment';
import { get, put, post } from '@/utils/api';
import { getKeyForAlias } from '@/plugins/keyAlias';

const state = {
	chats: [],
	loading: false,
	hasLoadedAll: false,
	chatSort: 'date',
	tempChats: []
};

const mutations = {
	SET_CHAT(state, payload)
	{
		if(!payload.messages) payload.messages = [];

		const currentChatIndex = state.chats.findIndex((chat) => chat.id === payload.id);

		if(currentChatIndex > -1)
		{
			Vue.set(state.chats, currentChatIndex, payload);
		}
		else
		{
			state.chats.push(payload);
		}
	},
	ADD_MESSAGE_TO_CHAT(state, { targetId, messageId })
	{
		const chatIndex = state.chats.findIndex((chat) => chat.id === targetId);

		if(chatIndex === -1)
		{
			return;
		}

		const newMessages = [...new Set([...state.chats[chatIndex].messages, messageId])];

		Vue.set(state.chats[chatIndex], 'messages', newMessages);
	},
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	},
	SET_HAS_LOADED_ALL(state, mode)
	{
		state.hasLoadedAll = mode;
	},
	CHANGE_CHAT_SORT(state, sortType)
	{
		state.chatSort = sortType;
	},
	SET_UNREAD(state, { targetId, unreadCount })
	{
		const chatIndex = state.chats.findIndex((chat) => chat.id === targetId);

		if(chatIndex === -1)
		{
			return;
		}

		Vue.set(state.chats[chatIndex], 'unread', unreadCount);
	},
	SET_TEMP_CHATS(state, accountIds)
	{
		Vue.set(state, 'tempChats', accountIds);
	}
};

const actions = {
	async loadChats({ commit, dispatch, getters }, { force = false } = {})
	{
		if(getters.hasLoadedAll && !force) return;

		commit('SET_HAS_LOADED_ALL', true); // set first to if the user clicks any links twice, the content is still loaded only once. Any new message from a user that this user hasn't had contact with will trigger a new chat being added to the `state`, any new chat that this user starts will also be added.

		dispatch('setLoading', true);
		const { data } = await get('chat/private');

		data.forEach((chat) => commit('SET_CHAT', chat));

		const accountIds = flatten(data.map((chat) => chat.with));

		await dispatch('profiles/loadProfiles', accountIds, { root: true });

		dispatch('setLoading', false);
	},
	async loadChat({ commit, dispatch, getters }, targetId)
	{
		if(getters.byId(targetId)) return;

		dispatch('setLoading', true);

		const { data } = await get(`chat/private/${targetId}`);

		commit('SET_CHAT', data);

		commit('SET_UNREAD', { targetId, unreadCount: 0 });

		await dispatch('profiles/loadProfiles', data.with, { root: true });

		dispatch('setLoading', false);
	},
	updateChatData({ commit, dispatch, getters }, data)
	{
		const chat = getters.byId(data.id);

		if(!chat)
		{
			commit('SET_CHAT', data);
		}
		else
		{
			commit('SET_CHAT', { ...chat, ...data });
		}

		// This is to update the latest message even if we are the sender as we might be using another machine where the message has to appear
		if(data.latestMessage)
		{
			dispatch('communications/updateMessage', data.latestMessage, { root: true });
		}
	},
	markAsRead({ commit, dispatch, getters }, targetId)
	{
		get(`communications/${targetId}/markAsRead`);

		commit('SET_UNREAD', { targetId, unreadCount: 0 });
	},
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	},
	async loadMessages({ commit, dispatch, rootGetters }, {
		targetId,
		page = 0,
		limit = 10,
		reportedPostId = undefined
	})
	{
		const data = await dispatch('communications/loadCommunications', {
			targetId,
			page,
			limit,
			...(reportedPostId && { reportedPostId })
		}, { root: true });

		commit('SET_UNREAD', { targetId, unreadCount: 0 });

		return data;
	},
	// async loadMessageMeta({ commit }, { targetId, messageId })
	// {
	// 	const { data } = await get(`chat/private/${targetId}/${messageId}`);

	// 	commit('UPDATE_MESSAGE', data);
	// },
	async sendMessage({ dispatch }, { targetId, message, type, connectionId, meta = undefined })
	{
		const messageData = {
			targetId,
			message,
			type,
			connectionId,
			meta
		};

		const { data } = await put(`/chat/private/${targetId}`, messageData);

		dispatch('profiles/loadProfiles', data.with, { root: true });

		dispatch('communications/updateMessage', data, { root: true });
	},
	async sendChatMessage({ dispatch }, { targetId, message, meta, connectionId })
	{
		await dispatch('sendMessage', { type: 'chat', targetId, message, meta, connectionId });
	},
	async sendScheduleMessage({ dispatch }, { targetId, message, suggestedDates })
	{
		await dispatch('sendMessage', { targetId, message, type: 'schedule', meta: { suggestedDates } });
	},
	// addAndLinkMessage({ commit, dispatch }, message)
	// {
	// 	commit('UPDATE_MESSAGE', message);
	// 	commit('ADD_MESSAGE_TO_CHAT', { targetId: message.targetId, messageId: message.id });
	// 	dispatch('profiles/loadProfiles', message.with, { root: true });
	// },
	async createChat({ dispatch }, { toAccountId })
	{
		const { data } = await put('chat/private', { to: toAccountId });

		dispatch('updateChatData', data);

		return data;
	},
	changeChatSort({ commit }, sortBy)
	{
		commit('CHANGE_CHAT_SORT', sortBy);
	},
	async updateMessageMeta({ dispatch }, { targetId, messageId, meta, updateSentTimestamp = false })
	{
		const { data } = await post(`/communications/${targetId}/${messageId}/meta`, { meta, updateSentTimestamp });

		dispatch('communications/updateMessage', data, { root: true });
	},
	setTempChats({ commit }, accountIds)
	{
		if(Array.isArray(accountIds))
		{
			commit('SET_TEMP_CHATS', accountIds);
		}
	},
	updateTempChats({ state, commit }, accountIds)
	{
		if(Array.isArray(accountIds))
		{
			commit('SET_TEMP_CHATS', [
				...state.tempChats,
				...accountIds
			]);
		}
	}
};

const getters = {
	all: (state) => state.chats,
	sortedBy: (state) => state.chatSort,
	orderedByDate: (state, getters) => [...getters.all].sort((a, b) =>
	{
		// Force b first
		if(!a.latestMessage) return 1;

		// Retain current ordering
		if(!b.latestMessage) return -1;

		return moment(b.latestMessage.date).format('X') - moment(a.latestMessage.date).format('X');
	}),
	orderedByName: (state, getters, rootState, rootGetters) => [...getters.all].sort((a, b) =>
	{
		// TODO: This won't really work with group chats, is that OK?
		const aFirstname = rootGetters['profiles/getDataValueByPath'](a.with[0], getKeyForAlias('FIRSTNAME'), 'profile');
		const bFirstname = rootGetters['profiles/getDataValueByPath'](b.with[0], getKeyForAlias('FIRSTNAME'), 'profile');

		if(aFirstname < bFirstname) return -1;

		if(aFirstname > bFirstname) return 1;

		return 0;
	}),
	total: (state, getters) => getters.all.length,
	byId: (state, getters) => (id) => getters.all.find((chat) => chat.id === id),
	loading: (state) => state.loading,
	hasLoadedAll: (state) => state.hasLoadedAll,
	messages: (state, getters, rootState, rootGetters) => (id) => rootGetters['communications/byTargetId'](id),
	messageCount: (state, getters) => (id) => getters.messages(id).length,
	message: (state, getters, rootState, rootGetters) => (messageId) => rootGetters['communications/byId'](messageId),
	messageMeta: (state, getters, rootState, rootGetters) => (messageId) => (rootGetters['communications/byId'](messageId) && rootGetters['communications/byId'](messageId).meta) || null,
	isGroupChat: (state, getters) => (id) => getters.byId(id)?.with?.length > 1,
	messagesOrderedByDate: (state, getters) => (id) =>
	{
		const messages = getters.messages(id);

		const messagesToSort = [...messages];

		messagesToSort.sort((a, b) =>
		{
			return moment(a.date).format('X') - moment(b.date).format('X');
		});

		return messagesToSort;
	},
	/**
	 * This groups the messages into 'batches', so all the chat messages recently sent
	 * are grouped together, for example. This is common in chat applications, and is a
	 * feature that quasar supports (e.g. only the most recent message in a group shows
	 * the timestamp).
	 */
	groupedOrderedMessages: (state, getters) => (id) =>
	{
		const messages = getters.messagesOrderedByDate(id);

		return (() =>
		{
			let prevId,
				prevType;

			return messages.reduce((agg, message) =>
			{
				if(prevId === message.from && prevType === message.type)
				{
					// add message to the previous batch
					agg[agg.length - 1].push(message);
				}
				else
				{
					agg.push([message]); // yes, an array in an array
				}

				prevId = message.from;
				prevType = message.type;

				return agg;
			}, []);
		})();
	},
	tempChats: (state) => state.tempChats
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
