import Vue from 'vue';
import { pick, str, dot, object } from 'dot-object';
import deepmerge from 'deepmerge';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import startCase from 'lodash/startCase';
import { get, post, put, destroy } from '@/utils/api';
import { setItems } from '@/utils/store';
import { sortRepeaterValues } from '@/utils/fieldSorting';
import { powerUps } from '@/configs/constants';
import { getKeyForAlias } from '@/plugins/keyAlias';

const state = {
	profiles: [],
	loading: {},
	connectionStats: {},
	profileLocks: {},
	profileLoadedAtTimestamps: {},
	timers: {},
	accountIdsToLoad: []
};

const mutations = {
	INSERT_NEW_ENTRIES(state, profiles)
	{
		setItems(state, 'profiles', state.profiles, profiles, 'accountId');

		const accountIds = profiles.map(({ accountId }) => accountId);

		accountIds.forEach((id) =>
		{
			state.profileLoadedAtTimestamps[id] = Date.now();
		});
	},
	// looks deprecated in favour of `INSERT_NEW_ENTRIES` - only used in tests
	INSERT_NEW_ENTRY(state, profile)
	{
		// If this entry exists already then we need to make sure that we replace, not append.
		const currentIndex = state.profiles.findIndex((p) =>
		{
			return (parseInt(p.accountId, 10) === parseInt(profile.accountId, 10));
		});

		if(currentIndex > -1)
		{
			state.profiles.splice(currentIndex, 1, profile);
		}
		else
		{
			state.profiles.push(profile);
		}
	},
	UPDATE_PROFILE_DATA(state, { accountId, data })
	{
		// Find the index of the existing record
		const profileIndex = state.profiles.findIndex((p) =>
		{
			return parseInt(accountId, 10) === parseInt(p.accountId, 10);
		});

		if(profileIndex === -1)
		{
			return;
		}

		// Get the actual existing record
		const profile = state.profiles[profileIndex];

		const newData = { ...profile.data, ...data };

		// TODO: work out way to allow specifying of value to be NOT public

		// Make sure that all the properties outside the data object stay the same
		const newProfile = { ...profile, data: newData };

		// Put the new data back into the store
		state.profiles.splice(profileIndex, 1, newProfile);
	},
	UPDATE_PROFILE(state, { accountId, entry })
	{
		const profileIndex = state.profiles.findIndex((p) =>
		{
			return parseInt(accountId, 10) === parseInt(p.accountId, 10);
		});

		if(profileIndex === -1)
		{
			return;
		}

		const newProfile = { ...state.profiles[profileIndex], ...entry };

		state.profiles.splice(profileIndex, 1, newProfile);
	},
	REMOVE_PROFILE(state, accountId)
	{
		const profileIndex = state.profiles.findIndex((p) =>
		{
			return parseInt(accountId, 10) === parseInt(p.accountId, 10);
		});

		if(profileIndex > -1)
		{
			delete state.profileLoadedAtTimestamps[accountId];
			delete state.profileLocks[accountId];
			Vue.delete(state.connectionStats, accountId);
			Vue.delete(state.profiles, profileIndex);
		}
	},
	UPDATE_MULTI_PROFILE_DATA(state, data)
	{
		const { accountId, type, entries } = data;

		// The current index of the profile in our array. Undefined if it does not exist
		const profileIndex = state.profiles.findIndex((p) =>
		{
			return parseInt(p.accountId, 10) === parseInt(accountId, 10);
		});

		// If the profile doesn't exist, something went wrong, and we just won't do anything (for now)
		if(profileIndex > -1)
		{
			// If this array does not exist yet, then we need to make it.
			if(!state.profiles[profileIndex].data[type])
			{
				Vue.set(state.profiles[profileIndex].data, type, []);
			}

			entries.forEach((entry, index) =>
			{
				/*
				* Replace the entry in the array with the data we're given
				* It's important that we 'spread' the entry object,
				* as otherwise the model that we submitted and the state can become
				* linked, causing unexpected updates.
				*/
				state.profiles[profileIndex].data[type].splice(index, 1, { ...entry });
			});
		}
	},
	SET_LOADING(state, { accountId, loading })
	{
		Vue.set(state.loading, accountId, loading);
	},
	ADD_TO_ACCEPTED_LIST(state, { accountId, listId })
	{
		// The current index of the profile in our array. Undefined if it does not exist
		const profileIndex = state.profiles.findIndex((p) =>
		{
			return parseInt(p.accountId, 10) === parseInt(accountId, 10);
		});

		// If the profile doesn't exist, something went wrong, and we just won't do anything (for now)
		if(profileIndex > -1)
		{
			if(!state.profiles[profileIndex].acceptedUserListIds)
			{
				state.profiles[profileIndex].acceptedUserListIds = [];
			}

			if(!state.profiles[profileIndex].acceptedUserListIds.includes(listId))
			{
				state.profiles[profileIndex].acceptedUserListIds.push(listId);
			}
		}
	},
	REMOVE_FROM_ACCEPTED_LIST(state, { accountId, listId })
	{
		// The current index of the profile in our array. Undefined if it does not exist
		const profileIndex = state.profiles.findIndex((p) =>
		{
			return parseInt(p.accountId, 10) === parseInt(accountId, 10);
		});

		// If the profile doesn't exist, something went wrong, and we just won't do anything (for now)
		if(profileIndex > -1)
		{
			const index = state.profiles[profileIndex].acceptedUserListIds?.findIndex((id) => id === listId);

			if(index > -1)
			{
				state.profiles[profileIndex].acceptedUserListIds.splice(index, 1);
			}
		}
	},
	SET_CONNECTION_STATS(state, { accountId, stats })
	{
		setItems(state, 'connectionStats', state.connectionStats, { [accountId]: { stats } }, 'accountId');
	},
	SET_LOADING_CONNECTION_STATS(state, { accountId, loading })
	{
		if(state.connectionStats[accountId])
		{
			Vue.set(state.connectionStats[accountId], 'loading', loading);
		}
		else
		{
			Vue.set(state.connectionStats, accountId, { loading });
		}
	},
	SET_PROFILE_CACHE_LOCK(state, { accountId, lock, key })
	{
		if(state.profileLocks[accountId])
		{
			if(lock)
			{
				state.profileLocks[accountId][key] = true;
			}
			else
			{
				delete state.profileLocks[accountId][key];

				// If no locks, delete property to prevent build-up,
				// and so `Object.keys` doesn't need to be used when checking if a profile is locked.
				if(!Object.keys(state.profileLocks[accountId]).length)
				{
					delete state.profileLocks[accountId];
				}
			}
		}
		else if(lock)
		{
			state.profileLocks[accountId] = { [key]: true };
		}
	},
	CLEAR_PROFILE_CACHE_LOCKS_BY_KEY(state, key)
	{
		Object.entries(state.profileLocks).forEach(([accountId, lock]) =>
		{
			delete lock[key];

			// If no locks, delete property to prevent build-up,
			// and so `Object.keys` doesn't need to be used when checking if a profile is locked.
			if(!Object.keys(lock).length) delete state.profileLocks[accountId];
		});
	},
	INCLUDE_ACCOUNT_IDS_TO_LOAD(state, accountIds)
	{
		state.accountIdsToLoad.push(...accountIds);
	},
	REMOVE_ACCOUNT_IDS_FROM_LOAD(state, accountIds)
	{
		state.accountIdsToLoad = state.accountIdsToLoad.filter((accountId) => !accountIds.includes(accountId));
	}
};

const actions = {
	async loadProfile({ dispatch, getters }, accountId)
	{
		if(!getters.loading(accountId))
		{
			dispatch('loading', { accountId, loading: true });

			const accountLoaded = getters.accountLoaded(accountId);

			if(!accountLoaded)
			{
				try
				{
					const res = await get(`profile/${accountId}`);
					const profile = res.data;

					dispatch('insertNewEntry', profile);
				}
				catch(e)
				{
					if(e && e.response && e.response.data && !e.response.data.permission)
					{
						dispatch('insertNewEntry', { accountId, error: true });
					}
				}
			}

			dispatch('loading', { accountId, loading: false });
		}

		return getters.get(accountId);
	},
	async reloadProfile({ dispatch }, accountId)
	{
		try
		{
			dispatch('loading', { accountId, loading: true });

			const res = await get(`profile/${accountId}`);

			const profile = res.data;

			dispatch('insertNewEntry', profile);
		}
		catch(e)
		{
			if(e && e.response && e.response.data && !e.response.data.permission)
			{
				dispatch('insertNewEntry', { accountId, error: true });
			}
		}
		finally
		{
			dispatch('loading', { accountId, loading: false });
		}
	},
	/**
	 * Gather together all the account ids that are requested for in a short span and get them all in one request.
	 * Almost like a `debounce`, but since all requested account ids are needed, it gathers the ids together, and then requests them.
	 */
	async loadProfiles({ state, commit, getters, dispatch }, requiredAccountIds = [])
	{
		const accountIds = [];

		if(!requiredAccountIds?.length) return;

		requiredAccountIds = uniq(requiredAccountIds);

		requiredAccountIds.forEach((accountId) =>
		{
			if(!getters.loading(accountId))
			{
				const accountLoaded = getters.accountLoaded(accountId);

				if(!accountLoaded)
				{
					if(['string', 'number'].includes(typeof accountId))
					{
						accountIds.push(parseInt(accountId, 10));
						dispatch('loading', { accountId, loading: true });
					}
				}
			}
		});

		if(!accountIds?.length) return;

		clearTimeout(state.timers.loadProfiles);

		commit('INCLUDE_ACCOUNT_IDS_TO_LOAD', accountIds);

		state.timers.loadProfiles = setTimeout(() => dispatch('fetch', uniq(getters.accountIdsToLoad)), 10);
	},
	/**
	 * Don't access this function directly - use `loadProfiles` instead as that gathers any frequent requests together
	 */
	async fetch({ state, commit, dispatch, getters }, accountIds = [])
	{
		// double-checking if files are already loaded
		accountIds = accountIds?.filter((accountId) => accountId && !getters.accountLoaded(accountId));

		if(accountIds?.length === 0) return false;

		// remove the ids from the list
		commit('REMOVE_ACCOUNT_IDS_FROM_LOAD', accountIds);

		try
		{
			if(accountIds.length)
			{
				const res = await post('profile/collection', { accountIds });

				const profiles = res.data;

				// profiles.forEach((profile) =>
				// {
				// 	dispatch('insertNewEntry', profile);
				// });
				dispatch('insertNewEntries', profiles);
			}
		}
		catch(e)
		{
			if(e && e.response && e.response.data && !e.response.data.permission)
			{
				accountIds.forEach((accountId) =>
				{
					dispatch('insertNewEntry', { accountId, error: true });
				});
			}
		}
		finally
		{
			accountIds.forEach((accountId) =>
			{
				dispatch('loading', { accountId, loading: false });
			});
		}

		return true;
	},
	setProfileCacheLock({ commit }, { accountId, lock, key })
	{
		commit('SET_PROFILE_CACHE_LOCK', { accountId, lock, key });
	},
	setProfileCacheLocks({ dispatch }, { accountIds, lock, key })
	{
		accountIds.forEach((accountId) =>
		{
			dispatch('setProfileCacheLock', { accountId, lock, key });
		});
	},
	clearProfileCacheLocksByKey({ commit }, key)
	{
		commit('CLEAR_PROFILE_CACHE_LOCKS_BY_KEY', key);
	},
	loading({ commit }, { accountId, loading })
	{
		commit('SET_LOADING', { accountId, loading });
	},
	async getUserLogAction({ commit, dispatch, getters, rootGetters }, action)
	{
		const accountId = rootGetters['user/accountId'];
		const account = getters.get(accountId);

		if(!account || !account.actions || !account.actions[action])
		{
			try
			{
				const res = await get(`profile/user/actions/${action}`);
				let loggedInUsersProfile;

				switch(action)
				{
					case 'recentlyViewed':
						await dispatch('loadProfiles', res.data.actions[action]);
						loggedInUsersProfile = rootGetters['user/getProfile'];

						res.data.actions[action] = res.data.actions[action].filter((aid) => parseInt(aid, 10) !== parseInt(accountId, 10)); // remove the user's own account from the list
						if(loggedInUsersProfile._tmp && loggedInUsersProfile._tmp.recentlyViewed)
						{
							res.data.actions[action] = res.data.actions[action].filter((aid) => parseInt(aid, 10) !== parseInt(loggedInUsersProfile._tmp.recentlyViewed, 10));
							res.data.actions[action].unshift(loggedInUsersProfile._tmp.recentlyViewed);
						}

						break;
					default:
						break;
				}

				commit('UPDATE_PROFILE', { accountId, entry: res.data });
			}
			catch(e)
			{
				if(e && e.response && e.response.data && !e.response.data.permission)
				{
					commit('UPDATE_PROFILE', { accountId, entry: { actions: { [action]: false } } });
				}
			}
		}

		return getters.get(accountId);
	},
	insertNewEntry({ dispatch }, profile)
	{
		dispatch('insertNewEntries', [profile]);
	},
	insertNewEntries({ commit, getters }, profiles)
	{
		commit('INSERT_NEW_ENTRIES', profiles);
		// profiles.forEach((p) =>
		// {
		// 	return commit('INSERT_NEW_ENTRY', p);
		// });
	},
	async clearCachedProfiles(
		{
			state,
			dispatch,
			getters,
			rootGetters
		},
		maxCachedProfiles = 100
	)
	{
		maxCachedProfiles = maxCachedProfiles > 0 ? maxCachedProfiles : 0;

		let profilesToDeleteCount = maxCachedProfiles ?
				getters.all.length - maxCachedProfiles :
				getters.all.length,
			timestampIndex,
			accountId;

		if(profilesToDeleteCount <= 0) return;

		const timestamps = Object.entries(
			state.profileLoadedAtTimestamps
		).sort(
			([, aTimestamp], [, bTimestamp]) => aTimestamp - bTimestamp
		);

		while(profilesToDeleteCount && timestampIndex < timestamps.length)
		{
			accountId = timestamps[timestampIndex][0];
			timestampIndex += 1;

			if(!(
				// Bad things happen when the current user profile is removed.
				parseInt(accountId, 10) === parseInt(rootGetters['user/accountId'], 10) ||
				state.profileLocks[accountId]
			))
			{
				// eslint-disable-next-line no-await-in-loop
				await dispatch('removeItem', accountId);
				profilesToDeleteCount -= 1;
			}
		}
	},
	// Updates local
	updateValue({ commit, getters }, { accountId, data })
	{
		const profile = getters.get(accountId);

		const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
		const expandedObj = object({ [data.key]: data.value });

		const mergedData = deepmerge(profile, { data: expandedObj }, { arrayMerge: overwriteMerge });

		commit('UPDATE_PROFILE', { accountId, entry: mergedData });
	},
	deepUpdateValue({ commit, getters }, { accountId, key, value })
	{
		const profile = getters.get(accountId);

		const data = str(key, value, clone(profile));

		commit('UPDATE_PROFILE', { accountId, entry: data });
	},
	// Update the local store
	updateProfileData({ commit, getters }, profile)
	{
		const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

		commit('UPDATE_PROFILE_DATA', {
			accountId: profile.accountId,
			data: deepmerge(getters.get(profile.accountId).data, profile.data, { arrayMerge: overwriteMerge })
		});

		return profile;
	},
	// Update the local store AND post to the backend
	async saveProfileData({ dispatch, getters, rootGetters }, profile)
	{
		const newValues = {};

		if(profile.formData)
		{
			profile.data = profile.formData;
		}

		Object.entries(profile.data).forEach(([fieldId, data]) =>
		{
			if(typeof data === 'undefined' || data === null) return;

			newValues[fieldId] = typeof data.value !== 'undefined' ? data : { value: data };
		});

		profile.data = { ...newValues };

		// This is for optimistic updates
		const newValuesWithKeys = Object.entries(newValues).reduce((agg, [fieldId, value]) =>
		{
			const schema = rootGetters['structure/fields/getSchemaForField'](fieldId);
			const key = schema?.key;

			if(key)
			{
				agg[key] = value;
			}

			return agg;
		}, {});

		const optimisticProfile = cloneDeep(getters.get(profile.accountId));
		const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

		optimisticProfile.data.profile = deepmerge(optimisticProfile.data.profile, newValuesWithKeys, { arrayMerge: overwriteMerge });
		dispatch('updateProfileData', optimisticProfile);

		const { data } = await post(`profile/${profile.accountId}`, {
			blockId: profile.blockId,
			changes: profile.data
		});

		profile.data = data;
		dispatch('updateProfileData', profile);
	},
	async saveProfileMetaData({ dispatch, rootGetters }, { path, data })
	{
		const res = await post('profile/meta/upsert', { path, data });

		dispatch('updateProfileData', {
			accountId: rootGetters['user/accountId'],
			data: res.data
		});
	},
	async adminSaveProfileData({ dispatch }, profile)
	{
		const newValues = {};

		Object.entries(profile.data).forEach(([fieldId, data]) =>
		{
			if(typeof data === 'undefined' || data === null) return;

			newValues[fieldId] = typeof data.value !== 'undefined' ? data : { value: data };
		});

		profile.data = { ...newValues };

		const { data } = await post(`profile/forceUpdateProfile/${profile.accountId}`, {
			changes: profile.data
		});

		profile.data = data;
		dispatch('updateProfileData', profile);
	},
	async profileLoaded({ commit, rootGetters, dispatch }, viewingAccountId)
	{
		if(!viewingAccountId) return; // We're looking at ourselves so nothing to do

		await dispatch('loadProfile', viewingAccountId);

		put('profile/user/actions/viewedProfile', { accountId: viewingAccountId }); // Log this action to the server

		const loggedInUsersProfile = rootGetters['user/getProfile'];

		if(!loggedInUsersProfile.actions || !loggedInUsersProfile.actions.recentlyViewed) // The server hasn't replied with this data yet, so we'll store it and then save it once the server returns
		{
			commit('UPDATE_PROFILE', { accountId: loggedInUsersProfile.accountId, entry: { _tmp: { recentlyViewed: viewingAccountId } } });

			return;
		}

		const recentlyViewed = loggedInUsersProfile.actions.recentlyViewed.filter((aid) => aid !== viewingAccountId);

		recentlyViewed.unshift(viewingAccountId);
		commit('UPDATE_PROFILE', { accountId: loggedInUsersProfile.accountId, entry: { actions: { recentlyViewed } } });
	},
	async deleteProfile({ dispatch }, accountId)
	{
		await destroy(`profile/${accountId}`);
	},
	removeItem({ commit }, accountId)
	{
		commit('REMOVE_PROFILE', accountId);
	},
	async updateUsersMeta(_, { accountId, path, data })
	{
		if(!accountId || !path || typeof data === 'undefined') return;

		const payload = { path, data };

		await post(`/profile/meta/${accountId}`, payload);
	},
	addToAcceptedList({ commit }, { accountId, listId })
	{
		commit('ADD_TO_ACCEPTED_LIST', { accountId, listId });
	},
	removeFromAcceptedList({ commit }, { accountId, listId })
	{
		commit('REMOVE_FROM_ACCEPTED_LIST', { accountId, listId });
	},
	async getConnectionStats({ commit, getters }, { accountId, forceLoad })
	{
		if(
			!accountId ||
			getters.isLoadingConnectionStats(accountId) ||
			(!forceLoad && getters.getConnectionStats(accountId))
		) return;

		commit('SET_LOADING_CONNECTION_STATS', { accountId, loading: true });

		try
		{
			const { data: { stats } } = await get(`/profile/${accountId}/connectionStats`);

			commit('SET_CONNECTION_STATS', { accountId, stats });
		}
		catch(e)
		{
			console.error('Failed to load connection stats:', accountId);
		}

		commit('SET_LOADING_CONNECTION_STATS', { accountId, loading: false });
	}
};

const getters = {
	all: (state) => state.profiles,
	loading: (state) => (id) => state.loading[id],
	get: (state, getters) => (accountId) =>
	{
		return accountId ?
			getters.all.find((p) => parseInt(p.accountId, 10) === parseInt(accountId, 10)) :
			undefined;
	},
	accountIdsToLoad: (state) => state.accountIdsToLoad,
	getProfiles: (state, getters) => (accountIds) => accountIds.map((id) => getters.get(id)),
	getProfilesSimpleData: (state, getters) => (accountIds) => accountIds.map((id) => getters.getSimpleData(id, 'profile')),
	/**
	 * Re-formats the profiles 'data' to remove the nested 'object' style and make it key: value.
	 * E.g., { firstN: { value: 'James', public: true } } becomes { firstN: 'James' }
	 */
	getSimpleData: (state, getters, rootState, rootGetters) => (accountId, source = 'profile') =>
	{
		const profile = getters.get(accountId);

		if(!profile || profile.error)
		{
			return null;
		}

		if(profile.data === null || profile.data[source] === undefined || !profile.data[source])
		{
			return {};
		}

		const keys = Object.keys(dot(profile.data[source]));

		const simpleData = object(
			Object.entries(dot(profile.data[source])).reduce((agg, [key, value]) =>
			{
				// In 'simple data' we don't care about the '_privacy' value, so just ignore it entirely
				// The backend will decide if it's needed or not.
				if(key.includes('_privacy') || key.includes('_meta')) return agg;

				// make sure that we aren't causing `dot.object` to fail after we've removed all `.value`-s
				// this happens with the initial multi-select data from a form, not the data returned after a POST
				if(key.lastIndexOf('].value') !== key.length - 7)
				{
					// check if the current key ends in something that also ends in `.value` in the `dottedData`'s keys
					const pos = key.lastIndexOf('].');

					if(pos > -1) // while the position would be at least more than 3, let's just keep it at the usual "if exists" check
					{
						const sub = key.substring(0, pos + 2);

						if(keys.includes(`${sub}value`)) // skip anything that will break the array in `dot.object`
						{
							return agg;
						}
					}
				}

				const newKey = key.replace(/\.value\b/g, '');

				agg[newKey] = value;

				return agg;
			}, {})
		);

		// find out which of the fields prepared to return are repeaters
		return Object.entries(simpleData).reduce((agg, [fieldKey, fieldData]) =>
		{
			const schema = rootGetters['dataSchemas/byKey'](fieldKey);

			if(schema?.type === 'repeater')
			{
				const field = rootGetters['structure/fields/getFieldBySchemaId'](schema.id);

				if(field)
				{
					agg[fieldKey] = sortRepeaterValues(field, fieldData, 'handlebars').data;
				}
				else
				{
					agg[fieldKey] = fieldData;
				}
			}
			else
			{
				agg[fieldKey] = fieldData;
			}

			return agg;
		}, {});
	},
	getName: (state, getters, rootState, rootGetters) => (accountId, nameAliases = ['FIRSTNAME', 'LASTNAME'], opts = {
		initialsOnly: false,
		generateFakeName: false
	}) =>
	{
		if(opts?.generateFakeName)
		{
			const {
				adjectives,
				nouns
			} = rootGetters['i18n/get']('admin.exampleWords');

			return `${
				startCase(adjectives[Math.floor(Math.random() * adjectives.length)])
			} ${
				startCase(nouns[Math.floor(Math.random() * nouns.length)])
			}`;
		}

		const names = nameAliases.map((alias) => getters.getDataValueByPath(accountId, getKeyForAlias(alias))).filter((name) => name);

		if(opts?.initialsOnly)
		{
			return names.map((name) => name.slice(0, 1)).join(' ');
		}

		return names.join(' ');
	},
	getDataValue: (state, getters) => (accountId, value, source = 'profile') =>
	{
		const data = getters.getSimpleData(accountId, source);

		return typeof data[value] === 'undefined' ? null : data[value];
	},
	getDataObjectByPath: (state, getters, rootState, rootGetters) => (accountId, path, source = 'profile') =>
	{
		const profile = getters.get(accountId);

		if(!profile)
		{
			return '';
		}

		source = source === 'profileMeta' ? 'meta' : source;
		path = `${source}.${path}`;
		const data = pick(path, profile.data);

		if(!data || !path)
		{
			return null;
		}

		const schema = rootGetters['dataSchemas/byKey'](path.split('.').pop());

		if(schema?.id && schema.type === 'repeater')
		{
			const sortedValue = sortRepeaterValues(rootGetters['structure/fields/getFieldBySchemaId'](schema.id), data.value, 'store').data;

			return {
				...data,
				value: sortedValue
			};
		}

		return data;
	},
	getDataValueByPath: (state, getters) => (accountId, path, source = 'profile') =>
	{
		const data = getters.getDataObjectByPath(accountId, path, source);

		return data && Object.prototype.hasOwnProperty.call(data, 'value') ? data.value : data;
	},
	// getDataPrivacyByPath: (state, getters) => (accountId, path, source = 'profile') =>
	// {
	// 	const data = getters.getDataObjectByPath(accountId, path, source);

	// 	return data && Object.prototype.hasOwnProperty.call(data, '_privacy') ? data._privacy : 'public';
	// },
	accountLoaded: (state, getters) => (accountId) =>
	{
		const profile = getters.get(accountId);

		if(!profile)
		{
			return false;
		}

		return !profile.partial;
	},
	getLabelValueForSelectedOptionForAlias: (state, getters, rootState, rootGetters) => (accountId, alias) =>
	{
		const path = rootGetters['app/settings/mappedKeyForAlias'](alias);
		const value = rootGetters['profiles/getDataValueByPath'](accountId, path);
		const schemaId = this.$store.getters['app/settings/keyForAlias'](alias);
		const field = this.$store.getters['structure/fields/getFieldBySchemaId'](schemaId);

		if(!field || !value || !path || !schemaId)
		{
			return null;
		}

		return rootGetters['i18n/get'](`custom.fields.${field.id}.options.${value}`);
	},
	isProfileAcceptedInList: (state, getters) => (accountId, userListId) =>
	{
		const profile = getters.get(accountId);

		if(!profile) return false;

		return profile.acceptedUserListIds?.includes(userListId);
	},
	canStartChat: (state, getters) => (accountId) => !!getters.get(accountId)?._canStartChat,
	canStartVideoChat: (state, getters, rootState, rootGetters) => (
		accountId
	) => !!rootGetters['app/settings/get']('videoChat')?.isEnabled &&
		!!getters.get(accountId)?._canStartVideoChat,
	getConnectionStats: (state) => (accountId, entityId, connectionTypeId) =>
	{
		const stats = state.connectionStats[accountId]?.stats;

		if(stats && entityId)
		{
			return connectionTypeId ? stats[entityId]?.[connectionTypeId] : stats[entityId];
		}

		return stats;
	},
	isLoadingConnectionStats: (state) => (accountId) => !!state.connectionStats[accountId]?.loading,
	getConnectionServices: (state, getters, rootState, rootGetters) => (accountId, entityId, connectionTypeId) =>
	{
		const connectionServices = rootGetters['entities/getPowerUpData'](entityId, powerUps.connectionServices)?.connectionServices;
		const offeredServices = getters.getDataValueByPath(accountId, `connections.${connectionTypeId}.offeredServices`, 'meta');

		if(!(connectionServices?.length && offeredServices && Object.keys(offeredServices).length))
		{
			return [];
		}

		// include request counts, if available - use 'powerUps/getServiceRequestCounts' dispatch to load data
		const serviceRequestCounts = rootGetters['powerUps/getServiceRequestCounts'](accountId, connectionTypeId) || {};

		return connectionServices.reduce((services, service) =>
		{
			const intersectedService = offeredServices[service.id];

			if(intersectedService)
			{
				services.push({
					...service,
					...intersectedService,
					requestCount: serviceRequestCounts[service.id] || 0
				});
			}

			return services;
		}, []);
	},
	getRequestedConnectionServices: (state, getters, rootState, root) => (accountId, entityId, connectionTypeId, targetAccountId) =>
	{
		/** The `offeredServices` are reduced to those still available for the entity connection. */
		const offeredServices = getters.getConnectionServices(targetAccountId, entityId, connectionTypeId);
		const requestedServices = getters.getDataValueByPath(accountId, `connections.${connectionTypeId}.requestedServices.profileId-${targetAccountId}`, 'meta');

		/** Reduce the `requestedServices` to those still offered. */
		return offeredServices.reduce((services, { id: serviceId }) =>
		{
			if(requestedServices[serviceId])
			{
				services[serviceId] = true;
			}

			return services;
		}, {});
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
