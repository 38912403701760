import store from '@/store';
import { generateImagePath } from '@/plugins/imagePath';

export function getFocalPointRaw(fileId)
{
	const file = store.getters['files/byId'](fileId);

	return file?.data?.focalPos;
}

export function getFocalPointForFile(fileId, returnImage = false, returnSize = false, thumb = false, breakCache = false, mode = undefined, thumbWidth = undefined)
{
	const pos = getFocalPointRaw(fileId);
	let imageURL;

	if(returnImage)
	{
		imageURL = generateImagePath(fileId, thumb, thumbWidth, breakCache, mode);
	}

	return generateFocusStyle(`url(${imageURL})`, pos?.x, pos?.y, returnImage, returnSize);
}

export function generateFocusedImage(imageData, focalX = 50, focalY = 50)
{
	return generateFocusStyle(imageData, focalX, focalY);
}

function generateFocusStyle(imageData, focalX = 50, focalY = 50, returnImage = true, returnSize = true)
{
	return {
		...(returnImage && { 'background-image': imageData }),
		'background-position-x': `${focalX}%`,
		'background-position-y': `${focalY}%`,
		...(returnSize && { 'background-size': 'cover' })
	};
}

export function getFocalPointString(fileId, image, size)
{
	return stringifyCSSObject(getFocalPointForFile(fileId), image, size);
}

function stringifyCSSObject(data)
{
	return Object.entries(data).reduce((agg, [propertyName, value]) =>
	{
		agg.push(`${propertyName}:${value}`);

		return agg;
	}, []).join(';');
}
