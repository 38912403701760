const SchemaValue = {
	methods: {
		dataSchemaValue(field, dataSchema)
		{
			let { value } = field,
				entityDefinition;

			switch(dataSchema.source)
			{
				case 'profile':
				case 'profileMeta':
					if(dataSchema.key)
					{
						value = this.$store.getters['profiles/getDataValueByPath'](this.accountToUse, dataSchema.key, dataSchema.source);
					}

					break;
				case 'entity':
					if(dataSchema.key)
					{
						value = this.$store.getters['entities/getContextValueByPath'](this.entityId ?? this.targetId, dataSchema.key);
					}

					break;
					// These are special fields that can manipulate the properties of an entity
				case 'entityProperty':
					if(dataSchema.key && dataSchema.type !== 'i18n')
					{
						value = this.$store.getters['entities/getValueByPath'](this.entityId ?? this.targetId, dataSchema.key);
					}
					else if(dataSchema.key)
					{
						value = this.$store.getters['i18n/get'](`custom.entities.${this.entityId ?? this.targetId}.${dataSchema.key}`);
					}

					break;
				case 'entityPowerUp':
					if(dataSchema.key)
					{
						const powerUps = this.$store.getters['powerUps/getPowerUps'];

						const powerUpData = powerUps.find((powerUp) => powerUp.fields.includes(field.id));

						value = this.nestedValue ? this.$store.getters['entities/getValueByPath'](this.entityId ?? this.targetId, `powerUpData[${powerUpData.id}][${this.keyToGet}][${this.nestedValue}].${dataSchema.key}`) :
							this.$store.getters['entities/getValueByPath'](this.entityId ?? this.targetId, `powerUpData[${powerUpData.id}].${dataSchema.key}`);
					}

					break;
				case 'entitySettings':
					entityDefinition = this.$store.getters['entityDefinitions/byId'](this.idToUse);
					if(entityDefinition && entityDefinition.settings)
					{
						value = entityDefinition.settings[`${dataSchema.key}`];
					}

					break;
				case 'entityImage':
					if(dataSchema.key)
					{
						value = this.$store.getters['entities/getFlatValueByPath'](this.entityId ?? this.targetId, dataSchema.key);
					}

					break;
					// This is so we can have i18n values in forms
				case 'i18n':
					if(dataSchema.key)
					{
						value = this.$store.getters['i18n/dynamicValue'](dataSchema.key, { id: this.entityId ?? this.targetId });
					}

					break;
				case 'itemApplicationForm':
					if(dataSchema.key)
					{
						value = this.$store.getters['applications/getValueByPath'](this.idToUse, dataSchema.key);
					}

					break;
				default:
					// throw new Error('Unknown schema source');
					if(this.customFieldValues)
					{
						if(typeof this.customFieldValues[dataSchema.key] !== 'undefined')
						{
							value = this.customFieldValues[dataSchema.key];
						}
						else if(typeof this.customFieldValues[field.id] !== 'undefined')
						{
							value = this.customFieldValues[field.id];
						}
					}

					break;
			}

			return value;
		}
	}
};

export default SchemaValue;
