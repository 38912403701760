<template>
	<div>
		<portal
			v-if="showTabs"
			:disabled="!tabPortalLocation"
			:to="tabPortalLocation"
		>
			<q-tabs v-model="activeTabName" :vertical="isVertical">
				<draggable
					v-if="adminEditMode"
					v-model="tabs"
					tag="div"
					:class="{ 'list-group': true, row: !isVertical }"
					handle=".handle"
				>
					<q-tab
						v-for="(tab, i) in tabs"
						:key="i"
						:name="`t_${data.id}_${i}`"
						:icon="tab.icon"
						:class="{ 'list-group-item': true, col: !isVertical }"
						@mousedown="moveTab(i)"
						@click="goToTab(i)"
					>
						<div class="handle">
							<q-icon name="fal fa-arrows" />
						</div>

						<I18N :id="tab.label" />
					</q-tab>
				</draggable>

				<q-tab
					v-for="(tab, i) in tabs"
					v-else
					:key="i"
					:name="`t_${data.id}_${i}`"
					:icon="tab.icon"
					@click="goToTab(i)"
				>
					<I18N :id="tab.label" />
				</q-tab>
			</q-tabs>
			<AddBlockButton
				:parent="data.id"
				:index="tabs.length"
			/>
		</portal>

		<span v-else />

		<h1
			v-if="data.showTitle"
			class="heading"
		>
			<q-icon
				v-if="activeIcon"
				:name="activeIcon"
				class="q-mr-md"
			/>
			<I18N :id="`custom.blocks.${activeTab}.title`" />
		</h1>

		<Block
			v-if="activeTabBlock"
			:key="`tab-${data.id}-${renderIndex}`"
			:data="activeTabBlock"
			v-bind="$props"
			:index="activeTabIndex"
		/>

		<AddBlockButton
			:parent="data.id"
			:index="activeTabIndex + 1"
		/>
	</div>
</template>

<script>
	import sh from 'shorthash';
	import draggable from 'vuedraggable';
	import BlockMixin from '@/components/blocks/BlockMixin';
	import AddBlockButton from '@/components/admin/generic/AddBlockButton';
	import Visibility from '@/components/blocks/Visibility';

	export default {
		name: 'TabsBlock',
		components: {
			Block: () => import('@/components/blocks/Block'),
			AddBlockButton,
			draggable
		},
		mixins: [BlockMixin, Visibility],
		provide()
		{
			return {
				$isInTabsBlock: true,
				$goToPreviousTab: this.goToPreviousTab
			};
		},
		data()
		{
			return {
				activeTab: null,
				moveBlock: null,
				activeTabName: '', // can only be set after component is mounted
				renderIndex: 0,
				tabIndexVisitedHistory: []
			};
		},
		computed: {
			children()
			{
				if(!this.data.positions?.['col-0']) return [];

				return this.data.positions?.['col-0']
					.map((blockId) => this.$store.getters['structure/blocks/getBlock'](blockId))
					// check if the user should see this block in this context - we use the data directly to dynamically update the blocks on the page as the user's (or entity's) data changes
					.filter((block) =>
					{
						return block && this.shouldRender(block);
					});
			},
			childHashes()
			{
				return this.children.map((child) => ({ blockId: child.id, hash: this.shortHashId(child.id) }));
			},
			shortHashBlockId()
			{
				return sh.unique(this.data.id);
			},
			tabs: {
				get()
				{
					return this.children.map((block) =>
					{
						return {
							label: `custom.blocks.${block.id}.title`,
							id: block.id,
							icon: this.generateIconName(block?.meta?.icon?.data),
							path: `${this.shortHashBlockId}_${this.shortHashId(block.id)}`
						};
					});
				},
				set(value)
				{
					const originalOrder = this.children;

					const oldIndex = originalOrder.findIndex((block) => block.id === this.moveBlock); // first one that doesn't match
					let newIndex = value.findIndex((block) => block.id === this.moveBlock);

					if(newIndex > oldIndex)
					{
						newIndex += 1; // because we need to new position while moving and not the index where it landed
					}

					const data = {
						target: {
							blockId: this.moveBlock
						},
						from: {
							parentMeta: { type: 'blocks', id: this.data.id },
							colIndex: '0',
							index: oldIndex
						},
						to: {
							parentMeta: { type: 'blocks', id: this.data.id },
							colIndex: '0',
							index: newIndex
						}
					};

					this.$store.dispatch('structure/blocks/admin/move', { changes: data, fromPageId: this.activePage.id, toPageId: this.activePage.id });
				}
			},
			showTabs()
			{
				return this.children.length > 1;
			},
			tabPortalLocation()
			{
				return this.data.tabPortalLocation || null;
			},
			activeTabBlock()
			{
				// find active tab that is the same as our hash value of children block id's
				const activeTabDetails = this.childHashes.find((tab) =>
				{
					return this.activeTab === tab.hash;
				});

				// if we've found one then use it to display the tab
				if(activeTabDetails)
				{
					return this.$store.getters['structure/blocks/getBlock'](activeTabDetails.blockId);
				}

				// default display the first one in the list & we have some actual tabs to show
				if(this.childHashes.length)
				{
					return this.$store.getters['structure/blocks/getBlock'](this.childHashes[0].blockId);
				}

				return false;
			},
			activeIcon()
			{
				const block = this.activeTabBlock;

				return this.generateIconName(block && block.meta && block.meta.icon && block.meta.icon.data);
			},
			isVertical()
			{
				return this.data.tabStyle === 'vertical';
			},
			activeTabIndex: {
				get()
				{
					return this.tabs.findIndex((tab) => tab.id === this.activeTabBlock.id);
				}
			}
		},
		watch: {
			$route: 'setCurrentTabFromUrl',
			data: {
				handler(next, prev)
				{
					if(!prev.children && next.children)
					{
						this.goToFirstTab(next.children);
					}
				},
				deep: true
			}
		},
		mounted()
		{
			this.setCurrentTabFromUrl();
		},
		methods: {
			goToTab(i)
			{
				if(!this.tabs[i])
				{
					console.error(`Trying to go to a tab that does not exist\
(tab #${i} but there's ${this.tabs.length} tabs).`);

					return;
				}

				const params = new URLSearchParams(window.location.search);
				let tab = params.get('tab') || [];

				if(!Array.isArray(tab))
				{
					tab = tab.split(',');
				}

				const currentTab = this.getCurrentTab(tab);

				if(currentTab)
				{
					const currentTabString = currentTab.join('_');

					tab = tab.map((t) => (t === currentTabString ? this.tabs[i].path : t));
				}
				else
				{
					tab.push(this.tabs[i].path);
				}

				params.set('tab', tab.join(','));
				window.history.pushState({}, '', `${window.location.pathname}?${decodeURIComponent(params)}`);
				this.setActiveTab(this.shortHashId(this.tabs[i].id));

				// Force the block representing the tab to re-render
				// This ensures that the parent tree is correct
				// If the parent tree is not updated, it can mean that targeting for adding new blocks/pasting blocks may be incorrect
				this.renderIndex += 1;
			},
			getCurrentTab(tab)
			{
				if(!Array.isArray(tab))
				{
					tab = tab.split(',');
				}

				return tab.map((t) => t.split('_')).find(([blockId]) =>
				{
					return blockId === this.shortHashBlockId;
				});
			},
			setCurrentTabFromUrl()
			{
				const { tab = [] } = this.$route.query;
				const currentTab = this.getCurrentTab(tab);

				if(currentTab)
				{
					this.setActiveTab(`${currentTab[1]}`);

					return;
				}

				this.setActiveTab(this.children && this.children.length && this.children[0].id);
			},
			setActiveTab(newActiveTab)
			{
				this.activeTab = newActiveTab;

				this.activeTabName = `t_${this.data.id}_${this.activeTabIndex}`;

				this.tabIndexVisitedHistory.push(this.activeTabIndex);
			},
			goToPreviousTab()
			{
				if(this.tabIndexVisitedHistory.length < 2)
				{
					// there's no previous tab.
					return;
				}

				const previousTabIndex = this.tabIndexVisitedHistory[
					this.tabIndexVisitedHistory.length - 2
				];

				this.goToTab(previousTabIndex);
			},
			goToFirstTab(children)
			{
				if(this.changeTab) // to stop it from erroring when a user adds the first tab
				{
					this.changeTab(children[0]);
				}
			},
			generateIconName(icon)
			{
				if(Array.isArray(icon))
				{
					return `${icon[0]} fa-${icon[1]}`;
				}

				return icon;
			},
			shortHashId(value)
			{
				return sh.unique(value);
			},
			moveTab(i)
			{
				this.moveBlock = this.tabs[i].id;
			}
		}
	};
</script>

<style scoped lang="postcss">
	h1.heading {
		margin: 0 0 1.6rem 0;

		svg {
			margin-right: 5px;
		}
	}

	.q-tab {
		white-space: normal;
	}

	.handle {
		float: left;
		padding-top: 8px;
		padding-bottom: 8px;
	}
</style>
