<template>
	<a
		tabindex="0"
		class="hiddenButton"
		:href="anchor"
	>{{ label }}</a>
</template>

<script>
	export default {
		props: {
			label: {
				type: String,
				required: true
			},
			anchor: {
				type: String,
				required: true
			}
		}
	};
</script>

<style scoped>
	.hiddenButton {
		position: fixed;
		height: 0;
		width: 0;
		opacity: 0;
		cursor: default;

		&:focus {
			opacity: 1;
			width: auto;
			height: auto;
			z-index: 1;
		}
	}
</style>
