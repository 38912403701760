/* eslint-disable import/no-unassigned-import */
import 'core-js/modules/esnext.string.replace-all';
import Vue from 'vue';
import '@/registerServiceWorker';
import Router from 'vue-router';
import { sync } from 'vuex-router-sync';
import { abilitiesPlugin } from '@casl/vue';
import autosize from 'autosize';
import { Dark } from 'quasar';
import PortalVue from 'portal-vue';
import VueHotkey from 'v-hotkey';
import VueMasonry from 'vue-masonry-css';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VeeValidate from 'vee-validate';
import VueMeta from 'vue-meta';
import VuePapaParse from 'vue-papa-parse';
import Croppa from 'vue-croppa';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import store from '@/store';
import router from '@/router';
import ability from '@/utils/ability';
import 'vue-croppa/dist/vue-croppa.css';
import initialLoad from '@/utils/initialLoad';
import veeValidateData from '@/utils/veeValidateData';
import syncSocketAndStore from '@/utils/syncSocketAndStore';
import SimpleButton from '@/components/ui/SimpleButton';
import Spinner from '@/components/ui/Spinner';
import I18N from '@/components/I18N';
import AdminI18N from '@/components/admin/generic/AdminI18N';
import DevelopmentText from '@/components/ui/DevelopmentText';
import QuasarProps from '@/components/ui/QuasarProps';
import i18n from '@/plugins/vue-i18n';
import '@/quasar';
import App from '@/components/App';
import ImagePath from '@/plugins/imagePath';
import KeyAlias from '@/plugins/keyAlias';
import 'animate.css';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import { allowedThumbnailWidths } from '@/configs/mediaConstants';

Vue.config.performance = process.env.NODE_ENV !== 'production';
if(process.env.NODE_ENV !== 'development')
{
	Sentry.init({
		Vue,
		dsn: 'https://214a4c38f8ae4e73a2495c2b7bee8096@o432855.ingest.sentry.io/5386974',

		// This sets the sample rate to be 0%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 0.05,

		/**
		 * Ignoring some errors that aren't really ours to fix...
		 * ResizeObserver errors seem to be exclusively related to Quasar, and
		 * something the Quasar devs appear to be intent on ignoring:
		 * https://github.com/quasarframework/quasar/issues/2233
		 */
		ignoreErrors: [
			// https://aluminati-fv.sentry.io/issues/2851068064/
			/^ResizeObserver loop limit exceeded$/,
			// https://aluminati-fv.sentry.io/issues/2924715782/
			/^ResizeObserver loop completed with undelivered notifications.$/
		],

		integrations: [
			new Sentry.Replay({
				// Additional SDK configuration goes in here, for example:
				maskAllText: true,
				blockAllMedia: true,
				block: [
					'.sentry-block', '[data-sentry-block]', // defaults
					'.avatar', '.avatar-shell', '.avatar-img', // avatar-shell includes the user's name
					'.icon', '.hero-image', '.user-custom-image', '.entityPicture',
					'.hero-image-container',
					'.qr-code-block-container' // QR block contains all the data that's being encoded
				],
				maskFn: (text) =>
				{
					// show the first letter of each string, so it's easier to track where the user is
					text = text.trim();

					if(text && text.length)
					{
						return text[0] + '*'.repeat(text.length - 1);
					}

					return '';
				},
				unmask: [
					'.sentry-unmask', '[data-sentry-unmask]', // defaults
					'span.singular > span', 'span.plural', // anything that comes from a I18N ?
					'.q-tooltip', '.more-nav-items-menu .q-item__section'
				],
				unblock: [
					'.sentry-unblock', '[data-sentry-unblock]', // defaults
					// NOTE - Font Awesome images come from a font, but for some reason that font doesn't load for
					// Sentry, so those images don't currently show on Replay
					'.fas', '.far', '.fal', '.fad', '.fass', // Font Awesome version < 6
					'.fa-solid', '.fa-regular', '.fa-light', '.fa-thin', '.fa-duotone', '.fa-brands', '.fa-sharp' // Font Awesome version >= 6.0
				]
			}),
			new Integrations.BrowserTracing()
		],
		tracesSampleRate: 1,
		environment: process.env.API,
		tracingOptions: {
			trackComponents: true
		},
		beforeSend: (event, hint) =>
		{
			if(event?.contexts?.vue?.componentName.includes('Croppa'))
			{
				console.warn('Croppa component error caught, not reporting to Sentry', hint.originalException);

				return null;
			}

			console.log('Encountered an error, reporting to sentry');

			console.error(hint.originalException || hint.syntheticException || event);

			Sentry.setUser({ id: store?.state?.user?.accountId });

			return event;
		}
	});
}

let socket; // = await import('@/utils/socket').then((mod) => mod.default);

// const Permissions = await import('@/plugins/permissions').then((mod) => mod.default);
// ADD BACK ONCE PUSH NOTIFICATIONS COMPLETE
// const PushNotifications = await import('@/plugins/pushNotifications').then((mod) => mod.default);

// if((process.env.VUE_AXE || process.env.VUE_AXE === 'true') && process.env.NODE_ENV !== 'production')
// {
// 	// eslint-disable-next-line no-unused-expressions
// 	import('@/plugins/vue-axe');
// }

// eslint-disable-next-line no-unused-expressions

sync(store, router);
Vue.use(Router);

Vue.use(abilitiesPlugin, ability);
// syncSocketAndStore(socket, store, router);
Vue.use(ImagePath, { store, allowedThumbnailWidths });
Vue.use(KeyAlias, { store });
// Vue.use(Permissions, { store });
// TODO ADD BACK ONCE PUSH COMPLETE
// Vue.use(PushNotifications, { store });
Vue.use(PortalVue);
Vue.use(VueHotkey);
Vue.use(VueMasonry);
Vue.use(VueDOMPurifyHTML);
Vue.use(VuePapaParse);

VeeValidate.Validator.extend('applicationTargetMatchesList', {
	validate(applicationId, [listId])
	{
		applicationId = typeof applicationId === 'string' ? applicationId : applicationId && applicationId.value;
		listId = typeof listId === 'string' ? listId : listId && listId.value;
		if(applicationId && listId)
		{
			const application = store.getters['applications/getApplication'](applicationId);

			if(application?.listId && application.listId === listId)
			{
				return true;
			}
		}

		return {
			valid: false,
			data: store.getters['i18n/get'](`custom.userLists.${listId}.label`)
		};
	}
},
{
	hasTarget: true
});

VeeValidate.Validator.extend('maxHtml', {
	validate(field, [length])
	{
		return field.replace(/(<([^>]+)>)/ig, '')?.length <= length;
	},
	params: ['length']
});

const optionsRequiredValidator = {
	validate: (value, args) => !!value && !!value.value,
	getMessage: (field, args) => ({ errorType: 'required', field })
};

VeeValidate.Validator.extend('options_required', optionsRequiredValidator);

VeeValidate.Validator.extend('isLink', {
	validate(value)
	{
		// I couldn't figure out how to use the existing url and email validation in here,
		// which is why I'm just doing more or less what `vee-validate`'s url validation does
		try
		{
			// eslint-disable-next-line no-new
			new URL(value);

			return true;
		}
		catch
		{
			return {
				// 11 comes from the length of 'mailto:' and then +5 for 'a@a.a'
				// for a minimum email address length... so, 12... which is > 11
				// TODO once the email validation ticket from Quade is live,
				//  this could be updated with a better email validation check
				valid: (value.startsWith('mailto:') && value.length > 11) ||
					value.startsWith('/'),
				data: store.getters['i18n/get']('form.errorTypes.isLink')
			};
		}
	}
});

Vue.use(VeeValidate, veeValidateData);

Vue.use(VueMeta);

Vue.use(Croppa);

/*
* Register some global components
*/
Vue.component('SimpleButton', SimpleButton);
Vue.component('I18N', I18N);
Vue.component('AdminI18N', AdminI18N);
Vue.component('Spinner', Spinner);
Vue.component('QuasarProps', QuasarProps);
Vue.component('DevelopmentText', DevelopmentText);

Vue.config.productionTip = false;

const themeModeToken = localStorage.getItem('themeModeToken');

if(themeModeToken)
{
	const { themeModeEnabled, theme } = JSON.parse(Buffer.from(themeModeToken, 'base64').toString('ascii'));
	const themeMode = theme === 'auto' ? theme : theme === 'dark';

	// need to set this after the main quasar import otherwise it doesn't get applied
	Dark.set(themeModeEnabled ? themeMode : 'light');
}

initialLoad(store)
	.then(async () =>
	{
		// const pathBeforeLogin = localStorage.getItem('pathBeforeLogin');
		// const pathBeforeLogin = getPathBeforeLogin();

		// if(pathBeforeLogin)
		// {
		// 	console.log('main::pathBeforeLogin', pathBeforeLogin);
		// 	localStorage.removeItem('pathBeforeLogin');
		// 	router.replace({ path: pathBeforeLogin });
		// }

		// This will only redirect if the path is set and valid, otherwise execution just continues
		// redirectToPathBeforeLogin();

		// socket can only be started after the user is logged in - otherwise it's going to connect to the wrong uri. It can't also be imported again as all imports only work once
		socket = await import('@/utils/socket').then((mod) => mod.default);
		syncSocketAndStore(socket, store, router);
	})
	.catch(async (err) =>
	{
		console.warn(err);
	});

Vue.directive('autosize', (el) => autosize(el));

setTimeout(() =>
{
	const el = document.getElementById('extreme_loading');

	if(el)
	{
		el.remove();
	}
}, 5000);

new Vue({
	router,
	store,
	i18n,
	...App
}).$mount('#app');
