<template>
	<div>
		<Form
			:blockId="blockId"
			:entityId="entityId"
			:fields="getFields"
			:editable="editable"
			:alwaysEdit="alwaysEditable"
			:showConfirmation="showConfirmation"
			:accountToUse="accountToUse"
			:collapseRepeaters="false"
			:autosave="autosave"
			:hideControls="hideControls"
			:hidePrivacyControls="hidePrivacyControls"
			:skipDirtyCheck="skipDirtyCheck"
			:onSubmit="saveData"
		>
			<template #display>
				<Block
					v-if="displayBlock"
					:data="displayBlock"
					colIndex="related"
					index="display"
					hideAddButton
					hideCutButton
				/>
			</template>
		</Form>
	</div>
</template>

<script>
	import { Validator } from 'vee-validate';
	import BlockMixin from '@/components/blocks/BlockMixin';
	import UserEditableMixin from '@/components/mixins/UserEditable';
	import FormMixin from '@/components/mixins/FormMixin';
	import Form from '@/components/form/Form';
	import * as Validators from '@/utils/customValidators';
	import { userCan } from '@/plugins/Permissions';
	import { addSuccess } from '@/utils/notifications';

	export default {
		components: {
			Form,
			Block: () => import('@/components/blocks/Block')
		},
		provide: {
			$formOrigin: 'BasicForm'
		},
		mixins: [
			BlockMixin,
			UserEditableMixin,
			FormMixin
		],
		data()
		{
			return {
				fields: [],
				groups: {}
			};
		},
		computed: {
			alwaysEditable()
			{
				return !!this.data?.alwaysEdit;
			},
			showConfirmation()
			{
				return !!this.data?.showConfirmationDialogue;
			},
			autosave()
			{
				return !!this.data?.autosave;
			},
			hideControls()
			{
				return !!this.data?.hideControls;
			},
			hidePrivacyControls()
			{
				return !!this.data?.hidePrivacyControls;
			},
			skipDirtyCheck()
			{
				return !!this.data?.skipDirtyCheck;
			},
			editable()
			{
				// Admins can edit anyone's profile.
				if(this.isCurrentlyEditing && userCan('update', 'profiles')) return true;

				return this.isCurrentUser;
			},
			fieldDefinitions()
			{
				return this.$store.getters['structure/fields/getFieldsForBlock'](this.data.id);
			},
			accountToUse()
			{
				if(this.opts && this.opts.forceCurrentUser)
				{
					return this.$store.getters['user/accountId'];
				}

				return this.accountId;
			},
			displayBlock()
			{
				if(this.data.relatedBlocks && this.relatedBlocks.display)
				{
					return this.$store.getters['structure/blocks/getBlock'](this.relatedBlocks.display);
				}

				return null;
			},
			entityId()
			{
				return this.targetId && this.$store.getters['entities/convertSlugToId'](this.targetId);
			}
		},
		created()
		{
			Object.entries(Validators.allValidators).forEach(([name, validator]) =>
			{
				Validator.extend(name, Validators[validator]);
			});
		},
		methods: {
			async saveData(data)
			{
				if(this.hasErrors)
				{
					return;
				}

				// This is intentionally not awaited, for optimistic updates
				this.$store.dispatch('profiles/saveProfileData', {
					accountId: this.accountToUse,
					blockId: this.blockId,
					data
				});

				// If autosave is not enabled, this message is show inside of the form component itself
				if(this.autosave)
				{
					addSuccess(this.$store.getters['i18n/get']('form.saving.success'));
				}
			}
		}
	};
</script>
