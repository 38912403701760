var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row items-center no-wrap full-width"},[_c('div',{ref:"tabsParent",staticClass:"full-width"},[_c('q-tabs',{ref:"tabsContainer",class:[
				_vm.$q.dark.isActive ? 'isDark' : 'isLight',
				{
					'no-arrows': _vm.noArrows,
					'full-width': _vm.overflowTabsToShow.length
				}
			],attrs:{"id":"tabs","align":"left","contentClass":[
				'navigation-overflow-list',
				'full-width',
				..._vm.customTabsBarClasses
			].join(' ')}},[(_vm.useSlots)?_vm._t("customTabs"):_vm._e(),_vm._l((_vm.tabsNotOverflowing),function(tab){return _c('q-route-tab',{key:tab.id,class:{'flex-grow': !_vm.isRecalculating && _vm.overflowTabsToShow.length},attrs:{"id":tab.id,"ripple":false,"to":_vm.routeLink(tab)}},[_c('div',[(tab.titleValue)?_c('span',[_vm._v(_vm._s(tab.titleValue))]):_c('I18N',{attrs:{"id":_vm.tabI18N(tab)},scopedSlots:_vm._u([{key:"fallback",fn:function(){return [_vm._v(" "+_vm._s(tab.name)+" ")]},proxy:true}],null,true)})],1)])})],2)],1),(!_vm.isAdminEditMode && _vm.overflowTabsToShow.length)?_c('q-btn-dropdown',{ref:"tabDropdown",class:[
			'q-ml-sm',
			'more-nav-items-button',
			_vm.customTabsBarClasses,
			_vm.$q.dark.isActive ? 'isDark' : 'isLight'
		],attrs:{"id":"more-nav-items","transitionShow":"jump-down","transitionHide":"jump-up","ripple":false,"contentClass":"more-nav-items-menu","autoClose":"","flat":"","icon":"far fa-ellipsis-h"}},[_c('q-tabs',{staticClass:"text-left",class:[
				_vm.customTabsBarClasses,
				_vm.$q.dark.isActive ? 'isDark' : 'isLight'
			],attrs:{"id":"tabs-dropdown","vertical":"","bordered":"","contentClass":['navigation-overflow-list', ..._vm.customOverflowMenuClasses].join(' ')}},_vm._l((_vm.overflowTabsToShow),function(tab,index){return _c('q-route-tab',{directives:[{name:"close-popup",rawName:"v-close-popup"}],key:tab.id,staticClass:"cursor-pointer justify-initial",attrs:{"ripple":false,"to":_vm.routeLink(tab)}},[(index)?_c('q-separator',{staticClass:"custom-separator"}):_vm._e(),_c('div',{staticClass:"q-pl-md"},[(tab.titleValue)?_c('span',[_vm._v(_vm._s(tab.titleValue))]):_c('I18N',{attrs:{"id":_vm.tabI18N(tab)},scopedSlots:_vm._u([{key:"fallback",fn:function(){return [_vm._v(" "+_vm._s(tab.name)+" ")]},proxy:true}],null,true)})],1)],1)}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }