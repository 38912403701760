export default {
	data()
	{
		return {
			cQuality: 1.5,
			foundParentElementWidth: undefined
		};
	},
	mounted()
	{
		this.getParentSizeAndThenSome(); // recalculate when the component is mounted. Issues can arise during entity and user search
	},
	methods: {
		/**
		 * Get the parent component size at runtime. DO NOT make this into a computed property as that will not work!
		 */
		getParentSize()
		{
			if(!this.foundParentElementWidth)
			{
				this.foundParentElementWidth = this.$parent.$el?.clientWidth;
			}

			return this.foundParentElementWidth;
		},
		getParentSizeAndThenSome()
		{
			return this.getParentSize() * this.cQuality;
		}
	}
};
