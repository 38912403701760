export const cAdminPanelTypes = Object.freeze({
	existingBlockPicker: 'existingBlockPicker',
	selectValidationField: 'selectValidationField',
	adminEditInsightsReport: 'adminEditInsightsReport',
	adminNewInsightsReport: 'adminNewInsightsReport',
	editAllAdminPermissions: 'editAllAdminPermissions',
	I18NEdit: 'I18NEdit',
	permissions: 'permissions',
	viewAs: 'viewAs',
	structureTemplate: 'structureTemplate',
	definition: 'definition',
	editRules: 'editRules',
	adminInsightsSettings: 'adminInsightsSettings',
	basicFormNewField: 'basicFormNewField',
	basicFormEditField: 'basicFormEditField',
	// Not sure it's really used
	newPage: 'newPage',
	newModule: 'newModule',
	editModule: 'editModule',
	editPage: 'editPage',
	// Not sure it's really used
	newBlockInfo: 'newBlockInfo',
	// Not sure it's really used
	newBlock: 'newBlock',
	editBlock: 'editBlock',
	basicFormEditRepeaterLabel: 'basicFormEditRepeaterLabel'
});

export const cAdminPanelTypesWithToolbars = [
	cAdminPanelTypes.editBlock,
	cAdminPanelTypes.permissions,
	cAdminPanelTypes.editModule,
	cAdminPanelTypes.editPage
];
