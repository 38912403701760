import moment from 'moment';
import Notifications from '@/components/ui/notificationsMenu/Notifications';

export default {
	components: {
		Notifications
	},
	computed: {
		notifications()
		{
			// filter out notifications the user has hidden
			return this.$store.getters['user/notifications/getNotifications'](this.type).filter((notification) =>
			{
				if(notification.type === 'chat')
				{
					const chatMeta = this.getChatMetaData(notification.targetId);

					if(chatMeta)
					{
						// check if the notification should be shown or hidden:
						const latestMessageTime = notification.date || null;
						const timeHidden = chatMeta.hidden || null;

						if(timeHidden && latestMessageTime)
						{
							// if the latest message came in after the user hid the chat, don't hide it
							return moment(latestMessageTime).isAfter(moment(timeHidden));
						}
					}
				}

				return true;
			});
		},
		unseenCount()
		{
			return this.$store.getters['user/notifications/getNotSeenCount'](this.type);
		},
		loaded()
		{
			return this.$store.getters['user/notifications/getLoaded'](this.type);
		}
	},
	async created()
	{
		// this.getCounter();
		this.getUnseenCounts();

		await this.$store.dispatch('user/notifications/loadNotifications', { type: this.type });
	},
	methods: {
		getChatMetaData(chatId)
		{
			return this.$store.getters['profiles/getDataValueByPath'](this.$store.getters['user/accountId'], `chats.${chatId}`, 'profileMeta') || null;
		},
		getUnseenCounts()
		{
			this.$store.dispatch('user/notifications/loadNotSeenCount', this.type);
		},
		open()
		{
			if(this.loaded)
			{
				this.$store.dispatch('user/notifications/markSeen', this.type);
			}
		}
	}
};
