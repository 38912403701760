import axios, { CancelToken, isCancel } from 'axios';
// import axiosRetry, { exponentialDelay } from 'axios-retry';
import localForage from 'localforage';
import { forEach } from 'p-iteration';
import store from '@/store';
import { addError } from '@/utils/notifications';
// import { setPathBeforeLogin } from '@/utils/pathBeforeLogin';

// An instance for general backend stuff
const instance = axios.create({
	baseURL: process.env.API,
	withCredentials: true
});

const baseURL = String(`${process.env.API}api/${process.env.API_VERSION}`);

// An instance scoped ONLY to the API endpoints. Use this 99% of the time
const apiInstance = axios.create({
	baseURL,
	withCredentials: true
});
let authToken = null;

// a singleton to handle all the Authorization/Bearer checks. Memorizes the result and keeps it around for half the duration of the refresh cycle
async function getAuthTokenSingleton()
{
	if(authToken) return authToken;

	// DO NOT AWAIT HERE!!!
	authToken = getAuthToken()
		.then((data) =>
		{
			// let's clear the result after a while
			setTimeout(() =>
			{
				authToken = null;
			}, 43200000); // half a day in js time

			return data;
		});

	return authToken;
}

// axiosRetry(apiInstance, { retries: 3, retryDelay: exponentialDelay });
async function getAuthToken()
{
	// If we are loading straight into some routes, e.g. spaces, this may be the first location that takes the token from the URL. If that's the case then we want to use it directly here
	const url = new URL(window.location.href);
	const params = url.searchParams;
	const urlToken = params.get('token');

	// If the token is still in the URL when we already have it in local storage, we can remove it from the URL.
	if(localStorage.getItem('jwt') && urlToken)
	{
		url.searchParams.delete('token');
		window.history.replaceState({}, document.title, url.toString());
	}

	return refreshTokenIfPossible(urlToken || localStorage.getItem('jwt'));
}

async function refreshTokenIfPossible(jwt)
{
	if(jwt)
	{
		const parts = jwt.split('.');

		if(parts[1])
		{
			const userData = JSON.parse(Buffer.from(parts[1], 'base64').toString('ascii'));
			const now = (new Date()) / 1000;

			const recheckThreshold = 86400; // 1 day

			const timeElapsedSinceIssue = now - userData.iat;

			// try to renew when about to expire soon - for now let's assume the exp for jwt and access token is the same
			// This will renew once every day
			if(timeElapsedSinceIssue > recheckThreshold)
			{
				let newJwt;

				try
				{
					const { data } = await instance.post('/auth/refresh', { jwt });

					newJwt = data.jwt;
					// request complete and store
					// resetAuthTokenRequest();
					store.dispatch('user/login', newJwt);
				}
				catch(e)
				{
					// if the refresh fails no need to log them out, just log it and move on
					console.error('ERROR', e);
				}

				return newJwt || jwt;
			}

			return jwt;
		}

		return jwt;
	}

	return undefined;
}

apiInstance.interceptors.request.use(async (config) =>
{
	// If not one of these specific pages that doesn't need a token, use method to get the current token or request a new one. Otherwise, use current token (possibly none)
	if(!config.url.includes('login') && !config.url.includes('refresh') && !config.url.includes('forgot_password') && !config.url.includes('reset_password') && !config.url.includes('activate'))
	{
		config.headers.Authorization = `Bearer ${await getAuthTokenSingleton()}`;
	}
	else
	{
		const jwt = localStorage.getItem('jwt');

		config.headers.Authorization = `Bearer ${jwt}`;
	}

	return config;
});

// eslint-disable-next-line one-var
let hadError = false;

apiInstance.interceptors.response.use(
	(res) =>
	{
		return res;
	},
	(err) =>
	{
		if(err?.response && err.response.status === 401 && err.response.data.error === 'Not authorized')
		{
			if(!hadError)
			{
				hadError = true;
				// setPathBeforeLogin(store.state.route.path);

				store.dispatch('user/logout');
			}
		}

		return Promise.reject(err);
	}
);

const pendingGetRequests = new Map();

// Convenience functions
const get = (url, data = {}, suppressErrors = false) =>
{
	const urlAndData = `${url}?${JSON.stringify(data)}`;

	// This need to be automatically run before any get request
	if(pendingGetRequests.has(urlAndData)) return pendingGetRequests.get(urlAndData); // exit and return the Promise

	store.dispatch('app/startLoading');

	const request = apiInstance.get(url, data)
		.then((res) =>
		{
			store.dispatch('app/stopLoading');

			return res;
		})
		.catch((error) =>
		{
			console.log(error);
			store.dispatch('app/stopLoading');
			if(!suppressErrors)
			{
				handleErrorResponse(error.response);
			}

			throw error;
		})
		.finally(() =>
		{
			pendingGetRequests.delete(urlAndData);
		});

	pendingGetRequests.set(urlAndData, request);

	return request;
};

/**
 * Allows streaming a API response.
 * @param {string} url
 * @param {(data: string) => void} onDataCallback
 */
const getStream = async (url, onDataCallback) =>
{
	if(typeof onDataCallback !== 'function')
	{
		console.warn('You have not provided a callback to run when data is received.');
	}

	store.dispatch('app/startLoading');

	const response = await fetch(`${baseURL}${url}`, { headers: {
		Authorization: `Bearer ${localStorage.getItem('jwt')}`
	} });

	if(!response.body)
	{
		return;
	}

	const reader = response.body.getReader();

	while(true)
	{
		// eslint-disable-next-line
		const { value, done } = await reader.read();

		if(value && typeof onDataCallback === 'function')
		{
			onDataCallback(Buffer.from(value).toString());
		}

		if(done) break;
	}

	store.dispatch('app/stopLoading');
};

const cacheMaxAge = (60 * 60 * 24) * 1000; // one day (JS dates are millis)

const getSWR = async (url, data = {}, callback, method = 'get') =>
{
	// Check if we have a response cached already
	const cache = await localForage.getItem(generateSWRKey(url, data));

	const cacheIsClean = !(await isSWRDirty());

	if(cache && cacheIsClean)
	{
		const { data: response, timestamp } = cache;

		// If the cache is older than the max age, just discard it
		if(Date.now() - timestamp < cacheMaxAge)
		{
			// Trigger the callback with cached data
			await callback(response, true);

			if('requestIdleCallback' in window)
			{
				// If we can, wait until the browser is ready for us to do some work
				window.requestIdleCallback(async () =>
				{
					await _getSWRRequest(url, data, callback, method);
				});
			}
			else
			{
				// Set up a timeout to load the real data
				setTimeout(async () =>
				{
					await _getSWRRequest(url, data, callback, method);
				}, 100);
			}

			return;
		}
	}

	// We get here if we had a cache, but it's expired
	// Or if there was no cache
	// Or once the browser is idling.
	await _getSWRRequest(url, data, callback, method, cacheIsClean);
};

const _getSWRRequest = async (
	url,
	data = {},
	callback,
	method = 'get',
	cacheWasClean = true
) =>
{
	let response;

	switch(method)
	{
		case 'post':
			response = (await post(url, data))?.data;

			break;
		case 'get':
		default:
			response = (await get(url, data))?.data;

			break;
	}

	await callback(response, false);

	await storeSWRData(url, data, response);

	if(!cacheWasClean)
	{
		// We've just reloaded the cache, it's clean again.
		await setDirtySWR(false);
	}

	return response;
};

const isSWRDirty = async () =>
{
	return localForage.getItem('dirty');
};

/**
 * If dirty is set to `true`, will cause cache not to be
 * used when the page is reloaded.
 */
const setDirtySWR = async (isDirty = true) =>
{
	await localForage.setItem('dirty', isDirty);
};

/**
 * Store the data in local storage
 * @param {*} url
 * @param {*} data
 */
const storeSWRData = async (url, data, response) =>
{
	// swrCircuitBreaker += 1;
	const payload = { data: response, timestamp: Date.now() };

	try
	{
		await localForage.setItem(generateSWRKey(url, data), payload);
	}
	catch(e)
	{
		console.error(e);

		try
		{
			await deleteAllSWRDataAndAddLatest(url, data, payload);
		}
		catch(e)
		{
			console.error(e);
		}
	}
};

// basically a copy of `deleteAllSWRDataAndAddLatest` but without the adding of the latest data as this is used to clear SWR data on a user logging out
const deleteAllSWRData = async () =>
{
	const swrRegex = new RegExp('^swr-');

	const keys = await localForage.keys();

	await forEach(keys, async (key) =>
	{
		if(swrRegex.test(key))
		{
			await localForage.removeItem(key);
		}
	});
};

const deleteAllSWRDataAndAddLatest = async (url, data, payload) =>
{
	const swrRegex = new RegExp('^swr-');

	const keys = await localForage.keys();

	await forEach(keys, async (key) =>
	{
		if(swrRegex.test(key))
		{
			await localForage.removeItem(key);
		}
	});

	await localForage.setItem(generateSWRKey(url, data), payload);
};

const generateSWRKey = (url, data) =>
{
	const params = JSON.stringify(data?.params);

	return `swr-${url}-${params}`;
};

const post = (url, data = {}, config = {}, { quiet = false } = {}) =>
{
	if(!quiet) store.dispatch('app/startLoading');

	return apiInstance.post(url, data, config)
		.then((res) =>
		{
			if(!quiet) store.dispatch('app/stopLoading');

			return res;
		})
		.catch((error) =>
		{
			if(!quiet) store.dispatch('app/stopLoading');

			handleErrorResponse(error.response);

			throw error;
		});
};

const put = (url, data = {}, config = {}) =>
{
	store.dispatch('app/startLoading');

	return apiInstance.put(url, data, config)
		.then((res) =>
		{
			store.dispatch('app/stopLoading');

			return res;
		})
		.catch((error) =>
		{
			store.dispatch('app/stopLoading');
			handleErrorResponse(error.response);

			throw error;
		});
};

const patch = (url, data = {}, config = {}) =>
{
	store.dispatch('app/startLoading');

	return apiInstance.patch(url, data, config)
		.then((res) =>
		{
			store.dispatch('app/stopLoading');

			return res;
		})
		.catch((error) =>
		{
			store.dispatch('app/stopLoading');
			handleErrorResponse(error.response);

			throw error;
		});
};

const destroy = (url, config = {}) =>
{
	store.dispatch('app/startLoading');

	return apiInstance.delete(url, config)
		.then((res) =>
		{
			store.dispatch('app/stopLoading');

			return res;
		})
		.catch((error) =>
		{
			store.dispatch('app/stopLoading');
			handleErrorResponse(error.response);

			throw error;
		});
};

const handleErrorResponse = (errorResponse) =>
{
	if(!errorResponse)
	{
		return;
	}

	console.error(errorResponse);

	if(!errorResponse.data)
	{
		return;
	}

	if(errorResponse.data.tokenIssue)
	{
		store.dispatch('user/logout');
	}

	if(errorResponse.data.message)
	{
		console.error(
			'The API responded with: ',
			errorResponse.data.message
		);
	}

	if(errorResponse.data.error)
	{
		console.error(errorResponse.data.error);
	}

	if(process.env.NODE_ENV === 'development')
	{
		addError(errorResponse.data.message || errorResponse.data.error || '');
	}
};

const cancellable = (type, url, data = {}) =>
{
	const { token, cancel } = CancelToken.source();
	let req;

	switch(type)
	{
		case 'get':
			req = get(url, { ...data, cancelToken: token });
			break;
		case 'post':
			req = post(url, { ...data }, { cancelToken: token });
			break;
		case 'put':
			req = put(url, { ...data }, { cancelToken: token });
			break;
		case 'patch':
			req = patch(url, { ...data }, { cancelToken: token });
			break;
		case 'destroy':
			req = destroy(url, { ...data }, { cancelToken: token });
			break;
		default:
			throw new Error('You must specify a known type');
	}

	return { req, cancel };
};

export {
	instance,
	apiInstance,
	get,
	getStream,
	getSWR,
	post,
	put,
	patch,
	destroy,
	cancellable,
	CancelToken,
	isCancel,
	baseURL,
	deleteAllSWRData,
	setDirtySWR,
	isSWRDirty
};
