// @ts-check
import Vue from 'vue';
import panel from '@/store/admin/panel';
import dash from '@/store/admin/dash';
import { deleteAllSWRData, post } from '@/utils/api';
import { addError } from '@/utils/notifications';
import { isElevated } from '@/plugins/Permissions';
import { cCustomGlobalCssStyles } from '@/configs/constants';
/**
 * @typedef {import('../../types/store/admin').StoreAdmin.State} State
 */

/** @type {State} */
const state = {
	editMode: false, // I've turned this on by default for development purposes - This should be set to false before release,
	safeMode: false,
	debugMode: false,
	viewAs: [],
	visiblePermissions: { do_not_show: {} }, // default to not showing any tabs until the visiblePermissions have loaded
	allowCustomGlobalStyles: true
};

const mutations = {
	SET_EDIT_MODE(state, mode)
	{
		state.editMode = mode;
	},
	TOGGLE_DEBUG_MODE(state)
	{
		state.debugMode = !state.debugMode;
	},
	SET_SAFE_MODE(state, mode)
	{
		state.safeMode = mode;
	},
	SET_VIEW_AS(state, listIds)
	{
		Vue.set(state, 'viewAs', listIds);
	},
	SET_VISIBLE_PERMISSIONS(state, permissionPaths)
	{
		Vue.set(state, 'visiblePermissions', permissionPaths);
	},
	/**
	 * @param {object} state
	 * @param {boolean} mode
	 */
	SET_CUSTOM_GLOBAL_STYLES(state, mode)
	{
		Vue.set(state, 'allowCustomGlobalStyles', !!mode);
	}
};

const actions = {
	setEditMode({ commit, dispatch }, mode)
	{
		commit('SET_EDIT_MODE', mode);

		if(mode === true)
		{
			dispatch('i18n/admin/load', null, { root: true });

			dispatch(
				'structure/admin/loadAllPermissions',
				null,
				{ root: true }
			);
		}
		else
		{
			dispatch('admin/panel/clear', null, { root: true });
		}
	},
	toggleDebugMode({ commit })
	{
		if(!isElevated())
		{
			return;
		}

		commit('TOGGLE_DEBUG_MODE');
	},
	setSafeMode({ commit }, mode)
	{
		if(mode === true)
		{
			document.getElementById('custom-style').type = 'text/css-deactivated';
		}
		else
		{
			document.getElementById('custom-style').type = 'text/css';
		}

		commit('SET_SAFE_MODE', mode);
	},
	/**
	 * @param {boolean} mode
	 */
	setAllowCustomGlobalStyles({ commit }, mode)
	{
		if(mode === true)
		{
			document.body.classList.add(
				cCustomGlobalCssStyles.globalStyleCustomGlobalCssStyles
			);
		}
		else
		{
			document.body.classList.remove(
				cCustomGlobalCssStyles.globalStyleCustomGlobalCssStyles
			);
		}

		commit('SET_CUSTOM_GLOBAL_STYLES', mode);
	},
	async viewAs({ commit, dispatch, rootState }, listIds)
	{
		if(!Array.isArray(listIds))
		{
			listIds = [listIds];
		}

		const reset = listIds.indexOf('reset') > -1;

		dispatch('admin/panel/closeActive', undefined, { root: true });

		// Show a fullscreen loader to hide the appearing/disappearing of data.
		dispatch('app/showFullLoader', undefined, { root: true });

		await deleteAllSWRData();
		if(!reset)
		{
		// Send the required IDs to the backend
			post('admin/viewAs', { listIds })
				.then(async () =>
				{
					window.location.reload();
				})
				.catch((e) =>
				{
					console.warn(e);
					addError('There was an error trying to change who you are viewing as.');
					dispatch('app/hideFullLoader', undefined, { root: true });
				});
		}
		else
		{
			await post('admin/reset');

			window.location.reload();
		}
	},
	setVisiblePermissions({ commit }, permissionPaths)
	{
		commit('SET_VISIBLE_PERMISSIONS', permissionPaths);
	}
};

const getters = {
	isEditMode: (state) => state.editMode,
	isSafeMode: (state) => state.safeMode,
	isDebugMode: (state) => state.debugMode,
	isViewingAs: (state) => state.viewAs && state.viewAs.length > 0,
	viewingAs: (state) => state.viewAs || [],
	isPermissionVisible: (state) => (path) => !Object.keys(state.visiblePermissions || {}).length || (path in state.visiblePermissions)
};

const modules = {
	panel,
	dash
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
	modules
};
