import { forEach } from 'p-iteration';
import flattenDeep from 'lodash/flattenDeep';
import groupBy from 'lodash/groupBy';
import Vue from 'vue';
import { addError } from '@/utils/notifications';
import { userCan } from '@/plugins/Permissions';
import { get, post } from '@/utils/api';
import { notificationTypes } from '@/configs/constants';

const state = {
	notSeenCount: {
		chat: 0,
		system: 0,
		admin: 0
	},
	notifications: {
		chat: [],
		system: [],
		admin: [],
		userPreferences: [],
		adminPreferences: [],
		notificationHistory: [],
		entityLevelSubscriptions: [],
		centerTab: null,
		allSubbedItems: []
	},
	loaded: {
		chat: false,
		system: false,
		admin: false
	}
};

const mutations = {
	SET_SEEN_COUNT(state, { type, count })
	{
		state.notSeenCount[type] = count;
	},
	CLEAR_SEEN_COUNT(state, type)
	{
		state.notSeenCount[type] = 0;
		// state.notifications[type].forEach((notification) => (notification.seen = true));
		state.notifications[type] = state.notifications[type].map((notification) => ({ ...notification, seen: true }));
	},
	SET_NOTIFICATION_CENTER_TAB(state, { tab })
	{
		state.notifications.centerTab = tab;
	},
	SET_NOTIFICATIONS(state, { type, notifications })
	{
		state.loaded[type] = true;
		state.notifications[type] = notifications;
	},
	SET_NOTIFICATION_HISTORY(state, { data })
	{
		state.notifications.notificationHistory = data;
	},
	SET_ADMIN_PREFERENCES(state, { data })
	{
		state.notifications.adminPreferences = data;
	},
	SET_USER_PREFERENCES(state, { subscribables })
	{
		state.notifications.userPreferences = subscribables;
	},
	SET_USER_PREFERENCE_STATE(state, { event, active, inAppActive, type, admin, preferenceType, scope, localTarget, id })
	{
		const preferencesToUpdate = state.notifications[
			admin ?
				'adminPreferences' :
				'userPreferences'
		];
		let eventToUpdate;

		if(preferenceType === 'block')
		{
			const preferenceTypeObj = preferencesToUpdate
				.find((pref) => pref.global);

			if(!preferenceTypeObj) return;

			eventToUpdate = preferenceTypeObj.global.subscribables
				.find((subscribable) => subscribable.eventName === event && subscribable.id === id);
		}
		else
		{
			const preferenceTypeObj = preferencesToUpdate
				.find((pref) => pref[preferenceType]);

			if(preferenceTypeObj)
			{
				eventToUpdate = preferenceTypeObj[preferenceType][type]
					.find((subscribable) => subscribable.eventName === event);
			}
			// if it's not a typed preference but a scoped one, the original type check will not resolve a useful value
			// instead, if we should have been given a target to search through
			else if(localTarget)
			{
				eventToUpdate = preferencesToUpdate
					// find the preferences for the target entity's definition and get it's subscribables property
					.find((pref) => pref[localTarget])?.[localTarget]?.subscribables?.find(
					// get the preference with the eventName and type we're looking for
						(subscribable) =>
							subscribable.eventName === event && subscribable.type === type
					);
			}
		}

		if(eventToUpdate)
		{
			eventToUpdate.active = active;
			eventToUpdate.inAppActive = inAppActive;

			if(scope)
			{
				eventToUpdate.scope = scope;
			}
		}
	},
	UPDATE_CHAT_NOTIFICATION(state, { updateSeen, message })
	{
		const i = state.notifications.chat.findIndex((chat) => chat.targetId === message.targetId);

		if(updateSeen && (!state.notifications.chat[i] || state.notifications.chat[i].seen))
		{
			state.notSeenCount.chat += 1;
		}

		if(i >= 0)
		{
			state.notifications.chat[i].id = message.id;
			state.notifications.chat[i].message = message.message;
			state.notifications.chat[i].date = message.date;
			state.notifications.chat[i].type = message.type;
			state.notifications.chat[i].fromAccountId = message.from;
			state.notifications.chat[i].seen = !updateSeen;
			state.notifications.chat[i].read = !updateSeen;
		}
		else
		{
			state.notifications.chat.unshift({ ...message, tmp: true });
		}
	},
	ADD_SYSTEM_NOTIFICATION(state, payload)
	{
		state.notifications.system.unshift(payload);

		if(!payload.seen)
		{
			state.notSeenCount.system += 1;
		}
	},
	ADD_ADMIN_NOTIFICATION(state, payload)
	{
		let notificationToUpdate = -1;

		if(payload.data.userListId && payload.data.entityId)
		{
			notificationToUpdate = state.notifications.admin.findIndex((notification) =>
			{
				return payload.data.userListId === notification.data.userListId && payload.data.entityId === notification.data.entityId && !notification.read;
			});
		}

		if(notificationToUpdate > -1)
		{
			state.notifications.admin.splice(notificationToUpdate, 1, payload);
		}
		else
		{
			state.notifications.admin.unshift(payload);

			if(!payload.seen)
			{
				state.notSeenCount.admin += 1;
			}
		}
	},
	MARK_NOTIFICATION_READ(state, { type, id })
	{
		let i;

		if(type === 'chat')
		{
			i = state.notifications.chat.findIndex((notification) => notification.targetId === id);
		}
		else
		{
			i = state.notifications[`${type}`].findIndex((notification) => notification.id === id);
		}

		if(i < 0)
		{
			return;
		}

		state.notifications[type][i].read = 1;
	},
	UPDATE_ENTITY_SUBSCRIPTIONS(state, { notifications, addToArray = true, entityId = null })
	{
		if(!addToArray && state.notifications.entityLevelSubscriptions?.length)
		{
			state.notifications.entityLevelSubscriptions = [...notifications];
		}
		else
		{
			const { data } = notifications;

			if(!state.notifications.entityLevelSubscriptions?.length && data)
			{
				state.notifications.entityLevelSubscriptions.push(data);
			}
			else
			{
				state.notifications.entityLevelSubscriptions.some((entityNotification, index) =>
				{
					if(entityNotification[entityId])
					{
						state.notifications.entityLevelSubscriptions[index] = data;

						return true;
					}

					return false;
				});
			}
		}
	},
	SET_SUBSCRIBED_ITEMS(state, subbedItems)
	{
		Vue.set(state, 'allSubbedItems', subbedItems.data);
	}
};

const actions = {
	async loadNotSeenCount({ commit }, type)
	{
		// Go get the unread count from API
		const { data: count } = await get(`profile/notifications/count/${type}`);

		commit('SET_SEEN_COUNT', { type, count });
	},
	async markRead({ commit }, { type, id })
	{
		if(type !== 'chat')
		{
			await post('profile/notifications/read', { notificationIds: [id] });
		}

		commit('MARK_NOTIFICATION_READ', { type, id });
	},
	async markSeen({ commit }, type)
	{
		await post(`profile/notifications/seen/${type}`);

		commit('CLEAR_SEEN_COUNT', type);
	},
	async loadNotifications({ commit, state }, { type, force = false })
	{
		if(!force && state.loaded[type]) return;

		const { data: notifications } = await get(`profile/notifications/${type}/0`);

		commit('SET_NOTIFICATIONS', { type, notifications });
		if(type === 'system')
		{
			// Add back when discussion feed is a thing
			// await dispatch('loadFollowedItems', { muted: false });
		}
		// commit('CLEAR_SEEN_COUNT', type);
	},
	async loadUserPreferences({ commit, rootGetters, dispatch })
	{
		const { data: subscribables } = await get('profile/notifications/userPreferences');

		commit('SET_USER_PREFERENCES', { subscribables });
		let objectOfEntities = [],
			entityIds = [];

		// const output = [];

		subscribables.forEach((subscribable) =>
		{
			Object.keys(subscribable).forEach((key) =>
			{
				// PR note - is there any reason to include those with a scope that has no length?
				const scopedDiscussions = subscribable[key]?.subscribables
					.filter((event) => event?.scope?.length && event?.eventName === 'newDiscussion');

				if(scopedDiscussions?.length)
				{
					scopedDiscussions.forEach((event) =>
					{
						const arrangedEvents = event.scope.map((details) => ({
							entityId: details?.id,
							targetId: event.id,
							type: event.type
						}));

						objectOfEntities.push(arrangedEvents);
					});
				}
			});
		});

		objectOfEntities = flattenDeep(objectOfEntities);

		// gather all the blockIds that are already _not_ loaded
		const itemIdsByType = objectOfEntities.reduce((agg, details) =>
		{
			let itemIsLoaded = false;

			switch(details.type)
			{
				case 'cardWall':
				case 'discussion':
					itemIsLoaded = !!rootGetters['structure/blocks/getBlock'](details.targetId);

					break;
				case 'concepts':
					itemIsLoaded = !!rootGetters['entityDefinitions/byId'](details.targetId);

					break;
				default:
					break;
			}

			if(!itemIsLoaded && !agg[details.type])
			{
				agg[details.type] = [details.targetId];
			}
			else if(!itemIsLoaded)
			{
				agg[details.type].push(details.targetId);
			}

			return agg;
		}, {});

		// then get the blocks in one call
		await forEach(Object.keys(itemIdsByType), async (itemType) =>
		{
			switch(itemType)
			{
				case 'cardWall':
				case 'discussion':
					await dispatch('structure/blocks/loadMultiple', itemIdsByType[itemType], { root: true });

					break;
				case 'concepts':
					await dispatch('entityDefinitions/loadMultiple', itemIdsByType[itemType], { root: true });

					break;
				default:
					break;
			}
		});

		const data = objectOfEntities.map((details) =>
		{
			let item;

			switch(details.type)
			{
				case 'cardWall':
				case 'discussion':
					item = rootGetters['structure/blocks/getBlock'](details.targetId);

					break;
				case 'concepts':
					item = {
						...rootGetters['entityDefinitions/byId'](details.targetId),
						type: 'entityDefinition'
					};

					break;
				default:
					break;
			}

			entityIds.push(details.entityId);

			return { [details.entityId]: [item] };
		});

		entityIds = [...new Set(entityIds)];

		dispatch('updateUsersEntitySubscriptions', { data, addToArray: false });

		if(userCan('showAdminPreferences', 'administration'))
		{
			const { data } = await get('profile/notifications/adminPreferences');

			commit('SET_ADMIN_PREFERENCES', { data });
		}
	},
	async loadNotificationHistory({ commit })
	{
		if(userCan('showAdminNotifications', 'administration'))
		{
			const { data } = await get('profile/notifications/notificationHistory');

			commit('SET_NOTIFICATION_HISTORY', { data });
		}
	},
	async setUserPreferenceState(
		{ commit },
		{
			event,
			active,
			type,
			admin,
			preferenceType,
			extra = {},
			targetId = null,
			localTarget = null
		}
	)
	{
		let updatedState = [];

		try
		{
			const { data } = await post(
				'profile/notifications/updateUserPreferences',
				{
					event,
					active,
					type,
					admin,
					preferenceType,
					extra,
					targetId
				}
			);

			updatedState = data || [];
		}
		catch(e)
		{
			console.error(e);
			addError(e.message);
		}
		finally
		{
			// Previously we used a dispatch('loadUserPreferences') call here, but this failed to return for new preferences
			// Instead we now use the data returned from the update step (because it does return the full pref object) and set it locally
			// This way, the following button state should always be correct, and toggling will work as expected
			// TL:DR keep all notifications up to date on the frontend when anything changes
			updatedState.forEach((notificationPreferenceObj) =>
			{
				commit('SET_USER_PREFERENCE_STATE', {
					event: notificationPreferenceObj.eventName || event,
					active: notificationPreferenceObj.active ?? active,
					inAppActive: notificationPreferenceObj.inAppActive ?? active,
					type: notificationPreferenceObj.type || type,
					admin,
					preferenceType,
					scope: notificationPreferenceObj.scope,
					localTarget,
					id: notificationPreferenceObj.id
				});
			});
		}
	},
	async setNotificationCenterTab({ commit }, { tab })
	{
		commit('SET_NOTIFICATION_CENTER_TAB', { tab });
	},
	async loadFollowedItems({ commit, getters, dispatch }, { muted = false, filters = null })
	{
		const { data } = await post('profile/notifications/getFollowedItems', { muted, filters });
		let followedUpdates = getters.getNotifications('system').filter((notification) => notification.typeId === 7 && (notification.read === 0 || notification.read === false));

		const newPosts = getters.getNotifications('system').filter((notification) => notification.typeId === 6 && (notification.read === 0 || notification.read === false));

		followedUpdates = groupBy(followedUpdates, (update) => update.data.parentId);

		await forEach(data, async (post, index) =>
		{
			await dispatch('communications/loadOne', { targetId: post.targetId, communicationId: post.id, gettingFollowing: true, scope: post.scope }, { root: true });
			if(newPosts.some((notification) => notification.data.postId === post.id))
			{
				data[index].newPost = true;
			}

			if(followedUpdates[post.id]?.length)
			{
				data[index].newComments = followedUpdates[post.id]?.length;
				data[index].updateAccountIds = followedUpdates[post.id]?.map((update) => update?.data?.fromAccountId);
			}
		});

		commit('SET_SUBSCRIBED_ITEMS', { data });

		return data;
	},
	addSystemNotification({ commit }, payload)
	{
		// For admin notifications
		if(payload.typeId === 3) commit('ADD_ADMIN_NOTIFICATION', payload);
		// For user notifications
		else if(payload.typeId?.toString() in notificationTypes)
		{
			commit('ADD_SYSTEM_NOTIFICATION', payload);
		}
		else
		{
			console.warn('Unknown notification type', payload);
		}
	},
	updateChatNotification({ commit }, { updateSeen, message })
	{
		commit('UPDATE_CHAT_NOTIFICATION', { updateSeen, message });
	},
	updateUsersEntitySubscriptions({ commit, rootGetters, dispatch }, { data, addToArray })
	{
		const entityId = rootGetters['entities/convertSlugToId'](data.entityId) || data.entityId;

		commit('UPDATE_ENTITY_SUBSCRIPTIONS', { notifications: data, addToArray, entityId });

		// Once updated, the user's profile must be updated to reflect the new preferences.
		// New preferences may otherwise not show the correct state
		dispatch('profiles/reloadProfile', rootGetters['user/accountId'], { root: true });
	}
};

const getters = {
	getNotSeenCount: (state) => (type) => state.notSeenCount[type],
	getNotifications: (state) => (type) => state.notifications[type],
	getLoaded: (state) => (type) => state.loaded[type],
	getUserPreferences: (state) => state.notifications.userPreferences,
	getAdminPreferences: (state) => state.notifications.adminPreferences,
	getNotificationCenterTab: (state) => state.notifications.centerTab,
	getNotificationHistory: (state) => state.notifications.notificationHistory,
	getEntityLevelNotificationPreferences: (state) => (entityId) =>
	{
		return state.notifications.entityLevelSubscriptions.filter((notification) =>
		{
			return notification[entityId]?.length;
		});
	},
	getAllSubscribedItems: (state) => state.allSubbedItems
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
