<template>
	<div v-if="isUserControlsLoading" class="loading-overlay">
		<q-inner-loading :showing="true" />
	</div>
	<div v-else class="user-controls">
		<!-- title: -->
		<div class="flex items-center">
			<q-icon
				name="fad fa-cog"
				class="q-mr-sm q-mt-xs"
			/>
			<h3 class="q-my-none txtGrey-8 bold">
				<AdminI18N id="userControls.tabs.account.title" />
			</h3>
		</div>
		<div class="actions">
			<q-separator class="q-my-md" />
			<!-- my profile: -->
			<router-link :to="myProfileLink">
				<div class="clickable flex">
					<div class="text">
						<div class="title">
							<I18N id="userControls.myProfile.title" />
						</div>
						<div class="desc txtGrey-8">
							<I18N id="userControls.myProfile.desc" />
						</div>
					</div>
					<q-space />
					<div class="control">
						<q-icon
							name="fas fa-user"
							class="q-mr-sm"
							size="17px"
						/>
					</div>
				</div>
			</router-link>
			<!-- my account: -->
			<q-separator v-if="entryViewPermissions.myAccount" class="q-my-md" />
			<a
				v-if="entryViewPermissions.myAccount"
				class="clickable flex"
				:href="myAccountLink"
				target="_blank"
			>
				<div class="text">
					<div class="title">
						<I18N id="userControls.myAccount.title" />
					</div>
					<div class="desc txtGrey-8">
						<I18N id="userControls.myAccount.desc" />
					</div>
				</div>
				<q-space />
				<div class="control">
					<q-icon
						name="fad fa-user-lock"
						class="q-mr-sm"
						size="17px"
					/>
				</div>
			</a>
			<q-separator v-if="manageMyLogins && entryViewPermissions.socialSignIn" class="q-my-md" />
			<div
				v-if="manageMyLogins && entryViewPermissions.socialSignIn"
				class="clickable flex q-my-md"
				style="align-items: center; cursor: pointer;"
				@click="openManageMyLogins"
			>
				<div class="text">
					<div class="title">
						<I18N id="userControls.manageMyLogins.titles.modalOpen" />
					</div>
					<div class="desc">
						<I18N id="userControls.manageMyLogins.descriptions.activator" />
					</div>
				</div>
				<q-space />
				<div class="control">
					<SimpleButton
						:aria-label="$t('userControls.manageMyLogins.titles.modalOpen')"
						icon="fad fa-handshake"
						class="q-mr-sm"
						size="sm"
						dense
						flat
						@click.stop.prevent="openManageMyLogins"
					/>
				</div>
			</div>
			<q-separator class="q-my-md" />
			<Modal width="900px">
				<template #activator="{open}">
					<SimpleButton
						class="full-width q-mb-sm"
						unelevated
						color="secondary"
						@click="open"
					>
						<q-icon
							name="fad fa-bell"
							class="q-mr-sm"
							color="white"
							size="15px"
						/>
						<AdminI18N id="userControls.notificationCenter" />
					</SimpleButton>
				</template>

				<template #title>
					<div class="row">
						<AdminI18N id="userControls.notificationCenter" wrap="h3" />
						<SimpleButton
							size="sm"
							class="q-ml-sm"
							flat
							rounded
							:icon="!showSettings ? 'fal fa-cog' : 'fal fa-arrow-left'"
							:aria-label="!showSettings ? $t('userControls.notificationSettings') : $t('userControls.back')"
							@click="toggleNotificationsSettings"
						/>
					</div>
				</template>
				<template #default>
					<NotificationSettings v-if="showSettings" />
					<template v-else>
						<I18N
							id="notifications.intro"
							:vars="{ serviceName }"
							wrap="p"
						/>
						<NotificationCenter class="q-mt-md" />
					</template>
					<!-- ADD BACK ONCE PUSH COMPLETED -->
					<!-- <q-list>
						<q-item-section>
							<q-item-label>
								<I18N id="notifications.types.chat.allowPushTitle" />
								<q-toggle
									:value="pushNotificationsEnabled"
									color="green"
									@input="notificationsToggle"
								/>
							</q-item-label>
							<q-item-label caption>
								<I18N id="notifications.types.chat.allowPush" />
							</q-item-label>
						</q-item-section>
					</q-list> -->
				</template>
			</Modal>
			<Modal width="600px">
				<template
					#activator="{open}"
				>
					<SimpleButton
						class="full-width q-mb-sm"
						unelevated
						color="secondary"
						@click="open"
					>
						<q-icon
							name="fab fa-accessible-icon"
							class="q-mr-sm"
							color="white"
							size="15px"
						/>
						<AdminI18N id="userControls.accessibility" />
					</SimpleButton>
				</template>

				<template>
					<h3><AdminI18N id="userControls.accessibility" /></h3>
					<I18N id="accessibility.intro" :vars="{ serviceName: serviceName }" />
					<Accessibility />
				</template>
			</Modal>
			<Modal v-if="shouldShowPrivacySettings" width="600px">
				<template #activator="{ open }">
					<SimpleButton
						class="full-width q-mb-sm"
						unelevated
						color="secondary"
						@click="open"
					>
						<q-icon
							name="fad fa-lock"
							class="q-mr-sm"
							color="white"
							size="15px"
						/>
						<AdminI18N id="userControls.privacy" />
					</SimpleButton>
				</template>

				<template #title>
					<AdminI18N
						id="userControls.privacy"
						wrap="h3"
					/>
				</template>

				<template #default>
					<PrivacySettings />
				</template>
			</Modal>
			<div v-if="publishedLanguagesFormatted.length > 1">
				<q-separator class="q-my-md" />
				<div class="text">
					<div class="title">
						<I18N id="userControls.language" />
					</div>
				</div>
				<q-select
					v-model="locale"
					:options="publishedLanguagesFormatted"
					outlined
					class="q-pb-sm"
					dense
				/>
			</div>
			<div v-if="themeModeEnabled">
				<q-separator class="q-my-md" />
				<div class="text wrap">
					<div class="title q-mb-xs">
						<I18N id="userControls.themeMode.title" />
					</div>
				</div>
				<q-btn-toggle
					:value="theme"
					spread
					round
					unelevated
					toggleColor="primary"
					:textColor="$q.dark.isActive ? 'white' : 'grey-8'"
					class="full-width themeSwitcher"
					:options="[
						{value: 'light', slot: 'light'},
						{value: 'dark', slot: 'dark'},
						{value: 'auto', slot: 'system'}
					]"
					@input="setTheme"
				>
					<template v-slot:light>
						<div class="row items-center">
							<q-icon
								:color="$q.dark.isActive || theme === 'light' ? 'yellow-5' : 'yellow-9'"
								size="18px"
								name="fad fa-sun"
								class="q-mr-sm"
							/>
							<div class="text-center">
								<I18N id="userControls.themeMode.mode.light" />
							</div>
						</div>
					</template>
					<template v-slot:dark>
						<div class="row items-center no-wrap">
							<q-icon
								:color="$q.dark.isActive ? 'white' : 'grey-8'"
								size="16px"
								name="fad fa-moon"
								class="q-mr-sm"
							/>
							<div class="text-center">
								<I18N id="userControls.themeMode.mode.dark" />
							</div>
						</div>
					</template>
					<template v-slot:system>
						<div class="row items-center no-wrap">
							<q-icon
								:color="$q.dark.isActive || theme === 'auto' ? 'white' : 'grey-8'"
								size="16px"
								name="fad fa-computer-classic"
								class="q-mr-sm"
							/>
							<div class="text-center">
								<I18N id="userControls.themeMode.mode.system" />
							</div>
						</div>
					</template>
				</q-btn-toggle>
			</div>
			<q-separator class="q-my-md" />
			<SimpleButton
				id="btn-app-logout"
				class="full-width"
				color="grey-3"
				textColor="grey-9"
				size="md"
				unelevated
				@click="logout($event)"
			>
				<q-icon
					name="fad fa-lock-alt"
					class="q-mr-sm"
					size="14px"
				/>
				<AdminI18N id="userControls.logout" />
			</SimpleButton>
		</div>
		<q-inner-loading :showing="isLanguageLoading" />
	</div>
</template>

<script>
	import Modal from '@/components/Modal';
	import NotificationCenter from '@/components/ui/NotificationCenter';
	import NotificationSettings from '@/components/ui/NotificationSettings';
	import Accessibility from '@/components/ui/Accessibility';
	import { post } from '@/utils/api';
	import { konami } from '@/plugins/easterEggs/konami';
	import { theBigShinyM } from '@/plugins/easterEggs/logoAscii';
	import { defaultPages } from '@/configs/constants';
	import { I18N } from 'acb-lib';
	import LanguagesMixin from '@/components/mixins/LanguagesMixin';
	import PrivacySettings from '@/components/ui/PrivacySettings.vue';
	import { cSettingId } from '@/configs/constants/settings';

	export default {
		components: {
			Modal,
			NotificationCenter,
			NotificationSettings,
			Accessibility,
			PrivacySettings
		},
		mixins: [LanguagesMixin],
		data()
		{
			return {
				viewAsMode: false,
				viewAs: {},
				entryViewPermissions: Object.keys(defaultPages).reduce((agg, defaultPage) =>
				{
					agg[defaultPage] = false;

					return agg;
				}, {}),
				konamiListener: null,
				serviceName: this.$store.getters['i18n/get']('siteTitle'),
				showSettings: false,
				isLanguageLoading: false,
				isUserControlsLoading: true
			};
		},
		computed: {
			locale: {
				get()
				{
					const language = this.$store.getters['user/metaValue']('language', 'meta') ||
						this.$store.getters['app/settings/get']('languages.default');

					return I18N.cLanguages[language];
				},
				async set(value)
				{
					if(value === this.localeActive)
					{
						return;
					}

					this.isLanguageLoading = true;

					await this.$store.dispatch('i18n/changeLocale', value.value);

					this.isLanguageLoading = false;
				}
			},
			localeActive()
			{
				return this.$store.getters['i18n/localeActive'];
			},
			myAccountLink()
			{
				return this.$store.getters['app/settings/get']('myAccount')?.link || `${window.location.origin}/account.php`;
			},
			manageMyLogins()
			{
				return this.$store.getters['app/settings/get']('socialSignIn')?.enable === 'enable';
			},
			theme()
			{
				return this.$store.getters['user/getMetaSimple']?.settings?.theme || 'light';
			},
			themeModeEnabled()
			{
				return !!this.$store.getters['app/settings/get']('themeMode')?.enabled;
			},
			shouldShowPrivacySettings()
			{
				return !!this.$store.getters['app/settings/get'](
					cSettingId.userConnectivity
				)?.isEnabled;
			},
			myProfileLink()
			{
				return this.$store.getters['app/settings/defaultProfileEditPage'];
			}
		},
		async created()
		{
			await this.$store.dispatch('user/loadFrameworkData', 'accountCreationType');
		},
		async mounted()
		{
			this.isUserControlsLoading = true;
			const { data: canUserViewDefaultPages } = await post('settings/permission/getDefaultPagesViewPermission', {
				defaultPageNames: Object.keys(defaultPages)
			});

			Object.entries(canUserViewDefaultPages).forEach(([defaultPageName, value]) =>
			{
				if(value?.show === true)
				{
					this.entryViewPermissions[defaultPageName] = true;
				}
			});

			this.isUserControlsLoading = false;

			// ADD BACK ONE PUSH COMPLETED
			// pushNotificationsEnabled()
			// {
			// 	return this.$pushNotifications.usersDeviceTokens() && this.$pushNotifications.usersDeviceTokens().length > 0;
			// }

			this.konamiListener = konami(theBigShinyM); // since `konami` returns an anonymous function, we need to store it separately to remove it later

			if(window.addEventListener)
			{
				window.addEventListener('keyup', this.konamiListener);
			}
			else
			{
				window.attachEvent('onkeyup', this.konamiListener);
			}
		},
		destroyed()
		{
			if(window.removeEventListener)
			{
				window.removeEventListener('keyup', this.konamiListener);
			}
			else
			{
				window.detachEvent('onkeyup', this.konamiListener);
			}
		},
		methods: {
			toggleNotificationsSettings()
			{
				this.showSettings = !this.showSettings;
			},
			// ADD BACK WHEN PUSH COMPLETE
			// notificationsToggle(val)
			// {
			// 	if(val)
			// 	{
			// 		this.$pushNotifications.getToken();
			// 	}
			// 	else
			// 	{
			// 		this.$pushNotifications.disableDeviceTokens();
			// 	}
			// },
			// openMyProfileLink()
			// {
			//	this.$router.push({ name: 'page', params: this.defaultProfileLink }).catch((error) =>
			//	{
			//		if(error.name !== 'NavigationDuplicated')
			//		{
			//			throw error;
			//		}
			//	});
			// },
			// openMyAccountLink()
			// {
			//	// Open it in new tab
			//	window.open(this.myAccountLink, '_blank');
			// },
			openManageMyLogins()
			{
				this.$store.dispatch('app/setShowSocialSignInModal', true);
			},
			logout(e)
			{
				e.preventDefault();
				this.$store.dispatch('user/logout');
			},
			async setTheme(theme)
			{
				await this.$store.dispatch('profiles/saveProfileMetaData', { path: 'settings.theme', data: theme });
			}
		}
	};
</script>

<style scoped lang="postcss">
	.text {
		flex: 3;

		.title {
			font-weight: 600;
		}

		.desc {
			font-size: 0.9em;
			white-space: nowrap;
		}
	}

	.clickable {
		cursor: pointer;
		align-items: center;
		color: var(--ep-color-text);

		&:hover,
		&:focus {
			.text {
				color: var(--q-color-primary);
			}
		}
	}

	.control {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
	}

	.themeSwitcher {
		border: 1px solid #ddd;
	}

	.loading-overlay {
		height: 400px;
	}
</style>
