// @ts-check
import Vue from 'vue';
import { getMatchId } from '../../../../utils/connections';

/**
 * @typedef {{
 * 		state: State,
 * 		dispatch: (dispatchId: string, ...rest: Array<*>) => void,
 * 		getters: { all: Array<MatchData> }
 * }} Store
 */
/**
 * @typedef {(
 * 	import('../../../../types/store/entities/connections/matches').EntityConnectionMatches.MatchData
 * )} MatchData
 */
/**
 * @typedef {(
 * 	import('../../../../types/store/entities/connections/matches').EntityConnectionMatches.MatchDetails
 * )} MatchDetails
 */
/**
 * Stores connection match data between users per connection type.
 * This only reflects the most current data.
 *
 * Separate references should be kept,
 * if criteria, and metadata, need to be for a specific search.
 *
 * @typedef {Object} State
 * @property {Object.<string, MatchData>} matches - Record key is `${entityId}:${connectionTypeId}:${followerAccountId}:${leaderAccountId}`.
 * @property {Object.<string, Object.<string, boolean>>} matchLocks - Locks to be used with cache clearing. This does not prevent removals, as they're considered forced.
 * @property {Object.<string, number>} matchLoadedAtTimestamps
 */
/** @type {State} */
const state = {
	matches: {},
	matchLocks: {},
	matchLoadedAtTimestamps: {}
};

const mutations = {
	/**
	 * @typedef {((
	 * 	state: State,
	 * 	{ matchId: string, matchData: MatchData }
	 * ) => void)} SET_MATCH
	 *
	 * @type {SET_MATCH}
	 */
	SET_MATCH(state, { matchId, matchData })
	{
		Vue.set(state.matches, matchId, matchData);

		state.matchLoadedAtTimestamps[matchId] = Date.now();
	},
	SET_MATCHES(state, matchIdMatchDataMap)
	{
		const now = Date.now();

		Object.entries(matchIdMatchDataMap).forEach(([matchId, match]) =>
		{
			Vue.set(state.matches, matchId, match);
			state.matchLoadedAtTimestamps[matchId] = now;
		});
	},
	/**
	 * @typedef {((
	 * 	state: State,
	 * 	matchId: string
	 * ) => void)} REMOVE_MATCH
	 *
	 * @type {REMOVE_MATCH}
	 */
	REMOVE_MATCH(state, matchId)
	{
		if(matchId in state.matches)
		{
			delete state.matchLoadedAtTimestamps[matchId];
			delete state.matchLocks[matchId];
			Vue.delete(state.matches, matchId);
		}
	},
	/**
	 * @typedef {((
	 * 	state: State,
	 * 	lockDetails: { matchId: string, lock: boolean, key: string }
	 * ) => void)} SET_MATCH_CACHE_LOCK
	 *
	 * @type {SET_MATCH_CACHE_LOCK}
	 */
	SET_MATCH_CACHE_LOCK(state, { matchId, lock, key })
	{
		if(state.matchLocks[matchId])
		{
			if(lock)
			{
				state.matchLocks[matchId][key] = true;
			}
			else
			{
				delete state.matchLocks[matchId][key];

				// If no locks, delete property to prevent build-up,
				// and so `Object.keys` doesn't need to be used when checking if a profile is locked.
				if(!Object.keys(state.matchLocks[matchId]).length)
				{
					delete state.matchLocks[matchId];
				}
			}
		}
		else if(lock)
		{
			state.matchLocks[matchId] = { [key]: true };
		}
	},
	/**
	 * @typedef {((
	 * 	state: State,
	 * 	key: string
	 * ) => void)} CLEAR_MATCH_CACHE_LOCKS_BY_KEY
	 *
	 * TODO: Not currently used, since only 1 components is currently locking matches.
	 *
	 * @type {CLEAR_MATCH_CACHE_LOCKS_BY_KEY}
	 */
	CLEAR_MATCH_CACHE_LOCKS_BY_KEY(state, key)
	{
		Object.entries(state.matchLocks).forEach(([matchId, lock]) =>
		{
			delete lock[key];

			// If no locks, delete property to prevent build-up,
			// and so `Object.keys` doesn't need to be used when checking if a profile is locked.
			if(!Object.keys(lock).length) delete state.matchLocks[matchId];
		});
	}
};

/**
 * TODO: Could make cache management more generic,
 * like a disposable store.
 */
const actions = {
	/**
	 * @typedef {((store: Object, matchData: MatchData) => string)} setMatch
	 *
	 * @type {setMatch}
	 */
	setMatch({ commit }, matchData)
	{
		const matchId = getMatchId(matchData);

		commit('SET_MATCH', {
			matchId,
			matchData
		});

		return matchId;
	},
	setMatches({ commit }, matchIdMatchDataMap)
	{
		commit('SET_MATCHES', matchIdMatchDataMap);
	},
	/**
	 * @typedef {((store: Object, matchDetails: MatchDetails) => void)} removeMatch
	 *
	 * @type {removeMatch}
	 */
	removeMatch({ commit }, matchDetails)
	{
		commit('REMOVE_MATCH', getMatchId(matchDetails));
	},
	/**
	 * @typedef {((store: Object, matchId: string) => void)} removeMatchById
	 *
	 * @type {removeMatchById}
	 */
	removeMatchById({ commit }, matchId)
	{
		commit('REMOVE_MATCH', matchId);
	},
	/**
	 * @typedef {((
	 * 	store: Object,
	 * 	matchDetails: Array<MatchDetails>
	 * ) => void)} removeMatches
	 *
	 * @type {removeMatches}
	 */
	removeMatches({ dispatch }, matchDetails)
	{
		matchDetails.forEach((match) =>
		{
			dispatch('removeMatch', match);
		});
	},
	/**
	 * @typedef {((store: Object, matchId: Array<string>) => void)} removeMatchesById
	 *
	 * @type {removeMatchesById}
	 */
	removeMatchesById({ dispatch }, matchIds)
	{
		matchIds.forEach((matchId) =>
		{
			dispatch('removeMatchById', matchId);
		});
	},
	/**
	 * @typedef {((
	 * 	store: Object,
	 * 	lockDetails: {
	 * 		matchId: string,
	 * 		lock: boolean,
	 * 		key: string
	 * 	}) => void
	 * )} setMatchCacheLock
	 *
	 * @type {setMatchCacheLock}
	 */
	setMatchCacheLock({ commit }, lockDetails)
	{
		commit('SET_MATCH_CACHE_LOCK', lockDetails);
	},
	/**
	 * @typedef {((
	 * 	store: Object,
	 * 	lockDetails: {
	 * 		matchIds: Array<string>,
	 * 		lock: boolean,
	 * 		key: string
	 * 	}) => void
	 * )} setMatchCacheLocks
	 *
	 * @type {setMatchCacheLocks}
	 */
	setMatchCacheLocks({ dispatch }, { matchIds, lock, key })
	{
		matchIds.forEach((matchId) =>
		{
			dispatch('setMatchCacheLock', { matchId, lock, key });
		});
	},
	/**
	 * @typedef {((store: Object, key: string) => void)} clearMatchCacheLocksByKey
	 *
	 * @type {clearMatchCacheLocksByKey}
	 */
	clearMatchCacheLocksByKey({ commit }, key)
	{
		commit('SET_SCORE_CACHE_LOCKS_BY_KEY', key);
	},
	/**
	 * @callback clearCachedMatches
	 * @param {Store} store
	 * @param {number} [maxCachedScores] - The desired number of scores left after the clear.
	 * Locks can cause this number to be exceeded. Defaults to 100.
	 *
	 * @type {clearCachedMatches}
	 */
	clearCachedMatches({ dispatch, getters, state }, maxCachedMatches = 100)
	{
		maxCachedMatches = maxCachedMatches > 0 ? maxCachedMatches : 0;

		let matchesToDeleteCount = maxCachedMatches ?
				getters.all.length - maxCachedMatches :
				getters.all.length,
			timestampIndex = 0,
			matchId;

		if(matchesToDeleteCount <= 0) return;

		const timestamps = Object.entries(
			state.matchLoadedAtTimestamps
		).sort(
			([, aTimestamp], [, bTimestamp]) => aTimestamp - bTimestamp
		);

		while(matchesToDeleteCount && timestampIndex < timestamps.length)
		{
			matchId = timestamps[timestampIndex][0];
			timestampIndex += 1;

			if(!state.matchLocks[matchId])
			{
				dispatch('removeMatch', state.matches[matchId]);
				matchesToDeleteCount -= 1;
			}
		}
	}
};

const getters = {
	/**
	 * @typedef {((state: State) => Array<MatchData>)} all
	 *
	 * @type {all}
	 */
	all: (state) => Object.values(state.matches),
	/**
	 * @typedef {((state: State, getters: Object) => (matchDetails: MatchDetails) => MatchData)} get
	 *
	 * @type {get}
	 */
	get: (state) => (matchDetails) => state.matches[getMatchId(matchDetails)],
	/**
	 * @typedef {((state: State) => (matchIds: Array<string>) => Array<MatchData>)} getById
	 *
	 * @type {getById}
	 */
	getById: (state) => (matchIds) =>
	{
		return matchIds.map((matchId) => state.matches[matchId]);
	},
	/**
	 * @typedef {((state: State) => (matchId: string) => MatchData)} getOneById
	 *
	 * @type {getOneById}
	 */
	getOneById: (state) => (matchId) =>
	{
		return state.matches[matchId];
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
