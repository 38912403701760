// @ts-check
import { computed } from 'vue';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { getDefinition } from '@/components/blocks/blockDefinitions';
import { powerUps } from '@/configs/constants';
import { userCan } from '@/plugins/Permissions';
import { profileLinkFromBlockData } from '@/utils/routerLinkGenerator';
import store from '@/store';
import { cAdminPanelTypes } from '@/configs/adminPanelTypes';

/**
 * @typedef {import('@/types/app').UUID} UUID
 * @typedef {import('@/types/block').GenericBlock} GenericBlock
 * @typedef {import('@/types/item').BlockData} BlockData
 * @typedef {import(
 * 	'@/types/components/blocks/blockMixin'
 * ).BlockMixin.Props} BlockMixinProps
 */
/**
 * @template T
 * @typedef {import('vue').ComputedRef<T>} ComputedRef<T>
 */

/**
 * Use in conjunction with the Options API mixin. until fully migrated.
 *
 * @param {BlockMixinProps} props - Props provided by `setup()`
 * or `definedProps()`.
 */
export function useBlockMixin(props)
{
	/** @type {ComputedRef<number>} */
	const accountId = computed(() =>
	{
		if(props.targetIdOverride) return props.targetIdOverride;

		// The accountId is either the one from the URL,
		// or by default, the current user.
		// Make sure that the targetId is also a number - NaN is a falsy value,
		// so defaults to the user's accountId.
		return (
			store.state.route.params.targetId &&
			parseInt(store.state.route.params.targetId, 10)
		) || parseInt(store.getters['user/accountId'], 10);
	});

	return {
		accountId
	};
}

const Block = {
	name: 'BlockInstance',
	props: {
		data: {
			type: Object,
			required: true
		},
		parentId: {
			type: String,
			default: ''
		},
		options: Object,
		targetIdOverride: {
			type: [String, Number],
			default: null
		},
		toolbarData: {
			type: Object,
			default: () => ({})
		}
	},
	provide()
	{
		return {
			profileLink: () => this.profileLink,
			blockId: this.id
		};
	},
	inject: {
		pageId: {
			default: null
		},
		contextTargetId: {
			from: 'targetId',
			default: null
		},
		parentData: {
			default: () => ({})
		}
	},
	data()
	{
		return {
			content: this.data.content
		};
	},
	computed: {
		/**
		 * @returns {BlockData}
		 */
		blockData()
		{
			return this.$store.getters['structure/blocks/getBlock'](this.blockId);
		},
		id()
		{
			return this.data.id;
		},
		/**
		 * @returns {(UUID | undefined)} The id of the entity that was selected through
		 * `AttachToEntity.vue` ("Find an entity") if there is one.
		 */
		attachedEntityId()
		{
			return this.data?.entityId;
		},
		/**
		 * The id of a user or an entity to show.
		 * @returns {(String | Number | UUID)} User ID (number) OR UUID of an entity OR slug of an entity.
		 */
		targetId()
		{
			/**
			 * If an entityId was set up in the block settings, then it overrides everything else.
			 */
			if(this.attachedEntityId) return this.attachedEntityId;

			/**
			 * If used within an entitySearchContainer or userSearchContainer, the targetId to use is the one provided by the results block (eg. results grid).
			 */
			if(this.targetIdOverride) return this.targetIdOverride;

			/**
			 * Otherwise, the targetId to use is the one to get from context (provided when on a page of type entity or user or from the URL).
			 */
			return this.contextTargetId;
		},
		blockId()
		{
			return this.data.id;
		},
		opts()
		{
			return this.data.options || {};
		},
		children()
		{
			if(!this.data.children) return [];

			// Map the IDs of blocks to their data, then remove any that are 'undefined' (user does not have permission for that!)
			return this.data.children
				.map((blockId) => this.$store.getters['structure/blocks/getBlock'](blockId))
				.filter((block) => block);
		},
		isAdmin()
		{
			return this.$store.getters['admin/isEditMode'] && userCan('manageEditMode', 'administration');
		},
		// If this block has a specific profile link, return that, otherwise return the default profile page
		profileLink()
		{
			return profileLinkFromBlockData(this.data);
		},
		adminEditMode()
		{
			return this.$store.getters['admin/isEditMode'];
		},
		accountId()
		{
			if(this.targetIdOverride) return this.targetIdOverride;

			// The accountId is either the one from the URL, or by default, the current user
			// make sure that the targetId is also a number - NaN is a falsy value, so defaults to the user's accountId
			return (this.$store.state.route.params.targetId && parseInt(this.$store.state.route.params.targetId, 10)) || parseInt(this.$store.getters['user/accountId'], 10);
		},
		isCurrentUser()
		{
			return parseInt(this.accountId, 10) === parseInt(this.$store.state.user.accountId, 10);
		},
		relatedBlocks()
		{
			return this.data.relatedBlocks || null;
		},
		isCurrentlyEditing()
		{
			return this.$store.getters['admin/isEditMode'] && userCan('manageEditMode', 'administration');
		},
		/**
		* Any block which has children has a `positions` property, which contains its
		* child ids organized into 12 columns, i.e. `col-0`, `col-1`, ... `col-11`.
		* This method retrieves the child block ids.
		* @returns {Array<GenericBlock>} Returns all blocks with col-# key
		*/
		blockIdsInCols()
		{
			return this.data.positions || { 'col-0': [] };
		},
		/** @returns {Array<UUID>} Return the blocks in the first column */
		defaultPosition()
		{
			return this.blockIdsInCols?.['col-0'] || [];
		},
		defaultPositionIds()
		{
			return this.defaultPosition;
		},
		blockDefinition()
		{
			return getDefinition(this.data.type) || {};
		},
		requiredPowerUps()
		{
			return this.blockDefinition.requiredPowerUps || [];
		},
		getOptionalPowerUps()
		{
			return this.blockDefinition.optionalPowerUps || [];
		},
		checkOptionalPowerUps()
		{
			if(!this.entity || !this.entity.powerUps)
			{
				return {};
			}

			const powerUpsOn = {};

			// check each optional power up
			this.getOptionalPowerUps.forEach((powerUp) =>
			{
				// get key for particular power up from power ups enum
				const key = Object.keys(powerUps).find((key) => powerUps[key] === powerUp);

				powerUpsOn[key] = this.entity.powerUps.includes(powerUp);
			});

			return powerUpsOn;
		},
		powerUpData()
		{
			const powerUpData = {};

			if(this.entity?.powerUpData)
			{
				// go through each optional power up required by this block, if it's enabled then get its data
				Object.keys(this.checkOptionalPowerUps).forEach((key) =>
				{
					if(this.checkOptionalPowerUps[key])
					{
						powerUpData[key] = this.entity.powerUpData[powerUps[key]];
					}
				});

				// also go get the required power ups and their data as well
				this.requiredPowerUps.forEach((powerUp) =>
				{
					const key = Object.keys(powerUps).find((key) => powerUps[key] === powerUp);

					if(this.checkPowerUps(this.entityId))
					{
						powerUpData[key] = this.entity.powerUpData[powerUps[key]];
					}
				});
			}

			return powerUpData;
		},
		contactInformationAvailable()
		{
			return this.powerUpData?.contact && Object.keys(this.powerUpData.contact).length;
		},
		currentUserBlockMetaData()
		{
			const currentUser = this.$store.getters['user/accountId'];

			return this.$store.getters['profiles/getDataValueByPath'](currentUser, `block.${this.blockId}`, 'meta');
		},
		viewingUserBlockMetaData()
		{
			if(this.isCurrentUser)
			{
				return this.currentUserBlockMetaData;
			}

			return this.$store.getters['profiles/getDataValueByPath'](this.accountId, `block.${this.blockId}`, 'meta');
		},
		title()
		{
			return this.$store.getters['i18n/get'](`custom.blocks.${this.id}.title`);
		}
	},
	methods: {
		newBlock()
		{
			this.$store.dispatch('structure/blocks/admin/openPanel', { parent: this.data.id });
		},
		editBlock()
		{
			this.$store.dispatch('admin/panel/new', {
				id: `edit-${this.data.id}`,
				type: cAdminPanelTypes.editBlock,
				subTitle: `custom.blocks.${this.data.id}.title`,
				block: this.data
			});
		},
		checkPowerUps(entityId)
		{
			if(entityId)
			{
				const entity = this.$store.getters['entities/byId'](entityId);

				if(entity)
				{
					// store the entity we're looking at in the data
					// this.data.entity = cloneDeep(entity);

					// check each required power up stored in block def to see if this entity has all of them turned on
					return this.requiredPowerUps.every((powerUp) => entity.powerUps.includes(powerUp));
				}
			}

			// this.data.entity = {};

			return false;
		},
		missingPowerUps()
		{
			const entityId = this.entity ? this.entity.id : false;

			if(!entityId)
			{
				return false;
			}

			// if we've got missing power ups let's store them
			if(!this.checkPowerUps(this.entity.id))
			{
				return this.requiredPowerUps.filter((powerUp) => !this.entity.powerUps.includes(powerUp));
			}

			return false;
		},
		typeOfConfig()
		{
			const missingIds = this.missingPowerUps();

			// missing power ups on this block? return the type as def so the modal can open the definition route and power-up tab so user can enable it
			return missingIds ? 'definition' : '';
		},
		getPowerUp()
		{
			// return power-up data always the first power up in the missing power ups arr, but we will change this to be flagged up in the entity data if a power up is not configured
			return this.$store.getters['powerUps/byId'](this.missingPowerUps()?.[0]);
		},
		async saveBlockUserMeta(path, data)
		{
			path = `block.${this.id}.${path}`;
			await this.$store.dispatch('profiles/saveProfileMetaData', { path, data });
		},
		emitBlockEvent(type, data)
		{
			const eventName = `blockEvent:${type}`;

			this.$emit(eventName, data);
		},

		/**
		 * @param {GenericBlock} block
		 *   an array of key names to pick from the block keys (since we do not
		 *   want to return all block data)
		 * @param {Array<String>} [includeSelectionFromRoot]
		 *   if you'd like to return a specific location of which objects to
		 *   return
		 * @param {String} [explicitSearchIn]
		 *   return all options that belong to the block. NB. This bypasses
		 *   explicitSearchIn option
		 * @param {Boolean} [unionise]
		 *   any options of block found
		 * @returns {[Object | Boolean]}
		 */
		getBlockOptions(
			block,
			includeSelectionFromRoot = [],
			explicitSearchIn = 'options',
			unionise = true
		)
		{
			if(unionise)
			{
				const unionOptions = { ...this.data?.options,
					...block?.options,
					...pickBy(block, (value, key) =>
					{
						return includeSelectionFromRoot.includes(key);
					}) };

				return isEmpty(unionOptions) ? false : unionOptions;
			}

			return block[explicitSearchIn] || false;
		}
	}
};

export default Block;
