// @ts-check
import Vue from 'vue';
// @ts-ignore
import { get, post, put, destroy } from '@/utils/api';
import keyBy from 'lodash/keyBy';
// @ts-ignore
import { cAdminPanelTypes } from '@/configs/adminPanelTypes';
import omit from 'lodash/omit';
import { Utils, Errors } from 'acb-lib';
// @ts-ignore
import { queryString } from '@/utils/queryString';

/** @type {import ('./admin').State} */
const state = {
	engagementReportModesByReportId: {},
	// socketListeners: 0,
	currentDashboardFilters: undefined,
	dashboards: {},
	allDashboardsAreLoading: false,
	reportBeingRemoved: undefined,
	reportProgressByReportId: {},
	reportsLoadingState: {},
	loadingDashboards: {},
	loadingReports: {},
	loadedReports: {},
	reports: {},
	idsOfReportsLoaded: []
};

/** @type {import ('./admin').Mutations} */
const mutations = {
	SET_ENGAGEMENT_REPORT_MODE(state, payload)
	{
		Vue.set(
			state.engagementReportModesByReportId,
			payload.reportId,
			payload.engagementMode
		);
	},
	SET_CURRENT_DASHBOARD_FILTERS(state, filters)
	{
		state.currentDashboardFilters = filters;
	},
	SET_REPORTS_LOADED(state, reportIds)
	{
		reportIds.forEach((reportId) =>
		{
			if(!state.idsOfReportsLoaded.includes(reportId))
			{
				state.idsOfReportsLoaded.push(reportId);
			}
		});
	},
	RESET_ALL(state)
	{
		state.dashboards = {};
		state.allDashboardsAreLoading = false;
		state.reportBeingRemoved = undefined;
		state.reportProgressByReportId = {};
		state.loadingDashboards = {};
		state.loadingReports = {};
		state.reports = {};
		state.idsOfReportsLoaded = [];
	},
	SET_DASHBOARDS(state, dashboards)
	{
		state.dashboards = keyBy(dashboards, (dash) => dash.id);
	},
	SET_DASHBOARD(state, dashboard)
	{
		Vue.set(state.dashboards, dashboard.id, dashboard);
	},
	SET_DASHBOARD_LOADING(state, { dashboardId, dashboardIsLoading })
	{
		Vue.set(state.loadingDashboards, dashboardId, dashboardIsLoading);
	},
	SET_ALL_DASHBOARDS_LOADING(state, isLoading)
	{
		state.allDashboardsAreLoading = isLoading;
	},
	SET_REPORT_LOADING(state, { reportId, reportIsLoading })
	{
		Vue.set(state.loadingReports, reportId, reportIsLoading);
	},
	SET_REPORT_LOADING_STATE(state, { reportId, loadingState })
	{
		Vue.set(state.reportsLoadingState, reportId, loadingState);
	},
	SET_REPORT_LOADED(state, { reportId, reportIsLoaded })
	{
		Vue.set(state.loadedReports, reportId, reportIsLoaded);
	},
	SET_REPORTS(state, reports)
	{
		reports.forEach((report) =>
		{
			Vue.set(state.reports, report.id, report);
		});
	},
	SET_REPORT_BEING_REMOVED(state, reportId)
	{
		state.reportBeingRemoved = reportId;
	},
	SET_REPORT_PROGRESS(state, { reportId, dbRowsMbSize, dbRowsLength })
	{
		Vue.set(state.reportProgressByReportId, reportId, {
			...state.reportProgressByReportId[reportId],
			rows: dbRowsLength || 0,
			sizeMb: dbRowsMbSize || 0
		});
	},
	REMOVE_DASHBOARD(state, dashboardId)
	{
		state.dashboards = omit(state.dashboards, dashboardId);
	},
	REMOVE_REPORT(state, reportId)
	{
		// remove the report from dashboards
		const dashboards = Object.keys(state.dashboards);

		if(!dashboards)
		{
			return;
		}

		dashboards.forEach((dashboardId) =>
		{
			if(!state.dashboards[dashboardId].reportIds)
			{
				return;
			}

			state.dashboards[dashboardId].reportIds.forEach(
				(reportIdInDashboard, reportIndex) =>
				{
					if(reportIdInDashboard === reportId)
					{
						Vue.delete(
							state.dashboards[dashboardId].reportIds,
							reportIndex
						);
					}
				}
			);
		});

		// remove from reports
		Vue.delete(state.reports, reportId);
	},
	REMOVE_REPORT_FROM_DASH(state, { dashboardId, reportId })
	{
		Vue.delete(state.dashboards[dashboardId], reportId);
	},
	SET_REPORT(state, report)
	{
		if(!report) return;

		Vue.set(state.reports, report.id, report);
	}
};

/** @type {import ('./admin').Actions} */
const actions = {
	handleDashboardSocketMessage({ commit }, dashboardDefinition)
	{
		// The listener is in syncSocketAndStore.js

		commit('SET_DASHBOARD', dashboardDefinition);
	},
	handleReportsSocketMessage({ commit }, reports)
	{
		// The listener is in syncSocketAndStore.js

		reports.forEach((report) =>
		{
			if(process.env.NODE_ENV !== 'production')
			{
				console.info(`[DEV] socket report (title: ${report?.report?.title} id: ${report?.report?.id}))`, report);
			}

			commit('SET_REPORTS', [report.report]);
			commit('SET_REPORT_LOADING_STATE', {
				reportId: report.report.id,
				loadingState: report.loadingState
			});

			// allows user knowing that something is happening
			// by telling them that lots of MBs of data are being processed
			commit('SET_REPORT_PROGRESS', {
				reportId: report.report.id,
				dbRowsLength: report.progress?.rows || 0,
				dbRowsMbSize: report.progress?.sizeMb || 0
			});
		});
	},
	setReportEngagementMode({ commit }, payload)
	{
		commit('SET_ENGAGEMENT_REPORT_MODE', payload);
	},
	async setDashboardFilters({ commit, dispatch }, payload)
	{
		commit('SET_CURRENT_DASHBOARD_FILTERS', payload.filters);

		await dispatch('loadDashboard', {
			dashboardId: payload.dashboardId
		});
	},
	async waitForSocketListeners({ rootGetters })
	{
		while(!rootGetters['app/isConnected'])
		{
			// eslint-disable-next-line no-await-in-loop
			await Utils.sleep(50);
		}
	},
	async loadDashboard({ commit, dispatch, getters }, opts)
	{
		const { refreshCache, dashboardId } = opts;

		try
		{
			await dispatch('waitForSocketListeners');

			commit('SET_DASHBOARD_LOADING', {
				dashboardId,
				dashboardIsLoading: true
			});

			const queryParams = queryString({
				filterOverrides: getters.currentDashboardFilters,
				refreshCache,
				userEngagementModeByReport: getters.allReportsEngagementMode
			});

			await get(`/insights2/dashboard/${dashboardId}${queryParams}`);

			return true;
		}
		catch(err)
		{
			console.error(err);

			return false;
		}
		finally
		{
			commit('SET_DASHBOARD_LOADING', {
				dashboardId,
				dashboardIsLoading: false
			});
		}
	},
	/*
	 * Loads ALL dashboards and the list of their reports.
	 * Reports are only loaded when a user checks out a dashboard.
	 */
	async loadAllDashboards({ commit })
	{
		try
		{
			commit('SET_ALL_DASHBOARDS_LOADING', true);

			/** @type {{data: import('acb-lib').Items.InsightsDashboards.GetAllDashesRes}} */
			const res = await get('/insights2/dashboards');

			if(!res.data) return [];

			if(Errors.assertStdError(res.data))
			{
				throw new Error('Could not load dashboards.');
			}

			const { dashboards, reportDefinitions } = res.data;

			if(
				!Array.isArray(dashboards) ||
				!Array.isArray(reportDefinitions)
			)
			{
				return [];
			}

			reportDefinitions.forEach((report) =>
			{
				// by default, no report is loaded
				commit('SET_REPORT_LOADED', {
					reportId: report.id,
					reportIsLoaded: false
				});

				commit('SET_REPORT_LOADING_STATE', {
					reportId: report.id,
					loadingState: 'idle'
				});
			});

			commit('SET_DASHBOARDS', dashboards);
			commit('SET_REPORTS', reportDefinitions);
			commit('SET_ALL_DASHBOARDS_LOADING', false);

			return res.data || [];
		}
		catch(err)
		{
			console.error(err);
		}
		finally
		{
			// dashboardsAreLoading.value = false;
		}

		return [];
	},
	async createNewDashboard({ dispatch }, newDashData)
	{
		try
		{
			/** @type {{data: import('acb-lib').Items.InsightsDashboards.NewDashRes}} */
			const res = await post('/insights2/dashboard', newDashData);

			await dispatch('loadAllDashboards');

			return res.data;
		}
		catch(err)
		{
			console.error(err);

			return false;
		}
	},
	async updateDashboard({ commit }, payload)
	{
		try
		{
			const res = await put(
				`/insights2/dashboard/${payload.id}`,
				payload
			);

			if(Errors.assertStdError(res.data))
			{
				throw new Error('Could not update the dashboard.');
			}

			commit('SET_DASHBOARD', {
				title: res.data.title,
				id: res.data.id,
				_isDefault: res.data._isDefault,
				reportIds: res.data.reportIds
			});

			return res;
		}
		catch(err)
		{
			console.error(err);

			return false;
		}
	},
	async deleteDashboard({ commit }, payload)
	{
		try
		{
			/** @type {{data: import('acb-lib').Items.InsightsReports.UpdateReportRes}} */
			const res = await destroy(
				`/insights2/dashboard/${payload.dashboardId}`
			);

			if(Errors.assertStdError(res.data))
			{
				throw new Error('Could not delete the dashboard.');
			}

			typeof payload.callback === 'function' && payload.callback();

			commit('REMOVE_DASHBOARD', payload.dashboardId);

			return res;
		}
		catch(err)
		{
			console.error(err);

			return err;
		}
	},
	async setLoading({ commit }, payload)
	{
		commit('SET_REPORT_LOADING', {
			reportId: payload.reportId,
			reportIsLoading: payload.loading
		});
	},

	async loadOneReport({ getters, commit, dispatch }, payload)
	{
		try
		{
			dispatch('setLoading', {
				reportId: payload.reportId,
				loading: true
			});

			commit('SET_REPORT_LOADING_STATE', {
				reportId: payload.reportId,
				loadingState: 'idle'
			});

			let url = `/insights2/report/${payload.reportId}`;

			if(payload.refreshCache !== false)
			{
				url += '/refreshCache';
			}

			const { currentDashboardFilters } = getters;

			const reportEngagementMode = getters.reportEngagementMode(
				payload.reportId
			);

			url += queryString({
				userEngagementMode: reportEngagementMode,
				filterOverrides: currentDashboardFilters
			});

			await dispatch('waitForSocketListeners');

			await get(url);

			await Utils.sleep(1000);

			return true;
		}
		catch(err)
		{
			console.error(err);

			return false;
		}
		finally
		{
			dispatch('setLoading', {
				reportId: payload.reportId,
				loading: false
			});
		}
	},
	async newReport({ commit, dispatch, getters }, newReportData)
	{
		try
		{
			const {
				_addToDashboardWithId
			} = newReportData;

			/** @type {{data: import('acb-lib').Items.InsightsReports.NewReportRes}} */
			const res = await post('/insights2/report', newReportData);

			// we should never get there if there was
			// an error, it's just to have the type guard
			if(Errors.assertStdError(res.data))
			{
				throw new Error(
					'The API did not return the new report ID, something went wrong.'
				);
			}

			const reportCreatedAndProcessed = res.data;

			commit('SET_REPORTS', [reportCreatedAndProcessed]);

			dispatch('setIdOfEditPanel', { reportId: res.data.id });

			const currentDashData = getters.getDashData(_addToDashboardWithId);

			if(!currentDashData)
			{
				return undefined;
			}

			commit('SET_DASHBOARD', {
				...currentDashData,
				reportIds: [res.data.id, ...(currentDashData.reportIds || [])]
			});

			return true;
		}
		catch(err)
		{
			console.error(err);

			return false;
		}
	},
	async updateReport({ commit, getters }, payload)
	{
		try
		{
			if(payload.quiet !== true)
			{
				commit('SET_REPORT_LOADING', {
					reportId: payload.reportId,
					reportIsLoading: true
				});
			}

			// This is so that when a report is reloaded after it's been
			// updated, dashboard filters and user engagement tabs are
			// preserved.
			const queryParams = queryString({
				filterOverrides: getters.currentDashboardFilters,
				userEngagementMode: getters.reportEngagementMode(
					payload.reportId
				)
			});

			await put(`/insights2/report/${payload.reportId}${queryParams}`, {
				...payload.reportData,
				graphData: undefined,
				tableData: undefined
			});

			return true;
		}
		catch(err)
		{
			console.error(err);

			return false;
		}
		finally
		{
			if(payload.quiet !== true)
			{
				commit('SET_REPORT_LOADING', {
					reportId: payload.reportId,
					reportIsLoading: false
				});
			}
		}
	},
	setReportBeingRemoved({ commit }, reportId)
	{
		commit('SET_REPORT_BEING_REMOVED', reportId);
	},
	unsetReportBeingRemoved({ commit })
	{
		commit('SET_REPORT_BEING_REMOVED', undefined);
	},
	async removeReportFromDash({ commit, dispatch }, payload)
	{
		dispatch('setReportBeingRemoved', payload.reportId);

		try
		{
			await put(
				`/insights2/dashboard/${payload.dashboardId}/removeReport/${payload.reportId}`
			);

			commit('REMOVE_REPORT_FROM_DASH', {
				dashboardId: payload.dashboardId,
				reportId: payload.reportId
			});

			return true;
		}
		catch(err)
		{
			console.error(err);

			return false;
		}
		finally
		{
			dispatch('unsetReportBeingRemoved');
		}
	},
	async deleteReportFromEverywhere({ commit, dispatch }, payload)
	{
		dispatch('setReportBeingRemoved', payload.reportId);
		try
		{
			await destroy(
				`/insights2/report/${payload.reportId}`
			);

			commit('REMOVE_REPORT', payload.reportId);

			return true;
		}
		catch(err)
		{
			console.error(err);

			return false;
		}
		finally
		{
			dispatch('setReportBeingRemoved', undefined);
		}
	},
	setReports(context, reports)
	{
		context.commit('SET_REPORTS', reports);
		context.commit(
			'SET_REPORTS_LOADED',
			reports.map((report) => report.id)
		);
	},
	newStatEditPanel(
		{ dispatch },
		{
			reportId = undefined,
			addToEnd = false,
			readOnly = false
		} = {}
	)
	{
		const type = !reportId ?
			cAdminPanelTypes.adminNewInsightsReport :
			cAdminPanelTypes.adminEditInsightsReport;

		dispatch(
			// @ts-ignore
			'admin/panel/new',
			{ type, reportId, readOnly, addToEnd },
			{ root: true }
		);
	},
	setIdOfEditPanel({ dispatch, rootGetters }, { reportId = undefined } = {})
	{
		if(rootGetters['admin/panel/activeStack']?.reportId)
		{
			// there's already a reportId, let's not change it.
			return;
		}

		// @ts-ignore
		dispatch('admin/panel/editActivePanel', { reportId }, { root: true });
	}
};

/** @type {import ('./admin').Getters} */
const getters = {
	areReportsOfDashLoaded: (state) => (dashId) =>
	{
		const reportsOfDash = state.dashboards[dashId]?.reportIds || [];

		if(!reportsOfDash.length) return false;

		return reportsOfDash.every((reportId) =>
			state.idsOfReportsLoaded.includes(reportId));
	},
	getAllDashboards: (state) => Object.values(state.dashboards) || [],
	getDashData: (state, getters) => (dashId) =>
	{
		const dashDef = state.dashboards[dashId];

		if(!dashDef) return undefined;

		return {
			...dashDef,
			reports: (dashDef.reportIds || []).map((reportId) =>
				getters.getReportById(reportId))
		};
	},
	getReportById: (state) => (reportId) =>
	{
		return state.reports[reportId];
	},
	getReportLoadingState: (state) => (reportId) =>
	{
		return state.reportsLoadingState[reportId] || 'idle';
	},
	isDashLoading: (state) => (dashId) => state.loadingDashboards[dashId],
	areAllDashboardsLoading: (state) => state.allDashboardsAreLoading,
	isReportLoading: (state) => (reportId) =>
		state.loadingReports[reportId] === true,
	isReportLoaded: (state) => (reportId) =>
	{
		return (
			state.loadedReports[reportId] === true &&
			!!state.reports[reportId]._data
		);
	},
	loadingProgressOfReportById: (state) => (reportId) =>
	{
		return (
			state.reportProgressByReportId[reportId] || {
				rows: 0,
				sizeMb: 0
			}
		);
	},
	getReportBeingRemoved: (state) => state.reportBeingRemoved,
	reportEngagementMode: (state) => (reportId) =>
	{
		return state.engagementReportModesByReportId[reportId];
	},
	allReportsEngagementMode: (state) =>
	{
		return state.engagementReportModesByReportId;
	},
	isAnyDashLoading: (state) =>
	{
		return Object.values(state.loadingDashboards).some(Boolean);
	},
	currentDashboardFilters: (state) =>
	{
		return state.currentDashboardFilters;
	}
};

const store = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

export default store;
