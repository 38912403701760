<template>
	<div
		v-if="defer(4)"
		@click="onClick"
	>
		<div v-if="loading" style="position:absolute;top:0;left:0;width:100%;display:flex;flex-direction:column;align-items:center;">
			<div style="text-align:center;display:flex;flex-grow:1;padding:2em 45%;opacity:0.5;filter:blur(1px);">
				<q-spinner :size="40" />
			</div>
		</div>
		<!-- eslint-disable vue/no-v-html -->
		<div
			:style="loading ? 'opacity: 0.4; filter: blur(4px)' : ''"
			:class="['template', ...templateOptionClasses]"
			v-html="renderedTemplate"
		/>
		<!-- eslint-enable -->
	</div>
</template>

<script>
	// @ts-check
	import Handlebars from 'handlebars';
	import { ref, watch } from 'vue';
	import throttle from 'lodash/throttle';
	import debounce from 'lodash/debounce';
	import sh from 'shorthash';
	// eslint-disable-next-line import/no-unassigned-import
	// eslint-disable-next-line no-unused-vars
	import linkifyHtml from 'linkify-html';
	import * as helper from '@/utils/handlebars';
	import BlockMixin, {
		useBlockMixin
	} from '@/components/blocks/BlockMixin';
	import EntityMixin from '@/components/mixins/Entity';
	import Defer from '@/components/mixins/Defer';
	import {
		getLabelsForOptions,
		getRepeaterTranslations,
		isThisFieldTypeTranslatable,
		retrieveFieldsById,
		retrieveFieldsByKey,
		sortRepeaterData
	} from '@/utils/handlebarsCache';
	import { loadDefaultsForSorting } from '@/utils/fieldSorting';
	import { getBlockDefault } from '@/components/blocks/blockDefinitions';
	import { getIsUserOnlineComputed } from '@/utils/userDeviceKeepalive';
	import { handleLinkClickLogging } from '@/composables/linkClickLogging';

	/**
	 * @template T
	 * @typedef {import('vue').Ref<T>} Ref<T>
	 */
	/**
	 * @template T
	 * @typedef {import('vue').ComputedRef<T>} ComputedRef<T>
	 */

	export default {
		name: 'HandlebarsBlock',
		mixins: [BlockMixin, EntityMixin, Defer()],
		inject: {
			$searchBlockStore: {
				default: null
			},
			$searchState: {
				default: null
			},
			displayLoading: {
				default: null
			}
		},
		data()
		{
			return {
				cachedTemplateData: {},
				loading: false,
				generateTemplateVariablesDebounced: debounce(this.generateTemplateVariablesAndStopLoading, 150)
			};
		},
		/**
		 * @param {import(
		 * 	'@/types/components/blocks/blockMixin'
		 * ).BlockMixin.Props} props
		 */
		setup(props)
		{
			const {
				accountId
			} = useBlockMixin(props);
			/**
			 * Need to assign the shared throttled function.
			 * @type {Ref<Function>}
			 */
			const generateTemplateVariablesAndStopLoadingRef = ref(() =>
			{
				// This ref must be provided by setup()
				// and assigned by the component.
				throw new Error(
					// eslint-disable-next-line no-multi-str
					'DEV ERROR: The generateTemplateVariablesAndStopLoadingRef \
was not assigned.'
				);
			});
			// const generateTemplateVariablesAndStopLoading = throttle(
			// 	function generateTemplateVariablesAndStopLoading()
			// 	{
			// 		// TODO: Add code from Options API's function.
			// 	}
			// );
			/**
			 * Counterpart of this component's method.
			 */
			const generateTemplateVariablesAndStopLoading = () =>
			{
				generateTemplateVariablesAndStopLoadingRef.value();
			};
			const isTargetUserOnline = getIsUserOnlineComputed(
				accountId
			);

			watch(isTargetUserOnline, () =>
			{
				generateTemplateVariablesAndStopLoading();
			});

			return {
				isTargetUserOnline,
				generateTemplateVariablesAndStopLoadingRef
			};
		},
		computed: {
			// appLoading()
			// {
			// 	return this.$store.getters['app/loading'];
			// },
			localeActive()
			{
				return this.$store.getters['i18n/localeActive'];
			},
			enabledLanguages()
			{
				return this.$store.getters['i18n/enabledLanguages'];
			},
			block()
			{
				return this.$store.getters['structure/blocks/getBlock'](this.blockId);
			},
			templateWithNoBreaks()
			{
				return this.data?.parsedTemplate?.replaceAll(/\n+/g, ' ');
			},
			template()
			{
				return Handlebars.compile(this.templateWithNoBreaks || '');
			},
			renderedTemplate()
			{
				let template;

				try
				{
					// this refers to the Handlebars.template()
					template = this.template(this.cachedTemplateData);

					if(this.autoLink)
					{
						template = linkifyHtml(template, {
							attributes: {
								target: '_blank'
							},
							ignoreTags: ['style', 'svg', 'path', 'script', 'meta', 'head']
						});
					}
				}
				catch(e)
				{
					// do nothing... the error is output onto the admin panel when the content is saved
					// console.log('Something went wrong here');
					// console.log(e.message);
				}

				return template;
			},
			currentUserAccountId()
			{
				if(this.targetIdOverride) return this.targetIdOverride;

				return this.$store.getters['user/accountId'];
			},
			fieldsHash()
			{
				return sh.unique(JSON.stringify(this.$store.getters['structure/fields/getFields']));
			},
			fieldDefaultsHash()
			{
				return sh.unique(JSON.stringify(this.$store.getters['structure/fields/getDefaults']));
			},
			userData()
			{
				const data = this.$store.getters['profiles/getSimpleData'](this.currentUserAccountId);

				return this.sortRepeaterData(data);
			},
			userOptions()
			{
				return this.getLabelsForOptions(this.userData);
			},
			targetUserData()
			{
				const data = this.$store.getters['profiles/getSimpleData'](this.accountId);

				return this.sortRepeaterData(data);
			},
			targetUserOptions()
			{
				return this.getLabelsForOptions(this.targetUserData);
			},
			entityData()
			{
				if(this.entityIdToUse)
				{
					const data = this.$store.getters['entities/getFlatData'](this.entityIdToUse);

					return this.sortRepeaterData(data);
				}

				return null;
			},
			entityOptions()
			{
				if(this.entityIdToUse)
				{
					return this.getLabelsForOptions(this.entityData);
				}

				return {};
			},
			currentUsersLanguage()
			{
				return this.$store.getters['user/metaValue']('language', 'meta');
			},
			languageData()
			{
				return this.$store.getters['i18n/language'](this.currentUsersLanguage);
			},
			searchData()
			{
				return this.$searchState;
			},
			keyAliasData()
			{
				const { entityData, targetUserData, userData } = this;

				return Object.entries(this.$store.getters['app/settings/mappedKeyAliases']).reduce((keyAlias, [alias, keyName]) =>
				{
					if(entityData?.[keyName])
					{
						keyAlias.entity[alias] = this.entityData[keyName];

						return keyAlias;
					}

					if(userData?.[keyName])
					{
						keyAlias.user[alias] = userData[keyName];
					}

					if(targetUserData?.[keyName])
					{
						keyAlias.profile[alias] = targetUserData[keyName];
					}

					return keyAlias;
				}, { user: {}, profile: {}, entity: {} });
			},
			keyAliasOptions()
			{
				const { entityOptions, userOptions, targetUserOptions } = this;

				return Object.entries(this.$store.getters['app/settings/mappedKeyAliases']).reduce((keyAlias, [alias, keyName]) =>
				{
					if(entityOptions?.[keyName])
					{
						keyAlias.entity[alias] = this.entityOptions[keyName];

						return keyAlias;
					}

					if(userOptions?.[keyName])
					{
						keyAlias.user[alias] = userOptions[keyName];
					}

					if(targetUserOptions?.[keyName])
					{
						keyAlias.profile[alias] = targetUserOptions[keyName];
					}

					return keyAlias;
				}, { user: {}, profile: {}, entity: {} });
			},
			// cachedTemplateData()
			// {
			// 	return this.generateTemplateVariablesDebounced();
			// 	// return this.$store.getters['handlebars/cacheValue'](this.cacheParams);
			// },
			entityIdToUse()
			{
				// eslint-disable-next-line no-restricted-globals
				return isNaN(this.entityId) ? this.entityId : null;
			},
			templateOptionClasses: {
				get()
				{
					if(this.block?.templateOptions)
					{
						return Object.entries(this.block.templateOptions).map(([key, value]) => (value ? `templateOption-${key}` : null))
							.filter((option) => option);
					}

					return [];
				}
			},
			autoLink()
			{
				const autoLinkDefault = getBlockDefault(this.block.type, 'options.autoLink');

				return this.block?.options?.autoLink ?? autoLinkDefault;
			}
		},
		watch: {
			userData: {
				handler()
				{
					this.generateTemplateVariablesDebounced();
				},
				deep: true
			},
			targetUserData: {
				handler()
				{
					this.generateTemplateVariablesDebounced();
				},
				deep: true
			},
			entityData: {
				handler()
				{
					this.generateTemplateVariablesDebounced();
				},
				deep: true
			},
			fieldsHash(newVal, oldVal)
			{
				if(newVal !== oldVal)
				{
					this.generateTemplateVariablesDebounced();
				}
			},
			fieldDefaultsHash: {
				handler: debounce(function handler(newVal, oldVal)
				{
					if(newVal !== oldVal)
					{
						this.generateTemplateVariablesDebounced();
					}
				}, 100)
			}
		},
		async created()
		{
			this.generateTemplateVariablesAndStopLoading = throttle(this.generateTemplateVariablesAndStopLoading, 100);
			// Shared for the Composition API.
			this.generateTemplateVariablesAndStopLoadingRef = this.generateTemplateVariablesAndStopLoading;

			if(this.displayLoading)
			{
				// a form has just been saved. tell the HB block to wait for new data.
				this.loading = true;
			}

			await loadDefaultsForSorting();

			this.generateTemplateVariablesDebounced();
		},
		methods: {
			async onClick(/** @type {MouseEvent} */ event)
			{
				await handleLinkClickLogging({
					logType: 'externalLinkClicked',
					mouseEvent: event,
					store: this.$store,
					logExtraData: {
						blockId: this.blockId
					}
				});
			},
			async generateTemplateVariablesAndStopLoading()
			{
				// check if a helper has scheduled a promise to be resolved; if so, resolve it and recompile
				if(helper.hasPromises())
				{
					await helper.executePromises();
					this.generateTemplateVariablesDebounced();

					return;
				}

				this.cachedTemplateData = {
					user: this.userData,
					userAccountId: this.currentUserAccountId,
					profile: this.targetUserData,
					profileAccountId: this.accountId,
					profileIsOnline: this.isTargetUserOnline,
					entity: this.entityData,
					entityId: this.entityId,
					i18n: this.languageData,
					localeActive: this.localeActive,
					search: this.searchData,
					keyAlias: this.keyAliasData,
					__profileOptions: this.targetUserOptions,
					__userOptions: this.userOptions,
					__entityOptions: this.entityOptions,
					__keyAliasOptions: this.keyAliasOptions
				};

				this.loading = false;
			},
			isThisFieldTypeTranslatable(type)
			{
				return isThisFieldTypeTranslatable(type);
			},
			retrieveFieldsByKey(data)
			{
				const cacheKey = JSON.stringify(data);

				return retrieveFieldsByKey(cacheKey, data);
			},
			retrieveFieldsById(data)
			{
				const cacheKey = data.join();

				return retrieveFieldsById(cacheKey, data);
			},
			getLabelsForOptions(data)
			{
				if(!data) return {};

				// Field hash lets us know when fields have been updated
				// Required to update data when repeaters are sorted
				// Field defaults hash does the same for default versions of core fields
				const key = sh.unique(`${JSON.stringify(data)}-${this.fieldsHash}-${this.fieldDefaultsHash}`);

				return getLabelsForOptions(key, data);
			},
			getRepeaterTranslations(value, field)
			{
				return getRepeaterTranslations(value, field);
			},
			sortRepeaterData(data)
			{
				const cacheKey = sh.unique(`${JSON.stringify(data)}-${this.fieldsHash}-${this.fieldDefaultsHash}`);

				return sortRepeaterData(cacheKey, data);
			}
		}
	};
</script>

<style scoped>
	.template {
		border-radius: inherit; /* Ensure that design panel border-radius properly applies to HTML template wrapper */
		/* hyphens: auto; */
		position: relative; /* Set positioning context to the HTML template wrapper */
		transition: 0.25s opacity;
		white-space: pre-line;
		word-break: break-word;
	}

	.templateOption-height100 {
		height: 100%; /* Force template wrapper to occupy container space (makes percentage height values possible) */
	}

	.templateOption-disableWhitespace {
		white-space: unset; /* Disable white-space rule to allow over forms of content in textarea fields */
	}
</style>
