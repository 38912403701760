function translateToCSS(data)
{
	const { radial, radialType, rotation, position, colors } = data;

	const colorString = colors.reduce((agg, color) =>
	{
		agg += `, ${color.value} ${color.position}%`;

		return agg;
	}, '');

	return `${radial ? 'radial' : 'linear'}-gradient(${radial ? `${radialType} at ${position.x || position.x === 0 ? position.x : 50}% ${position.y || position.y === 0 ? position.y : 50}%` : `${rotation}deg`}${colorString})`;
}

function translateToObject(data)
{
	const values = data.replace(/(linear|radial)-gradient\(/, '')
		.replace(/\)$|\);/, '')
		.split(', ');

	const obj = {
		radial: false,
		radialType: 'circle',
		rotation: 0,
		position: {
			x: 50,
			y: 50
		},
		colors: []
	};

	const firstArg = values[0].replace('deg', '');

	if(Number.isNaN(Number(firstArg)))
	{
		obj.rotation = 0;
		obj.radial = true;

		if(firstArg.includes('circle'))
		{
			obj.radialType = 'circle';
		}
		else
		{
			obj.radialType = 'ellipse';
		}

		const position = firstArg.replace(/(circle|ellipse) at /, '')
			.replaceAll('%', '')
			.split(' ');

		if(position.length === 2)
		{
			obj.position.x = Number(position[0]);
			obj.position.y = Number(position[1]);
		}
	}
	else
	{
		obj.rotation = Number(firstArg);
	}

	values.shift();

	obj.colors = values.map((value) =>
	{
		const separates = value.split(' ');

		return {
			value: separates[0],
			position: Number(separates[1].replace('%', ''))
		};
	});

	return obj;
}

function isGradient(color)
{
	// check if value is css gradient colour
	if(typeof color === 'string' && color?.includes('gradient'))
	{
		return true;
	}

	// check if the object is a gradient
	if(color?.radialType)
	{
		return true;
	}

	return false;
}

export { translateToCSS, translateToObject, isGradient };
