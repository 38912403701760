export const languageIsoCodesToEnglishDisplayValue = Object.freeze({
	en: 'English',
	fr: 'French',
	cy: 'Welsh',
	ee: 'Estonian',
	ms: 'Malay',
	de: 'German',
	zh: 'Chinese',
	es: 'Spanish',
	ja: 'Japanese'
});

/**
 * Not all languages are supported by our default font - this is a list
 * of the languages that require different fonts. There needs to be a
 * corresponding CSS variable for the language, defined in a `theme.json`
 * (global or service specific) or in the `theme` table.
 * The variable name will be something like `body.font.font-family-{iso}`
 * and will produce and `--body-font-font-family-{iso}` CSS variable.
 */
export const cLanguageIsosFontsRequired = Object.freeze(['ja']);
