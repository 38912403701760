import deepmerge from 'deepmerge';
import Vue from 'vue';

const state = {
	stacks: {},
	errorMessages: [],
	activeId: null
};

const mutations = {
	CREATE_NEW(state, { id, payload })
	{
		Vue.set(state.stacks, id, payload);
	},
	DELETE_STACK(state, id)
	{
		Vue.delete(state.stacks, id);
	},
	CLEAR_STACK(state)
	{
		state.stacks = {};
		state.errorMessages = [];
		state.activeId = null;
	},
	CLEAR_ERROR_MESSAGES(state)
	{
		state.errorMessages = [];
	},
	SET_ACTIVE(state, id)
	{
		state.activeId = id;
	},
	UPDATE_ACTIVE(state, { stackId, dataToUpdate })
	{
		state.stacks[stackId] = { ...dataToUpdate };
	},
	SET_ACTIVE_PANEL_DATA(state, newData)
	{
		state.stacks[state.activeId] = deepmerge(state.stacks[state.activeId], newData);
	},
	SET_ERROR_MESSAGE(state, errorMessage)
	{
		state.errorMessages.push(errorMessage);
	}
};

const actions = {
	new({ commit, dispatch }, payload)
	{
		const id = payload.id || payload.type;

		dispatch('i18n/load', true, { root: true });

		commit('CREATE_NEW', { id, payload });
		commit('SET_ACTIVE', id);

		dispatch('audit/log', {
			logType: 'openPanel',
			subject: id,
			extra: {
				payload
			}
		}, { root: true });
	},
	closeActive({ dispatch, state })
	{
		dispatch('close', state.activeId);
	},
	editActivePanel({ commit }, newData)
	{
		commit('SET_ACTIVE_PANEL_DATA', newData);
	},
	close({ commit, dispatch }, stackId)
	{
		commit('DELETE_STACK', stackId);
		dispatch('resetActive', stackId);
	},
	resetActive({ commit, state }, stackId)
	{
		const stackIds = Object.keys(state.stacks);

		if(stackId === state.activeId)
		{
			commit('SET_ACTIVE', stackIds[stackIds.length - 1]);
		}
	},
	updateActive({ getters, commit, dispatch }, { stackId, valuesToUpdate })
	{
		if(!stackId)
		{
			stackId = state.activeId;
		}

		const stack = getters.getStack(stackId);

		if(stack && valuesToUpdate)
		{
			const dataToUpdate = {
				...stack,
				...valuesToUpdate
			};

			commit('UPDATE_ACTIVE', { stackId, dataToUpdate });
		}
	},
	clear({ commit })
	{
		commit('CLEAR_STACK');
	},
	clearErrorMessages({ commit })
	{
		commit('CLEAR_ERROR_MESSAGES');
	},
	setErrorMessage({ commit }, errorMessage)
	{
		commit('SET_ERROR_MESSAGE', errorMessage);
	}
};

const getters = {
	state: (state) => state,
	activeStack: (state) => state.stacks[state.activeId],
	getStack: (state) => (stackId) => state.stacks[stackId],
	errorMessages: (state) => state.errorMessages,
	availableStacks: (state) => Object.keys(state.stacks).length
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
