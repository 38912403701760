import deepmerge from 'deepmerge';
import { get, put, post, destroy } from '@/utils/api';
import EventBus from '@/components/admin/generic/EventBus';
import { getBlockDefault } from '@/components/blocks/blockDefinitions';
import { Utils } from 'acb-lib';
import { cStructureUpdateDelayMs } from '@/configs/constants';
import { cAdminPanelTypes } from '@/configs/adminPanelTypes';

const state = {
	templates: {},
	loading: false,
	/**
	 * When the backend is handling the Move/Unlinked Clone/Linked Copy
	 */
	pasting: false,
	isDragging: false,
	hasLoadedAll: false,
	capturedBlocks: [],
	relevantHooksByBlockType: {},
	blockToDeleteId: undefined
};

const mutations = {
	SET_TEMPLATES(state, templates)
	{
		state.templates = templates;
	},
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	},
	TOGGLE_DRAGGING(state)
	{
		state.isDragging = !state.isDragging;
	},
	SET_ALL_LOADED(state)
	{
		state.hasLoadedAll = true;
	},
	CAPTURE_BLOCK(state, block)
	{
		const index = state.capturedBlocks.findIndex((captured) => captured.target.blockId === block.target.blockId);

		if(index === -1)
		{
			state.capturedBlocks.push(block);
		}
		else
		{
			state.capturedBlocks.splice(index, 1);
		}
	},
	SET_CAPTURED(state, data = [])
	{
		state.capturedBlocks = data;
	},
	REMOVE_CAPTURED(state, targetIds = [])
	{
		targetIds.forEach((id) =>
		{
			const index = state.capturedBlocks.findIndex((block) => block.target.blockId === id);

			if(index >= 0)
			{
				state.capturedBlocks.splice(index, 1);
			}
		});
	},
	SET_RELEVANT_HOOKS_FOR_BLOCK_TYPE(state, hookData = {})
	{
		Object.entries(hookData).forEach(([blockType, hooks]) =>
		{
			state.relevantHooksByBlockType = deepmerge(state.relevantHooksByBlockType, { [blockType]: hooks });
		});
	},
	SET_BLOCK_TO_DELETE(state, blockId = undefined)
	{
		state.blockToDeleteId = blockId;
	},
	SET_PASTING(state, pasting = false)
	{
		state.pasting = pasting;
	}
};

const actions = {
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	},
	load({ state, commit })
	{
		if(Object.keys(state.templates).length === 0)
		{
			return get('structure/block/templates').then((res) =>
			{
				commit('SET_TEMPLATES', res.data);
			});
		}

		return Promise.resolve(true);
	},
	async loadAll({ dispatch, commit }, opts)
	{
		if(!state.hasLoadedAll)
		{
			dispatch('setLoading', true);

			const { data } = await get('structure/block', { params: opts });

			dispatch('setLoading', false);
			dispatch('structure/admin/handleServerChanges', data, { root: true });
			commit('SET_ALL_LOADED');
		}
	},
	async loadOne({ dispatch }, id)
	{
		dispatch('setLoading', true);
		const { data } = await get(`/structure/block/${id}`);

		dispatch('app/handleServerResponse', data, { root: true });
		dispatch('setLoading', false);
	},
	async loadRelevantHooksForBlock({ commit, getters }, blockId)
	{
		if(!getters.relevantHooksById(blockId).length)
		{
			try
			{
				const { data: relevantHookData } = await get(`structure/block/${blockId}/amISubscribable`);

				if(Object.keys(relevantHookData).length)
				{
					commit('SET_RELEVANT_HOOKS_FOR_BLOCK_TYPE', relevantHookData);
				}
			}
			catch(e)
			{
				console.error(e.message);
			}
		}
	},
	async search({ dispatch }, {
		page = 1,
		limit = 10,
		filters = {},
		sortBy = null,
		descending = false,
		includeUsages = false
	})
	{
		const { data } = await get('/structure/block/search', {
			params: {
				page,
				limit,
				filters,
				sortBy,
				descending,
				includeUsages
			}
		});

		const itemData = data.items.map((item) => item.data);

		dispatch('structure/blocks/setBlocks', itemData, { root: true });

		return data;
	},
	openPanel({ dispatch }, opts = {})
	{
		const payload = {
			type: 'newBlock',
			opts
		};

		dispatch('admin/panel/new', payload, { root: true });
	},
	openInfoPanel({ dispatch }, opts = {})
	{
		const payload = {
			type: cAdminPanelTypes.newBlockInfo,
			opts
		};

		dispatch('admin/panel/new', payload, { root: true });
	},
	openExistingBlocksPanel({ dispatch }, opts = {})
	{
		const payload = {
			type: cAdminPanelTypes.existingBlockPicker,
			opts
		};

		dispatch('admin/panel/new', payload, { root: true });
	},
	async upsert({ dispatch, rootGetters }, block)
	{
		try
		{
			const opts = block._opts || {};
			let data;

			delete block._opts;

			if(block.id)
			{
				({ data } = await post(`structure/block/${block.id}`, { block }));
			}
			else
			{
				let page;

				if(block._pageId)
				{
					page = rootGetters['structure/pages/getPageById'](block._pageId);
					delete block._pageId;
				}
				else
				{
					page = rootGetters['structure/pages/getActivePage'];
				}

				const pageId = (page && page.id) || undefined;

				block = { ...block, ...state.templates[block.type] };

				const payload = {
					pageId: opts.noParent ? undefined : pageId,
					block,
					parentId: block._parentId,
					colIndex: opts.colIndex,
					index: opts.index,
					asParentFor: opts.asParentFor,
					related: opts.related,
					defaults: opts.defaults
				};

				({ data } = await put('structure/block', payload));
			}

			await dispatch('structure/admin/handleServerChanges', data, { root: true });

			return data;
		}
		catch(e)
		{
			console.warn(e);

			return null;
		}
	},
	async upsertBatch({ dispatch }, blocks)
	{
		const { data } = await post('structure/block', { blocks });

		await dispatch('structure/admin/handleServerChanges', data, { root: true });
	},
	async edit({ dispatch, rootGetters }, { blockId, changes, sendToServer = true })
	{
		if(!blockId) throw new Error('You must provide a block ID');

		const block = rootGetters['structure/blocks/getBlock'](blockId);

		// overwrite arrays
		const updatedBlock = await deepmerge(block, changes, { arrayMerge: (d, s) => s });
		// const updatedBlock = { ...block, ...changes };

		if(sendToServer)
		{
			return dispatch('upsert', updatedBlock);
		}

		return updatedBlock;
	},
	async updateProperty({ dispatch }, { blockId, key, value })
	{
		if(!blockId) throw new Error('You must provide a block ID');

		try
		{
			const { data } = await post(`structure/block/${blockId}/property`, { key, value });

			await dispatch('structure/admin/handleServerChanges', data, { root: true });
		}
		catch(e)
		{
			console.warn(e);
		}
	},
	async edits({ dispatch }, blocks)
	{
		const editPromises = blocks.map(async (block) => dispatch('edit', { blockId: block.blockId, changes: block.changes, sendToServer: false }));
		const editResponses = await Promise.all(editPromises);

		await dispatch('upsertBatch', editResponses);
	},
	async delete({ dispatch }, { id, colIndex, index, parentMeta })
	{
		const { data } = await destroy(`structure/block/${id}/${parentMeta.type}/${parentMeta.id}/${colIndex}/${index}`);

		dispatch('structure/admin/handleServerChanges', data, { root: true });

		dispatch('structure/admin/setRevertToken', data.revertToken, { root: true });
	},
	async deleteEverywhere({ dispatch }, { id })
	{
		const { data } = await destroy(`structure/block/${id}`);

		dispatch('setWasDeletedState', { blockId: id, wasDeleted: true });

		dispatch('structure/admin/setRevertToken', data.revertToken, { root: true });

		// Make the block disappear with a transition first - it's easier
		// to visually confirm that what was supposed to happen did happen.
		await Utils.sleep(cStructureUpdateDelayMs);

		dispatch('setWasDeletedState', { blockId: id, wasDeleted: false });
		dispatch('setBlockToDelete');

		await dispatch('structure/admin/handleServerChanges', data, { root: true });
	},
	async addFieldToBlock({ dispatch }, { blockId, fieldId })
	{
		if(!blockId || !fieldId)
		{
			throw new Error('You must provide a field and block ID');
		}

		const { data } = await put(`structure/block/${blockId}/addField/${fieldId}`);

		await dispatch('structure/admin/handleServerChanges', data, { root: true });

		return data;
	},
	async toggleDraggingState({ commit })
	{
		commit('TOGGLE_DRAGGING');
	},
	async move({ dispatch }, { changes, fromPageId, toPageId })
	{
		const { data } = await post('structure/block/move', { updates: [changes], fromPageId, toPageId });

		dispatch('structure/admin/setRevertToken', data.revertToken, { root: true });
		await dispatch('structure/admin/handleServerChanges', data, { root: true });
	},
	async moveMultiple({ dispatch }, { changes, fromPageId, toPageId })
	{
		const { data } = await post('structure/block/move', { updates: changes, fromPageId, toPageId });

		dispatch('structure/admin/setRevertToken', data.revertToken, { root: true });
		await dispatch('structure/admin/handleServerChanges', data, { root: true });

		return data;
	},
	async cloneMultiple({ dispatch }, { changes, fromPageId, toPageId })
	{
		const { data } = await post('structure/block/clone', { updates: changes, fromPageId, toPageId });

		dispatch('structure/admin/setRevertToken', data.revertToken, { root: true });
		await dispatch('structure/admin/handleServerChanges', data, { root: true });

		return data;
	},
	async addNewBlock({ dispatch, rootGetters }, blockDefinition)
	{
		const { key: blockType } = blockDefinition;
		const defaults = getBlockDefault(blockType);

		try
		{
			const currentStack = rootGetters['admin/panel/activeStack'];

			const data = await dispatch('upsert', {
				type: blockType,
				_parentId: currentStack.opts && currentStack.opts.parent,
				_pageId: currentStack.opts && currentStack.opts.pageId,
				_opts: {
					colIndex: currentStack.opts && currentStack.opts.colIndex,
					index: currentStack.opts && currentStack.opts.index,
					noParent: (currentStack.opts && currentStack.opts.noParent) || undefined,
					asParentFor: (currentStack.opts && currentStack.opts.asParentFor) || undefined,
					related: (currentStack.opts && currentStack.opts.related) || undefined,
					defaults
				}
			});

			const block = rootGetters['structure/blocks/getBlock'](data.id);

			dispatch('admin/panel/clear', null, { root: true });

			const noEditAfterAdd = currentStack.opts && currentStack.opts.noEditAfterAdd;

			if(!noEditAfterAdd)
			{
				dispatch('admin/panel/new', {
					id: `edit-${block.id}`,
					type: cAdminPanelTypes.editBlock,
					subType: block.type,
					subTitle: `custom.blocks.${block.id}.title`,
					block,
					scopeProvider: currentStack.opts.scopeProvider
				}, { root: true });
			}

			EventBus.$emit('admin:create:block', block);

			return block;
		}
		catch(e)
		{
			// TODO: handle this somehow.
			console.error('Failed to add block', e);

			return null;
		}
	},
	async addChild({ dispatch }, { colIndex, index, childId, blockId, pageId = null })
	{
		let data;

		if(pageId && !blockId)
		{
			({ data } = await put(`/structure/page/${pageId}/addChild/${childId}/${index}`));
		}
		else
		{
			({ data } = await post(`/structure/block/${blockId}/${colIndex}/${index}/${childId}`));
		}

		await dispatch('structure/admin/handleServerChanges', data, { root: true });
	},
	captureBlock({ commit }, { target, from, meta })
	{
		commit('CAPTURE_BLOCK', { target, from, meta });
	},
	emptyCaptured({ commit })
	{
		commit('SET_CAPTURED', []);
	},
	removeCaptured({ commit }, targetIds)
	{
		commit('REMOVE_CAPTURED', targetIds);
	},
	async pasteCaptured({ commit, dispatch, getters, rootGetters }, { to, action })
	{
		const {
			parentMeta: {
				id: targetId
			},
			colIndex,
			index
		} = to;
		let indexModifier = 0;

		commit('SET_PASTING', true);

		const changes = getters.capturedBlocks.map((block) =>
		{
			const { target, from } = block;
			const data = {
				target,
				from,
				to: {
					...to,
					index: index + indexModifier
				}
			};

			indexModifier += 1;

			return data;
		});

		// since the pages we can take the items from are varied, let's just use the target paste page id as the source page too until we come up with a better solution
		const toPageId = (rootGetters['app/isAdminDashboard'] && rootGetters['app/getCurrentRoute'].params.pageId) ||
			rootGetters['app/getActivePageId'] || {};
		let blocks = [];

		if(action === 'clone' || action === 'move')
		{
			({ changes: { blocks } } = await dispatch(
				action === 'clone' ? 'cloneMultiple' : 'moveMultiple',
				{ changes, fromPageId: toPageId, toPageId }
			));
		}
		else if(action === 'copy')
		{
			const { index } = to;
			const childrenIds = getters.capturedBlocks.map((block) => block.target.blockId);
			let data;

			if(toPageId === targetId)
			{
				({ data } = await post(`/structure/page/${toPageId}/addChildren/${index}`, { childrenIds }));
			}
			else
			{
				({ data } = await post(`/structure/block/${targetId}/${colIndex}/${index}`, { childrenIds }));
			}

			dispatch('structure/admin/setRevertToken', data.revertToken, { root: true });
			await dispatch('structure/admin/handleServerChanges', data, { root: true });

			blocks = data.changes.blocks;
		}
		else
		{
			throw new Error('Unknown block action: ', action);
		}

		commit('SET_PASTING', false);

		dispatch('removeCaptured', blocks?.map((block) => block.id));
	},
	setBlockToDelete({ commit }, blockId)
	{
		commit('SET_BLOCK_TO_DELETE', blockId);
	},
	setWasDeletedState({ dispatch }, { blockId, wasDeleted })
	{
		dispatch(
			'structure/blocks/setBlockProperty',
			{
				blockId,
				property: 'wasDeleted',
				value: wasDeleted
			},
			{ root: true }
		);
	}
};

const getters = {
	loading: (state) => state.loading,
	getTemplates: (state) => state.templates,
	isDraggingModeActive: (state) => state.isDragging,
	isPasting: (state) => state.pasting,
	allTypes: (state, getters, rootState, rootGetters) => Array.from(
		new Set(
			Object.values(
				rootGetters['structure/blocks/getBlocks']
			).map((block) => block.type)
		)
	),
	capturedBlocks: (state) => state.capturedBlocks,
	capturedById: (state) => (id) => state.capturedBlocks.find((block) => block.target.blockId === id),
	relevantHooksById: (state, _0, _1, rootGetters) => (blockId) =>
	{
		const block = rootGetters['structure/blocks/getBlock'](blockId);

		if(block)
		{
			const { type: blockType } = block;

			if(blockType && state.relevantHooksByBlockType[blockType] && Array.isArray(state.relevantHooksByBlockType[blockType]))
			{
				return state.relevantHooksByBlockType[blockType];
			}
		}

		return [];
	},
	blockToDelete: (state) => state.blockToDeleteId
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
