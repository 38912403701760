// @ts-check

/**
 * @typedef Opts
 * @property {boolean=} isNotALink
 * @property {MouseEvent=} mouseEvent
 * @property {string=} linkTargetOverride
 * @property {string=} linkOverride An optional link override (the
 * 	href attribute is used otherwise, provided the user clicked on an
 * 	anchor element).
 * @property {"internalModalOpened" | "externalLinkClicked"} logType
 * @property {any} store
 * @property {(Record<string, unknown>)=} logExtraData
 */

/**
 * Allows recording user clicks on links.
 * By default, will use the `href` attribute on links — but you
 * can specify another link.
 *
 * Respects users doing an `alt`+`click` to open in a new tab (and
 * still records the click).
 *
 * You can also override the `target` attribute of the link.
 *
 * You can also prevent the link from navigating away once it's been recorded.
 * For instance, if the link opens a modal.
 *
 * Halts the click, records it, then triggers it again.
 * @param {Opts} opts
 * @returns {Promise<void>}
 */
export async function handleLinkClickLogging(opts)
{
	// Temporarily disabled until we find something better.

	// if(!opts.store)
	// {
	// 	console.error('You need to provide the store!');
	// }

	// if(!['internalModalOpened', 'externalLinkClicked'].includes(opts.logType))
	// {
	// 	console.error('Invalid logType provided!');
	// }

	// let href = '';

	// let target = '';

	// // note — this will not capture "right-click > open in new tab"
	// // You can listen for the `contextmenu` event but not for the
	// // "open in new tab" one 😢

	// if(opts.mouseEvent)
	// {
	// 	const anchorElement = /** @type {HTMLAnchorElement} */ (opts.mouseEvent.target);

	// 	href = anchorElement.href;

	// 	// Ignore clicks if it's not a link and if there's no link override.
	// 	// Note — this will miss where `<a>` tag contains other elements.
	// 	if(!opts.isNotALink && !opts.linkOverride && anchorElement.localName !== 'a')
	// 	{
	// 		return;
	// 	}

	// 	opts.mouseEvent.preventDefault();

	// 	try
	// 	{
	// 		if(new URL(anchorElement.href).origin === window.origin)
	// 		{
	// 			// ignore - we already record this in other ways
	// 			return;
	// 		}
	// 	}
	// 	catch(err)
	// 	{
	// 		// new URL() will throw if the URL is invalid but we don't care.
	// 	}

	// 	target = anchorElement.target;

	// 	// Think different
	// 	const isMac = window.navigator.userAgent.includes('Mac OS X');

	// 	const userRequestedNewTab = isMac ? opts.mouseEvent.metaKey : opts.mouseEvent.ctrlKey;

	// 	if(userRequestedNewTab)
	// 	{
	// 		target = 'blank';
	// 	}
	// }

	// // overrides always win
	// if(opts.linkTargetOverride)
	// {
	// 	target = opts.linkTargetOverride;
	// }

	// if(opts.linkOverride)
	// {
	// 	href = opts.linkOverride;
	// }

	// const log = opts.store.dispatch('audit/logImmediate', {
	// 	logType: opts.logType,
	// 	subject: href,
	// 	extra: opts.logExtraData
	// });

	// if(target !== 'blank')
	// {
	// 	// We need to record the click before
	// 	// navigating away (unless we're opening a new tab,
	// 	// since the current one will remain open).
	// 	await log;
	// }

	// if(!href)
	// {
	// 	return;
	// }

	// const a = document.createElement('a');

	// // a.href = this.btnLink;
	// a.href = href;

	// a.target = target ? `_${target}` : '';

	// a.click();
}
