<template>
	<div v-if="showFields">
		<div
			v-for="(field, i) in fields"
			:key="`${formId}-${field.id}`"
			:class="[
				{ fieldContainer: field.type !== 'hidden' && field.type !== 'textarea' && field.type !== 'repeater' },
				{ textareaContainer: field.type === 'textarea' },
				{ flexContainer: $formOrigin === 'BasicForm' || field.infoPopup },
				isChildOfRepeater(field.id) && shouldShowPrivacyControls(field) ? 'fieldInput--repeater' : 'fieldInput',
				{ 'fieldInput--repeaterParent': field.type === 'repeater' }
			]"
		>
			<q-icon
				v-if="field.infoTooltip"
				class="q-my-sm q-ml-sm"
				size="sm"
				style="float: right"
				round
				flat
				dense
				:textColor="dark ? 'white' : 'primary'"
				:color="dark ? 'white' : 'primary'"
				name="fad fa-info-circle"
			>
				<q-tooltip>
					<I18N
						:id="field.infoTooltip.i18nPath"
						class="tooltip-text"
						:textColor="dark ? 'white' : 'primary'"
					/>
				</q-tooltip>
			</q-icon>

			<!--
				note: this doesn't use @input or v-model
				field.inputValue gets assigned directly within ElementMixin,
				so anything responding to field.inputValue changes, e.g. deep watchers, will react immediately, not waiting for the debounce
				_might_ also explain why ApplicationSelector was able to have a different value for its inputVal vs the field.inputValue
			-->
			<component
				:is="fieldElement(field)"
				v-if="displayField(field)"
				:id="`${formId}-${i}`"
				class="field-flex-left"
				:value="field.inputValue"
				:fieldInt="i"
				:blockId="blockId"
				:entityId="entityId"
				:field="field"
				:accountToUse="accountToUse"
				:editable="editable"
				:alwaysEdit="alwaysEdit"
				:repeatersKey="repeatersKey"
				:repeaterFieldsPath="repeaterFieldsPath"
				:doNotRegisterForm="true"
				:formId="formId"
				:fieldMeta="fieldMeta"
				:disabled="field.neverAllowEdit || isFieldDisabled(field)"
				:processOptions="field.processOptions"
				:multiSelect="field.multiSelect"
				:customLabelFunction="field.customLabelFunction"
				:collapseRepeaters="collapseRepeaters"
				:repeaterIsDeleted="repeaterIsDeleted"
				:inputTabIndex="showPrivacyButtons ? -1 : 0"
				:preventClear="field.preventClear"
				:autosave="autosave"
				:dark="dark"
				@submitForm="saveField"
				@updateParentMeta="updateParentMeta"
			/>
			<div
				v-if="shouldShowPrivacyControls(field)"
				:class="[isChildOfRepeater(field.id) ? 'privacyEdit--repeater' : 'privacyEdit']"
			>
				<PrivacyEditButton
					:id="`privacy-${formId}-${i}`"
					class="field-flex-right"
					:field="field"
					:tabindex="privacyTabIndex"
				/>
			</div>
			<div
				v-if="field.infoPopup && field.editing"
				class="q-ml-sm"
			>
				<Modal
					:size="field.infoPopup.size || 'small'"
					:title="$t(field.infoPopup.headingI18NPath)"
				>
					<template #activator="{ open }">
						<SimpleButton
							round
							flat
							:textColor="dark ? 'white' : 'primary'"
							icon="fad fa-question-circle"
							@click="open"
						/>
					</template>
					<template>
						<I18N
							v-if="field.infoPopup.contentI18NPath"
							:id="field.infoPopup.contentI18NPath"
							wrap="p"
						/>
						<slot :name="`infoPopup-${field.name}`" />
					</template>
				</Modal>
			</div>
			<slot :name="`afterField-${field.id||field.name}`" class="field-flex-right" />
		</div>
		<InvisibleNavigationButton
			v-if="$formOrigin === 'BasicForm'"
			:anchor="showPrivacyButtons ? `#${formId}-0` : `#privacy-${formId}-0`"
			:label="showPrivacyButtons ? $t('a11y.tabThroughFields') : $t('a11y.tabThroughPrivacy')"
			class="field-aria-nav-toggle"
		/>
	</div>
</template>

<script>
	import { throttle } from 'quasar';
	import { fieldMapping } from '@/configs/constants';
	import bus from '@/components/form/elements/utils/bus';

	export default {
		components: {
			InvisibleNavigationButton: () => import('@/components/ui/InvisibleNavigationButton'),
			DisplayValue: () => import('@/components/form/elements/DisplayValue'),
			DisplayValueOptions: () => import('@/components/form/elements/DisplayValueOptions'),
			RepeaterDisplayValue: () => import('@/components/form/elements/repeaters/DisplayValue'),
			ErrorMessages: () => import('@/components/form/elements/utils/ErrorMessages'),
			Title: () => import('@/components/form/elements/Title'),
			Heading: () => import('@/components/form/elements/Heading'),
			Paragraph: () => import('@/components/form/elements/Paragraph'),
			Description: () => import('@/components/form/elements/Description'),
			TextInput: () => import('@/components/form/elements/TextInput'),
			stringTemplate: () => import('@/components/form/elements/TextInput'),
			geoLocation: () => import('@/components/form/elements/GeoLocation'),
			I18NInput: () => import('@/components/form/elements/I18NInput'),
			Options: () => import('@/components/form/elements/Options'),
			Radio: () => import('@/components/form/elements/Radio'),
			Checkbox: () => import('@/components/form/elements/Checkbox'),
			Toggle: () => import('@/components/form/elements/Toggle'),
			RepeaterEdit: () => import('@/components/form/elements/repeaters/Edit'),
			// should be fine to switch to DisplayValue, unless there's a need for Edit middle-management, which I'm not seeing
			// RepeaterEdit: () => import('@/components/form/elements/repeaters/DisplayValue'),
			userList: () => import('@/components/form/elements/UserList'),
			structureSelector: () => import('@/components/form/elements/StructureSelector'),
			imageUploader: () => import('@/components/form/elements/ImageUploader'),
			Upload: () => import('@/components/form/elements/Upload'),
			applicationSelector: () => import('@/components/form/elements/ApplicationSelector'),
			TypeAhead: () => import('@/components/form/elements/TypeAhead'),
			DateInput: () => import('@/components/form/elements/DateInput'),
			date: () => import('@/components/form/elements/DateInput'),
			TimeInput: () => import('@/components/form/elements/TimeInput'),
			BlockSelector: () => import('@/components/form/elements/BlockSelector'),
			DataSchemaSelector: () => import('@/components/form/elements/DataSchemaSelector'),
			ColorPicker: () => import('@/components/form/elements/ColorPicker'),
			// DurationOptions: () => import('@/components/form/elements/DurationOptions'), add back in when durations power-up is done
			tags: () => import('@/components/form/elements/TagsInput'),
			codeEditor: () => import('@/components/form/elements/CodeEditor'),
			iconPicker: () => import('@/components/form/elements/IconPicker'),
			PrivacyEditButton: () => import('@/components/form/PrivacyEditButton'),
			dataMappingSelector: () => import('@/components/form/elements/DataMappingSelector'),
			files: () => import('@/components/form/elements/Files'),
			wysiwyg: () => import('@/components/form/elements/WYSIWYG'),
			Modal: () => import('@/components/Modal'),
			fieldPicker: () => import('@/components/form/elements/FieldPicker'),
			userSelector: () => import('@/components/form/elements/UserSelector'),
			emailTemplatePicker: () => import('@/components/form/elements/EmailTemplatePicker'),
			MembershipTagsSelector: () => import('@/components/form/elements/MembershipTagsSelector'),
			ticketEventPicker: () => import('@/components/form/elements/TicketEventPickerElement')
		},
		props: {
			blockId: {
				type: [String],
				default: ''
			},
			entityId: {
				type: String,
				default: ''
			},
			fields: {
				type: Array,
				required: true
			},
			fieldMeta: {
				type: Object,
				default: () => ({})
			},
			editable: {
				type: Boolean,
				default: false
			},
			alwaysEdit: {
				type: Boolean,
				default: false
			},
			accountToUse: {
				type: [Number, String, undefined],
				default: undefined
			},
			repeatersKey: {
				type: [Number, undefined],
				default: undefined
			},
			repeaterFieldsPath: {
				type: String,
				default: ''
			},
			formId: {
				type: String,
				default: undefined
			},
			showFields: {
				type: Boolean,
				default: true
			},
			disabled: {
				type: Boolean,
				default: false
			},
			validate: {
				type: Function,
				default: undefined
			},
			collapseRepeaters: {
				type: Boolean,
				default: true
			},
			dark: {
				type: Boolean,
				default: false
			},
			repeaterIsDeleted: {
				type: Boolean,
				default: false
			},
			autosave: {
				type: Boolean,
				default: false
			},
			hideControls: {
				type: Boolean,
				default: false
			},
			hidePrivacyControls: {
				type: Boolean,
				default: false
			}
		},
		inject: {
			$formOrigin: {
				default: null
			}
		},
		data()
		{
			return {
				show: true
			};
		},
		computed: {
			hashId()
			{
				const hash = this.$route?.hash;

				if(hash)
				{
					return hash.substring(hash.indexOf('#') + 1);
				}

				return null;
			},
			privacyTabIndex()
			{
				return this.showPrivacyButtons ? 0 : -1;
			},
			showPrivacyButtons()
			{
				return this.$route?.hash?.indexOf('#privacy') > -1 || false;
			},
			repeaterFields()
			{
				return this.$store.getters['structure/fields/bySchemaType']('repeater');
			}
		},
		watch: {
			hashId: {
				handler()
				{
					// on hash change, scroll to first element of the form
					this.scrollToField();
				},
				deep: true
			}
		},
		mounted()
		{
			this.scrollToField = throttle(this.scrollToField, 50);
			if(this.hashId)
			{
				this.scrollToField();
			}

			if(!bus.registeredForms)
			{
				bus.registeredForms = {};
			}

			if(this.validate)
			{
				if(!bus.validateForms)
				{
					bus.validateForms = {};
				}

				bus.validateForms[this.formId] = this.validate;
			}
		},
		methods: {
			updateParentMeta(data)
			{
				this.$emit('updateParentMeta', data);
			},
			scrollToField(id = `${this.formId}-0`)
			{
				setTimeout(() =>
				{
					// we don't want the header to cover the content we are scrolling to
					const headerHeight = this.$store.getters['app/settings/headerHeight'] + 16; // give it a little padding

					const rect = document.getElementById(id)?.getBoundingClientRect();

					if(rect)
					{
						window.scrollBy(0, Math.round(rect.top) - headerHeight);
					}
				}, 100);
			},
			displayField(field)
			{
				if(this.alwaysEdit) return true;

				return ((field.inputValue !== '' && field.inputValue !== undefined && field.inputValue !== null) || field.alwaysDisplay || field.editing || field.type === 'repeater') && !field.deleted;
			},
			saveField(data)
			{
				this.$emit('submitForm', data);
			},
			fieldElement(field)
			{
				if(['heading', 'paragraph'].includes(field.type))
				{
					return field.type;
				}

				if(!field.editing && !this.alwaysEdit)
				{
					switch(field.type)
					{
						case 'checkbox': return 'DisplayValueOptions';
						case 'repeater': return 'RepeaterDisplayValue';
						case 'toggle': return 'Toggle';
						default: return 'DisplayValue';
					}
				}

				const fieldName = fieldMapping.find((map) =>
				{
					return map.aliases.includes(field.type);
				});

				return fieldName?.name || field.type;
			},
			isChildOfRepeater(fieldId)
			{
				return this.repeaterFields.some((field) => field.childIds?.includes(fieldId));
			},
			isFieldDisabled(field)
			{
				return this.disabled || !!field.disabled;
			},
			shouldShowPrivacyControls(field)
			{
				if(this.hidePrivacyControls || this.isChildOfRepeater(field.id)) return false;

				return this.$formOrigin === 'BasicForm' && field.editing && !['paragraph', 'heading'].includes(field.type);
			}
		}
	};
</script>

<style lang="postcss" scoped>
	.field-aria-nav-toggle {
		margin-left: 11em;
	}

	.fieldContainer {
		.field {
			border-bottom: 1px dashed #c4c4c4;
		}
	}

	.textareaContainer {
		.field {
			border-bottom: 1px dashed #c4c4c4;
			white-space: pre-line;
		}
	}

	.slide-enter-active,
	.slide-leave-active {
		transition: margin-bottom 0.8s ease-out;
	}

	.slide-enter,
	.slide-leave-to {
		margin-bottom: -200px;
	}

	.slide-enter-to,
	.slide-leave {
		margin-bottom: 0;
	}

	.flexContainer {
		display: flex;

		.field-flex-left {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;
		}

		.field-flex-right {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: 48px;
			padding: 6px 12px;
		}

		.q-field--with-bottom {
			padding-bottom: 0;
		}
	}

	.fieldInput {
		position: relative;
	}

	.fieldInput--repeater {
		position: relative;
	}

	.inset .fieldInput--repeater {
		max-width: calc(100% - 48px);
	}

	.fieldInput--repeaterParent {
		width: 100%;
	}

	.fieldInput--repeaterParent .privacyEdit {
		position: absolute;
		right: 20px;
	}

	.privacyEdit--repeater {
		left: 100%;
		position: absolute;
	}

	.tooltip-text {
		font-size: 0.95rem;
	}
</style>
