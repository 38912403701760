<template>
	<Modal
		v-model="modelValue"
		:blockData="data"
		:hideCloseButton="blockData.hideCloseButton"
		v-bind="{...modalProps, isLoading}"
	>
		<template v-slot:[slotName]>
			<Block
				v-for="(blockId, index) in defaultPosition"
				:key="blockId"
				v-bind="$props"
				:data="blockId"
				colIndex="0"
				:index="index"
				@allActionsCompleted="close"
			/>
			<AddBlockButton
				:parent="data.id"
				:index="defaultPosition.length"
			/>
			<div v-if="data.requiresAgreeing" class="float-right">
				<SimpleButton
					class="outline q-mr-md"
					size="md"
					outline
					@click="close"
				>
					<I18N :id="`custom.blocks.${data.id}.agreementButtons.cancel`" fallback="blocks.modal.agreementButtons.cancel" />
				</SimpleButton>
				<ConfirmDialogue
					#default="{ show: open }"
					language="blocks.modal.confirmation"
					:requiresConfirmation="true"
					:runOnConfirm="confirm"
				>
					<SimpleButton
						color="primary"
						class="submit"
						@click.stop="open"
					>
						<I18N :id="`custom.blocks.${data.id}.agreementButtons.agree`" fallback="blocks.modal.agreementButtons.agree" />
					</SimpleButton>
				</ConfirmDialogue>
			</div>
			<div class="float-clear" />
		</template>
	</Modal>
</template>

<script>
	import Vue from 'vue';
	import { addError } from '@/utils/notifications';
	import * as notification from '@/utils/notifications';
	import BlockMixin from '@/components/blocks/BlockMixin';
	import BlockValidation from '@/components/blocks/BlockValidationMixin';
	import Modal from '@/components/Modal';
	import ConfirmDialogue from '@/components/ui/ConfirmDialogue';
	import { getBlockDefault } from '@/components/blocks/blockDefinitions';
	import { userCan } from '@/plugins/Permissions';

	export default {
		components: {
			ConfirmDialogue,
			Modal,
			Block: () => import('@/components/blocks/Block'),
			AddBlockButton: () => import('@/components/admin/generic/AddBlockButton')
		},
		mixins: [BlockMixin, BlockValidation],
		props: {
			value: {
				type: Boolean,
				default: false
			}
		},
		provide()
		{
			return {
				$stepperBus: this.stepperBus
			};
		},
		inject: {
			$modalBus: {
				default: null
			},
			$modalChain: {
				default: null
			},
			$isInTabsBlock: {
				default: false
			},
			$goToPreviousTab: {
				default: () => ({})
			}
		},
		data()
		{
			return {
				modelValue: this.value,
				stepperBus: new Vue(),
				isLoading: false
			};
		},
		computed: {
			inEditMode()
			{
				return this.$store.getters['admin/isEditMode'];
			},
			slotName()
			{
				return this.adminEditMode && !this.modelValue ? 'activator' : 'default';
			},
			modalProps()
			{
				const props = { ...this.data.modalProps };

				if(!props.maximized)
				{
					if(props.width === 'fullWidth')
					{
						props.fullWidth = true;
					}
					else if(props.width)
					{
						props.width = `${props.width}px`;
					}
				}

				return props;
			},
			userModalData()
			{
				return this.currentUserBlockMetaData;
			},
			userHasAgreedToModal()
			{
				return this.userModalData?.agreed;
			},
			userHasBeenShownModal()
			{
				return this.userModalData?.shown;
			},
			requiresAgreement()
			{
				return this.data.requiresAgreeing ?? getBlockDefault('modal', 'requiresAgreeing');
			},
			modalShowOnce()
			{
				return this.data.showOnce ?? getBlockDefault('modal', 'showOnce');
			},
			isAdminEditMode()
			{
				return this.$store.getters['admin/isEditMode'] &&
					userCan('manageEditMode', 'administration');
			},
			userShouldSeeModal()
			{
				return !this.isAdminEditMode && (
					!this.data.noAutoOpen &&
					!this.userHasAgreedToModal && // if modal requires agreement it should always be shown until user agrees
					(
						this.requiresAgreement ||
						!this.modalShowOnce ||
						!this.userHasBeenShownModal)
				); // if no agreement is required, either show every time or if the user hasn't seen the one time modal
			},
			modalState()
			{
				return this.$store.getters['app/getModalState'](this.id);
			}
		},
		watch: {
			modalState(value)
			{
				this.modelValue = value;
			},
			modelValue(value)
			{
				this.$emit('input', value);

				if(this.modalState !== value && value === false)
				{
					this.close(); // for some reason, `close` isn't called after the first few times when closing the modal
				}
			},
			value()
			{
				this.modelValue = this.value;
			},
			toolbarData: {
				handler(newVal)
				{
					if(newVal?.showing)
					{
						this.modelValue = true;
					}
				},
				deep: true
			}
		},
		created()
		{
			if(this.$modalBus)
			{
				this.$modalBus.$on(`${this.id}:show`, this.show);

				this.$modalBus.$emit('register', { id: this.id, skip: !this.userShouldSeeModal });
			}

			if(this.stepperBus)
			{
				this.stepperBus.$on('allActionsCompleted', this.close);
			}

			if(this.userShouldSeeModal && !this.$modalChain) // don't immediately show all blocks in the chain or mark them off as shown
			{
				this.markShown();
				this.show();
			}
		},
		destroyed()
		{
			if(this.$modalBus)
			{
				this.$modalBus.$off(`${this.id}:show`);
			}

			this.$store.dispatch('app/toggleModalState', { modalId: this.id, visible: false });
		},
		methods: {
			show()
			{
				this.$store.dispatch('app/toggleModalState', { modalId: this.id, visible: true });

				if(!this.userHasBeenShownModal)
				{
					this.markShown();
				}
			},
			async close()
			{
				if(!this.userHasAgreedToModal)
				{
					await this.submit({
						agreed: false,
						timestamp: Date.now()
					});

					if(this.requiresAgreement && !this.inEditMode)
					{
						if(this.$isInTabsBlock && typeof this.$goToPreviousTab === 'function')
						{
							try
							{
								this.$goToPreviousTab();

								return;
							}
							catch(e)
							{
								console.error('Could not use $goToPreviousTab - carrying on. Error: ', e);
								// it didn't work, let's try other things
							}
						}

						if(window.history.length === 1) // If we can't redirect the browser (in the app) then just re-open the modal
						{
							this.$store.dispatch('user/logout');

							return;
						}

						window.history.back();
					}
				}
				else
				{
					this.modelValue = false; // user has agreed to a modal, and they're clicking on Cancel (shouldn't really get here if the `userShouldSeeModal` logic is correct, but just for sanity's sake)
				}

				this.$store.dispatch('app/toggleModalState', { modalId: this.id, visible: false });
			},
			confirm()
			{
				if(this.requiresAgreement)
				{
					const validation = this.validateSteppers();

					if(!validation.complete)
					{
						const steps = validation.remainingSteps > 1 ? 'steps' : 'step';

						notification.addError({
							message: `${validation.remainingSteps} ${steps} remaining of ${validation.requiredSteps}`,
							position: 'center'
						});

						return;
					}
				}

				this.submit({
					agreed: true,
					timestamp: Date.now()
				});
			},
			async markShown()
			{
				if(!this.userHasBeenShownModal)
				{
					await this.$store.dispatch('profiles/saveProfileMetaData', {
						path: `block.${this.blockId}`,
						data: {
							shown: true,
							timestamp: Date.now()
						}
					});
				}
			},
			async submit(data)
			{
				try
				{
					if(!this.data.demoMode)
					{
						this.isLoading = true;

						data.shown = true; // If we submit the data we are sure that the user has seen the modal

						await this.$store.dispatch('profiles/saveProfileMetaData', {
							path: `block.${this.blockId}`,
							data
						});

						this.emitBlockEvent('modalClosed', this.data);
					}

					this.modelValue = false;
					this.$store.dispatch('app/toggleModalState', { modalId: this.id, visible: false });
				}
				catch(e)
				{
					addError(e.message);
				}
				finally
				{
					this.isLoading = false;
				}
			}
		}
	};
</script>
