<template>
	<!-- Keep as root element for convenient styling for parent. -->
	<div
		v-if="isIndicatorVisible"
		:class="indicatorClass"
	>
		<q-tooltip>
			{{ tooltipText }}
		</q-tooltip>
	</div>
</template>

<script setup>
	// @ts-check
	/**
	 * Dot indicator for user connectivity.
	 *
	 * The indicator size defaults to 15px,
	 * but can be changed by styling this component, e.g.
	 * ```html
	 * <ConnectivityIndicator style="width: 30px" />
	 *
	 * <ConnectivityIndicator class="custom-width" />
	 * ```
	 */
	import { computed, defineProps, inject } from 'vue';
	import i18n from '@/plugins/vue-i18n';
	import {
		cUserConnectivityIndicatorBehaviour
	} from '@/configs/constants/settings';
	import {
		getIsUserOnlineComputed,
		indicatorBehaviour,
		isCurrentUserPrivate,
		isFeatureEnabled
	} from '@/utils/userDeviceKeepalive';

	/**
	 * @template T
	 * @typedef {import('vue').ComputedRef<T>} ComputedRef<T>
	 */
	/**
	 * @typedef {import('vue-i18n').TranslateResult} TranslateResult
	 * @typedef {import(
	 * 	'@/types/settings'
	 * ).UserConnectivitySettings} UserConnectivitySettings
	 */

	const props = defineProps({
		accountId: {
			type: Number,
			required: true
		}
	});

	/**
	 * Overrides the feature toggle for preview purposes.
	 * @type {boolean}
	 */
	const isPreview = inject('isUserConnectivityPreview', false);

	/** @type {ComputedRef<boolean>} */
	const isUserOnline = getIsUserOnlineComputed(
		computed(() => props.accountId)
	);

	/** @type {ComputedRef<TranslateResult>} */
	// eslint-disable-next-line no-unused-vars
	const tooltipText = computed(
		() => (
			isUserOnline.value ?
				i18n.t('userConnectivity.online') :
				i18n.t('userConnectivity.offline')
		)
	);

	/** @type {ComputedRef<boolean>} */
	// eslint-disable-next-line no-unused-vars
	const isIndicatorVisible = computed(
		() =>
		{
			if(isPreview) return true;

			if(!isFeatureEnabled.value) return false;

			if(isCurrentUserPrivate.value) return false;

			switch(indicatorBehaviour.value)
			{
				case cUserConnectivityIndicatorBehaviour.onlineOnly:
					return isUserOnline.value;
				case cUserConnectivityIndicatorBehaviour.trafficLight:
					return true;
				default:
					return false;
			}
		}
	);

	/** @type {ComputedRef<Array<string>>} */
	// eslint-disable-next-line no-unused-vars
	const indicatorClass = computed(() =>
	{
		return [
			'dot-indicator',
			isUserOnline.value ?
				'bg-positive' :
				'bg-negative'
		];
	});
</script>

<style lang="postcss" scoped>
	.dot-indicator {
		/** Default width. */
		width: 15px;
	}
</style>
