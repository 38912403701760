const UserEditable = {
	data()
	{
		return {
			editMode: false
		};
	},
	computed: {
		allowEdit()
		{
			return this.accountId === this.$store.getters['user/accountId'];
		}
	},
	methods: {
		toggleEditMode()
		{
			this.editMode = !this.editMode;
		}
	}
};

export default UserEditable;
