import Vue from 'vue';
import { get, post } from '@/utils/api';

const state = {
	fileList: {}
};

const mutations = {
	SET_FILE_LIST(state, { url, data })
	{
		Vue.set(state.fileList, url, { data, loading: false });
	},
	SET_FILE_LIST_LOADING(state, url)
	{
		Vue.set(state.fileList, url, { data: [], loading: true });
	},
	INCREMENT_DOWNLOAD_COUNT(state, { url, index })
	{
		Vue.set(state.fileList[url].data[index].data, 'downloadCount', state.fileList[url].data[index].data.downloadCount + 1);
	},
	REMOVE_LIST_ITEM(state, { url, fileId })
	{
		const index = state.fileList[url].data.findIndex((file) => file.id === fileId);

		Vue.delete(state.fileList[url].data, index);
	},
	REMOVE_FILE(state, id)
	{
		state.files = state.files.filter((file) => file.id === id);
	}
};

const actions = {
	async loadFileList({ commit, dispatch, getters }, { url, forceReload, scope = null })
	{
		const cacheUrl = `${url}?scope=${scope}`;

		if(!getters.byUrl(url, scope)?.data?.length || forceReload)
		{
			if(!forceReload) // no flicker
			{
				commit('SET_FILE_LIST_LOADING', cacheUrl);
			}

			const { data: { fileList } } = await get(url, { params: { scope } });

			commit('SET_FILE_LIST', { url: cacheUrl, data: fileList });
			dispatch('files/addFiles', fileList, { root: true });
		}

		return getters.byUrl(url, scope);
	},
	refreshFileList({ dispatch }, { url, scope = null })
	{
		dispatch('loadFileList', { url, scope, forceReload: true });
	},
	removeListItem({ commit }, { url, scope = null, fileId = undefined })
	{
		if(fileId)
		{
			commit('REMOVE_LIST_ITEM', { url: `${url}?scope=${scope}`, fileId });
		}
	},
	incrementDownloadCount({ commit }, { url, index, id = null })
	{
		commit('INCREMENT_DOWNLOAD_COUNT', { url, index, id });
	},
	async claimFiles({ commit, dispatch }, { fromContextId, toContextId, scope = null })
	{
		if(!fromContextId || !toContextId)
		{
			return;
		}

		const { data: { fileList } } = await post('files/claimFiles', { fromContextId, toContextId, scope });
		const cacheUrl = `files/getFileList/${toContextId}?scope=${scope}`;

		// let's update the file list cache. There's a race going on between the new post showing itself (and its files) and the file claim function actually putting the files onto the post
		commit('SET_FILE_LIST', { url: cacheUrl, data: fileList });
		dispatch('files/addFiles', fileList, { root: true });
	},
	async deleteFilesByContextId({ commit }, { contextId, scope = null })
	{
		if(!contextId) return;

		await post('files/deleteFilesByContextId', { contextId, scope });
	}
};

const getters = {
	byUrl: (state) => (url, scope = null) => state.fileList[`${url}?scope=${scope}`] || { data: [] },
	loading: (state, getters) => (url, scope) => getters.byUrl(url, scope).loading || false
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
