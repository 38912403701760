const actions = {
	async addField({ dispatch }, { itemId, itemType, fieldId })
	{
		switch(itemType)
		{
			// case 'module':
			// 	break;
			// case 'page':
			// 	break;
			case 'block':
				await dispatch('structure/blocks/admin/addFieldToBlock', { blockId: itemId, fieldId }, { root: true });
				break;
			case 'field':
				break;
			// case 'dataSchema':
			// 	break;
			// case 'entity':
			// 	break;
			case 'application':
				await dispatch('applications/admin/addField', { applicationId: itemId, fieldId }, { root: true });
				break;
			// case 'entityDefinition':
			// 	break;
			// case 'powerUp':
			// 	break;
			// case 'workflow': // no getters for these
			// 	break;
			// case 'workflowAction':
			// 	break;
			// case 'dataMapping':
			// 	break;
			// case 'template':
			// 	break;
			default:
				console.error('Unknown Item type or no "addField" action exists for it', itemType);
		}
	},
	async removeField({ dispatch }, { itemId, itemType, fieldId })
	{
		switch(itemType)
		{
			// case 'module':
			// 	break;
			// case 'page':
			// 	break;
			// case 'block':
			// 	break;
			case 'field':
				await dispatch('structure/fields/admin/delete', { id: fieldId, itemId, itemType }, { root: true });
				break;
			// case 'dataSchema':
			// 	break;
			// case 'entity':
			// 	break;
			case 'application':
				await dispatch('applications/admin/removeField', { applicationId: itemId, fieldId }, { root: true });
				break;
			// case 'entityDefinition':
			// 	break;
			// case 'powerUp':
			// 	break;
			// case 'workflow': // no getters for these
			// 	break;
			// case 'workflowAction':
			// 	break;
			// case 'dataMapping':
			// 	break;
			// case 'template':
			// 	break;
			default:
				console.error('Unknown Item type or no "removeField" action exists for it', itemType);
		}
	}
};

const getters = {
	byId: (state, getters, rootState, rootGetters) => (id, type) =>
	{
		switch(type)
		{
			case 'module':
				return rootGetters['structure/modules/getModuleById'](id);
			case 'page':
				return rootGetters['structure/pages/getPageById'](id);
			case 'block':
				return rootGetters['structure/blocks/getBlock'](id);
			case 'field':
				return rootGetters['structure/fields/byId'](id);
			case 'dataSchema':
				return rootGetters['dataSchemas/byId'](id);
			case 'entity':
				return rootGetters['entities/byId'](id);
			case 'application':
				return rootGetters['applications/getApplication'](id);
			case 'entityDefinition':
				return rootGetters['entityDefinitions/byId'](id);
			case 'powerUp':
				return rootGetters['powerUps/byId'](id);
			// case 'workflow': // there are no getters for these
			// 	break;
			// case 'workflowAction':
			// 	break;
			case 'dataMapping':
				return rootGetters['dataMappings/byId'](id);
			// case 'template':
			//  break;
			default:
				console.error('Unknown Item type or no "getById" getter exists for it', type);
		}

		return null;
	}
};

export default {
	namespaced: true,
	actions,
	getters
};
