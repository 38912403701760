<template>
	<div :class="{ editing: formEditing }">
		<q-inner-loading class="form-loader" :showing="shouldShowLoadingIndicators">
			<Spinner
				color="primary"
				size="3em"
				:thickness="2"
			/>
		</q-inner-loading>
		<!-- <div><b>Form.vue</b> {{ localFormId }}</div> -->
		<div v-if="!displayForm">
			<slot name="header" />
			<FormFields
				:fields="localFields"
				:blockId="blockId"
				:entityId="entityId"
				:accountToUse="accountToUse"
				:editable="isEditable"
				:alwaysEdit="alwaysEdit"
				:repeatersKey="repeatersKey"
				:repeaterFieldsPath="repeaterFieldsPath"
				:formId="localFormId"
				:fieldMeta="fieldMeta"
				:showFields="showFields"
				:collapseRepeaters="collapseRepeaters"
				:dark="dark"
				:repeaterIsDeleted="repeaterIsDeleted"
				:hideControls="hideControls"
				:hidePrivacyControls="hidePrivacyControls"
				@submitForm="submitForm"
				@updateParentMeta="updateParentMeta"
			>
				<!-- allows more complex info to be passed to the FormFields infoPopup -->
				<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
					<slot :name="slot" v-bind="scope" />
				</template>
				<template v-for="field in localFields" #[`afterField-${field.id||field.name}`]>
					<slot :name="`afterField-${field.id||field.name}`" />
				</template>
			</FormFields>
		</div>
		<form
			v-else
			@submit.prevent
		>
			<slot name="test-test" />
			<ValidationObserver ref="observer">
				<div slot-scope="{ validate }">
					<div
						v-if="blockId || showHeader"
						class="formHeadingsWrapper"
					>
						<h2>
							<I18N :id="`${I18NPath}.title`" />
						</h2>
						<SimpleButton
							v-if="!alwaysEdit && isEditable"
							size="12px"
							unelevated
							class="editForm float-right"
							:color="editButtonColor"
							textColor="grey-10"
							:class="{'no-outline':formEditing}"
							@click="toggleFormEdit()"
						>
							<I18N
								v-if="!formEditing"
								:id="`${I18NPath}.userEdit`"
								fallback="blocks.basicForm.userEdit"
							/>
							<I18N
								v-else
								:id="`${I18NPath}.cancel`"
								fallback="blocks.basicForm.cancel"
							/>
						</SimpleButton>
					</div>
					<div
						v-if="localFields.length"
						ref="form"
						class="form"
					>
						<!-- <Block v-if="displayBlock && !formEditing" :data="displayBlock" /> -->
						<slot v-if="!formEditing" name="display">
							<FormFields
								class="form-fields display"
								:fields="localFields"
								:blockId="blockId"
								:entityId="entityId"
								:accountToUse="accountToUse"
								:editable="isEditable"
								:alwaysEdit="alwaysEdit"
								:formId="localFormId"
								:fieldMeta="fieldMeta"
								:showFields="showFields"
								:disabled="disableAllFields"
								:validate="validate"
								:collapseRepeaters="collapseRepeaters"
								:repeaterFieldsPath="repeaterFieldsPath"
								:dark="dark"
								:repeaterIsDeleted="repeaterIsDeleted"
								:hideControls="hideControls"
								:hidePrivacyControls="hidePrivacyControls"
								@submitForm="submitForm"
								@updateParentMeta="updateParentMeta"
							>
								<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
									<slot :name="slot" v-bind="scope" />
								</template>
								<template v-for="field in localFields" #[`afterField-${field.id||field.name}`]>
									<slot :name="`afterField-${field.id||field.name}`" />
								</template>
							</FormFields>
						</slot>

						<FormFields
							v-else
							class="form-fields display"
							:fields="localFields"
							:blockId="blockId"
							:entityId="entityId"
							:accountToUse="accountToUse"
							:editable="isEditable"
							:alwaysEdit="alwaysEdit"
							:formId="localFormId"
							:fieldMeta="fieldMeta"
							:showFields="showFields"
							:disabled="disableAllFields"
							:validate="validate"
							:collapseRepeaters="collapseRepeaters"
							:repeaterFieldsPath="repeaterFieldsPath"
							:dark="dark"
							:repeaterIsDeleted="repeaterIsDeleted"
							:autosave="autosave"
							:hideControls="hideControls"
							:hidePrivacyControls="hidePrivacyControls"
							@submitForm="submitForm"
							@updateParentMeta="updateParentMeta"
						>
							<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
								<slot :name="slot" v-bind="scope" />
							</template>
							<template v-for="field in localFields" #[`afterField-${field.id||field.name}`]>
								<slot :name="`afterField-${field.id||field.name}`" />
							</template>
						</FormFields>
					</div>

					<slot name="beforeSaveButton" />

					<div v-if="formEditing && (overrideControlHiding || !shouldHideControls)" class="block-details">
						<span class="button-container formErrors">
							<ErrorMessages showAll :errorMessages="flatFormErrors" />
							<slot name="preButtons" />
							<SimpleButton
								v-if="!alwaysEdit"
								unelevated
								class="outline q-mr-sm"
								color="grey-7"
								textColor="white"
								size="md"
								@click="toggleFormEdit(false)"
							>
								<I18N :id="`${I18NPath}.cancel`" fallback="blocks.basicForm.cancel" />
							</SimpleButton>
							<SimpleButton
								:disabled="formHasErrors"
								:loading="loading || shouldShowLoadingIndicators"
								unelevated
								color="primary"
								class="submit"
								@click="saveForm(validate)"
							>
								<I18N :id="`${I18NPath}.saveAll`" fallback="blocks.basicForm.saveAll" />
							</SimpleButton>
							<q-btn-dropdown
								v-if="dropdownOpts.length > 0"
								color="primary"
								:label="getI18N('notifications.saveAll')"
							>
								<q-list>
									<q-item
										v-for="option in dropdownOpts"
										:key="option"
										v-close-popup
										clickable
										@click="saveForm(validate, option)"
									>
										<q-item-section>
											<q-item-label><I18N :id="`notifications.subscribables.${option}`" /></q-item-label>
										</q-item-section>
									</q-item>
								</q-list>
							</q-btn-dropdown>
							<slot name="buttons" />
						</span>
					</div>
				</div>
			</ValidationObserver>
		</form>
		<Modal v-model="displayConfirmation">
			<template #default="{ close }">
				<FormConfirmation
					:changeReportRows="changeReportRows"
					:confirm="confirmedSave(close)"
					:cancelAction="close"
					:mainFormId="mainFormId"
					:blockId="blockId"
					:entityId="entityId"
					:accountToUse="accountToUse"
				/>
			</template>
		</Modal>
	</div>
</template>

<script>
	import Vue from 'vue';
	import uuid from 'uuid/v4';
	import dot from 'dot-object';
	import debounce from 'lodash/debounce';
	import cloneDeep from 'lodash/cloneDeep';
	import pickBy from 'lodash/pickBy';
	import uniq from 'lodash/uniq';
	import isEqual from 'lodash/isEqual';
	import { ValidationObserver } from 'vee-validate';
	import FormFields from '@/components/form/FormFields';
	import bus from '@/components/form/elements/utils/bus';
	import { Utils } from 'acb-lib';
	import RuleParser from '@/components/admin/panel/RuleBuilder/mixins/RuleParser';
	import EventBus from '@/components/admin/generic/EventBus';
	import { addSuccess } from '@/utils/notifications';
	import { sortRepeaterValues } from '@/utils/fieldSorting';
	import FormConfirmation from '@/components/form/FormConfirmation';
	import Modal from '@/components/Modal';
	import FormSchemaMixin from '@/components/mixins/FormSchemaMixin';

	export default {
		components: {
			Modal,
			FormConfirmation,
			ErrorMessages: () => import('@/components/form/elements/utils/ErrorMessages'),
			FormFields,
			ValidationObserver,
			Spinner: () => import('@/components/ui/Spinner')
		},
		mixins: [
			RuleParser,
			FormSchemaMixin
		],
		inject: {
			$hideControls: {
				default: false
			},
			$formSaverId: {
				default: null
			},
			$hideIndividualSaveControl: {
				default: false
			},
			confirmedSaveAction: {
				type: Function,
				default: null
			},
			$formId: {
				default: null
			}
		},
		props: {
			blockId: {
				type: [String],
				default: ''
			},
			fields: {
				type: Array,
				required: true
			},
			fieldMeta: {
				type: Object,
				default: () => ({})
			},
			editable: {
				type: Boolean,
				default: false
			},
			onSubmit: {
				type: Function,
				default: null
			},
			alwaysEdit: {
				type: Boolean,
				default: false
			},
			showConfirmation: {
				type: Boolean,
				default: false
			},
			accountToUse: {
				type: [Number, String, undefined],
				default: undefined
			},
			displayForm: {
				type: Boolean,
				default: true
			},
			repeatersKey: {
				type: Number,
				default: undefined
			},
			repeaterFieldsPath: {
				type: String,
				default: ''
			},
			formId: {
				type: String,
				default: undefined
			},
			showFields: {
				type: Boolean,
				default: true
			},
			disableAllFields: {
				type: Boolean,
				default: false
			},
			hideControls: {
				type: Boolean,
				default: false
			},
			i18nPath: {
				type: String,
				default: undefined
			},
			loading: {
				type: Boolean,
				default: false
			},
			showHeader: {
				type: Boolean,
				default: false
			},
			entityId: {
				type: String,
				default: ''
			},
			doNotRegisterForm: {
				type: Boolean,
				default: false
			},
			ignoreValidation: {
				type: Boolean,
				default: false
			},
			noReallyIgnoreValidation: {
				type: String,
				default: 'I did not think about what I am doing'
			},
			skipDirtyCheck: {
				type: Boolean,
				default: false
			},
			collapseRepeaters: {
				type: Boolean,
				default: true
			},
			dropdownOpts: {
				type: Array,
				default: () => ([]),
				required: false
			},
			dark: {
				type: Boolean,
				default: false
			},
			onlySubmitOnce: { // use to prevent from being able to submit an "add" form more than once. Should be `false` on "edit" forms
				type: Boolean,
				default: false
			},
			overrideControlHiding: {
				type: Boolean,
				default: false
			},
			repeaterIsDeleted: {
				type: Boolean,
				default: false
			},
			// used to suppress the success message from submitForm(), if handling success/error messages elsewhere
			displaySuccessMessage: {
				type: Boolean,
				default: true
			},
			// used to show any relevant loading indicators, if performing external operations, e.g. with @submit handlers
			// TODO: should this influence displayLoading? Looks specific to Handlebars and submitForm() logic
			showLoadingIndicators: {
				type: Boolean,
				default: false
			},
			autosave: {
				type: Boolean,
				default: false
			},
			hidePrivacyControls: {
				type: Boolean,
				default: false
			},
			idToUse: {
				type: [Number, String, undefined],
				default: undefined
			}
		},
		data()
		{
			const localFormId = this.formId || uuid();

			return {
				inTransit: {},
				localFieldsMeta: {},
				formEditing: this.alwaysEdit,
				progress: 0,
				localFormId,
				mainFormId: localFormId.split('#')[0],
				formSaverDisposer: null,
				savingInProgress: false,
				displayLoading: false,
				forceDisableFields: [],
				forceEnableFields: [],
				dirtyFields: [],
				displayConfirmation: false,
				formErrors: {}
			};
		},
		provide()
		{
			return {
				i18nPath: this.i18nPath,
				formInstance: () => this,
				displayLoading: () => this.displayLoading,
				$formId: this.localFormId
			};
		},
		computed: {
			// appLoading()
			// {
			// 	return this.$store.getters['app/loading'] || false;
			// },
			isEditable()
			{
				return this.alwaysEdit ? true : this.editable;
			},
			shouldHideControls()
			{
				return this.hideControls || this.$hideControls;
			},
			withConditionalFields()
			{
				return this.withConditionalFieldsRecursive(this.fields);
			},
			localFields()
			{
				const fields = [];

				this.withConditionalFields.forEach((fieldWithReference) =>
				{
					const keepTheseValues = {};
					let field = cloneDeep(fieldWithReference);

					if(field.editing || field.fieldEdit)
					{
						keepTheseValues.editing = field.editing;
						keepTheseValues.fieldEdit = field.fieldEdit;
					}

					// Fix the id/name/key to make sure they have something
					field.id = field.id || field.key || field.name;
					field.name = field.name || field.id || field.key;
					field.key = field.key || field.id || field.name;

					if(!field.id)
					{
						console.error(field);
						throw new Error('You must provide either an [\'id\', \'key\', \'name\'] for your field.');
					}

					field = {
						...field,
						...this.localFieldsMeta[field.id],
						...this.fieldMeta[field.id],
						...this.fieldMeta[field.schema],
						...keepTheseValues,
						value: field.value
					};

					if(this.forceDisableFields.includes(field.name))
					{
						field.disabled = true;
					}

					if(this.forceEnableFields.includes(field.name))
					{
						field.disabled = false;
					}

					if(typeof field.value === 'undefined' || field.value === null)
					{
						switch(field.type)
						{
							case 'checkbox':
								field.value = field.defaultOption ? field.defaultOption : [];
								break;
							case 'toggle':
								field.value = false;
								break;
							case 'repeater':
								field.value = [];
								break;
							case 'imageUploader':
								field.value = null;
								break;
							default:
								field.value = '';
								break;
						}
					}

					if(
						// if there is no input value
						typeof field.inputValue === 'undefined' ||
						// or this is an image uploader and the input value is invalid
						(field.type === 'imageUploader' && !field.inputValue) ||
						// or...
						(
							// the value and input value don't match
							!isEqual(field.value, field.inputValue) &&
							// and the form is not currently saving
							!this.savingInProgress &&
							// and the field is not being edited
							!field.editing &&
							// and the field is either not a repeater or none of the repeater's children are being edited
							(
								field.type !== 'repeater' ||
								!field.childrenBeingEdited?.length
							)
						)
					)
					// then match the field's input value with its display value.
					// display value is also retrieved from the profile store, so this should match saved data once saving completes
					{
						field.inputValue = field.value;
					}
					else if(field.type === 'repeater')
					{
						// scrub blank repeater iterations from the input during form submission
						// if we don't do this, it causes duplicates to appear in the form editor after saving
						if(this.savingInProgress)
						{
							field.inputValue = this.checkEmptyRepeaterData(field.inputValue);
						}
					}

					fields.push(field);
				});

				return fields;
			},
			formHasErrors()
			{
				// Filter out errors which we still want to continue to allow the user to submit through (ie server connection errors shouldn't prevent retries)
				return this.flatFormErrors.some((error) => error.disallowSubmit !== false);
			},
			flatFormErrors()
			{
				return Object.keys(this.formErrors).reduce((acc, key) =>
				{
					acc.push(...(this.formErrors[key] || []).map((error) => error.msg));

					return acc;
				}, []);
			},
			// formErrors()
			// {
			// 	const errors = [];

			// 	if(!this.errors)
			// 	{
			// 		return errors;
			// 	}

			// 	console.log('formErrors', this.errors);

			// 	this.errors.items.forEach((error) =>
			// 	{
			// 		const [formIds] = error.field.split('|');

			// 		if(formIds.split('#')[0] !== this.localFormId)
			// 		{
			// 			return;
			// 		}

			// 		console.log('msg', error.msg);
			// 		errors.push(error.msg);
			// 	});

			// 	return errors;
			// },
			editButtonColor()
			{
				if(this.formEditing) return 'warning';

				return 'grey-4';
			},
			I18NPath()
			{
				if(this.i18nPath) return `${this.i18nPath}`;

				return `custom.blocks.${this.blockId}`;
			},
			shouldShowLoadingIndicators()
			{
				return this.savingInProgress || this.showLoadingIndicators;
			},
			changeReportRows()
			{
				return this.localFields.filter((field) => this.dirtyFields.includes(field.id));
			},
			shouldShowSaveButton()
			{
				if(this.hideSaveAllButton) return false;

				return (this.overrideControlHiding || !this.$hideIndividualSaveControl) || this.alwaysEdit;
			}
		},
		watch: {
			fields(newValue, oldValue)
			{
				import('deep-equal').then(({ default: deepEqual }) =>
				{
					if(!deepEqual(newValue, oldValue)) // JSON.stringify(newValue) !== JSON.stringify(oldValue)) // Watch is being triggered even when there aren't any changes :/
					{
						this.localFieldsMeta = this.setLocalFieldsMeta();
					}
				});
			}
		},
		async created()
		{
			this.debounceProgressUpdate = debounce(() =>
			{
				this.calculateProgress();
			}, 500);

			if(this.autosave)
			{
				this.saveForm = debounce(this.saveForm, 2000);
			}

			bus.$on('handleCreatingCustomError', this.handleCreatingCustomError);
			bus.$on('handleRemovingCustomError', this.handleRemovingCustomError);
			bus.$on('fieldUpdated', this.handleFieldUpdate);
			bus.$on('submitField', this.handleSubmitField);
			bus.$on('transmittingData', this.handleTransmittingData);
			bus.$on('fieldSave', this.handleFieldSave);
			bus.$on('valueOrderUpdated', this.updateFieldOrder);
			bus.$on('dirtyField', this.addDirtyField);
			bus.$on('cleanField', this.removeDirtyField);
			bus.$on(`form-error-add:${this.localFormId}`, this.addFormError);
			bus.$on(`form-errors-clear:${this.localFormId}`, this.clearFormErrors);

			await this.$store.dispatch('user/getListMemberships');
		},
		beforeDestroy()
		{
			bus.$off('handleCreatingCustomError', this.handleCreatingCustomError);
			bus.$off('handleRemovingCustomError', this.handleRemovingCustomError);
			bus.$off('fieldUpdated', this.handleFieldUpdate);
			bus.$off('submitField', this.handleSubmitField);
			bus.$off('transmittingData', this.handleTransmittingData);
			bus.$off('fieldSave', this.handleFieldSave);
			bus.$off('valueOrderUpdated', this.updateFieldOrder);
			bus.$off('dirtyField', this.addDirtyField);
			bus.$off('cleanField', this.removeDirtyField);
			bus.$off(`form-error-add:${this.localFormId}`, this.addFormError);
			bus.$off(`form-errors-clear:${this.localFormId}`, this.clearFormErrors);

			if(this.localFormId === this.mainFormId && typeof this.repeatersKey === 'undefined')
			{
				// remove the data from copy, so we don't bleed memory
				this.$store.dispatch('profileFormCopy/removeFormData', this.mainFormId); // get rid of all copied data related to this formId
			}

			// this.dirtyFields = []; // clear when done - can't actually do this since several forms may be edited at the same time, and we don't distinguish between them
		},
		mounted()
		{
			this.debounceProgressUpdate();

			this.setLocalDataCopy();
			this.localFieldsMeta = this.setLocalFieldsMeta();

			if(this.alwaysEdit)
			{
				this.toggleFormEdit(true);
			}

			if(!this.doNotRegisterForm)
			{
				this.registerForm();
				// if(this.$formSaverId)
				// {
				// 	console.log('registering', this.$formSaverId);
				// 	bus.registeredForms[this.$formSaverId][this.localFormId] = {
				// 		function: this.saveForm
				// 	};
				// }
				// else
				// {
				// 	bus.registeredForms[this.localFormId] = {
				// 		function: this.saveForm
				// 	};
				// }
			}
		},
		updated()
		{
			this.debounceProgressUpdate();

			if(this.alwaysEdit)
			{
				this.toggleFormEdit(true);
			}
		},
		destroyed()
		{
			if(bus.validateForms)
			{
				delete bus.validateForms[this.localFormId];
			}

			if(this.formSaverDisposer)
			{
				bus.unRegisterForm(this.formSaverDisposer);
				// if(this.$formSaverId)
				// {
				// 	delete bus.registeredForms[this.$formSaverId][this.localFormId];
				// }
				// else
				// {
				// 	delete bus.registeredForms[this.localFormId];
				// }
			}
		},
		methods: {
			/**
			 * Allows using another submit button.
			 * When using a submit button outside of the Form component, that button should call $refs.form.submitFromOutside
			 * Form.vue then performs validation
			 * If validation passes, Form emits `submit`, and then the actual post method can be called (outside of Form)
			 */
			async submitFromOutside()
			{
				const validate = await this.$refs.observer.validate;

				await this.saveForm(validate);
			},
			getFormId()
			{
				return this.localFormId;
			},
			registerForm()
			{
				this.formSaverDisposer = bus.registerForm(this.localFormId, { function: this.saveForm, name: 'FormComponent' }, this.$formSaverId);
			},
			handleCreatingCustomError(formId, errorName, messageData)
			{
				if(!formId || this.localFormId !== formId) return;

				const found = this.errors.items.find((error) => error.msg.errorType === errorName);

				if(found)
				{
					return;
				}

				this.errors.items.push({
					formId: this.formId,
					msg: {
						...messageData,
						errorType: errorName
					}
				});
			},
			handleRemovingCustomError(formId, errorName)
			{
				if(!formId || this.localFormId !== formId) return;

				this.errors.items.forEach((error, i) =>
				{
					if(error.formId === this.formId && error.msg.errorType === errorName)
					{
						this.$delete(this.errors.items, i);
					}
				});
			},
			handleTransmittingData(formId, fieldInt, repeatersKey, uuid)
			{
				this.inTransit[uuid] = true;
			},
			async handleFieldUpdate(formId, fieldInt, repeatersKey, uuid, field, extras = {})
			{
				if(!formId || this.localFormId !== formId || typeof repeatersKey !== 'undefined') return;

				Vue.set(this.localFieldsMeta[field.id], 'fieldEdit', field.fieldEdit);
				Vue.set(this.localFieldsMeta[field.id], 'editing', field.editing);
				Vue.set(this.localFieldsMeta[field.id], 'inputValue', field.inputValue);

				// Get the state of repeaters' children
				if(Object.prototype.hasOwnProperty.call(extras, 'editing') && extras.field)
				{
					const childrenBeingEdited = this.localFieldsMeta[field.id].childrenBeingEdited || [];
					const childIndex = childrenBeingEdited.findIndex((fieldId) => fieldId === extras.field.id);
					const newValue = cloneDeep(childrenBeingEdited);
					const timeout = 500;
					let useTimeout = false;

					// if this child is being edited, or the child is a repeater with a child open for editing
					if(extras.editing || extras.field.childrenBeingEdited)
					{
						if(extras.field.id && !childrenBeingEdited.includes(extras.field.id))
						{
							newValue.push(extras.field.id);
						}
					}
					else if(childIndex > -1)
					{
						newValue.splice(childIndex, 1);

						useTimeout = true;
					}

					// if a child's edit state switches back to false, this forces the update to wait until savingInProgress is true
					// this prevents saving overwriting the input value early and catching the wrong data
					// related to value sync conditions in localFields
					if(useTimeout)
					{
						setTimeout(() =>
						{
							Vue.set(this.localFieldsMeta[field.id], 'childrenBeingEdited', newValue);
						}, timeout);
					}
					else
					{
						Vue.set(this.localFieldsMeta[field.id], 'childrenBeingEdited', newValue);
					}
				}

				// update the copy of data so conditional fields could be checked
				// only the profile being edited. If it's the user's own profile, then it's at the same location and doesn't need to be updated separately!
				await this.$store.dispatch('profileFormCopy/setFormFieldData', { formId: `${this.mainFormId}-${this.localFormId}`, fieldKey: field.key, data: field.inputValue });

				if(field.source === 'profile' || field.source === 'profileMeta')
				{
					await this.$store.dispatch('profileFormCopy/setFormFieldData', { formId: `${this.mainFormId}-${this.accountToUse}`, fieldKey: field.key, data: field.inputValue });
				}
				else if(this.entityId && this.$store.getters['profileFormCopy/getFormData'](`${this.mainFormId}-${this.entityId}`))
				{
					let { key } = field;

					if(field.source === 'entity')
					{
						// custom fields on entities are placed in `context` key in the data
						key = `context.${key}`;
					}

					await this.$store.dispatch('profileFormCopy/setFormFieldData', { formId: `${this.mainFormId}-${this.entityId}`, fieldKey: key, data: field.inputValue });
				}

				delete this.inTransit[uuid];

				this.debounceProgressUpdate();

				this.$emit('change', this.getInputValues());

				// If autosave is enabled we want to call the save method
				if(this.autosave === true)
				{
					const validate = await this.$refs.observer.validate;

					await this.saveForm(validate, undefined, { doNotClose: true });
				}
			},
			async handleFieldSave(formId, fieldInt, repeatersKey, field, key)
			{
				if(!formId || this.localFormId !== formId || typeof repeatersKey !== 'undefined') return;

				// const data = object({ [key]: pick(key, field.inputValue) });

				// this.submitForm({ [field.id]: data });
				if(this.formHasErrors) return;

				const data = this.getInputValues(true);

				await this.submitForm(data);
			},
			async handleSubmitField(formId, data)
			{
				if(!formId || this.localFormId !== formId) return;

				// clean deleted repeater entries

				if(this.formEditing)
				{
					await this.saveForm(bus.validateForms[this.localFormId]);

					return;
				}

				await bus.validateForms[this.localFormId]();

				this.getInputValues(true);

				await this.submitForm(data);
			},
			getInputValues(save = false)
			{
				const data = {};

				this.localFields.forEach((field) =>
				{
					if(field.name === undefined) return;

					data[field.name] = field.inputValue;

					if(save)
					{
						this.localFieldsMeta[field.id].value = field.inputValue;
					}
				});

				return data;
			},
			async getInputValue(fieldId)
			{
				return this.localFields.find((field) => field.id === fieldId);
			},
			async saveForm(
				validate,
				action = false,
				opts = { doNotClose: false }
			)
			{
				if(Object.keys(this.inTransit).length !== 0) // We're waiting on data to transmit from the bus (debouncing)
				{
					await Utils.sleep(300);
				}

				if(this.formHasErrors) return;

				if(
					!this.ignoreValidation &&
					this.noReallyIgnoreValidation !== 'I definitely understand what I am doing and why this is a bad idea'
				)
				{
					// Runs validation for form fields
					await validate();
				}

				// after validation is done, we know that the data is all good
				// if needed, ask the user for confirmation that they want to submit this data
				if(this.showConfirmation && !this.displayConfirmation)
				{
					this.displayConfirmation = true;

					return;
				}

				const data = this.getInputValues(true);

				await this.submitForm(data, action, opts);

				EventBus.$emit(`admin:form:${this.localFormId}:save`);
			},
			confirmedSave(modalCloseAction)
			{
				return async () =>
				{
					if(typeof this.confirmedSaveAction === 'function')
					{
						await this.confirmedSaveAction(); // await just in case it's needed
					}
					else
					{
						const data = this.getInputValues(true);

						await this.submitForm(data);
					}

					modalCloseAction();
				};
			},
			getFieldMetaData(data)
			{
				// Find all key metas
				// This loop only affects repeaters, but only first level _meta properties are important; this applies to nested repeaters too.
				// This had broken deletion of repeater iterations, as the repeater fields also had _meta properties found by this check.
				// It led to deleted indexes being checked again, of course throwing an error and halting execution

				// This function relies heavily on the old behaviour of dot-object, so we set it back to do that here. TODO: refactor this so we don't care
				dot.useBrackets = false;
				const _metas = Object.keys(dot.dot(data)).filter((key) => /\d+\._meta\./.test(key));

				// we MUST make sure to reset this, or we'll get inconsistent behaviour!
				dot.useBrackets = true;

				return _metas;
			},
			manipulateSubmittedData(data)
			{
				const _metas = this.getFieldMetaData(data);

				// This function will find anything with a `_meta.deleted=true` flag on it and remove it from the data.
				function removeDeletedEntries(data, index)
				{
					const output = cloneDeep(data);

					// Check whether the parent object actually exists
					// optimisation for Firefox (Chromium has inconsistent results, but usually a slight benefit)
					// if(Object.keys(output?.[index]?._meta || {}).includes('deleted'))
					if(output?.[index]?._meta && Object.prototype.hasOwnProperty.call(output[index]._meta, 'deleted'))
					{
						if(output[index]._meta.deleted === true)
						{
							output.splice(index, 1);
						}
						else
						{
							delete output[index]._meta.deleted;
						}
					}

					return output;
				}

				// process from back to forth so the deleting of items from the array wouldn't be an issue
				_metas.reverse().forEach((key) =>
				{
					key = key.substring(0, key.indexOf('_meta')).replace(/\.$/, '');
					const keys = key.split('.');

					const i = keys.pop();

					const parentObject = dot.pick(keys.join('.'), data);

					const parentObjectInLocalMeta = this.localFieldsMeta?.[keys.join('.')];

					// This has been quite a pain. This data is stored in 3 places - the data we just submitted, and twice in this.localFieldsMeta.
					// This line handles updating the data
					dot.set(keys.join('.'), removeDeletedEntries(parentObject, i), data);

					// And these lines update the fields meta
					if(parentObjectInLocalMeta)
					{
						this.localFieldsMeta[keys.join('.')].value = removeDeletedEntries(parentObjectInLocalMeta.value, i);
						this.localFieldsMeta[keys.join('.')].inputValue = removeDeletedEntries(parentObjectInLocalMeta.inputValue, i);
					}
				});

				return data;
			},
			async submitForm(data, action = false, opts = { doNotClose: false })
			{
				if(this.formHasErrors || this.submitted) return;

				if(this.onlySubmitOnce) this.submitted = true;

				if(!this.isEditable && !this.alwaysEdit) return;

				if(this.$parent?.displayBlock?.type === 'handlebars')
				{
					// tell the display block to wait a moment for new data
					this.displayLoading = true;
					// give the display block time to load
					setTimeout(() =>
					{
						this.displayLoading = false;
					}, 2000);
				}

				this.savingInProgress = true;

				// since we're now using a copy of form data directly,
				// we'll need to clone it before trying to manipulate it
				let changedData = cloneDeep(
					pickBy(
						data,
						(val, key) => (
							this.skipDirtyCheck ||
							this.dirtyFields.includes(key) ||
							Array.isArray(val) ||
							typeof val === 'object'
						)
					)
				);

				changedData = this.manipulateSubmittedData(changedData);

				// make sure we don't save completely empty repeater iterations
				// this does not check for empty strings because a user could have legitimately cleared all values from an iteration
				const filteredData = Object.keys(changedData).reduce((agg, key) =>
				{
					const [field] = this.localFields.filter((field) => field.name === key || field.id === key);

					if(typeof field === 'undefined' || field.type === 'repeater')
					{
						agg[key] = this.checkEmptyRepeaterData(changedData[key]);
					}
					else if(field.type === 'geoLocation')
					{
						// If we have a geolocation field, we don't want to submit its value at all - that's all calculated on the server
						return agg;
					}
					else
					{
						agg[key] = changedData[key];
					}

					return agg;
				}, {});

				// if(!Object.keys(changedData).length)
				// {
				// 	this.toggleFormEdit(false);
				// 	return;
				// }

				if(process.env.NODE_ENV === 'development' && this.$formSaverId && !this.onSubmit)
				{
					console.warn('This form is inside of a form saver but you have not provided an onSubmit function. Suggest replacing @submit with :onSubmit');
				}

				// Sort the repeater data that's been updated as filteredData, one field at a time
				const sortedData = Object.keys(filteredData).reduce((agg, key) =>
				{
					const field = this.localFields.find((field) => field.name === key || field.id === key);

					if(field?.type === 'repeater')
					{
						agg[key] = sortRepeaterValues(field, filteredData[key], 'store').data;
					}
					else
					{
						agg[key] = filteredData[key];
					}

					return agg;
				}, {});

				this.reassignData(sortedData);

				const sortedFields = Object.keys(sortedData); // because the fields with `.` in them are turned into objects themselves and won't match the fields in `this.dirtyFields`. The check further down is also checking the value instead of the key's existence, so it got some false positives with just `sortedData.includes`

				if(this.onSubmit)
				{
					await this.onSubmit(sortedData);
				}

				bus.$emit('submitForm', this.localFormId, sortedData);
				this.$emit('submit', sortedData);
				this.$emit('submitted', sortedData);

				// remove the field we just changed from the dirty fields array
				this.dirtyFields = this.dirtyFields.filter((field) => !sortedFields.includes(field));

				this.savingInProgress = false;
				if(!opts.doNotClose)
				{
					this.toggleFormEdit(false);
				}

				// If autosaving, this message will display before the data has saved since there is now a long timeout inside of the basic form
				// It's possible this could have negative effects when auto saving is enabled outside of the basic form, but we'll cross that bridge when we come to it
				if(!this.autosave && this.displaySuccessMessage)
				{
					addSuccess(this.$store.getters['i18n/get']('form.saving.success'));
				}
			},
			checkEmptyRepeaterData(data)
			{
				return data?.filter((values) =>
				{
					const metaExists = !!values._meta;
					const metaExistsIsNotEmpty =
						metaExists && typeof values._meta === 'object' && Object.keys(values._meta).length > 0;
					// make sure we aren't counting meta properties, make sure values are useful before saving them
					const dataValuesExist = Object.keys(values).filter((key) => !key.includes('_meta') && !!values[key]?.value).length > 0;
					// make sure it isn't marked for deletion
					const isMarkedForDeletion = values?._meta?.deleted === true;

					return dataValuesExist && (!metaExists || metaExistsIsNotEmpty) && !isMarkedForDeletion;
				}) || [];
			},
			reassignData(data)
			{
				Object.keys(data).forEach((fieldId) =>
				{
					const field = this.localFields.find((field) => field.id === fieldId);

					field.value = cloneDeep(data[fieldId]);
				});
			},
			toggleFormEdit(force = null)
			{
				if(!this.isEditable) return;

				if(force === null)
				{
					this.formEditing = !this.formEditing;
				}
				else
				{
					this.formEditing = force;
				}

				if(this.$refs.form)
				{
					if(this.formEditing)
					{
						this.$refs.form.classList.add('editing');
					}
					else
					{
						this.$refs.form.classList.remove('editing');
					}
				}

				this.localFields.forEach((field) =>
				{
					if(!this.localFieldsMeta[field.id])
					{
						// fields array has changed, probably due to conditional fields... just update the meta
						this.localFieldsMeta = this.setLocalFieldsMeta();
					}

					this.localFieldsMeta[field.id].fieldEdit = false;
					this.localFieldsMeta[field.id].editing = this.formEditing;
				});

				Object.keys(this.fieldMeta).forEach((key) =>
				{
					this.fieldMeta[key].fieldEdit = false;
					this.fieldMeta[key].editing = this.formEditing;
				});
			},
			setLocalFieldsMeta()
			{
				const localFieldsMeta = {};

				this.localFields.forEach((field) =>
				{
					if(this.localFieldsMeta && this.localFieldsMeta[field.id])
					{
						localFieldsMeta[field.id] = this.localFieldsMeta[field.id];
					}
					else
					{
						localFieldsMeta[field.id] = this.initialLocalFieldMeta();
					}
				});

				return localFieldsMeta;
			},
			initialLocalFieldMeta()
			{
				return {
					editing: this.formEditing,
					fieldEdit: false
				};
			},
			setLocalDataCopy()
			{
				// sets the copy of all data in the store so conditional fields can keep track of any changes across repeaters
				if(!this.$store.getters['profileFormCopy/getFormData'](`${this.mainFormId}-${this.$store.getters['user/accountId']}`))
				{
					const copyOfUser = cloneDeep(this.$store.getters['profiles/getSimpleData'](this.$store.getters['user/accountId']));

					this.$store.dispatch('profileFormCopy/setFormData', { formId: `${this.mainFormId}-${this.$store.getters['user/accountId']}`, data: copyOfUser });

					if(this.accountToUse && !Number.isNaN(this.accountToUse)) // needs to be profile accountId to be user in this context
					{
						const copyOfProfile = cloneDeep(this.$store.getters['profiles/getSimpleData'](this.accountToUse));

						this.$store.dispatch('profileFormCopy/setFormData', {
							formId: `${this.mainFormId}-${this.accountToUse}`,
							data: copyOfProfile
						});
					}
				}

				if(this.entityId && !this.$store.getters['profileFormCopy/getFormData'](`${this.mainFormId}-${this.entityId}`))
				{
					const copyOfEntity = cloneDeep(this.$store.getters['entities/byId'](this.entityId));

					this.$store.dispatch('profileFormCopy/setFormData', { formId: `${this.mainFormId}-${this.entityId}`, data: copyOfEntity });
				}

				const formDataFormId = `${this.mainFormId}-${this.localFormId}`;

				if(!this.$store.getters['profileFormCopy/getFormData'](formDataFormId))
				{
					const copyOfFormData = cloneDeep(this.localFields.reduce((data, { key, inputValue }) =>
					{
						data[key] = inputValue;

						return data;
					}, {}));

					this.$store.dispatch('profileFormCopy/setFormData', { formId: formDataFormId, data: copyOfFormData });
				}
			},
			calculateProgress()
			{
				const fieldLength = this.localFields.length;
				const filledFields = this.localFields.map((field) =>
				{
					const input = field.inputValue !== undefined ? field.inputValue : field.value;

					if(field.type === 'text' || field.type === 'textarea' || field.type === 'email' || field.type === 'number')
					{
						if(input?.length)
						{
							return true;
						}
					}

					if(field.type === 'checkbox' || field.type === 'radio')
					{
						if(Object.keys(input).length && Object.values(input).some((value) => value))
						{
							return true;
						}
					}

					if(field.type === 'options')
					{
						if(input?.value?.length || input?.length)
						{
							return true;
						}
					}

					// Toggles are valid on or off?
					return field.type === 'toggle' || field.type === 'heading' || field.type === 'paragraph' || field.type === 'repeater';
				})
					.filter((filled) => filled);

				this.progress = (filledFields.length / fieldLength) * 100;

				this.$emit('progress', this.progress);
			},
			/**
			 * Danger, Will Robinson!
			 * You're gonna have a bad time, when resetting many fields. Rather just rerender the Form
			 * Forms with a few fields should be fine. Many fields can queue enough changes that they're affected by the debounced race-condition issue
			 */
			reset()
			{
				bus.$emit(`resetFields:${this.localFormId}`);
			},
			getI18N(path)
			{
				if(!path)
				{
					const path = this.i18nPath ? `${this.i18nPath}.saveAll` : 'blocks.basicForm.saveAll';

					return this.$store.getters['i18n/get'](path);
				}

				return this.$store.getters['i18n/get'](path);
			},
			withConditionalFieldsRecursive(otherFields)
			{
				const fields = [];

				if(!(Array.isArray(otherFields) && otherFields.length))
				{
					return fields;
				}

				otherFields.forEach((field) =>
				{
					if(field.type === 'conditional')
					{
						const accountId = this.$store.getters['user/accountId'];

						const userData = this.$store.getters['profileFormCopy/getFormData'](
							`${this.mainFormId}-${accountId}`
						);

						const profileData = this.accountToUse && !Number.isNaN(this.accountToUse) ?
							this.$store.getters['profileFormCopy/getFormData'](
								`${this.mainFormId}-${this.accountToUse}`
							) : {};

						// When creating an entity from a template, profileFormCopy === formData instead of entity data
						// This allows conditions with entity rules to still work as intended in entity templates
						// If entity data comes from form data, the `context` property needs to be added
						const entityData = this.entityId ?
							this.$store.getters['profileFormCopy/getFormData'](
								`${this.mainFormId}-${this.entityId}`
							) :
							this.formatEntityData(this.$store.getters['profileFormCopy/getFormData'](
								`${this.mainFormId}-${this.localFormId}`
							));

						const formData = this.$store.getters['profileFormCopy/getFormData'](
							`${this.mainFormId}-${this.localFormId}`
						);

						// check if all the rules are met to show the fields
						if(field.children?.length &&
							this.areConditionsMet(
								field.rules,
								accountId,
								this.accountToUse,
								this.entityId,
								userData,
								profileData,
								entityData || {},
								formData
							)
						)
						{
							field.children.forEach((childFieldId) =>
							{
								const schema = this.$store.getters['structure/fields/getSchemaForField'](childFieldId);

								if(schema)
								{
									if(schema.type === 'conditional')
									{
										const subField = {
											...schema,
											...this.$store.getters['structure/fields/getField'](childFieldId)
										};
										const subFields = this.withConditionalFieldsRecursive([subField]);

										if(subFields.length)
										{
											fields.push(...subFields);
										}
									}
									else
									{
										if(!this.localFieldsMeta[childFieldId])
										{
											this.localFieldsMeta[childFieldId] = this.initialLocalFieldMeta();
										}

										const value = this.dataSchemaValue(field, schema);

										fields.push({
											...schema,
											...this.$store.getters['structure/fields/getField'](childFieldId),
											value: value || null
										});
									}
								}
							});
						}
					}
					else
					{
						if(field.dirty) // somewhere up along the call path the field has been marked as dirty, let the form know. E.g. from entity templates
						{
							this.addDirtyField(this.localFormId, field.id);
						}

						fields.push(field);
					}
				});

				return fields;
			},
			/**
			 * @returns {boolean} Did this form validate correctly? Returns `true` if there are no errors.
			 */
			async manualValidate()
			{
				return this.$refs.observer.validate();
			},
			/**
			 * Allows to explicitly set or unset the disabled prop for some fields. This function results in manipulating two arrays (mentioned below) which have control over the disabled prop on fields in localFields above.
			 * @param {string[]} fieldNames     Names of fields on which you wish to toggle the disabled prop.
			 * @param {string} action           Action to perform. Accepts 'disable' or 'enable'.
			 */
			toggleDisableFields(fieldNames, action)
			{
				// Names of the arrays we're manipulating
				const disableArrayName = 'forceDisableFields';
				const enableArrayName = 'forceEnableFields';
				let addTo = null,
					removeFrom = null;

				// Set up which array to subtract from and which to add to based on action
				// Ex: if disabling a field, we want to add to the 'disable' array and remove from the 'enable' array
				switch(action)
				{
					case 'disable':
						addTo = disableArrayName;
						removeFrom = enableArrayName;

						break;
					case 'enable':
						addTo = enableArrayName;
						removeFrom = disableArrayName;

						break;
					default:
						break;
				}

				// Provided the action given was valid, and we're set up, continue
				if(addTo && removeFrom)
				{
					if(Array.isArray(fieldNames))
					{
						// Re-assign the array we add to ensuring no duplicates
						// Ex: re-assign the forceDisableFields array with new field names
						this[addTo] = uniq([
							...this[addTo],
							...fieldNames
						]);

						// Iterate through array to remove from and take out the field names listed
						// Ex: remove our field names from forceEnableFields
						fieldNames.forEach((fieldName) =>
						{
							const index = this[removeFrom].findIndex((existingName) => fieldName === existingName);

							if(index > -1)
							{
								this[removeFrom].splice(index, 1);
							}
						});
					}
				}
			},
			updateFieldOrder(formId, field)
			{
				if(formId && this.formId?.split('#')?.shift() === formId && this.repeatersKey)
				{
					this.localFields.forEach((localField, index) =>
					{
						const keyArray = localField.key.split('.');

						if(keyArray.length > 2)
						{
							// Get the second last item in the array, that's our key
							const key = keyArray[keyArray.length - 2];

							// If this doesn't exist, just skip; we don't want to run the else statement below
							if(field.inputValue?.[this.repeatersKey])
							{
								if(field.inputValue[this.repeatersKey][key])
								{
									this.$set(this.localFields[index], 'inputValue', field.inputValue[this.repeatersKey][key].value);
								}
								else
								{
									this.$set(this.localFields[index], 'inputValue', undefined);
								}
							}
						}
					});
				}
			},
			updateParentMeta({ field, value })
			{
				// NOTE: localFieldsMeta on the parent Form component holds a copy of the visible input values for your form
				if(this.localFieldsMeta[field.id])
				{
					if(Object.prototype.hasOwnProperty.call(this.localFieldsMeta[field.id], 'inputValue'))
					{
						this.$set(this.localFieldsMeta[field.id], 'inputValue', value);
					}

					if(Object.prototype.hasOwnProperty.call(this.localFieldsMeta[field.id], 'value'))
					{
						this.$set(this.localFieldsMeta[field.id], 'value', value);
					}
				}
			},
			addDirtyField(formId, fieldId)
			{
				if(!formId || (this.formId !== formId && this.localFormId !== formId)) return;

				if(!this.dirtyFields.includes(fieldId))
				{
					this.dirtyFields.push(fieldId);
				}
			},
			removeDirtyField(formId, fieldId)
			{
				if(!formId || (this.formId !== formId && this.localFormId !== formId)) return;

				this.dirtyFields = this.dirtyFields.filter((id) => id !== fieldId);
			},
			toggleConfirmation()
			{
				this.displayConfirmation = true;
			},
			addFormError(error)
			{
				if(this.$formId)
				{
					// Only the top level form needs to care - pass the buck
					bus.$emit(`form-error-add:${this.$formId}`, error);

					return;
				}

				if(!this.formErrors[`${error.field}-${error.repeatersKey}`])
				{
					this.$set(this.formErrors, `${error.field}-${error.repeatersKey}`, []);
				}

				this.formErrors[`${error.field}-${error.repeatersKey}`].push(error);
				// this.formErrors.push(error.msg);
			},
			clearFormErrors(ctx)
			{
				if(this.$formId)
				{
					// Only the top level form needs to care - pass the buck
					bus.$emit(`form-errors-clear:${this.$formId}`, ctx);

					return;
				}

				// Reset them to empty
				this.$delete(this.formErrors, `${ctx.fieldId}-${ctx.repeatersKey}`);
			},
			/**
			 * Formats FormData into EntityData structure by adding the `context` parent property for data properties
			 * that have `dataSchema.source === entity`
			 * @param entityData
			 */
			formatEntityData(entityData)
			{
				return Object.entries(entityData || {}).reduce((agg, [key, value]) =>
				{
					if(key === 'context')
					{
						agg.context = { ...agg.context, ...value };

						return agg;
					}

					const schema = this.$store.getters['dataSchemas/byKey'](key);

					if(schema?.source === 'entity')
					{
						agg.context[key] = value;
					}
					else
					{
						agg[key] = value;
					}

					return agg;
				}, { context: {} });
			}
		}
	};
</script>

<style lang="postcss" scoped>
	.form-loader {
		z-index: 10;
	}

	.formHeadingsWrapper {
		padding-bottom: 16px;
		overflow: hidden;

		h2 {
			display: inline;
		}
	}

	.fieldContainer {
		width: 100%;
		padding: 8px 0;
		position: relative;

		&:last-child {
			border-bottom: 0;
		}
	}

	.block.editing {
		background: rgba(239, 239, 239, 0.5);
		padding: 20px 20px 0 20px;
		box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
		border: 1px solid rgba(0, 0, 0, 0.1);

		>>> .field {
			border-bottom: none;
			padding: 0 0 5px 0;
		}

		.button-container {
			position: relative;
			width: 100%;
			padding: 15px 20px;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			border-radius: 0 0 5px 5px;
			box-shadow: 0 -1px 20px 0 rgba(0, 0, 0, 0.1);
			left: -20px;
			display: block;
			background: #efefef;
			text-align: right;
			box-sizing: content-box;
		}
	}

	.form {
		margin-bottom: 18px;

		.form-fields {
			>>> .field {
				margin-bottom: 18px;
				border-bottom: none;
			}
		}

		&.editing {
			margin-bottom: 0;

			.form-fields {
				>>> .field {
					margin-bottom: 0;
				}
			}
		}
	}

	>>> .q-field--with-bottom {
		padding-bottom: 10px;
		transition: 0.5s 0.2s;
	}

	>>> .q-field--with-bottom.q-field--error {
		padding-bottom: 32px;

		.q-field__bottom {
			margin-bottom: 2px;
			padding: 6px 10px;
			color: var(--q-color-negative);
			font-weight: 600;
		}
	}

	/* To position any additional buttons on the same line as the Cancel and Save buttons*/
	.button-container >>> span > div {
		display: inline-block;
	}
</style>

<style>
	.q-tooltip--style {
		font-size: 12px;
	}
</style>
