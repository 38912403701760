import Vue from 'vue';
import uniq from 'lodash/uniq';
import admin from '@/store/structure/blocks/admin';
import { get, post, put } from '@/utils/api';
import { setItems } from '@/utils/store';
import { cAdminPanelTypes } from '@/configs/adminPanelTypes';

const modules = {
	admin
};

const state = {
	blocks: {}
};

const mutations = {
	SET_BLOCKS(state, blocks)
	{
		if(!blocks || !blocks.length) return;

		if(blocks.length === 1)
		{
			// this is mainly used when saving:
			// when updating one block, update _only_ that one block,
			// not the whole `state.blocks` object
			// as the latter will trigger an update on _all_ blocks on the page.
			Vue.set(state.blocks, blocks[0].id, blocks[0]);
		}
		else
		{
			// IMPORTANT - updates to one block makes all blocks reload!
			setItems(state, 'blocks', state.blocks, blocks);
		}
	},
	SET_BLOCK(state, block)
	{
		Vue.set(state.blocks, block.id, block);
	},
	CLEAR(state)
	{
		Vue.set(state, 'blocks', {});
	},
	SET_BLOCK_PROPERTY(state, { blockId, property, value })
	{
		const block = state.blocks[blockId];
		const newBlock = { ...block, [property]: value };

		Vue.set(state.blocks, blockId, newBlock);
	},
	ADD_CHILD(state, { blockId, childId })
	{
		const block = state.blocks[blockId];

		if(!block.children)
		{
			block.children = [];
		}

		block.children.push(childId);
		Vue.set(state.blocks, blockId, { ...block });
	},
	REMOVE_BLOCKS(state, blockIds)
	{
		blockIds.forEach((id) =>
		{
			delete state.blocks[id];
		});
	}
};

const actions = {
	async loadForce({ dispatch }, id)
	{
		const { data } = await get(`/structure/block/${id}`);

		await dispatch('app/handleServerResponse', data, { root: true });
	},
	async load({ dispatch, getters }, id)
	{
		if(getters.getBlock(id)) return;

		await dispatch('loadForce', id);
	},
	async loadMultiple({ dispatch, getters }, ids)
	{
		if(!Array.isArray(ids))
		{
			ids = [ids];
		}

		const blocksNeeded = ids.filter((id) => !getters.getBlock(id));

		if(!blocksNeeded.length) return {};

		const { data } = await post('/structure/block/loadMultiple', { ids: uniq(blocksNeeded) });

		await dispatch('app/handleServerResponse', data, { root: true });

		return data;
	},
	setBlocks({ commit }, blocks)
	{
		if(!blocks || (Array.isArray(blocks) && !blocks.length)) return;

		if(!Array.isArray(blocks)) commit('SET_BLOCK', blocks);

		commit('SET_BLOCKS', blocks);
	},
	setBlock({ commit }, block)
	{
		commit('SET_BLOCK', block);
	},
	setBlockProperty({ commit }, payload)
	{
		commit('SET_BLOCK_PROPERTY', payload);
	},
	addChild({ commit }, payload)
	{
		commit('ADD_CHILD', payload);
	},
	openPermissionEditPanel({ dispatch }, data)
	{
		dispatch('admin/panel/new', {
			id: `permissions-${data.block.id}`,
			type: cAdminPanelTypes.permissions,
			...data
		}, { root: true });
	},
	// Remove all blocks from the store. Used for 'viewAs'.
	clear({ commit })
	{
		commit('CLEAR');
	},
	reset({ dispatch })
	{
		dispatch('clear');
	},
	async removeBlocks({ commit }, blockIds)
	{
		commit('REMOVE_BLOCKS', blockIds);
	},
	async addComment({ dispatch }, { blockId, comment })
	{
		const { data } = await put(`structure/block/${blockId}/comment`, { comment });

		await dispatch('communications/updateMessage', data.comment, { root: true });
		dispatch('communications/setTotalCommunications', { pageId: blockId, total: data.total }, { root: true });

		return data;
	},
	async editComment({ dispatch }, { blockId, commentId, comment })
	{
		const { data } = await post(`structure/block/${blockId}/comment/${commentId}`, { comment });

		await dispatch('communications/updateMessage', data, { root: true });
	},
	async deleteComment({ dispatch }, { blockId, commentId })
	{
		await dispatch('communications/remove', { targetId: blockId, communicationId: commentId }, { root: true });
	},
	async setReaction({ dispatch }, { blockId, commentId, reactionId })
	{
		const { data } = await post(`structure/block/${blockId}/setReaction/${commentId}`, { reactionId });
		const { score, userReactions, individualReactionScores } = data.updates;

		await dispatch('communications/updatePostScore', { communicationId: commentId, score }, { root: true });
		dispatch('communications/updatePostReactionScores', { communicationId: commentId, scores: individualReactionScores }, { root: true });
		dispatch('user/setPostReactions', { postId: commentId, reactions: userReactions }, { root: true });
	},
	async recalculateOverallPostScore({ dispatch }, { blockId })
	{
		const { data } = await get(`structure/block/${blockId}/recalculatePostScores`);

		Object.entries(data.updates).forEach(async ([communicationId, score]) =>
		{
			communicationId = +communicationId;

			await dispatch('communications/updatePostScore', { communicationId, score }, { root: true });
		});
	}
};

const getters = {
	getBlocks: (state) => state.blocks,
	getBlock: (state, getters) => (blockId) => getters.getBlocks[blockId],
	getBlocksOfTypes: (state, getters) => (types) =>
	{
		if(!Array.isArray(types))
		{
			return getters.getBlockByType(types);
		}

		const blocks = getters.getBlocks;

		return Object.values(blocks).filter((block) => types.includes(block.type));
	},
	getBlockByType: (state, getters) => (type) => getters.getBlocksOfTypes([type]),
	getBlocksForPage: (state, getters, rootState, rootGetters) => (pageId) =>
	{
		const page = rootGetters['structure/pages/getPageById'](pageId);

		return page.children.map((blockId) => getters.getBlock(blockId));
	},
	getGlobalBlocks: (state, getters, rootState, rootGetters) =>
	{
		const globals = rootGetters['structure/modules/getModuleById']('global');

		if(!globals)
		{
			return [];
		}

		return globals.blocks;
	},
	getGlobalBlock: (state, getters) => (blockId) =>
	{
		const global = getters.getGlobalBlocks;

		return global.find((block) => block.id === blockId);
	},
	getActivePageBlockIds: (state, getters, rootState, rootGetters) =>
	{
		const page = rootGetters['structure/pages/getActivePage'];

		if(!page || !page.positions)
		{
			return [];
		}

		return page.positions['col-0'] || [];
	},
	getActivePageBlocks: (state, getters, rootState, rootGetters) =>
	{
		const page = rootGetters['structure/pages/getActivePage'];

		if(!page || !page.positions)
		{
			return [];
		}

		return page.positions['col-0'].map((blockId) => getters.getBlock(blockId));
	},
	getFieldTemplate: (state, getters) => (blockId, templateId) =>
	{
		return getters.getBlock(blockId).content.templates[templateId];
	},
	getFieldByName: (state, getters) => (blockId, templateId, fieldName) =>
	{
		return getters.getFieldTemplate(blockId, templateId).fields.find((field) => field.name === fieldName);
	},
	/**
	 * Quite dangerous: this is potentially wrong when a block is used in more than one place.
	 * Prefer using `parentId`, available in `Block`, `BlockToolbar` and `ElementControls`.
	 * */
	getBlockParent: (state, getters, rootState, rootGetters) => (blockId, includePage = false) =>
	{
		const parent = Object.values(getters.getBlocks)
			.find((block) => block.children?.includes(blockId));

		if(!parent && includePage)
		{
			return Object.values(rootGetters['structure/pages/getPages']).find((page) => page.children?.includes(blockId));
		}

		return parent;
	},
	getAncestors: (state, getters) => (blockId) =>
	{
		const parent = getters.getBlockParent(blockId);
		const ancestors = [];

		ancestors.push(parent);

		if(parent?.type)
		{
			ancestors.push(...getters.getAncestors(parent.id));
		}

		return ancestors.filter((item) => item);
	},
	getPageForBlock: (state, getters) => (blockId) =>
	{
		const ancestors = getters.getAncestors(blockId) || [];
		const topLevelBlockId = ancestors[ancestors.length - 1]?.id;

		return getters.getBlockParent(topLevelBlockId, true);
	}
	// getFieldById: (state, getters) => (blockId, fieldId) =>
	// {
	// 	const block = getters.getBlock(blockId);
	// 	const { templates } = block.content;
	//
	// 	let field;
	//
	// 	Object.entries(templates).forEach(([, template]) =>
	// 	{
	// 		const match = template.fields.find((f) => f.id === fieldId);
	// 		if(match)
	// 		{
	// 			field = match;
	// 		}
	// 	});
	//
	// 	return field;
	// }
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
