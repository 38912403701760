// @ts-check
import { v4 as uuid } from 'uuid';
import { computed } from 'vue';
import { post } from '@/utils/api';
import store from '@/store';
import {
	cSettingId,
	cUserConnectivityIndicatorBehaviour,
	cConnectivityPrivacyPath
} from '@/configs/constants/settings';

/**
 * @typedef {import(
 * 	'@/types/settings'
 * ).UserConnectivitySettings} UserConnectivitySettings
 * @typedef {import('@/types/app').UUID} UUID
 */
/**
 * @template T
 * @typedef {import('vue').ComputedRef<T>} ComputedRef<T>
 */
/**
 * @template T
 * @typedef {import('vue').Ref<T>} Ref<T>
 */
/** Time before the keepalive is considered timed out. */
export const cUserDeviceKeepaliveTimeout = 900000;

/**
 * Allow breathing room for requests
 * with a more frequent interval than the timeout.
 */
const cUserDeviceKeepaliveRefreshTime = 600000;

/** @type {ComputedRef<UserConnectivitySettings>} */
export const appUserConnectivitySettings = computed(() => store.getters[
	'app/settings/get'
](cSettingId.userConnectivity));

/** @type {(UUID | null)} */
let deviceId = localStorage.getItem('deviceId');

if(!deviceId)
{
	deviceId = uuid();

	localStorage.setItem('deviceId', deviceId);
}

/** @returns {Promise<void>} */
const updateDeviceKeepalive = async () =>
{
	await post('user/deviceKeepalive', {
		deviceId
	});
};
/** @type {number | undefined} */
let userDeviceKeepaliveInterval;

/** @returns {void} */
export function addUserDeviceKeepaliveInterval()
{
	if(userDeviceKeepaliveInterval) return;

	if(!appUserConnectivitySettings.value?.isEnabled) return;

	userDeviceKeepaliveInterval = setInterval(
		async () =>
		{
			// The feature has been disabled. Cease the keepalive.
			if(!appUserConnectivitySettings.value?.isEnabled)
			{
				removeUserDeviceKeepaliveInterval();

				return;
			}

			await updateDeviceKeepalive();
		},
		cUserDeviceKeepaliveRefreshTime
	);

	updateDeviceKeepalive();
}

/** @returns {void} */
export function removeUserDeviceKeepaliveInterval()
{
	if(userDeviceKeepaliveInterval)
	{
		clearInterval(userDeviceKeepaliveInterval);
		userDeviceKeepaliveInterval = undefined;
	}
}

/** @type {ComputedRef<number>} */
const currentUserAccountId = computed(
	() => store.getters['user/accountId']
);

/**
 * @param {Ref<number>} accountId
 * - A Composition API ref of the target accountId.
 * Defaults to the store's current user.
 * @returns {ComputedRef<boolean>}
 */
export function getIsUserOnlineComputed(
	accountId
)
{
	return computed(() =>
	{
		if(accountId.value === currentUserAccountId.value)
		{
			return !store.getters['app/isOffline'];
		}

		const user = store.getters['profiles/get'](accountId.value);

		return !!user?.keepaliveTimestamp &&
			user.keepaliveTimestamp > (
				Date.now() - cUserDeviceKeepaliveTimeout
			);
	});
}

/**
 * If the current user has privacy enabled and isn't an admin,
 * they can't see the connectivity of other users.
 * The API won't return the keepalive timestamp so all users
 * will appear offline if the indicator is shown.
 */
export const isCurrentUserPrivate = computed(() => (
	!store.getters[
		'user/isAdmin'
	] && store.getters[
		'user/metaValue'
	](cConnectivityPrivacyPath)
));

/** @type {ComputedRef<boolean>} */
export const isFeatureEnabled = computed(
	() => !!appUserConnectivitySettings.value?.isEnabled
);

/** @type {ComputedRef<cUserConnectivityIndicatorBehaviour>} */
export const indicatorBehaviour = computed(
	() => appUserConnectivitySettings.value?.indicatorBehaviour ||
		cUserConnectivityIndicatorBehaviour.trafficLight
);
