import Vue from 'vue';
import { pick } from 'dot-object';
import { destroy, post, put, get } from '@/utils/api';

const state = {
	loaded: false
};

const mutations = {
	SET_LOADED(state)
	{
		state.loaded = true;
	},
	SET_FIELDS(state, fields)
	{
		Vue.set(state, 'fields', fields);
	}
};

const actions = {
	async loadAll({ state, dispatch, commit })
	{
		if(state.loaded) return;

		const { data } = await get('structure/field/', { params: { withSchemas: true } });

		await dispatch('app/handleServerResponse', data, { root: true });
		// dispatch('structure/fields/setFields', data.fields, { root: true });
		// dispatch('dataSchemas/setDataSchemas', data.dataSchemas, { root: true });

		// commit('SET_FIELDS', fields);

		commit('SET_LOADED');
	},
	async upsert({ dispatch }, { field, itemId = null, itemType = null, fieldId = null, source = null, _opts = { overwrite: ['options'] } })
	{
		let data;

		if(field.id)
		{
			({ data } = await post('structure/field', { itemId, itemType, fieldId, field, _opts, source }));
		}
		else
		{
			({ data } = await put('structure/field', { itemId, itemType, fieldId, field, _opts, source }));
		}

		await dispatch('structure/admin/handleServerChanges', data, { root: true });

		return data;
	},
	async delete({ dispatch }, { id, itemId, itemType })
	{
		const { data } = await destroy(`structure/field/${id}/${itemType}/${itemId}`);

		await dispatch('structure/admin/handleServerChanges', data, { root: true });
	},
	async deleteEverywhere({ dispatch }, { id })
	{
		const { data } = await destroy(`structure/field/${id}`);

		await dispatch('structure/admin/handleServerChanges', data, { root: true });
	}
};

const getters = {
	loaded: (state) => state.loaded,
	getAll: (state, getters, rootState, rootGetters) =>
	{
		// Field IDs to not include in the list
		const fieldsToFilter = ['language'];

		return Object.values(rootGetters['structure/fields/getFields'])
			.filter((field) => !fieldsToFilter.includes(field.id))
			.map((field) =>
			{
				const schema = rootGetters['dataSchemas/byId'](field.schema) || {};

				return { ...field, _schema: schema, _i18n: rootGetters['i18n/get'](`custom.fields.${field.id}`) };
			});
	},
	getBy: (state, getters) => (property, value) => getters.getAll.filter((field) => pick(property, field) === value),
	getByType: (state, getters) => (type) => getters.getBy('type', type),
	getByLabel: (state, getters) => (text) =>
	{
		const searchText = text.toLowerCase();

		return getters.getAll.filter((field) => field?._i18n?.label?.toLowerCase().includes(searchText));
	},
	getFieldsBySchemaSource: (state, getters) => (source) =>
	{
		if(Array.isArray(source)) return getters.getAll.filter((field) => source.includes(field._schema.source));

		return getters.getAll.filter((field) => field._schema.source === source);
	},
	getByLabelAndBySource: (state, getters) => (source, text = '') =>
	{
		const searchText = text.toLowerCase();

		return getters
			.getFieldsBySchemaSource(source)
			.filter((field) =>
			{
				if(typeof field?._i18n?.label !== 'string')
				{
					return false;
				}

				return field._i18n.label.toLowerCase().includes(searchText);
			});
	},
	getByLabelAndKeyAndBySource: (state, getters) => (source, text = '') =>
	{
		const searchText = text.toLowerCase();

		return getters
			.getFieldsBySchemaSource(source)
			.filter((field) =>
			{
				if(typeof field?._i18n?.label !== 'string')
				{
					return false;
				}

				return field._i18n.label.toLowerCase().includes(searchText) ||
					field._schema?.key?.toLowerCase().includes(searchText);
			});
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
