<script>
	import { QBtn, QIcon, QTooltip } from 'quasar';

	export default {
		name: 'SimpleButton',
		extends: QBtn,
		props: {
			color: {
				type: String,
				default: 'primary'
			},
			iconLeft: {
				type: String,
				default: null
			},
			customColor: {
				type: String,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		render(h)
		{
			const attrs = this.$attrs;
			const props = {
				...this.$props,
				disable: this.disabled || this.$props.disable
			};
			let icon,
				tooltip;

			if(props.iconLeft && !props.round)
			{
				icon = h(QIcon, { props: { name: props.iconLeft, left: true } });
			}

			if(attrs['aria-label'] && !(this.$slots.default && this.$slots.default.some((node) => node.componentOptions && node.componentOptions.tag === 'q-tooltip')) && !this.disable)
			{
				tooltip = h(QTooltip, {}, attrs['aria-label']);
			}

			// If the button is white, and the text colour has not been explicitly set, make it black
			if(props.color === 'white' && !props.textColor) props.textColor = 'black';

			const style = this.customColor ? { background: `${this.customColor} !important` } : undefined;

			return h(QBtn, { props, on: this.$listeners, style }, [
				icon,
				tooltip,
				this.$slots.default
			]);
		}
	};
</script>
