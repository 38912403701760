<template>
	<div>
		<q-splitter
			v-model="splitterModel"
		>
			<template v-slot:before>
				<q-tabs
					v-model="innerTab"
					vertical
					:class="$q.dark.isActive ? 'white' : 'text-grey-9'"
					@input="storeCurrentTab()"
				>
					<div v-for="(userPreferenceType, index) in userPreferenceTypes" :key="index">
						<q-tab
							:name="userPreferenceType"
							icon="fad fa-bell"
							:label="getLabel(userPreferenceType)"
						/>
					</div>
				</q-tabs>
			</template>
			<template v-slot:after>
				<q-tab-panels
					v-for="(userPreferenceType, index) in userPreferenceTypes"
					:key="index"
					v-model="innerTab"
					animated
					transitionPrev="slide-down"
					transitionNext="slide-up"
				>
					<q-tab-panel :name="userPreferenceType">
						<component
							:is="typeOfNotification()"
							:preferenceType="userPreferenceType"
							:notificationSettings="definitionSettings(userPreferenceType)"
							:label="getLabel(userPreferenceType)"
						/>
						<!-- <Subscribables :preferenceType="userPreferenceType" /> -->
					</q-tab-panel>
				</q-tab-panels>
			</template>
		</q-splitter>
	</div>
</template>

<script>
	import flatten from 'lodash/flatten';

	export default {
		components: {
			Subscribables: () => import('@/components/ui/subscribablePreferences')
			// PushPreferences: () => import('@/components/ui/pushNotificationsPreferences')
		},
		props: {
			notificationsToDisplay: {
				type: String,
				required: true
			}
		},
		data()
		{
			return {
				tab: 'subscribables',
				innerTab: null,
				splitterModel: 20,
				entities: [],
				loadingTab: false
			};
		},
		computed:
			{
				userPreferenceTypes()
				{
					const userPrefs = this.$store.getters['user/notifications/getUserPreferences'];

					return flatten(userPrefs?.map((value) => Object.keys(value)) || []);
				},
				entityDefinitionIds()
				{
					return this.userPreferenceTypes.filter((id) => id !== 'global');
				},
				startingTab()
				{
					const currentTab = this.$store.getters['user/notifications/getNotificationCenterTab'];

					return currentTab || this.userPreferenceTypes[0];
				},
				loading()
				{
					return this.$store.getters['entityDefinition/loading'];
				}
			},
		async created()
		{
			this.innerTab = this.startingTab;
			this.$store.dispatch('entityDefinitions/loadAll', { force: true });
		},
		methods:
			{
				async storeCurrentTab()
				{
					await this.$store.dispatch('user/notifications/setNotificationCenterTab', { tab: this.innerTab });
				},
				entityDef(defId)
				{
					return this.$store.getters['entityDefinitions/byId'](defId);
				},
				typeOfNotification()
				{
					switch(this.notificationsToDisplay)
					{
						case 'subscribables':
							return 'Subscribables';
						// case 'push':
						// 	return 'PushPreferences';
						default:
							return 'Subscribables';
					}
				},
				getLabel(userPrefType)
				{
					this.loadingTab = true;

					if(userPrefType !== 'global')
					{
						const entity = this.entityDef(userPrefType);

						if(entity)
						{
							this.loadingTab = false;

							return entity.name;
						}
					}

					this.loadingTab = false;

					return `${userPrefType[0].toUpperCase()}${userPrefType.slice(1)}`;
				},
				definitionSettings(userPrefType)
				{
					if(userPrefType !== 'global')
					{
						const entity = this.entityDef(userPrefType);

						return entity?.settings?.notificationsForEntities || [];
					}

					return [];
				}
			}
	};
</script>
