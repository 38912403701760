import RuleParser from '@/components/admin/panel/RuleBuilder/mixins/RuleParser';
import { userCan } from '@/plugins/Permissions';

export default {
	mixins: [RuleParser],
	inject: {
		$targetIdIsNotAccountId: {
			default: false
		}
	},
	computed: {
		entityId()
		{
			// get the first valid entityId based on this hierarchy
			return [
				this.data?.entityId,
				this.targetIdOverride,
				this.$store.state.route.params.targetId
			].find((id) => (
				id &&
				Number.isNaN(Number(id)) &&
				this.$store.getters['entities/convertSlugToId'](id)
			));
		},
		accountToUse()
		{
			if(this.$targetIdIsNotAccountId === true)
			{
				return parseInt(this.$store.state.user.accountId, 10);
			}

			const validNumericalId = [
				this.targetIdOverride,
				this.$store.state.route.params.targetId,
				this.$store.state.user.accountId
			].find((id) => (
				id && (String(parseInt(id, 10)) === String(id))
			));

			if(validNumericalId)
			{
				return parseInt(validNumericalId, 10);
			}

			return undefined;
		},
		activePage()
		{
			return (this.$store.getters['app/isAdminDashboard'] &&
				this.$store.getters['app/getCurrentRoute'].params.pageId &&
				this.$store.getters['structure/pages/getPageById'](this.$store.getters['app/getCurrentRoute'].params.pageId)
			) ||
			this.$store.getters['structure/pages/getActivePage'] ||
			{};
		},
		isAdminEditMode()
		{
			return this.$store.getters['admin/isEditMode'] && userCan('manageEditMode', 'administration');
		}
	},
	async mounted()
	{
		// only load the list membership states if the block itself
		// or its children have advanced visibility
		if(this.blockData && (this.blockData.visibility === 'advanced' ||
			(this.blockData.children &&
				this.blockData.children.some((blockId) =>
				{
					const child = this.$store.getters['structure/blocks/getBlock'](blockId);

					// NOT a Vue child block object! Just data
					return child?.visibility === 'advanced';
				})
			))
		)
		{
			await this.$store.dispatch(
				'userLists/loadListMembershipStatesMapForUser',
				{ accountId: this.accountToUse }
			);

			if(this.accountToUse !== this.$store.getters['user/accountId'])
			{
				await this.$store.dispatch(
					'userLists/loadListMembershipStatesMapForUser',
					{ accountId: this.$store.getters['user/accountId'] }
				);
			}
		}
	},
	methods: {
		shouldRender(block)
		{
			if(!block) return false;

			if(this.isPreviewMode || this.isAdminEditMode) return true;

			// check if the user should see this block in this context - we use the data directly to dynamically update the blocks on the page as the user's (or entity's) data changes
			if(block.visibility === 'advanced')
			{
				return this.areConditionsMet(
					block.rules,
					this.$store.getters['user/accountId'],
					this.accountToUse,
					this.entityId,
					this.$store.getters['profiles/getSimpleData'](this.$store.getters['user/accountId']),
					this.accountToUse && !Number.isNaN(this.accountToUse) ? this.$store.getters['profiles/getSimpleData'](this.accountToUse) : {},
					this.entityId ? this.$store.getters['entities/byId'](this.entityId) : {},
					null
				);
			}

			if(typeof block.visibleOnDesktop === 'boolean')
			{
				if(!block.visibleOnDesktop && this.$q.platform.is.desktop)
				{
					return block.visibleOnDesktop;
				}
			}

			if(typeof block.visibleOnMobile === 'boolean')
			{
				if(!block.visibleOnMobile && this.$q.platform.is.mobile)
				{
					return block.visibleOnMobile;
				}
			}

			if(this.activePage.type === 'profile')
			{
				const accountId = parseInt(this.$store.getters['user/accountId'], 10);

				if(this.targetId === null || parseInt(this.targetId, 10) === accountId)
				{
					if(typeof block.visibleOnUsersOwnProfile === 'boolean')
					{
						return block.visibleOnUsersOwnProfile;
					}
				}

				if(typeof block.visibleOnOtherUsersProfiles === 'boolean')
				{
					return block.visibleOnOtherUsersProfiles;
				}
			}
			// If either of these settings are set, but we are NOT on a profile page, we should default to showing nothing - it's safer.
			else if(block.visibleOnUsersOwnProfile === false || block.visibleOnOtherUsersProfiles === false)
			{
				return false;
			}

			return true;
		}
	}
};
