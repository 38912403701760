import { subject } from '@casl/ability';
import ability from '@/utils/ability';
import store from '@/store';

/** NOTE: not an actual mixin so it could be used in locations where the component hasn't been loaded yet, e.g. Admin.vue route check */
export function userCan(action, subjectName, subjectId = undefined, scope = undefined, field = undefined, conditions = {}, { checkIfUserIsAdmin } = { checkIfUserIsAdmin: true })
{
	if(checkIfUserIsAdmin && store.getters['user/isAdmin']) return true;

	if(!action || !subjectName) throw new Error(`You need a subject and action. Action: ${action}, subject: ${subjectName}`);

	// If we use this.$can instead of the store, it remains reactive
	if(ability.can(action, subject(subjectName, { subjectId, scope, ...conditions }), field))
	{
		return true;
	}

	// else was it because there's no rule about this subject?
	if(ability.relevantRuleFor(action, subject(subjectName, { subjectId, scope, ...conditions })) === null)
	{
		const basePaths = store.getters['user/basePaths'];

		// config will have basePaths so this check can be left here and eventually be removed altogether
		if(!basePaths)
		{
			return false;
		}

		// check if the path has parent paths with permissions
		const basePath = basePaths.find((path) =>
		{
			return subjectName !== path && subjectName.indexOf(path) > -1;
		});

		if(basePath)
		{
			return userCan(action, basePath, subjectId, scope, field, conditions);
		}
	}

	return false;
}

/**
 * Accepted formats:
 * - [{ actions: [], subject, subjectId, scope, field, conditions }]
 * - { subject: ['action'] }
 */
export function userCanAnyOf(checks, checkAny = false, opts)
{
	if(checkAny)
	{
		if(Array.isArray(checks))
		{
			return checks.some((check) =>
			{
				return check.actions.some((action) => userCan(action, check.subject, 'has_any_specific_subjectId', undefined, undefined, {}, opts)); // other conditions don't matter if we're just checking if there are any specific permissions for any subjectId-s on the subject
			});
		}

		return Object.keys(checks).some((subject) =>
		{
			return checks[subject].some((action) => userCan(action, subject, 'has_any_specific_subjectId', undefined, undefined, {}, opts));
		});
	}

	if(Array.isArray(checks))
	{
		return checks.some((check) =>
		{
			return check.actions.some((action) => userCan(action, check.subject, check.subjectId, check.scope, check.field, check.conditions || {}, opts));
		});
	}

	return Object.keys(checks).some((subject) =>
	{
		return checks[subject].some((action) => userCan(action, subject, undefined, undefined, undefined, {}, opts));
	});
}

/**
 * Check for any actions on all paths. Mostly used for adminBar checks
 */
export function userCanAnyOfActions(actions, checkAny = false, opts)
{
	if(!Array.isArray(actions))
	{
		actions = [actions];
	}

	return userCanAnyOf({
		'items.modules': actions,
		'items.pages': actions,
		'items.blocks': actions,
		'items.entities': actions,
		'items.applications': actions,
		'items.entityDefinitions': actions,
		'items.dataMappings': actions,
		'items.templates': actions,
		abuseReports: actions,
		files: actions,
		notifications: actions,
		profiles: actions,
		searchManagement: actions,
		settings: actions,
		tools: actions,
		userLists: actions,
		insights: actions,
		connections: actions,
		adminDashboard: actions
	}, checkAny, opts);
}

/** @returns {boolean} */
export function isAluminatiUser()
{
	return store.getters['profiles/isProfileAcceptedInList'](store.getters['user/accountId'], 'aluminati');
}

export function isElevated()
{
	return store.getters['user/isElevated'];
}

export function isPermissionVisible(path)
{
	// check if the permission path is even visible - if it's not, then the permissions can't be set anyway and there's no point in showing the shield either, the user will only see a blank permissions modal

	return store.getters['admin/isPermissionVisible'](path);
}
