<template>
	<div>
		<q-toolbar class="header text-white">
			<div class="modules-container">
				<div
					v-if="isSmallScreen"
					class="mobile-header"
				>
					<img
						v-if="imageId && isSmallScreen"
						:src="imgThumbSrc"
						:alt="siteTitle"
						class="logo"
						@click="$router.push('/')"
					>
					<div class="nav-button">
						<q-btn
							v-if="isSmallScreen"
							dense
							flat
							round
							icon="fal fa-bars"
							@click="drawer = !drawer"
						/>
					</div>
				</div>
				<div
					v-if="!isSmallScreen"
					tabindex="0"
					class="logo"
					@click="$router.push('/')"
				>
					<img
						v-if="imageId"
						v-bind="imgThumbProps"
					>
					<img
						v-else
						src="@/assets/v7-logo.png"
						:alt="siteTitle"
					>
				</div>

				<nav v-if="!isSmallScreen" class="modules-nav fixed-width full-width">
					<Modules
						:gradientFallback="themeStyleModulesFallback"
					/>
				</nav>

				<q-space />

				<div
					v-if="!isSmallScreen"
					class="userTools"
				>
					<Popover
						class="q-mr-sm"
						:initialWidth="cUserControlsMenuWidth"
					>
						<template #activator>
							<UserProfile />
						</template>
						<template>
							<UserControls />
						</template>
					</Popover>
					<ChatNotifications />
					<SystemNotifications />
				</div>
			</div>
		</q-toolbar>

		<q-toolbar
			v-if="showPagesNavigation"
			class="pages"
		>
			<div class="pages-container">
				<nav class="pages-nav full-width">
					<q-scroll-area
						v-if="isSmallScreen"
						ref="pagesScrollArea"
						horizontal
						:visible="visible"
						class="pagesScrollContainer"
					>
						<Pages />
					</q-scroll-area>
					<div v-else>
						<Pages
							:gradientFallback="themeStylePagesFallback"
						/>
					</div>
				</nav>
				<div :class="['left pageScrollShadow', {show: shouldShowLeftShadow}]" />
				<div :class="['right pageScrollShadow', {show: shouldShowRightShadow}]" />
			</div>
		</q-toolbar>

		<q-drawer
			v-if="isSmallScreen"
			v-model="drawer"
			:width="300"
			elevated
			class="mobileMenu"
		>
			<q-scroll-area class="fit">
				<nav>
					<DrawerModuleAndPages />
				</nav>
			</q-scroll-area>
		</q-drawer>
	</div>
</template>

<script>
	import { debounce } from 'quasar';
	import throttle from 'lodash/throttle';
	import { cUserControlsMenuWidth, cAppThemes } from '@/configs/constants';
	import Modules from '@/components/layout/navigation/Modules';
	import Pages from '@/components/layout/navigation/Pages';
	import DrawerModuleAndPages from '@/components/layout/navigation/DrawerModuleAndPages';
	import Popover from '@/components/ui/Popover';
	import UserProfile from '@/components/layout/UserProfile';
	import UserControls from '@/components/ui/UserControls';
	import SystemNotifications from '@/components/ui/notificationsMenu/IconSystem';
	import ImageMixin from '@/components/mixins/ImageMixin';
	import ChatNotifications from '@/components/ui/notificationsMenu/IconChat';
	import { isGradient } from '@/utils/gradientTools';

	export default {
		components: {
			Modules,
			Pages,
			DrawerModuleAndPages,
			Popover,
			UserProfile,
			UserControls,
			SystemNotifications,
			ChatNotifications
		},
		mixins: [ImageMixin],
		data()
		{
			return {
				tab: '',
				drawer: false,
				name: this.$store.getters['app/settings/get']('name'),
				loginLink: process.env.API,
				visible: false,
				thumbSize: 500,
				cUserControlsMenuWidth,
				shouldShowLeftShadow: false,
				shouldShowRightShadow: false
			};
		},
		computed: {
			imageId()
			{
				return this.$store.getters['app/settings/get']('logoImage');
			},
			siteTitle()
			{
				return this.$store.getters['i18n/get']('siteTitle') || this.$t('navigation.topBar.logo.altText');
			},
			showPagesNavigation()
			{
				if(this.$store.state.route.meta && this.$store.state.route.meta.displayPages !== undefined)
				{
					return this.$store.state.route.meta.displayPages;
				}

				return !!(this.currentModule) && !this.currentModule.hideSubNav;
			},
			currentModule()
			{
				return this.$store.getters['structure/modules/getModuleBySlug'](this.$store.state.route.params.moduleSlug);
			},
			isSmallScreen()
			{
				return this.$q.screen.lt.md;
			},
			theme()
			{
				return this.$store.getters['user/getMetaSimple']?.settings?.theme || cAppThemes?.light;
			},
			themeStyleModulesFallback()
			{
				const style = (this.theme === cAppThemes?.light) ?
					this.$store.getters['app/settings/themeStylesByRules'](['header.color.background']) :
					this.$store.getters['app/settings/themeStylesByRules'](['theme.dark.header.color.background']);

				return isGradient(style[0].value);
			},
			themeStylePagesFallback()
			{
				const style = (this.theme === cAppThemes?.light) ?
					this.$store.getters['app/settings/themeStylesByRules'](['header.color.pages.backgroundColour']) :
					this.$store.getters['app/settings/themeStylesByRules'](['theme.dark.header.color.pages.backgroundColour']);

				return isGradient(style[0].value);
			},
			pages()
			{
				return this.$store.getters['structure/pages/getModulePages'](
					this.currentModule?.id
				)?.filter((page) => page && page.hideFromNav !== true);
			}
		},
		watch: {
			pages()
			{
				this.handleShadows(this.pagesScrollArea());
			}
		},
		updated()
		{
			this.emitOnUpdate();
		},
		created()
		{
			this.emitOnUpdate = debounce(this.emitOnUpdate, 500);
		},
		beforeDestroy()
		{
			this.destroyPageShadowsListener();
		},
		mounted()
		{
			this.initPageShadowsListener();
		},
		methods: {
			destroyPageShadowsListener()
			{
				if(!this.isSmallScreen || !this.pagesScrollArea())
				{
					return;
				}

				this.pagesScrollArea().removeEventListener('scroll', this.scrollShadowsOnMobile);
			},
			initPageShadowsListener()
			{
				if(!this.isSmallScreen || !this.pagesScrollArea())
				{
					return;
				}

				// determine whether there's a shadow to show
				// need to wait for a bit, otherwise el.scrollWidth === el.clientWidth
				// and no shadow is shown
				// nextTick doesn't do the trick.
				setTimeout(() => this.handleShadows(this.pagesScrollArea()), 500);

				this.pagesScrollArea().addEventListener('scroll', this.scrollShadowsOnMobile);
			},
			pagesScrollArea()
			{
				return this.$refs.pagesScrollArea?.$el?.children?.[0];
			},
			/**
			 * Allows showing a shadow on the left when users can scroll
			 * to the left, a shadow on the right when users can scroll
			 * the right.
			 * Could be done in CSS only but probably not when using
			 * quasar's `q-tabs` + `q-scroll-area`.
			 */
			handleShadows(element)
			{
				if(!element) return;

				const { clientWidth, scrollWidth, scrollLeft } = element;

				const wiggleRoom = 5;

				if((clientWidth + scrollLeft + wiggleRoom) < scrollWidth)
				{
					this.shouldShowRightShadow = true;
				}
				else
				{
					this.shouldShowRightShadow = false;
				}

				if(scrollLeft > wiggleRoom)
				{
					this.shouldShowLeftShadow = true;
				}
				else
				{
					this.shouldShowLeftShadow = false;
				}
			},
			scrollShadowsOnMobile: throttle(function run(event)
			{
				this.handleShadows(event.target);
			}, 100),
			emitOnUpdate()
			{
				// dispatches headerHeight update via App
				this.$emit('updated');
			}
		}
	};
</script>

<style lang="postcss" scoped>
	.modules-container,
	.pages-container {
		display: flex;
		width: 100%;
		margin: auto;
	}

	.logo {
		margin: var(--header-logo-margin);
		height: var(--header-logo-height);
		width: var(--header-logo-width);
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: var(--header-logo-imgSize-height);
			width: var(--header-logo-imgSize-width);
			max-width: var(--header-logo-imgSize-maxWidth);
			min-width: var(--header-logo-imgSize-minWidth);
		}
	}

	.modules-container >>> .q-tab {
		padding: 0 10px;
		min-height: 73px;
		text-transform: none;
		color: var(--header-color-tabs-link);
		font-family: var(--header-font-modules-fontFamily);
		font-weight: var(--header-font-modules-weight);

		&.q-tab--active {
			background: var(--header-color-tabs-active);
			color: var(--header-color-tabs-activeLink);
		}
	}

	.pages-container >>> .q-tab {
		padding: 0 10px;
		margin-top: 2px;
		text-transform: none;
		color: var(--header-color-pages-link);
		font-family: var(--header-font-pages-fontFamily);
		font-weight: var(--header-font-pages-weight);

		&.q-tab--active {
			transition: all 0.3s ease 0s;
			background: var(--header-color-pages-backgroundColourActive);
			color: var(--header-color-pages-activeLink);
		}

		&:hover,
		&:focus {
			background: rgba(255, 255, 255, 0.4); /* var(--header-color-pages-linkHover);*/
			transition: all 0.3s ease 0s;
		}
	}

	>>> .q-tab__content {
		min-width: auto;
	}

	>>> .q-tab__indicator {
		background: var(--header-color-tabs-modules-marker);
	}

	>>> a:hover,
	>>> a:focus {
		opacity: 1;
	}

	.pages {
		background: var(--header-color-pages-backgroundColour);

		.pagesScrollContainer {
			max-width: 100%;
			min-height: 50px;
			height: 100%;
		}

		>>> .q-tab__indicator {
			background: var(--header-color-tabs-pages-marker);
		}
	}

	.userTools {
		display: inline-flex;
		align-items: center;

		.q-btn {
			>>> i {
				color: var(--header-color-tabs-icons);
			}
		}
	}

	>>> .mobileMenu {
		.q-item__label {
			color: #444;
			font-weight: 600;
		}

		.q-expansion-item__container a {
			color: #53585d;
		}

		.q-expansion-item__content {
			padding-left: 0;
		}
	}

	.body--dark {
		.mobileMenu {
			>>> .q-item__label {
				color: #fff;
			}

			>>> .q-expansion-item__container a {
				color: #bfbfbf;
			}
		}
	}

	.modules-nav.fixed-width {
		padding-right: 20px;
		width: unset;
		min-width: 0;
		flex-shrink: 1;
		flex-grow: 1;
	}

	.mobile-header {
		width: 100%;
		display: flex;
		align-items: center;

		& .logo {
			max-width: 60%;
		}

		& .nav-button {
			flex: 1;
			position: absolute;
			right: 3px;

			>>> i {
				color: var(--header-color-tabs-icons);
			}
		}
	}

	@media (min-width: 1424px) {
		.modules-container,
		.pages-container {
			width: 80rem;
		}
	}

	@media (max-width: 1023px) {
		.mobile-header {
			& .logo {
				max-width: 25%;
				margin: 0;
			}
		}

		>>> .pages {
			padding-inline: 0;

			.pageScrollShadow {
				/**
				will cause the white `part` of the linear gradient
				to be black against a light background and white against
				a black background.
				*/
				mix-blend-mode: difference;
				height: 50px;
				position: absolute;
				pointer-events: none;
				opacity: 0;
				top: 0;
				width: 10px;
				z-index: 2;
				transition: 100ms;

				&.show {
					opacity: 0.5;
				}

				&.left {
					left: 0;
					background: linear-gradient(
						90deg,
						white 0%,
						transparent 100%
					);
				}

				&.right {
					right: 0;
					background: linear-gradient(
						90deg,
						transparent 0%,
						white 100%
					);
				}
			}

			.q-tabs {
				height: 100%;
			}

			.q-tab:hover,
			.q-tab:focus {
				background: transparent;
			}

			.q-tab__indicator {
				/* background: transparent; */
			}

			.q-tab__content {
				padding: 0;
			}

			.q-router-link--active {
				color: #fff;
			}

			.q-tab--inactive {
				opacity: 0.6;
			}
		}
	}

	@media (max-width: 600px) {
		.mobile-header {
			& .logo {
				max-width: 60%;
			}
		}
	}

	body.body--dark {
		.modules-container >>> .q-tab {
			color: var(--theme-dark-header-color-tabs-link);

			&.q-tab--active {
				background: var(--theme-dark-header-color-tabs-active);
			}
		}

		.modules-container >>> .q-tab__indicator {
			background: var(--theme-dark-header-color-tabs-modules-marker);
		}

		.pages {
			background: var(--theme-dark-header-color-pages-backgroundColour);

			>>> .q-tab__indicator {
				background: var(--theme-dark-header-color-tabs-pages-marker);
			}
		}

		.pages-container >>> .q-tab {
			color: var(--theme-dark-header-color-pages-link);

			&.q-tab--active {
				background: var(--theme-dark-header-color-pages-backgroundColourActive);
				color: var(--theme-dark-header-color-pages-activeLink);
			}
		}

		.userTools {
			.q-btn {
				>>> i {
					color: var(--theme-dark-header-color-tabs-icons);
				}
			}
		}
	}
</style>
