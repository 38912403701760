<template>
	<VEmojiPicker
		:class="pickerClasses"
		labelSearch="Search"
		:customEmojis="customEmojiList"
		:customCategories="customCategoryList"
		:continuousList="true"
		@select="$emit('select', $event)"
	/>
</template>

<script>
	import { VEmojiPicker } from 'v-emoji-picker';
	import { emojiList } from '@/configs/constants';

	export default {
		components: { VEmojiPicker },
		props: {
			customEmojis: {
				type: [Array, undefined],
				required: false,
				default: undefined
			},
			customCategories: {
				type: [Array, undefined],
				required: false,
				default: undefined
			},
			/**
			 * Whether to apply or not the `position: absolute` attribute on `VEmojiPicker`.
			 * Enabled by default.
			 */
			absolute: {
				type: Boolean,
				default: true,
				required: false
			}
		},
		computed: {
			pickerClasses()
			{
				return [
					this.absolute ? 'emojipicker' : '',
					this.$q.dark.isActive ? 'bg-dark' : ''
				];
			},
			customEmojiList()
			{
				// If we have a prop list go with it
				if(this.customEmojis) return this.customEmojis;

				const emojiListCustom = this.$store.getters['app/settings/get']('emojiList');

				// If we have a predefined emoji list then load that up
				if(emojiListCustom) return emojiListCustom;

				const includeEmojiList = this.$store.getters['app/settings/get']('includeEmojiListByAlias');

				// Only include the emojis that are in the list
				if(includeEmojiList)
				{
					return emojiList.filter((emoji) => emoji.aliases.some((alias) => includeEmojiList.includes(alias)));
				}

				const excludeEmojiList = this.$store.getters['app/settings/get']('excludeEmojiListByAlias');

				// Exclude the emojis that are in the list
				if(excludeEmojiList)
				{
					return emojiList.filter((emoji) => !emoji.aliases.some((alias) => excludeEmojiList.includes(alias)));
				}

				return undefined;
			},
			customCategoryList()
			{
				// If we have a prop list go with it
				if(this.customCategories) return this.customCategories;

				// Load the custom category or undefined
				return this.$store.getters['app/settings/get']('wysiwyg')?.categoryList;
			}
		}
	};
</script>

<style scoped>
	@font-face {
		font-family: NotomojiColor;
		src: local("Apple Color Emoji"),
			local("Segoe UI Emoji"),
			local("Segoe UI Symbol"),
			local("Noto Color Emoji"),
			url(https://cdn.glitch.com/61908de1-dd0a-4359-a54b-6cb6d41bb5fd%2FNotoColorEmoji.ttf?1513108808150)format("truetype");
	}

	.emojipicker {
		position: absolute;
	}
</style>
