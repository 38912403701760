import admin from '@/store/structure/admin';
import appModules from '@/store/structure/modules';
import pages from '@/store/structure/pages';
import blocks from '@/store/structure/blocks';
import fields from '@/store/structure/fields';
import { get } from '@/utils/api';
import { isAluminatiUser } from '@/plugins/Permissions';
import { cAdminPanelTypes } from '@/configs/adminPanelTypes';

const modules = {
	admin,
	modules: appModules,
	pages,
	blocks,
	fields
};

const state = {
	structure: {},
	currentRoute: {},
	loadingData: false
};

const mutations = {
	SET_STRUCTURE(state, structure)
	{
		state.structure = structure;
	},
	SET_CURRENT_ROUTE(state, payload)
	{
		state.currentRoute = payload;
	},
	SET_LOADING_DATA(state, isLoading)
	{
		state.loadingData = isLoading;
	}
};

const actions = {
	async load({ commit, dispatch })
	{
		const { data: structure } = await get('structure');

		commit('SET_STRUCTURE', structure);
		await dispatch('app/handleServerResponse', structure, { root: true });
		dispatch('app/structureLoaded', null, { root: true });

		return structure;
	},
	async loadSpecificModule({ dispatch }, { moduleSlug, opts = {} })
	{
		const { data: structure } = await get(`structure/${moduleSlug}`, { params: opts });

		await dispatch('app/handleServerResponse', structure, { root: true });
	},
	openDefinitionEditPanel({ dispatch }, data)
	{
		const readOnly = !isAluminatiUser();

		dispatch('admin/panel/new', {
			id: `def-${data.element}-${data.itemId}`,
			itemId: data.itemId,
			itemTypeId: data.itemTypeId,
			type: cAdminPanelTypes.definition,
			readOnly
		}, { root: true });
	},
	// Whether we're loading the pages data
	setLoadingData({ commit }, isLoading)
	{
		commit('SET_LOADING_DATA', isLoading);
	},
	// loadFields({ dispatch })
	// {
	// 	return get('structure/fields')
	// 		.then((res) => res.data)
	// 		.then((fields) =>
	// 		{
	// 			console.log(fields);
	// 		});
	// },
	async routerUpdate({ state, commit, dispatch, rootGetters }, payload)
	{
		commit('SET_CURRENT_ROUTE', payload); // Store the current path, so we can use it once the structure has loaded if we need to

		if(Object.keys(state.structure).length === 0) // If the structure doesn't actually exist (ie the app just loaded, but we're still waiting for API response)
		{
			return; // We don't do anything since there is no data to play with
		}

		if(!payload.moduleSlug || !payload.pageSlug) // This will mean the user just switched modules and there isn't a page just yet (it'll come in a split second)
		{
			return; // We need both slugs to get started
		}

		const module = rootGetters['structure/modules/getModuleBySlug'](payload.moduleSlug);
		const page = rootGetters['structure/pages/getPageBySlug'](payload.moduleSlug, payload.pageSlug);

		if(page && page.type) // A page type may not exist for all pages or the page itself hasn't been loaded yet (this function runs again once it has the data)
		{
			const defaultData = rootGetters['app/settings/get'](`structure.defaults.${page.type}`);
			const defaultPage = defaultData ? defaultData.moduleId === module.id && defaultData.pageId === page.id : null;

			await dispatch('pageTypeLoaded', { payload, pageType: page.type, defaultPage });
		}
	},
	async pageTypeLoaded({ dispatch }, { payload, pageType, defaultPage })
	{
		dispatch('setLoadingData', true);

		switch(pageType)
		{
			case 'profile':
				await dispatch('profiles/profileLoaded', parseInt(payload.targetId, 10), { root: true });
				break;
			case 'entity':
				// console.log('Loading entity', payload, pageType, defaultPage);
				await dispatch('entities/load', { id: payload.targetId }, { root: true });
				break;
			default:
				if(defaultPage)
				{
				// The default page was loaded for this pageType - This is just an example of the variable
				}

				break;
		}

		dispatch('setLoadingData', false);
	}
};

const getters = {
	getStructure: (state) => state.structure,
	getFriendlyName: (state, getters) => (type, id) =>
	{
		switch(type)
		{
			case 'module': {
				try
				{
					return getters['modules/getModuleById'](id).slug;
				}
				catch(e)
				{
					return id;
				}
			}
			case 'page': {
				try
				{
					const page = getters['pages/getPageById'](id);

					const module = getters['modules/getModuleById'](page.moduleId);
					const moduleName = getters.getFriendlyName('module', module.id);

					return `${moduleName} > ${page.slug}`;
				}
				catch(e)
				{
					return id;
				}
			}
			case 'block': {
			// TODO: This will not work unless the blocks have previously been downloaded (the user has visited the pages)
			// We need to think of a way around this.
				try
				{
					const block = getters['blocks/getBlock'](id);

					const page = getters['pages/getPageById'](block.pageId);
					const pageName = getters.getFriendlyName('page', page.id);

					const module = getters['modules/getModuleById'](page.moduleId);
					const moduleName = getters.getFriendlyName('module', module.id);

					return `${moduleName} > ${pageName} > ${block.type}`;
				}
				catch(e)
				{
					return id;
				}
			}
			default:
				return `${id} (${type})`;
		}
	},
	getDefaultPage: (state, getters, rootState, rootGetters) => (key) =>
	{
		const data = rootGetters['app/settings/get'](`structure.defaults.${key}`);

		if(!data)
		{
			return false;
		}

		const module = rootGetters['structure/modules/getModuleById'](data.moduleId);
		const page = rootGetters['structure/pages/getPageById'](data.pageId);

		if(!module || !page) // If module or page does not exist (either deleted or user cannot see it)
		{
			return false;
		}

		return {
			moduleSlug: module.slug,
			pageSlug: page.slug
		};
	},
	getDefaultPagePath: (state, getters) => (key) =>
	{
		const { moduleSlug, pageSlug } = getters.getDefaultPage(key);

		return `/${moduleSlug}/${pageSlug}`;
	},
	getCurrentRoute: (state) => state.currentRoute,
	loadingData: (state) => state.loadingData,
	pluralName: () => (name) =>
	{
		switch(true)
		{
			case /modules?/.test(name):
				return 'modules';
			case /pages?/.test(name):
				return 'pages';
			case /blocks?/.test(name):
				return 'blocks';
			case /fields?/.test(name):
				return 'fields';
			case /dataSchemas?/.test(name):
				return 'dataSchemas';
			case /powerUps?/.test(name):
				return 'powerUp';
			case /applications?/.test(name):
				return 'applications';
			case /entityDefinitions?/.test(name):
				return 'entityDefinitions';
			case /entit(y|ies)/.test(name):
				return 'entities';
			default:
				return null;
		}
	}
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
