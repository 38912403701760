<script>
	import I18N from '@/components/I18N';

	export default {
		extends: I18N,
		props: {
			editable: {
				type: Boolean,
				default: false
			}
		}
	};
</script>
