<template>
	<Notifications
		:unseenCount="unseenCount"
		:notifications="notifications"
		:icon="icon"
		class="q-mr-sm"
		label="navigation.notifications.messages"
		labelLink="/chat/"
		showUserSearch
		@open="open"
	/>
</template>

<script>
	import IconMixin from '@/components/ui/notificationsMenu/IconMixin';

	export default {
		mixins: [IconMixin],
		props: {
			icon: {
				type: String,
				default: 'fas fa-envelope'
			}
		},
		data()
		{
			return {
				type: 'chat'
			};
		}
	};
</script>
