<template>
	<div
		v-if="active"
		v-show="!isSmallScreen"
		class="container recentViewedFooter"
	>
		<div class="controls">
			<div class="closeButton" @click="toggle()">
				<q-icon v-if="open" name="fal fa-angle-double-down" />
				<q-icon v-else name="fal fa-angle-double-up" />
			</div>
		</div>

		<!-- <transition name="slide-down"> -->
		<div
			v-if="open"
			class="recentlyViewedWrapper"
		>
			<div class="recentlyViewedTitle">
				<q-icon
					name="far fa-clock"
					class="q-mr-sm"
				/>
				<I18N id="footer.recentNavigation.recentlyViewed" />
			</div>
			<div
				v-for="(item, i) in items"
				:key="i"
				class="recentlyViewedBlock"
			>
				<component
					:is="action"
					:i="i"
					:item="item"
					:profileLink="profileLink"
				/>
			</div>
		</div>
		<!-- </transition> -->
	</div>
</template>

<script>
	import BlockMixin from '@/components/blocks/BlockMixin';

	export default {
		components: {
			recentlyViewed: () => import('@/components/blocks/types/RecentFooterViewed')
		},
		mixins: [BlockMixin],
		props: {
			data: {
				type: Object,
				required: true
			}
		},
		data()
		{
			return {
				action: this.data.default,
				open: true
			};
		},
		computed:
			{
				active()
				{
					const adminDashModeActive = this.$store.getters['admin/dash/isActive'];
					const adminPanelModeActive = this.$store.getters['admin/panel/activeStack'];
					const draggingBlocksModeActive = this.$store.getters['structure/blocks/admin/isDraggingModeActive'];

					if(adminDashModeActive || adminPanelModeActive || draggingBlocksModeActive)
					{
						return false;
					}

					const profile = this.$store.getters['user/getProfile'];

					return profile && profile.actions && profile.actions[this.action] && profile.actions[this.action].length !== 0;
				},
				items()
				{
					return this.$store.getters['user/getProfile'].actions[this.action];
				},
				isSmallScreen()
				{
					return this.$q.screen.lt.md;
				}
			},
		created()
		{
			this.$store.dispatch('profiles/getUserLogAction', this.action);
			this.getLocalStorageState();
		},
		methods: {
			toggle()
			{
				this.open = !this.open;
				window.localStorage.setItem('footer-bar-isOpen', this.open);
			},
			getLocalStorageState()
			{
				const value = JSON.parse(window.localStorage.getItem('footer-bar-isOpen'));

				if(typeof value === 'undefined' || value === null)
				{
					this.open = true;
				}
				else
				{
					this.open = value;
				}
			}
		}
	};
</script>

<style scoped>
	.recentViewedFooter {
		z-index: 9999;
	}

	.container {
		width: 100%;
		position: fixed !important;
		bottom: 0;
		height: auto;
		left: 0;
		margin: 0;

		.controls {
			display: flex;
			justify-content: flex-end;

			.closeButton {
				background-color: #fff;
				border-radius: 5px 5px 0 0;
				padding: 3px 10px;
				display: inline-block;
			}
		}
	}

	.recentlyViewedWrapper {
		padding: 20px 10px;
		overflow-x: scroll;
		box-shadow: 0 -1px 25px rgba(0, 0, 0, 0.2);
		background: #fff;
		white-space: nowrap;
	}

	.recentlyViewedTitle {
		padding: 20px 20px 20px 0;
		border-right: 1px solid #ccc;
		margin-right: 30px;
		text-align: center;
		color: #868686;
		width: 200px;
		display: inline-block;
		vertical-align: top;
	}

	.recentlyViewedTitle svg {
		text-align: center;
		display: block;
		width: 100%;
		font-size: 1.6em;
		color: #a6a6a6;
		margin-bottom: 6px;
	}

	.recentlyViewedBlock {
		margin-right: 20px;
		border-right: 1px solid #ccc;
		display: inline-block;
		vertical-align: top;
		white-space: normal;
		width: 350px;
	}

	.recentlyViewedBlock:last-child {
		border-right: none;
	}

	.recentlyViewedBlock h2 a {
		text-decoration: none;
		color: #333;
	}

	.recentlyViewedBlock h2 a:hover,
	.recentlyViewedBlock h2 a:focus {
		color: #0099f8;
	}

	.recentlyViewedBlock p {
		position: relative;
		line-height: 1.2em;
		max-height: 6em;
		margin-right: -1em;
		padding-right: 1em;
		margin-top: 0;
		width: 57%;
	}

	.recentlyViewedBlock p::before {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.recentlyViewedBlock p::after {
		content: "";
		position: absolute;
		right: 0;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		background: white;
	}

	.slide-down-enter-active,
	.slide-down-leave-active {
		transition: all 0.3s ease;
	}

	.slide-down-enter,
	.slide-down-leave-to {
		transform: translateY(100px);
	}
</style>
