// @ts-check

import qs from 'qs';

/**
 * Give it an object and it creates a URL query string.
 *
 * E.g. give it:
 * ```js
 * {
 * 		foo: 'bar',
 * 		foo2: 'bar2',
 * 		baz: undefined
 * }
 * ```
 * and it will return `?foo=bar&foo2=bar2`
 * (notice that baz is ignored as it was undefined).
 *
 * It does include the `?` at the start of the string.
 * @param {Record<string, unknown>} params
 */
export function queryString(/** @type {Record<string, unknown>} */ params)
{
	const paramsToUse = /** @type {Record<string, unknown>} */ ({});

	Object.entries(params).forEach(([key, value]) =>
	{
		if(!value) return;

		paramsToUse[key] = value;
	});

	if(!Object.keys(paramsToUse).length)
	{
		return '';
	}

	let url = '';

	if(Object.keys(params).length > 0)
	{
		const queryParams = qs.stringify(params);

		url += `?${queryParams}`;
	}

	return url;
}
