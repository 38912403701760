// @ts-check
import { Notify } from 'quasar';

/** @typedef {import('@/types/quasar').NotifyOptsObject} NotifyOptsObject */
/** @typedef {import('@/types/quasar').NotifyAction} NotifyAction */
/** @typedef {import('@/types/quasar').NotifyOpts} NotifyOpts */
/** @typedef {import('vue-i18n').TranslateResult} TranslateResult */

/** @type {import('@/types/utils/notifications').addNotificationWithStates} */
export function addNotificationWithStates(firstState, states, defaults)
{
	const notification = Notify.create(
		/** @type {NotifyOpts} */({
			...defaults,
			...states[firstState]
		})
	);
	let currentState = firstState;

	return {
		get currentState()
		{
			return currentState;
		},
		setState: (state, data) =>
		{
			currentState = state;
			notification({ ...states[state], ...data });
		},
		update: (data) =>
		{
			notification(data);
		},
		dismiss: () =>
		{
			notification();
		}
	};
}

/**
 * @param {(string | NotifyOptsObject)} payload
 * @param {Array<NotifyAction>} [actions]
 * @param {NotifyOptsObject} [defaults]
 */
export function addNotification(payload, actions = [], defaults = {})
{
	// Global defaults
	defaults.position = (
		typeof payload === 'object' && payload?.position
	) || 'bottom-right';

	if(typeof payload === 'string')
	{
		return Notify.create({ ...defaults, actions, message: payload });
	}

	return Notify.create(
		/** @type {NotifyOpts} */({ ...defaults, actions, ...payload })
	);
}

/**
 * @param {(string | NotifyOptsObject)} payload
 * @param {Array<NotifyAction>} [actions]
 */
export function addNormal(payload, actions = [])
{
	addNotification(payload, actions);
}

/**
 * @param {(string | NotifyOptsObject)} payload
 * @param {Array<NotifyAction>} [actions]
 */
export function addSuccess(payload, actions = [])
{
	addNotification(
		payload,
		actions,
		{
			color: 'positive'
		}
	);
}

/**
 * @param {(string | NotifyOptsObject)} payload
 * @param {Array<NotifyAction>} [actions]
 */
export function addWarn(payload, actions = [])
{
	addNotification(
		payload,
		actions,
		{
			color: 'warning'
		}
	);
}

/**
 * @param {(string | NotifyOptsObject)} payload
 * @param {Array<NotifyAction>} [actions]
 */
export function addError(payload, actions = [])
{
	// To avoid having empty string notifications
	if(typeof payload === 'string' && payload.length === 0)
	{
		return;
	}

	addNotification(
		payload,
		actions,
		{
			color: 'negative'
		}
	);
}

/**
 * @param {(string | NotifyOptsObject)} payload
 * @param {Array<NotifyAction>} [actions]
 */
export function addInfo(payload, actions = [])
{
	addNotification(
		payload,
		actions,
		{
			color: 'info'
		}
	);
}

/**
 * @typedef {Object} AddServerResponseNotificationOpts
 * @property {(TranslateResult | boolean | Error)} response - Request response.
 * Either boolean (success/failure), string (error message), or `Error`.
 * @property {TranslateResult} [onSuccessMessage] - If falsy, toast is not displayed.
 * @property {Function} [onSuccessCallback] - Callback if the response is a success.
 * @property {Function} [onErrorCallback] - Callback if the response is a failure.
 * @property {TranslateResult} [onErrorMessage] - Error message override used for the toast.
 * The failure response is still logged to console.
 */

/**
 * @param {AddServerResponseNotificationOpts['response']} response - Request response.
 * Either boolean (success/failure), string (error message), or `Error`.
 * @param {AddServerResponseNotificationOpts['onSuccessMessage']} [onSuccessMessage] - If falsy, toast is not displayed.
 * @param {AddServerResponseNotificationOpts['onSuccessCallback']} [onSuccessCallback] - Callback if the response is a success.
 * @param {AddServerResponseNotificationOpts['onErrorCallback']} [onErrorCallback] - Callback if the response is a failure.
 * @param {AddServerResponseNotificationOpts['onErrorMessage']} [onErrorMessage] - Error message override used for the toast.
 * The failure response is still logged to console.
 *
 * TODO: A config object is starting to make sense for the callbacks, messages, etc.
 */
export function addServerResponseNotification(
	response,
	onSuccessMessage,
	onSuccessCallback,
	onErrorCallback,
	onErrorMessage
)
{
	switch(typeof response)
	{
		case 'boolean':
			if(response)
			{
				if(onSuccessMessage) addSuccess(onSuccessMessage);

				if(typeof onSuccessCallback === 'function') onSuccessCallback();
			}
			else
			{
				console.error('Server error');
				// TODO: i18n
				addError(onErrorMessage || 'Server error');

				if(typeof onErrorCallback === 'function') onErrorCallback(response);
			}

			break;
		case 'string':
			console.error(response);
			addError(onErrorMessage || response);

			if(typeof onErrorCallback === 'function') onErrorCallback(response);

			break;
		case 'object':
			if(response instanceof Error)
			{
				console.error(response);
				addError(onErrorMessage || response.message);

				if(typeof onErrorCallback === 'function') onErrorCallback(response);
			}
			// TODO: Could check for the standard `e.response?.data?.message`.
			else
			{
				// May occur if a `TranslateResult` is ever an object.
				console.error('Unknown object response', response);
			}

			break;
		default:
			console.error('Unknown response type', typeof response, response);
			break;
	}
}

/**
 * @param {AddServerResponseNotificationOpts} opts
 */
export function addServerResponseNotificationObj(opts)
{
	addServerResponseNotification(
		opts.response,
		opts.onSuccessMessage,
		opts.onSuccessCallback,
		opts.onErrorCallback,
		opts.onErrorMessage
	);
}
