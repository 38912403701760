// @ts-check
import { powerUps } from '../../../configs/constants';
import matches from './matches';
import reservations from './reservations';

const modules = {
	matches,
	reservations
};

const state = {};

const mutations = {};

const actions = {};

const getters = {
	/**
	 * @type {(
	 * 	(state: Object, getters: Object, rooState, rootGetters) => (
	 * 		entityId: string,
	 * 		connectionTypeId: string
	 * 	) => Object
	 * )}
	 */
	getOneById: (state, getters, rootState, rootGetters) => (entityId, connectionTypeId) =>
	{
		return rootGetters['entities/getPowerUpData'](
			entityId, powerUps.connections
		)?.connectionTypes.find(({ id }) => connectionTypeId === id);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
	modules
};
