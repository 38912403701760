import { userCan, isPermissionVisible } from '@/plugins/Permissions';

export default {
	inject: {
		$blockDataScope: {
			default: null,
			from: 'blockDataScope'
		}
	},
	computed: {
		_permissionBlockId()
		{
			return this.id || this.block?.id || this.data?.id || this.blockId || this.$blockPermissionId;
		},
		_permissionBlock()
		{
			return this.$store.getters['structure/blocks/getBlock'](this._permissionBlockId);
		},
		_permissionPageId()
		{
			return this.$store.getters['app/getActivePageId'];
		},
		permissionSubject()
		{
			if(this._permissionBlock)
			{
				return `items.blocks.${this._permissionBlock.type}`;
			}

			return null;
		},
		_blockScope()
		{
			return this.blockDataScope || this.$blockDataScope;
		}
	},
	methods: {
		_can({ action, subject, subjectId = undefined, scope = undefined, field = undefined, conditions = {} })
		{
			return userCan(action, subject, subjectId, scope, field, conditions);
		},
		can(action, blockId = this._permissionBlockId, conditions = {}, field = null)
		{
			if(!this.permissionSubject) return false;

			return this._can({ action, subject: this.permissionSubject, subjectId: blockId, conditions, field });
		},
		canAnyOfInScope(checks, scope = this._blockScope, blockId = this._permissionBlockId, conditions = {}, field = null)
		{
			return this.permissionSubject && checks.some((action) => this._can({ action, subject: this.permissionSubject, subjectId: blockId, scope, conditions, field }));
		},
		canInScope(action, scope = this._blockScope, blockId = this._permissionBlockId, conditions = {}, field = null)
		{
			if(!this.permissionSubject) return false;

			return this._can({ action, subject: this.permissionSubject, subjectId: blockId, scope, conditions, field });
		},
		canForCurrentPage(action, scope = undefined, conditions = {}, field = undefined)
		{
			return this._can({ action, subject: 'items.pages', subjectId: this._permissionPageId, scope, conditions, field });
		},
		showBlockShield()
		{
			if(!this.permissionSubject) return false;

			return isPermissionVisible(this.permissionSubject);
		}
	}
};
