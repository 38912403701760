<template>
	<div>
		<transition name="fade">
			<div
				v-if="capturedBlocks.length && isAdmin"
				:class="[{ floating: !minimised }, 'selectionBox', 'q-pa-md', 'shadow-10']"
			>
				<div class="row">
					<div style="flex: 1 1 auto">
						<SimpleButton
							flat
							round
							size="sm"
							:aria-label="minimised ? $t('capturedBlocks.list.restore') : $t('capturedBlocks.list.minimise')"
							:icon="minimised ? 'fal fa-chevron-up' : 'fal fa-chevron-down'"
							@click="minimised = !minimised"
						/>
						<q-badge
							rounded
							color="primary"
							class="q-ml-sm"
							:label="minimised ? numberOfCapturedBlocks : numberOfCapturedBlocksLong"
						/>
					</div>
					<div>
						<SimpleButton
							v-if="minimised"
							flat
							round
							color="negative"
							:aria-label="$t('capturedBlocks.list.empty')"
							size="sm"
							icon="fal fa-trash-alt"
							@click="emptySelection"
						/>
						<SimpleButton
							v-else
							flat
							rounded
							color="negative"
							size="sm"
							iconLeft="fal fa-trash-alt"
							@click="emptySelection"
						>
							<I18N id="capturedBlocks.list.empty" />
						</SimpleButton>
					</div>
				</div>
				<q-list
					v-if="!minimised"
					style="max-height: 450px; overflow-y: auto"
				>
					<q-item
						v-for="block in prettyCapturedBlocks"
						:key="block.target.id"
						class="captureItem"
					>
						<q-item-section>
							<!-- more stable hover target -->
							<div
								style="bottom: 0; left: 0; position: absolute; right: 0; top: 0;"
								@mouseover="hoverBlock(block.target.id)"
								@mouseout="unhoverBlock(block.target.id)"
							/>
							<q-item-label class="ellipsis">
								{{ getLabel(block.target) }}
							</q-item-label>
							<q-item-label caption>
								<template
									v-if="block.from.id !== block.sourcePage.id"
								>
									<I18N
										id="capturedBlocks.list.parent"
									>
										<template #parent>
											{{ getLabel(block.from) }}
										</template>
									</I18N>
									<br>
								</template>
								<I18N id="capturedBlocks.list.page">
									<template #page>
										{{ getLabel(block.sourcePage) }}
									</template>
								</I18N>
							</q-item-label>
						</q-item-section>
						<q-item-section side>
							<SimpleButton
								flat
								round
								size="sm"
								:aria-label="$t('capturedBlocks.list.removeOne')"
								icon="fal fa-times"
								color="negative"
								@click="removeOne(block.target.id)"
								@mouseover="hoverBlock(block.target.id)"
							/>
						</q-item-section>
					</q-item>
				</q-list>
			</div>
		</transition>
	</div>
</template>

<script>
	import EventBus from '@/components/admin/generic/EventBus';
	import BlockMoveMixin from '@/components/mixins/BlockMoveMixin';

	export default {
		mixins: [BlockMoveMixin],
		data()
		{
			return {
				minimised: false
			};
		},
		computed: {
			isAdmin: {
				get()
				{
					return this.$store.getters['admin/isEditMode'];
				}
			}
		},
		methods: {
			emptySelection()
			{
				const targets = this.capturedBlocks.map((captured) => captured.target.blockId);

				this.$store.dispatch('structure/blocks/admin/emptyCaptured');

				// trigger this after selection box transition has finished
				setTimeout(() =>
				{
					targets.forEach((target) =>
					{
						this.unhoverBlock(target);
					});
				}, 500);
			},
			removeOne(targetId)
			{
				this.$store.dispatch('structure/blocks/admin/removeCaptured', [targetId]);

				// trigger this after selection box transition has finished
				setTimeout(() =>
				{
					this.unhoverBlock(targetId);
				}, 500);
			},
			hoverBlock(id)
			{
				EventBus.$emit(`captured:blocks:${id}:hover`);
			},
			unhoverBlock(id)
			{
				EventBus.$emit(`captured:blocks:${id}:unhover`);
			}
		}
	};
</script>

<style scoped>
	.selectionBox {
		background-color: #fff;
		border-radius: 8px 8px 0 0;
		bottom: 0;
		max-width: 160px;
		position: fixed;
		right: 50px;
		width: 160px;
		z-index: 2021;
	}

	.captureItem:hover,
	.captureItem:focus {
		background-color: #eee;
	}

	.floating {
		border-radius: 8px;
		bottom: 50px;
		max-width: 400px;
		width: 400px;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
