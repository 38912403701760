import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { subject } from '@casl/ability';
import { pick } from 'dot-object';
import { get, post, put, destroy, instance, apiInstance, deleteAllSWRData } from '@/utils/api';
import { setOneItem } from '@/utils/store';
import { addError, addSuccess } from '@/utils/notifications';
import notifications from '@/store/user/notifications';
import ability from '@/utils/ability';
import { getKeyForAlias } from '@/plugins/keyAlias';
import { KeyAliasKeys } from '@/configs/constants/schemas';

const modules = {
	notifications
};

const state = {
	accountId: null,
	loggedIn: false,
	jwt: null,
	isAdmin: false,
	isElevated: false,
	notificationsEnabled: true,
	userDeviceTokens: [],
	blockStatistics: {},
	listMemberships: [],
	dataPrivacy: [],
	chatSidebarCollapse: false,
	loggedOut: false,
	permissions: {},
	basePaths: [],
	frameworkData: {},
	serviceId: null,
	imageAccessToken: null
};

const mutations = {
	SET_USER(state, { accountId, isAdmin, isElevated, hasNotifications, deviceTokens, _imageAccessToken })
	{
		// state.data = data;
		state.accountId = accountId;
		state.isAdmin = isAdmin;
		state.isElevated = isElevated;
		state.notificationsEnabled = hasNotifications;
		state.userDeviceTokens = deviceTokens;
		// state.imageAccessToken = _imageAccessToken;
	},
	SET_IS_ADMIN(state, isAdmin)
	{
		state.isAdmin = !!isAdmin;
	},
	USER_LOGGED_IN(state, jwt)
	{
		state.loggedIn = true;
		state.jwt = jwt;
	},
	USER_LOGGED_OUT(state)
	{
		state.loggedOut = true;
		state.loggedIn = false;
		state.jwt = null;
	},
	SET_USER_NOTIFICATIONS(state, notificationsState)
	{
		state.notificationsEnabled = notificationsState;
	},
	SAVE_USER_DEVICE_TOKEN(state, payload)
	{
		setOneItem(state, 'userDeviceTokens', payload);
	},
	DISABLE_USER_DEVICE_TOKENS(state)
	{
		Vue.set(state, 'userDeviceTokens', []);
	},
	SET_BLOCK_STATISTICS(state, { statTargetId, stat, data })
	{
		if(!state.blockStatistics[statTargetId])
		{
			state.blockStatistics[statTargetId] = {};
		}

		state.blockStatistics[statTargetId][stat] = data;
	},
	SET_LIST_MEMBERSHIPS(state, memberships)
	{
		Vue.set(state, 'listMemberships', memberships);
	},
	UPDATE_LIST_MEMBERSHIP_STATE(state, { membershipId, updatedState })
	{
		const { listMemberships } = state;
		const index = listMemberships.findIndex((m) => m.id === membershipId);

		if(index > -1)
		{
			const membership = state.listMemberships[index];

			membership.state = updatedState;
			Vue.set(state.listMemberships, index, membership);
		}
	},
	UPSERT_LIST_MEMBERSHIP(state, membership)
	{
		const { listMemberships } = state;

		const index = listMemberships.findIndex((m) => m.id === membership.id);

		if(index > -1)
		{
			Vue.set(state.listMemberships, index, membership);
		}
		else
		{
			Vue.set(state.listMemberships, state.listMemberships.length, membership);
		}
	},
	REMOVE_LIST_MEMBERSHIP(state, membershipId)
	{
		const { listMemberships } = state;
		const index = listMemberships.findIndex((m) => m.id === membershipId);

		Vue.delete(state.listMemberships, index);
	},
	REMOVE_LIST_MEMBERSHIP_BY_LIST_ID(state, userListId)
	{
		const { listMemberships } = state;
		const index = listMemberships.findIndex((m) => m.userListId === userListId);

		Vue.delete(state.listMemberships, index);
	},
	UPSERT_DATA_PRIVACY(state, privacySettings)
	{
		const currentAsObject = Object.values(state.dataPrivacy).reduce((agg, setting) =>
		{
			agg[setting.dataSchemaId] = setting;

			return agg;
		}, {});

		const newAsObject = Object.values(privacySettings).reduce((agg, setting) =>
		{
			agg[setting.dataSchemaId] = setting;

			return agg;
		}, {});

		const merged = Object.values(Object.assign({}, currentAsObject, newAsObject));

		Vue.set(state, 'dataPrivacy', merged);
	},
	SET_CHAT_SIDEBAR(state, value)
	{
		state.chatSidebarCollapse = value;
	},
	SET_PERMISSIONS(state, permissions)
	{
		Vue.set(state, 'permissions', permissions);
	},
	SET_BASE_PATHS(state, paths)
	{
		Vue.set(state, 'basePaths', paths);
	},
	SET_FRAMEWORK_DATA(state, { key, data })
	{
		Vue.set(state.frameworkData, key, data);
	},
	SET_SERVICE_ID(state, id)
	{
		state.serviceId = id;
	},
	SET_IMAGE_ACCESS_TOKEN(state, token)
	{
		state.imageAccessToken = token;
	}
};

const actions = {
	load({ commit, dispatch })
	{
		return get('user')
			.then((res) =>
			{
				return res.data;
			})
			.then((profile) =>
			{
				commit('SET_USER', profile);
				dispatch('profiles/insertNewEntry', profile, { root: true });
				dispatch('app/userLoaded', {}, { root: true });
				commit('admin/SET_VIEW_AS', profile.viewAs, { root: true });
			})
			.catch((e) =>
			{
				console.error(e);
			});
	},
	setUser({ commit, dispatch }, data)
	{
		const jwt = localStorage.getItem('jwt');

		try
		{
			if(jwt)
			{
				const [, data] = jwt.split('.');

				const jwtData = JSON.parse(atob(data));

				commit('SET_SERVICE_ID', jwtData?.data?.serviceId);
			}
		}
		catch(e)
		{
			console.error(e);
		}

		commit('SET_USER', data);
	},
	setIsAdmin({ commit }, isAdmin)
	{
		commit('SET_IS_ADMIN', isAdmin);
	},
	async setNotifications({ commit }, notificationState)
	{
		await post('user/setNotifications', { enabled: notificationState });
		commit('SET_USER_NOTIFICATIONS', notificationState);
	},
	// ADD BACK WHEN PUSH COMPLETED
	// async saveDeviceToken({ commit }, deviceToken)
	// {
	// 	await post(`pushNotifications/${deviceToken}`);
	// 	commit('SAVE_USER_DEVICE_TOKEN', deviceToken);
	// },
	// async disablePushNotifications({ commit })
	// {
	// 	await post('pushNotifications/disable');
	// 	commit('DISABLE_USER_DEVICE_TOKENS');
	// },
	login({ commit }, jwt)
	{
		localStorage.setItem('jwt', jwt);
		commit('USER_LOGGED_IN', jwt);
	},
	async logout({ commit, getters, dispatch })
	{
		// If there is no token, the user it not 'logged in' and a different process (login.vue) will handle sending the user somewhere
		if(!getters.jwt) return;

		try
		{
			// Delete SWR data when a user logs out
			await deleteAllSWRData();
			await dispatch('audit/save'); // Save the audit log before logging out.

			const { data } = await apiInstance.get('service/frameworkUrl/93'); // TODO this needs to be the actual users serviceId

			await instance.get('logout', {
				headers: {
					Authorization: `Bearer ${getters.jwt}`
				},
				params: {
					deviceId: localStorage.getItem('deviceId')
				}
			});

			localStorage.removeItem('jwt');
			commit('USER_LOGGED_OUT');
			// window.location.href = `${response.data.url}logout.php`;
			window.location.assign(`${data.url}logout.php`);
		}
		catch(e)
		{
			await deleteAllSWRData();
			await instance.get('logout');
			localStorage.removeItem('jwt');
			commit('USER_LOGGED_OUT');
			window.location.assign(window.location.origin);
		}
	},
	async gatherBlockStatistics({ commit }, { statTargetId, stat })
	{
		const response = await get(`/user/${statTargetId}/blockStatistics/${stat}`);
		const { data } = response;

		commit('SET_BLOCK_STATISTICS', { statTargetId, stat, data });
	},
	async removeSocialAccountLink({ dispatch, rootGetters }, { externalId, label })
	{
		const response = await destroy(`/user/socialAccountLink/${externalId}`);
		const { success } = response.data;

		if(success)
		{
			dispatch('load');

			dispatch('app/setSocialSignInStatus', {
				statusType: 'unlinked',
				statusProvider: label,
				statusMessage: rootGetters['i18n/get']('socialSignIn.routes.status.unlinked')
			}, { root: true });

			addSuccess(rootGetters['i18n/dynamicValue']('socialSignIn.routes.status.unlinked', { provider: label }));

			return true;
		}

		addError(rootGetters['i18n/dynamicValue']('socialSignIn.routes.status.errorUnlinking', { provider: label }));

		return false;
	},
	async addSocialAccountLink({ dispatch }, { provider, dataString })
	{
		const response = await get(`/user/socialAccountLink/${provider}/new/${dataString}`);
		const { newLink } = response.data;

		if(newLink === 'created') dispatch('load');

		return newLink;
	},
	async gatherMultiBlockStatistics({ commit }, { statTargetId, stats })
	{
		const response = await post(`/user/${statTargetId}/multiBlockStatistics`, { stats });
		const { data } = response;

		data.forEach((statData) =>
		{
			const stat = statData.targetStat;
			const statisticData = statData.stat;

			commit('SET_BLOCK_STATISTICS', { statTargetId, stat, data: statisticData });
		});
	},
	async getListMemberships({ commit, getters })
	{
		if(!getters.listMembershipsLoaded) // either this or it's going to be loaded each time a form is displayed
		{
			const { data } = await get('/user/listMemberships');

			commit('SET_LIST_MEMBERSHIPS', data);
		}
	},
	upsertListMembership({ commit, dispatch, getters }, membership)
	{
		if(!membership.id) throw new Error('Missing list membership ID');

		commit('UPSERT_LIST_MEMBERSHIP', membership);

		if(membership.state === 'accepted')
		{
			const { accountId } = getters;

			dispatch('profiles/addToAcceptedList', { accountId, listId: membership.userListId }, { root: true });
		}
	},
	updateUserListState({ commit }, { membershipId, updatedState })
	{
		commit('UPDATE_LIST_MEMBERSHIP_STATE', { membershipId, updatedState });
	},
	removeListMembership({ commit }, membershipId)
	{
		if(!membershipId) throw new Error('Missing list membership ID');

		commit('REMOVE_LIST_MEMBERSHIP', membershipId);
	},
	removeListMembershipByUserListId({ commit, dispatch, getters }, userListId)
	{
		if(!userListId) throw new Error('Missing list ID');

		const { accountId } = getters;

		commit('REMOVE_LIST_MEMBERSHIP_BY_LIST_ID', userListId);
		dispatch('profiles/removeFromAcceptedList', { accountId, listId: userListId }, { root: true });
	},
	upsertDataPrivacy({ commit }, privacySettings)
	{
		commit('UPSERT_DATA_PRIVACY', privacySettings);
	},
	setChatSidebar({ commit }, { collapsed })
	{
		// this is intended to be a front-end only setting, applied to this session only
		commit('SET_CHAT_SIDEBAR', collapsed);
	},
	async addFavourite({ dispatch, getters }, { type, value })
	{
		const { accountId } = getters;
		const currentFavourites = cloneDeep(getters.favouritesByType(type)) || [];

		currentFavourites.push(value);

		dispatch('profiles/updateValue', { accountId, data: { key: `meta.favourites.${type}`, value: currentFavourites } }, { root: true });
		const { data: { profile } } = await put('/profile/favourites/add', { type, value });

		dispatch('profiles/updateProfileData', profile, { root: true });
	},
	// Remove favourite locally
	clearFavourite({ dispatch, getters }, { type, targetId })
	{
		const { accountId } = getters;
		let currentFavourites = cloneDeep(getters.favouritesByType(type) || []);

		currentFavourites = currentFavourites.filter((fav) => fav.targetId !== targetId);

		// Update profile data locally
		dispatch(
			'profiles/updateValue',
			{
				accountId,
				data: { key: `meta.favourites.${type}`, value: currentFavourites }
			},
			{
				root: true
			}
		);
	},
	async removeFavourite({ dispatch }, { type, targetId })
	{
		// Remove favourite locally
		dispatch('clearFavourite', { type, targetId });

		// Remove favourite from DB
		const { data: { profile } } = await destroy(
			'/profile/favourites',
			{ params: { type, targetId } }
		);

		dispatch('profiles/updateProfileData', profile, { root: true });
	},
	setPostReactions({ dispatch, getters }, { postId, reactions })
	{
		const { accountId, allReactions } = getters;

		if(allReactions)
		{
			allReactions[`${postId}`] = reactions;
		}

		dispatch('profiles/updateValue', { accountId, data: { key: 'meta.reactedPosts', value: allReactions || { [`${postId}`]: reactions } } }, { root: true });
	},
	async getPermissions({ dispatch })
	{
		const { data } = await get('/user/permissions');

		dispatch('setPermissions', data.permissions);
		dispatch('setBasePaths', data.basePaths);
	},
	setPermissions({ commit }, permissions)
	{
		commit('SET_PERMISSIONS', permissions);
	},
	setBasePaths({ commit }, basePaths)
	{
		commit('SET_BASE_PATHS', basePaths);
	},
	async setLastExportTemplate({ dispatch, getters }, { exportId, template })
	{
		dispatch('profiles/saveProfileMetaData', { path: `selectedExportTemplate.${exportId}`, data: template }, { root: true });
	},
	async setAdminTableOptions({ dispatch, getters }, { options })
	{
		const currentOpts = cloneDeep(getters.metaValue('adminDashboardTableOptions'));
		const optsToSave = currentOpts ?
			{
				...currentOpts,
				...options
			} :
			options;

		dispatch('profiles/saveProfileMetaData', {
			path: 'adminDashboardTableOptions',
			data: optsToSave
		}, { root: true });
	},
	async loadFrameworkData({ state, commit }, key)
	{
		if(!state.frameworkData[key])
		{
			const { data } = await get(`/user/frameworkData/${key}`);

			commit('SET_FRAMEWORK_DATA', { key, data });
		}
	},
	async getAndSetNewImageAccessToken({ commit })
	{
		const { data: { token } } = await get('/user/image_access_token');

		commit('SET_IMAGE_ACCESS_TOKEN', token);
	},
	removeImageAccessToken({ commit })
	{
		commit('SET_IMAGE_ACCESS_TOKEN', undefined);
	}
};

const getters = {
	getProfile: (state, getters, rootState, rootGetters) => rootGetters['profiles/get'](state.accountId),
	getProfileSimple: (state, getters, rootState, rootGetters) => rootGetters['profiles/getSimpleData'](state.accountId, 'profile'),
	getMetaSimple: (state, getters, rootState, rootGetters) => rootGetters['profiles/getSimpleData'](state.accountId, 'meta'),
	getDataValueByPath: (state, getters, rootState, rootGetters) => (path, source = 'profile') => rootGetters['profiles/getDataValueByPath'](state.accountId, path, source),
	/**
	 * Applies the user's privacy settings to their own data.
	 * Used when a user is providing their own data directly for use,
	 * e.g. video chat display name.
	 */
	getPublicDataValueByPath: (
		state,
		getters,
		rootState,
		rootGetters
	) => (path, source = 'profile') =>
	{
		const schema = rootGetters['dataSchemas/byKey'](path);

		// TODO: Since this checks for a schema, won't work for metadata.
		if(!(
			schema &&
			getters.getDataPermission(schema.id) === 'public'
		)) return null;

		return getters.getDataValueByPath(path, source);
	},
	dataValue: (state, getters) => (name) => getters.getProfileSimple && getters.getProfileSimple[name],
	metaValue: (state, getters) => (name) => getters.getMetaSimple && pick(name, getters.getMetaSimple),
	loggedIn: (state) => state.jwt && state.loggedIn,
	isAdmin: (state) => state.isAdmin,
	isElevated: (state) => state.isElevated,
	hasNotifications: (state) => state.notificationsEnabled,
	accountId: (state) => state.accountId,
	// getDataPermission: (state, getters, rootStore, rootGetters) => (path, source = 'profile') => rootGetters['profiles/getDataPrivacyByPath'](state.accountId, path, source),
	getDataPermission: (state, getters, rootStore, rootGetters) => (schemaId) =>
	{
		const setting = state.dataPrivacy.find((setting) => setting.dataSchemaId === schemaId);

		if(!setting)
		{
			return 'public';
		}

		return setting.state || 'public';
	},
	getUserDeviceTokens: (state) => state.userDeviceTokens,
	getBlockStatistics: (state) => (statTargetId, stat) => (state.blockStatistics[statTargetId] ? state.blockStatistics[statTargetId][stat] : null),
	listMembershipsLoaded: (state) => state.listMemberships.length > 0,
	userListIds: (state) => state.listMemberships.filter((membership) => membership.state === 'accepted').map((membership) => membership.userListId),
	isUserInList: (state) => (userListId) => state.listMemberships.some((membership) => membership.userListId === userListId),
	isListMembershipHidden: (state) => (userListId) => !!state.listMemberships.find((membership) => membership.userListId === userListId)?.hidden,
	chatSidebarCollapse: (state) => state.chatSidebarCollapse,
	favouritesByType: (state, getters) => (type) =>
	{
		const { favourites } = getters.getMetaSimple;

		return favourites?.[type];
	},
	reactionsOnPost: (state, getters) => (postId) =>
	{
		const { reactedPosts } = getters.getMetaSimple;

		return reactedPosts?.[postId];
	},
	allReactions: (state, getters) =>
	{
		const { reactedPosts } = getters.getMetaSimple;

		return !Array.isArray(reactedPosts) ? reactedPosts :
			reactedPosts.reduce((agg, post, index) =>
			{
				if(post)
				{
					agg[`${index}`] = post;
				}

				return agg;
			}, {});
	},
	selectedExportTemplate: (state, getters) => (exportId) =>
	{
		const { selectedExportTemplate } = getters.getMetaSimple;

		return selectedExportTemplate?.[exportId] || false;
	},
	// this is NOT the same as '!state.loggedIn' - this indicates the user _specifically_ clicked the logout button - we use it to avoid logging them back in the login page
	loggedOut: (state) => state.loggedOut,
	language: (state, getters, rootState, rootGetters) => rootGetters['user/metaValue']('language', 'meta') || rootGetters['app/settings/get']('languages.default'),
	jwt: (state) => state.jwt,
	permissions: (state) => state.permissions,
	can: (state) => (action, subjectName, subjectId, conditions = {}) => ability.can(action, subject(subjectName, { subjectId, ...conditions })),
	basePaths: (state) => state.basePaths,
	frameworkData: (state) => (key) => state.frameworkData[key],
	serviceId: (state) => state.serviceId,
	imageAccessToken: (state) => state.imageAccessToken,
	getPublicName: (state, getters) =>
	{
		let publicName = getters.getPublicDataValueByPath(
			getKeyForAlias(KeyAliasKeys.firstName)
		);

		const lastName = getters.getPublicDataValueByPath(
			getKeyForAlias(KeyAliasKeys.lastName)
		);

		if(lastName)
		{
			publicName = publicName ?
				`${publicName} ${lastName}` :
				lastName;
		}

		return publicName || getters.accountId;
	}
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
