import Vue from 'vue';
// import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import admin from '@/store/structure/modules/admin';
import { get } from '@/utils/api';
import { setItems, filterInaccessibleChildIds } from '@/utils/store';

const modules = {
	admin
};

const state = {
	modules: {},
	updateModuleRemovedFromList: false
};

const mutations = {
	SET_MODULES(state, modules)
	{
		setItems(state, 'modules', state.modules, modules, 'id', { preserveNewOrder: true });
	},
	SET_MODULE(state, module)
	{
		if(module.archived)
		{
			Vue.delete(state.modules, module.id);
		}
		else
		{
			Vue.set(state.modules, module.id, module);
		}
	},
	REMOVE_MODULE(state, id)
	{
		Vue.delete(state.modules, id);
	},
	RESET(state)
	{
		Vue.set(state, 'modules', {});
	},
	SET_MODULE_UPDATE_REMOVED_FROM_LIST(state, removingFromList)
	{
		Vue.set(state, 'updateModuleRemovedFromList', removingFromList);
	}
};

const actions = {
	setModules({ commit, dispatch, rootGetters }, modules)
	{
		if(!modules) return;

		if(Array.isArray(modules))
		{
			modules.forEach((module) => dispatch('setModule', module));
		}
		else
		{
			const pages = rootGetters['structure/pages/getPages'];

			Object.keys(modules).forEach((moduleId) =>
			{
				if(!Array.isArray(modules[moduleId]?.childIds))
				{
					return;
				}

				const { /* accessibleChildIds, */ inaccessibleChildIds } = (
					filterInaccessibleChildIds(modules[moduleId].childIds, pages)
				);

				/**
				 * PT_177103795 It's likely there are childIds which do not correspond to a loaded page, causing an error in layout/navigation/Pages.vue
				 * Dispatching the audit from here, since there's no access to the causal page id
				 * in the Pages.vue without duplicating $store.getters['structure/pages/getModulePages']
				 * and this might be a handy audit
				 *
				 * Omitting the childIds filter to allow the PT_177103795 error to occur to generate Sentry timestamps,
				 * since the audit will also be done for visibility filters
				 */
				// modules[moduleId].childIds = accessibleChildIds;

				if(inaccessibleChildIds.length)
				{
					dispatch('audit/log', {
						logType: 'inaccessibleChildIds',
						subject: '',
						extra: {
							moduleId,
							childIds: inaccessibleChildIds,
							viewingAs: rootGetters['admin/viewingAs']
						}
					}, { root: true });
				}
			});

			commit('SET_MODULES', modules);
		}
	},
	setModule({ commit, dispatch, rootGetters }, module)
	{
		if(!module) return;

		if(!Array.isArray(module.childIds))
		{
			commit('SET_MODULE', module);

			return;
		}

		const pages = rootGetters['structure/pages/getPages'];

		const { /* accessibleChildIds, */ inaccessibleChildIds } = (
			filterInaccessibleChildIds(module.childIds, pages)
		);

		/**
		 * PT_177103795 See setModules
		 */
		// module.childIds = accessibleChildIds;

		if(inaccessibleChildIds.length)
		{
			dispatch('audit/log', {
				logType: 'inaccessibleChildIds',
				subject: '',
				extra: {
					moduleId: module.id,
					childIds: inaccessibleChildIds,
					viewingAs: rootGetters['admin/viewingAs']
				}
			}, { root: true });
		}

		commit('SET_MODULE', module);
	},
	reset({ commit })
	{
		commit('RESET');
	},
	updateModuleForRemoval({ commit }, removingFromList)
	{
		commit('SET_MODULE_UPDATE_REMOVED_FROM_LIST', removingFromList);
	},
	async loadModule({ getters, dispatch }, id)
	{
		let { data } = await get(`structure/module/${id}`);

		// Since duplicate GET requests return a cached Promise,
		// the childIds assignment below can be a direct
		// store data modification.
		// Clone to avoid.
		data = cloneDeep(data);

		const index = data.modules.findIndex((module) => module.id === id);

		// data.pages gets updated when a user is removed from a userList
		// but the modules.childIds (pageIds) don't so the page doesn't
		// disappear. The below basically filters out any childIds that
		// shouldn't be showing anymore because they're no longer
		// in the data.pages array.
		data.modules[index].childIds = data.modules[
			index
		].childIds.filter((pageId) =>
		{
			return data.pages.find((page) => pageId === page.id);
		});

		await dispatch('app/handleServerResponse', data, { root: true });

		return data;
	},
	async loadModuleBySlug({ dispatch }, slug)
	{
		const { data } = await get(`structure/module/slug/${slug}`);

		await dispatch('app/handleServerResponse', data, { root: true });

		return data;
	},
	async loadAll({ dispatch })
	{
		const { data } = await get('structure/module');

		return dispatch('app/handleServerResponse', data, { root: true });
	}
};

const getters = {
	getModules: (state) => state.modules,
	getDefaultModuleId: (state, getters, rootState, rootGetters) =>
	{
		const id = rootGetters['app/settings/get']('structure.defaults.module');

		if(id && typeof id === 'string')
		{
			return id;
		}

		// If no default is specified, just go for the first
		return Object.keys(getters.getModules).find((moduleId) => moduleId !== 'global');
	},
	getDefaultModule: (state, getters) => state.modules[getters.getDefaultModuleId],
	getModuleBySlug: (state, getters) => (moduleSlug) =>
	{
		return Object.values(getters.getModules).find((module) => module.slug === moduleSlug);
	},
	getModuleById: (state, getters) => (moduleId) => getters.getModules[moduleId],
	getActiveModule: (state, getters, rootState, rootGetters) => state.modules[rootGetters['app/getActiveModuleId']],
	getModulesAsArray: (state, getters) =>
	{
		const modules = { ...getters.getModules };

		delete modules.global;

		return Object.values(modules);
	},
	getSlugForId: (state, getters) => (id) =>
	{
		const module = getters.getModuleById(id);

		if(!module || !module.slug)
		{
			return null;
		}

		return module.slug;
	},
	getNavigationModules: (state, getters, rootState, rootGetters) =>
	{
		return getters.getModulesAsArray.reduce((modules, module) =>
		{
			if(module && !(module.hideFromNav || module.isAbstract))
			{
				const pages = rootGetters['structure/pages/getModulePages'](module.id)
					.filter((page) => !!page);

				if(pages.length || rootGetters['admin/isEditMode'])
				{
					modules.push(module);
				}
			}

			return modules;
		}, []);
	},
	shouldModuleUpdateForRemovedUsers: (state) => state.updateModuleRemovedFromList
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
