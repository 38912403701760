<template>
	<div class="toggle-switch" @click="clicked">
		<div :class="{ active: value }" class="rail">
			<div
				:class="{ focus, active: value }"
				class="pin"
				@mousedown="setFocus"
				@mouseup="unsetFocus"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: Boolean,
				required: true
			}
		},
		data: () => ({ focus: false }),
		methods: {
			setFocus()
			{
				this.focus = true;
			},
			unsetFocus()
			{
				this.focus = false;
			},
			clicked()
			{
				this.$emit('click');
				this.$emit('input');
			}
		}
	};
</script>

<style scoped>
	.toggle-switch {
		cursor: pointer;
	}

	.rail {
		position: relative;
		height: 20px;
		border-radius: 10px;
		width: 45px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), inset 0 0 0 0 #4fa901;
		transition: all 0.3s ease;
		background: rgba(137, 137, 137, 0.5);

		&.active {
			box-shadow: inset 0 0 5px rgba(0, 0, 0, 0), inset 0 0 0 10px #59b200;
		}
	}

	.pin {
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 100px;
		position: absolute;
		top: 0;
		transform: scale(1.1);
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
		transition: width 0.1s ease, transform 0.3s ease;

		&.focus {
			width: 23px;
		}

		&.active {
			transform: scale(1.1) translateX(22px);
		}
	}
</style>
