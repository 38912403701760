import { get, post, put } from '@/utils/api';

const state = {
	items: [],
	loading: false
};

const mutations = {
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	}
};

const actions = {
	async search({ dispatch }, { page = 1, limit = 10, filters = {}, sortBy = null, descending = false, bulkReports = false })
	{
		const { data } = await get('reportAbuse/search', { params: { page, limit, filters, sortBy, descending, bulkReports } });

		return data;
	},
	async getOne({ dispatch }, { id, bulkReports = false } = {})
	{
		const { data } = await get(`reportAbuse/${id}`, { params: { bulkReports } });

		return data?.item || null;
	},
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	},
	setReportAbuse({ commit }, mappings)
	{
		if(Array.isArray(mappings))
		{
			commit('SET_DATA_MAPPINGS', mappings);
		}
	},
	async createCommunication({ dispatch }, { reportedId, reportedType, category, additionalData })
	{
		const { data } = await put('/reportAbuse/communication', { reportedId, reportedType, category, data: additionalData });

		return data?.success || false;
	},
	async deleteCommunicationAdmin({ dispatch }, { id, reason })
	{
		const { data } = await post('/reportAbuse/deleteCommunicationAdmin', { id, reason });

		return data?.success || false;
	},
	async commentCommunicationAdmin({ dispatch }, { id, message })
	{
		const { data } = await post('/reportAbuse/commentCommunicationAdmin', { id, message });

		return data?.success || false;
	},
	async enableCommunicationAdmin({ dispatch }, { id })
	{
		const { data } = await post('/reportAbuse/enableCommunicationAdmin', { id });

		return data?.success || false;
	},
	async update({ dispatch }, payload)
	{
		if(!payload?.id || !payload?.status)
		{
			return false;
		}

		const { data } = await post(`/reportAbuse/${payload.id}`, payload);

		return data?.success || false;
	}
};

const getters = {
	loading: (state) => state.loading
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
