import { I18N } from 'acb-lib';
import { languageAdminNameForIso } from '@/utils/languages';

/** @typedef {import('../../types/components/mixins/languages').Computed} Computed */
/** @typedef {import('../../types/components/mixins/languages').Methods} Methods */

export default {
	/** @type {Computed} */
	computed: {
		availableLanguagesFormatted()
		{
			return this.formatLanguagesArray(this.enabledLanguages);
		},
		localeDefault()
		{
			return this.$store.getters['i18n/localeDefault'];
		},
		publishedLanguages()
		{
			return this.$store.getters['i18n/publishedLanguages'];
		},
		publishedLanguagesFormatted()
		{
			return this.formatLanguagesArray(this.$store.getters['i18n/publishedLanguages']);
		},
		enabledLanguages()
		{
			return this.$store.getters['i18n/enabledLanguages'];
		}
	},
	/** @type {Methods} */
	methods: {
		formatLanguagesArray(languages)
		{
			return languages.map((iso) =>
			{
				// Language label in that language, e.g. `Français, 日本語...`.
				const labelInLanguage = I18N.cLanguages[iso] || iso;

				// e.g. `English`, `Français (French)`...
				const labelOriginalAndEnglish = languageAdminNameForIso(iso);

				return {
					label: labelInLanguage,
					labelOriginalAndEnglish,
					value: iso,
					isDefault: iso === this.localeDefault,
					isPublished: this.publishedLanguages.includes(iso)
				};
			});
		}
	}
};
