// @ts-check
/**
 * Widths an image's thumbnail can be requested to have.
 * @enum {import('@/types/app').AllowedThumbnailWidth}
 */
const allowedThumbnailWidths = /**
	 * @type {import('@/types/app').AllowedThumbnailWidthConst}
	 */Object.freeze({
		56: 56,
		100: 100,
		250: 250,
		300: 300,
		500: 500,
		600: 600,
		900: 900,
		1920: 1920
	});

/**
 * Media types allowed for a media or status post created through Create User Post
 */
const userPostMediaTypes = {
	images: 'images',
	audio: 'audio',
	videos: 'videos',
	documents: 'documents',
	links: 'links'
};
/**
 * Aspect ratio of a post thumbnail when the post has display mode thumbnail or thumbnailOnly
 */
const userPostThumbnailAspectRatios = {
	'16/9': '16/9',
	'3/2': '3/2',
	1: '1'
};

/** Using the older export syntax so that JEST won't be confused. */
module.exports = {
	allowedThumbnailWidths,
	userPostMediaTypes,
	userPostThumbnailAspectRatios
};
