<template>
	<Notifications
		:unseenCount="unseenCount"
		:notifications="notifications"
		:icon="icon"
		label="navigation.notifications.notifications"
		@open="open"
	/>
</template>

<script>
	import IconMixin from '@/components/ui/notificationsMenu/IconMixin';

	export default {
		mixins: [IconMixin],
		props: {
			icon: {
				type: String,
				default: 'fas fa-bell'
			}
		},
		data()
		{
			return {
				type: 'system'
			};
		}
	};
</script>
