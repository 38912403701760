import { destroy, get, post, put } from '@/utils/api';
import { cAdminPanelTypes } from '@/configs/adminPanelTypes';

const state = {
	modules: null
};

const mutations = {
	SET_MODULES(state, { modules })
	{
		state.modules = modules;
	}
};

const actions = {
	async search({ dispatch }, { page = 1, limit = 10, filters = {}, sortBy = null, descending = false, typesToLoad = ['normal', 'abstract'] })
	{
		const { data } = await get('/structure/module/search', { params: { page, limit, filters, sortBy, descending, typesToLoad } });

		const itemData = data.items.map((item) => item.data);

		dispatch('structure/modules/setModules', itemData, { root: true });

		return data;
	},
	async changeOrder({ dispatch, rootGetters }, modules)
	{
		const originalModules = rootGetters['structure/modules/getModules'];

		const orderIds = modules.map((module) => module.id);
		const newOrder = {};

		orderIds.forEach((moduleId) =>
		{
			newOrder[moduleId] = originalModules[moduleId];
		});

		dispatch('structure/modules/setModules', newOrder, { root: true });

		const response = await post('structure/module/order/', { order: orderIds });

		if(!response?.data)
		{
			dispatch('structure/modules/setModules', originalModules, { root: true });
		}
	},
	openPanel({ dispatch })
	{
		dispatch('admin/panel/new', { type: cAdminPanelTypes.newModule }, { root: true });
	},
	async upsert({ dispatch }, module)
	{
		let data;

		if(module.id)
		{
			({ data } = await post(`structure/module/${module.id}`, { module }));
		}
		else
		{
			({ data } = await put('structure/module', { module }));
		}

		await dispatch('structure/admin/handleServerChanges', data, { root: true });

		return data;
	},
	async delete({ dispatch }, moduleId)
	{
		const { data } = await destroy(`structure/module/${moduleId}`);

		dispatch('structure/admin/handleServerChanges', data, { root: true });

		return data;
	},
	load({ state, commit }, force = false)
	{
		if(state.modules === null || force)
		{
			return get('structure/module/adminView').then((res) =>
			{
				commit('SET_MODULES', res.data);
			});
		}

		return Promise.resolve(true);
	},
	async updateProperty({ dispatch }, { moduleId, key, value })
	{
		if(!moduleId) throw new Error('You must provide a module ID');

		try
		{
			const { data } = await post(`structure/module/${moduleId}/property`, { key, value });

			dispatch('structure/admin/handleServerChanges', data, { root: true });
		}
		catch(e)
		{
			console.warn(e);
		}
	},
	async moveChild({ state, commit, dispatch }, { origin, target, child })
	{
		if(typeof child !== 'undefined')
		{
			try
			{
				await post(`structure/module/${origin}/moveChild/${child}/target/${target}`);
			}
			catch(e)
			{
				throw new Error('Error submitting move request');
			}
			finally
			{
				await dispatch('structure/modules/loadAll', null, { root: true });
			}
		}
	}
};

const getters = {
	getModules: (state) => state.modules
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
