import memoize from 'lodash/memoize';
import deepmerge from 'deepmerge';
import dot from 'dot-object';
import store from '@/store';
import { coreFieldDefaults, sortRepeaterValues } from '@/utils/fieldSorting';

export const isThisFieldTypeTranslatable = (type) => ['options', 'checkbox', 'radio'].includes(type);

export const retrieveFieldsByKey = memoize((cacheKey, data) =>
{
	return Object.keys(data).map((key) => store.getters['dataSchemas/byKey'](key))
		.reduce((agg, schema) =>
		{
			if(schema)
			{
				agg[schema.key] = deepmerge(schema, store.getters['structure/fields/getFieldBySchemaId'](schema.id) || {});
			}

			return agg;
		}, {});
});

export const retrieveFieldsById = memoize((cacheKey, data) =>
{
	return data.map((id) => store.getters['dataSchemas/byFieldId'](id))
		.reduce((agg, schema) =>
		{
			if(schema)
			{
				agg[schema.key] = deepmerge(schema, store.getters['structure/fields/getFieldBySchemaId'](schema.id) || {});
			}

			return agg;
		}, {});
});

export const getLabelsForOptions = memoize((cacheKey, data) =>
{
	const fieldsForData = retrieveFieldsByKey(cacheKey, data);

	return Object.entries(data).reduce((agg, [key, value]) =>
	{
		const field = fieldsForData[key];

		if(!field) return agg;

		if(isThisFieldTypeTranslatable(field.type))
		{
			const { sharedOptions } = field;

			if(Array.isArray(value))
			{
				if(sharedOptions)
				{
					agg[key] = value.map((option) => store.getters['i18n/get'](`sharedOptions.${sharedOptions}.${option}`));
				}
				else
				{
					agg[key] = value.map((option) => store.getters['i18n/get'](`custom.fields.${field.id}.options.${option}`));
				}
			}
			else if(sharedOptions)
			{
				agg[key] = store.getters['i18n/get'](`sharedOptions.${sharedOptions}.${value}`);
			}
			else
			{
				agg[key] = store.getters['i18n/get'](`custom.fields.${field.id}.options.${value}`);
			}

			return agg;
		}
		else if(field.type === 'repeater')
		{
			// sanity check the value
			if(Array.isArray(value))
			{
				// if there is a sort applied to this field, just do it here before we start translating
				// format should still be YYYY-MM-DD
				const translatableValue = sortRepeaterValues(field, value, 'handlebars').data;

				// process these values after verifying which key is which
				agg[key] = getRepeaterTranslations(translatableValue, field);
			}

			return agg;
		}

		return agg;
	}, {});
});

export const getRepeaterTranslations = memoize((value, field) =>
{
	// gather data for this field's children
	const baseChildrenObject = retrieveFieldsById(field.childIds.join(), field.childIds);
	let conditional = {};

	dot.object(baseChildrenObject);

	// before picking the children, check if a conditional property is returned
	// if so, get the children from there and run retrieveFieldsById again
	if(baseChildrenObject.conditional)
	{
		conditional = retrieveFieldsById(baseChildrenObject.conditional.childIds.join(), baseChildrenObject.conditional.childIds);
		dot.object(conditional);
	}

	// remove unnecessary levels from this data object and expose only the children we need
	const children = dot.pick(field.key, deepmerge(baseChildrenObject, conditional));

	if(
		typeof value === 'object' &&
		!Array.isArray(value) &&
		'value' in value
	)
	{
		value = value.value;
	}

	// iterate over this repeater's stored data
	return value.map((instance) =>
	{
		if(!instance) return null;

		// iterate over this instance's fields
		return Object.keys(instance).reduce((agg, key) =>
		{
			// if this field is NOT a repeater, we should check for a translation
			if(children?.[key]?.type && children?.[key]?.type !== 'repeater')
			{
				// to reduce unnecessary store calls, check only for fields that are relevant
				if(isThisFieldTypeTranslatable(children[key].type))
				{
					const childId = children[key].id;
					const field = store.getters['structure/fields/byId'](childId);
					let translation,
						valueToUse = instance[key],
						/** whether instance[key] is like `{ value: ... }` */
						hasValue = false;

					if(
						instance[key] &&
						typeof instance[key] === 'object' &&
						!Array.isArray(instance[key]) &&
						'value' in instance[key]
					)
					{
						hasValue = true;
						valueToUse = valueToUse.value;
					}

					if(field?.sharedOptions)
					{
						const { sharedOptions } = field;

						translation = store.getters['i18n/get'](
							`sharedOptions.${sharedOptions}.${valueToUse}`
						);
					}
					// If there's an array of values (multi-select dropdown for instance), return an array of translations
					else if(Array.isArray(valueToUse))
					{
						translation = valueToUse.map((value) => (
							store.getters['i18n/get'](
								`custom.fields.${childId}.options.${value}`
							)
						));
					}
					else
					{
						translation = store.getters['i18n/get'](`custom.fields.${childId}.options.${valueToUse}`);
					}

					// if there is one, return it
					if(translation)
					{
						if(hasValue)
						{
							agg[key] = { value: translation };
						}
						else
						{
							agg[key] = translation;
						}

						return agg;
					}
				}

				// if not a translatable field type OR no translation was found, return the original value
				agg[key] = instance[key];

				return agg;
			}

			// if the field is also a repeater, we need to go deeper...
			if(children?.[key])
			{
				agg[key] = getRepeaterTranslations(instance[key], children[key]);
			}

			return agg;
		}, {});
	}).filter((v) => v);
});

export const sortRepeaterData = memoize((cacheKey, data) =>
{
	if(!data)
	{
		return data;
	}

	return Object.entries(data).reduce((agg, [key, value]) =>
	{
		let sorted = false;

		if(Array.isArray(value))
		{
			const schema = store.getters['dataSchemas/byKey'](key);

			if(schema && schema.type === 'repeater')
			{
				const field = store.getters['structure/fields/getFieldBySchemaId'](schema.id);

				if(field && (field.sort || coreFieldDefaults.includes(field.id)))
				{
					agg[key] = sortRepeaterValues(field, value, 'handlebars').data;

					sorted = true;
				}
			}
		}

		if(!sorted)
		{
			agg[key] = value;
		}

		return agg;
	}, {});
});
