<template>
	<Popover v-if="requiresConfirmation">
		<template #activator="propsForSlot">
			<slot v-bind="propsForSlot">
				<SimpleButton
					flat
					icon="far fa-trash-alt"
					:loading="loading"
					:aria-label="$t('blocks.controls.header.delete')"
					color="negative"
					size="xs"
					round
					class="q-pa-xs"
				/>
			</slot>
		</template>

		<template>
			<div class="q-mb-md">
				<I18N :id="title" />
			</div>
			<div class="q-gutter-sm">
				<SimpleButton
					v-close-popup
					unelevated
					color="negative"
					@click.stop.prevent="confirmAction"
				>
					<I18N :id="confirm" />
				</SimpleButton>
				<SimpleButton
					v-close-popup
					unelevated
				>
					<I18N :id="cancel" />
				</SimpleButton>
			</div>
		</template>
	</Popover>
	<div v-else>
		<slot>
			<SimpleButton
				flat
				icon="far fa-trash-alt"
				:loading="loading"
				:aria-label="$t('blocks.controls.header.delete')"
				color="negative"
				size="xs"
				round
				class="q-pa-xs"
				@click.stop.prevent="confirmAction"
			/>
		</slot>
	</div>
</template>

<script>
	/** Note: you must use `@click.stop` to get the dialog to actually show */
	export default {
		components: {
			Popover: () => import('@/components/ui/Popover')
		},
		inject: {
			parentTree: {
				default: []
			}
		},
		props: {
			runOnConfirm: {
				type: Function,
				required: true
			},
			language: {
				type: String,
				required: true
			},
			requiresConfirmation: {
				type: Boolean,
				required: true
			}
		},
		data()
		{
			return {
				loading: false,
				title: `${this.language}.title`,
				confirm: `${this.language}.confirm`,
				cancel: `${this.language}.cancel`
			};
		},
		methods: {
			async confirmAction()
			{
				this.toggleLoading();
				await this.runOnConfirm(true);
				this.toggleLoading();
			},
			toggleLoading()
			{
				this.loading = !this.loading;
			}
		}
	};
</script>

<style scoped>
	.wrapper {
		padding: 10px;
	}
</style>
