import Vue from 'vue';
import { get } from '@/utils/api';

const state = {
	items: {},
	loading: {},
	loaded: {}
};

const mutations = {
	SET_DATA(state, data)
	{
		Vue.set(state.items, data.id, data);
	},
	SET_LOADING(state, { id, loading })
	{
		Vue.set(state.loading, id, loading);
	},
	SET_LOADED(state, { id })
	{
		Vue.set(state.loaded, id, true);
	},
	RESET_DATA(state, id)
	{
		Vue.set(state.items, id, {});
	},
	RESET_ALL(state)
	{
		state.items = {};
		state.loading = {};
		state.loaded = {};
	}
};

const actions = {
	async forceLoadStatistics({ getters, commit, dispatch }, id)
	{
		dispatch('loading', { id, loading: true });
		try
		{
			const { data } = await get(`statistics/${id}`);

			if(data?.displayType === 'table' && data?.data[0]?.amount) // order all the tables with amounts in them - useful when browsing through details
			{
				data.data = data.data.sort((a, b) => b.amount - a.amount); // in descending order
			}

			commit('SET_DATA', data);
		}
		catch(e)
		{
			console.error('There was an error', e.message);
		}
		finally
		{
			dispatch('loading', { id, loading: false });
			commit('SET_LOADED', { id });
		}
	},
	async loadStatistics({ getters, commit, dispatch }, id)
	{
		if(getters.byId(id))
		{
			return;
		}

		dispatch('forceLoadStatistics', id);
	},
	async getDetails({ getters, commit, dispatch }, { id, category, subCategory })
	{
		dispatch('loading', { id, loading: true });

		try
		{
			const { data } = await get(`statistics/details/${id}`, { params: { category, subCategory } });

			commit('SET_DATA', data);
		}
		catch(e)
		{
			console.error('There was an error', e.message);
		}
		finally
		{
			dispatch('loading', { id, loading: false });
		}
	},
	resetData({ commit }, id)
	{
		commit('RESET_DATA', id);
	},
	resetAll({ commit })
	{
		commit('RESET_ALL');
	},
	resetActivityFeed({ commit }, id)
	{
		commit('RESET_DATA', id);
	},
	async loadActivityFeed({ getters, commit, dispatch }, { id, index })
	{
		if(!getters.loading(id))
		{
			dispatch('loading', { id, loading: true });
			try
			{
				const limit = 100; // needs to be the same number as it is in the backend!
				const offset = (index - 1) * limit - 1;
				let lastId,
					previous = getters.byId(id);

				if(!previous || !previous.data)
				{
					previous = {
						data: []
					};
				}

				if(getters.totalData(id))
				{
					lastId = getters.byIndex(id, offset);

					if(!lastId)
					{
						lastId = 0;
					}
					else
					{
						lastId = lastId.id;
					}
				}
				else
				{
					lastId = -1;
				}

				const { data } = await get(`statistics/activityFeed/${lastId}`);

				if(!data || data.length === 0)
				{
					return false;
				}

				const activities = {
					id,
					data: previous.data.concat(data)
				};

				commit('SET_DATA', activities);

				return true;
			}
			catch(e)
			{
				console.error('There was an error', e.message);
			}
			finally
			{
				dispatch('loading', { id, loading: false });
			}
		}

		return false;
	},
	loading({ commit }, { id, loading })
	{
		commit('SET_LOADING', { id, loading });
	}
};

const getters = {
	all: (state) => state.items,
	loading: (state) => (id) => state.loading[id],
	loaded: (state) => (id) => state.loaded[id],
	total: (state, getters) => (id) => Object.keys(getters.all).length,
	byId: (state, getters) => (id) => getters.all[id],
	totalData: (state, getters) => (id) => getters.byId(id) && getters.byId(id).data && getters.byId(id).data.length,
	byIndex: (state, getters) => (id, index) => getters.byId(id) && getters.byId(id).data && getters.byId(id).data[index],
	categoriesById: (state, getters) => (id) => getters.byId(id) && getters.byId(id).categories,
	seriesById: (state, getters) => (id) => getters.byId(id) && getters.byId(id).series,
	xAxisTypeById: (state, getters) => (id) => getters.byId(id) && getters.byId(id).xAxisType,
	displayTypeById: (state, getters) => (id) => (getters.byId(id) && getters.byId(id).displayType) || 'chart',
	dataById: (state, getters) => (id) => getters.byId(id) && getters.byId(id).data,
	titleById: (state, getters) => (id) => getters.byId(id) && getters.byId(id).title
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
