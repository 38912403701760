export function theBigShinyM()
{
	// brighter
	const b = 'color:#FF668E;font-weight:bold;';
	// darker
	const d = 'color:#892C4E;font-weight:bold;';

	// eslint-disable-next-line max-len
	console.log('%c            MMM                    MMM            \n         MMMMMMMMMM            MMMMMMMMMMM        \n     MMMMMMMMMMMMMMMMMM    MMMMMMMMMMMMMMMMMMM    \n  MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM  \n  %cM%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM%cM  \n  MMMMM%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM%cMMMM  \n  MMMMMMMM%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM%cMMMMMMM  \n  MMMMMMMMMMMM%cMMMMMMMMMMMMMMMMMMMMMMMM%cMMMMMMMMMM  \n  MMMMMMMMMMMM  %cMMMMMMMMMMMMMMMMMM  %cMMMMMMMMMMMM  \n  MMMMMMMMMMMM     %cMMMMMMMMMMMM     %cMMMMMMMMMMMM  \n  MMMMMMMMMMMM         %cMMMM         %cMMMMMMMMMMMM  \n  MMMMMMMMMMMM                      MMMMMMMMMMMM  \n  MMMMMMMMMMMM                      MMMMMMMMMMMM  \n  MMMMMMMMMMM%cMMMM                MMMM%cMMMMMMMMMMM  \n  MMMMMMMM%cMMMMMMMMMMM        MMMMMMMMMMM%cMMMMMMMM  \n  MMMM%cMMMMMMMMMMMMMMMMMM  MMMMMMMMMMMMMMMMMM%cMMMM  \n  M%cMMMMMMMMMMMMMMMMMMMMM  MMMMMMMMMMMMMMMMMMMMM%cM  \n   %cMMMMMMMMMMMMMMMMMMMMM  MMMMMMMMMMMMMMMMMMMMM   \n       MMMMMMMMMMMMMMMMM  MMMMMMMMMMMMMMMMMM      \n          MMMMMMMMMMMMMM  MMMMMMMMMMMMMM          \n              MMMMMMMMMM  MMMMMMMMMM              \n                  MMMMMM  MMMMMM                  \n                      MM  MM                      ', b, d, b, d, b, d, b, d, b, d, b, d, b, d, b, d, b, d, b, d, b, d, b, d, b);
}
