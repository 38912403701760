
const Block = {
	name: 'BlockInstance',
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	computed: {
		children()
		{
			if(!this.data.children) return [];

			// Map the IDs of blocks to their data, then remove any that are 'undefined' (user does not have permission for that!)
			return this.data.children
				.map((blockId) => this.$store.getters['structure/blocks/getBlock'](blockId))
				.filter((block) => block);
		}
	},
	methods: {
		validateSteppers()
		{
			// Only get the top level stepper, then access child stepper element
			const steppers = this.getChildElementsOfType(this.$children, 'stepper', true)
				.filter((stepper) => stepper.getSteps)
				.map((child) => child.$children[0]);

			const remainingSteps = steppers.reduce((acc, step) => acc + step.remainingSteps, 0);
			const requiredSteps = steppers.reduce((acc, step) => acc + step.items.length, 0);

			return {
				complete: remainingSteps === 0,
				remainingSteps,
				requiredSteps
			};
		},
		getChildElementsOfType(children, type, recursive)
		{
			const foundBlocks = [];

			if(recursive && children)
			{
				children.forEach((child) =>
				{
					if(child.data?.type === type)
					{
						foundBlocks.push(child);
					}

					if(child.$children && recursive)
					{
						const matches = this.getChildElementsOfType(child.$children, type, recursive);

						if(matches?.length)
						{
							foundBlocks.push(...matches);
						}
					}
				});
			}

			return foundBlocks;
		}
	}
};

export default Block;
