// @ts-check

export const cConnectivityPrivacyPath = 'settings.privacy.isConnectivityPrivate';

/** @enum {import('@/types/settings').cUserConnectivityIndicatorBehaviour} */
export const cUserConnectivityIndicatorBehaviour = /**
	* @type {typeof import('@/types/settings').cUserConnectivityIndicatorBehaviour}
	*/({
		trafficLight: 'trafficLight',
		onlineOnly: 'onlineOnly'
	});

/** @enum {import('@/types/settings').cSettingId} */
export const cSettingId = /**
	* @type {typeof import('@/types/settings').cSettingId}
	*/({
		userListManagementColumns: 'userListManagementColumns',
		exportsUserTable: 'exportsUserTable',
		users: 'users',
		logoImage: 'logoImage',
		faviconImage: 'faviconImage',
		structureOrders: 'structure.orders',
		structureDefaults: 'structure.defaults',
		serviceNameVariations: 'serviceNameVariations',
		keyAliases: 'keyAliases',
		themeMode: 'themeMode',
		videoChat: 'videoChat',
		searchRules: 'searchRules',
		videoChatSearch: 'video-chat-search',
		mentionSearch: 'mention-search',
		chatSearch: 'chat-search',
		userConnectivity: 'userConnectivity'
	});
