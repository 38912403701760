import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import admin from '@/store/structure/fields/admin';
import { get, post } from '@/utils/api';
import { setItems } from '@/utils/store';

const modules = {
	admin
};

const state = {
	fields: {},
	defaults: [],
	acceptedRules: {
		default: ['required'],
		date: ['required', 'date_after', 'date_before'],
		year: ['required', 'min_custom', 'max_custom'],
		text: ['required', 'numeric', 'email', 'min', 'max', 'url', 'slug_valid', 'feed_valid', 'isLink', 'handlebars', 'handlebarsOrURL'],
		textarea: ['required', 'numeric', 'email', 'min', 'max', 'url', 'slug_valid', 'feed_valid'],
		number: ['required', 'min_custom', 'max_custom', 'between'],
		toggle: ['required'],
		checkbox: ['required', 'min_custom', 'max_custom'],
		options: ['required', 'notification_ownership'],
		files: ['file_types', 'size'],
		repeater: [],
		geoLocation: []
	}
};

const mutations = {
	SET_FIELDS(state, fields)
	{
		if(!fields || !fields.length) return;

		setItems(state, 'fields', state.fields, fields, 'id', { freeze: true });
	},
	SET_FIELD(state, field)
	{
		Vue.set(state.fields, field.id, field);
	},
	SET_SINGLE_OPTS(state, { fields, opts })
	{
		const allFields = cloneDeep(state.fields);

		// loop through our fields and set the options
		fields.forEach((field) =>
		{
			field.options = opts.map((option) =>
			{
				return { label: option.label, value: option.value };
			});
		});

		Object.keys(fields).forEach((i) =>
		{
			allFields[fields[i].id] = fields[i];
		});

		Vue.set(state, 'fields', allFields);
	},
	SET_MULTI_OPTS(state, { fields, opts })
	{
		const allFields = cloneDeep(state.fields);

		// loop through our fields and set the options
		fields.forEach((field) =>
		{
			field.options = [...opts];
		});

		Object.keys(fields).forEach((i) =>
		{
			allFields[fields[i].id] = fields[i];
		});

		Vue.set(state, 'fields', allFields);
	},
	RESET(state)
	{
		Vue.set(state, 'fields', {});
	},
	SET_EXPLICIT_DEFAULT(state, field)
	{
		if(!state.defaults.find((currentField) => currentField.id === field.id))
		{
			const newState = cloneDeep(state.defaults);

			newState.push(field);

			Vue.set(state, 'defaults', newState);
		}
	}
};

const actions = {
	async loadByTag({ state, dispatch, commit }, tag)
	{
		const { data } = await get(`structure/field/tag/${tag}`);

		await dispatch('app/handleServerResponse', data, { root: true });
	},
	setFields({ commit }, fields)
	{
		if(!fields || (Array.isArray(fields) && !fields.length)) return;

		if(!Array.isArray(fields)) commit('SET_FIELD', fields);

		commit('SET_FIELDS', fields);
	},
	async setOptions({ commit }, { fields, opts, single = true })
	{
		if(single)
		{
			await commit('SET_SINGLE_OPTS', { fields, opts });
		}
		else
		{
			await commit('SET_MULTI_OPTS', { fields, opts });
		}
	},
	setField({ commit }, originalCopy)
	{
		const field = cloneDeep(originalCopy);

		if(field.options)
		{
			// Clean out the options
			field.options.forEach((data, i) =>
			{
				field.options[i] = data.key;
			});
		}

		commit('SET_FIELD', field);
	},
	reset({ commit })
	{
		commit('RESET');
	},
	async loadDefault({ state, commit }, fieldId)
	{
		if(!state.defaults.find((field) => field.id === fieldId))
		{
			const { data } = await get(`structure/field/default/${fieldId}`);

			commit('SET_EXPLICIT_DEFAULT', data);
		}

		return true;
	},
	async loadDefaults({ state, commit }, fieldIds)
	{
		const idsToLoad = fieldIds.filter((id) => !state.defaults.find((field) => field.id === id));

		if(idsToLoad.length)
		{
			const { data } = await post('structure/field/default/getMany', { fieldIds: idsToLoad });

			if(data)
			{
				data.forEach((field) =>
				{
					commit('SET_EXPLICIT_DEFAULT', field);
				});
			}
		}

		return true;
	}
};

const getters = {
	getFields: (state) => state.fields,
	getField: (state, getters) => (fieldId) => getters.getFields[fieldId],
	getLabelById: (
		state,
		getters,
		rootState,
		rootGetters
	) => (fieldId) => rootGetters['i18n/get'](`custom.fields.${fieldId}.label`),
	byId: (state, getters) => (fieldId) => getters.getField(fieldId),
	getFieldsForBlock: (state, getters, rootState, rootGetters) => (blockId) =>
	{
		if(!rootGetters['structure/blocks/getBlock'](blockId).fields) return [];

		return rootGetters['structure/blocks/getBlock'](blockId).fields.map((fieldId) => getters.getField(fieldId));
	},
	getValidation: (state, getters) => (fieldId) =>
	{
		return getters.getField(fieldId).validation;
	},
	getAcceptedValidation: (state) => (fieldType = null) =>
	{
		return state.acceptedRules[fieldType] || state.acceptedRules.default;
	},
	getSchemaForField: (state, getters, rootState, rootGetters) => (fieldId) =>
	{
		const field = getters.getField(fieldId);

		if(field && field.schema)
		{
			return rootGetters['dataSchemas/getDataSchema'](field.schema);
		}

		return undefined;
	},
	byTag: (state, getters) => (tag) =>
	{
		return Object.values(getters.getFields).filter((field) => field.tags && field.tags.includes(tag));
	},
	bySchemaType: (state, getters, rootState, rootGetters) => (type) =>
	{
		const schemaIds = rootGetters['dataSchemas/byType'](type).map((schema) => schema.id);

		return Object.values(getters.getFields).filter((field) => schemaIds.includes(field.schema));
	},
	getFieldBySchemaId: (state, getters) => (schemaId) =>
	{
		return Object.values(getters.getFields).find((field) => field.schema === schemaId);
	},
	getDefaults: (state) => state.defaults,
	getDefault: (state, getters) => (fieldId) => getters.getDefaults.find((field) => fieldId === field.id)
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
