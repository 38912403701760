import Vue from 'vue';
import { get, post } from '@/utils/api';

const state = {
	loading: false,
	membershipRequests: [],
	membershipRequestsArchives: [],
	states: []
};

const mutations = {
	SET_STATES(state, states)
	{
		Vue.set(state, 'states', states);
	},
	SET_MEMBERSHIP_REQUEST(state, payload)
	{
		const currentIndex = state.membershipRequests.findIndex((request) => request.id === payload.id);

		if(currentIndex > -1)
		{
			Vue.set(state.membershipRequests, currentIndex, payload);
		}
		else
		{
			state.membershipRequests.push(payload);
		}
	},
	SET_MEMBERSHIP_REQUEST_ARCHIVES(state, payload)
	{
		state.membershipRequestsArchives = payload;
	},
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	}
};

const actions = {
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	},
	async loadMembershipRequests({ dispatch }, payload)
	{
		dispatch('setLoading', true);
		const { data } = await get('applications/membershipRequests/admin', { params: payload });

		dispatch('setMembershipRequests', data.data);

		const accountIds = data.data.map((application) => application.accountId);

		const applicationIds = data.data.map((application) => application.applicationId);

		await dispatch('applications/loadApplications', applicationIds, { root: true });
		await dispatch('profiles/loadProfiles', accountIds, { root: true });

		dispatch('setLoading', false);

		return data;
	},
	async loadMembershipRequest({ dispatch }, { membershipRequestId, membershipId })
	{
		dispatch('setLoading', true);

		const { data } = await get(`applications/membershipRequests/admin/${membershipRequestId}`, { params: { membershipId } });

		dispatch('setMembershipRequest', data);

		const { accountId, applicationId } = data;

		if(applicationId)
		{
			await dispatch('applications/loadApplications', [applicationId], { root: true });
		}

		// await dispatch('applications/admin/loadApplications', null, { root: true }); // please don't do this
		await dispatch('profiles/reloadProfile', accountId, { root: true }); // make sure it's the latest profile data (as this is where all the user's application data is stored), otherwise the admin will have to reload the whole page to make sure it's the latest

		dispatch('setLoading', false);

		return data;
	},
	async loadStates({ dispatch, getters, state })
	{
		if(!getters.states)
		{
			dispatch('applications/setLoading', true, { root: true });

			const { data } = await get('applications/states');

			dispatch('setStates', data);
			dispatch('applications/setLoading', false, { root: true });
		}
	},
	async changeRequestState({ dispatch }, {
		id,
		state,
		membershipId,
		message,
		changedFrom,
		emailTemplateOverride,
		shouldSendNotifications
	})
	{
		const { data } = await post(`applications/membershipRequests/admin/${id}/${state}`, {
			membershipId,
			message,
			changedFrom,
			emailTemplateOverride,
			shouldSendNotifications
		});

		dispatch('setMembershipRequest', data);
	},
	setStates({ commit }, states)
	{
		commit('SET_STATES', states);
	},
	setMembershipRequests({ dispatch }, payload)
	{
		if(Array.isArray(payload))
		{
			payload.forEach((request) => dispatch('setMembershipRequest', request));
		}
	},
	setMembershipRequest({ commit }, payload)
	{
		commit('SET_MEMBERSHIP_REQUEST', payload);
	},
	async loadMembershipRequestArchives({ commit }, { membershipRequestId })
	{
		const { data } = await get(`applications/membershipRequests/admin/archive/${membershipRequestId}`);

		commit('SET_MEMBERSHIP_REQUEST_ARCHIVES', data);
	}
};

const getters = {
	getStates: (state) => state.states,
	getState: (state, getters) => (name) => getters.getStates.find((s) => s.name === name),
	loading: (state) => state.loading,
	membershipRequests: (state) => state.membershipRequests,
	membershipRequestsArchives: (state) => state.membershipRequestsArchives,
	membershipRequestsById: (state, getters) => (id) => getters.membershipRequests.find((req) => id === req.id),
	membershipRequestsByIds: (state, getters) => (ids) => getters.membershipRequests.filter((req) => ids.includes(req.id)),
	membershipRequestsByIdsByStates: (state, getters) => (ids, states) =>
	{
		const reqs = getters.membershipRequestsByIds(ids);

		return reqs.filter((req) => states.includes(req.state));
	},
	membershipRequestById: (state, getters) => (id) => getters.membershipRequests.find((mr) => parseInt(mr.id, 10) === parseInt(id, 10))
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
