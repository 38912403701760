import Vue from 'vue';

const setItemsOptsDefaults = { freeze: false, preserveNewOrder: false };

export function setItems(state, keyName, originalItems, newItems, fieldNameToAggregateBy = 'id', opts)
{
	opts = Object.assign({}, setItemsOptsDefaults, opts);
	let newItemsAsObject = newItems;

	// If newItems does not exist, or is an empty array, just exit
	if(!newItems || (Array.isArray(newItems) && newItems.length === 0)) return;

	// If the incoming items are an array, make them into an object to make it easier to merge.
	if(Array.isArray(newItems))
	{
		newItemsAsObject = newItems.reduce((agg, item) =>
		{
			agg[item[fieldNameToAggregateBy]] = item;

			return agg;
		}, {});
	}

	// If the original items are an array, we convert them to an object for the merge, and then back to an array to store them.
	if(Array.isArray(originalItems))
	{
		const originalItemsAsObject = originalItems.reduce((agg, item) =>
		{
			agg[item[fieldNameToAggregateBy]] = item;

			return agg;
		}, {});

		Vue.set(state, keyName, Object.values(Object.assign({}, originalItemsAsObject, newItemsAsObject)));

		return;
	}

	const targetObject = {};

	if(opts.preserveNewOrder)
	{
		Object.keys(newItemsAsObject).reduce((obj, key) =>
		{
			obj[key] = undefined;

			return obj;
		}, targetObject);
	}

	const objectToSet = opts.freeze ?
		Object.freeze(Object.assign(targetObject, originalItems, newItemsAsObject)) :
		Object.assign(targetObject, originalItems, newItemsAsObject);

	Vue.set(state, keyName, objectToSet);
}

export function setOneItem(state, keyName, newItem, idField = 'id')
{
	const currentIndex = state[keyName].findIndex((item) => item.id === newItem[idField]);

	if(currentIndex > -1)
	{
		Vue.set(state[keyName], currentIndex, newItem);
	}
	else
	{
		state[keyName].push(newItem);
	}
}

export function filterInaccessibleChildIds(childIds, accessibleChildren)
{
	if(!childIds)
	{
		childIds = [];
	}

	const result = {
		accessibleChildIds: accessibleChildren ? childIds : [],
		inaccessibleChildIds: accessibleChildren ? [] : childIds
	};

	if(!accessibleChildren)
	{
		return result;
	}

	result.accessibleChildIds = result.accessibleChildIds.filter((childId) =>
	{
		if(!accessibleChildren[childId])
		{
			result.inaccessibleChildIds.push(childId);

			return false;
		}

		return true;
	});

	return result;
}
