<template>
	<q-list>
		<div v-for="{ open, module, pages } in tree" :key="module.id">
			<q-expansion-item
				v-if="pages.length > 1"
				:label="$t(`custom.modules.${module.id}`)"
				expandIcon="fal fa-angle-down"
				:defaultOpened="open"
			>
				<q-item
					v-for="page in pages"
					:key="page.id"
					v-ripple
					clickable
					:to="`/${module.slug}/${page.slug}`"
				>
					<I18N :id="`custom.pages.${page.id}`" />
				</q-item>
			</q-expansion-item>
			<q-item
				v-else
				clickable
				:to="`/${module.slug}/${pages[0] ? pages[0].slug : ''}`"
			>
				<q-item-section>
					<q-item-label>
						<I18N :id="`custom.modules.${module.id}`" />
					</q-item-label>
				</q-item-section>
			</q-item>
			<q-separator />
		</div>
	</q-list>
</template>

<script>
	import { routeConfig } from '@/router/index';

	export default {
		computed: {
			tree()
			{
				return this.$store.getters['structure/modules/getNavigationModules'].map((module) => ({
					open: this.currentModule?.id === module?.id,
					module,
					pages: this.$store.getters['structure/pages/getModulePages'](module.id)
						.filter((page) => page && page.hideFromNav !== true)
						.filter((page) => !page.isAbstract)
				}));
			},
			currentModuleSlug()
			{
				return this.$store.state.route.params.moduleSlug;
			},
			currentModule()
			{
				return this.$store.getters['structure/modules/getModuleBySlug'](this.currentModuleSlug);
			},
			staticPages()
			{
				return routeConfig.filter((r) => r.displayInMenu);
			}
		}
	};
</script>

<style scoped>
	.q-list {
		color: pink;
	}

	>>> .q-expansion-item__content {
		padding-left: 30px;
	}
</style>
