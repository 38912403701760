
<template>
	<div>
		<slot />
	</div>
</template>

<script setup>
	// @ts-check
	import { useAttrs } from 'vue';
	import { useQuasarPropsProvide } from '@/composables/ui/quasarProps';

	const attrs = useAttrs();

	/**
	 * Take whatever is passed as attributes and provide it to all descendant components
	 */
	useQuasarPropsProvide({ ...attrs });
</script>
