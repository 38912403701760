import Vue from 'vue';
import { get, getSWR } from '@/utils/api';
import settings from '@/store/app/settings';
import { reloadStyles } from '@/utils/designProcessors';
// import { processStyles, getIntermediateValues } from '@/utils/designProcessors';
// import { setThemeContent } from '@/utils/initialLoad';

const modules = {
	settings
};

const state = {
	connected: false,
	userLoaded: false,
	themeLoaded: false,
	languageLoaded: false,
	structureLoaded: false,
	loading: false,
	activeModuleId: null,
	activePageId: null,
	fullLoader: false,
	keyDown: null,
	connectedInstance: null,
	notificationPermission: ('Notification' in window) && Notification.permission,
	routes: [],
	hideFooter: false,
	stopBodyScroll: false,
	initFinished: false,
	socialSignIn: {
		showModal: false,
		status: {
			type: null,
			providerName: '',
			message: ''
		}
	},
	offline: false,
	modalState: {},
	headerScrollToTopKey: 0,
	blockMove: {
		showModal: false,
		id: null
	}
};

const mutations = {
	SET_CONNECTED(state)
	{
		state.connected = true;
	},
	SET_USER_LOADED(state)
	{
		state.userLoaded = true;
	},
	SET_THEME_LOADED(state)
	{
		state.themeLoaded = true;
	},
	SET_LANGUAGE_LOADED(state)
	{
		state.languageLoaded = true;
	},
	SET_STRUCTURE_LOADED(state)
	{
		state.structureLoaded = true;
	},
	TOGGLE_LOADING(state)
	{
		state.loading = !state.loading;
	},
	START_LOADING(state)
	{
		state.loading = true;
	},
	STOP_LOADING(state)
	{
		state.loading = false;
	},
	SET_ACTIVE_MODULE_ID(state, moduleId)
	{
		Vue.set(state, 'activeModuleId', moduleId);
		// state.activeModuleId = moduleId;
	},
	SET_ACTIVE_PAGE_ID(state, pageId)
	{
		state.activePageId = pageId;
	},
	FULL_LOADER(state, mode)
	{
		state.fullLoader = mode;
	},
	KEY_DOWN(state, key)
	{
		state.keyDown = key;
	},
	SET_CONNECTED_INSTANCE(state, instance)
	{
		state.connectedInstance = instance;
	},
	SET_NOTIFICATION_PERMISSION(state, permission)
	{
		state.notificationPermission = permission;
	},
	ADD_ROUTE(state, route)
	{
		state.routes.push(route);
	},
	HIDE_FOOTER(state, hidden)
	{
		state.hideFooter = hidden;
	},
	SET_INIT_FINISHED(state)
	{
		state.initFinished = true;
	},
	SET_SHOW_SOCIAL_SIGN_IN_MODAL(state, show)
	{
		localStorage.setItem('showSocialSignInModal', show);
		state.socialSignIn.showModal = show;
	},
	SET_SHOW_BLOCK_MOVE_MODAL(state, payload)
	{
		Vue.set(state, 'blockMove', payload);
	},
	/**
	 * Update the SSI "status" to indicate what happened to the most recent account link attempt.
	 *
	 * @param {Object} state Current store state.
	 * @param {Object} Details of the status of the attempt. statusType refers to the state of the request (did it succeed or fail?), statusProvider is the name of the account provider the user attempted to link to (Google, Facebook etc.) and statusMessage is a fallback message provided from the link attempt, in case we didn't even manage to decrypt the payload in the API.
	 */
	SET_SOCIAL_SIGN_IN_STATUS(
		state,
		{
			statusType,
			statusProvider,
			statusMessage
		}
	)
	{
		Vue.set(state.socialSignIn.status, 'type', statusType);
		Vue.set(state.socialSignIn.status, 'providerName', statusProvider);
		Vue.set(state.socialSignIn.status, 'message', statusMessage);
	},
	SET_OFFLINE(state, isOffline)
	{
		state.offline = isOffline;
	},
	TOGGLE_MODAL_STATE(state, { modalId, visible })
	{
		if(state.modalState[modalId] !== visible) // closing a modal is a mess. This is to prevent closing a modal from triggering actions repeatedly
		{
			Vue.set(state.modalState, modalId, visible);
		}
	},
	SET_HEADER_SCROLL_TO_TOP_KEY(state, key)
	{
		Vue.set(state, 'headerScrollToTopKey', key);
	}
};

const actions = {
	async init({ commit, dispatch, rootGetters }, opts)
	{
		await getSWR('/init', { params: { opts } }, async (data, isFromCache) =>
		{
			if(data.generalAccessPermitted === false)
			{
				window.location.replace('/account.php');
			}

			// if this response is not from cache, BUT we DO have an access token already, we'll just keep using the existing one - this stops images from flashing
			// because the token is part of the URL, so if it changes, we have to reload all the images
			if(!isFromCache && rootGetters['user/imageAccessToken'])
			{
				data.user._imageAccessToken = rootGetters['user/imageAccessToken'];
			}

			commit('admin/SET_VIEW_AS', data.viewAs, { root: true });
			await Promise.all([
				dispatch('app/languageLoaded', {}, { root: true }),

				dispatch('user/setUser', data.user, { root: true }),
				dispatch('profiles/insertNewEntry', data.user, { root: true }),
				dispatch(
					'app/userLoaded',
					{ accountId: data.user?.accountId },
					{ root: true }
				),
				dispatch('app/settings/setSettings', data.settings, { root: true })
			]);

			// can't get the locale before settings and user data has been saved into $store
			await dispatch('i18n/initialiseLocale', null, { root: true });

			await Promise.all([
				// shouldn't save any languages before the locale has been set
				dispatch('i18n/saveUserLanguage', data.i18n, { root: true }),
				dispatch('i18n/setAppLanguage', null, { root: true }),

				dispatch('handleServerResponse', data.page || {}),

				dispatch('handleServerResponse', data.structure),
				dispatch('app/structureLoaded', null, { root: true }),

				dispatch('user/upsertDataPrivacy', data.dataPrivacy, { root: true }),

				dispatch('user/setPermissions', data.permissions, { root: true }),

				dispatch('admin/setVisiblePermissions', data.visiblePermissions, { root: true }),

				dispatch('user/setBasePaths', data.basePaths, { root: true })
			]);
		});

		if(localStorage.getItem('showSocialSignInModal'))
		{
			dispatch('setShowSocialSignInModal', localStorage.getItem('showSocialSignInModal') === 'true');
		}

		commit('SET_INIT_FINISHED');
	},
	setActiveModuleId({ commit }, moduleId)
	{
		commit('SET_ACTIVE_MODULE_ID', moduleId);
	},
	setActivePageId({ commit, rootGetters }, { moduleSlug, pageSlug })
	{
		commit('SET_ACTIVE_PAGE_ID', rootGetters['structure/pages/getPageId'](moduleSlug, pageSlug));
	},
	userLoaded({ commit }, { accountId })
	{
		commit('SET_USER_LOADED');

		// Now that we have the user's data we can load the i18n file for them
		// dispatch('i18n/load', {}, { root: true });
	},
	languageLoaded({ commit })
	{
		commit('SET_LANGUAGE_LOADED');
	},
	structureLoaded({ commit })
	{
		commit('SET_STRUCTURE_LOADED');
	},
	toggleLoading({ commit })
	{
		commit('TOGGLE_LOADING');
	},
	startLoading({ commit })
	{
		commit('START_LOADING');
	},
	stopLoading({ commit })
	{
		commit('STOP_LOADING');
	},
	showFullLoader({ commit })
	{
		commit('FULL_LOADER', true);
	},
	hideFullLoader({ commit })
	{
		setTimeout(() => commit('FULL_LOADER', false), 1000);
	},
	keyHeldDown({ commit }, key)
	{
		commit('KEY_DOWN', key);
	},
	setConnected({ commit })
	{
		commit('SET_CONNECTED');
	},
	async handleServerResponse({ dispatch }, payload)
	{
		await Promise.all([
			payload.i18n && dispatch('i18n/updateKeys', payload.i18n, { root: true }),
			(payload.pages || payload.page) && dispatch('structure/pages/setPages', payload.pages || payload.page, { root: true }),
			(payload.blocks || payload.block) && dispatch('structure/blocks/setBlocks', payload.blocks || payload.block, { root: true }),
			(payload.modules || payload.module) && dispatch('structure/modules/setModules', payload.modules || payload.module, { root: true }),
			(payload.fields || payload.field) && dispatch('structure/fields/setFields', payload.fields || payload.field, { root: true }),
			(payload.dataSchemas || payload.dataSchema) && dispatch('dataSchemas/setDataSchemas', payload.dataSchemas || payload.dataSchema, { root: true }),
			(payload.entities || payload.entity) && dispatch('entities/setEntities', payload.entities || payload.entity, { root: true }),
			(payload.entityDefinitions || payload.entityDefinition) && dispatch('entityDefinitions/setEntityDefinitions', payload.entityDefinitions || payload.entityDefinition, { root: true }),
			(payload.applications || payload.application) && dispatch('applications/setApplications', payload.applications || payload.application, { root: true }),
			(payload.powerUps || payload.powerUp) && dispatch('powerUps/setPowerUps', payload.powerUps || payload.powerUp, { root: true }),
			(payload.dataMappings || payload.dataMapping) && dispatch('dataMappings/setDataMappings', payload.dataMappings || payload.dataMapping, { root: true }),
			payload.settings && dispatch('app/settings/mergeSettings', payload.settings, { root: true }),
			payload.updateItems && dispatch('app/handleItemChanges', payload.updateItems, { root: true }),
			payload.updateStyles && reloadStyles()
		]);
		// dispatch('user/notifications/updateUsersEntitySubscriptions', { data, addToArray: true });
	},
	handleItemChanges({ dispatch }, changes)
	{
		Object.keys(changes).forEach((itemType) =>
		{
			if(changes[itemType].length)
			{
				changes[itemType].forEach((change) =>
				{
					if(change.id && change.action)
					{
						if(itemType === 'entities' || itemType === 'profiles') // only ones that have the removeItem action
						{
							switch(change.action)
							{
								case 'delete':
									dispatch(`${itemType}/removeItem`, change.id, { root: true });
									break;
								default:
									break;
							}
						}
					}
				});
			}
		});
	},
	setNotificationPermission({ commit }, permission)
	{
		commit('SET_NOTIFICATION_PERMISSION', permission);
	},
	loadTheme({ commit, dispatch }, theme)
	{
		return get('service/theme.css').then(({ data: theme }) =>
		{
			commit('SET_THEME_LOADED');

			return theme;
		});
		// return getSWR('service/theme.css', undefined, async (theme) =>
		// {
		// 	commit('SET_THEME_LOADED');

		// 	// apply theme to ACB
		// 	insertTheme(theme);

		// 	// find all relevant assets
		// 	const assets = getIntermediateValues(theme, 'backgroundImage');

		// 	if(assets && assets.length)
		// 	{
		// 	// load all relevant assets
		// 		await dispatch('files/loadAssets', assets.map((asset) => asset.value), { root: true });
		// 	}
		// });
	},
	addRoute({ commit }, route)
	{
		commit('ADD_ROUTE', route);
	},
	toggleFooter({ commit }, hidden)
	{
		commit('HIDE_FOOTER', hidden);
	},
	setShowSocialSignInModal({ commit }, show)
	{
		commit('SET_SHOW_SOCIAL_SIGN_IN_MODAL', show);
	},
	/**
	 * Payload should consist of { show: boolean, id: UUID, colIndex: number, index: number }
	 */
	setShowBlockMoveModal({ commit }, payload)
	{
		commit('SET_SHOW_BLOCK_MOVE_MODAL', payload);
	},
	/**
	 * Update the app store state with the status of a SSI account link attempt. See SET_SOCIAL_SIGN_IN_STATUS for accepted data.
	 */
	setSocialSignInStatus({ commit }, { statusType, statusProvider, statusMessage })
	{
		commit(
			'SET_SOCIAL_SIGN_IN_STATUS',
			{
				statusType,
				statusProvider,
				statusMessage
			}
		);
	},
	/**
	 * Set the SSI status back to default values.
	 */
	resetSocialSignInStatus({ dispatch })
	{
		dispatch('setSocialSignInStatus', {
			statusType: null,
			statusProvider: '',
			statusMessage: ''
		});
	},
	setOffline({ commit }, isOffline)
	{
		commit('SET_OFFLINE', isOffline);
	},
	toggleModalState({ commit }, payload)
	{
		commit('TOGGLE_MODAL_STATE', payload);
	},
	/**
	 * Scrolls page to the top,
	 * and forces the header to appear for certain edge-cases.
	 * See `App.vue`.
	 */
	scrollToTop({ state, commit })
	{
		window.scrollTo(0, 0);
		commit('SET_HEADER_SCROLL_TO_TOP_KEY', state.headerScrollToTopKey + 1);
	}
};

const getters = {
	appLoaded(state)
	{
		return state.initFinished;
	},
	loadingProgress(state)
	{
		// The bits of state we care about when determining if we're loaded
		const loadStates = ['userLoaded', 'languageLoaded', 'structureLoaded', 'settings', 'themeLoaded'];

		// Count the total items that have been loaded
		const loadedItems = loadStates.reduce((totalLoaded, property) =>
		{
			let updatedTotalLoaded = totalLoaded;

			if(state[property])
			{
				updatedTotalLoaded += 1;
			}

			return updatedTotalLoaded;
		}, 0);

		// Return some data to be used
		return { total: loadStates.length, current: loadedItems };
	},
	loadState: (state) => (type) => state[`${type}Loaded`],
	getActiveModuleId: (state) => state.activeModuleId,
	getActivePageId: (state) => state.activePageId,
	showFullLoader: (state) => state.fullLoader,
	getUsersPressedKey: (state) => state.keyDown,
	connectedInstance: (state) => state.connectedInstance,
	notificationPermission: (state) => state.notificationPermission,
	getRoutes: (state) => state.routes,
	getCurrentRoute: (state) => state.routes[state.routes.length - 1],
	getPrevRoute: (state) => state.routes[state.routes.length - 2],
	isAdminDashboard: (state, getters) => getters.getCurrentRoute?.matched?.some((match) => match.meta?.adminPage === true),
	footerHidden: (state) => state.hideFooter,
	showSocialSignInModal: (state) => state.socialSignIn.showModal,
	showBlockMoveModal: (state) => state.blockMove.showModal,
	blockMoveModalId: (state) => state.blockMove.id,
	blockMoveModalData: (state) => state.blockMove,
	isDark: (state) => state.isDark,
	isOffline: (state) => state.offline,
	socialSignInStatus: (state) => state.socialSignIn.status,
	getModalState: (state) => (modalId) => state.modalState[modalId],
	headerScrollToTopKey: (state) => state.headerScrollToTopKey,
	isConnected: (state) => state.connected
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
