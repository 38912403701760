import Vue from 'vue';
import uniqBy from 'lodash/uniqBy';
import admin from '@/store/dataSchemas/admin';
import { get } from '@/utils/api';
import { setItems } from '@/utils/store';

const modules = {
	admin
};

const state = {
	dataSchemas: {},
	loadedWholeDataSchema: true
};

const mutations = {
	SET_DATA_SCHEMAS(state, dataSchemas)
	{
		setItems(state, 'dataSchemas', state.dataSchemas, dataSchemas);
	},
	SET_DATA_SCHEMA(state, dataSchema)
	{
		Vue.set(state.dataSchemas, dataSchema.id, dataSchema);
	},
	RESET(state)
	{
		Vue.set(state, 'dataSchemas', []);
	},
	EVERYTHING_STORED(state)
	{
		state.loadedWholeDataSchema = true;
	},
	KEY_ALIASES(state, aliases)
	{
		Vue.set(state, 'keyAliases', aliases);
	}
};

const actions = {
	setDataSchemas({ commit }, dataSchemas)
	{
		if(!dataSchemas) return;

		commit('SET_DATA_SCHEMAS', dataSchemas);
	},
	setDataSchema({ commit }, dataSchema)
	{
		commit('SET_DATA_SCHEMA', dataSchema);
	},
	reset({ commit })
	{
		commit('RESET');
	},
	async loadWholeSchemaFile({ commit, state })
	{
		if(!state.loadedWholeDataSchema)
		{
			const { data } = await get('structure/dataSchema');

			commit('SET_DATA_SCHEMAS', data);
			commit('EVERYTHING_STORED');
		}
	}
};

const getters = {
	getDataSchemas: (state) => state.dataSchemas,
	loadedWholeDataSchema: (state) => state.loadedWholeDataSchema,
	getDataSchema: (state, getters) => (id) => getters.getDataSchemas[id],
	byId: (state, getters) => (id) => getters.getDataSchema(id),
	byKey: (state, getters) => (key) => Object.values(getters.getDataSchemas).find((schema) => schema.key === key),
	byType: (state, getters) => (type) => Object.values(getters.getDataSchemas).filter((schema) => schema.type === type),
	bySource: (state, getters) => (source) => Object.values(getters.getDataSchemas).filter((schema) => schema.source === source),
	bySourcesAndTypes: (state, getters) => (sources, types) => Object.values(getters.getDataSchemas).filter((schema) => (!sources.length || sources.includes(schema.source)) && (!types.length || types.includes(schema.type))),
	byFieldId: (state, getters, rootState, rootGetters) => (fieldId) =>
	{
		const field = rootGetters['structure/fields/getField'](fieldId);

		return field ? getters.byId(field.schema) : null;
	},
	getAll: (state, getters) => (source = null, types = []) =>
	{
		if(!Array.isArray(source))
		{
			source = [source];
		}

		return getters.bySourcesAndTypes(source, types);
	},
	getAllKeys: (state, getters) => (source = null, types = []) =>
	{
		const allSchemas = getters.getAll(source, types);

		const uniqueSchemas = uniqBy(allSchemas, 'id');

		if(!(Array.isArray(types) && types.length))
		{
			return uniqueSchemas
				.map((schema) => schema.key);
		}

		return uniqueSchemas
			.filter((schema) => types.includes(schema.type))
			.map((schema) => schema.key);
	}
};

export default {
	namespaced: true,
	modules,
	state,
	mutations,
	actions,
	getters
};
