import Router from 'vue-router';
import { toInt } from 'acb-lib';
import store from '@/store';
import { get } from '@/utils/api';
import { addInfo, addError, addSuccess } from '@/utils/notifications';
import i18n from '@/plugins/vue-i18n.js';
import { waitForTruthyValue } from '@/utils/waitForTruthyValue';
import Module from '../views/Module';
import Page from '../views/Page';

// Guard to check if the user is an admin before accessing a page.
const adminCheck = async (to, from, next) =>
{
	try
	{
		// We don't care about the response content - a 200 means they are an admin, anything else means they are not.
		await get('admin/check');
		next();
	}
	catch(e)
	{
		addInfo('That page does not exist, sorry for any inconvenience caused.');
		to.params.pageSlug = '404';
		next(false);
	}
};

// This will resolve when the user is logged in OR 50 checks have been made,
async function checkUserIsLoggedIn()
{
	const maxChecks = 50;
	let currentCheck = 0;

	const loggedIn = store.getters['user/jwt'];

	if(!loggedIn)
	{
		let jwtInterval;

		const jwtPromise = new Promise((resolve, reject) =>
		{
			jwtInterval = setInterval(() =>
			{
				if(store.getters['user/jwt'])
				{
					clearInterval(jwtInterval);
					resolve();
				}
				else
				{
					currentCheck += 1;
					if(currentCheck >= maxChecks)
					{
						// If the check hasn't passed by now, just let them through the route will fail them.
						clearInterval(jwtInterval);
						resolve();
					}
				}
			}, 100);
		});

		return jwtPromise;
	}

	// if the user is already logged in, return a fake resolved promise.
	return Promise.resolve();
}

/**
 * @param {number} targetAccountId
 */
async function checkUserCanViewProfile(targetAccountId)
{
	if(Number.isNaN(targetAccountId))
	{
		// This isn't a profile, give me something else.
		return false;
	}

	try
	{
		const profile = await get(`profile/${targetAccountId}`);

		if(profile)
		{
			return profile.status === 200;
		}
	}
	catch(e)
	{
		console.error(e);
	}

	return false;
}

const routeConfig = [
	{
		path: '/settings',
		component: () => import('@/views/Settings'),
		displayInMenu: false,
		label: 'settings',
		meta: {
			displayPages: false
		}
	},
	{
		name: 'chat',
		path: '/chat/:targetId?',
		component: () => import('@/views/Chat'),
		label: 'chat',
		meta: {
			displayPages: false
		}
	},
	{
		path: '/admin',
		meta: { adminPage: true },
		component: () => import(/* webpackChunkName: "admin" */'@/views/Admin'),
		beforeEnter: adminCheck,
		children: [
			{
				name: 'userLists',
				path: 'userLists',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/UserLists'),
				children: [
					{
						name: 'listDetail',
						path: 'view',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/userLists/ListDetail')
					},
					// seems unused
					{
						name: 'listEdit',
						path: ':listId/edit',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/userLists/ListEdit')
					}
				]
			},
			{
				name: 'applications',
				path: 'applications',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Applications'),
				children: [
					{
						name: 'editApplication',
						path: ':applicationId',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/applications/Edit')
					},
					// seems unused
					{
						name: 'listJoinRequests',
						path: ':applicationId/requests',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/ListJoinRequests'),
						// seems unused
						children: [
							{
								name: 'listJoinRequest',
								path: ':requestId',
								component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/ListJoinRequest')
							}
						]
					}
				]
			},
			{
				name: 'membershipRequests',
				path: 'membershipRequests',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/membershipRequests/List'),
				children: [
					{
						name: 'membershipRequest',
						path: ':requestId',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/membershipRequests/Request')
					}
				]
			},
			{
				name: 'layouts',
				path: 'layouts',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/layouts/List'),
				children: [
					{
						name: 'editModuleLayout',
						path: 'module/:moduleId',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/layouts/EditModule'),
						children: [
							{
								name: 'editPageLayout',
								path: 'page/:pageId',
								component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/layouts/EditPage')
							}
						]
					}
				]
			},
			{
				name: 'entities',
				path: 'entities',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Entities'),
				children: [
					{
						name: 'editEntity',
						path: ':definitionId/entity/:entityId',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/entities/EditEntity')
					},
					{
						name: 'editEntityDefinition',
						path: ':definitionId/edit/:tab',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/entities/EditEntityDefinition')
					}
				]
			},
			{
				name: 'dashboard',
				path: 'dashboard',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Dashboard')
			},
			{
				name: 'insights',
				path: 'insights',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Insights')
			},
			{
				name: 'insights2',
				path: 'insights2',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Insights2'),
				children: [
					{
						name: 'insightsDashboard',
						path: 'dash/:dashboardId',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Insights2/Dashboard')
					}
				]
			},
			{
				name: 'notifications',
				path: 'notifications',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/AdminPreferences')
			},
			{
				name: 'broadcasts',
				path: 'broadcasts',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/BroadcastNotification')
			},
			{
				name: 'notificationHistory',
				path: 'notificationHistory',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/NotificationHistory')
			},
			{
				name: 'blocks',
				path: 'blocks',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Blocks'),
				children: [
					{
						name: 'editBlock',
						path: ':blockId',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/blocks/Edit')
					}
				]
			},
			// seems unused
			{
				name: 'apps',
				path: 'apps',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Apps')
			},
			// seems unused
			{
				name: 'reports',
				path: 'reports',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Reports')
			},
			// seems unused
			{
				name: 'exports',
				path: 'exports',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Exports')
			},
			// seems unused
			{
				name: 'pageContent',
				path: 'content',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/PageContent')
			},
			{
				name: 'settings',
				path: 'settings',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Settings')
			},
			{
				name: 'connections',
				path: 'connections',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Connections'),
				children: [
					{
						name: 'viewConnection',
						path: ':connectionId',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/connections/ViewConnection')
					}
				]
			},
			{
				name: 'users',
				path: 'users',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Users'),
				children: [
					{
						name: 'usersLists',
						path: ':accountId/lists',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/users/UsersLists')
					}
					// editUser removed due to issues experienced - might be replaced by the new edit functionality available to the users table
					// {
					// 	name: 'editUser',
					// 	path: ':accountId/edit',
					// 	component: () => import('@/components/admin/dash/users/Edit')
					// }
				]
			},
			{
				name: 'emailTemplateBuilder',
				path: 'emailTemplateBuilder',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/EmailTemplateBuilder'),
				children: [
					{
						name: 'editEmail',
						path: ':emailTemplate',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/EmailTemplateBuilder')
					}
				]
			},
			{
				name: 'tools',
				path: 'tools',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/Tools')
			},
			{
				name: 'searchManagement',
				path: 'searchManagement',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/SearchManagement')
			},
			{
				name: 'dataMappings',
				path: 'dataMappings',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/DataMappings'),
				children: [
					{
						name: 'editMapping',
						path: ':id',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/DataMappings/Edit')
					}
				]
			},
			{
				name: 'reportAbuse',
				path: 'reportAbuse',
				component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/ReportAbuse'),
				children: [
					{
						name: 'editReportAbuse',
						path: ':id/:bulkReports?',
						component: () => import(/* webpackChunkName: "admin" */'@/components/admin/dash/ReportAbuse/Edit')
					}
				]
			}
			/** IMPORTANT: When adding new routes here, also update aluminate-vue/src/configs/constants.js::adminDashboardPermissionPaths with the `name` from here are the key and the necessary permissions that page will need. also update aluminate-vue/src/configs/constants.js::adminDashboardSubPaths with any sub-routes you're adding
			 * Also make sure that any new permissions that page needs are added to aluminate-api/src/models/PermissionsManager/permissionAreas.json
			 * This is to ensure that the permissions continue to work.
			 *
			 * DON'T DELETE THIS COMMENT!
			 */

			// {
			// 	name: 'adminPage',
			// 	path: ':page',
			// 	component: () => import('../views/AdminPage')
			// }
		]
	},
	{
		path: '/autoLogIn',
		beforeEnter(to, from, next)
		{
			// console.log('from', from, 'to', to);
			next({ path: '/', query: to.query });
			// Put the full page url including the protocol http(s) below
			// window.location.href = `${process.env.API}login`;
		}
	},
	{
		path: '/socialAuth',
		async beforeEnter(to, _0, _1)
		{
			const { provider, dataString, source, returnTo } = to.query;
			const result = await store.dispatch('user/addSocialAccountLink', { provider, dataString, source });
			const decodedReturnPath = decodeURIComponent(returnTo)
				// ACB doesn't know what /app is, but sometimes it gets sent as a return path as it's part of the URL
				.replace(/^\/?app/, '');

			store.dispatch('app/setSocialSignInStatus', {
				statusType: result.status,
				statusProvider: result.provider,
				statusMessage: result.message || ''
			});

			router.push(decodedReturnPath, () =>
			{
				switch(result.status)
				{
					case 'created':
						addSuccess(
							result.message ||
							i18n.t('socialSignIn.routes.status.created', { provider: result.provider })
						);

						break;
					case 'exists':
						addInfo(
							result.message ||
							i18n.t('socialSignIn.routes.status.exists', { provider: result.provider })
						);

						break;
					default:
						addError(
							result.message ||
							i18n.t('socialSignIn.routes.status.error', { provider: result.provider })
						);
				}
			});
		}
	},
	{
		path: '/socialAuth/error',
		beforeEnter(to, _0, _1)
		{
			const { message, returnTo } = to.query;

			router.push(decodeURIComponent(returnTo), () =>
			{
				addError(decodeURIComponent(message));
			});
		}
	},
	{
		path: '/:moduleSlug',
		name: 'module',
		component: Module
	},
	{
		path: '/space/:targetId',
		name: 'spaceIndex',
		async beforeEnter(to, from, next)
		{
			await checkUserIsLoggedIn();

			const { targetId } = to.params;

			await store.dispatch('entities/load', { id: targetId });

			const entity = store.getters['entities/byId'](targetId);

			await store.dispatch('structure/loadSpecificModule', { moduleSlug: entity.location.module, opts: { id: entity.location.module } });

			const moduleSlug = store.getters['structure/modules/getSlugForId'](entity.location.module);
			const pageSlug = store.getters['structure/pages/getSlugForId'](entity.location.page);

			next({ name: entity.location.routerPageName, params: { moduleSlug, pageSlug, targetId } });
		}
	},
	{
		path: '/space/:targetId/:moduleSlug',
		name: 'spaceIndexWithModule',
		async beforeEnter(to, from, next)
		{
			await checkUserIsLoggedIn();

			const { targetId, moduleSlug } = to.params;

			await store.dispatch('entities/load', { id: targetId });

			const entity = store.getters['entities/byId'](targetId);

			await store.dispatch('structure/loadSpecificModule', { moduleSlug });

			const pageSlug = store.getters['structure/pages/getSlugForId'](entity.location.page);

			next({ name: entity.location.routerPageName, params: { moduleSlug, pageSlug, targetId } });
		}
	},
	{
		path: '/space/:targetId/:moduleSlug/:pageSlug/',
		name: 'space',
		component: Page,
		async beforeEnter(to, from, next)
		{
			await checkUserIsLoggedIn();
			const { moduleSlug, targetId } = to.params;

			await store.dispatch('structure/loadSpecificModule', { moduleSlug });
			await store.dispatch('entities/load', { id: targetId });

			// Paul: removed on 2024/11/13 since we're also doing it
			// Page.view::mounted(), but I'm not absolutely sure there isn't a
			// case where one could happen and not the other.

			store.dispatch('audit/logImmediate', {
				logType: 'entityView',
				subject: targetId
			});

			next();
		}
	},
	{
		path: '/:moduleSlug/:pageSlug/:targetId?/:modifier?',
		name: 'page',
		component: Page,
		async beforeEnter(to, from, next)
		{
			await checkUserIsLoggedIn();
			const { moduleSlug, targetId } = to.params;

			await store.dispatch('structure/loadSpecificModule', { moduleSlug });

			if(targetId && !Number.isNaN(parseInt(targetId, 10)))
			{
				await store.dispatch('profiles/loadProfile', targetId);
			}

			if(targetId && Number.isNaN(parseInt(targetId, 10)))
			{
				await store.dispatch('entities/load', { id: targetId });
			}

			next();
		}
	},
	{
		path: '/',
		component: () => import('../views/Index')
	}
];

const router = new Router({
	mode: 'history',
	routes: routeConfig,
	base: process.env.NODE_ENV === 'production' ? '/app' : '/'
});

router.beforeEach(async (to, from, next) =>
{
	if(store.getters['i18n/admin/isEditingModeEnabled'])
	{
		return;
	}

	if(store.getters['admin/panel/active'])
	{
		addInfo('You must close the admin panel before navigating away.');
		next(false);

		return;
	}

	const userOnlyChangedQuery = (to.path === from.path && JSON.stringify(to.query) !== JSON.stringify(from.query));

	if(!to.meta) to.meta = {};

	to.meta.userOnlyChangedQuery = userOnlyChangedQuery;

	store.dispatch('app/setActiveModuleId', null);

	// Test the targetId as an account ID
	const targetIdAsInt = toInt(to?.params?.targetId);

	/**
	 * Global guard against access to restricted profiles.
	 * This is required to stop search from allowing access to data that it may
	 * otherwise return. This IS NOT a full protection against the return of
	 * the data in the first place. Search calls may still be returning data
	 * that the current user should not have access to, until the general
	 * profile access permissions stored in the visibilityPermissions table are
	 * converted to search filters in the API.
	 *
	 * The expected result is that, if you click a search result, you
	 * navigation will stop before you reach the requested profile. Users that
	 * are not "visible" to the current user WILL still be returned in search
	 * for the time being. This must be fixed in future.
	 */
	if(
		targetIdAsInt &&
		!(await checkUserCanViewProfile(targetIdAsInt))
	)
	{
		return;
	}

	/**
	 * TODO
	 * This type of logic of what needs to be loaded before the page can actually be displayed should be done inside structure store
	 * where it (kinda) already handles `X to be executed if pageType === Y`
	 */
	if(to.name === 'space' && to.params.targetId && !userOnlyChangedQuery && to.params.pageSlug !== '404')
	{
		await checkUserIsLoggedIn();

		// load the page and get its id, because we need the id, but all we have now is the slug
		const { moduleSlug, pageSlug } = to.params;
		let desiredPageId = store.getters['structure/pages/getPageId'](moduleSlug, pageSlug);

		await store.dispatch('entities/load', { id: to.params.targetId });

		// get the entityId; if we have no entityId, then there's no reason to continue processing landing page
		const entityId = store.getters['entities/convertSlugToId'](to.params.targetId);

		// try to reload the module & get the page again
		if(entityId && !desiredPageId)
		{
			await store.dispatch('structure/loadSpecificModule', { moduleSlug });
			desiredPageId = store.getters['structure/pages/getPageId'](moduleSlug, pageSlug);
		}

		if(!desiredPageId || !entityId)
		{
			// no point continuing now; fall back to the end
			to.params.pageSlug = '404';
		}
		else if(
			!to.meta.landingPageProcessed &&
			(from.params.targetId !== to.params.targetId) &&
			store.getters['entities/byId'](entityId)?.permissionType === 'memberships'
		)
		{
			const newRoute = { ...to };

			newRoute.meta.landingPageProcessed = true; // this should be true no matter what happens in this block

			// if the user is not in any of the entity's memberships, fall back to the settings for non-members
			let processAsDefaultUser = false;

			// get this entity's memberships
			const memberships = store.getters['entities/getPowerUpData'](
				entityId,
				store.getters['entities/getPowerUpId']('membership')
			)?.membershipType || [];

			if(memberships?.length)
			{
				await waitForTruthyValue(() => store.getters['user/accountId']);

				// load list memberships
				await store.dispatch('user/getListMemberships');

				// select a membership the user belongs to
				const membershipUserIsIn = memberships.find((data) => (
					data.primaryUserList?.value &&
					(
						store.getters['profiles/isProfileAcceptedInList'](
							store.getters['user/accountId'],
							data.primaryUserList.value
						) || store.getters['admin/viewingAs'].includes(data.primaryUserList.value)
					)
				));

				// if the user is not in any of the entity's memberships, treat them as a non-member
				if(!membershipUserIsIn)
				{
					processAsDefaultUser = true;
				}
				else
				{
					// get the landing page for that membership
					const landingPageId = store.getters['entities/getLandingPageForMembership'](entityId, membershipUserIsIn?.id);

					// if the user is trying to go to a specific page that they CAN access, don't go to the landing page
					await store.dispatch('entities/gatherViewablePages', { entityId });
					const viewablePages = store.getters['entities/getViewablePages'](entityId) || [];

					if(
						landingPageId &&
						landingPageId !== desiredPageId &&
						viewablePages.includes(landingPageId)
					)
					{
						// load the page
						await store.dispatch('structure/pages/loadPage', landingPageId);

						// get the page slug
						const landingPageSlug = store.getters['structure/pages/getSlugForId'](landingPageId);

						newRoute.params.pageSlug = landingPageSlug || to.params.pageSlug;

						await router.replace(newRoute);

						return;
					}
				}
			}
			else
			{
				processAsDefaultUser = true;
			}

			// do this if there are no memberships on the entity OR the user is not a member of any of them
			if(processAsDefaultUser && store.getters['entities/byId'](entityId)?.pagePermissions)
			{
				// no memberships? no problem! get the settings for non-members
				await store.dispatch('entities/gatherViewablePages', { entityId });

				// viewablePages is stored with the entity.id so make sure to convert from slug
				// after dispatching gatherViewablePages you should have the entity loaded already so call convertSlugToId here
				const landingPageNoMembership = store.getters['entities/getLandingPageNoMemberships'](entityId);
				const viewablePages = store.getters['entities/getViewablePages'](entityId);
				// if viewablePages is empty, the page is undefined. this is fine, the user shouldn't go to the original page anyway
				const newPageId = landingPageNoMembership || viewablePages?.[0];

				if(newPageId && newPageId !== desiredPageId)
				{
					await store.dispatch('structure/pages/loadPage', newPageId);

					// falling back to original pageId here will cause a loop
					newRoute.params.pageSlug = store.getters['structure/pages/getSlugForId'](newPageId) || newPageId;

					await router.replace(newRoute);

					return;
				}
				else if(!viewablePages?.length)
				{
					// something went wrong with getting the entity data or it has no pages
					to.params.pageSlug = '404';
				}
			}
		}

		// check if user can view this page regardless of landing page being processed
		// We don't need to check whether admins are in the right memberships
		if(entityId && desiredPageId && !store.getters['user/isAdmin'])
		{
			if(
				// without pagePermissions, viewablePages will be empty or null
				store.getters['entities/byId'](entityId)?.pagePermissions &&
				store.getters['entities/byId'](entityId)?.permissionType === 'memberships'
			)
			{
				await store.dispatch('entities/gatherViewablePages', { entityId });

				// viewablePages is stored with the entity.id so make sure to convert from slug
				const landingPageNoMembership = store.getters['entities/getLandingPageNoMemberships'](entityId);
				const viewablePages = store.getters['entities/getViewablePages'](entityId);

				if(!viewablePages)
				{
					// something went wrong with getting the entity data or it has no pages
					to.params.pageSlug = '404';
				}
				// send the user to a page they can access
				else if(!viewablePages.includes(desiredPageId))
				{
					const altRoute = { ...to };
					// if viewablePages is empty, the page is undefined. this is fine,
					// the user shouldn't go to the original page anyway
					const newPageId = landingPageNoMembership || viewablePages[0];

					if(newPageId && newPageId !== desiredPageId)
					{
						await store.dispatch('structure/pages/loadPage', newPageId);

						// falling back to original pageId here will cause a loop
						altRoute.params.pageSlug = store.getters['structure/pages/getSlugForId'](newPageId) || newPageId;

						await router.replace(altRoute);

						return;
					}
				}
			}
		}
	}

	store.dispatch('structure/routerUpdate', to.params);
	store.dispatch('app/addRoute', to);

	next();
});

router.afterEach(async (to) =>
{
	let userNavigatedThroughBrowserHistory = false;

	window.onpopstate = function historyNavigation()
	{
		userNavigatedThroughBrowserHistory = true;
	};

	if(!userNavigatedThroughBrowserHistory && !to.meta.userOnlyChangedQuery)
	{
		await store.dispatch('app/scrollToTop');
	}

	if(to.name !== 'chat')
	{
		store.dispatch('chats/setTempChats', []);
	}

	if(to.meta.landingPageProcessed)
	{
		to.meta.landingPageProcessed = false;
	}
});

export default router;

export { routeConfig };
