<template>
	<div class="modules-container footerWrapper">
		<q-toolbar class="q-pa-md">
			<q-toolbar-title>
				<div
					v-if="imageId"
					class="footerImageContainer"
				>
					<img
						v-bind="imgThumbProps"
					>
				</div>
				<div
					v-else
					class="footerImageContainer"
				>
					<img
						style="max-width: 214px;"
						src="~@/assets/v7-logo.png"
						:alt="$t('footer.logo.altText')"
					>
				</div>
			</q-toolbar-title>
			<div class="q-pr-md">
				<SimpleButton
					v-if="footerLink6URL"
					flat
					type="a"
					color="#fff"
					target="_blank"
					:href="footerLink6URL"
				>
					<I18N id="footer.footerLink6.label" />
				</SimpleButton>
				<SimpleButton
					v-if="footerLink7URL"
					flat
					type="a"
					color="#fff"
					target="_blank"
					:href="footerLink7URL"
				>
					<I18N id="footer.footerLink7.label" />
				</SimpleButton>
				<SimpleButton
					v-if="privacyPolicyURL"
					flat
					type="a"
					color="#fff"
					target="_blank"
					:href="privacyPolicyURL"
				>
					<I18N id="footer.privacy.label" />
				</SimpleButton>
				<SimpleButton
					v-if="termsAndConditionsURL"
					flat
					type="a"
					color="#fff"
					target="_blank"
					:href="termsAndConditionsURL"
				>
					<I18N id="footer.terms.label" />
				</SimpleButton>
				<SimpleButton
					v-if="cookiePolicyURL"
					flat
					type="a"
					color="#fff"
					target="_blank"
					:href="cookiePolicyURL"
				>
					<I18N id="footer.cookies.label" />
				</SimpleButton>
				<SimpleButton
					v-if="helpPageUrl"
					flat
					type="a"
					color="#fff"
					target="_blank"
					:href="helpPageUrl"
				>
					<I18N id="footer.helpPage.label" />
				</SimpleButton>
				<SimpleButton
					v-if="accessibilityPolicyURL"
					flat
					type="a"
					color="#fff"
					target="_blank"
					:href="accessibilityPolicyURL"
				>
					<I18N id="footer.accessibility.label" />
				</SimpleButton>
			</div>
			<div v-if="!isProduction">
				<p style="margin: 0; opacity: 0.4;">
					<span>{{ apiUrl }}</span>
				</p>
			</div>
		</q-toolbar>
	</div>
</template>

<script>
	import ImageMixin from '@/components/mixins/ImageMixin';

	export default {
		mixins: [ImageMixin],
		data()
		{
			return {
				thumbSize: 500
			};
		},
		computed: {
			connectedInstance()
			{
				return this.$store.getters['app/connectedInstance'];
			},
			imageId()
			{
				const footerImageId = this.$store.getters['app/settings/get']('footerOptions')?.footerLogoId;
				const headerImageId = this.$store.getters['app/settings/get']('logoImage');

				return footerImageId || headerImageId;
			},
			privacyPolicyURL()
			{
				return this.$store.getters['app/settings/get']('footerOptions')?.privacyPolicy;
			},
			termsAndConditionsURL()
			{
				return this.$store.getters['app/settings/get']('footerOptions')?.termsAndConditions;
			},
			cookiePolicyURL()
			{
				return this.$store.getters['app/settings/get']('footerOptions')?.cookiePolicy;
			},
			helpPageUrl()
			{
				return this.$store.getters['app/settings/get']('footerOptions')?.helpPage;
			},
			accessibilityPolicyURL()
			{
				return this.$store.getters['app/settings/get']('footerOptions')?.accessibilityPolicy;
			},
			footerLink6URL()
			{
				return this.$store.getters['app/settings/get']('footerOptions')?.footerLink6;
			},
			footerLink7URL()
			{
				return this.$store.getters['app/settings/get']('footerOptions')?.footerLink7;
			},
			env()
			{
				return process.env.LIVE;
			},
			apiUrl()
			{
				return process.env.API;
			},
			isProduction()
			{
				return !!this.env;
			}
		}
	};
</script>

<style lang="postcss" scoped>
	.modules-container,
	.pages-container {
		width: 100%;
		display: flex;
		max-width: 80rem;
		margin: auto;
	}

	.footerWrapper {
		>>> a,
		>>> span {
			color: var(--footer-color-link);
		}
	}

	.policy-container {
		padding-right: 0;
	}

	.q-toolbar {
		font-style: italic;
		font-size: small;
	}

	@media (min-width: 1424px) {
		.modules-container,
		.pages-container {
			max-width: 80rem;
		}

		.policy-container {
			padding-right: 20px;
		}
	}

	.footerImageContainer {
		display: flex;
		align-items: center;
		height: 50px;
		width: 214px;
	}
</style>
