<template>
	<SimpleButton
		dense
		round
		flat
		:aria-label="$t(label)"
		:icon="icon"
		:textColor="color"
		@click="open"
	>
		<q-badge
			v-if="unseenCount !== 0"
			floating
			:color="alertColor"
			class="roundBadge"
			:label="unseenCount > 9 ? '9+' : unseenCount"
		/>
		<q-popup-proxy>
			<div class="notificationsProxy">
				<q-banner>
					<Items
						:notifications="notifications"
						:label="label"
						:labelLink="labelLink"
						:admin="admin"
						:showUserSearch="showUserSearch"
					/>
				</q-banner>
			</div>
		</q-popup-proxy>
	</SimpleButton>
</template>

<script>
	import Items from '@/components/ui/notificationsMenu/Items';

	export default {
		components: {
			Items
		},
		props: {
			unseenCount: {
				type: [Number, String],
				required: true
			},
			label: {
				type: String,
				required: true
			},
			labelLink: {
				type: String,
				default: null
			},
			icon: {
				type: String,
				required: true
			},
			notifications: {
				type: Array,
				required: true
			},
			color: {
				type: String,
				default: 'white'
			},
			alertColor: {
				type: String,
				default: 'orange'
			},
			admin: {
				type: Boolean,
				default: false
			},
			showUserSearch: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			open()
			{
				this.$emit('open');
			}
		}
	};
</script>

<style scoped>
	.notificationsProxy .q-banner {
		padding: 0;
	}
</style>
