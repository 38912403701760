import Vue from 'vue';
import { get, put, post } from '@/utils/api';
import { addWarn } from '@/utils/notifications';

const state = {
	loading: false,
	connectionRequests: [],
	oldConnectionRequests: [],
	states: [],
	connectionShortlists: {}
};

const mutations = {
	SET_STATES(state, states)
	{
		Vue.set(state, 'states', states);
	},
	SET_CONNECTION_REQUEST(state, payload)
	{
		const currentIndex = state.connectionRequests.findIndex((request) =>
		{
			return (
				parseInt(request.leaderId, 10) === parseInt(payload.leaderId, 10) &&
				parseInt(request.followerId, 10) === parseInt(payload.followerId, 10) &&
				request.userListId === payload.userListId
			);
		});

		if(currentIndex > -1)
		{
			Vue.set(state.connectionRequests, currentIndex, payload);
		}
		else
		{
			state.connectionRequests.push(payload);
		}
	},
	SET_OLD_CONNECTION_REQUEST(state, payload)
	{
		const currentIndex = state.oldConnectionRequests.findIndex((r) => r.id === payload.id);

		if(currentIndex > -1)
		{
			state.oldConnectionRequests.splice(currentIndex, 1, payload);
		}
		else
		{
			state.oldConnectionRequests.push(payload);
		}
	},
	SET_LOADING(state, mode)
	{
		state.loading = mode;
	},
	RESET(state)
	{
		state.connectionRequests = [];
	},
	SET_STATE_OF_CONNECTION(state, { id, state: connectionState })
	{
		const connectionIndex = state.connectionRequests.findIndex((request) => request.listMembershipId === id);

		if(connectionIndex > -1)
		{
			Vue.set(state.connectionRequests, connectionIndex, { ...state.connectionRequests[connectionIndex], state: connectionState });
		}
	},
	SET_IS_LOADING_CONNECTION_SHORTLIST(state, { accountId, connectionTypeId, isLoading })
	{
		if(accountId in state.connectionShortlists)
		{
			if(connectionTypeId in state.connectionShortlists[accountId])
			{
				Vue.set(state.connectionShortlists[accountId][connectionTypeId], 'isLoading', isLoading);
			}
			else
			{
				Vue.set(state.connectionShortlists[accountId], connectionTypeId, { isLoading });
			}
		}
		else
		{
			Vue.set(state.connectionShortlists, accountId, { [connectionTypeId]: { isLoading } });
		}
	},
	SET_CONNECTION_SHORTLIST(state, { accountId, connectionTypeId, shortlist })
	{
		if(accountId in state.connectionShortlists)
		{
			if(connectionTypeId in state.connectionShortlists[accountId])
			{
				Vue.set(state.connectionShortlists[accountId][connectionTypeId], 'shortlist', shortlist);
			}
			else
			{
				Vue.set(state.connectionShortlists[accountId], connectionTypeId, { shortlist });
			}
		}
		else
		{
			Vue.set(state.connectionShortlists, accountId, { [connectionTypeId]: { shortlist } });
		}
	},
	SET_IS_LOADING_CONNECTION_SHORTLIST_APPLICATIONS_DATA(state, { accountId, connectionTypeId, isLoading })
	{
		if(accountId in state.connectionShortlists)
		{
			if(connectionTypeId in state.connectionShortlists[accountId])
			{
				Vue.set(state.connectionShortlists[accountId][connectionTypeId], 'isLoadingApplicationsData', isLoading);
			}
			else
			{
				Vue.set(state.connectionShortlists[accountId], connectionTypeId, { isLoadingApplicationsData: isLoading });
			}
		}
		else
		{
			Vue.set(state.connectionShortlists, accountId, { [connectionTypeId]: { isLoadingApplicationsData: isLoading } });
		}
	},
	SET_CONNECTION_SHORTLIST_APPLICATIONS_DATA(state, { accountId, connectionTypeId, applicationsData })
	{
		if(accountId in state.connectionShortlists)
		{
			if(connectionTypeId in state.connectionShortlists[accountId])
			{
				Vue.set(state.connectionShortlists[accountId][connectionTypeId], 'applicationsData', applicationsData);
			}
			else
			{
				Vue.set(state.connectionShortlists[accountId], connectionTypeId, { applicationsData });
			}
		}
		else
		{
			Vue.set(state.connectionShortlists, accountId, { [connectionTypeId]: { applicationsData } });
		}
	},
	REMOVE_CONNECTION_SHORTLIST_DATA(state)
	{
		Vue.set(state, 'connectionShortlists', {});
	}
};

const actions = {
	setLoading({ commit }, mode)
	{
		commit('SET_LOADING', mode);
	},
	async create({ dispatch, rootGetters }, payload)
	{
		dispatch('setLoading', true);
		try
		{
			const { data } = await put('applications/connections', payload);

			const accountIds = [data.leaderId, data.followerId];

			setTimeout(() =>
			{
				dispatch('setConnectionRequest', data);
			}, 500);

			await dispatch('profiles/loadProfiles', accountIds, { root: true });
		}
		catch(e)
		{
			console.warn(e);
			if(e.response && e.response.data && e.response.data.listAtCapacity)
			{
				addWarn(rootGetters['i18n/get']('errors.userLists.listAtCapacity'));
			}
		}
		finally
		{
			dispatch('setLoading', false);
		}
	},
	async resetConnections({ commit })
	{
		await commit('RESET');
	},
	async loadConnectionRequests({ dispatch }, { page = 0, filters = {} } = {})
	{
		dispatch('setLoading', true);
		const { data } = await get('applications/connections', { params: { page, filters } });

		dispatch('setConnectionRequests', data.data);

		const accountIds = data.data.map((application) => application.accountId);

		await dispatch('applications/admin/loadApplications', null, { root: true });
		await dispatch('profiles/loadProfiles', accountIds, { root: true });

		dispatch('setLoading', false);

		return data;
	},
	async loadOldConnectionRequest({ dispatch, getters }, listMembershipId)
	{
		const existingRequest = getters.oldConnectionRequestByMembershipId(listMembershipId);

		if(existingRequest) return existingRequest;

		dispatch('setLoading', true);
		const { data } = await get(`applications/oldConnections/${listMembershipId}`);

		dispatch('setOldConnectionRequest', data);

		return data;
	},
	async loadConnectionRequest({ dispatch }, id)
	{
		dispatch('setLoading', true);
		const { data } = await get(`applications/connections/${id}`);

		dispatch('setConnectionRequest', data);

		const { accountId } = data;

		await dispatch('applications/admin/loadApplications', null, { root: true });
		await dispatch('profiles/loadProfiles', [accountId], { root: true });

		dispatch('setLoading', false);

		return data;
	},
	async loadOwnConnectionRequests({ dispatch, rootGetters })
	{
		dispatch('setLoading', true);
		const { data } = await get('applications/connections/me');

		dispatch('setConnectionRequests', data);

		const accountIds = data.reduce((agg, connection) =>
		{
			agg.push(connection.leaderId, connection.followerId);

			return agg;
		}, []);

		// if(rootGetters['user/isAdmin'])
		// {
		// 	await dispatch('applications/admin/loadApplications', null, { root: true });
		// }

		await dispatch('profiles/loadProfiles', accountIds, { root: true });

		dispatch('setLoading', false);

		return data;
	},
	async loadPotentialConnections({ dispatch, rootGetters }, leaderAccountId)
	{
		if(!leaderAccountId) return null;

		dispatch('setLoading', true);
		const { data } = await get(`entities/connections/available/${leaderAccountId}`);

		dispatch('setConnectionRequests', data);

		const accountIds = data.reduce((agg, connection) =>
		{
			agg.push(connection.leaderId, connection.followerId);

			return agg;
		}, []);

		// if(rootGetters['user/isAdmin'])
		// {
		// 	await dispatch('applications/admin/loadApplications', null, { root: true });
		// }

		await dispatch('profiles/loadProfiles', [accountIds], { root: true });

		dispatch('setLoading', false);

		return data;
	},
	async loadStates({ dispatch, getters })
	{
		if(!getters.getStates || !getters.getStates.length)
		{
			dispatch('applications/setLoading', true, { root: true });

			const { data } = await get('applications/states');

			dispatch('setStates', data);
			dispatch('applications/setLoading', false, { root: true });
		}
	},
	setStates({ commit }, states)
	{
		commit('SET_STATES', states);
	},
	setConnectionRequests({ commit, dispatch }, payload)
	{
		if(Array.isArray(payload))
		{
			payload.forEach((request) => dispatch('setConnectionRequest', request));
		}
	},
	setConnectionRequest({ commit }, payload)
	{
		commit('SET_CONNECTION_REQUEST', payload);
	},
	setOldConnectionRequest({ commit }, payload)
	{
		commit('SET_OLD_CONNECTION_REQUEST', payload);
	},
	async changeState({ dispatch, commit }, { entityId, listId, followerId, leaderId, state, connectionId })
	{
		try
		{
			const { data } = await post(`applications/membershipRequests/${entityId}/${listId}/${followerId}/${state}/${connectionId}`);

			commit('SET_STATE_OF_CONNECTION', { id: data.id, state });

			await dispatch('resetConnections');
			await dispatch('loadPotentialConnections', leaderId);
			await dispatch('loadOwnConnectionRequests');
		}
		catch(e)
		{
			console.warn(e);
		}
	},
	async findExisting({ getters, commit }, { entityId, connectionId, leaderId, followerId, forceLoad })
	{
		try
		{
			// TODO: need a delete action to update the store data when a connection is deleted
			if(!forceLoad && getters.doesConnectionExist(leaderId, followerId, entityId, connectionId)) return;

			const { data } = await get(`applications/${entityId}/connections/${connectionId}/existing`, { params: { leaderId, followerId } });

			if(data)
			{
				commit('SET_CONNECTION_REQUEST', data);
			}
		}
		catch(e)
		{
			console.error(e.response?.data?.message || e);
		}
	},
	async loadConnectionShortlistByAccountId({ commit, getters }, { connectionTypeId, accountId, force = false })
	{
		try
		{
			if(
				!force &&
				(
					getters.getConnectionShortlistByAccountId(accountId, connectionTypeId) ||
					getters.getIsLoadingConnectionShortlist(accountId, connectionTypeId)
				)
			) return;

			commit('SET_IS_LOADING_CONNECTION_SHORTLIST', { accountId, connectionTypeId, isLoading: true });

			const { data: shortlist } = await get(`applications/connections/${connectionTypeId}/shortlist/${accountId}`);

			commit('SET_CONNECTION_SHORTLIST', { accountId, connectionTypeId, shortlist });
		}
		catch(e)
		{
			console.error(e);
		}

		commit('SET_IS_LOADING_CONNECTION_SHORTLIST', { accountId, connectionTypeId, isLoading: false });
	},
	async loadConnectionShortlistApplicationsDataByAccountId({ commit, getters }, { connectionTypeId, accountId, entityId, force = false })
	{
		try
		{
			if(
				!force &&
				(
					getters.getConnectionShortlistApplicationsDataByAccountId(accountId, connectionTypeId) ||
					getters.getIsLoadingConnectionShortlistApplicationsData(accountId, connectionTypeId)
				)
			) return;

			commit('SET_IS_LOADING_CONNECTION_SHORTLIST_APPLICATIONS_DATA', { accountId, connectionTypeId, isLoading: true });

			const { data: applicationsData } = await get(`applications/connections/${connectionTypeId}/shortlist/${accountId}/applications`, {
				paras: { entityId }
			});

			commit('SET_CONNECTION_SHORTLIST_APPLICATIONS_DATA', { accountId, connectionTypeId, applicationsData });
		}
		catch(e)
		{
			console.error(e);
		}

		commit('SET_IS_LOADING_CONNECTION_SHORTLIST_APPLICATIONS_DATA', { accountId, connectionTypeId, isLoading: false });
	},
	async loadOwnConnectionShortlist({ commit, getters, rootGetters }, { connectionTypeId, force = false })
	{
		const accountId = rootGetters['user/accountId'];

		try
		{
			if(
				!force &&
				(
					getters.getConnectionShortlistByAccountId(accountId, connectionTypeId) ||
					getters.getIsLoadingConnectionShortlist(accountId, connectionTypeId)
				)
			) return;

			commit('SET_IS_LOADING_CONNECTION_SHORTLIST', { accountId, connectionTypeId, isLoading: true });

			const { data: shortlist } = await get(`applications/connections/${connectionTypeId}/shortlist/own`);

			commit('SET_CONNECTION_SHORTLIST', { accountId, connectionTypeId, shortlist });
		}
		catch(e)
		{
			console.error(e);
		}

		commit('SET_IS_LOADING_CONNECTION_SHORTLIST', { accountId, connectionTypeId, isLoading: false });
	},
	removeConnectionShortlistData({ commit })
	{
		commit('REMOVE_CONNECTION_SHORTLIST_DATA');
	}
};

const getters = {
	getStates: (state) => state.states,
	getState: (state, getters) => (name) => getters.getStates.find((s) => s.name === name),
	loading: (state) => state.loading,
	connectionRequests: (state) => state.connectionRequests,
	oldConnectionRequests: (state) => state.oldConnectionRequests,
	potentialRequests: (state, getters) => getters.connectionRequests.filter((request) => request.potential || request.state === 'withdrawn'),
	activeConnections: (state, getters) => getters.connectionRequests.filter((request) => !request.potential && request.state !== 'withdrawn'),
	connectionRequestsByProperty: (state, getters) => (key, value) => getters.connectionRequests.filter((req) => req[key] === value),
	oldConnectionRequestsByProperty: (state, getters) => (key, value) => getters.oldConnectionRequests.filter((req) => req[key] === value),
	connectionRequestsByProperties: (state, getters) => (properties) =>
	{
		let connections = getters.connectionRequests;

		properties.forEach(([key, value]) =>
		{
			connections = connections.filter((conn) => conn[key] === value);
		});

		return connections;
	},
	connectionRequestByLeaderId: (state, getters) => (id) => getters.connectionRequestsByProperty('leaderId', id),
	connectionRequestsByAccountId: (state, getters) => (id) => getters.connectionRequests.filter((req) => req.leaderId === id || req.followerId === id),
	connectionRequestsByFollowerId: (state, getters) => (id) => getters.connectionRequestsByProperty('followerId', id),
	connectionRequestByMembershipId: (state, getters) => (id) => getters.connectionRequestsByProperty('listMembershipId', id)[0],
	oldConnectionRequestByMembershipId: (state, getters) => (id) => getters.oldConnectionRequestsByProperty('id', id)[0],
	connectionRequestById: (state, getters) => (id) => getters.connectionRequestsByProperty('listMembershipId', parseInt(id, 10))[0],
	doesConnectionExist: (state, getters) => (leaderId, followerId, entityId, connectionId) => getters.connectionRequests.some((request) =>
	{
		let isActive = false;

		switch(request.state)
		{
			case 'applied':
			case 'accepted':
				isActive = true;
				break;
			default:
				break;
		}

		return isActive &&
			parseInt(request.leaderId, 10) === parseInt(leaderId, 10) &&
			parseInt(request.followerId, 10) === parseInt(followerId, 10) &&
			request.entityId === entityId &&
			request.connectionId === connectionId;
	}),
	getConnectionShortlistByAccountId: (state) => (
		accountId,
		connectionTypeId
	) => state.connectionShortlists[accountId]?.[connectionTypeId]?.shortlist,
	getOwnConnectionShortlist: (state, getters, rootState, rootGetters) => (
		connectionTypeId
	) => getters.getConnectionShortlistByAccountId(rootGetters['user/accountId'], connectionTypeId),
	getIsLoadingConnectionShortlist: (state) => (
		accountId,
		connectionTypeId
	) => state.connectionShortlists[accountId]?.[connectionTypeId]?.isLoading,
	getConnectionShortlistApplicationsDataByAccountId: (state) => (
		accountId,
		connectionTypeId
	) => state.connectionShortlists[accountId]?.[connectionTypeId]?.applicationsData,
	getIsLoadingConnectionShortlistApplicationsData: (state) => (
		accountId,
		connectionTypeId
	) => state.connectionShortlists[accountId]?.[connectionTypeId]?.isLoadingApplicationsData
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
