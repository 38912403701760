<template>
	<div>
		<transition name="fade">
			<!-- isAdmin && (can('addChildren') || canForCurrentPage('addChildren')) -->
			<div
				v-if="userCanBuild"
				class="row justify-center"
			>
				<q-btn-group
					rounded
					class="buttonContainer"
				>
					<SimpleButton
						v-if="canAddChildren"
						icon="far fa-plus"
						:wide="wide"
						:data-parentId="parent"
						:data-colIndex="colIndex"
						:aria-label="$t('admin.panel.newBlockInfo.addBlock')"
						size="sm"
						round
						flat
						textColor="primary"
						style=""
						class="block-button-add primary bg-white q-mt-md"
						@click="newBlock"
					/>
					<SimpleButton
						v-if="capturedBlocks.length && !ancestorIsCaptured && canMoveChildren"
						icon="fad fa-paste"
						:wide="wide"
						:aria-label="$t('admin.panel.newBlockInfo.pasteSelection')"
						size="sm"
						round
						flat
						textColor="positive"
						class="block-button-add positive bg-white q-ml-lg q-mt-md"
						@click="confirmPaste"
					/>
				</q-btn-group>
			</div>
		</transition>
	</div>
</template>

<script>
	import BlockPermissions from '@/components/mixins/BlockPermissions';
	import { userCan } from '@/plugins/Permissions';
	import BlockMoveMixin from '@/components/mixins/BlockMoveMixin';

	export default {
		mixins: [BlockPermissions, BlockMoveMixin],
		inject: {
			$scopeProvider: {
				default: null
			},
			ancestorIsCaptured: {
				default: false
			}
		},
		props: {
			parent: {
				type: String,
				default: undefined
			},
			colIndex: {
				type: [String, Number],
				default: '0'
			},
			index: {
				type: Number,
				default: null
			},
			wide: {
				type: Boolean,
				default: true
			},
			pageId: {
				type: String,
				default: undefined
			}
		},
		computed: {
			userCanBuild()
			{
				return this.$store.getters['admin/isEditMode'] && userCan('manageEditMode', 'administration');
			},
			$blockPermissionId()
			{
				return this.parent;
			},
			canAddChildren()
			{
				return this.canForCurrentPage('addChildren');
			},
			canMoveChildren()
			{
				return this.canForCurrentPage('moveChildren');
			}
		},
		methods: {
			newBlock()
			{
				this.$store.dispatch('structure/blocks/admin/openPanel', {
					parent: this.parent,
					colIndex: this.colIndex,
					index: this.index,
					pageId: this.pageId,
					mode: 'full-screen',
					scopeProvider: this.$scopeProvider
				});
			},
			confirmPaste()
			{
				this.$store.dispatch('app/setShowBlockMoveModal', {
					showModal: true,
					id: this.parent || this.pageId,
					colIndex: this.colIndex,
					index: this.index
				});
			}
		}
	};
</script>

<style scoped lang="postcss">
	>>> .q-btn__wrapper {
		&::before {
			box-shadow: none;
		}
	}

	>>> .block-button-add {
		margin: 0 auto;

		&:hover,
		&:focus {
			color: white !important;
		}
	}

	>>> .block-button-add.primary {
		&:hover,
		&:focus {
			background: var(--q-color-primary) !important;
		}
	}

	>>> .block-button-add.positive {
		&:hover,
		&:focus {
			background: var(--q-color-positive) !important;
		}
	}

	.buttonContainer {
		box-shadow: var(--shadow-element-dreamy-medium);
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s;
		opacity: 1;
		max-height: 9999px;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
		max-height: 0;
	}
</style>
