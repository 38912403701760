<template>
	<q-card class="custom-shadow">
		<q-card-section>
			<Block
				v-for="(blockId, index) in defaultPosition"
				:key="index"
				v-bind="$props"
				:data="blockId"
				colIndex="0"
				:index="index"
			/>
			<AddBlockButton
				:parent="data.id"
				:index="defaultPosition.length"
			/>
		</q-card-section>
	</q-card>
</template>

<script>
	import BlockMixin from '@/components/blocks/BlockMixin';
	import AddBlockButton from '@/components/admin/generic/AddBlockButton';

	export default {
		components: { AddBlockButton, Block: () => import('@/components/blocks/Block') },
		mixins: [BlockMixin]
	};
</script>

<style lang="postcss" scoped>
	.box {
		background: var(--colors-white);
		padding: 30px;
		vertical-align: top;
		width: 100%;
		box-sizing: border-box;
		border-radius: 5px;
		box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
		clear: both;

		&.size-small {
			padding: 10px;
			position: relative;
		}

		&.noPaddingBlock {
			padding: 0;
		}
	}
</style>
