import Get from 'lodash/get';

const Entity = {
	provide()
	{
		return {
			i18nVariables: this.generateI18NVariables()
		};
	},
	inject: {
		i18nVariables: {
			default: {}
		}
	},
	computed: {
		entityId()
		{
			return this?.data?.entityId || this?.targetId || '';
		},
		entity()
		{
			/*
			This is a temporary solution for the following issue. It appears that there's some sort of race condition which is
			triggered by our monitoring script. This should hopefully stop said issue from occurring.
			https://sentry.io/organizations/aluminati-fv/issues/2913444111/?project=5386974&referrer=alert_email
			*/
			try
			{
				return this.$store.getters['entities/byId'](this.entityId);
			}
			catch(e)
			{
				return null;
			}
		},
		entityLoaded()
		{
			return !!this.entity;
		},
		powerUpDataLoaded()
		{
			return !!this.entity?.powerUpData;
		},
		leaderWord()
		{
			/*
			This is a temporary solution for the following issue. It appears that there's some sort of race condition which is
			triggered by our monitoring script. This should hopefully stop said issue from occurring.
			https://sentry.io/organizations/aluminati-fv/issues/2913444111/?project=5386974&referrer=alert_email
			*/
			try
			{
				return this.$store.getters['entities/leaderWord'](this.entityId);
			}
			catch(e)
			{
				return null;
			}
		},
		followerWord()
		{
			/*
			This is a temporary solution for the following issue. It appears that there's some sort of race condition which is
			triggered by our monitoring script. This should hopefully stop said issue from occurring.
			https://sentry.io/organizations/aluminati-fv/issues/2913444111/?project=5386974&referrer=alert_email
			*/
			try
			{
				return this.$store.getters['entities/followerWord'](this.entityId);
			}
			catch(e)
			{
				return null;
			}
		},
		isBasedOnTemplate()
		{
			// eslint-disable-next-line camelcase
			return !!this.entity?.system_fromTemplate;
		},
		templateId()
		{
			// eslint-disable-next-line camelcase
			return this.entity?.system_fromTemplate;
		},
		viewablePages()
		{
			if(this.entity?.id)
			{
				return this.$store.getters['entities/getViewablePages'](this.entity.id);
			}

			return null;
		}
	},
	created()
	{
		this.$store.dispatch('entities/load', { id: this.entityId });
	},
	async mounted()
	{
		if(this.entity?.id && !this.viewablePages)
		{
			await this.$store.dispatch('entities/gatherViewablePages', {
				entityId: this.entity.id
			});
		}
	},
	methods: {
		generateI18NVariables()
		{
			/*
			This try catch is to stop a race condition where `convertSlugToId` does not exist when loading the page sometimes.
			The particularly seems to cause an issue with the monitoring script.

			It's possible that when `unsplit-main` is merged (which undoes the lazy loading of the stores too) that this will be resolved, but
			for now, this is a workaround.
			*/
			try
			{
				return {
					...this.i18nVariables,
					primaryList: this.leaderWord,
					secondaryList: this.followerWord
				};
			}
			catch(e)
			{
				console.error(e);

				return {};
			}
		},
		hasPowerUp(powerUpId)
		{
			return this.entity?.powerUps.includes(powerUpId);
		},
		getPowerUpData(powerUpId)
		{
			if(this.powerUpDataLoaded)
			{
				// return specific power up data if we have it else return false to flag it needs configuring
				return Get(this.entity?.powerUpData, powerUpId, false);
			}

			return false;
		},
		needsConfig(powerUpId)
		{
			let type = false;

			if(!this.hasPowerUp(powerUpId))
			{
				type = 'definition';

				return type;
			}

			if(!this.getPowerUpData(powerUpId))
			{
				type = 'missingData';

				return type;
			}

			return type;
		},
		getPowerUp(powerUpId)
		{
			return this.$store.getters['powerUps/byId'](powerUpId);
		},
		editEntityPowerUp()
		{
			this.$router.push({ name: 'editEntity', params: { entityId: this.entityId, definitionId: this.entity?.definition } });
		},
		editEntityDefinition()
		{
			const definitionId = this.entity?.definition;

			this.$router.push({ name: 'editEntityDefinition', params: { definitionId } });
		},
		/**
		 * Determine whether the current user is allowed to view the given page, given the pageId.
		 * @param pageId {UUID} - The id of the page to determine visibility of
		 * @returns {Boolean} - Whether the user is able to view this page
		 */
		canUserViewEntityPage(pageId)
		{
			if(this.entity)
			{
				// if the entity has no pagePermissions, show its default layout page
				if(!this.entity.pagePermissions && this.entity.defaultPermission)
				{
					return this.entity.location?.page === pageId;
				}

				// if there are no pagePermissions, viewablePages will be undefined
				if(this.entity.pagePermissions)
				{
					if(Array.isArray(this.viewablePages))
					{
						return this.viewablePages.includes(pageId);
					}
				}
			}

			return true;
		}
	}
};

export default Entity;
