
export default function (count = 10)
{
	// @vue/component
	return {
		data()
		{
			return {
				displayPriority: 0
			};
		},

		mounted()
		{
			if(process.env.NODE_ENV !== 'test')
			{
				this.runDisplayPriority();
			}
		},

		methods: {
			runDisplayPriority()
			{
				const step = () =>
				{
					requestAnimationFrame(() =>
					{
						this.displayPriority += 1;
						if(this.displayPriority < count)
						{
							step();
						}
					});
				};

				step();
			},

			defer(priority)
			{
				if(process.env.NODE_ENV === 'test') return true;

				return this.displayPriority >= priority;
			}
		}
	};
}
